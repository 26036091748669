import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import Container from "@base/Container";
import i18n from "@i18n/i18n";
import PressableBase from "@base/PressableBase";
import SearchBase from "@components/search/SearchBase";
import debounce from "lodash.debounce";

interface Props {
    setSearch: (searchType) => any;
    onSearch: (search) => Promise<void>;
    searching: boolean;
}

interface State {
    searchType: any;
    search: string;
    searching: boolean;
}

export enum SearchType {
    USER = "USER",
    POST = "POST",
    HASHTAG = "HASHTAG",
}

const { t } = i18n;

export default class SearchBarSocial extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.debouncedSearch = debounce(this.debouncedSearch, 500);
        this.state = {
            search: "",
            searching: false,
            searchType: SearchType.USER,
        };
    }

    componentDidMount = () => {
        this.props.setSearch(SearchType.USER);
    };

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.searching !== this.props.searching) {
            this.setState({ searching: this.props.searching });
        }
    }

    setSearch = (SearchType) => {
        this.setState({ searchType: SearchType });
        this.props.setSearch(SearchType);
    };

    onSearch(value) {
        this.setState({ search: value, searching: value?.length > 2 }, async () => {
            this.debouncedSearch();
        });
    }

    async debouncedSearch() {
        await this.props.onSearch(this.state.search);
    }

    render() {
        return (
            <View>
                <Container>
                    <SearchBase
                        searching={this.state.searching}
                        onSearch={(value) => this.onSearch(value)}
                        value={this.state.search}
                    />
                    <View style={{ flexDirection: "row", justifyContent: "space-around", marginVertical: 10 }}>
                        <PressableBase
                            onPress={() => this.setSearch(SearchType.USER)}
                            accessibilityLabel="users-botton"
                        >
                            <RegularText
                                fontSize={16}
                                style={this.state.searchType == SearchType.USER ? styles.select : null}
                            >
                                {t("users")}
                            </RegularText>
                        </PressableBase>
                        <PressableBase
                            onPress={() => this.setSearch(SearchType.POST)}
                            accessibilityLabel="posts-botton"
                        >
                            <RegularText style={this.state.searchType == SearchType.POST ? styles.select : null}>
                                {t("posts")}
                            </RegularText>
                        </PressableBase>
                        <PressableBase
                            onPress={() => this.setSearch(SearchType.HASHTAG)}
                            accessibilityLabel="hashtags-botton"
                        >
                            <RegularText style={this.state.searchType == SearchType.HASHTAG ? styles.select : null}>
                                {t("hashtags")}
                            </RegularText>
                        </PressableBase>
                    </View>
                </Container>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        flexDirection: "row",
        paddingHorizontal: 12,
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: colors.shadow,
        height: 40,
        borderRadius: 20,
        marginHorizontal: 5,
    },

    select: {
        color: colors.secondary,
        borderBottomColor: colors.secondary,
        fontFamily: "NunitoBold",
    },
    input: {
        zIndex: 99,
        width: "90%",
    },
});
