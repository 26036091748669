import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { RedeemableModel, RedeemableType, ExperiencesRedux, GateModel } from "@custom-types/ExpercienceModel";
import { Route } from "@react-navigation/native";
import SearchBase from "@components/search/SearchBase";
import { FlatList, View } from "react-native";
import RedeemableWideCard from "./components/RedeemableWideCard";
import SwitchCard from "@components/cards/SwitchCard";
import ExperienceService from "@core/services/ExperienceService";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import BotCard from "@base/BotCard";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";

interface Props {
    navigation: NavigationType;
    route: Route<string, { redeemables?: Array<RedeemableModel>; gate?: GateModel }>;
    experiences: ExperiencesRedux;
}

interface State {
    redeemables: Array<RedeemableModel>;
}

const { t } = i18n;

export class _SelectRedeemableScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.useReddemible = this.useReddemible.bind(this);
        this.filterAndSortRedeemables = this.filterAndSortRedeemables.bind(this);
        this.state = {
            redeemables: [],
        };
    }

    componentDidMount() {
        this.filterAndSortRedeemables();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.experiences !== this.props.experiences) {

            this.filterAndSortRedeemables();
        }
    }

    filterAndSortRedeemables() {
        const gateRedeemables = this.props.route?.params?.redeemables || [];

        const redeemableTypes = Object.values(RedeemableType);
        let concatenatedDocs = [];

        redeemableTypes?.forEach((rType) => {
            concatenatedDocs = [...concatenatedDocs, ...this.props.experiences[rType].docs];
        });

        const redeemables = concatenatedDocs.filter((r) => {
            return gateRedeemables.some((gateRedeemable) => gateRedeemable._id === r._id);
        });



        this.setState({ redeemables: redeemables });
    }

    useReddemible(redeemable: RedeemableModel) {

        this.props.navigation.navigate(ExperienceNavigatorScreens.ConfirmUseRedeemable.routeName, {
            redeemable: redeemable,
            gate: this.props.route.params?.gate,
        });
    }

    renderItem = ({ item }) => {
        return (
            <RedeemableWideCard
                onPress={() => this.useReddemible(item)}
                redeemable={item}
                navigation={this.props.navigation}
            />
        );
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("select")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    <BotCard
                        title="Tienes estas opciones disponibles para usar!"
                        message="Selecciona uno para continuar"
                    />
                    <FlatList
                        data={this.state.redeemables}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item._id}
                        contentContainerStyle={{ paddingTop: 10, zIndex: 99 }}
                        initialNumToRender={5}
                        maxToRenderPerBatch={10}
                        showsHorizontalScrollIndicator={false}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        experiences: state.experiences,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SelectRedeemableScreen = connect(mapStateToProps, mapDispatchToProps)(_SelectRedeemableScreen);

export default SelectRedeemableScreen;
