import { Client } from "./Client";
import { AssetModel, MembershipModel, MerchantModel, RedeemableModel, RedeemableType } from "./ExpercienceModel";

export enum ActivityTypes {
    //DIGITAL CURRENCIES
    DigitalCurrencyTransaction = "digitalCurrencyTransaction",
    DigitalCurrencyExchange = "digitalCurrencyExchange",

    //REDEEMABLE

    RedeemableDrop = "redeemableDrop",
    RedeemableTransfer = "redeemableTransfer",
    RedeemableUse = "redeemableUse",

    //ASSET
    AssetClaim = "assetClaim",
    AssetDrop = "assetDrop",
    AssetTransfer = "assetTransfer",

    //MEMBERSHIP
    MembershipClaim = "membershipClaim",

    //ORDER
    OrderPurchase = "orderPurchase",
}

export interface ActivityModel {
    _id: string;
    client?: string;
    data;
    type: ActivityTypes;
    createdAt: Date | string;
}

export interface DigitalCurrencyTransactionActivityData {
    clientFrom: Partial<Client>;
    clientTo: Client;
    digitalCurrency: { _id: string };
    addressTo?: string;
    addressFrom?: string;
    txDetails: {
        amount: string;
        txId: string;
    };
}

export interface DigitalCurrencyExchangeActivityData {
    fromDigitalCurrency: string;
    toDigitalCurrency: string;
    amount?: string;
    exchange?: {
        name: string;
        image: string;
    };
    fee: string;
    status: "NOT_FOUND" | "INVALID" | "PENDING" | "DONE" | "FAILED" | "";
    quotationId: string;
    skeletonId: string;
    subStatus: "COMPLETED";
    amountReceived: string;
    estimatedAmount?: string;
}

export interface MembershipActivityData {
    membership: Partial<MembershipModel>;
}

export interface AssetActivityData {
    asset: Partial<AssetModel>;
    clientFrom?: Partial<Client>;
    clientTo?: Client;
}

export interface ReddeemableActivityData {
    redeemable: Partial<RedeemableModel>;
    clientFrom?: Partial<Client>;
    clientTo?: Client;
    gate?: {
        _id: string;
        name: string;
        images: Array<{ cover: string; thumbnail: string }>;
    };
}

export interface OrderActivityDara {
    _id: string;
    amount?: number;
    currency?: { digitalCurrencyId?: string; fiatCurrencyId?: string };
    gateway: {
        _id: string;
        name: string;
    };
    items?: Array<{
        type: "REDEEMABLE" | null;
        price?: number;
        quantity?: number;
        title?: string;
        data?: {
            _id: string;
            redeemableOption?: {
                type: RedeemableType;
            };
            experience: {
                _id: string;
            };
            merchant: {
                _id: string;
                name: string;
                image: { cover?: string; thumbnail?: string; carousel?: string };
            };
        };
    }>;
}
export interface OrderModel {
    createdAt: string;
    expirationDate: string;
    items: Array<OrderItemsModel>;
    merchant: MerchantModel;
    payer: string;
    payment: OrderPaymentModel;
    status: "pending" | "completed" | "canceled" | "executing" | "created";
    _id: string;
}

export interface OrderItemsModel {
    id: string;
    item: any;
    price: number;
    quantity: number;
    title: string;
    _id: string;
}

export interface OrderPaymentModel {
    amount: number;
    createdAt: string;
    externalId: string;
    externalUrl: string;
    fiatCurrency: string;
    gateway: string;
    merchant: MerchantModel;
    merchantGateway: string;
    status: "pending" | "expired" | "approved" | "rejected";
    _id: string;
}

export interface ActivityRedeemableAssignDataModel {
    _id: string;
    name: string;
    status: string;
    image: {
        carousel: string;
        cover: string;
        thumbnail: string;
    };
    merchant: {
        _id: string;
        name: string;
    };
    purcheses: {
        _id: string;
        currency: {
            digitalCurrencyId: string | null;
            fiatCurrencyId: string;
        };
        amount: number;
        items: Array<{
            id: string;
            item: { type: string; image?: { cover: string; thumbnail: string; carousel: string } };
            price: number;
            quantity: number;
            title: string;
            _id: string;
        }>;
    };
}

export interface ActivityRedeemableTransferDataModel {
    _id: string;
    name: string;
    status: string;
    experienceId: string;
    image: {
        carousel: string;
        cover: string;
        thumbnail: string;
    };
    merchant: {
        _id: string;
        name: string;
    };
    purcheses: {
        orderId: string;
        currency: {
            digitalCurrencyId: string;
            fiatCurrencyId: string;
        };
        amount: number;
        items?: [];
    };
    owner: string;
    previousOwner: string;
    direction: "RECEIVED" | string;
}
