import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: style */
const Logo = (props) => (
  <Svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    height={65}
    width={100}
    viewBox="0 0 440 240"
    style={{
      enableBackground: "new 0 0 440 240",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <G>
      <G>
        <G>
          <Path
            fill="#050326"
            d="M347.88,49.2H222.81l4.08-17.8c0.84-3.63-0.61-6.15-1.55-7.33c-1.57-1.97-3.94-3.1-6.51-3.1 c-0.2,0-0.39,0.01-0.59,0.02l-91.26,0.12l-0.6,0.1c-3.79,0.6-3.78,3.27-4.62,6.97l-37.94,178.2v0.85c0,7.39,5.75,11.8,11.31,11.8 l228.14-0.15l0.59-0.09c3.8-0.59,6.68-3.19,7.51-6.79l0.05-0.23l24.75-153.29v-0.63C356.18,52.44,351.96,49.2,347.88,49.2z"
          />
          <G>
            <Path
              fill="#FFFFFF"
              d="M125.92,181.68c7.4,0,13.74-1.35,19.01-4.03c5.28-2.69,8.73-7.65,10.36-14.89l7.83-34.06 c0.54-2.52,0.82-4.44,0.82-5.76c0-2.52-0.84-4.31-2.53-5.35c-1.69-1.04-4.54-1.56-8.57-1.56h-9.14c-1.31,0-2.23-0.11-2.77-0.33 c-0.54-0.22-0.82-0.66-0.82-1.32c0-0.55,0.05-0.99,0.16-1.32l6.85-29.78c0.22-1.1,1.6-1.64,2.36-1.64 c0.76,0,1.14,0.38,1.14,1.15c0,0,0.03,0.37,0,0.49c-1.56,6.56-7.02,29.06-7.02,29.06c0,0.55,0.22,0.82,0.65,0.82h21.31 c0.65,0,0.98-0.27,0.98-0.82l12.27-51.44c0-0.26-0.17-0.4-0.52-0.41h-48.7c-0.54,0-0.92,0.27-1.14,0.82l-12.83,55.05 c-0.33,1.43-0.49,2.8-0.49,4.11c0,2.74,0.92,4.8,2.77,6.17c1.85,1.37,4.79,2.06,8.81,2.06h9.63c1.52,0,2.28,0.55,2.28,1.65 c0,0.22-0.11,0.88-0.33,1.97l-6.85,30.61c-0.32,1.1-1.81,1.65-2.68,1.65c-0.87,0-1.14-0.55-0.82-1.65l7.18-30.38 c0-0.55-0.22-0.82-0.65-0.82h-21.31c-0.65,0-0.98,0.27-0.98,0.82l-7.18,30.21c-0.44,1.76-0.65,3.51-0.65,5.27 C104.38,177.13,111.56,181.68,125.92,181.68z"
            />
          </G>
          <Path
            fill="#FFFFFF"
            d="M342.7,60.86h-38.14c-0.46,0-0.86,0.29-1.21,0.86l-35.89,117c-0.11,0.23-0.08,0.43,0.09,0.6 c0.17,0.17,0.37,0.26,0.6,0.26h24.67c0.46,0,0.81-0.29,1.04-0.86l5.87-23.1h3.28l-5.35,23.1c-0.23,0.57,0,0.86,0.69,0.86h24.51 c0.69,0,1.03-0.29,1.03-0.86l19.33-117C343.21,61.15,343.04,60.86,342.7,60.86z M311.66,120.6l-3.97,17.95h-4.71l4.32-17.95 l8.97-35.55h2.81L311.66,120.6z"
          />
          <Path
            fill="#FFFFFF"
            d="M130.51,55.21l55.32-0.08L162.02,158.9c-0.69,3.22-1.03,6.1-1.03,8.63c0,4.95,1.7,8.54,5.09,10.79 c3.4,2.24,9.06,3.37,17,3.37c6.9,0,12.45-0.81,16.65-2.42c4.2-1.61,7.39-4.05,9.58-7.33c2.18-3.28,3.91-7.62,5.18-13.03 l22.61-97.18c0.23-0.57,0.06-0.86-0.52-0.86h-22.23c-0.58,0-0.98,0.29-1.21,0.86l-23.3,100.11c-0.23,1.15-0.8,1.72-1.72,1.72 c-0.69,0-2.81-0.35-2.81-1.03v-0.69L209.96,54.4c0.02-0.05,0.04-0.08,0.05-0.13l4.75-20.7c0.16-0.68-0.08-0.97-0.69-0.87 l-78.42,0.12c-0.62,0.1-0.99,0.43-1.12,0.99l-4.75,20.7C129.65,55.07,129.9,55.3,130.51,55.21z"
          />
          <Path
            fill="#FFFFFF"
            d="M323.29,184.79l-85.64,0.09l13.1-56.18h2.81c0.92,0,1.38,0.4,1.38,1.21c0,0.57-0.17,1.67-0.52,3.28 l-10.83,45.53c-0.23,0.57,0,0.86,0.69,0.86H263c0.46,0,0.8-0.29,1.03-0.86l10.66-45.18c0.46-1.73,0.69-3.45,0.69-5.18 c0-4.72-2.59-7.42-7.77-8.11l0.34-1.38c3.68-0.92,6.67-2.79,8.97-5.61c2.3-2.82,3.97-6.3,5-10.44l9.73-41.52 c0-0.28-0.18-0.42-0.55-0.43l-46.41,0c-0.57,0-0.98,0.29-1.21,0.86L209.74,206.6c-0.13,0.56,0.11,0.8,0.73,0.7l109.01-0.12 c0.62-0.1,0.98-0.37,1.08-0.82l3.43-20.7C324.14,184.99,323.91,184.69,323.29,184.79z M262.65,77.11h2.81 c0.92,0,1.38,0.4,1.38,1.21c0,0.23-0.11,0.81-0.34,1.73l-0.37,1.61l0,0l0,0L260.11,108c-0.23,1.38-0.55,2.36-0.95,2.94 c-0.4,0.57-1,0.86-1.81,0.86h-2.64L262.65,77.11z"
          />
        </G>
      </G>
      <G>
        <Path
          fill="#97F300"
          d="M103.14,204.91c-2.8,0-4.09-1.54-3.58-4.36l1.09-6.08c0.51-2.86,1.81-3.95,4.63-3.95h9.29 c2.82,0,4.09,1.5,3.6,4.36l-0.17,0.95h-4.58l0.06-0.37c0.16-0.99-0.14-1.36-1.13-1.36h-5.82c-0.99,0-1.34,0.29-1.5,1.27 l-0.84,4.85c-0.18,0.97,0.14,1.36,1.11,1.36h5.98c0.99,0,1.32-0.31,1.5-1.3l0.12-0.58h-4.38l0.49-2.9h8.78l-0.72,4.15 c-0.49,2.86-1.83,3.95-4.65,3.95H103.14z"
        />
        <Path
          fill="#97F300"
          d="M131.49,204.91l-0.66-2.32h-7.61l-1.48,2.32h-5.22l9.91-14.39h5.16l4.79,14.39H131.49z M125.22,199.44h4.73 l-1.46-5.16L125.22,199.44z"
        />
        <Path
          fill="#97F300"
          d="M155.53,190.52h4.13l-2.55,14.39h-4.3l1.4-7.85l-5.3,5.61h-2.4l-3.31-5.61l-1.4,7.85h-4.3l2.55-14.39h3.74 l4.5,7.79L155.53,190.52z"
        />
        <Path
          fill="#97F300"
          d="M161.95,190.52h4.62l-2.55,14.39h-4.62L161.95,190.52z"
        />
        <Path
          fill="#97F300"
          d="M181.82,190.52h4.4l-2.55,14.39h-3.7l-7.69-9.09l-1.6,9.09h-4.4l2.55-14.39h3.88l7.54,8.88L181.82,190.52z"
        />
        <Path
          fill="#97F300"
          d="M190.04,204.91c-2.8,0-4.09-1.54-3.58-4.36l1.09-6.08c0.51-2.86,1.81-3.95,4.63-3.95h9.29 c2.82,0,4.09,1.5,3.6,4.36l-0.17,0.95h-4.58l0.06-0.37c0.16-0.99-0.14-1.36-1.13-1.36h-5.82c-0.99,0-1.34,0.29-1.5,1.27 l-0.84,4.85c-0.18,0.97,0.14,1.36,1.11,1.36h5.98c0.99,0,1.32-0.31,1.5-1.3l0.12-0.58h-4.38l0.49-2.9h8.78l-0.72,4.15 c-0.49,2.86-1.83,3.95-4.65,3.95H190.04z"
        />
      </G>
    </G>
  </Svg>
);
export default Logo;
