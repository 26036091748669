import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import RegularText from "@base/RegularText";
import { HeaderType } from "@custom-types/HeaderType";
import { colors, settings } from "@styles/globalStyles";
import Wallet from "@core/wallet/Wallet";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import InlineButton from "@base/InlineButton";
import Row from "@base/Row";
import SwitchSelector from "react-native-switch-selector";
import WarningCard from "@base/WarningCard";
import RegularTextInput from "@base/RegularTextInput";
import store from "@store/index";
import { showModal, showPopup } from "@store/actions/global";
import BotCard from "@base/BotCard";
import QRBase from "@base/QRBase";
import { Route } from "@react-navigation/native";
import PressableBase from "@base/PressableBase";


interface Props {
    navigation: any;
    route: Route<
        string,
        {
            words: string[];
        }
    >;
}

interface State {
    words: string[];
    encryptedWords: string[];
    show: boolean;
    switch: boolean;
    encrypted: boolean;
    confirmPassword: boolean;
    password: string;
    confirmedPassword: string;
}

const { t } = i18n;
const CryptoJS = require("crypto-js");

export default class BackupScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            words: this.props.route?.params?.words || Wallet.getInstance().getMnemonic(),
            encryptedWords: [],
            show: false,
            switch: false,
            encrypted: false,
            confirmPassword: false,
            password: "",
            confirmedPassword: "",
        };
        this.onPress = this.onPress.bind(this);

        this.switchCurrency = this.switchCurrency.bind(this);
    }

    onPress() {
        this.setState({ show: !this.state.show });
    }

    switchCurrency() {
        this.setState({ switch: !this.state.switch });
    }

    changeInputValue(value, field) {
        this.setState(
            {
                ...this.state,
                [field]: value,
            },
            this.getEncryptedCode,
        );
    }

    getEncryptedCode = () => {
        if (
            this.state.password.length == 4 &&
            this.state.confirmedPassword.length == 4 &&
            this.state.password == this.state.confirmedPassword
        ) {
            setTimeout(() => {
                this.setState({ encrypted: true }, () => store.dispatch(showPopup({ type: "SUCCESS" })));
            }, 1000);

            const encrypetdSeed = CryptoJS.AES.encrypt(this.state.words.toString(), this.state.password).toString();
            this.setState({ encryptedWords: encrypetdSeed });
        }
        if (this.state.password.length == 4) {
            this.setState({ confirmPassword: true });
        }

        if (
            this.state.password.length == 4 &&
            this.state.confirmedPassword.length == 4 &&
            this.state.password != this.state.confirmedPassword
        ) {
            store.dispatch(showPopup({ type: "ERROR", message: t("password_error") }));
            this.setState({ confirmPassword: false, password: "", confirmedPassword: "" });
        }
    };
    render() {
        return (
            <ScreenWrapper>
                <Header title={t("backup")} type={HeaderType.Light} {...this.props} />
                <Row
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        marginBottom: 25,
                        zIndex: 99,
                    }}
                >
                    <View style={{ position: "relative" }}>
                        <SwitchSelector
                            options={[
                                { label: t("words"), value: 1 },
                                {
                                    label: t("qr"),
                                    value: 0,
                                },
                            ]}
                            initial={this.state.switch ? 1 : 0}
                            value={this.state.switch ? 1 : 0}
                            height={35}
                            style={{
                                zIndex: 99,
                                width: 220,
                                marginHorizontal: "auto",
                                alignSelf: "center",
                            }}
                            onPress={() => null}
                            textColor={colors.text}
                            selectedColor={colors.white}
                            buttonColor={colors.secondary}
                            borderColor={colors.secondary}
                            animationDuration={250}
                            backgroundColor={colors.shadow}
                        />
                        <PressableBase
                            onPress={this.switchCurrency}
                            style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 99 }}
                        ></PressableBase>
                    </View>
                </Row>
                {this.state.switch ? (
                    <Container>
                        {!this.state.encrypted && (
                            <View>
                                {this.state.confirmPassword ? (
                                    <View>
                                        <BotCard title={t("important")} message={t("info_confirm_password")} />
                                        <RegularTextInput
                                            key={"confirmedPassword"}
                                            secureTextEntry={true}
                                            maxLength={4}
                                            multiline={false}
                                            align={"center"}
                                            onChangeText={(text) => this.changeInputValue(text, "confirmedPassword")}
                                            value={this.state.confirmedPassword}
                                            placeholder={t("confirm_password")}
                                            style={{
                                                backgroundColor: colors.shadow,
                                                padding: 10,
                                                overflow: "hidden",
                                                borderRadius: settings.cardRadius,
                                                marginTop: 10,
                                            }}
                                        />
                                    </View>
                                ) : (
                                    <View>
                                        <BotCard message={t("warning_encrypted")} />
                                        <RegularTextInput
                                            key={"password"}
                                            secureTextEntry={true}
                                            maxLength={4}
                                            multiline={false}
                                            align={"center"}
                                            onChangeText={(text) => this.changeInputValue(text, "password")}
                                            value={this.state.password}
                                            placeholder={t("type_a_password")}
                                            style={{
                                                backgroundColor: colors.shadow,
                                                padding: 10,
                                                overflow: "hidden",
                                                borderRadius: settings.cardRadius,
                                                marginTop: 10,
                                            }}
                                        />
                                    </View>
                                )}
                            </View>
                        )}
                        {this.state.encrypted && (
                            <View>
                                <WarningCard message={t("warning_QR")} />
                                <View style={styles.qr}>
                                    <QRBase value={this.state.encryptedWords.toString()} size={200} />
                                </View>
                            </View>
                        )}
                    </Container>
                ) : (
                    <View style={{ flex: 1 }}>
                        <Container style={{ marginBottom: 20 }}>
                            <BotCard title={`${t("warning")}!`} message={t("warning_seed")} />
                        </Container>
                        {this.state.show ? (
                            <Container style={styles.container}>
                                {this.state.words.map((word, key) => {
                                    return (
                                        <RegularText style={styles.word} align="center" key={key}>
                                            {word}
                                        </RegularText>
                                    );
                                })}
                            </Container>
                        ) : (
                            <Container style={styles.container}>
                                {this.state.words.map((word, key) => {
                                    return (
                                        <RegularText style={styles.word} align="center" key={key}>
                                            {word.split("").map((w) => {
                                                return "•";
                                            })}
                                        </RegularText>
                                    );
                                })}
                            </Container>
                        )}

                        <Container style={{ marginBottom: 20 }}>
                            <InlineButton
                                onPress={this.onPress}
                                title={this.state.show ? t("hide_seed") : t("show_seed")}
                            />
                        </Container>
                    </View>
                )}
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "flex-start",
        justifyContent: "center",
    },
    word: {
        padding: 10,
        borderRadius: 10,
        backgroundColor: colors.shadow,
        margin: 5,
        overflow: "hidden",
    },
    qr: {
        flexDirection: "column",
        alignItems: "center",
        marginVertical: 20,
    },
});
