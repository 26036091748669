import Wallet from "@core/wallet/Wallet";
import CurrencyImplementation, { Skeleton } from "../../CurrencyImplementation";
import { PathNode } from "@core/utils/PathNode";
import * as bitcoin from "bitcoinjs-lib";
import { format } from "date-fns";
import HDNode from "hdkey";

const BIP84 = require("bip84");
const b58 = require("bs58check");
const bitcoinNetworks = { mainnet: bitcoin.networks.bitcoin, testnet: bitcoin.networks.testnet };
const bitcoinPubTypes = {
    mainnet: { zprv: "04b2430c", zpub: "04b24746" },
    testnet: { vprv: "045f18bc", vpub: "045f1cf6" },
};

function b58Encode(pub, data) {
    let payload = b58.decode(pub),
        key = payload.slice(4);

    return b58.encode(Buffer.concat([Buffer.from(data, "hex"), key]));
}

export default class BTCImplementation extends CurrencyImplementation {
    getSegWitChild() {
        const path = this.currency.getCurrentPath("84");
        const keypath = path.split("/").slice(0, 4).join("/").replace("''", "'");
        const account = bitcoin.bip32
            .fromSeed(this.currency.getSeed(), this.currency.getNetwork())
            .derivePath(keypath)
            .toBase58();
        return this.currency.isTestnet()
            ? b58Encode(account, bitcoinPubTypes.testnet.vprv)
            : b58Encode(account, bitcoinPubTypes.mainnet.zprv);
    }

    generateAddress(addressNode: PathNode, options?: { format?: string }) {
        if (options && options.format == "SEGWIT") {
            const child0 = this.getSegWitChild();
            const account0 = new BIP84.fromZPrv(child0);
            return account0.getAddress(Number(this.currency.getIndex()));
        }

        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        const keyPair = bitcoin.ECPair.fromPrivateKey(privateKey, { network: this.currency.getNetwork() });
        const { address } = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: this.currency.getNetwork() });
        return address;
    }

    getKeys(addressNode: PathNode) {
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        const keyPair = bitcoin.ECPair.fromPrivateKey(privateKey, { network: this.currency.getNetwork() });
        const { pubkey } = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: this.currency.getNetwork() });

        return { publicKey: pubkey.toString("hex"), privateKey: privateKey.toString("hex") };
    }

    async signTransaction(addressNode: PathNode, skeleton) {
        const rawTx = skeleton?.transactionData;
        const network = this.currency.isTestnet() ? bitcoin.networks.testnet : bitcoin.networks.bitcoin;
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, network.bip32).privateKey;
        const keyPair = bitcoin.ECPair.fromPrivateKey(privateKey, { network: network });

        const child0 = this.getSegWitChild();
        const account0 = new BIP84.fromZPrv(child0);
        const segWitKeyPair = account0.getKeypair(Number(this.currency.getIndex()));
        const p2wpkh = bitcoin.payments.p2wpkh({ pubkey: segWitKeyPair.publicKey, network: network });
        const p2sh = bitcoin.payments.p2sh({ redeem: p2wpkh, network });
        const redeemScript = p2sh.redeem.output;

        const tx = new bitcoin.TransactionBuilder(network);

        for (let key in rawTx?.inputs) {
            const input = rawTx?.inputs[key];
            const address = input.address || "";
            if (address.startsWith("bc") || address.startsWith("tb")) {
                tx.addInput(input.txid, input.vout, null, redeemScript);
            } else {
                tx.addInput(input.txid, input.vout);
            }
        }
        for (let key in rawTx?.outputs) {
            const output = rawTx?.outputs[key];
            if (output.address) {
                tx.addOutput(output.address, Math.round(output.value));
            } else {
                tx.addOutput(rawTx?.inputs[0].address, Math.round(output.value));
            }
        }
        for (let key in rawTx?.inputs) {
            const address = rawTx?.inputs[key].address || "";
            if (address.startsWith("bc") || address.startsWith("tb")) {
                tx.sign(Number(key), segWitKeyPair, null, null, rawTx?.inputs[key].value);
            } else {
                tx.sign(Number(key), keyPair);
            }
        }
        return tx.build().toHex();
    }

    parseSkeleton(skeletonData): Skeleton {
        /*const value = this.currency.fromDecimals(skeleton.transactionData?.outputs[0]?.value) || 0;
        const from = skeleton.transactionData?.inputs[0]?.address || "";
        const to = skeleton.transactionData?.outputs[0]?.address || "";
        const fee = this.currency.fromDecimals(skeleton.feeData?.fee) || 0;
        const swapAmount = skeleton.extra ? skeleton.extra.swapAmount : 0;

        return {
            amount: value,
            sendingTo: to,
            sendingFrom: from,
            fee: fee,
            swapAmount: swapAmount,
        };*/
        
        const amount = this.currency.fromDecimals(
            skeletonData.transactionData?.outputs[0]?.value
        );
        const sendingFrom = skeletonData?.transactionData?.inputs[0]?.address ||  "";
        const sendingTo = skeletonData.transactionData?.outputs[0]?.address || "";
        const underlyingCurrency = this.currency.getUnderlyingCurrency();

        const feeData = {
            amount: underlyingCurrency.fromDecimals(skeletonData.feeData?.amount) || 0,
            digitalCurrencyId: underlyingCurrency?.getId(),
        };

        const exchangeData = {
            fromAmount: this.currency.fromDecimals(skeletonData?.extraTransactionData?.value || 0),
            toCurrency: skeletonData?.extraTransactionData?.toCurrency || '',
            toAmount: Wallet.getInstance().findCurrencyById(skeletonData?.extraTransactionData?.toCurrency)?.fromDecimals(skeletonData?.extraTransactionData?.toAmount || 0),
            exchangeFeeData:
                skeletonData?.exchangeFeeData?.map((s) => {
                    const currency = Wallet.getInstance().findCurrencyById(s?.digitalCurrencyId);
                    if (!currency) return
                    return {
                        amount: currency.fromDecimals(s?.amount || 0),
                        digitalCurrencyId: s?.digitalCurrencyId || "",
                        provider: s?.provider || "",
                    };
                }) || [],

            exchangeProvider: {
                name: skeletonData?.extraTransactionData?.toolDetails?.name || "",
                image: skeletonData?.extraTransactionData?.toolDetails?.logoURI || "",
            },
        };



        const skeleton: Skeleton = {
            amount: amount,
            sendingFrom: sendingFrom,
            sendingTo: sendingTo,
            feeData: feeData,
            exchangeData: exchangeData,
        };

        return skeleton;
    }

    isValidAddress(address: string) {
        try {
            bitcoin.address.toOutputScript(address, this.currency.getNetwork());
            return true;
        } catch (err) {
            return false;
        }
    }

    getFormats() {
        return ["SEGWIT", "LEGACY"];
    }
}
