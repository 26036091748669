import { IStorage } from "./IStorage";

export default class WebStorage implements IStorage {

    getItem(key: string): Promise<string> {
        try {
            return Promise.resolve(JSON.parse(localStorage.getItem(key)))
        } catch (err) {
            //console.warn(err);
        }
    }

    setItem(key: string, object: Object): Promise<void> {
        return Promise.resolve(localStorage.setItem(key, JSON.stringify(object)));
    }

    async removeItem(key: string): Promise<any> {
        try {
            return await Promise.resolve(localStorage.removeItem(key));
        } catch (err) {
            //console.warn(err);
        }
    }

}