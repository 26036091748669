import BackupScreen from '@screens/settings/seed/BackupScreen';
import ResetSeedScreen from '@screens/settings/seed/ResetSeedScreen';
import HistorySeedScreen from '@screens/settings/seed/HistorySeedScreen';
import SeedScreen from '@screens/settings/seed/SeedScreen';
import ImportScreen from '@screens/settings/seed/import/ImportScreen';
import NavigationInstantiator from './helpers/NavigationInstantiator';
import ScannerSeedScreen from '@screens/scanner/ScannerSeedScreen';
import AccountsScreen from '@screens/accounts/AccountsScreen';



const AccountsNavigatorScreens = {
    AccountsMain: {
        routeName: 'AccountsMain',
        title: 'AccountsMain',
        component: AccountsScreen
    },
    SeedPhrase: {
        routeName: 'SeedPhrase',
        title: 'SeedPhrase',
        component: BackupScreen
    }
    
}

const AccountsNavigator = NavigationInstantiator(AccountsNavigatorScreens)

export {AccountsNavigator, AccountsNavigatorScreens}
