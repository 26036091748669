import { Platform } from "react-native";
import { IStorage } from "./implementations/IStorage";
import NativeStorage from "./implementations/NativeStorage";
import WebStorage from "./implementations/WebStorage";

interface IOpt {
    isSecure?: boolean,
    oldKey?: string
}

export class DeprecatedStorage {
    private version = "";
    static instance: DeprecatedStorage;
    private implementation: IStorage;

    constructor() {
        this.implementation = Platform.OS === 'web' ? new WebStorage() : new NativeStorage();
    }

    static getInstance() {
        if (!DeprecatedStorage.instance) {
            DeprecatedStorage.instance = new DeprecatedStorage();
        }
        return DeprecatedStorage.instance
    }

    getItem = async (key: string, options?: IOpt) => {
        const isSecure = !(options?.isSecure === false);
        const current = await this.implementation.getItem(`${key}`, { isSecure });
        const old = await this.implementation.getItem(options?.oldKey || key, { isSecure });
        return current || old;
    }

    setItem = (key: string, item: any, options?: IOpt) => {
        const isSecure = !(options?.isSecure === false);
        return this.implementation.setItem(`${key}`, item, { isSecure });
    }

    removeItem = (key: string, options?: IOpt) => {
        const isSecure = !(options?.isSecure === false);
        return this.implementation.removeItem(`${key}`, { isSecure });
    }
}