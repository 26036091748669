import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { FlatList, StyleSheet, View, ViewStyle } from "react-native";
import { colors } from "@styles/globalStyles";
import { connect } from "react-redux";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import { Chat } from "@custom-types/Chat";
import { Message } from "@custom-types/Message";
import { selectMessagesFromChat, selectOrderedAndFilteredChats } from "@store/reducers/chat.reducer";
import store from "@store/index";
import { getUnreadMessagesCount } from "@utils/helpers/chat/chat.helper";
import AvatarBase from "@components/avatar/AvatarBase";
import { loading, ready, showPopup, showPopupMessage } from "@store/actions/global";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import Badge from "@base/Badge";
import { VibrationType, vibrate } from "@utils/vibration/vibration.helper";
import Placeholder from "@base/Placeholder";
import Row from "@base/Row";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    chats: Array<Chat>;
    unread: number;
    initializing: boolean;
}

interface State {}

const { t } = i18n;

export class _MessagesSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.renderItem = this.renderItem.bind(this);
        this.navigateToSection = this.navigateToSection.bind(this);
        this.openChat = this.openChat.bind(this);

        this.state = {};
    }

    navigateToSection() {
        this.props.navigation.navigate(TabsNavigatorScreens.Messages.routeName);
    }

    async openChat(client_id) {
        try {
            store.dispatch(loading());
            vibrate(VibrationType.ONPRESS);
            var status = await SocialNetworkService.getInstance().contactUser(client_id);
            if (status == "redirect") {
                this.props.navigation.navigate("Messages", {
                    screen: "Chat",
                });
            }

            store.dispatch(ready());
        } catch (e) {
            store.dispatch(ready());
            store.dispatch(
                showPopupMessage({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
        }
    }

    renderItem = ({ item }) => {
        const messages: Array<Message> = selectMessagesFromChat(store.getState(), item.id);
        const unread = getUnreadMessagesCount(messages) || 0;
        // return unread > 0 ? (
        return (
            <View style={{ paddingRight: 18, position: "relative" }}>
                <AvatarBase
                    size={70}
                    alias={item?.to?.alias}
                    uri={item?.to?.profileImagePath?.thumbnail || ""}
                    onPress={() => this.openChat(item?.to?._id || "")}
                ></AvatarBase>
                <Badge
                    size={10}
                    count={unread}
                    color={colors.complementary || colors.secondary}
                    containerSyle={{ right: 8, bottom: 5, minWidth: 25, }}
                    smallBadgeContainer={{ right: 6, bottom: 6 }}
                    badgeStyle={{
                        borderWidth: 2,
                        borderRadius: 12,
                        paddingHorizontal: 6,
                        paddingBottom: 2,
                        paddingTop: 2,
                        borderColor: colors.tertiary,
                    }}
                    smallBadgeStyle={{
                        borderWidth: 2,
                        borderRadius: 6,
                        overflow: "hidden",
                        height: 12,
                        width: 12,
                        borderColor: colors.tertiary,
                    }}
                ></Badge>
            </View>
        );
    };

    render() {
        return (
            <>
                {this.props.initializing ? (
                    <View style={{paddingTop: 10}}>
                        <Placeholder width={"45%"} height={15} />
                        <Row style={{ justifyContent: "flex-start" }}>
                            <Placeholder width={70} height={70} radius={35} />
                            <Placeholder width={70} height={70} radius={35} />
                            <Placeholder width={70} height={70} radius={35} />
                            <Placeholder width={70} height={70} radius={35} />
                        </Row>
                    </View>
                ) : (
                    <>
                        {this.props.chats?.length > 0 && (
                            <View style={this.props.containerStyle}>
                                <SectionTitle
                                    onPress={this.navigateToSection}
                                    title={t("chats")}
                                    icon={"📫"}
                                    // counter={this.props.chats?.length ? (this.props.chats?.length > 9 ? "+9" : this.props.chats?.length ) : 0}
                                    seeMore={false}
                                    {...this.props}
                                ></SectionTitle>

                                <FlatList
                                    showsHorizontalScrollIndicator={false}
                                    data={this.props.chats}
                                    renderItem={this.renderItem}
                                    keyExtractor={(item) => item._id}
                                    contentContainerStyle={{ paddingTop: 10, zIndex: 99 }}
                                    initialNumToRender={2}
                                    maxToRenderPerBatch={2}
                                    horizontal={true}
                                ></FlatList>
                            </View>
                        )}
                    </>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        unread: state.chat.unread,
        initializing: state.chat?.initializing,
        chats: selectOrderedAndFilteredChats(state),
    };
};

const mapDispatchToProps = (dispatch) => ({});

const MessagesSection = connect(mapStateToProps, mapDispatchToProps)(_MessagesSection);

export default MessagesSection;
