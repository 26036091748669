import React, { Component } from "react";
import i18n from "@i18n/i18n";
import { View, StyleSheet, ScrollView, Platform, Animated } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import InlineButton from "@base/InlineButton";
import BotCard from "@base/BotCard";
// import RNDateTimePicker from "@react-native-community/datetimepicker";

import NationalitySection from "./NationalitySection";
import BoldText from "@base/BoldText";
import { getColorOpacity } from "@utils/helpers/global/global";
import FloatingTextInput from "@base/FloatingTextInput";
import Icon from "@components/icons";
import PressableBase from "@base/PressableBase";

const { t } = i18n;

interface PersonalInformation {
    nationality: string;
    name: string;
    lastname: string;
    birthdate?: Date;
    address: string;
    zipCode: string;
    city: string;
}

interface Props {
    personalInformation: PersonalInformation;
    errorMessage: string;
    validate: () => void;
    changeInputValue: (value: string | Date, field: string) => void;
    nextStep: () => void;
}

interface State {
    nationalityOpen: boolean;
    date: Date;
    showRNDateTimePicker: boolean;
    isFocused: boolean;
}

export default class StepOneKYC extends Component<Props, State> {
    camera: any;
    private _animatedIsFocused: Animated.Value;

    constructor(props: Props) {
        super(props);
        this.state = {
            date: null,
            nationalityOpen: false,
            showRNDateTimePicker: false,
            isFocused: false,
        };
    }

    handleDateChange = (_: any, selectedDate?: Date) => {
        if (selectedDate) {
            this.setState({ showRNDateTimePicker: !this.state.showRNDateTimePicker });
        }
        this.props.changeInputValue(selectedDate, "birthdate")
    };

    handleNationlaityChange = (text: string) => {
        this.setState({ nationalityOpen: false });
        this.props.changeInputValue(text, "nationality");
    };

    render() {
        return (
            <View style={{ flex: 1 }}>
                {!this.state.nationalityOpen ? (
                    <View style={{ flex: 1 }}>
                        <RegularText align={"center"} style={{ marginTop: 16, marginBottom: 10 }}>
                            {t("personal_information")}
                        </RegularText>
                        <View style={{ flex: 1 }}>
                            <ScrollView>
                                <FloatingTextInput
                                    value={this.props.personalInformation.name}
                                    label={t("name")}
                                    onChangeText={(text) => this.props.changeInputValue(text, "name")}
                                    multiline={false}
                                />
                                <FloatingTextInput
                                    value={this.props.personalInformation.lastname}
                                    label={t("lastname")}
                                    onChangeText={(text) => this.props.changeInputValue(text, "lastname")}
                                    multiline={false}
                                />
                                {/* <View style={styles.inputContainer}>
                                    <RegularText style={styles.placeholder}>{t("birthdate")}</RegularText>
                                    <PressableBase
                                        style={{ flex: 1, justifyContent: "center", alignItems: "center", margin: 10 }}
                                        onPress={() =>
                                            this.setState({ showRNDateTimePicker: !this.state.showRNDateTimePicker })
                                        }
                                    >
                                        {
                                            Platform.OS == "ios" && (
                                                <RNDateTimePicker
                                                    value={this.props.personalInformation.birthdate !== null ? this.props.personalInformation.birthdate : new Date()}
                                                    mode="date"
                                                    onChange={this.handleDateChange}
                                                    display="default"
                                                    textColor={colors.text}
                                                />
                                            )
                                        }
                                        {
                                            Platform.OS == "android" && (
                                                <View>
                                                    {this.state.showRNDateTimePicker && (
                                                        <RNDateTimePicker
                                                            value={this.props.personalInformation.birthdate !== null ? this.props.personalInformation.birthdate : new Date()}
                                                            mode="date"
                                                            onChange={this.handleDateChange}
                                                            display="default"
                                                            textColor={colors.text}
                                                        />
                                                    )}
                                                    {this.props.personalInformation.birthdate !== null && (
                                                        <RegularText
                                                            style={{
                                                                backgroundColor: getColorOpacity(colors.grey, 0.3),
                                                                paddingHorizontal: 10,
                                                                paddingBottom: 2,
                                                                borderRadius: 5,
                                                                overflow: "hidden",
                                                            }}
                                                            align="left"
                                                        >
                                                            {this.props.personalInformation.birthdate?.getDate() + " " + this.props.personalInformation.birthdate?.toDateString().split(" ")[1] + " " + this.props.personalInformation.birthdate?.getFullYear()}
                                                        </RegularText>)}
                                                </View>
                                            )
                                        }

                                    </PressableBase>
                                </View> */}
                                <View style={styles.inputContainer}>
                                    <RegularText fontSize={10} style={styles.placeholder}>
                                        {t("nationality")}
                                    </RegularText>
                                    <PressableBase
                                        style={[styles.input, styles.inputFlex]}
                                        onPress={() => this.setState({ nationalityOpen: true })}
                                    >
                                        <RegularText>
                                            {this.props.personalInformation.nationality.length
                                                ? this.props.personalInformation.nationality
                                                : t("select_country")}
                                        </RegularText>
                                        <Icon name="arrow-down" color={colors.text} />
                                    </PressableBase>
                                </View>
                                <FloatingTextInput
                                    value={this.props.personalInformation.address}
                                    label={t("address")}
                                    onChangeText={(text) => this.props.changeInputValue(text, "address")}
                                    multiline={false}
                                />
                                <View style={{ flex: 1, justifyContent: "space-between", flexDirection: "row" }}>
                                    <FloatingTextInput
                                        value={this.props.personalInformation.city}
                                        label={t("city")}
                                        onChangeText={(text) => this.props.changeInputValue(text, "city")}
                                        style={{ marginTop: 0, marginRight: 10, flex: 1 }}
                                        multiline={false}
                                    />
                                    <FloatingTextInput
                                        value={this.props.personalInformation.zipCode}
                                        label={t("zip_code")}
                                        onChangeText={(text) => this.props.changeInputValue(text, "zipCode")}
                                        style={{ marginTop: 0, flex: 1 }}
                                        multiline={false}
                                    />
                                </View>
                                <View>
                                    <View
                                        style={
                                            this.props.errorMessage.length ? styles.errorMessage : { display: "none" }
                                        }
                                    >
                                        <BotCard title={t("warning")} message={this.props.errorMessage} />
                                    </View>
                                    <InlineButton
                                        title={t("next")}
                                        onPress={() => this.props.validate()}
                                        style={{ marginVertical: 15, marginHorizontal: 0 }}
                                    />
                                </View>
                            </ScrollView>
                        </View>
                    </View >
                ) : (
                    <NationalitySection nationalitySelected={this.handleNationlaityChange} />
                )
                }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    inputContainer: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        minHeight: 65,
        marginBottom: 10,
    },
    input: {
        flex: 1,
        paddingHorizontal: 20,
        fontSize: 16,
        color: colors.text,
        textAlign: "left",
        fontFamily: "NunitoRegular",
        marginTop: 5
    },
    inputFlex: {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row"
    },
    errorMessage: {
        marginTop: 10,
    },
    dropdown: {
        height: Platform.OS === "ios" && 100,
        ...Platform.select({
            ios: {},
            android: {},
            web: {
                flex: 1,
                paddingVertical: 10,
                paddingHorizontal: 20,
                marginRight: 10,
                backgroundColor: "transparent",
                border: "none",
                fontSize: 16,
                fontFamily: "NunitoRegular",
                color: colors.text,
            },
        }),
    },
    item: {
        fontSize: 16,
        height: Platform.OS === "ios" && 100,
    },
    placeholder: {
        color: colors.grey,
        paddingTop: 8,
        paddingBottom: 2,
        paddingHorizontal: 20,
        fontSize: 10
    },
});
