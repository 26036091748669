import Container from "@base/Container";
import { Platform, StyleSheet, Text, View } from "react-native";
import { SimpleLineIcons } from "@expo/vector-icons";
import { colors } from "@styles/globalStyles";
import RegularTextInput from "@base/RegularTextInput";
import React from "react";
import { SerializedMessage } from "@custom-types/SerializedMessage";
import store from "@store/index";

const styles = StyleSheet.create({
    tickBox: {
        margin: 0,
        padding: 0,
        paddingRight: 7,
        marginTop: -2
    },
    viewTick: {
        flex: 1,
        flexDirection: 'row',
        alignContent: 'flex-start'
    },
    tick: {
        fontSize: 15,
        zIndex: 10,
        color: colors.white
    },
    secondTick: {
        marginLeft: -7,
        zIndex: 1
    },
    viewedTick: {
        color: '#2a9bfc'
    }
});


export function ChatTicks(props) {
    const message: SerializedMessage = props.message;
    const isAuthor = message.author.toString() === store.getState().auth.client?._id.toString();

    return (
        isAuthor ?
            <View style={styles.tickBox}>
                {message.pending &&
                    <SimpleLineIcons
                        name={'clock'}
                        size={12}
                        color={colors.text}
                    />
                }
                {message.sent &&
                    <Text style={styles.tick}>✓</Text>
                }
                {message.received &&
                    <View style={styles.viewTick}>
                        <Text style={styles.tick}>✓</Text>
                        <Text style={[styles.tick, styles.secondTick]}>✓</Text>
                    </View>
                }
                {message.read &&
                    <View style={styles.viewTick}>
                        <Text style={[styles.tick, styles.viewedTick]}>✓</Text>
                        <Text style={[styles.tick, styles.secondTick, styles.viewedTick]}>✓</Text>
                    </View>
                }
            </View>
            : null
    )
}