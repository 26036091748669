import React, { Component } from "react";
import { View, StyleSheet, Platform, ViewStyle, TextStyle } from "react-native";
import { BoldText } from "@base/BoldText";
import { getColorOpacity } from "./../../utils/helpers/global/global";
import i18n from "@i18n/i18n";
import Currency from "@core/currencies/Currency";
import { colors } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import CurrencyIcon from "./CurrencyIcon";

interface Props {
    currency: Currency;
    styles?: ViewStyle | TextStyle;
    containerStyles?: ViewStyle;
    fontSize?: number;
}

const { t } = i18n;

export default class NetworkCurrencySymbol extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <View style={[this.props.containerStyles, { paddingBottom: 2 }]}>
                {this.props.currency && this.props.currency.getNetworkSymbol() && (
                    <Row
                        style={[
                            styles.implementationSign,
                            this.props.styles,
                            {
                                backgroundColor: getColorOpacity(
                                    this.props.currency.getUnderlyingCurrency()?.getColor(),
                                    0.4,
                                ),
                            },
                        ]}
                    >
                        <CurrencyIcon
                            styles={{ marginRight: 4 }}
                            size={this.props.fontSize ? this.props.fontSize * 1.5 : 12}
                            currency={this.props.currency.getUnderlyingCurrency()}
                        ></CurrencyIcon>
                        <BoldText color={colors.networkSymbol} fontSize={this.props.fontSize || 8}>
                            {this.props.currency.getNetworkSymbol()?.toUpperCase()}
                        </BoldText>
                    </Row>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    implementationSign: {
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        textAlignVertical: "center",
        overflow: "hidden",
        marginLeft: 5,
        paddingLeft: 3,
        paddingRight: 6,
        paddingVertical: 2,
        borderRadius: 10,
        // marginTop: Platform.OS == "android" ? 2 : Platform.OS == "ios" ? 4 : 0,
    },
});
