import { _SendScreen } from "@screens/wallet/send/SendScreen";
import { connect } from "react-redux";
import { _ConfirmSendScreen } from "@screens/wallet/send/ConfirmSendScreen";
import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator";
import { CoincaexSell } from "../../helper/coincaex.helper";
import { _CoincaexAddBankScreen } from "../CoincaexAddBankScreen";
import store from "@store/index";
import { showSnackbar } from "@store/actions/global";
import i18n from "@i18n/i18n";

const { t } = i18n;

export class _CoincaexSellAddBankScreen extends _CoincaexAddBankScreen {
    constructor(props: any) {
        super(props);
    }

    onSuccess = () => {
        const sellData: CoincaexSell = {
            ...this.props.route?.params?.data,
        }

        this.props.navigation.navigate(CoincaexNavigatorScreens.SellBanks.routeName, {
            data: sellData,
        });
        store.dispatch(showSnackbar({ type: "SUCCESS", message: t('saved_succesfuly') }));
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({});

const CoincaexSellAddBankScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexSellAddBankScreen);

export default CoincaexSellAddBankScreen;
