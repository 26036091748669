import React, { Component } from "react";
import { StyleSheet, FlatList, RefreshControl } from "react-native";
import { connect } from "react-redux";
import { colors } from "@styles/globalStyles";
import { Client } from "@custom-types/Client";
import SocialHeader from "./components/SocialHeader";
import Post from "@custom-types/Post";
import { NavigationType } from "@custom-types/NavigationType";
import SocialNetworkService from "./service/SocialNetworkService";
import PostSmallCard from "./components/PostSmallCard";
import Container from "@base/Container";
import store from "@store/index";
import { addDiscoverPosts, setDiscoverPosts } from "@store/actions/social.actions";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { SocialNetworkNavigatorScreens } from "@navigation/SocialNetworkNavigator";
import { loading, ready, showModalBottom } from "@store/actions/global";
import { FloatButton } from "@base/FloatButton";
import { ModuleControlService } from "@core/services/ModuleControlService";
import PostCard from "./components/PostCard";

interface Props {
    client: Client;
    navigation: NavigationType;
    route: any;
    discoverPosts: Array<Post>;
}

interface State {
    syncing: boolean;
    smallStyle: boolean;
    selectedPost: Post;
    hashtagSearch: string;
}

const { t } = i18n;

export class _DiscoverScreen extends Component<Props, State> {
    socialNetworkService: SocialNetworkService;
    focusListener: any;
    constructor(props: Props) {
        super(props);
        this.socialNetworkService = SocialNetworkService.getInstance();
        this.state = {
            syncing: false,
            selectedPost: null,
            smallStyle: true,
            hashtagSearch: this.props.route?.params ? this.props.route.params.hashtag : "",
        };
        this.onRefresh = this.onRefresh.bind(this);
        this.loadMore = this.loadMore.bind(this);

        this.openPorfile = this.openPorfile.bind(this);
    }

    componentDidMount = async () => {
        this.setState({ smallStyle: true });
        if (this.state.hashtagSearch) {
            var posts = await this.socialNetworkService.searchHashtags(this.state.hashtagSearch, 1);
            if (posts) {
                store.dispatch(addDiscoverPosts(posts.docs));
            }
        } else {
            store.dispatch(loading());
            var posts = await this.socialNetworkService.getPostsDiscover();
            store.dispatch(ready());
        }
    };

    componentWillUnmount() {
        try {
            this.focusListener.remove();
        } catch (e) { }
    }

    onFollow = async (clientID) => {
        const follow = await this.socialNetworkService.followUser(clientID);

        if (follow) {
            const posts = this.props.discoverPosts;
            posts.map((item) => {
                if (item.author.clientID == clientID) {
                    return (item.author.follow = !item.author.follow);
                }
            });

            store.dispatch(setDiscoverPosts(posts));
            this.forceUpdate();
        }
    };

    renderItem = ({ item }) => (
        <PostSmallCard
            isLikeable={true}
            highlightedHashtag={true}
            openPost={(post) => this.openPost(post)}
            post={item}
            smallAvatar={true}
            navigation={this.props.navigation}
        />
    );

    onRefresh = async () => {
        this.setState({ syncing: true });
        var posts = await this.socialNetworkService.getPostsDiscover();
        if (posts) {
            store.dispatch(setDiscoverPosts(posts.docs));
            setTimeout(() => this.setState({ syncing: false }), 500);
        }
    };

    loadMore = async () => {
        var posts = await this.socialNetworkService.getPostsDiscover();
    };


    openPost = (post) => {
        store.dispatch(
            showModalBottom({
                modalContent: (
                    <PostCard
                        isLikeable={true}
                        post={post}
                        navigation={this.props.navigation}
                        modalView={true}
                    />
                ),
            }),
        );
    };

    openHashtag = async (hashtag) => {
        var posts = await this.socialNetworkService.searchHashtags(hashtag, 1);

        if (posts) {
            store.dispatch(setDiscoverPosts(posts.docs));
            this.setState({ syncing: false });
        }
    };

    openPorfile() {
        // this.props.navigation.navigate(SocialNetworkNavigatorScreens.SocialNetworkProfile.routeName, {
        //     backTo: SocialNetworkNavigatorScreens.SocialNetworkMain.routeName,
        // });
    }

    render() {
        return (
            <ScreenWrapper>
                <SocialHeader navigation={this.props.navigation} client={this.props.client} {...this.props} />

                <Container style={{ paddingHorizontal: 0, flex: 1 }}>
                    <FlatList
                        data={this.props.discoverPosts}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item.id}
                        contentContainerStyle={{ paddingBottom: 20, paddingHorizontal: 10 }}
                        numColumns={2}
                        initialNumToRender={4}
                        maxToRenderPerBatch={10}
                        onScrollToIndexFailed={(info) => {
                            const wait = new Promise((resolve) => setTimeout(resolve, 500));
                            wait.then(() => {
                                return;
                            });
                        }}
                        refreshControl={
                            <RefreshControl
                                tintColor={colors.text}
                                onRefresh={this.onRefresh}
                                refreshing={this.state.syncing}
                            />
                        }
                        onEndReachedThreshold={1}
                        extraData={this.props.discoverPosts}
                        onEndReached={this.loadMore}
                    />
                    {ModuleControlService.getInstance().isNecessaryUserVerified(this.props.client?.verified) && (
                        <FloatButton
                            onPress={() =>
                                this.props.navigation.navigate(SocialNetworkNavigatorScreens.NewPost.routeName)
                            }
                            iconName={"plus"}
                        />
                    )}
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        discoverPosts: state.social.discoverPosts,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const DiscoverScreen = connect(mapStateToProps, mapDispatchToProps)(_DiscoverScreen);

export default DiscoverScreen;

const styles = StyleSheet.create({});
