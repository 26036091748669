import React, { Component } from "react";
import { View, StyleSheet, Text, InteractionManager } from "react-native";
import RegularText from "@base/RegularText";
import { BoldText } from "@base/BoldText";

import Currency from "@core/currencies/Currency";
import Card from "@base/Card";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import Icon from "@components/icons";
import { colors } from "@styles/globalStyles";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import i18n from "@i18n/i18n";
import CurrencyIcon from "@components/accessories/CurrencyIcon";

interface Props {
    address?: string;
    amount?: string;
    currencyName?: string;
    transaction?: string;
    currency: Currency;
    // fiatCurrency: FiatCurrency
}

interface State {
    onPress: () => void;
}

const { t } = i18n;

export default class CustomMessageCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): void {}
    render() {
        const currency = this.props.currency;
        return (
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingHorizontal: 10,
                    backgroundColor: "transparent",
                }}
            >
                <View>
                    <View style={{ position: "absolute", top: 2, right: 2 }}>
                        <NetworkCurrencySymbol
                            containerStyles={{ alignItems: "flex-end" }}
                            styles={{
                                paddingVertical: 4,
                                paddingHorizontal: 8,
                            }}
                            fontSize={8}
                            currency={this.props.currency}
                        ></NetworkCurrencySymbol>
                    </View>
                    <View style={{ paddingTop: 8 }}>
                        <View style={styles.leftTitle}>
                            <CurrencyIcon size={40} styles={{marginRight: 10}} currency={currency} />
                            <BoldText fontSize={16}>{this.props.currencyName}</BoldText>
                        </View>
                        <RegularText style={styles.leftDesc} fontSize={10}>
                            {t("address")}
                        </RegularText>
                        <RegularText fontSize={10}>{this.props.address}</RegularText>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    icon: {
        width: 40,
        height: 40,
        marginRight: 10,
        borderRadius: 20,
        alignItems: "center",
        justifyContent: "center",
    },
    left: {
        display: "flex",
    },

    leftTitle: {
        marginTop: 2,
        flexDirection: "row",
        alignItems: "center",
    },
    leftDesc: {
        paddingTop: 10,
        display: "flex",
        lineHeight: 18,
    },
});
