import { SET_COUNTRY, SET_FIAT_CURRENCY } from "@store/actions/kriptonmarket.actions";

interface KMState {
    country: string,
    currency: string, 
}

const initalSatate: KMState = {
    country: null,
    currency: null,
};

const kriptonMarketReducer = (state = initalSatate, action) => {
  switch(action.type) {

    case SET_FIAT_CURRENCY: {
        return {
            ...state,
            currency: action.currency
        }
    }
    
    case SET_COUNTRY: {
        return {
            ...state,
            country: action.country
        }
    }

    default:
        return state;
  }
};

export default kriptonMarketReducer;