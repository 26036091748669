import SettingsScreen from "@screens/settings/SettingsScreen"
import { SecurityNavigator } from "./SecurityNavigator"
import LanguageScreen from "@screens/settings/language/LanguageScreen"
import { SeedNavigator } from "./SeedNavigator"
import NavigationInstantiator from "./helpers/NavigationInstantiator"

const SettingsNavigatorScreens = {
    MainSettings: {
        routeName: "MainSettings",
        title: "Settings",
        component: SettingsScreen,
    },
    Security: {
        routeName: "Security",
        title: "Security",
        component: SecurityNavigator,
    },
    Seed: {
        routeName: "Seed",
        title: "Seed",
        component: SeedNavigator,
    },
    Language: {
        routeName: "Language",
        title: "Language",
        component: LanguageScreen,
    },
}

const SettingsNavigator = NavigationInstantiator(SettingsNavigatorScreens)

export { SettingsNavigator, SettingsNavigatorScreens }
