import FlatListBase from "@base/FlatListBase";
import DappCard from "@components/cards/DappCard";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import { DappModel } from "@custom-types/DappModel";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { DappsNavigatorScreens } from "@navigation/DappsNavigator";
import React, { Component } from "react";
import { Linking, Platform, StyleSheet, View, ViewStyle } from "react-native";
import { connect } from "react-redux";
import { SectionTitle } from "../components/SectionTitle";
import DappsService from "@core/services/DappsService";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    allDapps: Array<DappModel>;
}

interface State {}

const { t } = i18n;

export class _DappsSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToDappExplorer = this.navigateToDappExplorer.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.state = {};
    }

    renderItem = ({ item }) => {
        return <DappCard navigation={this.props.navigation} dapp={item} />;
    };

    navigateToDappExplorer() {
        this.props.navigation.navigate("Dapps", {
            screen: DappsNavigatorScreens.DaapsExplorer.routeName,
        });
    }

    async loadMore() {
        DappsService.getInstance().getAllDapps();
    }

    render() {
        return (
            <>
                {ModuleControlService.getInstance().isModuleEnabled(Modules.dappsModule) &&
                    this.props.allDapps?.length > 0 && (
                        <View style={this.props.containerStyle}>
                            <SectionTitle
                                onPress={this.navigateToDappExplorer}
                                title={"DAPPs"}
                                icon={"💡"}
                                counter={"Explorer"}
                                seeMore={this.props.allDapps?.length > 3}
                                {...this.props}
                            ></SectionTitle>

                            <FlatListBase
                                data={this.props.allDapps}
                                renderItem={this.renderItem}
                                keyExtractor={(item) => item.id || item._id}
                                contentContainerStyle={{ paddingTop: 10, zIndex: 99 }}
                                initialNumToRender={4}
                                horizontal={true}
                                maxToRenderPerBatch={5}
                                showsHorizontalScrollIndicator={false}
                                onEndReached={this.loadMore}
                            />
                        </View>
                    )}
            </>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        allDapps: state.dapps.allDapps,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const DappsSection = connect(mapStateToProps, mapDispatchToProps)(_DappsSection);

export default DappsSection;
