import React, { Component } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { colors, settings } from "@styles/globalStyles";
import { connect } from "react-redux";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import InlineButton from "@base/InlineButton";
import RegularTextInput from "@base/RegularTextInput";
import i18n from "@i18n/i18n";
import RegularText from "@base/RegularText";
import OptionCard from "@components/cards/OptionCard";
import DigitalDocuments from "./DigitalDocuments";
import { KriptonService } from "@core/services/KriptonService";
import { loading, ready, showPopup } from "@store/actions/global";
import store from "@store/index";
import { KriptonMarketNavigatorScreens } from "@navigation/KriptonMarketNavigator";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    route: any;
}

interface State {
    birthday: string;
    first_name: string;
    last_name: string;
    gender: Gender;
    marital_status: MaritalStatus;
    nationality: string;
    politically_exposed: boolean;
    document_type: string;
    document_number: string;
    front_document_img: any;
    back_document_img: any;
    dni_selfie: any;
    error: string;
}

export enum MaritalStatus {
    single = "single",
    married = "married",
    divorcee = "divorcee",
    widower = "widower",
}

export enum Gender {
    male = "Hombre",
    female = "Mujer",
}

const { t } = i18n;

export class _PersonalInformationScreen extends Component<Props, State> {
    private kriptonService: KriptonService;
    focusListener: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            birthday: "",
            first_name: "",
            last_name: "",
            gender: Gender.male,
            marital_status: MaritalStatus.single,
            nationality: "",
            politically_exposed: false,
            document_type: "",
            document_number: "",
            front_document_img: "",
            back_document_img: "",
            dni_selfie: "",
            error: "",
        };
        this.kriptonService = KriptonService.getInstance();
        this.onPressNext = this.onPressNext.bind(this);
        this.onPressNacionality = this.onPressNacionality.bind(this);
        this.checkCountry = this.checkCountry.bind(this);
    }

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", this.checkCountry);
    }

    changeInputValue(value, field) {
        this.setState({
            ...this.state,
            [field]: value,
        });
    }

    onPressOption(value, field) {
        this.setState({
            ...this.state,
            [field]: value,
        });
    }

    checkCountry() {
        this.setState({
            nationality: this.props.route?.params?.country || "",
            error: "",
        });
    }

    onPressNacionality() {
        this.props.navigation.navigate(KriptonMarketNavigatorScreens.SelectNacionalityKripton.routeName);
    }

    async onPressNext() {
        if (!this.validate()) {
        } else {
            store.dispatch(loading());
            const digital_document_front = await this.kriptonService.newDigitalDocument(
                "front_document",
                this.state.front_document_img,
            );

            const digital_document_back = await this.kriptonService.newDigitalDocument(
                "back_document",
                this.state.back_document_img,
            );

            const digital_selfie = await this.kriptonService.newDigitalDocument("dni_selfie", this.state.dni_selfie);

            const personalInformation = await this.kriptonService.setPersonalInformation(
                this.state.birthday.replace("/", "-").replace("/", "-"),
                this.state.first_name,
                this.state.last_name,
                this.state.gender,
                this.state.marital_status,
                this.state.nationality,
                this.state.politically_exposed,
                this.state.document_type,
                this.state.document_number,
            );

            store.dispatch(showPopup({ type: "SUCCESS" }));
            this.props.navigation.navigate(KriptonMarketNavigatorScreens.ClientStatusKripton.routeName);
            store.dispatch(ready());
        }
    }

    validateBirthday() {
        const date = this.state.birthday.replace("/", "-").replace("/", "-");

        if (date.substring(4, 5) !== "-") {
            return true;
        }
        if (date.substring(7, 8) !== "-") {
            return true;
        }

        if (parseInt(date.substring(5, 7), 10) > 12) {
            return true;
        }

        if (parseInt(date.substring(8, 10), 10) > 31) {
            return true;
        }
    }

    validate() {
        if (this.state.first_name.length < 1) {
            this.setState({ error: "first_name" });
            return false;
        }
        if (this.state.last_name.length < 1) {
            this.setState({ error: "last_name" });
            return false;
        }
        if (this.state.birthday.length < 1) {
            this.setState({ error: "birthday" });
            return false;
        }

        if (this.validateBirthday()) {
            this.setState({ error: "birthday_error" });
            return false;
        }

        if (this.state.nationality.length < 1) {
            this.setState({ error: "nationality" });
            return false;
        }
        if (this.state.document_type.length < 1) {
            this.setState({ error: "document_type" });
            return false;
        }
        if (this.state.last_name.length < 1) {
            this.setState({ error: "document_number" });
            return false;
        }

        if (this.state.front_document_img.length < 1) {
            this.setState({ error: "front_document_img" });
            return false;
        }
        if (this.state.back_document_img.length < 1) {
            this.setState({ error: "back_document_img" });
            return false;
        }
        if (this.state.dni_selfie.length < 1) {
            this.setState({ error: "dni_selfie" });
            return false;
        }

        return true;
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("personal_information_optional")} type={HeaderType.Light} {...this.props} />

                <Container style={styles.container}>
                    <ScrollView>
                        {this.state.error == "first_name" && (
                            <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                {t("field_error")}
                            </RegularText>
                        )}
                        <View style={styles.inputContainer}>
                            <RegularTextInput
                                style={styles.input}
                                align={"center"}
                                onChangeText={(text) => this.changeInputValue(text, "first_name")}
                                value={this.state.first_name}
                                placeholder={t("first_name")}
                            ></RegularTextInput>
                        </View>

                        {this.state.error == "last_name" && (
                            <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                {t("field_error")}
                            </RegularText>
                        )}
                        <View style={styles.inputContainer}>
                            <RegularTextInput
                                style={styles.input}
                                align={"center"}
                                onChangeText={(text) => this.changeInputValue(text, "last_name")}
                                value={this.state.last_name}
                                placeholder={t("last_name")}
                            ></RegularTextInput>
                        </View>

                        {this.state.error == "birthday" && (
                            <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                {t("field_error")}
                            </RegularText>
                        )}

                        <View style={styles.inputContainer}>
                            <RegularTextInput
                                style={styles.input}
                                align={"center"}
                                onChangeText={(text) => this.changeInputValue(text, "birthday")}
                                value={this.state.birthday}
                                placeholder={t("birthday")}
                            ></RegularTextInput>
                        </View>

                        {this.state.error == "birthday_error" && (
                            <RegularText
                                style={{ marginTop: -10, marginBottom: 20 }}
                                align={"center"}
                                fontSize={12}
                                color={colors.secondary}
                            >
                                {t("birthday_error")}
                            </RegularText>
                        )}

                        {this.state.error == "nationality" && (
                            <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                {t("field_error")}
                            </RegularText>
                        )}

                        <PressableBase onPress={this.onPressNacionality} style={styles.inputContainer}>
                            {this.state.nationality.length > 0 && (
                                <RegularText style={styles.input} align={"center"} color={colors.text}>
                                    {this.state.nationality}
                                </RegularText>
                            )}
                            {this.state.nationality.length == 0 && (
                                <RegularText style={styles.input} align={"center"} color={colors.grey}>
                                    {t("nationality")}
                                </RegularText>
                            )}
                        </PressableBase>

                        {this.state.error == "document_type" && (
                            <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                {t("field_error")}
                            </RegularText>
                        )}
                        <View style={styles.inputContainer}>
                            <RegularTextInput
                                style={styles.input}
                                align={"center"}
                                onChangeText={(text) => this.changeInputValue(text, "document_type")}
                                value={this.state.document_type}
                                placeholder={t("document_type")}
                            ></RegularTextInput>
                        </View>
                        {this.state.error == "document_number" && (
                            <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                {t("field_error")}
                            </RegularText>
                        )}
                        <View style={styles.inputContainer}>
                            <RegularTextInput
                                style={styles.input}
                                align={"center"}
                                onChangeText={(text) => this.changeInputValue(text, "document_number")}
                                value={this.state.document_number}
                                placeholder={t("document_number")}
                            ></RegularTextInput>
                        </View>

                        <DigitalDocuments
                            alert={this.state.error == "front_document_img"}
                            onPressCallBack={(img) => {
                                this.setState({ front_document_img: img, error: "" });
                            }}
                            title={t("front_document")}
                            subtitle={t("front_document_description")}
                        />

                        <DigitalDocuments
                            alert={this.state.error == "back_document_img"}
                            onPressCallBack={(img) => {
                                this.setState({ back_document_img: img, error: "" });
                            }}
                            title={t("back_document")}
                            subtitle={t("back_document_description")}
                        />

                        <DigitalDocuments
                            alert={this.state.error == "dni_selfie"}
                            onPressCallBack={(img) => {
                                this.setState({ dni_selfie: img, error: "" });
                            }}
                            title={t("selfie_document")}
                            subtitle={t("selfie_description")}
                        />

                        <RegularText align="left" color={colors.grey}>
                            {t("politically_exposed")}
                        </RegularText>
                        <OptionCard
                            selected={this.state.politically_exposed}
                            onPress={() => this.onPressOption(true, "politically_exposed")}
                            title={t("yes")}
                        ></OptionCard>
                        <OptionCard
                            selected={!this.state.politically_exposed}
                            onPress={() => this.onPressOption(false, "politically_exposed")}
                            title={t("no")}
                        ></OptionCard>

                        <RegularText align="left" color={colors.text}>
                            {t("marital_status")}
                        </RegularText>
                        <OptionCard
                            selected={this.state.marital_status == MaritalStatus.single}
                            onPress={() => this.onPressOption(MaritalStatus.single, "marital_status")}
                            title={t("single")}
                        ></OptionCard>
                        <OptionCard
                            title={t("married")}
                            selected={this.state.marital_status == MaritalStatus.married}
                            onPress={() => this.onPressOption(MaritalStatus.married, "marital_status")}
                        ></OptionCard>
                        <OptionCard
                            title={t("divorcee")}
                            selected={this.state.marital_status == MaritalStatus.divorcee}
                            onPress={() => this.onPressOption(MaritalStatus.divorcee, "marital_status")}
                        ></OptionCard>
                        <OptionCard
                            title={t("widower")}
                            selected={this.state.marital_status == MaritalStatus.widower}
                            onPress={() => this.onPressOption(MaritalStatus.widower, "marital_status")}
                        ></OptionCard>

                        <RegularText align="left" color={colors.text}>
                            {t("gender")}
                        </RegularText>
                        <OptionCard
                            selected={this.state.gender == Gender.male}
                            onPress={() => this.onPressOption(Gender.male, "gender")}
                            title={t("male")}
                        ></OptionCard>
                        <OptionCard
                            selected={this.state.gender == Gender.female}
                            onPress={() => this.onPressOption(Gender.female, "gender")}
                            title={t("female")}
                        ></OptionCard>

                        <View style={{ height: 60, marginTop: 20 }}>
                            <InlineButton
                                title={t("save")}
                                onPress={this.onPressNext}
                                style={{ marginHorizontal: 0, marginBottom: 20 }}
                            />
                        </View>
                    </ScrollView>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        //justifyContent: "space-between",
        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    inputContainer: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 20,
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        paddingHorizontal: 40,
        textAlign: "center",
    },
});

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({});

const PersonalInformationScreen = connect(mapStateToProps, mapDispatchToProps)(_PersonalInformationScreen);

export default PersonalInformationScreen;
