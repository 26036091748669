import {TabsNavigator} from "./TabsNavigator"
import NavigationInstantiator from "./helpers/NavigationInstantiator"

const MainNavigatorScreens = {
    BaseAppNavigator: {
        routeName: "Tabs",
        title: "Tabs",
        component: TabsNavigator,
    },   
}

const MainNavigator = NavigationInstantiator(MainNavigatorScreens)

export {MainNavigator, MainNavigatorScreens}
