import React, { Component } from "react";
import { View, StyleSheet, Text } from "react-native";
import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import Card from "@base/Card";
import { KycStatus } from "@custom-types/KycStatus";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import Icon from "@components/icons";

interface Props {
    navigation?: NavigationType;
    title: string;
    status: string;
    targetScreen: string;
}

export default class StatusCard extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.onPress = this.onPress.bind(this);
    }

    getStatusColor(status:string) {
        return (status == KycStatus.approving && colors.green) ||
        (status == KycStatus.checking && colors.yellow) ||
        (status == KycStatus.pending && colors.red );
    } 
    
    onPress() {
        if(this.props.status == KycStatus.pending) {
            this.props.navigation.navigate(this.props.targetScreen)
        }
    }

    render() {
        return (
            <Card
                borderLeft={this.getStatusColor(this.props.status)}
                onPress={this.onPress}
                left={
                    <View>
                        <RegularText>{this.props.title}</RegularText>
                    </View>
                }
                right={
                    this.props.status == KycStatus.pending &&
                    <Icon name={"right"} size={15} color={colors.white} />
                }
            />
        );
    }
}

