import React, { Component } from "react";
import i18n from "@i18n/i18n";
import { importer } from "./helpers/importer";
import { View } from "react-native";


interface Props {}

interface State {}

const { t } = i18n;

class DefaultCustomHomeComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return <View></View>;
    }
}

const CustomHomeComponent = importer(DefaultCustomHomeComponent, "CustomHomeComponent");

export { CustomHomeComponent };
