import React, { Component } from 'react';
import { StyleSheet, View, TextStyle } from 'react-native';
import RegularText from './RegularText';
import { colors, settings } from '@styles/globalStyles';
import PressableBase from './PressableBase';

interface Props {
    title: string,
    style?: TextStyle
    align?: "auto" | "left" | "right" | "center" | "justify",
    fontSize?: number,
    color?: string,
    onPress: () => void,
}

interface State {
    inlineStyles: TextStyle
}

export default class CardButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            inlineStyles: {

            }
        }
    }

    addStyles: (() => TextStyle) = () => ({});

    render() {
        return (

            <View style={[styles.defaultStyles, this.props.style, this.addStyles()]}>
                <PressableBase onPress={this.props.onPress} style={{ padding: 10 }} accessibilityLabel="card-button">
                    <RegularText color={colors.text} align='center' > {this.props.title} </RegularText>
                </PressableBase>
            </View>

        )
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        marginVertical: 5,
        height: 55,
        borderRadius: settings.cardRadius,
        minWidth: 80,
        justifyContent: "center",
        borderWidth: 1,
        borderColor: colors.grey,
        borderStyle: 'dashed',
    },
});