import POAP from "@custom-types/POAP";

export const PoapAdatper = (data): POAP => {
    return {
        event: {
            id: data?.event?.id || 0,
            fancyId: data?.event?.fancyId || data?.event?.fancy_id || "",
            name: data?.event?.name || "",
            eventUrl: data?.event?.eventUrl || data?.event?.event_url || "",
            imageUrl: data?.event?.imageUrl || data?.event?.image_url || "",
            country: data?.event?.country || "",
            city: data?.event?.city || "",
            description: data?.event?.description || "",
            year: data?.event?.year || 0,
            startDate: data?.event?.startDate || data?.event?.start_date || "",
            endDate: data?.event?.endDate || data?.event?.end_date || "",
            expiryDate: data?.event?.expiryDate || data?.event?.expiry_date || "",
        },
        tokenId: data?.tokenId || "",
        owner: data?.owner || "",
        chain: data?.chain || "",
        created: data?.created || "",
    };
};
