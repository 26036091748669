
import Post from "@custom-types/Post";
import { ProfileData } from "@screens/profile2.0/components/ProfileHeader";

export const SET_PROFILE = "SET_PROFILE";
export const SET_POSTs = "SET_POSTs";
export const SET_NFTs = "SET_NFTs";
export const SET_POAPs = "SET_POAPs";
export const UPDATE_POST = "UPDATE_POST";


export const setProfile = (profile: ProfileData) => {
    return { type: SET_PROFILE, profile: profile };
};

export const setPOSTs = (data) => {
    return { type: SET_POSTs, data: data };
};

export const setNFTs = (data) => {
    return { type: SET_NFTs, data: data };
};

export const setPOAPs = (data) => {
    return { type: SET_POAPs, data: data };
};

export const updatePOST = (data: { posts: Array<Post>, idClient: string }) => {
    return { type: UPDATE_POST, data: data }
}
