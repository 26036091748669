
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator"
import {_SendScreen} from "@screens/wallet/send/SendScreen"
import {connect} from "react-redux"

export class _SendCryptoScreen extends _SendScreen {
    constructor(props: any) {
        super(props)
        this.setTitle("Send")
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.wallet,
        customMessage: state.chat.customMessage,
        onSuccessNavigate: MessagesNavigatorScreens.ConfirmSendCryptoScreen.routeName,
    }
}

const mapDispatchToProps = (dispatch) => ({})

const SendCryptoScreen = connect(mapStateToProps, mapDispatchToProps)(_SendCryptoScreen)

export default SendCryptoScreen
