import Container from "@base/Container";
import FlatListBase from "@base/FlatListBase";
import { Header } from "@components/header/Header";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Currency from "@core/currencies/Currency";
import ActivityService from "@core/services/ActivityService";
import { ActivityModel } from "@custom-types/ActivityModel";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { colors } from "@styles/globalStyles";
import { getActivityCard } from "@utils/helpers/activity/activity.helper";
import React, { Component } from "react";
import { RefreshControl, StyleSheet } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    selectedCurrency: string;
    activity: Array<ActivityModel>;
}

interface State {
    syncing: boolean;
}

const { t } = i18n;

export class _ActivityScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.getActivity = this.getActivity.bind(this);
        this.state = {
            syncing: false,
        };
    }

    async componentDidMount() {
        this.getActivity();
    }

    async getActivity() {
        this.setState({ syncing: true }, async () => {
            await ActivityService.getInstance().getActivity();
            this.setState({ syncing: false });
        });
    }

    renderItem = ({ item }) => {
        if (!item) return;
        return getActivityCard(item, this.props.navigation);
    };

    async onRefresh() {
        await this.getActivity();
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("activity")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    <FlatListBase
                        data={this.props.activity}
                        renderItem={(item) => this.renderItem(item)}
                        keyExtractor={(item) => item}
                        refreshControl={
                            <RefreshControl
                                tintColor={colors.white}
                                onRefresh={this.onRefresh}
                                refreshing={this.state.syncing}
                            />
                        }
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        activity: state.activity.activity,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ActivityScreen = connect(mapStateToProps, mapDispatchToProps)(_ActivityScreen);

export default ActivityScreen;
