import React, { Component } from "react";
import { View, Text, StyleSheet, Button } from "react-native";
import CurrencyCard from "@components/cards/CurrencyCard";
import { colors, settings } from "@styles/globalStyles";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import Row from "@base/Row";
import InlineButton from "@base/InlineButton";
import Balance from "@components/wallet/Balance";
import { NavigationType } from "@custom-types/NavigationType";
import RegularText from "@base/RegularText";
import QRCode from "react-native-qrcode-svg";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import RegularNumberInput from "@base/RegularNumberInput";
import Card from "@base/Card";
import BoldText from "@base/BoldText";
import RegularTextInput from "@base/RegularTextInput";
import store from "@store/index";
import { setCurrencies, updateCurrencyPath } from "@store/actions/wallet";
import wallet from "@store/sagas/wallet";
import { loading, ready, showPopup } from "@store/actions/global";
import SwitchCard from "@components/cards/SwitchCard";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import WarningCard from "@base/WarningCard";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { ClientService } from "@core/services/ClientService";
import BotCard from "@base/BotCard";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    selectedCurrency: string;
}

interface State {
    account: string;
    change: string;
    index: string;
    smart: boolean;
    networkID: number;
    switchPath: boolean;
    showWarning: boolean;
}

const { t } = i18n;

export class _CurrencySettingsScreen extends Component<Props, State> {
    protected currency: Currency;

    constructor(props: Props) {
        super(props);
        this.currency = Wallet.getInstance().findCurrencyById(this.props.selectedCurrency);
        this.state = {
            account: this.currency.getAccount(),
            change: this.currency.getChange(),
            index: this.currency.getIndex(),
            smart: this.currency.isSmart(),
            networkID: this.currency.getNetworkID(),
            switchPath: this.currency.getUnderlyingCurrency().hasEthCompatibility(),
            showWarning: false,
        };
        this.onPress = this.onPress.bind(this);
        this.switchSmart = this.switchSmart.bind(this);
        this.switchPath = this.switchPath.bind(this);
    }

    onPress = () => {
        ClientService.getInstance().setEthCompatibility(this.state.switchPath);
        store.dispatch(
            updateCurrencyPath({
                currency: this.currency,
                account: this.state.account,
                change: this.state.change,
                index: this.state.index,
                smart: this.state.smart,
                onSuccess: () => {
                    store.dispatch(showPopup({ type: "SUCCESS" }));
                    this.props.navigation.navigate(WalletNavigatorScreens.Wallet.routeName);
                },
            }),
        );
    };

    switchSmart() {
        //store.dispatch(switchSmart({smart: this.state.smart}));
        this.setState({ smart: !this.state.smart });
    }

    switchPath() {
        this.state.switchPath ? this.setState({ switchPath: false }) : this.setState({ switchPath: true });
        this.setState({ showWarning: true });
        setTimeout(() => {
            this.setState({ showWarning: false });
        }, 6000);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("settings")} type={HeaderType.Light} {...this.props} />

                <Container>
                    {/* this.currency.isSmartAllowed() &&
                        <SwitchCard title='Smart' onToggle={this.switchSmart} isOn={this.state.smart} />
                    */}
                    <WarningCard message={t("path_warning")} />
                    {this.currency.getUnderlyingCurrency().hasEthCompatibilitySupport() && (
                        <SwitchCard
                            title={""}
                            left={
                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                    <RegularText>{t("ethCompatibility")}</RegularText>
                                </View>
                            }
                            onToggle={() => this.switchPath()}
                            isOn={this.state.switchPath}
                        />
                    )}
                    {this.state.showWarning && 
                    <BotCard title={t('important')} message={t("bot_save_warning")}></BotCard>
                    }
                    <Card>
                        <BoldText align="center" color={colors.text}>
                            {" "}
                            Path{" "}
                        </BoldText>
                        <View style={styles.inputs}>
                            <RegularText align="center" style={styles.input}>
                                {"m'"}{" "}
                            </RegularText>
                            <RegularText align="center" style={styles.input}>
                                {"44'"}{" "}
                            </RegularText>
                            <RegularText align="center" style={styles.input}>
                                {this.state.networkID + "'"}{" "}
                            </RegularText>
                            <RegularTextInput
                                onChangeText={(value) => {
                                    this.setState({ account: value });
                                }}
                                value={this.state.account}
                                style={styles.input}
                                align="center"
                            />
                            <RegularTextInput
                                onChangeText={(value) => {
                                    this.setState({ change: value });
                                }}
                                value={this.state.change}
                                style={styles.input}
                                align="center"
                            />
                            <RegularTextInput
                                onChangeText={(value) => {
                                    this.setState({ index: value });
                                }}
                                value={this.state.index}
                                style={styles.input}
                                align="center"
                            />
                        </View>
                    </Card>
                </Container>
                <Container style={{ flex: 1, flexDirection: "column-reverse" }}>
                    <InlineButton
                        title={t("save")}
                        onPress={this.onPress}
                        style={{ marginHorizontal: 0, marginBottom: 20 }}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const CurrencySettingsScreen = connect(mapStateToProps, mapDispatchToProps)(_CurrencySettingsScreen);

export default CurrencySettingsScreen;

const styles = StyleSheet.create({
    inputs: {
        marginHorizontal: "auto",
        marginVertical: 15,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    input: {
        borderWidth: 1,
        borderRadius: settings.cardRadius,
        marginHorizontal: 3,
        width: 45,
        height: 40,
        alignItems: "center",
        justifyContent: "center",
        color: colors.grey,
        borderColor: colors.text,
        backgroundColor: colors.shadow,
        paddingVertical: 8,
        overflow: "hidden",
    },
});
