
import NotificationDetailScreen from "@screens/notifications/NotificationDetailScreen";
import NotificationsScreen from "@screens/notifications/NotificationsScreen";
import NavigationInstantiator from "./helpers/NavigationInstantiator";



const NotificationsNavigatorScreens = {
    NotificationsList: {
        routeName: "NotificationList",
        title: "Notification list",
        component: NotificationsScreen,
    },
    NotificationDetail: {
        routeName: "NotificationDetail",
        title: "Notification detail",
        component: NotificationDetailScreen,
    },
};

const NotificationsNavigator = NavigationInstantiator(NotificationsNavigatorScreens)

export { NotificationsNavigator, NotificationsNavigatorScreens }
