import Wallet from "@core/wallet/Wallet";

export const CoincaexNetwork = {
    ETH: { id: "ERC20", name: "Ethereum (ERC20)" },
    TRX: { id: "TRC20", name: "Tron (TRC20)" },
    MATIC: { id: "Polygon", name: "Polygon" },
    BTC: { id: "BTC", name: "Bitcoin" },
    BSC: { id: "BEP20", name: "BNB Smart Chain (BEP20)" },
};

export interface CoincaexFiatCurrency {
    symbol: string;
    name: string | any;
}

export interface CoincaexCountries {
    id: string;
    name: string;
    icon: any;
}

export interface CoincaexSignup {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword: string;
}

export interface CoincaexSignin {
    email: string;
    password: string;
}

export interface CoincaexBankAccount {
    bankName: string;
    bankAccountCurrency: string;
    bankAccountType: string;
    bankAccountName: string;
    bankAccountNumber: string;
}

export interface CoincaexAvailableBanks {
    banks: Array<CoincaexAvailableBanksType>;
    currencies: Array<CoincaexAvailableBanksType>;
    types: Array<CoincaexAvailableBanksType>;
}

export interface CoincaexAvailableBanksType {
    id: string;
    value: string;
}

export interface CoincaexSell {
    id?: string;
    token?: string;
    nit?: string;
    details?: string;
    amount?: number;
    crypto?: string;
    wallet?: string;
    network?: string;
    transactionDepositTotal?: number;
    bankAccountCurrency?: string;
    bankAccountName?: string;
    bankAccountNumber?: string;
    bankAccountType?: string;
    bankName?: string;
    voucher?: string;
    reference?: string;
    discountCode?: string;
    networkId?: string;
}

export interface CoincaexBuy {
    id?: string;
    token?: string;
    nit?: string;
    details?: string;
    amount?: number;
    crypto?: string;
    wallet?: string;
    network?: string;
    transactionDepositTotal?: number;
    bankName?: string;
    bankAccountCurrency?: string;
    bankAccountNumber?: string;
    voucher?: string;
    reference?: string;
    discountCode?: string;
}

export interface CoincaexQuotation {
    transactionAmount: number;
    transactionCryptoAmount: number;
    transactionDepositTotal: number;
    transactionExchangeRateCrypto: number;
    transactionExchangeRateFiat: number;
    commission: number;
}

export interface TransactionsCoincaex {
    [key: string]: {
        id: string;
        transactionTimeStamp: number;
        transactionUpdateTimeStamp: number | null;
        transactionStatus: string;
        transactionType: string;
        transactionCryptoCurrency: string;
        transactionDepositTotal: number;
        transactionCryptoAmount: number;
        transactionAmount: number;
        bankAccountCurrency: string;
        bankAccountName: string;
        bankAccountNumber: string;
        bankAccountType: string;
        bankName: string;
        clientDepositAddress: string;
        transactionCryptoNetwork: string;
        transactionExchangeRateCountry: number;
        transactionExchangeRateCrypto: number;
        transactionVoucher: string;
        clientId: string;
    };
}

export interface Bank {
    Logo: string;
    Bank: string;
    Code: string;
    Active: boolean;
    Type: string;
    Number: string;
    Currency: string;
    Name: string;
}

// export const CoincaexFiatCurrencies: Record<string, CoincaexFiatCurrency> = {
//     USD: { name: "USD", fullName: "Dólar Estadounidense", secondName: "DÓLARES", icon: require("../assets/USA.png") },
//     GTQ: { name: "GTQ", fullName: "Quetzal", secondName: "QUETZALES", icon: require("../assets/GT.png") },
//     HNL: { name: "HNL", fullName: "Lempira", secondName: "LEMPIRAS", icon: require("../assets/HN.png") },
// };

// export const CoincaexFiatCurrenciesArray = [
//     CoincaexFiatCurrencies.USD,
//     CoincaexFiatCurrencies.GTQ,
//     CoincaexFiatCurrencies.HNL,
// ];

export enum CoincaexCurrencies {
    BTC = "BTC",
    ETH = "ETH",
    USDT = "USDT",
    MATIC = "MATIC",
    USDC = "USDC",
}

export enum LatamXoCurrencies {
    BTC = "BTC",
    ETH = "ETH",
    MATIC = "MATIC",
    USDT = "USDT",
    TRONUSDT = "TRONUSDT",
    POLYGONUSDT = "POLYGONUSDT",
    USDC = "USDC",
    BSCUSDC = "BSCUSDC",
    POLYGONUSDC = "POLYGONUSDC",
}

export function equateCurrenciesFromCoincaex(currency: string, network: string) {
    try {
        switch (true) {
            case currency == CoincaexCurrencies.BTC &&
                (network == CoincaexNetwork.BTC.name || network == CoincaexNetwork.BTC.id):
                return Wallet.getInstance().findCurrencyById("BTC");
                break;
            case currency == CoincaexCurrencies.ETH &&
                (network == CoincaexNetwork.ETH.name || network == CoincaexNetwork.ETH.id):
                return Wallet.getInstance().findCurrencyById("ETH");
                break;
            case currency == CoincaexCurrencies.MATIC &&
                (network == CoincaexNetwork.MATIC.name || network == CoincaexNetwork.MATIC.id):
                return Wallet.getInstance().findCurrencyById("MATIC");
                break;
            case currency == CoincaexCurrencies.USDT &&
                (network == CoincaexNetwork.ETH.name || network == CoincaexNetwork.ETH.id):
                return Wallet.getInstance().findCurrencyById("USDT");
                break;
            case currency == CoincaexCurrencies.USDT &&
                (network == CoincaexNetwork.TRX.name || network == CoincaexNetwork.TRX.id):
                return Wallet.getInstance().findCurrencyById("TRONUSDT");
                break;
            case currency == CoincaexCurrencies.USDT &&
                (network == CoincaexNetwork.MATIC.name || network == CoincaexNetwork.MATIC.id):
                return Wallet.getInstance().findCurrencyById("POLYGONUSDT");
                break;
            case currency == CoincaexCurrencies.USDC &&
                (network == CoincaexNetwork.BSC.name || network == CoincaexNetwork.BSC.id):
                return Wallet.getInstance().findCurrencyById("BSCUSDC");
                break;
            case currency == CoincaexCurrencies.USDC &&
                (network == CoincaexNetwork.ETH.name || network == CoincaexNetwork.ETH.id):
                return Wallet.getInstance().findCurrencyById("USDC");
                break;
            case currency == CoincaexCurrencies.USDC &&
                (network == CoincaexNetwork.MATIC.name || network == CoincaexNetwork.MATIC.id):
                return Wallet.getInstance().findCurrencyById("POLYGONUSDC");
                break;
            default:
                return null;
        }
    } catch (e) {
        console.warn(e);
    }
}

export function equateCurrenciesToCoincaex(
    currencyID: string,
): Promise<{ currency: string; network: string; networkId: string }> {
    return new Promise((resolve, reject) => {
        try {
            switch (currencyID) {
                case LatamXoCurrencies.BTC:
                    resolve({
                        currency: CoincaexCurrencies.BTC,
                        network: CoincaexNetwork.BTC.name,
                        networkId: CoincaexNetwork.BTC.id,
                    });
                    break;
                case LatamXoCurrencies.ETH:
                    resolve({
                        currency: CoincaexCurrencies.ETH,
                        network: CoincaexNetwork.ETH.name,
                        networkId: CoincaexNetwork.ETH.id,
                    });
                    break;
                case LatamXoCurrencies.MATIC:
                    resolve({
                        currency: CoincaexCurrencies.MATIC,
                        network: CoincaexNetwork.MATIC.name,
                        networkId: CoincaexNetwork.MATIC.id,
                    });
                    break;
                case LatamXoCurrencies.USDT:
                    resolve({
                        currency: CoincaexCurrencies.USDT,
                        network: CoincaexNetwork.ETH.name,
                        networkId: CoincaexNetwork.ETH.id,
                    });
                    break;
                case LatamXoCurrencies.TRONUSDT:
                    resolve({
                        currency: CoincaexCurrencies.USDT,
                        network: CoincaexNetwork.TRX.name,
                        networkId: CoincaexNetwork.TRX.id,
                    });
                    break;
                case LatamXoCurrencies.POLYGONUSDT:
                    resolve({
                        currency: CoincaexCurrencies.USDT,
                        network: CoincaexNetwork.MATIC.name,
                        networkId: CoincaexNetwork.MATIC.id,
                    });
                    break;
                case LatamXoCurrencies.USDC:
                    resolve({
                        currency: CoincaexCurrencies.USDC,
                        network: CoincaexNetwork.ETH.name,
                        networkId: CoincaexNetwork.ETH.id,
                    });
                    break;
                case LatamXoCurrencies.BSCUSDC:
                    resolve({
                        currency: CoincaexCurrencies.USDC,
                        network: CoincaexNetwork.BSC.name,
                        networkId: CoincaexNetwork.BSC.id,
                    });
                    break;
                case LatamXoCurrencies.POLYGONUSDC:
                    resolve({
                        currency: CoincaexCurrencies.USDC,
                        network: CoincaexNetwork.MATIC.name,
                        networkId: CoincaexNetwork.MATIC.id,
                    });
                    break;
                default:
                    reject("Currency not found");
            }
        } catch (e) {
            reject(e);
        }
    });
}
