import * as Haptics from "expo-haptics";
import { Platform } from "react-native";

export enum VibrationType {
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
    WARNING = "WARNING",
    ONPRESS = "ONPRESS",
    NOTIFICATION = "NOTIFICATION",
}

export const vibrate = async (type?: VibrationType) => {
    //@todo: Add switch in Setting (active vibration)
    if (Platform.OS == "web") {
        return;
    }
    switch (type) {
        case VibrationType.ERROR:
            Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
            break;
        case VibrationType.SUCCESS:
            Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
            break;
        case VibrationType.WARNING:
            Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
            break;
        case VibrationType.ONPRESS:
            Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
            break;
        case VibrationType.NOTIFICATION:
            Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
        default:
            Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
            break;
    }
};
