import { Client } from "@custom-types/Client";
import store from "@store/index";
import { ApiService } from "@core/services/ApiService";
import { Chat } from "@custom-types/Chat";
import { SerializedMessage } from "@custom-types/SerializedMessage";
import { MessageState } from "@utils/helpers/chat/message-state/message.state";
import { createMessageState } from "@utils/helpers/chat/message-state/message.state.factory";
import { SocketClient } from "@core/services/SocketClient";
import { MessageAddType } from "@utils/helpers/chat/chat.helper";
import { BaseMessageState } from "@utils/helpers/chat/message-state/base.message.state";
import { schema } from "normalizr";
import { addMessage, updateMessage } from "@store/actions/message.actions";
import { MQTTClient } from "@core/services/MQTTClient";
import { AuthService } from "@core/services/AuthService";

const CryptoJS = require("crypto-js");
const EC = require("elliptic").ec;
const ec = new EC("secp256k1");

export class Message implements MessageState {
    public static readonly PENDING_STATE = 0;
    public static readonly SENT_STATE = 1;
    public static readonly RECEIVED_STATE = 2;
    public static readonly READ_STATE = 3;

    id: string;
    _id: string;
    author: Client;
    text: string;
    chatId: string;
    createdAt: any;
    state: BaseMessageState;
    sent: boolean;
    received: boolean;
    pending: boolean;
    internalId: string;
    to: Client;
    data: any;
    custom: boolean;
    serialized: SerializedMessage;

    public static create(data: any): Message {
        const selectedChat: Chat = store.getState().chat.selected;

        if (!data.hasOwnProperty("internalId") || data.internalId === "undefined") {
            data.internalId = data._id;
        }

        if (data.hasOwnProperty("_id")) {
            data.id = data._id;
        }

        if (!data.author) {
            data.author = store.getState().auth.client;
        }

        if (!data.to) {
            data.to = selectedChat.to;
        }

        if (!data.state) {
            data.state = Message.PENDING_STATE;
        }

        if (!data.chatId) {
            data.chatId = selectedChat.id;
        }

        data.createdAt = data.createdAt || Date.now()


        let message = Object.assign(new Message(), data);
        if (data.chatId && store.getState().chat.items[data.chatId]) {
            const chat: Chat = store.getState().chat.items[data.chatId];
            message.author = (message.author._id === chat.from._id) ? chat.from : chat.to;
        }
        message.state = createMessageState(data.state, message);
        message.serializer();

        return message;
    }

    encrypt(value) {
        if (value) {
            const sharedKey = AuthService.getSharedKeyFromExternalPublicKey(this.to.keyChat);
            return CryptoJS.AES.encrypt(value, sharedKey).toString();
        }
        return value;
    }

    decrypt() {
        try {
            const keyChat = (store.getState().auth.client.id === this.to._id) ? this.author.keyChat : this.to.keyChat;
            const sharedKey2 = AuthService.getSharedKeyFromExternalPublicKey(keyChat);
            this.text = CryptoJS.AES.decrypt(this.text, sharedKey2).toString(CryptoJS.enc.Utf8);

            if (this.data && this.data.address !== undefined) {
                this.data.address = CryptoJS.AES.decrypt(this.data.address, sharedKey2).toString(CryptoJS.enc.Utf8);
            }

            this.serialized.text = this.text;
            if (this.data && this.data.address !== undefined) {
                this.serialized.data.address = this.data.address;
            }

            return this;
        } catch (e) {
            this.text = 'this message is corrupt'
            this.serialized.text = this.text;

            if (this.data && this.data.address !== undefined) {
                this.data.address = 'this address is corrupt';
                this.serialized.data.address = this.data.address;
            }

            return this;
        }
    }

    /**
     * transforms message so that it can be read by chat component
     */
    serializer() {
        this.serialized = new SerializedMessage(this);
    }

    getStateNumber() {
        return Number(this.state.getStateNumber());
    }

    saveServer() {
        const msg = this.serializerToServer();
        MQTTClient.getInstance().publish('to-server-chat-new-message', JSON.stringify(msg));
    }

    saveStorage(addType: MessageAddType = MessageAddType.start, decrypt = true) {
        if (decrypt) this.decrypt();
        const messages = store.getState().message.items;
        if (!messages[this.internalId]) store.dispatch(addMessage(this, addType));
    }


    updateServer() {
        const msg = this.serializerToServer();
        MQTTClient.getInstance().publish('to-server-chat-update-message', JSON.stringify(msg));
    }

    updateStorage() {
        const messages = store.getState().message;

        if (messages.items[this._id]) {
            const msg: Message = messages.items[this._id];

            if (msg.state.getStateNumber() <= this.getStateNumber()) {
                store.dispatch(updateMessage(this, this._id))
                return;
            }
        }

        if (messages.items[this.internalId]) {
            const msg: Message = messages.items[this.internalId];
            if (msg.state.getStateNumber() <= this.getStateNumber()) {
                store.dispatch(updateMessage(this, this.internalId))
                return;
            }
        }
    }

    serializerToServer() {
        const text = this.encrypt(this.text);

        let data;
        if (this.data && this.data.address !== undefined) {
            data = { ...this.data, address: this.encrypt(this.data.address) }
        }

        const clientId = store.getState().auth.client?._id;

        return { ...this, state: this.getStateNumber(), text, data, serialized: null, clientId };
    }

    transitionTo(state: BaseMessageState) {
        this.state = state;
        this.state.setMessage(this);
    }


    saveEarlier() {
        this.state.saveEarlier();
    }

}

export const messageSchema = new schema.Entity('messages', {}, { idAttribute: '_id' });
