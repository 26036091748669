import AbstractCurrency, { IAbstractCurrency } from "@core/currencies/AbstractCurrency";

export interface IFiatCurrency extends IAbstractCurrency{
    sign?: string
}

export class FiatCurrency extends AbstractCurrency {

    constructor(options: IFiatCurrency) {
        super(
           {...options}
        );
        this.sign = options.sign
    }

}


