import Constants from "expo-constants"
import { Platform } from "react-native"
import * as Network from "expo-network"
import store from "@store/index"
import { hideModal, showModal, showSnackbar } from "@store/actions/global"
import i18n from "@i18n/i18n"
import NetInfo from '@react-native-community/netinfo';

const { t } = i18n

export const appendFileToFormData = async (form: FormData, fileUri, mimeType = "image/jpeg") => {
    if (Platform.OS == "web") {
        const base64 = await fetch(fileUri)
        const blob = await base64.blob()
        form.append("file", blob)
        return form
    }

    form.append("file", {
        uri: fileUri,
        type: mimeType,
        name: "file" + Math.random(),
    })

    return form
}

export const getEnv = function (v: string) {
    return Constants.expoConfig?.extra[v]
}

export const getColorOpacity = (color: string, opacity: number) => {
    if (color && color.length == 7) {
        const red = parseInt(color[1] + color[2], 16)
        const green = parseInt(color[3] + color[4], 16)
        const blue = parseInt(color[5] + color[6], 16)
        return `rgba(${red}, ${green}, ${blue}, ${opacity})`
    } else {
        return color
    }
}

export const consoleLog = (log: string | number) => {
    if (getEnv["ENV"] !== "PRODUCTION") {
        console.warn(log)
    }
}

export const isNetworkAvailable = async () => {

    // const Connection = NetInfo.addEventListener(state => {

    //     if (!state.isConnected || (Platform.OS !== "web" && !state.isInternetReachable)) {
    //         store.dispatch(showSnackbar({ type: "MESSAGE", message: t("no_network_connection") }));
    //     }
    // });
}
