import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { View, StyleSheet, ViewStyle } from "react-native";
import BoldText from "./BoldText";
import { VibrationType, vibrate } from "@utils/vibration/vibration.helper";

interface Props {
    size?: number;
    color?: string;
    count?: number;
    smallBadge?: boolean;
    containerSyle?: ViewStyle;
    smallBadgeContainer?: ViewStyle;
    badgeStyle?: ViewStyle;
    smallBadgeStyle?: ViewStyle;
}

interface State {
    smallBadge: boolean;
}

export default class Badge extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            smallBadge: false,
        };
    }

    componentDidMount() {
        this.changeBadge();
    }

    componentDidUpdate(props) {
        if (props.count != this.props.count) {
            this.setState({ smallBadge: false });
            // vibrate(VibrationType.NOTIFICATION)
            this.changeBadge();
        }
    }

    changeBadge() {
        setTimeout(() => {
            this.setState({ smallBadge: true });
        }, 10000);
    }

    render() {
        return (
            <View
                style={[
                    styles.wrapper,
                    this.props.containerSyle,
                    this.state.smallBadge && this.props.smallBadgeContainer,
                ]}
            >
                {this.props.count > 0 && (
                    <View>
                        {this.state.smallBadge ? (
                            <View
                                style={[
                                    styles.badge,
                                    {
                                        borderRadius: 9,
                                        overflow: "hidden",
                                        height: 16,
                                        width: 16,
                                        paddingHorizontal: 0,
                                        paddingVertical: 0,
                                    },
                                    this.props.smallBadgeStyle,
                                ]}
                            ></View>
                        ) : (
                            <View style={[styles.badge, this.props.badgeStyle, { minWidth: 30, }]}>
                                <BoldText numberOfLines={1} color={colors.labelText || colors.white} align="center" fontSize={10}>
                                    {this.props.count < 100 ? this.props.count : "+9"}
                                </BoldText>
                            </View>
                        )}
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        position: "absolute",
        bottom: 6,
        right: 14,
        zIndex: 100,
    },
    badge: {
        backgroundColor: colors.complementary || colors.secondary,
        borderRadius: 15,
        overflow: "hidden",
        paddingVertical: 2,
        paddingTop: 1,
        borderColor: colors.gradientFrom,
        borderWidth: 3,
        justifyContent: "center",
    },
    flexText: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: 10,
        bottom: 10,
    },
});
