import React, { Component } from 'react';
import i18n from '@i18n/i18n';
import {  View, StyleSheet, Dimensions, ScrollView } from 'react-native';
import BoldText from '@base/BoldText';
import { colors, settings } from '@styles/globalStyles';
import Icon from '@components/icons';
import RegularText from '@base/RegularText';
import Card from '@base/Card';
import InlineButton from '@base/InlineButton';
import PressableBase from '@base/PressableBase';

const { t } = i18n;

interface Props {
    isVideoRecorded: boolean,
    prevStep: () => void,
    openCamera: (camera: 'idCardFront' | 'idCardBack' | 'selfie' | 'video') => Promise<void>
}

interface State {
    imageHight: number,
    imageWidth: number,
    onPress?: () => void
}

export default class StepFourKYC extends Component<Props, State> {
    camera: any;
    constructor(props: Props) {
        super(props);

        this.state = {
            imageHight: 300,
            imageWidth: Dimensions.get("window").width,
        };
    }

    render() {
        return (
            <View style={styles.flex}>
                <View style={{ flex: 1 }}>
                    <RegularText align={"center"} style={{ marginTop: 16, marginBottom: 10 }}>
                        {t("facial_verification")}
                    </RegularText>
                    <View style={{ flex: 1 }}>
                        <ScrollView>
                            <Card style={{ alignItems: "baseline", flex: 1 }}>
                                {!this.props.isVideoRecorded &&
                                    <View style={{ flex: 1 }}>
                                        <BoldText fontSize={14} style={{ marginBottom: 10 }}>
                                            {t("video_title")}
                                        </BoldText>
                                        <RegularText fontSize={14} style={{ marginBottom: 10 }}>
                                            {t("video_description")}
                                        </RegularText>
                                        <View style={{ flex: 1 }}>
                                            <PressableBase
                                                style={styles.imagePlaceholderContainer}
                                                onPress={() => this.props.openCamera('video')}
                                            >
                                                <RegularText style={{ marginBottom: 10 }}>{t("record_video")}</RegularText>
                                                <Icon
                                                    name="selfie"
                                                    size={70}
                                                    color={colors.grey}
                                                />
                                            </PressableBase>
                                        </View>
                                    </View>
                                }
                            </Card>
                        </ScrollView>
                    </View>
                </View>
                <InlineButton
                    title={t("previous")}
                    onPress={this.props.prevStep}
                    style={{ marginVertical: 15 }}
                />
            </View>
        )
    }
}


const styles = StyleSheet.create({
    flex: {
        flex: 1,
        justifyContent: "space-between",
    },
    imagePlaceholderContainer: {
        marginVertical: 15,
        width: "100%",
        height: 150,
        borderColor: colors.grey,
        backgroundColor: colors.shadow,
        borderWidth: 1,
        borderRadius: settings.cardRadius,
        borderStyle: "dashed",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    icon: {
        height: 40,
        width: 40,
        borderRadius: 20,
        padding: 10,
        backgroundColor: colors.secondary,
        overflow: "hidden",
    },
    camera: {
        padding: 10,
        position: 'absolute',
        width: "100%",
        opacity: 0.7,
        backgroundColor: colors.background,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center"
    },
    cameraButton: {
        height: 40,
        width: 40,
        backgroundColor: colors.complementary,
        borderRadius: 100
    }
});




