import RegularText from "@base/RegularText";
import { colors, settings } from "@styles/globalStyles";
import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import i18n from "@i18n/i18n";
import NFT, { NFTsHistoryTransaction } from "@custom-types/NFTModel";
import AvatarBase from "@components/avatar/AvatarBase";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import Icon from "@components/icons";
import Row from "@base/Row";
import BoldText from "@base/BoldText";
import ImageBase from "@components/image/ImageBase";
import Wallet from "@core/wallet/Wallet";
import Currency from "@core/currencies/Currency";
import { getColorOpacity } from "@utils/helpers/global/global";
import { format } from "date-fns";
import PressableBase from "@base/PressableBase";

interface Props {
    nftTransaction: NFTsHistoryTransaction;

    onPress?: () => void;
    onPressAvatar?: (id: string) => void;
}

interface State {
    currency: Currency;
    date: string;
    historyType: "SELL" | "BUY" | "SEND" | "RECEIVED" | "";
}

const { t } = i18n;

export default class NFTWideCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currency: Wallet.getInstance().findCurrencyByBlockchain(
                this.props.nftTransaction.nft?.blockchain
            ),
            date: format(new Date(this.props.nftTransaction.timestamp), "MMM dd, yyyy H:mma"),
            historyType:
                this.props.nftTransaction?.marketplace !== null
                    ? this.props.nftTransaction?.type
                    : (this.props.nftTransaction?.type == "SELL" && "SEND") ||
                      (this.props.nftTransaction?.type == "BUY" && "RECEIVED") ||
                      "",
        };
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <View style={styles.wrapper}>
                    <Row>
                        <ImageBase
                            style={{ height: 90, width: 90 }}
                            uri={this.props.nftTransaction.nft.image?.thumbnail}
                        />
                        <View
                            style={{
                                position: "absolute",
                                bottom: 10,
                                left: 0,
                                alignItems: "center",
                                zIndex: 99,
                                overflow: "hidden",
                                borderTopRightRadius: settings.cardRadius,
                                borderBottomRightRadius: settings.cardRadius,
                            }}
                        >
                            <BoldText
                                fontSize={10}
                                color={"white"}
                                style={{
                                    paddingVertical: 2,
                                    paddingHorizontal: 6,

                                    textTransform: "uppercase",
                                    backgroundColor: getColorOpacity(colors.complementary || colors.secondary, 0.7),
                                }}
                            >
                                {this.state.historyType}
                            </BoldText>
                        </View>

                        <View style={styles.priceWrapper}>
                            <Row
                                style={[
                                    styles.priceRow,
                                    { backgroundColor: getColorOpacity(this.state.currency.getColor(), 0.6) },
                                ]}
                            >
                                <Icon size={12} name={this.state.currency.getIcon()} color={"white"}></Icon>

                                {this.props.nftTransaction?.price !== 0 && (
                                    <BoldText color={"white"} fontSize={12}>
                                        {" "}
                                        {this.state.currency.fromDecimalsToString(this.props.nftTransaction.price)}
                                    </BoldText>
                                )}
                            </Row>
                        </View>

                        <View style={styles.middleWrapper}>
                            <View style={{ flex: 1, justifyContent: "space-between" }}>
                                <Row style={{ marginTop: -6, alignItems: "center" }}>
                                    <BoldText fontSize={20}>
                                        {trimHelper(this.props.nftTransaction.nft.name, 12)}
                                    </BoldText>
                                    <RegularText fontSize={18}>
                                        {" #"}
                                        {this.props.nftTransaction.nft.tokenId}
                                    </RegularText>
                                </Row>
                            </View>

                            <View>
                                <RegularText fontSize={10}>{this.state.date}</RegularText>
                            </View>

                            {(this.state.historyType == "SELL" || this.state.historyType == "SEND") && (
                                <PressableBase
                                    onPress={() =>
                                        this.props.onPressAvatar(this.props.nftTransaction?.clientTo?._id || "")
                                    }
                                >
                                    <Row style={{ alignItems: "center", marginTop: 14 }}>
                                        <AvatarBase
                                            size={20}
                                            uri={this.props.nftTransaction.clientTo.profileImagePath?.thumbnail || ""}
                                            alias={this.props.nftTransaction.clientTo.alias}
                                            overlayContainerStyle={colors.secondaryShadow}
                                        ></AvatarBase>
                                        <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                                            {this.state.historyType == "SEND" ? t("send_to") : t("sold_to")}
                                        </RegularText>
                                        <BoldText fontSize={14} style={{ paddingLeft: 5 }}>
                                            {trimHelper(this.props.nftTransaction.clientTo.alias, 16)}
                                        </BoldText>
                                    </Row>
                                </PressableBase>
                            )}
                            {(this.state.historyType == "BUY" || this.state.historyType == "RECEIVED") && (
                                <PressableBase
                                    onPress={() =>
                                        this.props.onPressAvatar(this.props.nftTransaction?.clientFrom?._id || "")
                                    }
                                >
                                    <Row style={{ alignItems: "center", marginTop: 14 }}>
                                        <AvatarBase
                                            size={20}
                                            uri={this.props.nftTransaction.clientFrom.profileImagePath?.thumbnail || ""}
                                            alias={this.props.nftTransaction.clientFrom.alias}
                                            overlayContainerStyle={colors.secondaryShadow}
                                        ></AvatarBase>
                                        <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                                            {this.state.historyType == "RECEIVED" ? t("received_from") : t("buyed_to")}
                                        </RegularText>
                                        <BoldText fontSize={14} style={{ paddingLeft: 5 }}>
                                            {trimHelper(this.props.nftTransaction.clientFrom.alias, 16)}
                                        </BoldText>
                                    </Row>
                                </PressableBase>
                            )}
                        </View>
                    </Row>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: colors.shadow,
        flex: 1,
        marginHorizontal: 5,
        marginVertical: 5,
        height: 90,
        borderRadius: settings.cardRadius,
        overflow: "hidden",
    },
    middleWrapper: {
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
    priceWrapper: {
        position: "absolute",
        top: 10,
        right: 10,
    },
    priceRow: {
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        height: 22,
        borderRadius: settings.cardRadius,
        paddingHorizontal: 6,
    },
});
