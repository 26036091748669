import { Client, AuthType } from "@custom-types/Client";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";

import ClientHistoryStorage from "@utils/storage/storages/ClientHistoryStorage";
import { IOAuthHistoryStorage, OAuthHistoryStorage } from "@utils/storage/storages/OAuthHistoryStorage";
import SeedHistorySecureStorage from "@utils/storage/storages/SeedHistorySecureStorage";
import WalletSecureStorage from "@utils/storage/storages/WalletSecureStorage";
import { Storage } from "../../../utils/storage/Storage";
import { ClientData } from "./providers/OAuthProvider";

export class OAuthHistoryService {
    static async removeSeedHistory(mnemonic: string) {
        //@TODO: Remove in case mnemonic is repeated
        let history: IOAuthHistoryStorage[] = (await OAuthHistoryStorage.get()) || [];
        const i = history.findIndex((element) => element.mnemonic === mnemonic);
        if (i != -1) {
            const removed = history.splice(i, 1)[0];

            await OAuthHistoryStorage.set(history);
            await this.removeClientHistory(removed.clientId);
        }

        return history;
    }

    static async getItemSeedHistory(mnemonic: string) {
        let history: IOAuthHistoryStorage[] = (await OAuthHistoryStorage.get()) || [];
        const i = history.findIndex((element) => element.mnemonic === mnemonic);
        if (i != -1) {
            return i;
        }
        return;
    }

    static async addSeedHistory(client: Client, mnemonic: string, authType: string, email: string) {
        let history: IOAuthHistoryStorage[] = (await OAuthHistoryService.removeSeedHistory(mnemonic)) || [];
        history.push({ clientId: client._id, mnemonic, authType, email });
        await OAuthHistoryStorage.set(history);
        await this.addClientHistory(client);
    }

    static async getSeedHistory(client: Partial<ClientData>) {
        let history: IOAuthHistoryStorage[] = (await OAuthHistoryStorage.get()) || [];

        if (history.length == 0) {
            const seedHistory = await SeedHistorySecureStorage.get();
            if (seedHistory) {
                for (let h of seedHistory) {
                    if (h.client_id) {
                        try {
                            const c: Client = await this.getClientFromHistory(h.client_id);
                            await this.addSeedHistory(c, h.mnemonic, AuthType.ANON, null);
                        } catch (e) {}
                    } else {
                        try {
                            const walletSecure = await WalletSecureStorage.get();
                            if (walletSecure && walletSecure?.mnemonic && h.mnemonic == walletSecure?.mnemonic) {
                                const auth = await Storage.getInstance().getItem("auth4.0", {
                                    isSecure: true,
                                    oldKey: "auth4.0",
                                });
                                if (auth && auth?.client) {
                                    const c: Client = await this.getClientFromHistory(auth?.client?._id);
                                    await this.addSeedHistory(c, walletSecure?.mnemonic, AuthType.ANON, null);
                                }
                            }
                        } catch (e) {}
                    }
                }
                history = (await OAuthHistoryStorage.get()) || [];
            }
        }

        if (client) {
            if (client.authType != AuthType.ANON) {
                history = history.filter((i) => i.email == client.email || i.authType == AuthType.ANON);
            }
        }

        for (let i in history) {
            history[i] = {
                ...history[i],
                client: await OAuthHistoryService.getClientFromHistory(history[i].clientId),
            };
        }
        return history;
    }

    static async addClientHistory(client: Client) {
        let history: Client[] = (await ClientHistoryStorage.get()) || [];
        history.push(client);
        await ClientHistoryStorage.set(history);
    }

    static async removeClientHistory(clientId: string) {
        let history: Client[] = (await ClientHistoryStorage.get()) || [];
        history = history.filter((h) => {
            return h._id !== clientId;
        });

        await ClientHistoryStorage.set(history);
    }

    static async getClientFromHistory(clientId: string) {
        let history: Client[] = (await ClientHistoryStorage.get()) || [];

        let client: Client = history.find((h) => {
            return h._id === clientId;
        });

        const clientData = await SocialNetworkService.getInstance().getAccount(clientId);

        if (client) {
            if (clientData) {
                client.profileImagePath = clientData?.profileImagePath;
                client.alias = clientData?.alias || client.alias;
            }
        } else {
            client = Client.create(clientData);
        }

        return client;
    }
}


