import { IWalletConnectStore } from "@core/services/WalletConnectDataService";
import { ADD_CONNECTION, SET_CONNECTIONS } from "@store/actions/walletconnect.actions";

interface IWalletConnectReducerState {
    connections: Array<any>
}

const initalSatate: IWalletConnectReducerState = {
    connections: []
};

const WalletConnectReducer = (state = initalSatate, action) => {
  switch(action.type) {

    case ADD_CONNECTION:
        return {
            ...state,
            connections: [ ...new Set([...state.connections, action.connection])],
        }

    case SET_CONNECTIONS:
        return {
            ...state,
            connections: action.connections || state.connections,
        }

    default:
      return state;
  }
}


export default WalletConnectReducer;