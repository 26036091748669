import { Client } from "@custom-types/Client";
import { ObjectStorage } from "../ObjectStorage";

interface IOAuthHistoryStorage {
    mnemonic: string
    clientId: string
    authType: string
    email:string
    client?:Client
}

class OAuthHistoryStorage_ extends ObjectStorage {
    public isSecure = true;
    public key = 'oAuthHistory';
}

const OAuthHistoryStorage = OAuthHistoryStorage_.getInstance(OAuthHistoryStorage_);

export { OAuthHistoryStorage, IOAuthHistoryStorage }