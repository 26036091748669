import {Client} from "@custom-types/Client"
import { FollowerRequestType } from "@custom-types/FollowerRequestType"
import i18n from "@i18n/i18n"

const {t} = i18n

export function isAvatarAndAliasSet(client: Client) {
    if (client && client.alias == client.address || client && !client.profileImagePath) {
        return false
    } else {
        return true
    }
}

export function  setTimeVariables(date) {
    const now = Date.now()
    const postTime = Date.parse(date)
    const result = now - postTime
    if (result < 60000) {
        return `${t("now")}✨`
    }

    if (result > 60000 && result < 3600000) {
        return `${Math.round(result / 1000 / 60)} ${t("minutes_ago")}`
    }

    if (result > 3600000 && result < 86400000) {
        return `${Math.round(result / 1000 / 60 / 60)} ${t("hours_ago")}`
    }

    if (result > 86400000 && result < 2592000000) {
        return `${Math.round(result / 1000 / 60 / 60 / 24)} ${t("days_ago")}`
    }
}

export function nameSocialLengthHelper(name: string, amount?: number) {
    try {
        return (name.length > (amount ? amount : 12)) ? `${name.substring(0, amount ? amount -1 : 11)}...` : name
    } catch (e) {
        return '';
    }
}




