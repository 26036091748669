import RegularText from "@base/RegularText";
import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import Icon from "@components/icons";
import { colors } from "@styles/globalStyles";
import Currency from "@core/currencies/Currency";

import i18n from "@i18n/i18n";
import NFT from "@custom-types/NFTModel";
import ImageBase from "@components/image/ImageBase";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import BoldText from "@base/BoldText";
import Row from "@base/Row";
import { getColorOpacity } from "@utils/helpers/global/global";
import CurrencyIcon from "@components/accessories/CurrencyIcon";

interface Props {
    currency: Currency;
    nft: Partial<NFT>;
    network: string;
    owner: boolean;
    isTransfer: boolean;
}

interface State {}

const { t } = i18n;

export default class NFTsMessage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <View style={{ overflow: "hidden", padding: 5, marginTop: -5 }}>
                {this.props.isTransfer && (
                    <View
                        style={{
                            position: "absolute",
                            top: 20,
                            left: 5,
                            zIndex: 99,
                            backgroundColor: colors.grey,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                        }}
                    >
                        <BoldText
                            style={{ textTransform: "uppercase", paddingHorizontal: 8, paddingVertical: 1 }}
                            align={"left"}
                            fontSize={12}
                        >
                            {this.props.owner ? t("sent") : t("received")}
                        </BoldText>
                    </View>
                )}
                <ImageBase
                    style={{
                        width: "100%",
                        height: 125,
                        resizeMode: "cover",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                    }}
                    uri={this.props.nft?.image?.thumbnail || ""}
                ></ImageBase>

                {this.props.network?.toLowerCase() == "testnet" && (
                    <View style={styles.testnet}>
                        <BoldText color={colors.white} align="center" fontSize={8}>
                            TESTNET
                        </BoldText>
                    </View>
                )}
                {this.props.nft.isDraft ||
                    (!this.props.nft.tokenId && (
                        <View style={styles.draft}>
                            <BoldText color={colors.text} align="center" fontSize={8}>
                                {t("draft")}
                            </BoldText>
                        </View>
                    ))}

                <View style={styles.container}>
                    <Row style={styles.row}>
                        {this.props.currency && <CurrencyIcon size={20} iconSize={10} currency={this.props.currency} />}

                        <BoldText style={{ paddingLeft: 6 }}>
                            {trimHelper(this.props.nft.name, 22)}
                            {this.props.nft.tokenId && (
                                <RegularText>
                                    {" #"}
                                    {this.props.nft.tokenId}
                                </RegularText>
                            )}
                        </BoldText>
                    </Row>
                    <View style={{ marginTop: 10, paddingHorizontal: 5 }}>
                        <RegularText color={colors.grey} align={"justify"} fontSize={10}>
                            {this.props.isTransfer
                                ? this.props.owner
                                    ? t("info_send_NFT")
                                    : t("info_receive_NFT")
                                : t("info_NFT")}
                        </RegularText>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        minWidth: 230,
        paddingHorizontal: 0,
        paddingTop: 10,
        paddingBottom: 5,
    },
    row: {
        alignContent: "center",
        alignItems: "center",
    },
    testnet: {
        position: "absolute",
        top: 11,
        right: 11,
        paddingVertical: 2,
        paddingHorizontal: 4,
        backgroundColor: colors.complementary || colors.secondary,
        borderRadius: 5,
    },
    draft: {
        position: "absolute",
        top: 11,
        right: 11,
        paddingVertical: 2,
        paddingHorizontal: 4,
        backgroundColor: getColorOpacity(colors.yellow, 0.8),
        borderRadius: 5,
    },
});
