import BoldText from "@base/BoldText";
import BotCard from "@base/BotCard";
import Card from "@base/Card";
import Container from "@base/Container";
import InlineButton from "@base/InlineButton";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import AvatarBase from "@components/avatar/AvatarBase";
import { Header } from "@components/header/Header";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Currency from "@core/currencies/Currency";
import { Skeleton } from "@core/currencies/CurrencyImplementation";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import { WalletService } from "@core/services/WalletService";
import Wallet from "@core/wallet/Wallet";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { ExchangeNavigatorScreens } from "@navigation/ExchangeNavigator";
import { Route } from "@react-navigation/native";
import { loading, ready, setMessageLoader, showPopup, showPopupMessage } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import BigNumber from "bignumber.js";
import React, { Component } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    route: Route<string, { rawSwap }>;
    fiatCurrency: FiatCurrency;
}

interface State {
    skeleton: Skeleton;
}

const { t } = i18n;

export class _ApproveExchangeScreen extends Component<Props, State> {
    private currency: Currency;
    private fiatCurrency: FiatCurrency;

    constructor(props: Props) {
        super(props);

        this.currency = Wallet.getInstance().findCurrencyById(
            props.route?.params?.rawSwap?.extraTransactionData?.fromCurrency
        );
        this.fiatCurrency = this.props.fiatCurrency;
        const skeleton = this.currency
            .getImplementation()
            .parseSkeleton(this.props.route?.params?.rawSwap?.extraTransactionData?.approvalTransaction);
        this.state = {
            skeleton: skeleton,
        };

        this.onConfirm = this.onConfirm.bind(this);
    }

    componentDidMount() {
        if (!this.props.route?.params?.rawSwap?.extraTransactionData?.approvalTransaction) {
            showPopup({ type: "ERROR", message: t("an_error_has_occurred") });
        }
    }

    onConfirm() {
        WalletService.getInstance().confirmTransaction({
            currency: this.currency,
            skeleton: this.props.route?.params?.rawSwap?.extraTransactionData?.approvalTransaction,
            onSuccess: (resp) => {
                store.dispatch(setMessageLoader(`${t("approving")} 😉`));
                setTimeout(() => {
                    store.dispatch(ready());
                    store.dispatch(showPopup({ type: "SUCCESS", message: t("transaction_success") }));
                    this.props.navigation.navigate(ExchangeNavigatorScreens.ConfirmExchange.routeName, {
                        rawSwap: this.props.route?.params?.rawSwap,
                    });
                }, 5000);
            },
            onError: (error) => {
                console.warn(error);
                store.dispatch(showPopup({ type: "ERROR", message: t("an_error_has_occurred") }));
            },
        });
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("approve_tokens")} type={HeaderType.Light} {...this.props} />
                <ScrollView showsVerticalScrollIndicator={false}>
                    {this.props.route?.params?.rawSwap?.extraTransactionData?.approvalTransaction && (
                        <Container style={styles.container}>
                            <BotCard message={t("approve_tokens_message")} />
                            <Card>
                                <View
                                    style={{
                                        width: "100%",
                                        borderBottomColor: colors.grey,
                                        borderBottomWidth: 0.5,
                                        paddingBottom: 20,
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                        {t("amount")}
                                    </RegularText>

                                    <CurrencyBalanceIcon
                                        amount={this.state.skeleton?.amount}
                                        fontSize={16}
                                        iconSize={20}
                                        fontWeight={"bold"}
                                        currency={this.currency}
                                        style={{ paddingBottom: 10 }}
                                    />

                                    <View style={{ alignItems: "flex-start" }}>
                                        <CurrencyBalanceIcon
                                            style={{
                                                opacity: 0.9,
                                                backgroundColor: colors.tertiaryShadow,
                                                paddingHorizontal: 6,
                                                paddingVertical: 4,
                                                borderRadius: 10,
                                                overflow: "hidden",
                                            }}
                                            fiatCurrency={this.fiatCurrency}
                                            amount={this.currency.toFiat(Number(this.state.skeleton?.amount))}
                                        />
                                    </View>
                                </View>

                                <View
                                    style={{
                                        width: "100%",
                                        marginBottom: 20,
                                        borderBottomColor: colors.grey,
                                        borderBottomWidth: 0.5,
                                        paddingVertical: 20,
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                        {t("fee")}
                                    </RegularText>
                                    <CurrencyBalanceIcon
                                        amount={this.state.skeleton?.feeData?.amount}
                                        fontSize={16}
                                        iconSize={20}
                                        fontWeight={"bold"}
                                        currency={Wallet.getInstance().findCurrencyById(
                                            this.state.skeleton?.feeData?.digitalCurrencyId
                                        )}
                                        style={{ paddingBottom: 10 }}
                                    />

                                    <View style={{ alignItems: "flex-start" }}>
                                        <CurrencyBalanceIcon
                                            style={{
                                                opacity: 0.9,
                                                backgroundColor: colors.tertiaryShadow,
                                                paddingHorizontal: 6,
                                                paddingVertical: 4,
                                                borderRadius: 10,
                                                overflow: "hidden",
                                            }}
                                            fiatCurrency={this.fiatCurrency}
                                            amount={Wallet.getInstance()
                                                .findCurrencyById(this.state.skeleton?.feeData?.digitalCurrencyId)
                                                ?.toFiat(this.state?.skeleton?.feeData?.amount)}
                                        />
                                    </View>
                                </View>

                                <View style={{ width: "100%" }}>
                                    {this.state.skeleton?.exchangeData?.exchangeProvider?.name?.length > 0 && (
                                        <View
                                            style={{
                                                width: "100%",
                                                borderBottomColor: colors.grey,
                                                borderBottomWidth: 0.5,
                                                paddingBottom: 20,
                                            }}
                                        >
                                            <RegularText color={colors.text}>{t("provider")}</RegularText>
                                            <Row style={{ alignContent: "center", alignItems: "center" }}>
                                                <AvatarBase
                                                    uri={this.state.skeleton?.exchangeData?.exchangeProvider?.image}
                                                    overlayContainerStyle={{ marginRight: 5 }}
                                                    size={18}
                                                    alias={this.state.skeleton?.exchangeData?.exchangeProvider?.name}
                                                ></AvatarBase>
                                                <SemiBoldText numberOfLines={1} color={colors.text} style={{}}>
                                                    {this.state.skeleton?.exchangeData?.exchangeProvider?.name}
                                                </SemiBoldText>
                                            </Row>
                                        </View>
                                    )}

                                    <View
                                        style={{
                                            width: "100%",
                                            borderBottomColor: colors.grey,
                                            borderBottomWidth: 0.5,
                                            paddingBottom: 20,
                                            paddingTop: 20,
                                        }}
                                    >
                                        <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                            {t("sending_to")}
                                        </RegularText>
                                        <BoldText color={colors.text} style={{ marginBottom: 5 }}>
                                            {trimHelper(this.state.skeleton?.sendingTo, 30, true)}
                                        </BoldText>
                                    </View>
                                </View>
                            </Card>

                            <View style={styles.button}>
                                <InlineButton
                                    onPressPriority={"low"}
                                    onPress={this.onConfirm}
                                    title={t("approve")}
                                    vibrate={true}
                                />
                            </View>
                        </Container>
                    )}
                </ScrollView>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
        paddingVertical: 20,
    },
    button: {
        marginVertical: 25,
    },
    card: {},
    swipContainer: {
        flex: 1,
        height: 58,
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const ApproveExchangeScreen = connect(mapStateToProps, mapDispatchToProps)(_ApproveExchangeScreen);

export default ApproveExchangeScreen;
