import CoincaexBuyScreen from "@screens/wallet/buy/coincaex/screens/buy/CoincaexBuyScreen"
import NavigationInstantiator from "./helpers/NavigationInstantiator"
import CoincaexLoginScreen from "@screens/wallet/buy/coincaex/screens/CoincaexLoginScreen"
import CoincaexSignUpScreen from "@screens/wallet/buy/coincaex/screens/CoincaexSignUpScreen"
import CoincaexHomeScreen from "@screens/wallet/buy/coincaex/screens/CoincaexHomeScreen"
import CoincaexTransactionsScreen from "@screens/wallet/buy/coincaex/screens/CoincaexTransactions"
import CoincaexSellScreen from "@screens/wallet/buy/coincaex/screens/sell/CoincaexSellScreen"
import CoincaexBuyBanksScreen from "@screens/wallet/buy/coincaex/screens/buy/CoincaexBuyBanksScreen"
import CoincaexAddBankScreen from "@screens/wallet/buy/coincaex/screens/CoincaexAddBankScreen"
import CoincaexSellBanksScreen from "@screens/wallet/buy/coincaex/screens/sell/CoincaexSellBanksScreen"
import CoincaexBuyPaymentScreen from "@screens/wallet/buy/coincaex/screens/buy/CoincaexBuyPaymentScreen"
import CoincaexBuyDetailScreen from "@screens/wallet/buy/coincaex/screens/buy/CoincaexBuyDetailScreen"
import CoincaexSellDetailScreen from "@screens/wallet/buy/coincaex/screens/sell/CoincaexSellDetailScreen"
import CoincaexSendCryptoScreen from "@screens/wallet/buy/coincaex/screens/sell/CoincaexSendCryptoScreen"
import CoincaexConfirmSendCryptoScreen from "@screens/wallet/buy/coincaex/screens/sell/CoincaexConfirmSendCryptoScreen"
import CoincaexSellAddBankScreen from "@screens/wallet/buy/coincaex/screens/sell/CoincaexSellAddBankScreen"

const CoincaexNavigatorScreens = {
    CoincaexHome: {
        routeName: "CoincaexHome",
        title: "CoincaexHome",
        component: CoincaexHomeScreen,
    },
    Login: {
        routeName: "Login",
        title: "Login",
        component: CoincaexLoginScreen,
    },
    SignUp: {
        routeName: "SignUp",
        title: "SignUp",
        component: CoincaexSignUpScreen
    },
    Transactions: {
        routeName: "Transactions",
        title: "Transactions",
        component: CoincaexTransactionsScreen
    },
    AddBank: {
        routeName: "AddBank",
        title: "AddBank",
        component: CoincaexAddBankScreen
    },
    Buy: {
        routeName: "Buy",
        title: "Buy",
        component: CoincaexBuyScreen,
    },
    BuyBanks: {
        routeName: "BuyBanks",
        title: "BuyBanks",
        component: CoincaexBuyBanksScreen,
    },
    BuyPayment: {
        routeName: "BuyPayment",
        title: "BuyPayment",
        component: CoincaexBuyPaymentScreen,
    },
    BuyDetail: {
        routeName: "BuyDetail",
        title: "BuyDetail",
        component: CoincaexBuyDetailScreen,
    },
    Sell: {
        routeName: "Sell",
        title: "Sell",
        component: CoincaexSellScreen,
    },
    SellBanks: {
        routeName: "SellBanks",
        title: "SellBanks",
        component: CoincaexSellBanksScreen,
    },
    SellDetail: {
        routeName: "SellDetail",
        title: "SellDetail",
        component: CoincaexSellDetailScreen,
    },
    Send: {
        routeName: "Send",
        title: "Send",
        component: CoincaexSendCryptoScreen,
    },
    ConfirmSend: {
        routeName: "ConfirmSend",
        title: "ConfirmSend",
        component: CoincaexConfirmSendCryptoScreen,
    },
    AddBankSell: {
        routeName: "AddBankSell",
        title: "AddBankSell",
        component: CoincaexSellAddBankScreen
    }
    
}

const CoincaexNavigator = NavigationInstantiator(CoincaexNavigatorScreens)

export { CoincaexNavigator, CoincaexNavigatorScreens }
