import * as React from "react";
import Svg, { Defs, G, Path } from "react-native-svg";

function Logo(props) {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" height={60} width={80} viewBox="0 0 39.687499 26.458333" {...props}>
            <Path
                className="cls-80"
                d="M7.967 11.804c.143-.33-.119-.715.14-1.03.032-.026.067-.07.104-.07 1.563.018 2.85-.85 4.183-1.44 1.72-.762 3.352-1.765 4.67-3.17.13-.138.198-.323.333-.46.392-.297.688-.776 1.289-.686.124.583-.212.998-.532 1.413-1.008 1.307-2.36 2.18-3.768 2.998-.907.527-1.913.865-2.778 1.466a6.714 6.714 0 00-.828.563c-.865.27-1.725.561-2.654.519a.344.344 0 01-.159-.106z"
                fill="#e13265"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-3"
                d="M17.513 5.71c-.55.858-1.365 1.434-2.15 2.048-.712.553-1.583.831-2.29 1.4-.595.479-1.428.47-2.084.857-.09.053-.22.053-.289.12-.738.737-1.698.552-2.592.642.108-.198-.112-.463.13-.632.782-.357 1.645-.521 2.41-.924 2.29-1.2 4.693-2.24 6.397-4.32.307-.373.416-.89.863-1.151a.28.28 0 01.272.066c.178.773-.17 1.36-.664 1.894z"
                fill="#e6385d"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-153"
                d="M30.09 17.238a6.071 6.071 0 01-.763 1.522c-.42.42-.926.73-1.402 1.08-1.225.896-2.355 1.907-3.48 2.923-.41.37-.947.55-1.344.936-.68.331-1.402.48-2.153.487-.045-.296.177-.412.38-.513.916-.455 1.694-1.093 2.43-1.791.457-.434 1.058-.648 1.55-1.061 1.397-1.175 2.852-2.286 4.27-3.442.146-.12.305-.236.516-.143z"
                fill="#4699bc"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-127"
                d="M30.09 17.238c-.607.297-1.067.794-1.596 1.194-1 .754-1.953 1.571-2.932 2.357-1.103.886-2.31 1.64-3.363 2.588-.375.336-.976.317-1.254.804-.325.146-.664.143-1.008.114.31-.466.786-.783 1.244-1.016 1.325-.678 2.272-1.794 3.389-2.712.495-.429 1.045-.783 1.611-1.109.672-.383 1.01-1.114 1.657-1.537.735-.484 1.47-.982 2.124-1.588.135-.124.294-.261.503-.113-.04.37-.143.714-.373 1.016z"
                fill="#4990b8"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-11"
                d="M17.894 3.808c-.904 1.926-2.59 3.032-4.336 4.05-1.429.834-2.892 1.615-4.471 2.136-.27.09-.567.098-.85.146.074-.162-.098-.384.13-.506 1.159-.487 2.193-1.235 3.42-1.59.668-.193 1.194-.706 1.784-1.072 1.46-.9 2.77-1.979 3.733-3.426a.358.358 0 01.175-.069c.217.014.357.122.415.334z"
                fill="#e73c57"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-10"
                d="M17.386 3.557c-.29.69-.799 1.19-1.333 1.709-1.124 1.095-2.477 1.844-3.752 2.717-.357.244-.738.289-1.1.41-.802.27-1.43.86-2.23 1.12-.205.066-.392.126-.604.124l.288-1.003c.297-.018.572-.098.844-.225a60.914 60.914 0 003.898-1.958 5.481 5.481 0 001.299-.997c.703-.723 1.524-1.323 2.19-2.088.21-.05.379.008.5.19z"
                fill="#e74152"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-159"
                d="M30.825 14.582c.013.22.084.447-.106.63-.418.621-1.024 1.047-1.609 1.484-.79.593-1.574 1.185-2.278 1.892-.907.913-2.053 1.526-3.08 2.291-.857.249-1.317 1.069-2.095 1.437-.222.106-.416.301-.69.161-.14-.344.147-.484.333-.648.233-.207.492-.386.711-.611 1.725-1.434 3.472-2.845 5.292-4.16.18-.145.333-.32.516-.46a.796.796 0 01.156-.082c.767-.492 1.577-.926 2.236-1.574.17-.167.307-.413.611-.363z"
                fill="#4f7fb3"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-95"
                d="M23.099 23.7c.092-.162.137-.363.312-.464 1.6-.93 2.768-2.405 4.297-3.423.534-.357.99-.842 1.62-1.053-1.45 2.431-3.504 4.103-6.227 4.94z"
                fill="#43a1bf"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-150"
                d="M11.42 21.038c-.227-.109-.378-.294-.516-.495.127-.476.561-.397.87-.41 1.014-.045 1.932-.41 2.848-.77.666-.262 1.373-.384 2.037-.667.67-.286 1.288-.616 1.823-1.108.304-.135.529.04.762.193.407.373.103.624-.193.78-.725.379-1.405.86-2.207 1.077-1.236.336-2.402.884-3.649 1.188-.576.14-1.166.32-1.78.212z"
                fill="#5f29a7"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-120"
                d="M18.53 17.643c-.297.654-.961.823-1.522 1.109-.852.434-1.812.577-2.699.944a9.674 9.674 0 01-3.077.704c-.127.008-.254.024-.33.148-.22-.18-.482-.325-.495-.661.09-.379.436-.315.693-.379.257-.066.527-.1.791-.14 1.725-.069 3.278-.78 4.882-1.31.243-.079.4-.296.603-.447.389-.177.778-.285 1.15.032z"
                fill="#6326a4"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-6"
                d="M16.878 3.43c-.084.145-.14.317-.254.431-1.481 1.492-3.048 2.87-5.016 3.717-.804.347-1.59.741-2.376 1.13-.225.111-.418.199-.577-.077l.228-.386c.64-.415 1.312-.762 2.05-.968.572-.159.926-.688 1.44-.895 1.368-.553 2.275-1.637 3.273-2.63.349-.288.624-.693 1.137-.727.14.108.228.233.095.405z"
                fill="#e7464d"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-169"
                d="M23.736 20.786c.4-.529.998-.825 1.535-1.15.51-.31.968-.686 1.365-1.083 1.25-1.256 2.765-2.175 4.083-3.339 0 .36-.082.699-.257 1.014-.426.042-.587.45-.902.656-.765.502-1.672.931-2.188 1.632-.744 1.006-1.858 1.408-2.747 2.149-.468.325-.912.68-1.336 1.063-.225.204-.442.477-.817.357-.043-.513.394-.68.698-.92.175-.138.392-.225.566-.376z"
                fill="#4c88b5"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-8"
                d="M15.735 3.808a13.995 13.995 0 01-3.1 2.672c-1.12.72-2.41 1.043-3.488 1.802-.103.072-.19.069-.267-.037.045-.27.13-.526.283-.757.715-.121 1.28-.529 1.834-.957.053-.032.106-.056.161-.08 1.688-.775 3.093-1.91 4.27-3.336.157-.19.215-.598.633-.394.22.46-.15.743-.328 1.084z"
                fill="#e74a47"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-121"
                d="M11.42 21.038c1.156-.27 2.334-.469 3.45-.895 1.395-.531 2.847-.923 4.133-1.709.154-.092.558-.225.161-.532.62-.312 1.247-.584 1.966-.367.188.524-.304.502-.547.682-.36.053-.662.212-.963.424a12.276 12.276 0 01-3.496 1.7c-.653.202-1.28.522-1.92.77-.755.295-1.599.257-2.408.324l-.373-.395z"
                fill="#5c30a9"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-124"
                d="M27.906 4.959c.249.341.651.56.786.992.699 1.114.27 2.273.12 3.413-.056.429-.25.842-.363 1.265-.056.204-.143.397-.37.474-.305-.006-.628.042-.82-.283.745-1.784.851-3.615.401-5.488.027-.161 0-.362.249-.376z"
                fill="#5739ae"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-111"
                d="M11.925 19.519c-.492.18-1.05.082-1.516.365-.285-.111-.378-.38-.518-.614-.045-.341.222-.37.439-.404.826-.127 1.585-.43 2.31-.831.619-.344 1.317-.421 1.992-.57.347.08.746.006 1 .35-.68.524-1.455.87-2.265 1.122-.5.156-.99.323-1.442.582z"
                fill="#6f249e"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-138"
                d="M11.793 21.435c.524-.291 1.122-.331 1.683-.334.344 0 .571-.087.857-.227.553-.275 1.127-.543 1.72-.701 1.339-.355 2.535-.987 3.643-1.786.283-.204.543-.283.865-.262.05.341-.246.41-.444.534-1.516.955-3.059 1.855-4.768 2.429a1.763 1.763 0 00-.452.225c-.312.225-.654.389-1.04.384-.479-.008-.897.148-1.302.373-.28-.18-.59-.326-.762-.635z"
                fill="#5935ab"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-166"
                d="M30.698 10.274c.156.236.158.5.145.768a.355.355 0 01-.018.465c-.556.617-.873 1.384-1.35 2.048-.06.085-.148.151-.23.215-.63.545-1.222 1.13-1.791 1.735-.212.228-.426.519-.736.109.064-.558.403-.966.75-1.368.608-.482 1.327-.865 1.528-1.71.41-.597.82-1.193 1.16-1.838.11-.212.224-.463.544-.42z"
                fill="#5159b1"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-106"
                d="M28.05 11.031c.404-.825.727-1.69.692-2.614-.01-.27.125-.463.16-.696.092-.627-.178-1.18-.21-1.77.466.431.722.995 1.008 1.543.249.897-.137 1.685-.434 2.49-.196.531-.524 1.005-.672 1.56-.05.188-.153.405-.428.39-.196-.281-.22-.583-.117-.903z"
                fill="#533eb1"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-142"
                d="M28.174 11.915c.547-1.22 1.29-2.365 1.407-3.747.019-.227.08-.45.12-.677.248.344.383.738.51 1.135.204.513-.087.934-.3 1.344-.354.683-.56 1.421-.851 2.13-.161.238-.254.521-.447.736-.344.383-.559.32-.728-.21.042-.259-.116-.597.286-.711z"
                fill="#5348b1"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-12"
                d="M11.163 6.597h-.127c-.28.233-.64.31-.963.442-.2.082-.55.153-.43-.31l.248-.394c1.021-.262 1.905-.85 2.68-1.482.892-.73 1.78-1.542 2.286-2.64l.503-.175c.357.077.4.135.22.497-.32.643-.818 1.154-1.294 1.686-.895.997-2.051 1.624-3.123 2.376z"
                fill="#e7543c"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-9"
                d="M14.86 2.213c.188.248.018.441-.122.613-1.117 1.387-2.381 2.596-4.019 3.363-.188.088-.365.201-.545.31-.204.124-.26.01-.283-.164l.278-.373c1.378-.905 2.74-1.831 3.661-3.25l1.027-.5z"
                fill="#e75936"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-139"
                d="M23.736 20.786c-.32.527-.992.702-1.27 1.268-.06.415-.362.624-.7.796-.855.434-1.61 1.04-2.47 1.463l-1.126-.04c.1-.367.442-.484.717-.642.49-.283.963-.572 1.354-.99.178-.188.395-.391.704-.209.548-.053 1.016-.38 1.334-.728.426-.46.897-.732 1.457-.918z"
                fill="#4b84b7"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-4"
                d="M11.163 6.597c.37-.725 1.207-.85 1.757-1.355.969-.884 1.998-1.704 2.601-2.908.09-.182.042-.275-.159-.296l1.008-.35c.223.464-.09.718-.38.98-.85 1.103-1.802 2.103-2.964 2.886-.59.397-1.159.842-1.863 1.04z"
                fill="#e65142"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-23"
                d="M9.642 18.879c-.127-.146-.246-.291-.257-.495-.209-.368.05-.55.323-.577 1.241-.116 2.289-.788 3.44-1.156.336.09.664.188.685.622-.37.325-.81.494-1.278.65-.968.326-1.918.707-2.913.958z"
                fill="#7f2697"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-29"
                d="M13.18 16.736c-1.061.47-2.056 1.108-3.27 1.166-.241.011-.575.08-.525.48-.283-.173-.378-.443-.383-.755-.069-.098-.082-.233.034-.262 1.276-.307 2.493-.804 3.768-1.116.233.08.452.17.376.487z"
                fill="#882694"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-7"
                d="M13.833 2.713c.061.582-.442.902-.74 1.204-.757.762-1.665 1.373-2.506 2.05-.151.122-.278-.021-.418-.003.992-1.338 2.153-2.49 3.661-3.249z"
                fill="#e7622f"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-144"
                d="M9.642 18.879c-.029-.326.175-.334.429-.387.49-.1.968-.127 1.463-.4.701-.388 1.55-.528 2.307-.859.381-.124.64-.01.765.378-.762.223-1.567.31-2.262.747-.625.388-1.32.59-2.054.653-.187.016-.317.09-.397.26-.082-.133-.166-.263-.248-.395z"
                fill="#77259b"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-162"
                d="M20.942 22.435c-.171.071-.404.092-.505.22-.6.764-1.532 1.058-2.27 1.621-.302-.04-.606-.076-.908-.116-.142-.315.162-.341.307-.445 1.09-.754 2.294-1.323 3.392-2.063.223-.117.466-.199.651-.379a.557.557 0 01.156-.071c.12-.019.233-.01.318.092-.267.495-.892.63-1.143 1.14z"
                fill="#4d79b5"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-160"
                d="M28.94 12.045c.202-1.22 1.067-2.196 1.268-3.416.267.518.429 1.066.49 1.645-.646.543-.78 1.432-1.37 2.014-.088.087-.175.175-.26.265-.246-.12-.236-.3-.127-.508z"
                fill="#5052b3"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-37"
                d="M12.796 16.357c-.976.532-2.156.503-3.106 1.104-.22.137-.537-.196-.688.169l-.35-.648c.04-.1.098-.19.207-.22 1.18-.315 2.342-.717 3.559-.894.235.08.46.166.378.49z"
                fill="#922890"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-171"
                d="M21.07 21.673c-.382.46-.921.706-1.419.984-.823.46-1.659.892-2.389 1.5a57.973 57.973 0 01-.627-.209c.519-.516 1.207-.82 1.69-1.376 1.03-.52 2.075-1.016 3.04-1.648.146-.071.292-.132.453-.045.12.183-.013.294-.116.413-.193.158-.426.248-.635.38z"
                fill="#4e70b4"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-103"
                d="M14.344 23.16c-.273-.093-.54-.199-.736-.426.024-.117.111-.172.212-.21.119-.044.26-.028.368-.087.979-.526 2.09-.733 3.069-1.254a9.38 9.38 0 011.474-.65c.177-.061.344-.149.531-.043-.008.55-.558.6-.836.902-1.31.71-2.683 1.273-4.085 1.77z"
                fill="#544faf"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-114"
                d="M12.444 15.968L8.655 16.98l-.024-.262c-.169-.307-.005-.484.26-.571 1.018-.334 2.04-.665 3.117-.77.28.098.505.238.436.592z"
                fill="#9c288c"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-151"
                d="M19.294 24.313c.516-.54 1.154-.915 1.81-1.243a4.6 4.6 0 001.36-1.016c.6-.178.981-.67 1.458-1.022.22-.16.415-.34.7-.37-.613.59-1.18 1.244-1.854 1.757-.9.685-1.884 1.262-2.834 1.884l-.643.013z"
                fill="#4a8ab8"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-98"
                d="M12.037 15.466c-1.006.259-2.011.516-3.017.777-.24.064-.43.178-.391.477l-.342-.881c.082-.326.355-.426.628-.44.907-.05 1.778-.277 2.645-.518a.447.447 0 01.25.021c.2.14.407.278.227.569z"
                fill="#a52988"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-104"
                d="M17.26 21.294c-.434.22-.77.633-1.353.561-.314-.04-.571.225-.838.392-.445.28-.963.362-1.463.49-.252.031-.36-.2-.545-.292.007-.365.32-.444.558-.463.431-.031.802-.225 1.198-.341.953-.135 1.781-.595 2.633-.995.206-.098.4-.243.65-.159.194.297-.094.36-.245.46-.19.13-.418.205-.603.347z"
                fill="#5447ae"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-125"
                d="M14.846 21.8c-.526.405-1.299.121-1.783.643-.145-.156-.415-.146-.508-.373.188-.466.59-.535 1.016-.519.476.019.897-.1 1.275-.384a22.497 22.497 0 001.53-.508c.198-.071.38-.174.592-.055.143.325-.132.365-.317.458-.585.29-1.199.502-1.805.738z"
                fill="#563dae"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-87"
                d="M17.894 3.808l-.38-.254c-.016-.754.238-1.445.507-2.132l.265-.022c.15-.105.315-.053.476-.047.31.153.32.428.249.704-.069.267-.095.537-.127.807-.159.383-.365.727-.736.944h-.254z"
                fill="#dd425f"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-72"
                d="M8.026 14.442l-.059-.63a.562.562 0 01-.008-.389c.252-.362.66-.19.987-.257a13.028 13.028 0 001.977-.526c.293.212.352.495.248.825-.6.381-1.27.572-1.97.609a2.344 2.344 0 00-1.175.365z"
                fill="#c52d78"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-45"
                d="M8.026 14.442c.11-.363.328-.5.735-.514.635-.02 1.294-.01 1.868-.4.124-.084.32-.063.484-.09.249.096.291.281.225.514-1.005.699-2.122.916-3.326.754.006-.087.008-.174.014-.262z"
                fill="#bc2c7d"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-172"
                d="M29.195 13.69c.6-.711.839-1.672 1.571-2.299.075-.06.053-.233.077-.352.222.402.098.842.125 1.262l-.024.088c-.469.619-.77 1.362-1.4 1.857-.188.148-.35.407-.648.185-.095-.325.045-.556.299-.743z"
                fill="#4e63b4"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-64"
                d="M8.012 14.704c.503-.106 1-.246 1.524-.143.424.082.66-.323 1.027-.394.243-.048.476-.149.712-.223.322.138.42.37.293.696-.124.159-.304.225-.49.262-.965.193-1.928.405-2.923.299-.047-.167-.092-.333-.14-.5z"
                fill="#b32a81"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-158"
                d="M30.825 14.582c-.72.77-1.612 1.318-2.464 1.92-.145.101-.251.22-.434.107-.105-.498.36-.646.575-.948.875-.65 1.69-1.367 2.484-2.114.058.082.053.172.024.262-.064.257-.021.54-.183.776z"
                fill="#4f78b3"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-90"
                d="M18.021 1.424c.006.752-.246 1.442-.508 2.133h-.127l-.508-.127-.127-.381c-.124-.564.175-1.019.381-1.498.292-.071.577-.204.89-.127z"
                fill="#e04855"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-170"
                d="M30.965 13.563c-.561.985-1.506 1.545-2.405 2.154-.122.093-.233.228-.413.119-.124-.429.183-.627.453-.847 1.079-.632 1.796-1.584 2.357-2.675l.01-.015c.165.172.027.402.12.587-.127.209.031.47-.122.675z"
                fill="#4e71b4"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-62"
                d="M8.153 15.206c.79-.264 1.637-.174 2.442-.373.32-.08.637-.17.957-.254.265.019.32.156.22.384-.675.201-1.339.51-2.024.566-.516.042-1.019-.037-1.458.31.066-.236-.23-.392-.137-.63z"
                fill="#ac2a85"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-161"
                d="M15.746 23.697c-.302-.077-.635-.069-.884-.3.21-.417.62-.518 1.016-.642.535.153.969-.1 1.419-.328.394-.201.767-.506 1.264-.379.122.508-.413.485-.6.741-.355.268-.76.455-1.109.725-.341.267-.696.37-1.106.183z"
                fill="#4f62b4"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-19"
                d="M25.263 2.908c.54.183.971.535 1.394.897.035.262.059.522-.074.768-.187.209-.4.362-.7.346-.302-.074-.448-.293-.538-.569-.071-.259-.209-.494-.286-.75-.082-.266-.121-.535.201-.692z"
                fill="#743da3"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-165"
                d="M15.862 22.813c-.333.193-.666.389-1 .582-.172-.08-.346-.159-.518-.235.418-.574 1.156-.498 1.709-.826.73-.436 1.561-.7 2.35-1.042.079-.07.164-.08.253-.027.067.148-.029.249-.127.315-.814.569-1.621 1.159-2.667 1.233z"
                fill="#5156b1"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-51"
                d="M22.593 1.781c.421.006.802.133 1.143.376.273.458.471.94.464 1.484-.08.22-.167.437-.347.604-.251.108-.463.034-.654-.146.016-.693-.092-1.35-.563-1.905a.365.365 0 01-.043-.413z"
                fill="#943b94"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-53"
                d="M18.789 1.41l-.503-.007c.495-.201 1.008-.108 1.519-.106.439.434.513.976.465 1.553-.108.413.07.969-.558 1.122-.13-.048-.193-.156-.249-.275-.058-.569-.082-1.143-.29-1.688-.088-.228-.114-.49-.384-.6z"
                fill="#d03b70"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-32"
                d="M22.593 1.781c.12.223.194.492.366.66.5.489.457 1.036.27 1.621.156.19.174.392.058.609-.077.087-.175.105-.286.082-.476-.352-.384-.963-.648-1.413-.064-.55.002-1.114-.138-1.66.14-.02.286-.055.378.104z"
                fill="#9e388e"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-20"
                d="M26.528 4.57c.042-.254-.106-.542.132-.762.442.355.916.677 1.249 1.151-.135.095-.103.238-.109.37-.132.154-.119.437-.407.45a1.64 1.64 0 01-.685-.315c-.204-.27-.44-.534-.178-.894z"
                fill="#663baa"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-91"
                d="M17.132 1.551c.053.545-.264.998-.38 1.498-.34.254-.678.508-1.017.76-.015-.403.39-.713.254-1.141.04-.36.44-.582.381-.98.265.009.487-.21.762-.14z"
                fill="#e34b4c"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-39"
                d="M20.942 1.424c.18-.01.368-.08.508.103.464.365.27.948.46 1.403-.108.423.123.926-.295 1.275-.395.005-.41-.336-.538-.577-.084-.537-.13-1.082-.25-1.614-.049-.209-.049-.418.115-.59z"
                fill="#b53a82"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-59"
                d="M21.831 2.922c-.346-.405-.156-.958-.38-1.397.264 0 .534-.027.764.15.484.48.201 1.077.251 1.625-.084.122.024.312-.14.408-.389-.122-.516-.41-.495-.789z"
                fill="#a93b88"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-16"
                d="M25.263 2.908c-.37.548.212.93.249 1.408-.132.116.032.389-.233.437-.35-.074-.376-.442-.587-.64a.913.913 0 01-.08-.162c-.108-.505-.428-.982-.24-1.535.29.18.685.167.894.492z"
                fill="#7e3c9e"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-164"
                d="M15.746 23.697c.701.15 1.069-.474 1.611-.693.19-.077.358-.21.535-.318.17-.108.339-.148.508 0-.513.527-1.056 1.013-1.768 1.265-.328.032-.619-.077-.886-.254z"
                fill="#4d6ab5"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-61"
                d="M20.942 1.424c-.005.762.358 1.484.254 2.257a.356.356 0 01.027.378c-.569-.026-.466-.56-.662-.87-.103-.513-.029-1.045-.187-1.553-.035-.109-.019-.262.18-.233.13.005.259.013.386.019z"
                fill="#be3b7d"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-82"
                d="M20.554 1.406c.045.59.09 1.18.132 1.77-.042.071-.077.15-.175.161-.262-.09-.235-.352-.333-.542-.042-.522-.225-1.006-.376-1.498.251.024.524-.09.752.109z"
                fill="#c63e7a"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-109"
                d="M24.369 2.416l.38 1.522c-.306.153-.457-.082-.634-.254-.05-.527-.408-.98-.379-1.527a.897.897 0 01.633.26z"
                fill="#8a3d99"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-4"
                d="M9.642 6.73c.035.261.267.327.39.192.287-.317.679-.196 1.004-.325-.531.492-1.023 1.069-1.875.894l.481-.762z"
                fill="#e65142"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-13"
                d="M8 13.436c-.012.125-.022.252-.033.376a4.127 4.127 0 010-2.008l.045-.021c.225.307.148.658.159.997.021.238-.056.453-.172.654zM30.965 13.563l.122-.674c-.019.306.1.624-.08.918-.013-.082-.029-.162-.042-.244z"
                fill="#fdfdfd"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-5"
                d="M11.77 14.96c-.075-.127-.146-.254-.22-.383l-.278-.633c-.053-.17-.109-.338-.161-.508l-.215-.76c-.166-.153-.182-.372-.243-.57-.048-.286-.082-.572.111-.826.135-.36.463-.415.778-.487.63-.328 1.352-.304 2.008-.537.476-.183.984-.188 1.476-.283.627-.048 1.255.005 1.884-.008.175 0 .35.005.519.048.275.082.558.137.83.232.143.05.284.104.427.151.22.077.42.204.661.207.405.01.754-.175 1.114-.326.41-.145.833-.22 1.26-.296.07-.008.14-.013.211-.013.617 0 1.23-.006 1.847 0 .249-.006.484.071.722.119.3.04.585.127.879.187.375.114.756.191 1.143.254.238.104.542.022.732.257.257.103.559.05.789.24.164.279.09.588.124.885-.135.235-.119.494-.124.754-.098.574-.021 1.206-.542 1.643l-.728 1.275c-.222.725-.834 1.109-1.368 1.556-.238.2-.532.325-.807.476-.397.251-.857.28-1.302.378a3.02 3.02 0 01-.801.035c-.262-.035-.519-.109-.784-.133-.2-.034-.394-.082-.529-.254-.402-.087-.799-.087-1.167.127-.272.16-.563.16-.86.13-.214-.082-.383-.267-.634-.262-.392-.074-.778-.08-1.143.109-.572.296-1.151.49-1.768.119l-1.013-.254c-.254-.127-.508-.254-.765-.379-.222-.166-.442-.333-.664-.497l-.384-.381c-.116-.13-.235-.26-.352-.386-.06-.228-.277-.331-.407-.503 0-.217-.156-.35-.267-.505z"
                fill="#fefefe"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-86"
                d="M13.582 10.399c-.659.238-1.328.413-2.038.397.143-.36.495-.506.794-.625a16.018 16.018 0 004.302-2.519c.876-.724 1.792-1.47 2.016-2.704.04-.166.138-.272.31-.301a.2.2 0 01.146.1c.105.254.005.485-.082.715-.302.86-.852 1.529-1.469 2.204-1.124 1.235-2.728 1.72-3.98 2.733z"
                fill="#de2f69"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-85"
                d="M10.767 11.285c.01.294.018.59.029.884-.291.397-.768.299-1.16.407-.539.151-1.084.35-1.666.233l.04-1.026c.957.042 1.836-.342 2.757-.5z"
                fill="#d7316d"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-76"
                d="M18.91 4.694a.43.43 0 01-.254.254c-.444.16-.783.474-1.143.76.376-.58.535-1.23.635-1.9.138-.347.4-.608.635-.886.32.571.464 1.156.127 1.772z"
                fill="#e03864"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-167"
                d="M28.179 15.844l.38-.127c-.118.362-.568.487-.634.886a1.474 1.474 0 00-.508.506c-.688.674-1.54 1.135-2.284 1.735-.915.744-1.81 1.516-2.717 2.27-.095.08-.222.12-.333.175h-.381l.127-.38c.082-.316.352-.477.587-.639.78-.534 1.567-1.092 2.265-1.7.855-.747 1.783-1.406 2.62-2.173.253-.233.484-.532.878-.556z"
                fill="#5174b0"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-158"
                d="M27.417 17.111c.037-.301.206-.47.508-.508a1.302 1.302 0 01-.508.508z"
                fill="#4f78b3"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-21"
                d="M15.62 17.868l1.766-.119c-.433.706-1.24.775-1.905.95-.74.193-1.426.548-2.193.67-.455.07-.9.16-1.36.15.132-.291.4-.452.677-.513.948-.212 1.794-.646 2.649-1.072.106-.053.243-.045.365-.063z"
                fill="#6722a1"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-132"
                d="M21.191 17.646l.513.119c.167.304-.1.405-.264.54-.942.77-1.974 1.4-3.101 1.862a3.456 3.456 0 00-.77.42c-.167.123-.347.263-.56.07-.572.002-1.04.394-1.607.428-.185.01-.368.05-.553.077.752-.47 1.662-.585 2.413-1.026 1.111-.651 2.3-1.183 3.3-2.014.156-.23.502-.212.63-.479z"
                fill="#593baa"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-122"
                d="M27.258 10.793c-.262-.005-.532.032-.77-.122.39-.825.204-1.746.437-2.598.084-.577.087-1.164.243-1.733.061-.22.066-.458.249-.63l.38-.38c.498 1.714.35 3.402-.195 5.077-.058.177-.095.375-.344.386z"
                fill="#5c32ab"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-141"
                d="M24.623 17.492c.86-.476 1.55-1.143 2.156-1.905.262.262.363.032.53-.143.59-.624 1.13-1.307 1.886-1.754l-.254.76c.074.28-.185.34-.315.486-1.032 1.387-2.545 2.247-3.78 3.419-.88.83-1.927 1.55-2.996 2.185-.564.315-1.095.686-1.693.934-.228.096-.445.254-.71.12-.105-.435.278-.5.517-.646 1.288-.783 2.566-1.577 3.672-2.617.347-.262.701-.513.99-.841z"
                fill="#5363af"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-117"
                d="M27.507 14.312l.542-1.643c.016.542.286.28.437.108.185-.214.307-.486.457-.732-.018.185.04.349.127.508-.063.992-.87 1.325-1.56 1.759z"
                fill="#5553b0"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-167"
                d="M21.07 21.673c.134-.257.373-.336.634-.381-.132.259-.328.41-.635.38z"
                fill="#5174b0"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-155"
                d="M21.831 20.405c.622-.523 1.276-1.037 1.953-1.452 1.336-.82 2.26-2.104 3.585-2.924.466-.288.8-.79 1.193-1.198.241-.014.202.11.127.254-.243.203-.492.404-.508.759-.912.751-1.878 1.45-2.746 2.233-1.143 1.035-2.45 1.831-3.604 2.834l-.38.127c-.233-.426.322-.381.38-.633z"
                fill="#526db0"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-157"
                d="M21.831 20.405c.006.291-.428.318-.38.633-.953.666-2 1.153-3.049 1.648h-.508c.09-.333.665-.182.635-.632.244-.262.477-.54.89-.508.619-.043 1.087-.453 1.613-.701.262-.125.519-.315.8-.44z"
                fill="#5068b1"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-116"
                d="M24.623 17.492c-.082.508-.51.672-.89.89-1.155.952-2.425 1.722-3.764 2.389a4.013 4.013 0 00-.696.43c-.183.144-.378.294-.617.093h-.254l.89-.759c.502-.643 1.264-.945 1.891-1.426.662-.508 1.366-.971 2.17-1.241l1.27-.373z"
                fill="#5556ad"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-136"
                d="M23.353 17.865c-.177.477-.696.405-1.035.633-1.008.68-1.958 1.45-3.027 2.034-.724.13-1.338.545-2.032.76.194-.376.736-.339.89-.76.465-.354.939-.693 1.497-.897.699-.259 1.201-.83 1.847-1.18.33-.177.624-.423.97-.574.297-.005.594-.01.89-.013z"
                fill="#574bab"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-133"
                d="M22.464 17.879c-.183.336-.548.441-.823.664-.945.76-2.066 1.23-3.074 1.886a.72.72 0 01-.419.103c-.597.133-1.1.493-1.648.736-.542.24-1.087.394-1.654.532.45-.336.993-.484 1.487-.725.241-.117.532-.138.672-.416.511.064.723-.441 1.143-.598 1.252-.468 2.371-1.198 3.424-2.021.072-.056.09-.18.132-.273l.762.114z"
                fill="#5843ab"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-69"
                d="M18.91 4.694c.08-.6-.124-1.18-.127-1.772 0-.291-.158-.574.085-.87.14-.17.037-.437-.08-.641.406-.066.525.307.562.519.098.576.38 1.143.198 1.754.098.243.069.494-.008.73-.074.225-.196.437-.5.283h-.127z"
                fill="#d93b68"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-52"
                d="M7.97 12.81c.942-.215 1.884-.43 2.826-.641.119.153.116.33.103.513-.77.6-1.688.625-2.604.675-.1.005-.198.053-.296.082l-.026-.627z"
                fill="#cf2f73"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-168"
                d="M28.687 15.085c-.003-.104-.024-.199-.127-.254l.38-.381c.713-.315 1.117-.918 1.477-1.561.135-.241.236-.498.537-.575-.14.874-.714 1.482-1.296 2.072-.275.28-.646.466-.974.696z"
                fill="#516cb4"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-154"
                d="M18.656 21.294c.413.067.556-.338.847-.481 1.416-.685 2.784-1.45 4.048-2.395.045-.034.122-.026.183-.04a11.897 11.897 0 01-2.397 1.9c-.656.397-1.35.739-1.919 1.268-.304.153-.529.45-.889.508-.545.251-1.082.518-1.635.746-.328.135-.682.376-1.032.016.972-.434 1.99-.783 2.794-1.522z"
                fill="#525eb0"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-115"
                d="M26.528 4.57c-.098.35.209.58.254.886.182.68-.244 1.183-.508 1.733-.254.146-.498.323-.818.23-.137-.073-.177-.198-.18-.343.212-.752.16-1.575.62-2.257l.634-.254z"
                fill="#6e32a2"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-156"
                d="M25.893 4.821c-.154.765-.236 1.543-.508 2.281-.095.19-.138.437-.437.418-.352-.233-.127-.598-.211-.894.05-.455.095-.913.193-1.363.047-.222.074-.463.328-.569l.254-.38c.01.256.177.394.38.507z"
                fill="#79329e"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-107"
                d="M24.117 3.681c.278-.082.395.243.635.254v.127c.247.802-.222 1.455-.447 2.162-.4.328-.558.074-.677-.278-.037-.6-.328-1.236.235-1.757-.01-.217.024-.41.254-.508z"
                fill="#893496"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-38"
                d="M23.86 4.19c-.256.576-.11 1.18-.126 1.772.098.121.053.264.053.402-.106.225-.172.519-.527.288-.246-.648-.352-1.299-.034-1.958V4.06l.635.127z"
                fill="#923091"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-77"
                d="M20.18 2.795c.265.066.199.38.381.508.384.854.104 1.616-.328 2.357-.15.111-.304.177-.476.048-.092-.12-.114-.262-.127-.405.04-.455-.116-.915.045-1.365.553-.21.265-.794.508-1.14z"
                fill="#cd3474"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-88"
                d="M19.672 3.935c.17.453.125.926.127 1.394-.224.55-.293 1.212-1 1.427-.225-.456.17-.858.111-1.3.043-.254.172-.492.127-.759.617-.114.27-.709.508-1.013.104.055.125.148.127.254z"
                fill="#d5306e"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-24"
                d="M23.228 4.697c-.01.68-.013 1.357.127 2.027.12.598-.29.94-.635 1.312-.142.045-.254 0-.333-.127a1.22 1.22 0 01.058-.979c.22-.45.275-.92.3-1.405.068-.28.005-.595.23-.83h.253z"
                fill="#992b8d"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-100"
                d="M22.974 4.694c-.007.302.048.612-.127.887-.084.08-.177.077-.275.034-.203-.19-.299-.436-.365-.698.021-.416-.082-.842.132-1.236.006-.14-.02-.288.127-.38.305.414.3.941.508 1.393z"
                fill="#a3338b"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-119"
                d="M26.274 7.102c.077-.577.479-1.056.508-1.646.267-.052.436.138.635.254l-.381 2.408c-.278-.15-.614-.185-.844-.431-.09-.212-.072-.405.082-.582z"
                fill="#6431a7"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-42"
                d="M21.196 4.062v-.38l.381.507c.268.177.276.458.292.733 0 .624-.329 1.14-.58 1.68-.17.196-.288.484-.64.35-.106-.302.037-.604-.006-.906.14-.674.13-1.389.553-1.984z"
                fill="#b82f7f"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-55"
                d="M21.704 4.948c.045-.267-.084-.508-.127-.759.286-.38-.044-.886.254-1.267.13.28.289.54.508.76v1.266c-.074.146-.05.352-.256.416-.278 0-.238-.289-.379-.416z"
                fill="#ad3485"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-18"
                d="M24.242 6.216c.066-.744.476-1.403.508-2.154.312.098.325.434.508.632-.252.654-.236 1.355-.381 2.027-.177.267-.386.418-.704.204-.122-.251-.114-.49.069-.712z"
                fill="#81319a"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-40"
                d="M21.196 4.062l-.38 2.027c-.17.304-.157.76-.657.79-.153-.081-.214-.219-.24-.38-.08-.352.076-.638.261-.918a4.312 4.312 0 00.381-2.28l.127-.128c.223.265.157.696.508.886z"
                fill="#c33079"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-67"
                d="M18.91 5.456c.162.442-.05.85-.127 1.268.048.532-.314.85-.656 1.145-.979.852-1.897 1.792-3.154 2.26-.463.09-.926.183-1.391.272.243-.415.627-.693 1.032-.9a14.288 14.288 0 002.598-1.706c.616-.505.87-1.243 1.476-1.709.196-.15-.008-.47.222-.63z"
                fill="#da2d6c"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-140"
                d="M24.107 12.801c.524-.188 1.074-.1 1.627-.196-.548 1.617-2.524 2.818-4.04 2.485 1.048-.508 1.918-1.201 2.413-2.289z"
                fill="#6028a6"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-146"
                d="M26.274 7.737c.328-.024.648-.026.762.381-.064.685-.132 1.373-.183 2.059-.018.256-.066.457-.365.494a3.692 3.692 0 01-1.119-.235c-.098-.19-.019-.368.05-.543.217-.563.482-1.108.543-1.722.018-.183.103-.365.312-.431z"
                fill="#622ba6"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-79"
                d="M14.973 10.126c.008-.349.313-.352.532-.439.75-.299 1.31-.85 1.89-1.384.518-.479 1.119-.883 1.388-1.582.538-.32.63-.963 1.016-1.394v.38c.112.384-.052.731-.256 1.004-.834 1.103-1.58 2.293-2.831 3.008-.111.063-.109.071.14.193.087.037.159.087.148.198z"
                fill="#d32c72"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-43"
                d="M24.107 12.801c.33.05.145.233.077.352-.45.77-1.22 1.238-1.823 1.863-.12.124-.44.053-.667.074l-.249-.027c-.35-.304-.05-.531.122-.727.46-.521 1.04-.918 1.534-1.405.323-.138.665-.138 1.009-.13z"
                fill="#6622a2"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-1"
                d="M26.274 7.737c-.246.918-.49 1.836-.905 2.696l-.87-.198c-.178-.3.05-.521.166-.746.27-.527.524-1.05.51-1.66-.004-.24.117-.393.34-.47l.761-.254v.632z"
                fill="#6b29a2"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-41"
                d="M20.688 6.975l.508-.38c.17-.03.18.092.143.184-.166.408-.182.868-.434 1.247-.222.97-1.08 1.457-1.65 2.164a.824.824 0 01-.596.315l-.389-.133c-.23-.11-.15-.267-.053-.412.13-.194.305-.35.474-.508.664-.622 1.288-1.273 1.659-2.125.066-.153.153-.304.336-.352z"
                fill="#b7267f"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-70"
                d="M20.688 6.975c-.452 1.146-1.251 2.027-2.143 2.847-.148.135-.444.24-.272.553-.26-.074-.519-.145-.78-.22.044-.238.198-.426.364-.568a10.92 10.92 0 002.323-2.739c.313-.17.368-.553.635-.76.04.308-.055.596-.127.887z"
                fill="#bf287b"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-46"
                d="M22.72 7.991c.135-.46.543-.786.635-1.267l.381-.381c.334.749-.026 1.415-.272 2.087-.098.27-.315.498-.402.776-.19.605-.535.979-1.223.91l-.135.013c-.164-.217-.015-.4.08-.577.28-.54.598-1.058.936-1.56z"
                fill="#8f2891"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-102"
                d="M25.512 7.356c-.37.254-.127.654-.214.98-.18.676-.604 1.237-.802 1.899l-.64-.117c-.09-.034-.172-.074-.196-.18.251-.923.743-1.72 1.341-2.455l.381-.38c0 .105.024.198.127.253z"
                fill="#73279d"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-99"
                d="M20.445 10.439c-.365.18-.712.415-1.154.31-.153-.223.014-.36.151-.477.275-.236.455-.561.717-.791.57-.5.807-1.217 1.254-1.794.17-.12.32-.291.567-.212-.008.659-.323 1.23-.537 1.828-.204.49-.55.855-.995 1.136z"
                fill="#a52487"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-108"
                d="M23.736 10.013l.122.105c-.672 0-1.347-.002-2.019-.005.736-.053.866-.656 1.165-1.17.478-.82.72-1.669.732-2.6v-.381c.12.185.223.4.508.254v.76c.17 1.248-.566 2.066-1.29 2.888.208.035.534-.087.782.146z"
                fill="#892a96"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-81"
                d="M20.18 6.85c-.243 1.133-1.14 1.797-1.894 2.54-.257.255-.429.62-.794.768L17 10.113c-.04-.034-.077-.069-.116-.1.26-.482.696-.826 1.082-1.17a8.77 8.77 0 001.664-1.971c.098-.159.132-.426.423-.4l.127.381z"
                fill="#c52877"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-44"
                d="M20.445 10.439c.273-.41.664-.728.878-1.183.199-.289.318-.622.485-.926.13-.236.23-.611.658-.469.056.104.149.125.254.127.024.66-.571 1.02-.767 1.577-.066.188-.225.347-.249.561l-1.259.31z"
                fill="#9a248c"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-56"
                d="M14.727 12.788c.564.005 1.12.05 1.654.251.027.334-.246.432-.463.49-.526.143-.937.452-1.326.804a2.489 2.489 0 01-.775-.738c.12-.479.577-.577.913-.81z"
                fill="#ab2384"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-65"
                d="M21.45 7.737c-.375 1.082-1.148 1.87-1.944 2.643-.109.104-.207.21-.215.368-.232-.021-.436-.119-.632-.243.569-.54 1.133-1.085 1.704-1.622.262-.246.082-.707.45-.895.172-.195.323-.423.632-.402.032.032.043.066.032.1-.01.035-.019.051-.03.051z"
                fill="#ae2483"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-33"
                d="M14.59 14.336c.122-.564.598-.733 1.05-.87.294-.09.535-.215.738-.427.32.006.59.117.778.39-.058.208-.235.29-.412.351-.601.212-1.125.553-1.644.908a.981.981 0 01-.513-.352z"
                fill="#9f238a"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-17"
                d="M23.101 12.928c-.227.67-.92.897-1.33 1.392-.178.217-.487.376-.326.738-.444-.08-.542-.33-.357-.717.405-.847.995-1.431 2.01-1.413z"
                fill="#6c21a0"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-35"
                d="M17.167 15.1c-.543.18-1.056.067-1.559-.16-.124-.154-.052-.308.072-.377.556-.304 1.048-.738 1.693-.87.09.193.339.27.37.505-.29.416-.835.48-1.182.844-.064.024.058.1.008.032-.021-.03-.005-.04.029-.058.212-.106.402-.1.569.084z"
                fill="#8e2291"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-101"
                d="M17.373 13.693c-.431.275-.812.627-1.315.794-.209.068-.439.164-.447.452-.204-.016-.362-.119-.508-.254.278-.571.873-.706 1.37-.968.22-.117.456-.196.686-.291.114.055.22.119.217.267z"
                fill="#96228e"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-49"
                d="M14.727 12.788c-.169.423-.64.505-.912.81-.31-.233-.469-.564-.67-.977.588.03 1.09.016 1.582.167z"
                fill="#b92b82"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-27"
                d="M17.167 15.1c-.225-.01-.453-.023-.762-.037.341-.531.868-.658 1.339-.865.333.648.225.82-.577.902z"
                fill="#862295"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-26"
                d="M23.736 10.013h-1.029c.773-.956 1.424-1.874 1.537-3.038l.635-.254c.18.23-.169.55.127.76-.365.873-.973 1.621-1.267 2.529z"
                fill="#7e2899"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-74"
                d="M20.053 6.47c-.764 1.44-1.897 2.55-3.17 3.54-.219-.016-.449.069-.724-.085 1.648-1.06 2.923-2.402 3.64-4.215l.381-.127c.122.32-.264.567-.127.887z"
                fill="#cc2a74"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-34"
                d="M22.466 7.864c-.378.143-.526.492-.72.802-.126.206-.163.479-.423.592l.635-1.772c-.007-.707.188-1.344.635-1.9h.254c.038.434.059.865-.195 1.257-.201.312-.186.67-.186 1.021z"
                fill="#a0298a"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-58"
                d="M22.593 5.583l-.635 1.9c-.174.072-.285.273-.508.254V7.61c.096-.791.35-1.54.635-2.28l.254-.382.254.633z"
                fill="#a82c87"
                strokeWidth={0.264583}
            />
            <Path
                className="cls-66"
                d="M22.085 5.33c-.029.81-.29 1.555-.635 2.28l-.635.381c.104-.47.347-.905.381-1.394.043-.588.58-1.024.508-1.646l.381.381z"
                fill="#b02b83"
                strokeWidth={0.264583}
            />
        </Svg>
    );
}

export default Logo;
