
import { importer } from "./helpers/importer";

let DefaultCustomTabsNavigator = () => {
    return {};
};




const CustomTabsNavigator = importer(DefaultCustomTabsNavigator, "CustomTabsNavigator")

export { CustomTabsNavigator };
