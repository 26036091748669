import React, { Component } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import Label from "@base/Label";
import Card from "@base/Card";
import store from "@store/index";
import { loading, ready, showPopup } from "@store/actions/global";

import BotCard from "@base/BotCard";
import { setRequestStatusNFTs } from "@store/actions/nfts.action";
import { MarketPlaceStatus } from "@store/reducers/nfts.reducer";
import NFT from "@custom-types/NFTModel";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";

interface Props {
    navigation: NavigationType;
    route: any;
    selectedNFT: NFT;
}

interface State {
    fee: string;
    sendingTo: string;
    sendingFrom: string;
}

const { t } = i18n;

export class _ConfirmSaleScreen extends Component<Props, State> {
    private currency: Currency;
    constructor(props: Props) {
        super(props);
        this.onConfirm = this.onConfirm.bind(this);
        this.currency = Wallet.getInstance().findCurrencyById(
            this.props.route.params?.currency
        );
        const skeleton = this.currency.getImplementation().parseSkeleton(this.props.route.params?.data);
        this.state = {
            sendingTo: skeleton.sendingTo,
            sendingFrom: skeleton.sendingFrom,
            fee: skeleton.fee,
        };
    }

    async onConfirm() {
        store.dispatch(loading());
        try {
            
            const res = await this.currency.sendTransaction(this.props.route.params?.data);
            store.dispatch(showPopup({ type: "SUCCESS" }));
            store.dispatch(
                setRequestStatusNFTs({ status: MarketPlaceStatus.WaitingListing, nft: this.props.selectedNFT }),
            );

            this.props.navigation.goBack();
            this.props.navigation.navigate(TabsNavigatorScreens.NFTs.routeName, {
                screen: "NFTsMarketplace",
            });
        } catch (e) {
            console.warn(e.message);
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
        store.dispatch(ready());
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("confirm")} type={HeaderType.Light} {...this.props} />
                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <Container style={{ flex: 1, justifyContent: "space-between", paddingVertical: 20 }}>
                        <View>
                            <BotCard title={t("almost_done")} message={t("info_card_confirm_sale")}></BotCard>

                            <Card style={styles.card}>
                                <RegularText color={colors.text} align="center">
                                    {t("sending_from")}
                                </RegularText>
                                <BoldText fixedWidth={250} color={colors.text} fontSize={14} align="center">
                                    {this.state.sendingFrom}
                                </BoldText>
                            </Card>
                            <Card style={styles.card}>
                                <RegularText color={colors.text} align="center">
                                    {t("sending_to")}
                                </RegularText>
                                <BoldText fixedWidth={250} color={colors.text} fontSize={14} align="center">
                                    {this.state.sendingTo}
                                </BoldText>
                            </Card>

                            <Card style={styles.card}>
                                <RegularText color={colors.text} align="center">
                                    {t("fee")}
                                </RegularText>
                                <BoldText color={colors.text} align="center">
                                    {this.state.fee} {this.currency.getPName()}
                                </BoldText>
                                <Label
                                    text={`$ ${this.currency.toFiat(Number(this.state.fee))}`}
                                    align="center"
                                    fontSize={12}
                                />
                            </Card>
                        </View>
                        <InlineButton
                            style={{ marginHorizontal: 0 }}
                            onPress={this.onConfirm}
                            title={t("confirm_transaction")}
                        />
                    </Container>
                </ScrollView>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedNFT: state.nfts.selectedNFT,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ConfirmSaleScreen = connect(mapStateToProps, mapDispatchToProps)(_ConfirmSaleScreen);

export default ConfirmSaleScreen;

const styles = StyleSheet.create({
    card: {},
    swipContainer: {
        flex: 1,
        height: 58,
    },
});
