import React, { Component } from "react";
import { View, StyleSheet, Text } from "react-native";
import RegularText from "@base/RegularText";
import { BoldText } from "@base/BoldText";
import Icon from "@components/icons";
import { colors } from "@styles/globalStyles";
import { SimpleLineIcons } from "@expo/vector-icons";
import Card from "@base/Card";

interface Props {
    title: string;
    version?: string;
    icon?: string;
    iconSize?: number;
    tag?: string;
    onPress?: () => void;
}

export default class SimpleCard extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <Card
                onPress={this.props.onPress}
                left={
                    <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
                        <RegularText>{this.props.title} </RegularText>
                        {this.props.tag && (
                            <View style={styles.tag}>
                                <BoldText color={colors.white} align="center" fontSize={10}>
                                    {this.props.tag}
                                </BoldText>
                            </View>
                        )}
                    </View>
                }
                right={
                    this.props.version ? (
                        <RegularText> {this.props.version} </RegularText>
                    ) : (
                        <Icon
                            name={this.props.icon}
                            size={this.props.iconSize || 25}
                            color={colors.text}
                            align="right"
                        />
                    )
                }
                accessibilityLabel={this.props.title}
            />
        );
    }
}

const styles = StyleSheet.create({
    tag: {
        alignSelf: "center",
        paddingVertical: 2,
        paddingHorizontal: 5,
        backgroundColor: colors.secondary,
        borderRadius: 5,
        marginLeft: 10,
    },
});
