import React, { Component } from "react";
import { View, StyleSheet, Animated, Easing, ActivityIndicator, Platform, Vibration } from "react-native";
import RegularText from "@base/RegularText";
import { colors, settings } from "@styles/globalStyles";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import Modal from "react-native-modal";
import store from "@store/index";
import { hidePopupMessage } from "@store/actions/global";
import Icon from "@components/icons";
import BoldText from "@base/BoldText";
import { SkypeIndicator } from "react-native-indicators";
import PressableBase from "@base/PressableBase";
import { VibrationType, vibrate } from "@utils/vibration/vibration.helper";
interface State {
    sizeBot: any;
}

interface Props {
    popup?: PopupMessageOptions;
    showModalBottom: boolean;
}

const { t } = i18n;

export class _PopupMessage extends Component<Props, State> {
    protected sizeBot = 0.8;
    intervalID: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            sizeBot: new Animated.Value(this.sizeBot),
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        this.animation();
        if (this.props.popup) {
            switch (this.props.popup?.type) {
                case "ERROR":
                    vibrate(VibrationType.ERROR);
                    break;
                case "WARNING":
                    vibrate(VibrationType.WARNING);
                    break;
                case "SUCCESS":
                    vibrate(VibrationType.SUCCESS);
                    break;
                case "MESSAGE":
                    vibrate();
                    break;
            }
        }
    }

    componentDidMount() {
        this.animation();
    }

    animate(animations: { value: Animated.Value; config: Partial<Animated.TimingAnimationConfig> }[]) {
        animations.map((animation) => {
            Animated.timing(animation.value, {
                ...animation.config,
                toValue: animation.config.toValue,
                useNativeDriver: false,
            }).start();
        });
    }

    animation = () => {
        this.animate([
            {
                value: this.state.sizeBot,
                config: {
                    toValue: 0.75,
                    duration: 500,
                },
            },
        ]);

        setTimeout(() => {
            this.animate([
                {
                    value: this.state.sizeBot,
                    config: {
                        toValue: 1,
                        duration: 700,
                        easing: Easing.elastic(3),
                    },
                },
            ]);
        }, 500);
    };

    render() {
        return (
            <Modal
                testID={"modal"}
                isVisible={this.props.popup != null && !this.props.showModalBottom}
                animationOutTiming={0}
                backdropTransitionOutTiming={0}
            >
                <View style={styles.content}>
                    {this.props.popup && this.props.popup?.type == "ERROR" && (
                        <View>
                            <View style={{ padding: 20, alignItems: "center" }}>
                                <BoldText fontSize={18} style={{ marginBottom: 10 }}>
                                    Error
                                </BoldText>
                                <RegularText align="center" fontSize={14}>
                                    {this.props.popup?.message || t("error_message")}
                                </RegularText>
                            </View>
                            <PressableBase
                                style={styles.button}
                                onPress={
                                    this.props.popup.onPressAccept
                                        ? this.props.popup.onPressAccept
                                        : () => store.dispatch(hidePopupMessage())
                                }
                            >
                                <BoldText align="center" fontSize={14}>
                                    OK
                                </BoldText>
                            </PressableBase>
                        </View>
                    )}

                    {this.props.popup && this.props.popup?.type == "WARNING" && (
                        <View>
                            <View style={{ padding: 20, alignItems: "center" }}>
                                <Animated.View
                                    style={{
                                        transform: [{ scale: this.state.sizeBot }, { perspective: 1000 }],
                                    }}
                                >
                                    <View style={[styles.icon, { backgroundColor: "transparent" }]}>
                                        <Icon name="exclamation-circle" size={45} color={colors.white} />
                                    </View>
                                </Animated.View>
                                <View style={{ marginBottom: 10, alignItems: "center" }}>
                                    <RegularText align="center" fontSize={14}>
                                        {this.props.popup?.message}
                                    </RegularText>
                                </View>
                            </View>

                            <PressableBase style={styles.button} onPress={() => store.dispatch(hidePopupMessage())}>
                                <BoldText align="center" fontSize={14}>
                                    {"OK"}
                                </BoldText>
                            </PressableBase>
                        </View>
                    )}

                    {this.props.popup && this.props.popup?.type == "MESSAGE" && (
                        <View>
                            <View style={{ padding: 20, alignItems: "center" }}>
                                <Animated.View
                                    style={{
                                        transform: [{ scale: this.state.sizeBot }, { perspective: 1000 }],
                                    }}
                                >
                                    <View style={styles.icon}>
                                        <Icon name="robot" size={30} color={colors.white} />
                                    </View>
                                </Animated.View>
                                <View style={{ marginBottom: 10, alignItems: "center" }}>
                                    <RegularText align="center" fontSize={14}>
                                        {this.props.popup?.message}
                                    </RegularText>
                                </View>
                            </View>
                            <PressableBase style={styles.button} onPress={this.props.popup.onPressAccept}>
                                <BoldText align="center" fontSize={14}>
                                    {t("continue")}
                                </BoldText>
                            </PressableBase>
                            <PressableBase style={styles.button} onPress={() => store.dispatch(hidePopupMessage())}>
                                <BoldText align="center" fontSize={14}>
                                    {t("cancel_message")}
                                </BoldText>
                            </PressableBase>
                        </View>
                    )}

                    {this.props.popup && this.props.popup?.type == "SUCCESS" && (
                        <View>
                            <View style={{ padding: 20, alignItems: "center" }}>
                                <Animated.View
                                    style={{
                                        transform: [{ scale: this.state.sizeBot }, { perspective: 1000 }],
                                    }}
                                >
                                    <View style={styles.icon}>
                                        <Icon name="check" size={30} color={colors.white} />
                                    </View>
                                </Animated.View>
                                <View style={{ marginBottom: 10, alignItems: "center" }}>
                                    {this.props.popup?.title && (
                                        <BoldText align="center" fontSize={14} style={{ marginBottom: 5 }}>
                                            {this.props.popup?.title}
                                        </BoldText>
                                    )}
                                    <RegularText align="center" fontSize={14}>
                                        {this.props.popup?.message || t("congratulations")}
                                    </RegularText>
                                </View>
                            </View>
                            <PressableBase
                                style={styles.button}
                                onPress={
                                    this.props.popup.onPressAccept
                                        ? this.props.popup.onPressAccept
                                        : () => store.dispatch(hidePopupMessage())
                                }
                            >
                                <BoldText align="center" fontSize={14}>
                                    OK
                                </BoldText>
                            </PressableBase>
                        </View>
                    )}

                    {this.props.popup && this.props.popup?.type == "LOADING" && (
                        <View>
                            <View style={{ padding: 20, alignItems: "center" }}>
                                <View style={{ paddingVertical: 10 }}>
                                    {this.props.popup?.title && (
                                        <BoldText align="center" fontSize={18}>
                                            {this.props.popup?.title}
                                        </BoldText>
                                    )}
                                </View>
                                <View style={{ height: 80, alignItems: "center", justifyContent: "center" }}>
                                    {Platform.OS == "web" ? (
                                        <ActivityIndicator size={60} color={colors.complementary} />
                                    ) : (
                                        <SkypeIndicator
                                            size={60}
                                            animationDuration={2000}
                                            color={colors.complementary}
                                        />
                                    )}
                                </View>
                                <View style={{ paddingVertical: 10 }}>
                                    {this.props.popup?.message && (
                                        <RegularText align="center" fontSize={14}>
                                            {this.props.popup?.message}
                                        </RegularText>
                                    )}
                                </View>
                            </View>
                        </View>
                    )}
                </View>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        alignSelf: "center",
        justifyContent: "center",
        alignContent: "center",
        backgroundColor: colors.tertiary,
        shadowColor: "#000",
        maxWidth: 500,
        minWidth: 300,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        borderRadius: settings.cardRadius,
    },
    button: {
        paddingVertical: 15,
        borderTopWidth: 0.5,
        borderTopColor: colors.grey,
    },
    icon: {
        backgroundColor: colors.complementary,
        borderRadius: 50,
        width: 50,
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 20,
    },
});

const mapStateToProps = (state) => {
    return {
        popup: state.global.popupMessage,
        showModalBottom: state.global.showModalBottom,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const PopupMessage = connect(mapStateToProps, mapDispatchToProps)(_PopupMessage);

export default PopupMessage;
