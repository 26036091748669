import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { loading, ready, showSnackbar } from "@store/actions/global";
import i18n from "@i18n/i18n";
import { setActivity } from "@store/actions/activity.actions";
import { ActivityModel } from "@custom-types/ActivityModel";
const { t } = i18n;

export default class ActivityService {
    private static instance: ActivityService;

    constructor() {}

    static getInstance(): ActivityService {
        if (!ActivityService.instance) {
            ActivityService.instance = new ActivityService();
        }
        return ActivityService.instance;
    }

    async getActivity(limited?: boolean) {
        try {
            let url = `${getEnv("API_URL")}client/activity-log`;
            if (limited) {
                url = `${getEnv("API_URL")}client/activity-log?__limit=2&__offset=0`;
            }

            const resp = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            store.dispatch(
                setActivity(resp.data.docs, {
                    page: resp?.data?.page,
                    totalDocs: resp?.data?.totalDocs,
                    totalPages: resp?.data?.totalPages,
                })
            );

            return resp.data.docs;
        } catch (error) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(error);
        }
    }

    async getActivityById(id) {
        try {
            store.dispatch(loading());
            let url = `${getEnv("API_URL")}client/activity-log/${id}`;

            const resp = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready());
            return resp.data;
        } catch {
            store.dispatch(ready());
        }
    }

    async getActivityByCurrency(currencyID) {
        try {
            if (!currencyID) {
                return;
            }
            let url = `${getEnv("API_URL")}client/activity-log/digital-currencies/${currencyID}`;
            const resp = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            this.filterActivityReducer(resp?.data?.docs);
            return resp?.data?.docs;
        } catch (error) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(error);
        }
    }
    filterActivityReducer(data: ActivityModel[]) {
        if (!data?.length) {
            return;
        }
        let activity = store.getState().activity?.activity || [];
        data?.forEach((a) => {
            const exists = activity.some((existingActivity) => existingActivity._id === a._id);

            if (!exists) {
                activity.push(a);
            }
        });
        activity.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        store.dispatch(
            setActivity(
                activity,
                store.getState().activity.paginator
            )
        );
    }

    async getOrder(orderId: string) {
        try {
            const url = `${getEnv("API_URL")}client/orders/redeemables/${orderId}`;

            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            return response.data;
        } catch (error) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(error);
        }
    }
}
