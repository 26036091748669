import React, { Component } from "react";
import { View, Text, StyleSheet, Platform } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { colors } from "@styles/globalStyles";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import AppStorage from "@utils/storage";
import OptionCard from "@components/cards/OptionCard";
import InlineButton from "@base/InlineButton";

import { NavigationType } from "@custom-types/NavigationType";
import * as Updates from "expo-updates";
import WarningCard from "@base/WarningCard";
import store from "@store/index";
import { loading, reset } from "@store/actions/global";
import { ModuleControlService } from "@core/services/ModuleControlService";
import { OAuthService } from "@core/services/oauth/OAuthService";

interface Props {
    navigation: any;
}

interface State {
    language: string;
    save: boolean;
    languages: Array<string>;
}

const { t } = i18n;

export default class LanguageScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            language: "en",
            save: false,
            languages: [],
        };
    }

    async componentDidMount() {
        const language = await AppStorage.getItem("language");
        const languages = ModuleControlService.getInstance().getLanguages();
        if (language) {
            this.setState({ language: language, languages: languages || [] });
        }
    }

    async selectLanguage(language) {
        const old_language = await AppStorage.getItem("language");
        this.setState({ language: language });
        if (old_language !== language) {
            this.setState({ save: true });
        } else {
            this.setState({ save: false });
        }
    }

    onPress = async () => {
        store.dispatch(loading());
        await AppStorage.setItem("language", this.state.language);
        await OAuthService.getInstance().removeNotificationToken();
        await OAuthService.getInstance().setNotificationToken();
        i18n.locale = this.state.language;
        store.dispatch(reset());
    };

    isLanguageEnabled(lang: string) {
        return this.state.languages?.includes(lang);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("language")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    <View style={{ flex: 1 }}>
                        {this.isLanguageEnabled("en") && (
                            <OptionCard
                                selected={this.state.language == "en"}
                                title={t("en")}
                                onPress={() => this.selectLanguage("en")}
                            />
                        )}
                        {this.isLanguageEnabled("es") && (
                            <OptionCard
                                selected={this.state.language == "es"}
                                title={t("es")}
                                onPress={() => this.selectLanguage("es")}
                            />
                        )}
                        {this.isLanguageEnabled("pt") && (
                            <OptionCard
                                selected={this.state.language == "pt"}
                                title={t("pt")}
                                onPress={() => this.selectLanguage("pt")}
                            />
                        )}

                        {this.isLanguageEnabled("fr") && (
                            <OptionCard
                                selected={this.state.language == "fr"}
                                title={t("fr")}
                                onPress={() => this.selectLanguage("fr")}
                            />
                        )}

                        {this.isLanguageEnabled("tr") && (
                            <OptionCard
                                selected={this.state.language == "tr"}
                                title={t("tr")}
                                onPress={() => this.selectLanguage("tr")}
                            />
                        )}
                    </View>
                    {this.state.save && (
                        <View>
                            <WarningCard message={t("language_warning")} />

                            <InlineButton
                                title={t("save_restart")}
                                onPress={this.onPress}
                                style={{ marginHorizontal: 0, marginBottom: 20 }}
                            />
                        </View>
                    )}
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {},
});
