import React, { Component } from "react";
import { View, StyleSheet, Animated, Platform, Keyboard, ScrollView, Vibration } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { connect } from "react-redux";
import { LinearGradient } from "expo-linear-gradient";
import Container from "@base/Container";
import AvatarBase from "@components/avatar/AvatarBase";
import { Client } from "@custom-types/Client";
import Row from "@base/Row";
import BoldText from "@base/BoldText";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import Icon from "@components/icons";
import i18n from "@i18n/i18n";
import SidebarButton from "./SidebarButton";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import { ModuleControlService, Modules, NFTsModules } from "@core/services/ModuleControlService";
import Constants from "expo-constants";
import { hideSidebar, loading, ready } from "@store/actions/global";
import store from "@store/index";
import { NotificationModel } from "@custom-types/NotificationModel";
import { AuthNavigatorScreens } from "@navigation/AuthNavigator";
import { OAuthService } from "@core/services/oauth/OAuthService";
import { countUnreadNotification } from "@screens/notifications/notification.helper";
import PressableBase from "@base/PressableBase";
import { VibrationType, vibrate } from "@utils/vibration/vibration.helper";

interface Props {
    navigation?: any;
    showSidebar: boolean;
    client: Client;
    readyToRender: boolean;
    setFooter: (showSidebar: boolean) => void;
    unread: number;
    notifications?: NotificationModel[];
}

interface State {
    backgroundOpacity: any;
    marginLeft: any;
    sidebarOpacity: any;
    showModal: boolean;
    hidden: boolean;
}

const { t } = i18n;

export class _Sidebar extends Component<Props, State> {
    protected backgroundOpacity = 0;
    protected marginLeft = Platform.OS == "web" ? -20 : -500;
    protected sidebarOpacity = 0;
    constructor(props: Props) {
        super(props);
        this.state = {
            backgroundOpacity: new Animated.Value(this.backgroundOpacity),
            marginLeft: new Animated.Value(this.marginLeft),
            sidebarOpacity: new Animated.Value(this.sidebarOpacity),
            showModal: false,
            hidden: true,
        };

        this.showSidebar = this.showSidebar.bind(this);
        this.hideSidebar = this.hideSidebar.bind(this);
        this.animate = this.animate.bind(this);
        this.openProfile = this.openProfile.bind(this);
    }

    componentDidUpdate(props) {
        if (this.props.showSidebar !== props.showSidebar) {
            this.props.setFooter(this.props.showSidebar);
            if (this.props.showSidebar) {
                vibrate(VibrationType.ONPRESS);
                this.showSidebar();
            } else {
                vibrate(VibrationType.ONPRESS);
                this.hideSidebar();
            }
        }
    }

    showSidebar() {
        this.setState({ hidden: false });
        Keyboard.dismiss();
        this.animate([
            {
                value: this.state.backgroundOpacity,
                config: {
                    toValue: 0.7,
                    duration: 150,
                },
            },
            {
                value: this.state.sidebarOpacity,
                config: {
                    toValue: 1,
                    duration: 150,
                },
            },
            {
                value: this.state.marginLeft,
                config: {
                    toValue: -20,
                    duration: 300,
                },
            },
        ]);
    }

    hideSidebar() {
        store.dispatch(hideSidebar());
        setTimeout(() => {
            this.setState({ hidden: true });
        }, 300);
        this.animate([
            {
                value: this.state.backgroundOpacity,
                config: {
                    toValue: 0,
                    duration: 150,
                },
            },
            {
                value: this.state.marginLeft,
                config: {
                    toValue: Platform.OS == "web" ? -20 : -500,
                    duration: 300,
                },
            },
            {
                value: this.state.sidebarOpacity,
                config: {
                    toValue: 0,
                    duration: 150,
                },
            },
        ]);
    }

    animate(
        animations: {
            value: Animated.Value;
            config: Partial<Animated.TimingAnimationConfig>;
        }[]
    ) {
        animations.map((animation) => {
            Animated.timing(animation.value, {
                ...animation.config,
                toValue: animation.config.toValue,
                useNativeDriver: false,
            }).start();
        });
    }

    openProfile() {
        store.dispatch(hideSidebar());
        this.props.navigation.navigate("Profile", {
            screen: "ProfileMain",
            params: { clientID: this.props.client?._id },
        });
    }

    render() {
        return (
            <PressableBase
                disableOpacity={true}
                onPress={this.hideSidebar}
                style={this.props.readyToRender && !this.state.hidden ? styles.wrapper : styles.hidden}
            >
                <Animated.View style={[styles.background, { opacity: this.state.backgroundOpacity }]}>
                    <LinearGradient
                        style={{ flex: 1 }}
                        colors={[colors.gradientFrom, colors.gradientTo]}
                    ></LinearGradient>
                </Animated.View>

                <Container style={{ flex: 1, marginBottom: Platform.OS == "web" ? 35 : 0 }}>
                    <Animated.View style={[{ marginLeft: this.state.marginLeft, opacity: this.state.sidebarOpacity }]}>
                        <ScrollView showsHorizontalScrollIndicator={false}>
                            <View style={[styles.sidebarWrapper, { flex: 1, height: "100%" }]}>
                                <LinearGradient
                                    colors={[colors.tertiaryShadow, colors.shadow]}
                                    style={{ paddingBottom: 60 }}
                                >
                                    <PressableBase
                                        onPress={this.openProfile}
                                        style={{
                                            paddingHorizontal: 20,
                                            paddingVertical: 50,
                                            alignItems: "flex-start",
                                        }}
                                    >
                                        <Row
                                            style={{
                                                justifyContent: "center",
                                                alignContent: "center",
                                                alignItems: "center",
                                                textAlignVertical: "center",
                                                paddingTop: 0,
                                            }}
                                        >
                                            <AvatarBase
                                                size={65}
                                                uri={
                                                    this.props.client?.profileImagePath?.square ||
                                                    this.props.client?.profileImagePath?.thumbnail
                                                }
                                                alias={this.props.client?.alias || " "}
                                            ></AvatarBase>
                                            <View style={{ flex: 1, paddingLeft: 10, width: "100%" }}>
                                                <BoldText fontSize={18}>
                                                    {"@"}
                                                    {trimHelper(this.props.client?.alias, 20)}{" "}
                                                    {this.props.client?.verified && (
                                                        <Icon
                                                            size={18}
                                                            color={colors.complementary || colors.secondary}
                                                            name={"uniE924"}
                                                        />
                                                    )}
                                                </BoldText>
                                            </View>
                                        </Row>
                                    </PressableBase>

                                    <Container style={{ paddingLeft: 40 }}>
                                        <SidebarButton
                                            title={t("init")}
                                            icon="house1"
                                            screen={TabsNavigatorScreens.Home.routeName}
                                            {...this.props}
                                        />
                                        {ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule) && (
                                            <SidebarButton
                                                title={t("wallet")}
                                                icon="wallet"
                                                screen={TabsNavigatorScreens.Wallets.routeName}
                                                {...this.props}
                                            />
                                        )}

                                        <SidebarButton
                                            title={t("chat")}
                                            icon="chat1"
                                            counter={this.props.unread || 0}
                                            screen={TabsNavigatorScreens.Messages.routeName}
                                            {...this.props}
                                        />

                                        {ModuleControlService.getInstance().isNFTsModuleEnabled(
                                            NFTsModules.marketplace
                                        ) && (
                                            <SidebarButton
                                                title={"NFTs Marketplace"}
                                                icon="shop-window"
                                                screen={TabsNavigatorScreens.NFTs.routeName}
                                                {...this.props}
                                            />
                                        )}

                                        {ModuleControlService.getInstance().isModuleEnabled(
                                            Modules.socialNetworkModule
                                        ) && (
                                            <SidebarButton
                                                title={
                                                    Constants.expoConfig?.extra?.favButtonTitle
                                                        ? Constants.expoConfig?.extra?.favButtonTitle
                                                        : t("discover")
                                                }
                                                icon="compass"
                                                screen={TabsNavigatorScreens.SocialNetwork.routeName}
                                                {...this.props}
                                            />
                                        )}

                                        {ModuleControlService.getInstance().isModuleEnabled(Modules.dappsModule) && (
                                            <SidebarButton
                                                title={"Dapps"}
                                                icon="dapp"
                                                screen={TabsNavigatorScreens.Dapps.routeName}
                                                {...this.props}
                                            />
                                        )}

                                        <SidebarButton
                                            title={t("notifications")}
                                            icon="bell"
                                            counter={countUnreadNotification(this.props.notifications || [])}
                                            screen={TabsNavigatorScreens.Notifications.routeName}
                                            {...this.props}
                                        />

                                        <SidebarButton
                                            title={t("settings")}
                                            icon="settings"
                                            screen={TabsNavigatorScreens.Settings.routeName}
                                            {...this.props}
                                        />
                                        <SidebarButton
                                            title={t("signout")}
                                            icon="log-out"
                                            screen={AuthNavigatorScreens.Initializer.routeName}
                                            onPress={async () => {
                                                vibrate(VibrationType.WARNING);
                                                store.dispatch(loading());
                                                await OAuthService.getInstance().signout();
                                                store.dispatch(ready());
                                            }}
                                            {...this.props}
                                        />
                                    </Container>
                                </LinearGradient>
                            </View>
                        </ScrollView>
                    </Animated.View>
                </Container>
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({
    hidden: {
        display: "none",
    },
    wrapper: {
        position: "absolute",
        top: Platform.OS == "web" ? 5 : 0,
        left: Platform.OS == "web" ? 5 : 0,
        right: 0,
        bottom: 0,
        zIndex: 9,
    },
    background: {
        position: "absolute",
        height: "100%",
        width: "100%",
    },
    sidebarWrapper: {
        flex: 1,
        backgroundColor: colors.gradientFrom,
        width: "90%",
        borderRadius: Platform.OS == "web" ? settings.cardRadius : 0,
        overflow: "hidden",
        borderTopLeftRadius: 0,
        borderBottomRightRadius: settings.cardRadius,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        showSidebar: state.global.showSidebar,
        readyToRender: state.global.readyToRender,
        unread: state.chat.unread,
        notifications: state.notificationCenter?.notifications,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const Sidebar = connect(mapStateToProps, mapDispatchToProps)(_Sidebar);

export default Sidebar;
