import React, { Component } from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import Card from "@base/Card";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import BoldText from "@base/BoldText";
import Icon from "@components/icons";
import { colors } from "@styles/globalStyles";
import { NavigationType } from "@custom-types/NavigationType";
import { CoincaexBankAccount } from "../helper/coincaex.helper";

interface Props {
    bank: CoincaexBankAccount;
    navigation?: NavigationType;
}

interface State { }

const { t } = i18n;

export class _BankCardComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        const bank = this.props.bank;

        return (
            <Card>
                {bank.bankName &&
                    <Row style={styles.title}>
                        <BoldText fontSize={16}>{bank.bankName}</BoldText>
                        <Icon name="bank" color={colors.white} size={26}></Icon>
                    </Row>
                }

                {bank.bankAccountCurrency &&
                    <Row style={styles.container}>
                        <BoldText color={colors.text} fontSize={12}>
                            {bank.bankAccountCurrency}
                        </BoldText>
                    </Row>
                }

                {bank.bankAccountName &&
                    <Row style={styles.container}>
                        <RegularText style={{ paddingRight: 5 }} color={colors.text} fontSize={12}>
                            {t("account_name")}:
                        </RegularText>
                        <BoldText color={colors.text} fontSize={12}>
                            {bank.bankAccountName}
                        </BoldText>
                    </Row>
                }

                {bank.bankAccountNumber &&
                    <Row style={styles.container}>
                        <RegularText style={{ paddingRight: 5 }} color={colors.text} fontSize={12}>
                            {t("account_number")}:
                        </RegularText>
                        <BoldText color={colors.text} fontSize={12}>
                            {bank.bankAccountNumber}
                        </BoldText>
                    </Row>
                }

                {bank.bankAccountType &&
                    <Row style={styles.container}>
                        <RegularText style={{ paddingRight: 5 }} color={colors.text} fontSize={12}>
                            {t("account_type")}:
                        </RegularText>
                        <BoldText color={colors.text} fontSize={12}>
                            {bank.bankAccountType}
                        </BoldText>
                    </Row>
                }
            </Card >
        );
    }
}

const styles = StyleSheet.create({
    container: {
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        marginBottom: 5,
    },
    title: {
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        marginBottom: 10,
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const BankCardComponent = connect(mapStateToProps, mapDispatchToProps)(_BankCardComponent);

export default BankCardComponent;
