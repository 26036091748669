import React, {Component} from "react"
import {View, StyleSheet,  ScrollView} from "react-native"
import {Header} from "@components/header/Header"
import Container from "@base/Container"
import {HeaderType} from "@custom-types/HeaderType"
import {NavigationType} from "@custom-types/NavigationType"
import { colors, settings } from "@styles/globalStyles"
import {connect} from "react-redux"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import InlineButton from "@base/InlineButton"
import RegularTextInput from "@base/RegularTextInput"
import i18n from "@i18n/i18n"
import RegularText from "@base/RegularText"
import DigitalDocuments from "./DigitalDocuments"
import {KriptonService} from "@core/services/KriptonService"
import store from "@store/index"
import {loading, ready, showPopup} from "@store/actions/global"
import { KriptonMarketNavigatorScreens } from "@navigation/KriptonMarketNavigator"
import PressableBase from "@base/PressableBase"

interface Props {
    navigation: NavigationType,
    country: string
    route: any
}

interface State {
    postal_code: string
    street_address: string
    street_number: string
    telephone_number: string
    city: string,
    country: string,
    service_img: any
    error: string
}

const {t} = i18n

export class _AddressStreetScreen extends Component<Props, State> {
    private kriptonService: KriptonService
    focusListener: any

    constructor(props: Props) {
        super(props)

        this.state = {
            postal_code: "",
            street_address: "",
            street_number: "",
            telephone_number: "",
            city: "",
            country: "",
            service_img: "",
            error: "",
        }
        this.kriptonService = KriptonService.getInstance()
        this.onPressNext = this.onPressNext.bind(this)
        this.onPress = this.onPress.bind(this)
        this.checkCountry = this.checkCountry.bind(this)
    }

    componentDidMount(){
        this.focusListener = this.props.navigation.addListener(
            "focus",
                this.checkCountry
        );
    }

    checkCountry(){
        this.setState({
            country: this.props.route?.params?.country || 'ARG'
        })
    }

    changeInputValue(value, field) {
        this.setState({
            ...this.state,
            [field]: value,
        })
    }

    onPress() {
        this.props.navigation.navigate(KriptonMarketNavigatorScreens.SelectCountryKripton.routeName, {country: this.state.country})
    }

    async onPressNext() {
        if (!this.validate()) {
        } else {
            store.dispatch(loading())

            const domicile_digital_document = await this.kriptonService.newDigitalDocument(
                "service",
                this.state.service_img
            )

            const domicile = await this.kriptonService.setDomicile(this.state)

            store.dispatch(ready())
            store.dispatch(showPopup({type: "SUCCESS"}))
            this.props.navigation.navigate(KriptonMarketNavigatorScreens.ClientStatusKripton.routeName)
        }
    }

    validate() {
        if (this.state.postal_code.length < 1) {
            this.setState({error: "postal_code"})
            return false
        }
        if (this.state.street_address.length < 1) {
            this.setState({error: "street_address"})
            return false
        }
        if (this.state.street_number.length < 1) {
            this.setState({error: "street_number"})
            return false
        }

        if (this.state.telephone_number.length < 1) {
            this.setState({error: "telephone_number"})
            return false
        }
        if (this.state.city.length < 1) {
            this.setState({error: "city"})
            return false
        }

        if (this.state.service_img.length < 1) {
            this.setState({error: "service_img"})
            return false
        }

        return true
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("domicile")} type={HeaderType.Light} {...this.props} />
                <ScrollView>
                    <Container style={styles.container}>
                        {this.state.error == "city" && (
                            <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                {t("field_error")}
                            </RegularText>
                        )}
                        <PressableBase
                     
                            onPress={this.onPress} 
                            style={styles.inputContainer} 
                        >
                            <RegularText
                                style={styles.input}
                                align={"center"}
                            >
                                {this.state.country}
                            </RegularText>
                        </PressableBase>
                        <View style={styles.inputContainer}>
                            <RegularTextInput
                                style={styles.input}
                                align={"center"}
                                onChangeText={(text) => this.changeInputValue(text, "city")}
                                value={this.state.city}
                                placeholder={t("city")}></RegularTextInput>
                        </View>
                        {this.state.error == "postal_code" && (
                            <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                {t("field_error")}
                            </RegularText>
                        )}
                        <View style={styles.inputContainer}>
                            <RegularTextInput
                                style={styles.input}
                                align={"center"}
                                onChangeText={(text) => this.changeInputValue(text, "postal_code")}
                                value={this.state.postal_code}
                                placeholder={t("postal_code")}></RegularTextInput>
                        </View>
                        {this.state.error == "street_address" && (
                            <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                {t("field_error")}
                            </RegularText>
                        )}
                        <View style={styles.inputContainer}>
                            <RegularTextInput
                                style={styles.input}
                                align={"center"}
                                onChangeText={(text) => this.changeInputValue(text, "street_address")}
                                value={this.state.street_address}
                                placeholder={t("street_address")}></RegularTextInput>
                        </View>
                        {this.state.error == "street_number" && (
                            <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                {t("field_error")}
                            </RegularText>
                        )}
                        <View style={styles.inputContainer}>
                            <RegularTextInput
                                style={styles.input}
                                align={"center"}
                                onChangeText={(text) => this.changeInputValue(text, "street_number")}
                                value={this.state.street_number}
                                placeholder={t("street_number")}></RegularTextInput>
                        </View>
                        {this.state.error == "telephone_number" && (
                            <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                {t("field_error")}
                            </RegularText>
                        )}
                        <View style={styles.inputContainer}>
                            <RegularTextInput
                                style={styles.input}
                                align={"center"}
                                onChangeText={(text) => this.changeInputValue(text, "telephone_number")}
                                value={this.state.telephone_number}
                                placeholder={t("telephone_number")}></RegularTextInput>
                        </View>
                        <DigitalDocuments
                            alert={this.state.error == "service_img"}
                            onPressCallBack={(img) => {
                                this.setState({service_img: img, error: ""})
                            }}
                            title={t("service_image")}
                            subtitle={t("service_description")}
                        />

                        <View style={{height: 60, marginTop: 20}}>
                            <InlineButton
                                title={t("save")}
                                onPress={this.onPressNext}
                                style={{marginHorizontal: 0, marginBottom: 20}}
                            />
                        </View>
                    </Container>
                </ScrollView>
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        //justifyContent: "space-between",
        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    inputContainer: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 20,
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        paddingHorizontal: 40,
        textAlign: "center",
    },
})

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => ({})

const AddressStreetScreen = connect(mapStateToProps, mapDispatchToProps)(_AddressStreetScreen)

export default AddressStreetScreen
