import NavigationInstantiator from "./helpers/NavigationInstantiator";
import ProfileMainScreen from "@screens/profile2.0/ProfileMainScreen";
import ProfileOptionsScreen from "@screens/profile2.0/ProfileOptionsScreen";
import ProfileHistorySeedScreen from "@screens/profile2.0/ProfileHistorySeedScreen";
import BlockedUsersScreen from "@screens/profile2.0/BlockedUsersScreen";
import ProfileEditScreen from "@screens/profile2.0/ProfileEditScreen";
import UsersExtendedScreen from "@screens/profile2.0/UsersExtendedScreen";
import ProfilePostsScreen from "@screens/profile2.0/ProfilePostsScreen";
import NFTExtendedScreen from "@screens/profile2.0/NFTExtendedScreen";
import { NTFNavigator } from "./NFTNavigator";
import POAPScreen from "@screens/poaps/POAPScreen";
import { POAPsNavigator } from "./POAPsNavigator";
import PersonalVerificationScreen from "@screens/profile2.0/PersonalVerificationScreen";

const ProfileNavigatorScreens = {
    ProfileMain: {
        routeName: "ProfileMain",
        title: "Profile",
        component: ProfileMainScreen,
    },
    ProfileOptions: {
        routeName: "ProfileOptions",
        title: "Profile",
        component: ProfileOptionsScreen,
    },
    ProfileHistorySeed: {
        routeName: "ProfileHistorySeed",
        title: "History",
        component: ProfileHistorySeedScreen,
    },
    BlockedUsers: {
        routeName: "BlockedUsers",
        title: "Blocked Users",
        component: BlockedUsersScreen,
    },
    ProfileEdit: {
        routeName: "ProfileEdit",
        title: "Profile Edit",
        component: ProfileEditScreen,
    },
    Users: {
        routeName: "Users",
        title: "Users",
        component: UsersExtendedScreen,
    },
    ProfilePosts: {
        routeName: "ProfilePosts",
        title: "Posts",
        component: ProfilePostsScreen,
    },
    ProfileNFT: {
        routeName: "ProfileNFT",
        title: "NFT",
        component: NTFNavigator,
    },
    POAPs: {
        routeName: "POAPs",
        title: "POAPs",
        component: POAPsNavigator,
    },
    ProfileKYC: {
        routeName: "ProfileKYC",
        title: "KYC",
        component: PersonalVerificationScreen,
    }
};
const ProfileNavigator = NavigationInstantiator(ProfileNavigatorScreens);

export { ProfileNavigator, ProfileNavigatorScreens };
