import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import i18n from "@i18n/i18n";
import Container from "@base/Container";
import SimpleCard from "@components/cards/SimpleCard";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { HeaderType } from "@custom-types/HeaderType";
import { Header } from "@components/header/Header";
import { NavigationType } from "@custom-types/NavigationType";
import { Route } from "@react-navigation/native";
import SwitchCard from "@components/cards/SwitchCard";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import ProfileService from "@core/services/ProfileService";
import { Client } from "@custom-types/Client";
import { connect } from "react-redux";
import { ProfileData } from "./components/ProfileHeader";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import store from "@store/index";
import { hideModal, loading, ready, showModal } from "@store/actions/global";
import { reloadPosts } from "@store/actions/social.actions";
import { setRequestStatusNFTs } from "@store/actions/nfts.action";
import { MarketPlaceStatus } from "@store/reducers/nfts.reducer";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";

interface Props {
    navigation: NavigationType;
    route: Route<string, { isOwner?: boolean; client?: ProfileData }>;
    client: Client;
}

interface State {
    isOwner: boolean;
    isPrivateProfile: boolean;
    profile: ProfileData;
}

const { t } = i18n;

export class _ProfileOptionsScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.setPrivateProfile = this.setPrivateProfile.bind(this);
        this.showBlockUserModal = this.showBlockUserModal.bind(this);
        this.blockUser = this.blockUser.bind(this);
        this.state = {
            isOwner: this.props.route.params?.isOwner || false,
            isPrivateProfile: this.props.client?.isPrivateProfile || false,
            profile: this.props.route.params?.client || null,
        };
    }

    async setPrivateProfile() {
        await ProfileService.getInstance().setProfile({ isPrivateProfile: !this.state.isPrivateProfile });
    }

    showBlockUserModal() {
        store.dispatch(
            showModal({
                title: t("block_user"),
                message: t("block_user_modal"),
                btnTitle: t("confirm"),
                onPress: this.blockUser,
            }),
        );
    }

    async blockUser() {
        store.dispatch(hideModal());
        store.dispatch(loading());
        store.dispatch(reloadPosts(true));
        store.dispatch(setRequestStatusNFTs({ status: MarketPlaceStatus.Reload }));
        const blockUser = await SocialNetworkService.getInstance().blockUser(this.state.profile._id);
        this.props.navigation.goBack();
        this.props.navigation.goBack();
        store.dispatch(ready());
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={
                        this.state.isOwner ? t("settings") : trimHelper("@" + this.state.profile?.alias || "", 12)
                    }
                    type={HeaderType.Light}
                    {...this.props}
                />
                {this.state.isOwner && this.state.profile ? (
                    <Container style={{ flex: 1 }}>
                        <SwitchCard
                            title={t("private_account")}
                            onToggle={this.setPrivateProfile}
                            isOn={this.state.isPrivateProfile}
                        />
                        <SimpleCard
                            title={t("set_profile")}
                            icon="edit"
                            iconSize={24}
                            onPress={() => {
                                this.props.navigation.navigate(ProfileNavigatorScreens.ProfileEdit.routeName);
                            }}
                        />
                        {/* <SimpleCard
                            title={t("personal_verification")}
                            icon="id-card"
                            tag="Unverified"
                            onPress={() => {
                                this.props.navigation.navigate(ProfileNavigatorScreens.ProfileKYC.routeName);
                            }}
                        /> */}
                        <SimpleCard
                            title={t("blocked_user")}
                            icon="person-x"
                            onPress={() => {
                                this.props.navigation.navigate(ProfileNavigatorScreens.BlockedUsers.routeName);
                            }}
                        />
                        {/* <SimpleCard
                            title={t("history_seed")}
                            icon="clock-history"
                            onPress={() => {
                                this.props.navigation.navigate(ProfileNavigatorScreens.ProfileHistorySeed.routeName);
                            }}
                        /> */}
                    </Container>
                ) : (
                    <Container style={{ flex: 1 }}>
                        {this.state.profile && (
                            <SimpleCard
                                title={t("block_user")}
                                icon="person-x"
                                onPress={() => {
                                    this.showBlockUserModal();
                                }}
                            />
                        )}
                    </Container>
                )}
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ProfileOptionsScreen = connect(mapStateToProps, mapDispatchToProps)(_ProfileOptionsScreen);

export default ProfileOptionsScreen;
