import React, { Component } from "react";
import { View, StyleSheet, BackHandler, Platform } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import RegularText from "@base/RegularText";
import { HeaderType } from "@custom-types/HeaderType";
import { colors } from "@styles/globalStyles";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import Wallet from "@core/wallet/Wallet";
import store from "@store/index";
import { switchNetwork } from "@store/actions/wallet";
import Card from "@base/Card";
import BoldText from "@base/BoldText";
import BotCard from "@base/BotCard";
import Label from "@base/Label";

interface Props {
    navigation: any;
}

interface State {
    mainnet: boolean;
}

const { t } = i18n;
export default class NetworkScreen extends Component<Props, State> {
    private wallet: Wallet;
    focusListener: any;
    constructor(props: Props) {
        super(props);

        this.wallet = Wallet.getInstance();
        this.state = {
            mainnet: !this.wallet.isTestnet(),
        };
        this.switchNetwork = this.switchNetwork.bind(this);
        if (Platform.OS != 'web')
            BackHandler.addEventListener("hardwareBackPress", () => this.props.navigation.isFocused());
    }
    switchNetwork() {
        store.dispatch(switchNetwork({ testnet: this.state.mainnet }));
        this.setState({ mainnet: !this.state.mainnet });
    }

    componentDidMount() {
        this.wallet = Wallet.getInstance();
        this.setState({ mainnet: !this.wallet.isTestnet() });
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        try {
            this.focusListener.remove();
        } catch (e) { }
    }
    render() {
        return (
            <ScreenWrapper>
                <Header title={t("network")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    <View style={{ paddingVertical: 20 }}>
                        <BotCard message={t("mainnet_testnet")}></BotCard>
                    </View>
                    <Card
                        onPress={this.switchNetwork}
                        left={
                            <View>
                                {this.state.mainnet ? (
                                    <BoldText align="left">Mainnet</BoldText>
                                ) : (
                                    <RegularText align="left">Mainnet</RegularText>
                                )}
                            </View>
                        }
                        right={
                            <View>
                                {this.state.mainnet && (
                                    <Label text={t("active")} fontSize={12} />
                                )}
                            </View>
                        }
                    />
                    <Card
                        onPress={this.switchNetwork}
                        left={
                            <View>
                                {!this.state.mainnet ? (
                                    <BoldText align="left">Testnet</BoldText>
                                ) : (
                                    <RegularText align="left">Testnet</RegularText>
                                )}
                            </View>
                        }
                        right={
                            <View>
                                {!this.state.mainnet && (
                                    <Label text={t("active")} fontSize={12} />
                                )}
                            </View>
                        }
                        accessibilityLabel="testnet-button"
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    active: {
        backgroundColor: colors.secondary,
        borderRadius: 5,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        paddingHorizontal: 8,
    },
});
