import React, {Component} from "react"
import {View, StyleSheet} from "react-native"
import {Header} from "@components/header/Header"
import Container from "@base/Container"
import {HeaderType} from "@custom-types/HeaderType"
import {NavigationType} from "@custom-types/NavigationType"
import { colors, settings } from "@styles/globalStyles"
import {connect} from "react-redux"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import InlineButton from "@base/InlineButton"
import RegularTextInput from "@base/RegularTextInput"
import i18n from "@i18n/i18n"
import RegularText from "@base/RegularText"
import {KriptonService} from "@core/services/KriptonService"
import store from "@store/index"
import {loading, ready, showPopup} from "@store/actions/global"
import { KriptonMarketNavigatorScreens } from "@navigation/KriptonMarketNavigator"

interface Props {
    navigation: NavigationType
}

interface State {
    name_bank: string
    cbu: string
    tax_identification: string
    error: string
}

const {t} = i18n

export class _BankScreen extends Component<Props, State> {
    private kriptonService: KriptonService
    constructor(props: Props) {
        super(props)

        this.state = {
            name_bank: "",
            cbu: "",
            tax_identification: "",
            error: "",
        }
        this.onPressNext = this.onPressNext.bind(this)
        this.kriptonService = KriptonService.getInstance()
    }

    changeInputValue(value, field) {
        this.setState({
            ...this.state,
            [field]: value,
        })
    }

    async onPressNext() {
         
        if (!this.validate()) {
            
        } else {
            store.dispatch(loading());
            const bank = this.kriptonService.setBank(
                this.state.name_bank,
                this.state.cbu,
                this.state.tax_identification
            )
            if (bank) {
                store.dispatch(ready())
                store.dispatch(showPopup({type: "SUCCESS"}))
                this.props.navigation.navigate(KriptonMarketNavigatorScreens.ClientStatusKripton.routeName)
            }
        }
    }

    validate() {
        if (this.state.name_bank.length < 1) {
            this.setState({error: "name_bank"})
            return false
        }
        if (this.state.cbu.length < 1) {
            this.setState({error: "cbu"})
            return false
        }
        if (this.state.tax_identification.length < 1) {
            this.setState({error: "tax_identification"})
            return false
        }

        return true
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("bank")} type={HeaderType.Light} {...this.props} />

                <Container style={styles.container}>
                    {this.state.error == "name_bank" && (
                        <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                            {t('field_error')}
                        </RegularText>
                    )}
                    <View style={styles.inputContainer}>
                        <RegularTextInput
                            style={styles.input}
                            align={"center"}
                            onChangeText={(text) => this.changeInputValue(text, "name_bank")}
                            value={this.state.name_bank}
                            placeholder={t("name_bank")}></RegularTextInput>
                    </View>

                    {this.state.error == "cbu" && (
                        <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                            {t('field_error')}
                        </RegularText>
                    )}
                    <View style={styles.inputContainer}>
                        <RegularTextInput
                            style={styles.input}
                            align={"center"}
                            onChangeText={(text) => this.changeInputValue(text, "cbu")}
                            value={this.state.cbu}
                            placeholder={t("cbu")}></RegularTextInput>
                    </View>

                    {this.state.error == "tax_identification" && (
                        <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                            {t('field_error')}
                        </RegularText>
                    )}
                    <View style={styles.inputContainer}>
                        <RegularTextInput
                            style={styles.input}
                            align={"center"}
                            onChangeText={(text) => this.changeInputValue(text, "tax_identification")}
                            value={this.state.tax_identification}
                            placeholder={t("tax_identification")}></RegularTextInput>
                    </View>
                </Container>
                <Container style={{flex: 1, flexDirection: "column-reverse"}}>
                    <View style={{height: 60, marginTop: 20}}>
                        <InlineButton
                            title={t("save")}
                            onPress={this.onPressNext}
                            style={{marginHorizontal: 0, marginBottom: 20}}
                        />
                    </View>
                </Container>
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        //justifyContent: "space-between",
        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    inputContainer: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 20,
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        paddingHorizontal: 40,
        textAlign: "center",
    },
})

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => ({})

const BankScreen = connect(mapStateToProps, mapDispatchToProps)(_BankScreen)

export default BankScreen
