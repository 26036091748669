import { FollowerRequestType } from "@custom-types/FollowerRequestType";
import Post from "@custom-types/Post";
import { RELOAD } from "@store/actions/global";
import {
    ADD_DISCOVER_POSTS,
    ADD_MAIN_POSTS,
    RELOAD_POSTS,
    SET_DISCOVER_POSTS,
    SET_FOLLOWER_REQUEST,
    SET_MAIN_POSTS,
    SET_PROFILE_POSTS_NFT,
    SET_PROFILE_POSTS_SOCIAL,
    SET_SELECTED_POST,
    UPDATE_DISCOVER_POST,
} from "@store/actions/social.actions";

interface socialState {
    mainPosts: Array<Post>;
    discoverPosts: Array<Post>;
    profilePostsSocial: Array<Post>;
    profilePostsNFT: Array<Post>;
    reloadPosts: boolean;
    followerRequests: Array<FollowerRequestType>;
    selectedPost: Post;
}

const initialState: socialState = {
    mainPosts: [],
    discoverPosts: [],
    profilePostsSocial: [],
    profilePostsNFT: [],
    reloadPosts: false,
    followerRequests: [],
    selectedPost: null,
};

const socialReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MAIN_POSTS:
            return {
                ...state,
                mainPosts: action.mainPosts || state.mainPosts,
            };

        case ADD_MAIN_POSTS:
            return {
                ...state,
                mainPosts: [...state.mainPosts, ...action.mainPosts],
            };

        case SET_DISCOVER_POSTS:
            return {
                ...state,
                discoverPosts: action.discoverPosts || state.discoverPosts,
            };

        case UPDATE_DISCOVER_POST:
            const index = state.discoverPosts?.findIndex((p) => p._id === action.post._id);
    
            const newState = [...state.discoverPosts]
            newState[index].likes.liked = action.post.likes.like

            return {
                ...state,
                discoverPosts: [...newState]
            }

        case ADD_DISCOVER_POSTS:
            return {
                ...state,
                discoverPosts: [...state.discoverPosts, ...action.discoverPosts],
            };

        case RELOAD_POSTS:
            return {
                ...state,
                reloadPosts: action.reloadPosts,
            };
        case SET_PROFILE_POSTS_SOCIAL:
            return {
                ...state,
                profilePostsSocial: action.profilePostsSocial,
            };

        case SET_PROFILE_POSTS_NFT:
            return {
                ...state,
                profilePostsNFT: action.profilePostsNFT,
            };

        case SET_FOLLOWER_REQUEST:
            return {
                ...state,
                followerRequests: action.followerRequests,
            };
        case SET_SELECTED_POST:
            return {
                ...state,
                selectedPost: action.selectedPost || state.selectedPost,
            };

        case RELOAD:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default socialReducer;
