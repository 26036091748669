import { PathNode } from "@core/utils/PathNode";
import Common from "ethereumjs-common";
import * as ethUtil from "ethereumjs-util";
import HDNode from "hdkey";
import Web3 from "web3";
import CurrencyImplementation, { Skeleton } from "../../CurrencyImplementation";
import Wallet from "@core/wallet/Wallet";

const EthereumTx = require("ethereumjs-tx").Transaction;
const ethers = require("ethers");

export default class ETHImplementation extends CurrencyImplementation {
    async signMessage(addressNode: PathNode, hexMessage: string) {
        //@TODO assert >> this.currency.getAddress() == account
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        let wallet = new ethers.Wallet(privateKey);
        return await wallet.signMessage(hexMessage);
    }

    async signTypedData(addressNode: PathNode, data) {
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        let wallet = new ethers.Wallet(privateKey);

        try {
            if (!data?.types && !data?.message) {
                data = await JSON.parse(data);
            }
            if (data?.types && data.types["EIP712Domain"]) {
                delete data?.types["EIP712Domain"];
            }
            return await wallet._signTypedData(data?.domain, data?.types, data?.message);
        } catch (e) {}
    }

    generateAddress(addressNode: PathNode, options?: { chainId?: number }) {
        const chainId = options.chainId;
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        let address = "0x" + ethUtil.privateToAddress(privateKey).toString("hex");

        return Web3.utils.toChecksumAddress(address);

        //return Web3.utils.toChecksumAddress(address);
        //return rsk3.utils.toChecksumAddress(address, chainId);
    }

    async signTransaction(addressNode: PathNode, skeleton) {
        const rawTx = skeleton?.transactionData;
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;

        rawTx.gasLimit = rawTx.gas;
        rawTx.chainId = rawTx?.chainId || Number(skeleton.currency?.blockchainInfo?.chainId) || "";
        rawTx.data = rawTx?.data ? rawTx.data : "0x";

        if (rawTx.chainId) {
            const customCommon = Common.forCustomChain(
                this.currency.isTestnet() ? "ropsten" : "mainnet",
                {
                    chainId: rawTx.chainId,
                },
                "petersburg"
            );

            const tx = new EthereumTx(rawTx, { common: customCommon });
            tx.sign(privateKey);
            const serializedTx = tx.serialize();
            return "0x" + serializedTx.toString("hex");
        } else {
            const tx = new EthereumTx(rawTx, { chain: this.currency.isTestnet() ? "ropsten" : "mainnet" });
            tx.sign(privateKey);
            const serializedTx = tx.serialize();
            return "0x" + serializedTx.toString("hex");
        }
    }

    parseSkeleton(skeletonData): Skeleton {
        const amount = this.currency.fromDecimals(this.parseValue(
            skeletonData?.extraTransactionData?.value || skeletonData?.transactionData?.value || 0
        ));
        const sendingFrom = skeletonData?.from || skeletonData?.addressFrom || "";
        const sendingTo = skeletonData?.extraTransactionData?.to || skeletonData?.transactionData?.to || "";
        const underlyingCurrency = this.currency.getUnderlyingCurrency();

        const feeData = {
            amount: underlyingCurrency.fromDecimals(skeletonData.feeData?.amount) || 0,
            digitalCurrencyId: underlyingCurrency?.getId(),
        };
        

        const exchangeData = {
            fromAmount: this.currency.fromDecimals(skeletonData?.extraTransactionData?.value || 0),
            toCurrency: skeletonData?.extraTransactionData?.toCurrency || '',
            toAmount: Wallet.getInstance().findCurrencyById(skeletonData?.extraTransactionData?.toCurrency)?.fromDecimals(skeletonData?.extraTransactionData?.toAmount || 0),
            exchangeFeeData:
                skeletonData?.exchangeFeeData?.map((s) => {
                    const currency = Wallet.getInstance().findCurrencyById(s?.digitalCurrencyId);
                    if(!currency ) return
                    return {
                        amount: currency.fromDecimals(s?.amount || 0),
                        digitalCurrencyId: s?.digitalCurrencyId || "",
                        provider: s?.provider || "",
                    };
                }) || [],

            exchangeProvider: {
                name: skeletonData?.extraTransactionData?.toolDetails?.name || "",
                image: skeletonData?.extraTransactionData?.toolDetails?.logoURI || "",
            },
        };

        const skeleton: Skeleton = {
            amount: amount,
            sendingFrom: sendingFrom,
            sendingTo: sendingTo,
            feeData: feeData,
            exchangeData: exchangeData,
        };

        return skeleton;
    }

    parseValue(value) {
        if (typeof value === "string" && value.startsWith("0x")) {
            return parseInt(value, 16);
        }
        return parseInt(value);
    }

    isValidAddress(address: string) {
        return Web3.utils.isAddress(address.toLowerCase());
    }
}
