
import Wallet from "@core/wallet/Wallet";
import { ExperiencesRedux } from "@custom-types/ExpercienceModel";
import {
    SET_EXPERIENCES,
    SET_MEMBERSHIPS,
    SET_REDEEMABLES,
    UPDATE_MEMBERSHIP,
    UPDATE_REDEEMABLE,
    SET_SELECTED_REDEEMABLE,
    SET_MERCHANTS,
    SET_ASSETS,
    UPDATE_ASSET,
    ADD_REDEEMABLE_CART,
    REMOVE_REDEEMABLE_CART,
    EMPTY_REDEEMABLE_CART,
    SET_EXPERIENCE_FIAT_CURRENCY,
    SET_REEDEMABLE_CART,
    EMPTY_REDEEMABLE_CART_ITEM,
    SET_EXPERIENCE_CURRENCY,
    EMPTY_EXPERIENCE_CURRENCY
} from "@store/actions/experiences.actions";
import { RELOAD } from "@store/actions/global";
import { EMPTY_SPACE } from "@walletconnect/utils";

const initialState: ExperiencesRedux = {
    membership: {
        docs: [],
        paginator: { page: 1, totalDocs: 0, totalPages: 1 },
    },
    ticket: {
        docs: [],
        paginator: { page: 1, totalDocs: 0, totalPages: 1 },
    },
    voucher: {
        docs: [],
        paginator: { page: 1, totalDocs: 0, totalPages: 1 },
    },
    asset: {
        docs: [],
        paginator: { page: 1, totalDocs: 0, totalPages: 1 },
    },
    experiences: {
        docs: [],
        paginator: { page: 1, totalDocs: 0, totalPages: 1 },
    },
    merchants: {
        docs: [],
        paginator: { page: 1, totalDocs: 0, totalPages: 1 },
    },
    selectedRedeemable: null,
    selectedFiatCurrency: null,
    checkoutRedeemables: [],
    selectedCurrency: null,
};

const experiencesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REDEEMABLES:
            return {
                ...state,
                [action.redeemableType]: {
                    docs: action.redeemables,
                    paginator: action.paginator,
                },
            };
        case SET_MEMBERSHIPS:
            return {
                ...state,
                membership: {
                    docs: action.memberships,
                    paginator: action.paginator,
                },
            };
        case SET_ASSETS:
            return {
                ...state,
                asset: {
                    docs: action.assets,
                    paginator: action.paginator,
                },
            };
        case UPDATE_REDEEMABLE:
            const index = state[action.redeemable.redeemableOption.type].docs?.findIndex(
                (r) => r._id === action.redeemable._id,
            );
            state[action.redeemable.redeemableOption.type].docs[index] = action.redeemable;

            return {
                ...state,
                [action.redeemable.redeemableOption.type]: state[action.redeemable.redeemableOption.type],
            };

        case UPDATE_MEMBERSHIP:
            const m = state.membership.docs?.findIndex((r) => r._id === action.membership._id);
            state.membership.docs[m] = action.membership;

            return {
                ...state,
                membership: state.membership,
            };
        case UPDATE_ASSET:
            const a = state.asset.docs?.findIndex((asset) => asset._id === action.asset._id);
            state.asset.docs[a] = action.asset;

            return {
                ...state,
                asset: state.asset,
            };
        case SET_EXPERIENCES:
            return {
                ...state,
                experiences: {
                    docs: action.experiences,
                    paginator: action.paginator,
                },
            };

        case SET_MERCHANTS:
            return {
                ...state,
                merchants: {
                    docs: action.merchants,
                    paginator: action.paginator,
                },
            };

        case SET_SELECTED_REDEEMABLE:
            return {
                ...state,
                selectedRedeemable: action.redeemable,
            };

        case RELOAD:
            return {
                ...initialState,
            };

        case ADD_REDEEMABLE_CART:
            const addId = action.payload.redeemable._id
            const addIndex = state.checkoutRedeemables.findIndex(
                (item: any) => item.redeemable._id === addId
            );
            const updateRedeemableAdd = [...state.checkoutRedeemables];
            updateRedeemableAdd[addIndex].quantity += 1;

            return {
                ...state,
                checkoutRedeemables: updateRedeemableAdd,
            };

        case REMOVE_REDEEMABLE_CART:
            const removeId = action.payload.redeemable._id
            const removeIndex = state.checkoutRedeemables.findIndex(
                (item: any) => item.redeemable._id === removeId
            );
            const updateRedeemableRemove = [...state.checkoutRedeemables];
            updateRedeemableRemove[removeIndex].quantity -= 1;

            return {
                ...state,
                checkoutRedeemables: updateRedeemableRemove,
            };

        case EMPTY_REDEEMABLE_CART:

            return {
                ...state,
                checkoutRedeemables: [],
            };

        case SET_EXPERIENCE_FIAT_CURRENCY:
            return {
                ...state,
                selectedFiatCurrency: action.fiatCurrency,
            };

        case SET_REEDEMABLE_CART:
            return {
                ...state,
                checkoutRedeemables: action.payload.redeemable,
            };

        case EMPTY_REDEEMABLE_CART_ITEM:
            const emptyIndex = state.checkoutRedeemables.findIndex(
                (item: any) => item.redeemable._id === action.payload.id
            );

            const emptyRedeemable = [...state.checkoutRedeemables];
            emptyRedeemable[emptyIndex].quantity = 0;

            return {
                ...state,
                checkoutRedeemables: emptyRedeemable,
            };

        case SET_EXPERIENCE_CURRENCY:
            return {
                ...state,
                selectedCurrency: {
                    currency: action.selectedCurrency.currency,
                    gatewayId: action.selectedCurrency.gatewayId
                },
            };

        case EMPTY_EXPERIENCE_CURRENCY:
            return {
                ...state,
                selectedCurrency: null
            }

        default:
            return state;
    }
};

export default experiencesReducer;
