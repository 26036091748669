import React, { Component } from "react";
import { View, StyleSheet, RefreshControl } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import i18n from "@i18n/i18n";
import NFT from "@custom-types/NFTModel";
import NFTSmallCard from "@screens/nfts/components/NFTSmallCard";
import store from "@store/index";
import { setRequestStatusNFTs, setSelectedNFT, setTemporalNFTs } from "@store/actions/nfts.action";
import NFTService from "@core/services/NFTService";
import { colors } from "@styles/globalStyles";
import { connect } from "react-redux";
import { showModal } from "@store/actions/global";
import { MarketPlaceStatus } from "@store/reducers/nfts.reducer";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import BotCard from "@base/BotCard";
import FlatListBase from "@base/FlatListBase";

const { t } = i18n;

interface Props {
    navigation: NavigationType;
    clientId: string;
    temporalNfts: Array<NFT>;
    getProfileHeader: () => any;
    isOwner: boolean;
    loadingBackground?: boolean;
    onPressCard?: () => void;
    NFTs: Array<NFT>;
}

interface State {
    nfts: Array<NFT>;
    syncing: boolean;
    isMount: boolean;
}

export class _NFTsSection extends Component<Props, State> {
    intervalID: any;
    constructor(props: Props) {
        super(props);
        this.setUserNFTS = this.setUserNFTS.bind(this);
        this.onPressNFTCard = this.onPressNFTCard.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.setUserTemporalsNFTs = this.setUserTemporalsNFTs.bind(this);
        this.watchProcess = this.watchProcess.bind(this);
        this.setDfratNFTs = this.setDfratNFTs.bind(this);
        this.watchProcessDraft = this.watchProcessDraft.bind(this);
        this.state = {
            nfts: [],
            isMount: true,
            syncing: false,
        };
    }

    componentDidMount() {
        this.setState({ isMount: true });
        this.setUserNFTS(true);
        setTimeout(() => {
            this.setState({ isMount: false });
        }, 20000);
    }

    componentDidUpdate(props): void {
        if (props.NFTs !== this.props.NFTs) {
            this.setUserNFTS(true);
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
        this.setState({ isMount: false });
    }

    async setUserNFTS(isReducer: boolean) {
        this.setState({ syncing: true });

        const nfts = isReducer ? this.props.NFTs : await NFTService.getInstance().getUserNFTs(this.props.clientId);

        if (nfts?.length) {
            const sorted_nfts: Array<NFT> = nfts.sort((a: any, b: any) =>
                new Date(a.updatedAt) < new Date(b.updatedAt) ? 1 : -1,
            );
            // const nftsFilter = nfts.filter((n: NFT) => {
            //     return !n.isDraft;
            // });
            this.setState({ syncing: false, nfts: sorted_nfts }, () => {
                this.setUserTemporalsNFTs();
                this.setDfratNFTs();
            });
        }
    }

    setDfratNFTs() {
        const filteredDraftNFT = this.state.nfts.filter((nft: NFT) => {
            return (
                (nft.isDraft && nft.draftState == "pending") ||
                nft.draftState == "processing" ||
                nft.draftState == "preminted" ||
                nft.draftState == "minted"
            );
        });
        if (filteredDraftNFT?.length > 0) {
            this.watchProcessDraft();
        }
    }

    watchProcessDraft() {
        if (this.state.isMount) {
            setTimeout(async () => {
                this.setUserNFTS(false);
            }, 15000);
        }
    }

    setUserTemporalsNFTs() {
        if (this.props.temporalNfts?.length > 0) {
            const filteredTemporalNFT = this.props.temporalNfts.filter((nft: NFT) => {
                return nft.owner._id == this.props.clientId;
            });
            if (filteredTemporalNFT?.length > 0) {
                this.setState({ nfts: filteredTemporalNFT.concat(this.state.nfts) });
                this.watchProcess(filteredTemporalNFT);
            }
        }
    }

    watchProcess(filteredTemporalNFT) {
        this.intervalID = setInterval(async () => {
            this.proccessTemporalNFTs(filteredTemporalNFT);
        }, 15000);
    }

    async proccessTemporalNFTs(filteredTemporalNFT) {
        const existTemporalBuy = filteredTemporalNFT.find((t: NFT) => {
            return t.type == "temporalBuy";
        });
        if (existTemporalBuy !== undefined) {
            // await NFTService.getInstance().getNFT(existTemporalBuy._id, false);
        }

        const nfts = await NFTService.getInstance().getUserNFTs(this.props.clientId);

        if (nfts?.length > 0) {
            const nftExist = nfts.find((n: NFT) => {
                return n.uri.split("/").slice(-2)[0] == filteredTemporalNFT[0].uri.split("/").slice(-2)[0] && n.image;
            });

            if (nftExist !== undefined) {
                const resetReducer = this.props.temporalNfts.filter((n: NFT) => {
                    return n.uri.split("/").slice(-1)[0] !== nftExist.uri.split("/").slice(-1)[0];
                });
                store.dispatch(setTemporalNFTs(resetReducer || []));
                const sorted_nfts: Array<NFT> = nfts.sort((a: any, b: any) =>
                    new Date(a.updatedAt) < new Date(b.updatedAt) ? 1 : -1,
                );
                this.setState({ nfts: sorted_nfts });
                store.dispatch(setRequestStatusNFTs({ status: MarketPlaceStatus.Reload }));
                clearInterval(this.intervalID);
            }
        }
    }

    onRefresh() {
        this.setUserNFTS(false);
    }

    async onPressNFTCard(nft: NFT) {
        if (nft.type == "temporalBuy" || nft.type == "temporalVideo") {
            store.dispatch(
                showModal({
                    title: nft.name,
                    avatar: nft.image.thumbnail,
                    subtitle: t("nft_temporal_subtitle"),
                    message: t("nft_temporal_message"),
                }),
            );
            return;
        }

        const updatedNft = await NFTService.getInstance().getNFT(nft._id, nft.blockchain, true);
        if (updatedNft) {
            store.dispatch(setSelectedNFT(updatedNft));
            this.props.navigation.navigate(ProfileNavigatorScreens.ProfileNFT.routeName);
        }
    }

    renderItem = ({ item }) => (
        <NFTSmallCard
            key={item._id}
            isOwner={this.props.isOwner}
            nft={item}
            onPress={() => this.onPressNFTCard(item)}
        />
    );

    render() {
        return (
            <Container style={{ flex: 1, paddingHorizontal: 5 }}>
                <FlatListBase
                    showsHorizontalScrollIndicator={false}
                    data={this.state.nfts}
                    renderItem={this.renderItem}
                    keyExtractor={(item) => item._id}
                    contentContainerStyle={{ paddingBottom: 20 }}
                    ListHeaderComponent={this.props.getProfileHeader()}
                    numColumns={2}
                    columnWrapperStyle={{ justifyContent: "space-between", flex: 1 }}
                    extraData={this.state.nfts}
                    refreshControl={
                        <RefreshControl
                            progressViewOffset={10}
                            tintColor={colors.text}
                            onRefresh={this.onRefresh}
                            refreshing={this.props.loadingBackground}
                        />
                    }
                    ListEmptyComponent={
                        <View style={{ paddingHorizontal: 15, paddingTop: 15 }}>
                            {!this.props.loadingBackground && (
                                <BotCard
                                    title={this.props.isOwner ? t("empty_nft_own") : null}
                                    message={this.props.isOwner ? t("empty_create_nft") : t("empty_nft")}
                                />
                            )}
                        </View>
                    }
                />
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        temporalNfts: state.nfts.temporalNfts,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const NFTsSection = connect(mapStateToProps, mapDispatchToProps)(_NFTsSection);

export default NFTsSection;

const styles = StyleSheet.create({});
