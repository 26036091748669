import {StyleSheet, Appearance} from "react-native"

const darkColors = {
    primary: '#303030',
    secondary: '#ffc404',
    complementary: '#ffc404',
    tertiary: '#1a1a1a', 
    background: '#4b4432',
    gradientFrom: '#303030',
    gradientTo: '#131313',
    grey: '#9091a4',
    white: '#fff',
    black: '#333',
    blue: '#2696fb',
    green: '#1DBC60',
    yellow: '#FECA54',
    red: '#EE4540',
    text: '#fff',
    shadow: 'rgba(255,255,255,0.04)',
    secondaryShadow: 'rgba(255, 255, 255, 0.1)',
    tertiaryShadow: 'rgba(255, 255, 255, 0.08)'
}

const lightColors = {
    primary: '#d8d9db',
    secondary: '#cca532',
    complementary: '#cca532',
    tertiary: '#fff', 
    background: '#d8d9db',
    gradientFrom: '#f9f7f3',
    gradientTo: '#dbdad8',
    grey: '#b3953cc4',
    white: 'white',
    black: '#333',
    blue: '#2696fb',
    green: '#1DBC60',
    yellow: '#FECA54',
    red: '#EE4540',
    text: '#715719',
    shadow: 'rgba(255,255,255,0.7)',
    secondaryShadow: 'rgba(0, 0, 0, 0.1)',
    tertiaryShadow: 'rgba(239, 183, 66, 0.1)'
}

const settings = {}

export { lightColors, darkColors, settings }
