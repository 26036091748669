import {SET_CLIENT} from '@store/actions/chat.actions';
import {SET_TOKEN} from "@store/actions/auth";
import {Token} from "@custom-types/Token";
import {Client} from "@custom-types/Client";
import { RELOAD } from '@store/actions/global';

interface state {
  client: Client,
  token: Token
}

const initialState: state = {
  client: null,
  token: null
};

const authReducer = (state = initialState, action) => {
  switch(action.type) {

    case SET_CLIENT:
      return {
        ...state,
        client: action.client
      }

    case SET_TOKEN:
      return {
        ...state,
        token: action.token
      }

    default:
      return state;
  }
};

export default authReducer;