import React, { Component } from "react";
import { View, Text, StyleSheet } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import store from "@store/index";
import { hideModal, reset, showModal, showPopup } from "@store/actions/global";
import InlineButton from "@base/InlineButton";
import { generateMnemonic } from "bip39";
import WarningCard from "@base/WarningCard";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import BotCard from "@base/BotCard";
import Initializer, { AppStatus } from "../../initializer/Initializer";
import { AuthNavigatorScreens } from "@navigation/AuthNavigator";
import { OAuthService } from "@core/services/oauth/OAuthService";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import Card from "@base/Card";
import Row from "@base/Row";
import AvatarBase from "@components/avatar/AvatarBase";
import BoldText from "@base/BoldText";
import Wallet from "@core/wallet/Wallet";
import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import { getColorOpacity } from "@utils/helpers/global/global";

interface Props {
    navigation: NavigationType;
    client: Client;
}

interface State {
    words: string[];
}

const { t } = i18n;

export class _RemoveSeedScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPress = this.onPress.bind(this);
        this.state = {
            words: Wallet.getInstance().getMnemonic(),
        };
    }

    async onPress() {
        await OAuthService.getInstance().removeAccount(this.state.words.join(" "));
        Initializer.redirect(this.props.navigation);
        
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("remove_account")} type={HeaderType.Light} {...this.props} />
                <Container style={styles.container}>
                    <View style={{flex: 1}}>
                        <BotCard title={t("warning")} message={t("remove_seed_warning")}></BotCard>
                        
                        <Card style={{ marginTop: 25 }}>
                            <View style={{ }}>
                                <AvatarBase
                                    uri={
                                        this.props?.client?.profileImagePath?.thumbnail ||
                                        this.props?.client?.profileImagePath?.square ||
                                        ""
                                    }
                                    alias={this.props?.client?.alias}
                                    size={60}
                                ></AvatarBase>
                                <View style={{ paddingTop: 10 }}>
                                    <BoldText align="center" fontSize={18} numberOfLines={1}>
                                        {"@"}
                                        {this.props?.client?.alias}
                                    </BoldText>
                                </View>
                                <View style={{ paddingTop: 20, paddingBottom: 20 }}>
                                    <BoldText>{t("seed_phrase")}</BoldText>
                                    <View
                                        style={{
                                            
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            flexWrap: "wrap",
                                            paddingTop: 10,
                                        }}
                                    >
                                        {this.state.words?.map((w) => {
                                            return (
                                                <RegularText
                                                key={w}
                                                    fontSize={12}
                                                    align="center"
                                                    style={{
                                                        marginRight: 8,
                                                        marginTop:8,
                                                        backgroundColor: colors.tertiaryShadow,
                                                        borderRadius: 5,
                                                        paddingHorizontal: 6,
                                                        paddingVertical: 2,
                                                    }}
                                                >
                                                    {w}
                                                </RegularText>
                                            );
                                        })}
                                    </View>
                                </View>
                            </View>
                        </Card>
                    </View>
                    <InlineButton
                        title={t("confirm")}
                        onPress={this.onPress}
                        style={{ marginHorizontal: 0, marginBottom: 20 }}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    word: {
        paddingHorizontal: 20,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const RemoveSeedScreen = connect(mapStateToProps, mapDispatchToProps)(_RemoveSeedScreen);

export default RemoveSeedScreen;
