// React
import React, { Component } from "react";
import { BackHandler, FlatList, Platform } from "react-native";

// Types
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import { NotificationModel } from "@custom-types/NotificationModel";

// Components
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import NotificationCard from "@components/cards/NotificationCard";
import BotCard from "@base/BotCard";

// Base
import Container from "@base/Container";

// Store
import { connect } from "react-redux";

import i18n from "@i18n/i18n";
import { NotificationService } from "@core/services/NotificationService";

interface Props {
    navigation: NavigationType;
    notifications: Array<NotificationModel>;
}

interface State {}

const { t } = i18n;

export class _NotificationsScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        if (Platform.OS != 'web')
            BackHandler.addEventListener("hardwareBackPress", () => this.props.navigation.isFocused());
    }

    componentDidMount() {
        NotificationService.getInstance().getNotifications();
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("notifications")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    <FlatList
                        ListEmptyComponent={<BotCard message={t("empty_message")} />}
                        data={this.props.notifications}
                        renderItem={({ item }) => (
                            <NotificationCard notification={item} navigation={this.props.navigation} />
                        )}
                        keyExtractor={(item) => item._id}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notifications: state.notificationCenter?.notifications,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const NotificationsScreen = connect(mapStateToProps, mapDispatchToProps)(_NotificationsScreen);

export default NotificationsScreen;
