import React, { Component } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import { colors, settings } from "@styles/globalStyles";
import Icon from "@components/icons";
import ImageBase from "@components/image/ImageBase";
import { Route } from "@react-navigation/native";
import POAPService from "@core/services/POAPService";
import POAP from "@custom-types/POAP";
import AvatarBase from "@components/avatar/AvatarBase";

interface Props {
    navigation: NavigationType;
    route: Route<string, { poapID?: string }>;
}

interface State {
    poap: POAP;
}

const { t } = i18n;

export class _POAPScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            poap: null,
        };
    }

    async componentDidMount() {
        const POAP = await POAPService.getInstance().getPOAP(this.props.route.params?.poapID || "");
        this.setState({ poap: POAP || null });
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={trimHelper(this.state.poap?.event?.name || " ", 16)}
                    type={HeaderType.Light}
                    {...this.props}
                />

                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    {this.state.poap !== null && (
                        <Container style={{ flex: 1 }}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    paddingVertical: 15,
                                    width: "100%",
                                }}
                            >
                                <Row style={styles.date}>
                                    <Icon
                                        style={{ paddingRight: 5 }}
                                        color={colors.text}
                                        name={"calendar-minus"}
                                        size={20}
                                    ></Icon>
                                    <BoldText>{this.state.poap.event.startDate}</BoldText>
                                </Row>
                                <View>
                                    <RegularText align="right" fontSize={12}>
                                        EVENT ID
                                    </RegularText>
                                    <RegularText fontSize={20}>#{this.state.poap.event.id}</RegularText>
                                </View>
                            </View>
                            <AvatarBase
                                uri={this.state.poap?.event?.imageUrl || ""}
                                alias={this.state.poap?.event?.name}
                                size={300}
                            ></AvatarBase>

                            <View style={{ paddingVertical: 25 }}>
                                <BoldText fontSize={18}>{this.state.poap.event.name}</BoldText>
                                <RegularText style={{ paddingTop: 20 }} align="justify">
                                    {this.state.poap.event.description}
                                </RegularText>
                            </View>
                        </Container>
                    )}
                </ScrollView>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return { fiatCurrency: state.wallet.fiatCurrency, selectedNFT: state.nfts.selectedNFT, client: state.auth.client };
};

const mapDispatchToProps = (dispatch) => ({});

const POAPScreen = connect(mapStateToProps, mapDispatchToProps)(_POAPScreen);

export default POAPScreen;

const styles = StyleSheet.create({
    date: {
        paddingHorizontal: 8,
        paddingVertical: 2,
        borderRadius: 5,
    },
});
