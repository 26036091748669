import { importer } from "@custom/helpers/importer";

const nunitoBold = require("@assets/fonts/Nunito-Bold.ttf")
const nunitoLight = require("@assets/fonts/Nunito-Light.ttf")
const nunitoRegular = require("@assets/fonts/Nunito-Regular.ttf")
const nunitoSemiBold = require("@assets/fonts/Nunito-SemiBold.ttf")

const DefaultFonts = {
    bold: nunitoBold,
    light: nunitoLight,
    regular: nunitoRegular,
    semiBold: nunitoSemiBold
}

const Fonts = importer(DefaultFonts, "Fonts")

export { Fonts };