import { put, takeEvery } from "redux-saga/effects";
import {
    onChange,
    setCurrencies,
    SWITCH_NETWORK,
    synced,
    UPDATE_CURRENCY_PATH,
    SYNC_PURCHASE_ORDERS,
} from "@store/actions/wallet";
import Wallet from "@core/wallet/Wallet";
import { loading, ready } from "@store/actions/global";
import store from "@store/index";
import Currency from "@core/currencies/Currency";
import { SimplexService } from "@core/services/SimplexService";
import { setRequestStatusNFTs } from "@store/actions/nfts.action";
import { MarketPlaceStatus } from "@store/reducers/nfts.reducer";

//IMPORTANT: This saga is deprecated, the functions that were left here are deprecated, so they should be replaced with new functions

function* syncPurchaseOrders(action) {
    const wallet = Wallet.getInstance();
    if (action && action.currency) {
        const currency: Currency = action.currency;
        const orders = yield SimplexService.getInstance().getPurchaseOrders(currency.getName());
        currency.setPurchaseOrders(orders);
        store.dispatch(onChange(wallet));
    }
    yield put(synced());
}

function* updateCurrencyPath(action) {
    const wallet = Wallet.getInstance();
    const parameters = action.parameters;
    parameters.currency.setAccount(parameters.account);
    parameters.currency.setChange(parameters.change);
    parameters.currency.setIndex(parameters.index);
    parameters.currency.setSmart(parameters.smart);

    yield put(loading());
    yield parameters.currency.syncBalance();
    yield wallet.saveState();
    yield put(onChange(wallet));
    yield put(ready());
    parameters.onSuccess ? parameters.onSuccess() : null;
}

function* switchNetwork(action) {
    yield put(loading());
    const wallet = Wallet.getInstance();
    const parameters = action.parameters;
    wallet.setIsTestnet(parameters.testnet);
    yield wallet.saveState();
    yield wallet.syncBalance();
    yield put(setCurrencies(wallet.getCurrencies()));
    yield wallet.syncData();
    yield put(onChange(wallet));
    store.dispatch(setRequestStatusNFTs({ status: MarketPlaceStatus.Reload }));
    yield put(ready());
    parameters.onSuccess ? parameters.onSuccess() : null;
}

export default function* main() {
    while (true) {
        yield takeEvery(SYNC_PURCHASE_ORDERS, syncPurchaseOrders);
        yield takeEvery(UPDATE_CURRENCY_PATH, updateCurrencyPath);
        yield takeEvery(SWITCH_NETWORK, switchNetwork);
    }
}
