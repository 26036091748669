import React, { Component } from "react"
import { StyleSheet } from "react-native"
import { Header } from "@components/header/Header"
import Container from "@base/Container"
import { HeaderType } from "@custom-types/HeaderType"
import { NavigationType } from "@custom-types/NavigationType"
import { connect } from "react-redux"
import Currency from "@core/currencies/Currency"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import i18n from "@i18n/i18n"
import TransactionHistoryComponent from "../components/TransactionHistoryComponent"


interface Props {
    navigation: NavigationType
    currencies: Array<Currency>
    selectedCurrency: string
    route: any
}

interface State { }

const { t } = i18n

export class _CoincaexTransactionsScreen extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={t("history")}
                    type={HeaderType.Light}
                    {...this.props}
                />
                <Container style={{ flex: 1, justifyContent: "space-between" }}>
                    <TransactionHistoryComponent
                        navigation={this.props.navigation}
                        currencies={this.props.currencies}
                        selectedCurrency={this.props.selectedCurrency}
                        transactions={this.props.route?.params?.transactions}
                    />
                </Container>
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
})

const mapStateToProps = (state) => {
    return state.wallet
}

const mapDispatchToProps = (dispatch) => ({})

const CoincaexTransactionsScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexTransactionsScreen)

export default CoincaexTransactionsScreen
