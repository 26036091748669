import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import SwapType from "@custom-types/SwapType";
import TransactionType from "@custom-types/TransactionType";
import i18n from "@i18n/i18n";
import { loading, ready } from "@store/actions/global";
import { onChange, onTransactionReady, prepareExchange, sync, synced } from "@store/actions/wallet";
import store from "@store/index";

const { t } = i18n;

export class WalletService {
    private static instance;

    private constructor() {}

    public static getInstance(): WalletService {
        if (!WalletService.instance) {
            WalletService.instance = new WalletService();
        }

        return WalletService.instance;
    }

    async prepareTransaction(params: { transaction: TransactionType; onSuccess?: () => void; onError?: (e) => void }) {
        try {
            store.dispatch(loading());
            const res = await params.transaction.currency.newTransaction(params.transaction);
            store.dispatch(ready());
            store.dispatch(onTransactionReady(res?.data));
            params?.onSuccess ? params?.onSuccess() : null;
        } catch (e: any) {
            store.dispatch(ready());
            let res = t("an_error_has_occurred");
            try {
                res = e?.response.data.message;
            } catch (e) {}
            params?.onError ? params?.onError(res) : null;
        }
    }

    async confirmTransaction(params: { currency: Currency; skeleton; onSuccess?: (r) => void; onError?: (e) => void }) {
        try {
            store.dispatch(loading());
            const res = await params?.currency.sendTransaction(params?.skeleton);
            const wallet = Wallet.getInstance();
            store.dispatch(ready());
            store.dispatch(onTransactionReady(res.data));
            wallet.syncBalance();
            params?.currency?.syncTransactions();
            store.dispatch(onChange(wallet));
            params.onSuccess ? params.onSuccess(res.data) : null;
        } catch (e: any) {
            store.dispatch(ready());
            params?.onError ? params?.onError(e?.response?.data?.message) : null;
        }
    }

    async syncBalance(currency?: Currency) {
        try {
            store.dispatch(sync());
            const wallet = Wallet.getInstance();
            if (currency) {
                currency.syncBalance();
            } else {
                await wallet.syncData();
                await wallet.syncBalance();
            }
            store.dispatch(synced());
        } catch (e) {}
    }

    async getTransactions(currency: Currency, txid?: string) {
        store.dispatch(loading());
        Wallet.getInstance().syncBalance();
        store.dispatch(ready());
    }

    async updateCurrencyEnabled(params: { currency: Currency; enabled: boolean }) {
        const wallet = Wallet.getInstance();
        params?.currency.setAsyncEnabled(params?.enabled);
        store.dispatch(loading());
        await wallet.saveState();
        if (params?.enabled) {
            params?.currency.syncBalance();
        }
        store.dispatch(onChange(wallet));
        store.dispatch(ready());
    }

    async prepareExchange(params: { swap: SwapType; onSuccess?: (r) => void; onError?: (e) => void }) {
        try {
            store.dispatch(loading());

            const swap: SwapType = params.swap;
            store.dispatch(prepareExchange({ swap: swap }));
            const res = await swap.from.newSwap(swap);

            store.dispatch(ready());
            store.dispatch(onTransactionReady({ 
                transactionData: res.transactionRequest, extra: { swapAmount: swap.to.fromDecimals(res.estimate.toAmount) } }));
            /*if (res.data.approved) {
                swap.from.setApproved(false);
            }*/
            params.onSuccess ? params.onSuccess(1) : null;
        } catch (e: any) {
            store.dispatch(ready());
            let res = t("an_error_has_occurred");
            try {
                res = e.response.data.message;
            } catch (e) {}
            params.onError ? params.onError(res) : null;
        }
    }
}
