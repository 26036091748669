
import NavigationInstantiator from "./helpers/NavigationInstantiator"
import NetworkScreen from "@screens/wallet/settings/network/NetworkScreen"
import WalletSettingsScreen from "@screens/wallet/settings/WalletSettingsScreen"

const WalletSettingsNavigatorScreens = {
    MainWalletSettings: {
        routeName: "MainWalletSettings",
        title: "WalletSettings",
        component: WalletSettingsScreen,
    },
    Network: {
        routeName: "Network",
        title: "Network",
        component: NetworkScreen,
    }
}

const WalletSettingsNavigator = NavigationInstantiator(WalletSettingsNavigatorScreens)

export { WalletSettingsNavigator, WalletSettingsNavigatorScreens }
