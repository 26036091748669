import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { ActivityIndicator, StyleSheet, View, ViewStyle } from "react-native";
import { colors } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import { AreaChart } from "react-native-svg-charts";
import Row from "@base/Row";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import Card from "@base/Card";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import Wallet from "@core/wallet/Wallet";
import * as shape from "d3-shape";
import { SectionTitle } from "../components/SectionTitle";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import RegularText from "@base/RegularText";
import { VibrationType, vibrate } from "@utils/vibration/vibration.helper";

interface Props {
    navigation: NavigationType;
    client: Client;
    currencies?: Array<Currency>;
    totalBalance: number;
    fiatCurrency?: FiatCurrency;
    containerStyle?: ViewStyle;
    syncing: boolean;
}

interface State {}

const { t } = i18n;

export class _WalletSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.redirectToWallet = this.redirectToWallet.bind(this);
        this.state = {};
    }

    redirectToWallet() {
        vibrate(VibrationType.ONPRESS);
        this.props.navigation.navigate(TabsNavigatorScreens.Wallets.routeName);
    }

    render() {
        if (!this.props.fiatCurrency) {
            return <></>;
        }
        return (
            <View style={this.props.containerStyle}>
                {ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule) && (
                    <View style={{}}>
                        <SectionTitle
                            onPress={this.redirectToWallet}
                            title={t("wallet").toUpperCase()}
                            icon={"💵"}
                            counter={this.props.syncing && t("sync")}
                            seeMore={false}
                            {...this.props}
                        ></SectionTitle>

                        {this.props.syncing && (
                            <View
                                style={{
                                    position: "absolute",
                                    right: 10,
                                    bottom: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                    height: 50,
                                    width: 50,
                                    overflow: "hidden",
                                    opacity: 1,
                                }}
                            >
                                <ActivityIndicator size={30} color={colors.text} />
                            </View>
                        )}

                        <Card
                            style={{
                                flex: 1,
                                paddingTop: 15,
                                minHeight: 60,
                                marginTop: 10,
                                marginRight: 0,
                                paddingRight: 0,
                                opacity: this.props.syncing ? 0.5 : 1,
                            }}
                            onPress={this.redirectToWallet}
                            background={
                                <AreaChart
                                    style={{ height: 85, marginTop: -5, marginHorizontal: -5 }}
                                    data={Wallet.getInstance()
                                        .findCurrencyById("ethereum.mainnet.native.eth")
                                        ?.getPriceHistoryDaily()
                                        .map((p) => p.price)}
                                    contentInset={{ top: 10, bottom: 35 }}
                                    curve={shape.curveNatural}
                                    svg={{
                                        stroke: colors.grey,
                                        strokeOpacity: 0,
                                        strokeWidth: 1,
                                        fill: colors.tertiaryShadow,
                                        fillOpacity: 1,
                                    }}
                                ></AreaChart>
                            }
                        >
                            <Row style={styles.row}>
                                <View style={{ flex: 1, overflow: "hidden" }}>
                                    <View style={{ position: "relative" }}>
                                        <Row>
                                            <Row style={{ alignItems: "center" }}>
                                                <CurrencyIcon
                                                    currency={this.props.fiatCurrency}
                                                    size={28}
                                                    styles={{ marginRight: 6 }}
                                                ></CurrencyIcon>
                                                <RegularText style={{ paddingTop: 2, paddingRight: 1 }}>
                                                    {this.props.fiatCurrency.getSign()}
                                                </RegularText>
                                                <BoldText align="center" fontSize={28}>
                                                    {
                                                        this.props.fiatCurrency
                                                            .fiatFormat(this.props.totalBalance)
                                                            .split(".")[0]
                                                    }
                                                </BoldText>
                                            </Row>

                                            <BoldText align="left" fontSize={14} style={{ paddingTop: 2 }}>
                                                {
                                                    this.props.fiatCurrency
                                                        .fiatFormat(this.props.totalBalance)
                                                        .split(".")[1]
                                                }
                                            </BoldText>

                                            <Row style={{ paddingLeft: 10, overflow: "hidden" }}>
                                                {Wallet.getInstance()
                                                    .getBalancedCurrencies()
                                                    .map((c) => {
                                                        return (
                                                            <CurrencyIcon
                                                                key={c.getId()}
                                                                size={16}
                                                                iconSize={8}
                                                                currency={c}
                                                                styles={{ marginRight: 4 }}
                                                            />
                                                        );
                                                    })}
                                            </Row>
                                        </Row>
                                    </View>
                                </View>
                            </Row>
                        </Card>
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },
    row: {
        width: "100%",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.gradientFrom,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        totalBalance: state.wallet.totalBalance,
        fiatCurrency: state.wallet.fiatCurrency,
        currencies: state.wallet.currencies,
        syncing: state.wallet.syncing,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const WalletSection = connect(mapStateToProps, mapDispatchToProps)(_WalletSection);

export default WalletSection;
