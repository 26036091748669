import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { ExperiencesRedux } from "@custom-types/ExpercienceModel";
import { StyleSheet, View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import FlatListBase from "@base/FlatListBase";
import ExperienceService from "@core/services/ExperienceService";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import { ExperienceModuleType, ModuleControlService, Modules } from "@core/services/ModuleControlService";
import ExperienceCard from "./ExperienceCard";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    client: Client;
    experiences: ExperiencesRedux;
}

interface State { }

const { t } = i18n;

export class _ExperiencesSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    renderItem = ({ item }) => {
        return (
            <ExperienceCard experience={item} navigation={this.props.navigation} />
        );
    };

    navigateToSection = () => {
        this.props.navigation.navigate(TabsNavigatorScreens.Experiences.routeName);
    }

    loadMore = async () => {
        await ExperienceService.getInstance().getExperiences(true);
    }

    render() {
        return (
            <View>
                {this.props.experiences?.experiences.paginator.totalDocs > 0 &&
                    ModuleControlService.getInstance().isModuleEnabled(Modules.experiencesModule) &&
                    !ModuleControlService.getInstance().getActiveExperienceModuleType(
                        ExperienceModuleType.exclusive,
                    ) && (
                        <View style={[{ paddingVertical: 10 }, this.props.containerStyle]}>
                            <SectionTitle
                                onPress={this.navigateToSection}
                                title={t("experiences").toUpperCase()}
                                icon={"🔥"}
                                // counter={t("discover").toUpperCase()}
                                seeMore={true}
                                {...this.props}
                            ></SectionTitle>

                            <FlatListBase
                                data={this.props.experiences?.experiences?.docs}
                                renderItem={this.renderItem}
                                keyExtractor={(item) => item._id}
                                contentContainerStyle={{ paddingTop: 10, zIndex: 99 }}
                                initialNumToRender={2}
                                horizontal={true}
                                maxToRenderPerBatch={5}
                                showsHorizontalScrollIndicator={false}
                                onEndReachedThreshold={1}
                                onEndReached={this.loadMore}
                            ></FlatListBase>
                        </View>
                    )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        experiences: state.experiences,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ExperiencesSection = connect(mapStateToProps, mapDispatchToProps)(_ExperiencesSection);

export default ExperiencesSection;


