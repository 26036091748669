import { StyleSheet, Appearance } from "react-native";

const lightColors = {
    primary: "#d8d9db",
    secondary: "rgba(255,255,255,0.25)",
    complementary: "#e8d100",
    tertiary: "#003a40",
    background: "#003a40",
    gradientFrom: "#28bdc8",
    gradientTo: "#003a40",
    grey: "#aaabad",
    white: "white",
    black: "#333",
    blue: "#2696fb",
    green: "#1DBC60",
    yellow: "#FECA54",
    red: "#EE4540",
    text: "white",
    shadow: "rgba(255, 255, 255, 0.05)",
    secondaryShadow: "rgba(255, 255, 255, 0.1)",
    tertiaryShadow: "rgba(255, 255, 255, 0.2)",
    highlightedText: "white",
    networkSymbol: "#fff",
    labelText: "white",
    latamxo1: "#fff",
    latamxo2: "#fff",
    mainButtonsBackground: '#fff',
    mainButtonsColor: '#137f88',
    balanceColor: '#e8d100'
};

const darkColors = lightColors;

const settings = {
    cardRadius: 5,
    shadow: {
        shadowOpacity: 0.1,
        shadowRadius: 10,
    },
    gradientLocations: [0, 0.6],
    mainButtonsRadius: 5
};

export { lightColors, darkColors, settings };
