interface JSON_Node {
    xpub: string,
    xpriv: string
}

export class PathNode {

    public path: string;
    public node: JSON_Node;

    constructor(path: string = '', node:JSON_Node ) {
        this.path = path;
        this.node = node;
    }
}
