import i18n from "@i18n/i18n";
import * as ImageManipulator from "expo-image-manipulator";
import { Dimensions, Platform } from "react-native";
import * as FileSystem from "expo-file-system";
import * as VideoThumbnails from "expo-video-thumbnails";
import { MediaTypes } from "@screens/nfts/newNFTScreen";
import { Video } from "expo-av";

const { t } = i18n;

export const resizeImageHelper = async (image: { cancelled?: boolean; height: number; width: number; uri: string }) => {
    let raw_image = image || null;
    if (raw_image && (raw_image.height > 4000 || raw_image.height > 4000)) {
        let image_result = await ImageManipulator.manipulateAsync(
            raw_image.uri,
            [{ resize: { width: Math.round(raw_image.width / 1.5), height: Math.round(raw_image.height / 1.5) } }],
            {
                format: ImageManipulator.SaveFormat.JPEG,
            },
        );
        return await compressImage(image_result);
    } else {
        return await compressImage(raw_image);
    }
};

export const sourceIsTooBig = async (uri: string, mediaType: MediaTypes, maxSize?: number) => {
    const defaultSizes = {
        image: 3,
        video: 50,
    };

    let fileSize = Math.round((mediaType == MediaTypes.VIDEO ? defaultSizes.video : defaultSizes.image) * 1024000);

    if (Platform.OS == "web") {
        const base64 = await fetch(uri);
        const blob = await base64.blob();

        if (blob?.size > fileSize) {
            return true;
        }
    } else {
        const fileInfo = await FileSystem.getInfoAsync(uri);
        if (fileInfo.size >= fileSize) {
            return true;
        }
    }
};

export const compressImage = async (image: { cancelled?: boolean; height: number; width: number; uri: string }) => {
    let raw_image = image;
    let compress_threshold = 0.9;

    for (let i = 0; i < 4; i++) {
        if (await sourceIsTooBig(raw_image.uri, MediaTypes.IMAGE)) {
            raw_image = await ImageManipulator.manipulateAsync(image.uri, [{ resize: { width: raw_image.width } }], {
                compress: compress_threshold,
                format: ImageManipulator.SaveFormat.JPEG,
            });
            compress_threshold = compress_threshold - 0.1;
        }

        break;
    }
    return raw_image;
};

export const generateThumbnails = async (video: { uri: string; duration: number }) => {
    try {
        const { uri } = await VideoThumbnails.getThumbnailAsync(video?.uri, {
            time: Math.round(video.duration / 2) || 500,
        });
        if (uri) {
            let resize_image = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 720 } }], {
                compress: 0.9,
                format: ImageManipulator.SaveFormat.JPEG,
            });
            return resize_image.uri;
        }
    } catch (e) {
        console.warn(e);
    }
};

export const calcFileSize = async (uri: string) => {
    try {
        var size = 0;

        if (Platform.OS == "web") {
            const base64 = await fetch(uri);
            const blob = await base64.blob();
            size = blob.size;
        } else {
            const fileInfo = await FileSystem.getInfoAsync(uri);
            size = fileInfo.size;
        }

        return size;
    } catch (e) {
        console.warn(e);
    }
};

export const videoSourceIsTooBig = async (video_raw: {
    uri: string;
    width?: number;
    height?: number;
    rotation?: number;
    duration?: number;
}) => {
    var videoData: {
        width: number;
        height: number;
        duration: number;
        size: number;
        format: string;
    };

    if (Platform.OS == "web") {
        const base64 = await fetch(video_raw.uri);
        const blob = await base64.blob();

        const video_inspect: any = await new Promise((resolve) => {
            let video = document.createElement("video");
            video.onloadedmetadata = async () => {
                resolve(
                    (videoData = {
                        width: video.videoWidth || 0,
                        height: video.videoHeight || 0,
                        duration: video.duration || 0,
                        size: blob.size || 0,
                        format: video_raw.uri.substring(11, 14),
                    }),
                );
            };

            video.src = URL.createObjectURL(blob);
        });

        return evaluateVideoSource(video_inspect);
    } else {
        const fileInfo = await FileSystem.getInfoAsync(video_raw.uri);
        videoData = {
            width: video_raw.rotation == 90 ? video_raw.height : video_raw.width,
            height: video_raw.rotation == 90 ? video_raw.width : video_raw.height,
            duration: video_raw.duration / 1000,
            size: fileInfo.size,
            format: video_raw.uri.substring(video_raw.uri?.length - 3, video_raw.uri?.length),
        };

        return evaluateVideoSource(videoData);
    }
};

const evaluateVideoSource = (videoData: {
    width: number;
    height: number;
    duration: number;
    size: number;
    format: string;
}) => {
    const videoSizes = {
        max_width: 720,
        min_width: 360,
        min_aspect_ratio: 0.56,
        max_aspect_ratio: 1.78,
        duration: 90,
        max_size: 50,
        max_size_per_second: 0.85,
        format: "mp4",
    };

    let response: {
        error: boolean;
        error_message: string;
    };

    switch (true) {
        case videoData.size > videoSizes.max_size * 1024000:
            response = {
                error: true,
                error_message: t("video_size_error"),
            };
            break;

        default:
            response = {
                error: false,
                error_message: "",
            };
    }

    return response;
};
