import BoldText from "@base/BoldText";
import Card from "@base/Card";
import Container from "@base/Container";
import InlineButton from "@base/InlineButton";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import AvatarBase from "@components/avatar/AvatarBase";
import { Header } from "@components/header/Header";
import Icon from "@components/icons";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Currency from "@core/currencies/Currency";
import { Skeleton } from "@core/currencies/CurrencyImplementation";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import ActivityService from "@core/services/ActivityService";
import { WalletService } from "@core/services/WalletService";
import Wallet from "@core/wallet/Wallet";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { Route } from "@react-navigation/native";
import { showPopup, showPopupMessage } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import { getColorOpacity } from "@utils/helpers/global/global";
import React, { Component } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    fiatCurrency: FiatCurrency;
    currentOperation: any;
    route: Route<string, { rawSwap }>;
}

interface State {
    skeleton: Skeleton;
    diff: number;
}

const { t } = i18n;

export class _ConfirmExchangeScreen extends Component<Props, State> {
    private from: Currency;
    private to: Currency;
    private fiatCurrency: FiatCurrency;

    constructor(props: Props) {
        super(props);

        this.from = Wallet.getInstance().findCurrencyById(
            props.route?.params?.rawSwap?.extraTransactionData?.fromCurrency
        );

        const skeleton = this.from?.getImplementation()?.parseSkeleton(props.route?.params?.rawSwap);
        this.to = Wallet.getInstance().findCurrencyById(skeleton?.exchangeData?.toCurrency);
        this.fiatCurrency = this.props.fiatCurrency;
        this.onConfirm = this.onConfirm.bind(this);

        this.state = {
            skeleton: skeleton,
            diff: this.calculateDiff(
                skeleton?.exchangeData?.fromAmount,
                this.from,
                skeleton?.exchangeData?.toAmount,
                this.to
            ),
        };

        this.onConfirm = this.onConfirm.bind(this);
    }

    calculateDiff(amount: number, from: Currency, swapAmount: number, to: Currency) {
        const o = to.getFiat() * swapAmount;
        const i = from.getFiat() * amount;
        const diff = (o - i) / i;
        // if (diff < -0.05) {
        //     store.dispatch(
        //         showPopupMessage({
        //             type: "WARNING",
        //             title: t("warning"),
        //             message: t("swap_warning", { percentage: (diff * -100).toFixed(2) }),
        //         })
        //     );
        // }
        return diff;
    }

    onConfirm() {
        WalletService.getInstance().confirmTransaction({
            currency: this.from,
            skeleton: this.props.route?.params?.rawSwap,
            onSuccess: () => {
                WalletService.getInstance().getTransactions(this.from);
                this.from.getActivity();
                store.dispatch(showPopup({ type: "SUCCESS", message: t("transaction_success") }));
                this.props.navigation.navigate(WalletNavigatorScreens.Wallet.routeName);
            },
            onError: (error) => {
                console.warn(error);
                store.dispatch(showPopup({ type: "ERROR", message: t("an_error_has_occurred") }));
            },
        });
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("confirm_swap")} type={HeaderType.Light} {...this.props} />

                <Container style={styles.container}>
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("amount")}
                            </RegularText>
                            <CurrencyBalanceIcon
                                style={{ paddingBottom: 5 }}
                                iconSize={22}
                                fontSize={18}
                                currency={this.from}
                                amount={this.state.skeleton?.exchangeData?.fromAmount}
                            />
                            <CurrencyBalanceIcon
                                style={styles.balanceIcon}
                                iconSize={15}
                                fontSize={12}
                                fiatCurrency={Wallet.getInstance().getFiatCurrency()}
                                amount={this.from.toFiat(this.state.skeleton?.exchangeData?.fromAmount)}
                            />
                        </Card>
                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("sending_to")}
                            </RegularText>
                            <SemiBoldText numberOfLines={1} color={colors.text} fontSize={14} align="center">
                                {this.state.skeleton?.sendingTo}
                            </SemiBoldText>
                        </Card>
                        {this.state.skeleton?.exchangeData?.exchangeProvider?.name?.length > 0 && (
                            <Card>
                                <RegularText color={colors.text}>{t("provider")}</RegularText>
                                <Row style={{ alignContent: "center", alignItems: "center" }}>
                                    <AvatarBase
                                        uri={this.state.skeleton?.exchangeData?.exchangeProvider?.image}
                                        overlayContainerStyle={{ marginRight: 5 }}
                                        size={18}
                                        alias={this.state.skeleton?.exchangeData?.exchangeProvider?.name}
                                    ></AvatarBase>
                                    <SemiBoldText numberOfLines={1} color={colors.text} style={{}}>
                                        {this.state.skeleton?.exchangeData?.exchangeProvider?.name}
                                    </SemiBoldText>
                                </Row>
                            </Card>
                        )}

                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("you_will_receive")}
                            </RegularText>
                            <Row style={{ alignContent: "center", alignItems: "center", justifyContent: "center" }}>
                                <CurrencyBalanceIcon
                                    style={{ paddingBottom: 5 }}
                                    iconSize={22}
                                    fontSize={18}
                                    currency={this.to}
                                    trimLength={15}
                                    amount={this.state?.skeleton?.exchangeData?.toAmount}
                                />
                                <NetworkCurrencySymbol
                                    styles={{
                                        minWidth: 40,
                                        paddingHorizontal: 8,
                                    }}
                                    currency={this.to}
                                ></NetworkCurrencySymbol>
                            </Row>
                            <CurrencyBalanceIcon
                                style={styles.balanceIcon}
                                iconSize={15}
                                fontSize={12}
                                fiatCurrency={Wallet.getInstance().getFiatCurrency()}
                                amount={this.to.toFiat(this.state?.skeleton?.exchangeData?.toAmount)}
                            />
                        </Card>

                        {this.state.diff && (
                            <Card style={styles.card}>
                                <RegularText>{t("return_percentage")}</RegularText>
                                <Row
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignContent: "center",
                                        paddingTop: 5,
                                    }}
                                >
                                    <View style={{ paddingRight: 25 }}>
                                        <CurrencyIcon size={22} currency={this.from}></CurrencyIcon>
                                        <View
                                            style={{
                                                position: "absolute",
                                                left: 18,
                                                borderColor: colors.gradientFrom,
                                                borderWidth: 2,
                                                height: 22,
                                                width: 22,
                                                borderRadius: 11,
                                            }}
                                        >
                                            <CurrencyIcon size={18} currency={this.to}></CurrencyIcon>
                                        </View>
                                    </View>
                                    <View
                                        style={{
                                            paddingHorizontal: 8,
                                            // marginHorizontal: 10,
                                            paddingVertical: 2,
                                            opacity: 0.95,
                                            backgroundColor:
                                                this.state.diff < 0
                                                    ? getColorOpacity(colors.red, 0.7)
                                                    : getColorOpacity(colors.green, 0.6),
                                            borderRadius: 12,
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <SemiBoldText fontSize={12} align={"center"}>
                                            {this.state.diff < 0 ? "" : "+"} {(this.state.diff * 100).toFixed(2)}
                                            {"%"}
                                        </SemiBoldText>
                                    </View>
                                </Row>
                            </Card>
                        )}

                        {this.state.skeleton?.exchangeData?.exchangeFeeData?.map((item) => {
                            const currency = Wallet.getInstance().findCurrencyById(item?.digitalCurrencyId);
                            if (!currency) return;
                            return (
                                <Card>
                                    <RegularText color={colors.text} align="center">
                                        {t("service_fee")}
                                    </RegularText>
                                    <CurrencyBalanceIcon
                                        style={{ paddingBottom: 5 }}
                                        iconSize={22}
                                        fontSize={18}
                                        currency={currency}
                                        amount={item?.amount}
                                    />

                                    <CurrencyBalanceIcon
                                        style={styles.balanceIcon}
                                        iconSize={15}
                                        fontSize={12}
                                        fiatCurrency={Wallet.getInstance().getFiatCurrency()}
                                        amount={currency.toFiat(item?.amount)}
                                    />
                                </Card>
                            );
                        })}

                        {this.state?.skeleton?.feeData && (
                            <Card>
                                <RegularText color={colors.text} align="center">
                                    {t("fee")}
                                </RegularText>
                                <CurrencyBalanceIcon
                                    style={{ paddingBottom: 5 }}
                                    iconSize={22}
                                    fontSize={18}
                                    currency={Wallet.getInstance().findCurrencyById(
                                        this.state?.skeleton?.feeData?.digitalCurrencyId
                                    )}
                                    amount={this.state?.skeleton?.feeData?.amount}
                                />

                                <CurrencyBalanceIcon
                                    style={styles.balanceIcon}
                                    iconSize={15}
                                    fontSize={12}
                                    fiatCurrency={Wallet.getInstance().getFiatCurrency()}
                                    amount={Wallet.getInstance()
                                        .findCurrencyById(this.state?.skeleton?.feeData?.digitalCurrencyId)
                                        .toFiat(this.state?.skeleton?.feeData?.amount)}
                                />
                            </Card>
                        )}
                    </ScrollView>

                    <View style={styles.swipContainer}>
                        <InlineButton onPress={this.onConfirm} title={t("confirm")} vibrate={true} />
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,

        justifyContent: "space-between",
        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    card: {},
    swipContainer: {
        paddingTop: 25,
        // height: 58,
    },
    balanceIcon: {
        backgroundColor: colors.tertiaryShadow,
        paddingHorizontal: 6,
        paddingVertical: 4,
        borderRadius: 16,
        overflow: "hidden",
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const ConfirmExchangeScreen = connect(mapStateToProps, mapDispatchToProps)(_ConfirmExchangeScreen);

export default ConfirmExchangeScreen;
