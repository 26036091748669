const lightColors = {
    primary: "#ffffff",
    secondary: "#0B83FE",
    complementary: "#47a2ff",
    tertiary: "#131313",
    background: "#000000",
    gradientFrom: "#000000",
    gradientTo: "#000000",
    text: "#ffffff",
    white: "#ffffff",
    grey: "#777777",
    shadow: "rgba(255,255,255,0.1)",
    secondaryShadow: "rgba(61, 86, 110, 0.5)",
    tertiaryShadow: "rgba(255, 255, 255, 0.1)",
    labelText: "#ffffff",
    mainButtonsColor: "#ffffff",
    modalBackground: "#0B83FE",
    popupBackground: "#393939",
    activeFooter: "#0B83FE",
    avatarBase: "#8d8d8e",
    floatButtonText: "#ffffff",
    floatButtonBackground: "#0B83FE",
    incognitoButton: "#363638"
};

const darkColors = lightColors;

const settings = {
    // gradientLocations: [0, 0.7],
};

export { lightColors, darkColors, settings };
