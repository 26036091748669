import WebViewBase from "@components/webview/WebViewBase";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import { Client } from "@custom-types/Client";
import i18n from "@i18n/i18n";
import WalletConnectModal from "@screens/walletconnect/WalletConnectModal";
import {
    hideModal,
    hideModalBottom,
    hidePopupMessage,
    loading,
    ready, showModalBottom, showPopupMessage,
    showSnackbar
} from "@store/actions/global";
import store from "@store/index";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import React from "react";

const { t } = i18n;

export enum METHODS {
    "available" = "available",
    "connect" = "connect",
    "personalSign" = "personalSign",
    "transactionSign" = "transactionSign",
}

export enum TYPES {
    "request" = "request",
    "response" = "response",
}

export default class XOConnectService {
    private webView: WebViewBase;
    private client: Client;
    private processedRequests = new Set<string>();
    private receivedRequests = new Set<string>();

    constructor(ref: WebViewBase, client: Client) {
        this.webView = ref;
        this.client = client;
    }

    static xoConnectListener = (WebViewBaseRef: WebViewBase) => {
        return `(function() {
            window.XOConnect = true;
            window.addEventListener(
                "message",
                (event) => {
                    if (event.data?.length) {
                        window.ReactNativeWebView.postMessage(event.data);
                    }
                },
                false
            );   
    })();`;
    };

    send(id: string, data: any) {
        const response = JSON.stringify({
            id,
            type: "receive",
            data,
        });
        this.processedRequests.add(id);
        this.webView.sendPostMessage(response);
    }

    cancel(id: string) {
        const response = JSON.stringify({
            id,
            type: "cancel",
        });
        this.processedRequests.add(id);
        this.webView.sendPostMessage(response);
    }

    async onConnect(id) {
        let currencies = [];
        const client = this.client;

        let cs = Wallet.getInstance().getEnabledCurrencies();

        cs.map((c: Currency) => {
            currencies.push({
                id: c.getId(),
                symbol: c.getSymbol(),
                icon: c.getIcon(),
                address: c.getAddress(),
            });
        });

        const currency = Wallet.getInstance().findCurrencyById("ETH");

        const signature = await currency.signMessage(`xoConnect-${id}`);

        this.send(id, {
            client: {
                _id: client._id,
                alias: client?.alias,
                profileImagePath: client?.profileImagePath,
                currencies: currencies,
                signature,
            },
        });
    }

    onCancel(id) {
        store.dispatch(hideModal());
        if (!this.processedRequests.has(id)) {
            this.cancel(id);
        }
    }

    onPersonalSign(id: string, data: any) {
        const currency = Wallet.getInstance().findCurrencyById("ETH");
        const title = "";
        const message = data;

        store.dispatch(
            showModalBottom({
                modalContent: (
                    <WalletConnectModal
                        avatar={currency.getIcon()}
                        title={trimHelper(title, 50)}
                        description={t("personal_sign_subtitle")}
                        subtitle={t("personal_sign_description")}
                        message={`${t("message")}: ${message}`}
                        btnTitle={t("wallet_connect_sign")}
                        onPress={async () => {
                            store.dispatch(hideModalBottom());
                            setTimeout(async () => {
                                const signedMessage = await currency.signMessage(message);
                                const data = { txs: signedMessage };
                                this.send(id, data);
                            }, 1000);
                        }}
                        currency={currency}
                        client={store.getState().auth.client}
                        clientMessage={t("signed_by")}
                    />
                ),
                onPressClose: () => {
                    store.dispatch(hideModalBottom());

                    setTimeout(() => {
                        this.cancel(id);
                    }, 750);
                },
            }),
        );
    }

    async onTransactionSign(id: string, data: any, currencyId: string) {
        store.dispatch(loading());
        const currency = Wallet.getInstance().findCurrencyById(currencyId);

        try {
            const transaction = await currency.newTransaction({
                currency: currency,
                amount: currency.fromDecimals(data.value || 0),
                addressFrom: data.from,
                addressTo: data.to,
                data: data?.data,
            });

            if (!transaction) {
                return;
            }

            const skeleton = await currency.getImplementation().parseSkeleton(transaction.data);
            store.dispatch(
                showModalBottom({
                    modalContent: (
                        <WalletConnectModal
                            avatar={currency?.getIcon()}
                            title={currency.getName()?.toUpperCase()}
                            message={t("eth_sendTransaction", {
                                from: trimHelper(skeleton.sendingFrom, 40, true) || "",
                                to: trimHelper(skeleton.sendingTo, 40, true) || "",
                                gas: skeleton?.feeData?.amount || "",
                            })}
                            btnTitle={t("confirm")}
                            onPress={() => {
                                store.dispatch(hideModalBottom());
                                setTimeout(() => {
                                    this.ethSendTransaction(id, currency, transaction);
                                }, 1000);
                            }}
                            currency={currency}
                            amount={skeleton.amount}
                            client={store.getState().auth.client}
                            clientMessage={t("signed_by")}
                        />
                    ),
                    onPressClose: () => {
                        store.dispatch(hideModalBottom());

                        setTimeout(() => {
                            this.cancel(id);
                        }, 750);
                    },
                }),
            );
        } catch (error: any) {
            store.dispatch(
                showPopupMessage({
                    type: "ERROR",
                    message: error?.response?.data?.message || t("an_error_has_occurred"),
                    onPressAccept: () => {
                        store.dispatch(hidePopupMessage());
                        setTimeout(() => {
                            this.onCancel(id);
                        }, 500);
                    },
                }),
            );
        }
        store.dispatch(ready());
    }

    async ethSendTransaction(id: string, currency: Currency, transaction: any) {
        store.dispatch(loading());
        try {
            const res = await currency.sendTransaction(transaction.data);
            const data = { id, result: res?.data?.hash, jsonrpc: "2.0" };
            this.send(id, data);
            // store.dispatch(showPopupMessage({ type: "SUCCESS", message: t("transaction_success") }));
            // store.dispatch(showPopup({ type: "SUCCESS" }));
        } catch (e: any) {
            this.onCancel(id);
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response.data.message || t("an_error_has_occurred"),
                }),
            );
        }
        store.dispatch(ready());
    }

    handleRequests(payload) {
        if (payload?.nativeEvent?.data) {
            try {
                const resp = JSON.parse(payload?.nativeEvent?.data);
                if (this.receivedRequests.has(resp?.id)) {
                    return;
                }
                this.receivedRequests.add(resp?.id);
                if (resp?.type == TYPES.response) return;
                if (resp?.type == "cancel") {
                    this.onCancel(resp.id);
                }
                switch (resp?.method) {
                    case METHODS.connect:
                        this.onConnect(resp.id);
                        break;
                    case METHODS.personalSign:
                        this.onPersonalSign(resp.id, resp.data);
                        break;
                    case METHODS.transactionSign:
                        this.onTransactionSign(resp.id, resp.data, resp.currency);
                        break;
                }
            } catch (e) {
                console.warn(e);
            }
        }
    }
}
