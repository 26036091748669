import ConfirmTransferRedeemableScreen from "@screens/experiences/ConfirmTransferRedeemableScreen";
import MembershipScreen from "@screens/experiences/MembershipScreen";
import RedeemableScreen from "@screens/experiences/RedeemableScreen";
import SectionRedeemableScreen from "@screens/experiences/SectionRedeemableScreen";
import TransferRedeemableScreen from "@screens/experiences/TransferRedeemableScreen";
import NavigationInstantiator from "./helpers/NavigationInstantiator";
import ExperienceScreen from "@screens/experiences/ExperienceScreen";
import CheckoutScreen from "@screens/experiences/CheckoutScreen";
import SelectRedeemableScreen from "@screens/experiences/SelectRedeemableScreen";
import ConfirmUseRedeemableScreen from "@screens/experiences/ConfirmUseRedeemableScreen";
import SectionMembershipScreen from "@screens/experiences/SectionMembershipScreen";
import SectionAssetScreen from "@screens/experiences/SectionAssetScreen";
import AssetScreen from "@screens/experiences/AssetScreen";
import TransferAssetScreen from "@screens/experiences/TransferAssetScreen";
import ConfirmTransferAssetScreen from "@screens/experiences/ConfirmTransferAssetScreen";

const ExperienceNavigatorScreens = {
    SectionRedeemable: {
        routeName: "SectionRedeemable",
        title: "SectionRedeemable",
        component: SectionRedeemableScreen,
    },
    Redeemable: {
        routeName: "Redeemable",
        title: "Redeemable",
        component: RedeemableScreen,
    },
    Asset: {
        routeName: "Asset",
        title: "Asset",
        component: AssetScreen,
    },
    Membership: {
        routeName: "Membership",
        title: "Membership",
        component: MembershipScreen,
    },
    SectionMembership: {
        routeName: "SectionMembership",
        title: "SectionMembership",
        component: SectionMembershipScreen,
    },
    SectionAsset: {
        routeName: "SectionAsset",
        title: "SectionAsset",
        component: SectionAssetScreen,
    },
    ExperienceDetail: {
        routeName: "ExperienceDetail",
        title: "ExperienceDetail",
        component: ExperienceScreen,
    },
    Checkout: {
        routeName: "Checkout",
        title: "Checkout",
        component: CheckoutScreen,
    },
    TransferRedeemable: {
        routeName: "TransferRedeemable",
        title: "TransferRedeemable",
        component: TransferRedeemableScreen,
    },
    TransferAsset: {
        routeName: "TransferAsset",
        title: "TransferAsset",
        component: TransferAssetScreen,
    },
    ConfirmTransferRedeemable: {
        routeName: "ConfirmTransferRedeemable",
        title: "ConfirmTransferRedeemable",
        component: ConfirmTransferRedeemableScreen,
    },
    ConfirmTransferAsset: {
        routeName: "ConfirmTransferAsset",
        title: "ConfirmTransferAsset",
        component: ConfirmTransferAssetScreen,
    },
    SelectToUse: {
        routeName: "SelectToUse",
        title: "SelectToUse",
        component: SelectRedeemableScreen,
    },
    ConfirmUseRedeemable: {
        routeName: "ConfirmUseRedeemable",
        title: "ConfirmUseRedeemable",
        component: ConfirmUseRedeemableScreen,
    },
};

const ExperienceNavigator = NavigationInstantiator(ExperienceNavigatorScreens);

export { ExperienceNavigator, ExperienceNavigatorScreens };
