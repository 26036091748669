import React, {Component} from "react"
import {View, StyleSheet, FlatList} from "react-native"
import {Header} from "@components/header/Header"
import Container from "@base/Container"
import {HeaderType} from "@custom-types/HeaderType"
import {NavigationType} from "@custom-types/NavigationType"
import store from "@store/index"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import i18n from "@i18n/i18n"
import OptionCard from "@components/cards/OptionCard"
import {KriptonService} from "@core/services/KriptonService"
import {loading, ready} from "@store/actions/global"
import { KriptonMarketNavigatorScreens } from "@navigation/KriptonMarketNavigator"

interface Props {
    navigation: NavigationType
}

interface State {
    countries: Array<any>
    selectedCountry: string
}

const {t} = i18n

export default class SelectNationalityScreen extends Component<Props, State> {
    private from = true
    private kriptonService: KriptonService

    constructor(props: Props) {
        super(props)
        //this.onPress = this.onPress.bind(this);
        this.state = {
            countries: [],
            selectedCountry: "",
        }
        this.kriptonService = KriptonService.getInstance()
        this.initCountries()
    }

    async initCountries() {
        store.dispatch(loading())
        const countries = await this.kriptonService.getCountries()

        this.setState({countries: countries.data})
        store.dispatch(ready())
    }

    onPress = (country: string) => {
        this.setState({selectedCountry: country})
        this.props.navigation.navigate(KriptonMarketNavigatorScreens.PersonalInformationKripton.routeName, {country: country})
    }

    renderItem = ({item}) => (
        <OptionCard
            title={item.name}
            onPress={() => this.onPress(item.alpha3)}
            selected={this.state.selectedCountry == item.alpha3}
        />
    )

    render() {
        return (
            <ScreenWrapper>
                <Header {...this.props} title={t("select_country")} type={HeaderType.Light} />
                <Container style={styles.container}>
                    {this.state.countries && (
                        <FlatList
                            data={this.state.countries}
                            renderItem={this.renderItem}
                            keyExtractor={(item) => item.alpha3}
                            contentContainerStyle={{paddingBottom: 20}}
                            initialNumToRender={30}
                            maxToRenderPerBatch={30}
                        />
                    )}
                </Container>
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: -15,
        overflow: "hidden",
        padding: 40,
    },
})
