const lightColors = {
    primary: "#000000",
    secondary: "#ffffff",
    complementary: "#ffffff",
    tertiary: "#181819",
    background: "#101113",
    gradientFrom: "#101113",
    gradientTo: "#1c1d1f",
    text: "#D7D7D3",
    grey: "#b0b0ae",
    white: "#555555",
    shadow: "rgba(0,0,0,0.3)",
    secondaryShadow: "rgba(150, 150, 150, 0.05)",
    tertiaryShadow: "rgba(255, 255, 255, 0.12)",
    highlightedText: "#ffffff",
    mainButtonsColor: "#000000",
    modalBackground: "#ffffff",
    popupBackground: "#101113",
    activeFooter: "#ffffff",
    modalBackgroundFrom: "#101113",
    modalBackgroundTo: "#101113",
    floatButtonText: "#000000",
    inlineButtonText: "#000000",
    avatarBase: "#b0b0ae",
    labelText: "#000000",
 
};

const darkColors = lightColors;

const settings = {
    // gradientLocations: [0, 0.7],
};

export { lightColors, darkColors, settings };
