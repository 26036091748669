import React, { Component } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { BuyNavigatorScreens } from "@navigation/BuyNavigator";
import store from "@store/index";
import { showSnackbar } from "@store/actions/global";
import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator";
import FloatingTextInput from "@base/FloatingTextInput";
import InlineButton from "@base/InlineButton";
import { CoincaexService } from "@core/services/CoincaexService";
import { CoincaexSignup } from "../helper/coincaex.helper";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    selectedCurrency: string;
}

interface State {
    registerForm: CoincaexSignup;
}

const { t } = i18n;

export class _CoincaexSignUpScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            registerForm: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                password: "",
                confirmPassword: "",
            },
        };
    }

    handleInputChange = (value: string, field: string) => {
        this.setState({
            ...this.state,
            registerForm: { ...this.state.registerForm, [field]: value },
        });
    };

    handleSubmit = async () => {
        if (this.validate()) {
            const resp = await CoincaexService.getInstance().signup(this.state.registerForm);

            if (resp) {
                this.props.navigation.navigate(CoincaexNavigatorScreens.Login.routeName, {
                    login: { email: this.state.registerForm.email, password: this.state.registerForm.password },
                });
            }
        }
    };

    validate = (): boolean => {
        const { firstName, lastName, phone, email, password, confirmPassword } = this.state.registerForm;

        if (!firstName || !lastName || !phone || !email || !password || !confirmPassword) {
            store.dispatch(showSnackbar({ type: "ERROR", message: t("complete_field_warning") }));
            return false;
        }

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.registerForm.email)) {
            store.dispatch(showSnackbar({ type: "ERROR", message: t("error_email") }));
            return false;
        }

        if (!/^-?\d+$/.test(this.state.registerForm.phone)) {
            store.dispatch(showSnackbar({ type: "ERROR", message: t("error_phone") }));
            return false;
        }

        if (password !== confirmPassword) {
            store.dispatch(showSnackbar({ type: "ERROR", message: t("password_error") }));
            return false;
        }

        return true;
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("sign_up")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1, justifyContent: "space-between" }}>
                    <ScrollView>
                        <FloatingTextInput
                            onChangeText={(text) => this.handleInputChange(text, "firstName")}
                            value={this.state.registerForm.firstName}
                            label={t("name")}
                            multiline={false}
                        />
                        <FloatingTextInput
                            onChangeText={(text) => this.handleInputChange(text, "lastName")}
                            value={this.state.registerForm.lastName}
                            label={t("lastname")}
                            multiline={false}
                        />
                        <FloatingTextInput
                            onChangeText={(text) => this.handleInputChange(text, "email")}
                            value={this.state.registerForm.email}
                            label={t("email")}
                            multiline={false}
                        />
                        <FloatingTextInput
                            onChangeText={(text) => this.handleInputChange(text, "phone")}
                            value={this.state.registerForm.phone}
                            label={t("phone")}
                            multiline={false}
                        />
                        <FloatingTextInput
                            onChangeText={(text) => this.handleInputChange(text, "password")}
                            value={this.state.registerForm.password}
                            label={t("password")}
                            multiline={false}
                            secureTextEntry={true}
                        />
                        <FloatingTextInput
                            onChangeText={(text) => this.handleInputChange(text, "confirmPassword")}
                            value={this.state.registerForm.confirmPassword}
                            label={t("confirmPassword")}
                            multiline={false}
                            secureTextEntry={true}
                        />
                    </ScrollView>
                    <InlineButton style={{ marginBottom: 10 }} title={t("sign_up")} onPress={this.handleSubmit} />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const CoincaexSignUpScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexSignUpScreen);

export default CoincaexSignUpScreen;
