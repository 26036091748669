import { SHOW_ACTIONS, HIDE_ACTIONS } from "@store/actions/header";

interface IHeaderReducerState {
    showActions: boolean
}

const initalSatate: IHeaderReducerState = {
  showActions: false
};

const headerReducer = (state = initalSatate, action) => {
  switch(action.type) {

    case SHOW_ACTIONS:
        return { 
        ...state, 
        showActions: true
        }

    case HIDE_ACTIONS:
        return { 
        ...state, 
        showActions: false
        }

    default:
      return state;
  }
}


export default headerReducer;