import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { StyleSheet, View } from "react-native";

import i18n from "@i18n/i18n";

import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import { MembershipModel } from "@custom-types/ExpercienceModel";
import ImageBase from "@components/image/ImageBase";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { getColorOpacity } from "@utils/helpers/global/global";
import Icon from "@components/icons";
import { ExperiencesNavigatorScreens } from "@navigation/ExperiencesNavigator";
import AvatarBase from "@components/avatar/AvatarBase";
import ExperienceService from "@core/services/ExperienceService";
import store from "@store/index";
import { updateMembership, updateRedeemable } from "@store/actions/experiences.actions";
import BoldText from "@base/BoldText";
import { ExperienceNavigator, ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import Row from "@base/Row";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation?: NavigationType;
    membership: MembershipModel;
}

interface State {}

const { t } = i18n;

export default class MembershipCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToMembership = this.navigateToMembership.bind(this);
        this.setFavorite = this.setFavorite.bind(this);
    }

    navigateToMembership() {
        this.props.navigation.navigate("Experience", {
            screen: ExperienceNavigatorScreens.Membership.routeName,
            params: { membership: this.props.membership },
        });
    }

    async setFavorite() {
        ExperienceService.getInstance().setMembershipFavorite(this.props.membership);
        let membership: MembershipModel = this.props.membership;
        membership.favorite = !this.props.membership.favorite;
        store.dispatch(updateMembership(membership));
    }

    render() {
        return (
            <PressableBase style={[styles.card]} onPress={this.navigateToMembership}>
                <ImageBase
                    style={{ height: 180 }}
                    uri={
                        this.props.membership?.membershipOption?.image?.carousel ||
                        this.props.membership?.membershipOption?.image?.cover
                    }
                    blurRadius={this.props.membership.archivedAt ? 10 : 0}
                ></ImageBase>
                <PressableBase
                    style={{ position: "absolute", right: 10, top: 10, zIndex: 100 }}
                    onPress={this.setFavorite}
                >
                    {this.props.membership.favorite ? (
                        <Icon color={colors.yellow} size={20} name="star-fill" />
                    ) : (
                        <Icon color={colors.grey} size={20} name="star" />
                    )}
                </PressableBase>
                <View style={{ position: "absolute", left: 10, top: 10, zIndex: 100 }}>
                    <AvatarBase
                        uri={this.props.membership.merchant?.image?.thumbnail}
                        alias={this.props.membership.merchant?.name}
                        size={25}
                    ></AvatarBase>
                </View>
                {this.props.membership.archivedAt && (
                    <View
                        style={{
                            position: "absolute",
                            zIndex: 100,
                            bottom: 45,
                            left: -10,
                            borderRadius: 15,
                            paddingRight: 10,
                            paddingLeft: 18,
                            paddingVertical: 3,
                            backgroundColor: getColorOpacity(colors.grey, 0.8),
                        }}
                    >
                        <Row style={{ alignItems: "center" }}>
                            <Icon
                                size={12}
                                style={{ paddingRight: 5 }}
                                color={colors.labelText}
                                name={"archive"}
                            ></Icon>

                            <RegularText color={colors.labelText} fontSize={10}>
                                {t("archived").toUpperCase()}
                            </RegularText>
                        </Row>
                    </View>
                )}
                <View style={{ position: "absolute", zIndex: 100, left: -10, bottom: 10 }}>
                    <View
                        style={{
                            backgroundColor: colors.secondary,
                            borderRadius: 10,
                            paddingRight: 10,
                            paddingLeft: 20,
                            paddingVertical: 2,
                        }}
                    >
                        <BoldText color={colors.labelText} numberOfLines={1} fontSize={12}>
                            {this.props.membership.membershipOption.name}
                        </BoldText>
                    </View>
                </View>
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        borderRadius: settings.cardRadius,
        margin: 5,
        overflow: "hidden",
        backgroundColor: colors.shadow,
        flex: 1,
    },
    titleWrapper: {
        position: "absolute",
        zIndex: 100,
        bottom: 10,
        left: 10,
        backgroundColor: colors.secondary,
        paddingHorizontal: 10,
        paddingVertical: 2,
        borderRadius: settings.cardRadius,
    },
    iconWrapper: {
        position: "absolute",
        top: 10,
        right: 10,
    },
});
