import { Platform, StyleSheet } from "react-native";
import { colors } from "@styles/globalStyles";
import React from "react";
import Icon from "@components/icons";
import GradientWrapper from "@components/wrapper/GradientWrapper";
import PressableBase from "./PressableBase";

const styles = StyleSheet.create({
    gradientWrapperStyle: {
        backgroundColor: colors.floatButtonBackground,
        width: 55,
        height: 55,
        borderRadius: 30,
        justifyContent: "center",
        alignItems: "center",
    },
});

interface IFloatButton {
    onPress: () => void;
    iconName: string;
    style?: StyleSheet;
}

export function FloatButton(props: IFloatButton) {
    return (
        <PressableBase
            style={{ position: "absolute", right: 20, bottom: 20, marginBottom: 8 }}
            onPress={() => props.onPress()}
            accessibilityLabel={props.iconName}
        >
            <GradientWrapper disable={true} style={styles.gradientWrapperStyle}>
                <Icon name={props.iconName} size={22} color={colors.floatButtonText} align="center" />
            </GradientWrapper>
        </PressableBase>
    );
}
