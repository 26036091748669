import CircleButton from "@base/CircleButton";
import RadiusButton from "@base/RadiusButton";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import SemiBoldText from "@base/SemiBold";
import AvatarBase from "@components/avatar/AvatarBase";
import POAP from "@custom-types/POAP";
import i18n from "@i18n/i18n";
import { hideModalBottom } from "@store/actions/global";
import store from "@store/index";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { PoapAdatper } from "@adapters/POAP.adapter";

interface Props {
    claimData;
    onPress: () => void;
}

interface State {
    poap: POAP;
}

const { t } = i18n;

const DataToRemove = {
    beneficiary: "",
    claimed: false,
    claimed_date: "",
    created_date: "2024-10-17T13:31:06.563Z",
    delegated_mint: false,
    delegated_signed_message: "",
    event: {
        animation_url: "",
        channel: "",
        city: "",
        country: "",
        description:
            "El POAP de BeeXO es un reconocimiento digital exclusivo para los participantes de eventos organizados por la comunidad de BeeXO, centrada en la innovación y el uso de tecnologías blockchain y web3. Este POAP (Proof of Attendance Protocol) simboliza la participación en encuentros clave que impulsan la colaboración entre desarrolladores, emprendedores y entusiastas de cripto-tecnologías. Cada poseedor del POAP de BeeXO se convierte en parte de una red global de visionarios comprometidos con el futuro descentralizado.",
        end_date: "27-Oct-2024",
        event_template_id: null,
        event_url: "https://beexo.com/",
        expiry_date: "27-Oct-2025",
        fancy_id: "beexo-2024",
        from_admin: false,
        id: 179078,
        image_url: "https://assets.poap.xyz/42ff3fdd-eb2c-4205-bbb8-8628d7d6127d.png",
        location_type: "VIRTUAL",
        minting_config: {},
        name: "Beexo",
        platform: "VIDEO_CALL",
        private_event: true,
        start_date: "17-Oct-2024",
        timezone: "America/Argentina/Buenos_Aires",
        virtual_event: true,
        year: 2024,
    },
    event_id: 179078,
    event_template: null,
    id: 33038948,
    is_active: true,
    minting_template: null,
    qr_hash: "sciwhb",
    secret: "123ed21f0242ed066281fc11e463752e7ded114ec75d33d1813d7c5812bd0173",
    signer: "",
    tx_hash: "",
    tx_status: "",
    user_input: "",
};

export class ClaimPoapModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPressMint = this.onPressMint.bind(this);
        this.state = {
            poap: PoapAdatper(this.props.claimData),
        };
    }

    onPressClose() {
        store.dispatch(hideModalBottom());
    }

    onPressMint() {
        this.props?.onPress();
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <AvatarBase
                    size={180}
                    alias={this.state.poap?.event?.name}
                    uri={this.state.poap?.event?.imageUrl}
                ></AvatarBase>
                <View style={{ paddingVertical: 25 }}>
                    <SemiBoldText align={"center"} fontSize={24}>
                        {trimHelper(this.state.poap?.event?.name, 30)}
                    </SemiBoldText>
                    <RegularText style={{ paddingTop: 5 }} align={"center"} fontSize={16}>
                        {trimHelper(this.state.poap?.event?.description, 80)}
                    </RegularText>
                </View>

                <SemiBoldText fontSize={16}>{t("poap_question")}</SemiBoldText>

                <Row style={{ paddingTop: 35, justifyContent: "center" }}>
                    <CircleButton
                        style={{ height: 40, width: 40, borderRadius: 20, marginBottom: 0 }}
                        iconSize={20}
                        icon="x-lg"
                        onPress={this.onPressClose}
                    ></CircleButton>
                    <RadiusButton
                        style={{ marginLeft: 20 }}
                        title={"Mint POAP"}
                        onPress={this.onPressMint}
                    ></RadiusButton>
                </Row>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 20,
        paddingBottom: 50,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
});
