import { StyleSheet, Appearance } from 'react-native';

const darkColors = {
    primary: '#1d1b2c',
    secondary: '#985dff',
    tertiary: '#000', 
    background: '#000',
    gradientFrom: '#181818',
    gradientTo: '#000',
    grey: '#9091a4',
    white: 'white',
    black: '#333',
    blue: '#2696fb',
    green: '#1DBC60',
    yellow: '#FECA54',
    red: '#EE4540',
    text: 'white',
    shadow: 'rgba(255,255,255,0.04)',
    secondaryShadow: 'rgba(0, 0, 0, 0.1)',
    tertiaryShadow: 'rgba(152, 93, 255, 0.15)',
    highlightedText: "white",
};



const settings = {}

export { darkColors, settings }