import React, { Component } from 'react';
import i18n from '@i18n/i18n';
import {  View, StyleSheet, Dimensions, ScrollView } from 'react-native';
import BoldText from '@base/BoldText';
import { colors, settings } from '@styles/globalStyles';
import Icon from '@components/icons';
import RegularText from '@base/RegularText';
import Card from '@base/Card';
import ImageBase from '@components/image/ImageBase';
import InlineButton from '@base/InlineButton';
import BotCard from '@base/BotCard';
import PressableBase from '@base/PressableBase';

const { t } = i18n;

interface Props {
    idCardFront: any;
    idCardBack: any;
    errorMessage: string
    validate: () => boolean
    prevStep: () => void
    openCamera: (camera: 'idCardFront' | 'idCardBack' | 'selfie' | 'video') => Promise<void>
}

interface State {
    imageHight: number,
    imageWidth: number,
    onPress?: () => void
}

export default class StepTwoKYC extends Component<Props, State> {
    camera: any;
    constructor(props: Props) {
        super(props);

        this.state = {
            imageHight: 300,
            imageWidth: Dimensions.get("window").width,
        };
    }

    render() {
        return (
            <View style={styles.flex}>
                <RegularText align={"center"} style={{ marginTop: 16, marginBottom: 10 }}>
                    {t("identity_verification")}
                </RegularText>
                <View style={{ flex: 1 }}>
                    <ScrollView>
                        <Card style={{ alignItems: "baseline", flex: 1 }}>
                            <BoldText fontSize={14} style={{ marginBottom: 10 }}>
                                {t("idcard_title")}
                            </BoldText>
                            <RegularText fontSize={14} style={{ marginBottom: 10 }}>
                                {t("idcard_description_one")}
                            </RegularText>
                            <RegularText fontSize={14} style={{ marginBottom: 10 }}>
                                {t("idcard_description_two")}
                            </RegularText>
                            <RegularText fontSize={14} style={{ marginBottom: 10 }}>
                                {t("idcard_description_three")}
                            </RegularText>
                            <RegularText fontSize={14} style={{ marginBottom: 10 }}>
                                {t("idcard_description_four")}
                            </RegularText>
                            {this.props.idCardFront ? (
                                <View
                                    style={{
                                        maxWidth: 600,
                                        width: "100%",
                                        marginHorizontal: "auto",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        marginVertical: 20
                                    }}
                                >
                                    <ImageBase
                                        uri={this.props.idCardFront}
                                        style={{
                                            borderRadius: settings.cardRadius,
                                            width: this.state.imageWidth / 2,
                                            height: this.state.imageHight / 2,
                                            maxWidth: 600,
                                            maxHeight: 600,
                                            resizeMode: "cover",
                                            flex: 1,
                                            aspectRatio: 1.5,
                                        }}
                                    />
                                    <View
                                        style={{
                                            position: "absolute",
                                            alignSelf: "center",
                                            bottom: -25,
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                            width: this.state.imageWidth / 2,
                                            height: 50,
                                        }}
                                    >
                                        <PressableBase onPress={() => this.props.openCamera('idCardFront')}>
                                            <Icon
                                                name="camera"
                                                size={20}
                                                color={colors.white}
                                                style={styles.icon}
                                            />
                                        </PressableBase>
                                    </View>
                                </View>) : (<PressableBase
                                    style={styles.imagePlaceholderContainer}
                                    onPress={() => this.props.openCamera('idCardFront')}
                                >
                                    <RegularText style={{ marginBottom: 10 }}>{t("front_idcard")}</RegularText>
                                    <Icon
                                        name="idcard-front"
                                        size={70}
                                        color={colors.grey}
                                    ></Icon>
                                </PressableBase>)
                            }

                            {this.props.idCardBack ? (
                                <View
                                    style={{
                                        marginTop: 10,
                                        maxWidth: 600,
                                        width: "100%",
                                        marginHorizontal: "auto",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        marginBottom: 20
                                    }}
                                >
                                    <ImageBase
                                        uri={this.props.idCardBack}
                                        style={{
                                            borderRadius: settings.cardRadius,
                                            width: this.state.imageWidth / 2,
                                            height: this.state.imageHight / 2,
                                            maxWidth: 600,
                                            maxHeight: 600,
                                            resizeMode: "cover",
                                            flex: 1,
                                            aspectRatio: 1.5,
                                        }}
                                    />
                                    <View
                                        style={{
                                            position: "absolute",
                                            alignSelf: "center",
                                            bottom: -25,
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                            width: this.state.imageWidth / 2,
                                            height: 50,
                                        }}
                                    >
                                        <PressableBase onPress={() => this.props.openCamera('idCardBack')}>
                                            <Icon
                                                name="camera"
                                                size={20}
                                                color={colors.white}
                                                style={styles.icon}
                                            />
                                        </PressableBase>
                                    </View>
                                </View>) : (<PressableBase
                                    style={styles.imagePlaceholderContainer}
                                    onPress={() => this.props.openCamera('idCardBack')}
                                >
                                    <RegularText style={{ marginBottom: 10 }}>{t("back_idcard")}</RegularText>
                                    <Icon
                                        name="idcard-back"
                                        size={70}
                                        color={colors.grey}
                                    />
                                </PressableBase>)
                            }
                        </Card >
                        <View>
                            <View style={this.props.errorMessage.length ? styles.errorMessage : { display: 'none' }}>
                                <BotCard
                                    title={t("warning")}
                                    message={this.props.errorMessage}
                                />
                            </View>
                            <View style={styles.buttonContainer}>
                                <InlineButton
                                    title={t("previous")}
                                    onPress={this.props.prevStep}
                                    style={{ flex: 1, marginVertical: 15, marginRight: 5, marginLeft: 0 }}
                                />
                                <InlineButton
                                    title={t("next")}
                                    onPress={this.props.validate}
                                    style={{ flex: 1, marginVertical: 15, marginLeft: 5, marginRight: 0 }}
                                />
                            </View>
                        </View>
                    </ScrollView>
                </View >
            </View >
        )
    }
}


const styles = StyleSheet.create({
    flex: {
        flex: 1,
    },
    container: {
        flex: 1,
    },
    imagePlaceholderContainer: {
        marginVertical: 15,
        width: "100%",
        height: 150,
        borderColor: colors.grey,
        backgroundColor: colors.shadow,
        borderWidth: 1,
        borderRadius: settings.cardRadius,
        borderStyle: "dashed",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    icon: {
        height: 40,
        width: 40,
        borderRadius: 20,
        padding: 10,
        backgroundColor: colors.secondary,
        overflow: "hidden",
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    errorMessage: {
        marginTop: 10,
        marginBottom: 10,
    }
});




