import { StyleSheet, Appearance } from 'react-native';

const darkColors = {
    primary: '#27AAE2',
    secondary: '#27AAE2',
    complementary: '#27AAE2',
    tertiary: '#010310', 
    background: '#010310',
    gradientFrom: '#041151',
    gradientTo: '#010310',
    grey: '#9091a4',
    white: 'white',
    black: '#333',
    blue: '#2696fb',
    green: '#1DBC60',
    yellow: '#FECA54',
    red: '#EE4540',
    text: 'white',
    shadow: 'rgba(255,255,255,0.04)',
    secondaryShadow: 'rgba(0, 0, 0, 0.1)',
    tertiaryShadow: 'rgba(255,255,255,0.04)'
};

const lightColors = {
    primary: '#1d1b2c',
    secondary: '#9B5AF2',
    complementary: '#27AAE2',
    tertiary: '#fff', 
    background: '#d8d9db',
    gradientFrom: '#f3f5f9',
    gradientTo: '#d8d9db',
    grey: '#9091a4',
    white: 'white',
    black: '#333',
    blue: '#2696fb',
    green: '#1DBC60',
    yellow: '#FECA54',
    red: '#EE4540',
    text: '#454656',
    shadow: 'rgba(255,255,255,0.8)',
    secondaryShadow: 'rgba(0, 0, 0, 0.1)'
};

const settings = {}

export { lightColors, darkColors, settings }