import React, { Component } from 'react';
import { StyleSheet, View, TextStyle, ViewStyle } from 'react-native';

interface Props {
    style?: TextStyle | Array<ViewStyle>
    children?: any
}

interface State {
    
}

export default class Row extends Component<Props, State> {
    constructor(props:Props){
        super(props)
    }

    render() {
        return(
            <View style={[styles.defaultStyles, this.props.style]}>
                {this.props.children}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    defaultStyles:{
        flexDirection: 'row'
    }
});