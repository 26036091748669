import React, { Component } from "react";
import WebViewBase from "@components/webview/WebViewBase";
import { View } from "react-native";

interface Props {
  marker: [number, number]
  markerImage?: string
}

interface State {
  queryParams: string
}

export class LeafletComponent extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      queryParams: ''
    };
  }

  componentDidMount(): void {
    this.setParams()
  }

  setParams = () => {
    let url = `?lat=${this.props.marker[0]}&lng=${this.props.marker[1]}`
    if (this.props.markerImage) {
      url += `&img=${this.props.markerImage}`
    }
    this.setState({ queryParams: url })
  }

  render() {
    return (
      <View style={{ height: 200, width: "100%", marginTop: 20, }}>
        <WebViewBase source={`https://rodrigomanuelcrespo.github.io/leaflet-map/${this.state.queryParams}`} />
      </View>
    );
  }
}

export default LeafletComponent;