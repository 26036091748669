import { _SendScreen } from "@screens/wallet/send/SendScreen";
import { connect } from "react-redux";
import { _ConfirmSendScreen } from "@screens/wallet/send/ConfirmSendScreen";
import { _ConfirmTransferRedeemableScreen } from "@screens/experiences/ConfirmTransferRedeemableScreen";
import ExperienceService from "@core/services/ExperienceService";
import store from "@store/index";
import { loading, ready, showPopup } from "@store/actions/global";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";
import { ChatService } from "@core/services/ChatService";
import { ASSET, REDEEMABLE } from "@custom-types/ExpercienceModel";
import { setCustomMessage } from "@store/actions/chat.actions";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import Wallet from "@core/wallet/Wallet";
import i18n from "@i18n/i18n";
import { _ConfirmTransferAssetScreen } from "@screens/experiences/ConfirmTransferAssetScreen";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";

const { t } = i18n;

export class _ConfirmTtransferAssetExpendedScreen extends _ConfirmTransferAssetScreen {
    constructor(props: any) {
        super(props);
        this.sendMessage = this.sendMessage.bind(this);
        this.setAssetMessage = this.setAssetMessage.bind(this);
    }

    async onConfirm() {
        const resp = await ExperienceService.getInstance().confirmAssetTransfer(this.state.skeleton);

        if (resp) {
            store.dispatch(loading());
            await this.setAssetMessage(this.state.asset);
            await this.sendMessage();

            await ExperienceService.getInstance().getAssets();
            await ExperienceService.getInstance().getMerchants();
            this.props.navigation.navigate(MessagesNavigatorScreens.Chat.routeName);
            store.dispatch(ready());
            return;
        }
        store.dispatch(ready());
        store.dispatch(
            showPopup({
                type: "ERROR",
                message: t("an_error_has_occurred"),
            }),
        );
    }

    async setAssetMessage(asset: ASSET) {
        const customMessage: CustomMessageModel = store.getState().chat.customMessage;
        customMessage.network = Wallet.getInstance().getNetworkType();
        customMessage.currencyId = "...";
        customMessage.currencyName = "...";
        customMessage.address = "...";
        customMessage.asset = {
            _id: asset._id,
            assetOption: asset?.assetOption,
            merchant: asset?.merchant,
        };

        store.dispatch(setCustomMessage(customMessage));
    }

    async sendMessage() {
        const service: ChatService = await ChatService.getInstance();
        await service.sendCustomMessage();
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        customMessage: state.chat.customMessage,
        selectedChat: state.chat.selected,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ConfirmTtransferAssetExpendedScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_ConfirmTtransferAssetExpendedScreen);

export default ConfirmTtransferAssetExpendedScreen;
