import React, { Component } from "react";
import i18n from "@i18n/i18n";
import { View, StyleSheet } from "react-native";
import Currency from "@core/currencies/Currency";
import AvatarBase from "@components/avatar/AvatarBase";
import BoldText from "@base/BoldText";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import RadiusButton from "@base/RadiusButton";
import { getColorOpacity } from "@utils/helpers/global/global";
import { colors } from "@styles/globalStyles";
import CircleButton from "@base/CircleButton";
import store from "@store/index";
import { hideModalBottom } from "@store/actions/global";
import { Client } from "@custom-types/Client";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import Card from "@base/Card";
import InlineButton from "@base/InlineButton";

interface Props {
    avatar?: string;
    title?: string;
    subtitle?: string;
    description?: string;
    message?: string;
    client?: Client;
    clientMessage?: string;
    btnTitle?: string;
    selectCurrency?: boolean;
    currency?: Currency;
    amount?: string | number;
    currencies?: Array<any>;
    onPress?: () => void;
}

interface State {}

const { t } = i18n;

export default class WalletConnectModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <AvatarBase uri={this.props.avatar || ""} alias={this.props.title || ""} size={75}></AvatarBase>
                <BoldText align="center" style={{ paddingTop: 10 }} fontSize={24}>
                    {trimHelper(this.props.title || "", 25)}
                </BoldText>

                {this.props.currencies && (
                    <Row
                        style={{
                            flexWrap: "wrap",
                            paddingTop: 25,
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {this.props.currencies.map((c: Currency) => {
                            return (
                                <View
                                    key={c.getId()}
                                    style={{
                                        justifyContent: "center",
                                        alignContent: "center",
                                        alignItems: "center",
                                        paddingRight: 6,
                                    }}
                                >
                                    <CurrencyIcon size={30} iconSize={18} currency={c} />
                                </View>
                            );
                        })}
                    </Row>
                )}

                <RegularText align="center" fontSize={18} style={{ paddingTop: 20 }}>
                    {this.props.description || ""}
                </RegularText>

                {this.props.subtitle && (
                    <RegularText align="center" style={{ paddingTop: 20 }} fontSize={14}>
                        {this.props.subtitle}
                    </RegularText>
                )}

                {this.props.currency && (
                    <View style={{ width: "100%" }}>
                        {this.props.amount !== undefined ? (
                            <Row
                                style={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    paddingTop: 45,
                                }}
                            >
                                <CurrencyIcon size={22} iconSize={15} currency={this.props.currency}></CurrencyIcon>

                                <BoldText style={{ paddingLeft: 5 }} fontSize={14}>
                                    {this.props.amount} {this.props.currency.getPName()}
                                </BoldText>
                            </Row>
                        ) : (
                            <Row
                                style={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    paddingTop: 45,
                                   
                                }}
                            >
                                <CurrencyIcon size={22} iconSize={15} currency={this.props.currency}></CurrencyIcon>

                                <BoldText style={{ paddingLeft: 5 }} fontSize={14}>
                                    {this.props.currency.getBlockchain().toUpperCase()}
                                </BoldText>
                            </Row>
                        )}
                    </View>
                )}

                {this.props.client && (
                    <Row style={{ justifyContent: "flex-start", alignItems: "center", paddingTop: 10, width: "100%" }}>
                        <AvatarBase
                            uri={this.props.client?.profileImagePath?.thumbnail}
                            alias={this.props.client?.alias}
                            size={22}
                        ></AvatarBase>
                        <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                            {this.props.clientMessage}{"@"}
                            {this.props.client?.alias}
                        </RegularText>
                    </Row>
                )}

                {this.props.message && (
                    <View style={{ justifyContent: "flex-start", paddingTop: 25, width: "100%" }}>
                        <RegularText align="left">{this.props.message}</RegularText>
                    </View>
                )}
                {this.props.btnTitle && (
                    <Row style={{ paddingTop: 50 }}>
                        <InlineButton title={this.props.btnTitle} onPress={this.props.onPress} />
                    </Row>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        paddingBottom: 100
    },
});
