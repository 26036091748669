import { StyleSheet } from "react-native";
import React, { Component } from "react";
import { Chat } from "@custom-types/Chat";
import store from "@store/index";
import { selectChat } from "@store/actions/chat.actions";
import { ChatService } from "@core/services/ChatService";
import { connect } from "react-redux";
import { SearchCardInterface } from "@custom-types/SearchCardInterface";
import { setSearch, showSnackbar } from "@store/actions/global";
import { selectChatsAsArray } from "@store/reducers/chat.reducer";
import MessageCard from "./MessageCard";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";

interface Props {
    item: SearchCardInterface;
    chats: Array<Chat>;
    navigation: any;
    onLongPress?: any;
    selected?: boolean;
    clearSearch?: () => void;
}

interface State {
    initial: string;
}

export class _ContactClientCard extends Component<Props, State> {
    chatService: ChatService;

    constructor(props) {
        super(props);

        this.redirectToChat = this.redirectToChat.bind(this);
    }

    async componentDidMount() {
        this.chatService = await ChatService.getInstance();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps != this.props;
    }

    async redirectToChat() {
        try {
            const client = this.props.item.getClient();
            let chat = await this.chatService.getById(client._id);

            if (!chat) {
                store.dispatch(showSnackbar({ type: "ERROR", message: chat.toString() }));
                return;
            }

            store.dispatch(selectChat(chat));
            this.props.navigation.navigate(MessagesNavigatorScreens.Chat.routeName);
            this.props.clearSearch && this.props.clearSearch();
        } catch (e) {
            console.warn(e);
        }
    }

    render() {
        return (
            <MessageCard
                onLongPress={this.props.onLongPress}
                selected={this.props.selected}
                onPress={this.redirectToChat}
                profileImagePath={this.props.item.getClient().profileImagePath?.square}
                initial={this.props.item.getClient().alias[0]}
                title={this.props.item.getTitle()}
                subtitle={this.props.item.getSubtitle()}
                navigation={this.props.navigation}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        chats: selectChatsAsArray(state),
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ContactClientCard = connect(mapStateToProps, mapDispatchToProps)(_ContactClientCard);

export default ContactClientCard;

const styles = StyleSheet.create({});
