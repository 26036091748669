import * as React from "react"
import Svg, { Circle, LinearGradient, Path, Stop } from "react-native-svg"

const Logo = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    x={0}
    y={0}
    viewBox="0 0 107 65"
    width={100}
    height={100}
    style={{
      enableBackground: "new 0 0 107 65",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <LinearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={28.488}
      y1={32.5}
      x2={78.512}
      y2={32.5}
    >
      <Stop
        offset={0}
        style={{
          stopColor: "#f6a94d",
        }}
      />
      <Stop
        offset={1}
        style={{
          stopColor: "#f0851f",
        }}
      />
    </LinearGradient>
    <Circle
      cx={53.5}
      cy={32.5}
      r={25.01}
      style={{
        fill: "url(#SVGID_1_)",
      }}
    />
    <LinearGradient
      id="SVGID_00000011726661917629793170000010311115961009724549_"
      gradientUnits="userSpaceOnUse"
      x1={31.765}
      y1={32.5}
      x2={75.235}
      y2={32.5}
    >
      <Stop
        offset={0}
        style={{
          stopColor: "#f6a94d",
        }}
      />
      <Stop
        offset={1}
        style={{
          stopColor: "#f0851f",
        }}
      />
    </LinearGradient>
    <Path
      style={{
        fill: "url(#SVGID_00000011726661917629793170000010311115961009724549_)",
      }}
      d="M75.23 32.5c0 12-9.73 21.73-21.73 21.73S31.77 44.5 31.77 32.5 41.5 10.76 53.5 10.76 75.23 20.5 75.23 32.5z"
    />
    <Path style={{ fill: "#fff" }} d="m53.05 30.93.73 1.6v-2.99z" />
    <Path
      style={{ fill: "#fff" }}
      d="m53.05 30.93.73-1.39V25.3H56l2.75-5.22h-6.27l-4.37 9.53h-.07v-9.53h-5.89v24.84h5.89v-6.57l1.24-2.09 3.45 8.66h1.05V32.53z"
    />
    <Path
      style={{ fill: "#fff" }}
      d="M59.44 44.92h.76V34.66c0-2.47 1.17-3.34 3.2-3.34.48 0 .92.03 1.42.14v-5.85h-.92c-1.68 0-3.2 1.22-3.83 3.48h-.06V26.1h-4.42l-1.08 2.07v5.95l4.93 10.8z"
    />
    <Path
      style={{ fill: "#fff" }}
      d="M54.5 26.1v2.07l1.09-2.07zM54.5 44.92h4.94L54.5 34.11zM61.52 20.37h-.01l-.98 3.47h-.2l-.98-3.47h-.01v3.47h-.2v-3.76h.32l.98 3.38h.01l.94-3.38h.33v3.76h-.2zM62.22 20.08h.19v2.47h.01l1.08-1.46h.23l-.78 1.02.92 1.73h-.23l-.82-1.55-.41.54v1.01h-.19zM63.88 21.09h.34v-.82h.18v.82h.42v.18h-.42v2.12c0 .26.12.31.22.31.08 0 .14-.01.23-.03v.18c-.09.02-.19.03-.26.03-.26 0-.37-.16-.37-.46v-2.14h-.34v-.19z"
    />
  </Svg>
)

export default Logo
