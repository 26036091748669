import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { Header } from "@components/header/Header";
import React, { Component } from "react";
import { HeaderType } from "@custom-types/HeaderType";
import i18n from "@i18n/i18n";
import { NavigationType } from "@custom-types/NavigationType";
import {  View, StyleSheet, Image } from "react-native";
import BoldText from "@base/BoldText";
import { colors } from "@styles/globalStyles";
import Container from "@base/Container";
import { Camera, CameraType } from "expo-camera";
import StepOneKYC from "./components/StepOneKYC";
import StepTwoKYC from "./components/StepTwoKYC";
import StepThreeKYC from "./components/StepThreeKYC";
import StepFourKYC from "./components/StepFourKYC";
import store from "@store/index";
import { hideModal, loading, ready, showModal } from "@store/actions/global";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import avatar from "@assets/images/avatar";
import { Audio } from 'expo-av';
import PressableBase from "@base/PressableBase";

const { t } = i18n;

interface PersonalInformation {
    nationality: string;
    name: string;
    lastname: string;
    birthdate?: Date;
    address: string;
    zipCode: string;
    city: string;
}

interface Props {
    navigation: NavigationType;
    title: string;
    version?: string;
    icon?: string;
    iconSize?: number;
    onPress?: () => void;
    onFinish: () => void;
}

interface State {
    personalInformation: PersonalInformation;
    currentStep: number;
    idCardFront: any;
    idCardBack: any;
    camera: "idCardFront" | "idCardBack" | "selfie" | "video";
    selfie: any;
    alert: boolean;
    isRecording: boolean;
    videoUri: any;
    isVideoRecorded: boolean;
    openCamera: boolean;
    timeRemaining: number;
    errorMessage: string;
    imageIndex: number;
    onPress?: () => void;
}

export default class PersonalVerificationScreen extends Component<Props, State> {
    camera: any;
    constructor(props: Props) {
        super(props);

        this.state = {
            personalInformation: {
                nationality: "",
                name: "",
                lastname: "",
                birthdate: new Date(),
                address: "",
                zipCode: "",
                city: "",
            },
            currentStep: 1,
            idCardFront: null,
            idCardBack: null,
            camera: "selfie",
            selfie: null,
            alert: false,
            isRecording: false,
            videoUri: null,
            isVideoRecorded: false,
            openCamera: false,
            timeRemaining: 8,
            errorMessage: "",
            imageIndex: 0,
        };
    }

    goToNextStep = () => {
        const { currentStep } = this.state;

        if (currentStep < 4) {
            this.setState({ currentStep: currentStep + 1 });
        }
    };

    goToPreviousStep = () => {
        const { currentStep } = this.state;

        if (currentStep > 1) {
            this.setState({ currentStep: currentStep - 1 });
        }
    };

    renderStepBar = () => {
        const { currentStep } = this.state;
        return (
            <View style={styles.stepBar}>
                <View style={currentStep > 1 ? styles.stepActive : styles.stepOn}>
                    <BoldText style={styles.stepText}>1</BoldText>
                </View>
                <View style={currentStep > 1 ? styles.stepLineActive : styles.stepLine} />
                <View style={[currentStep === 2 && styles.stepOn, currentStep < 2 && styles.step, currentStep > 2 && styles.stepActive]}>
                    <BoldText style={styles.stepText}>2</BoldText>
                </View>
                <View style={currentStep > 2 ? styles.stepLineActive : styles.stepLine} />
                <View style={[currentStep === 3 && styles.stepOn, currentStep < 3 && styles.step, currentStep > 3 && styles.stepActive]}>
                    <BoldText style={styles.stepText}>3</BoldText>
                </View>
                <View style={currentStep > 3 ? styles.stepLineActive : styles.stepLine} />
                <View style={[currentStep === 4 && styles.stepOn, currentStep < 4 && styles.step, currentStep > 4 && styles.stepActive]}>
                    <BoldText style={styles.stepText}>4</BoldText>
                </View>
            </View>
        );
    };

    changeInputValue = (value: string | Date, field: string) => {
        this.setState((prevState) => ({
            personalInformation: {
                ...prevState.personalInformation,
                [field]: value,
            },
        }));
    };


    validateStep = (): boolean => {
        if (this.state.currentStep === 1) {
            const { name, lastname, address, city, zipCode, nationality } = this.state.personalInformation

            if (name.length < 1 || name.length > 29) {
                this.setState(prevState => ({
                    personalInformation: {
                        ...prevState.personalInformation,
                        name: ''
                    },
                    errorMessage: t("complete_field_warning"),
                }));
                setTimeout(() => {
                    this.setState({ errorMessage: "" });
                }, 3000);
                return false;
            }

            if (lastname.length < 1 || lastname.length > 29) {
                this.setState(prevState => ({
                    personalInformation: {
                        ...prevState.personalInformation,
                        lastname: ''
                    },
                    errorMessage: t("complete_field_warning"),
                }));
                setTimeout(() => {
                    this.setState({ errorMessage: "" });
                }, 1500);
                return false;
            }

            if (nationality.length < 1 || nationality.length > 29 || nationality === t("nationality")) {
                this.setState(prevState => ({
                    personalInformation: {
                        ...prevState.personalInformation,
                        nationality: ''
                    },
                    errorMessage: t("complete_field_warning"),
                }));
                setTimeout(() => {
                    this.setState({ errorMessage: "" });
                }, 1500);
                return false;
            }

            if (city.length < 1 || city.length > 29) {
                this.setState(prevState => ({
                    personalInformation: {
                        ...prevState.personalInformation,
                        city: ''
                    },
                    errorMessage: t("complete_field_warning"),
                }));
                setTimeout(() => {
                    this.setState({ errorMessage: "" });
                }, 1500);
                return false;
            }

            if (address.length < 1 || address.length > 29) {
                this.setState(prevState => ({
                    personalInformation: {
                        ...prevState.personalInformation,
                        address: ''
                    },
                    errorMessage: t("complete_field_warning"),
                }));
                setTimeout(() => {
                    this.setState({ errorMessage: "" });
                }, 1500);
                return false;
            }

            if (zipCode.length < 1 || zipCode.length > 29) {
                this.setState(prevState => ({
                    personalInformation: {
                        ...prevState.personalInformation,
                        zipCode: ''
                    },
                    errorMessage: t("complete_field_warning"),
                }));
                setTimeout(() => {
                    this.setState({ errorMessage: "" });
                }, 1500);
                return false;
            }
        }

        if (this.state.currentStep === 2) {
            if (!this.state.idCardFront || !this.state.idCardBack) {
                this.setState({
                    errorMessage: t("complete_file_warning"),
                });
                setTimeout(() => {
                    this.setState({ errorMessage: "" });
                }, 3000);
                return false;
            }
        }

        if (this.state.currentStep === 3) {
            if (!this.state.selfie) {
                this.setState({
                    errorMessage: t("complete_file_warning"),
                });
                setTimeout(() => {
                    this.setState({ errorMessage: "" });
                }, 3000);
                return false;
            }
        }

        this.goToNextStep()
    }

    openCamera = async (camera: "idCardFront" | "idCardBack" | "selfie" | "video") => {
        let permissionCamera = await Camera.requestCameraPermissionsAsync();
        let permissionAudio = await Audio.requestPermissionsAsync();

        if (permissionCamera.granted === false) {
            alert("Permission to access camera roll is required!");
            return;
        }

        if (permissionAudio.granted === false) {
            alert("Permission to record audio is required!");
            return;
        }

        this.setState({ ...this.state, camera, openCamera: true });
    };

    startRecording = async () => {
        if (this.state.camera === "video") {
            this.setState({ isRecording: true });
            const interval = setInterval(this.updateTimeRemaining, 1000);
            const avatarInterval = setInterval(this.changeImage, 500);
            const video = await this.camera.recordAsync({ maxDuration: 8, mute: true });
            store.dispatch(loading());
            clearInterval(interval);
            clearInterval(avatarInterval);
            this.setState({
                isRecording: false,
                isVideoRecorded: true,
                videoUri: video.uri,
                timeRemaining: 8,
                openCamera: false,
            });
            this.stopRecording();
            store.dispatch(ready());
            this.finishVerification();
        } else {
            const photo = await this.camera.takePictureAsync();
            this.setState({ ...this.state, [this.state.camera]: photo.uri, openCamera: false });
        }
    };

    stopRecording = async () => {
        await this.camera.stopRecording();
    };

    finishVerification = () => {
        store.dispatch(
            showModal({
                avatarIcon: "check",
                message: t("verification_completed"),
                onPressClose: () => {
                    this.props.navigation.navigate(ProfileNavigatorScreens.ProfileMain.routeName);
                    store.dispatch(hideModal());
                },
            }),
        );
    };

    changeImage = () => {
        this.setState((prevState) => ({
            imageIndex: prevState.imageIndex === 0 ? 1 : 0,
        }));
    };

    renderCamera = () => {
        return (
            <View style={{ flex: 1, marginVertical: 10 }}>
                {/* <Camera
                    ratio="16:9"
                    style={{ flex: 1 }}
                    type={
                        this.state.camera === "idCardBack" || this.state.camera === "idCardFront"
                            ? CameraType.back
                            : CameraType.front
                    }
                    ref={(ref) => {
                        this.camera = ref;
                    }}
                /> */}
                {this.state.camera === "video" ? (
                    !this.state.isRecording ? (
                        <View style={styles.camera}>
                            <PressableBase onPress={() => this.startRecording()} style={styles.cameraButton} />
                        </View>
                    ) : (
                        <View style={styles.camera}>
                            <View style={styles.avatar}>
                                {this.state.imageIndex === 0 ? (
                                    <Image
                                        style={{ height: 80, width: 80, borderRadius: 10 }}
                                        source={{ uri: avatar.openMouth }}
                                    />
                                ) : (
                                    <Image
                                        style={{ height: 80, width: 80, borderRadius: 10 }}
                                        source={{ uri: avatar.closeMouth }}
                                    />
                                )}
                            </View>
                            <BoldText>{t("kyc_mouth")}</BoldText>
                            <BoldText>{this.state.timeRemaining}</BoldText>
                        </View>
                    )
                ) : (
                    <View style={styles.camera}>
                        {this.state.camera === "idCardFront" && (
                            <BoldText align="center" fontSize={12} style={{ marginBottom: 10 }}>
                                {t("photo_idcard_front")}
                            </BoldText>
                        )}
                        {this.state.camera === "idCardBack" && (
                            <BoldText align="center" fontSize={12} style={{ marginBottom: 10 }}>
                                {t("photo_idcard_back")}
                            </BoldText>
                        )}
                        {this.state.camera === "selfie" && (
                            <BoldText align="center" fontSize={12} style={{ marginBottom: 10 }}>
                                {t("take_selfie")}
                            </BoldText>
                        )}
                        <PressableBase onPress={() => this.startRecording()} style={styles.cameraButton} />
                    </View>
                )}
                {(this.state.camera === "idCardFront" || this.state.camera === "idCardBack") && (
                    <View
                        style={{
                            width: "100%",
                            height: "100%",
                            flex: 1,
                            position: "absolute",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <View
                            style={{
                                width: "90%",
                                height: "45%",
                                borderStyle: "dashed",
                                borderWidth: 2,
                                opacity: 0.8,
                                borderColor: colors.white,
                                borderRadius: 6,
                            }}
                        ></View>
                    </View>
                )}
            </View>
        );
    };

    updateTimeRemaining = () => {
        const timeRemaining = this.state.timeRemaining - 1;
        this.setState({ timeRemaining });
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("personal_verification")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    <View style={{ flex: 1, justifyContent: "space-between" }}>
                        {!this.state.openCamera ? (
                            <View style={{ flex: 1 }}>
                                {this.renderStepBar()}
                                {this.state.currentStep === 1 && (
                                    <StepOneKYC
                                        errorMessage={this.state.errorMessage}
                                        personalInformation={this.state.personalInformation}
                                        nextStep={this.goToNextStep}
                                        changeInputValue={this.changeInputValue}
                                        validate={this.validateStep}
                                    />
                                )}
                                {this.state.currentStep === 2 && (
                                    <StepTwoKYC
                                        idCardFront={this.state.idCardFront}
                                        idCardBack={this.state.idCardBack}
                                        errorMessage={this.state.errorMessage}
                                        openCamera={this.openCamera}
                                        validate={this.validateStep}
                                        prevStep={this.goToPreviousStep}
                                    />
                                )}
                                {this.state.currentStep === 3 && (
                                    <StepThreeKYC
                                        selfie={this.state.selfie}
                                        errorMessage={this.state.errorMessage}
                                        openCamera={this.openCamera}
                                        validate={this.validateStep}
                                        prevStep={this.goToPreviousStep}
                                    />
                                )}
                                {this.state.currentStep === 4 && (
                                    <StepFourKYC
                                        isVideoRecorded={this.state.isVideoRecorded}
                                        openCamera={this.openCamera}
                                        prevStep={this.goToPreviousStep}
                                    />
                                )}
                            </View>
                        ) : (
                            this.renderCamera()
                        )}
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    camera: {
        padding: 10,
        position: "absolute",
        width: "100%",
        opacity: 0.8,
        backgroundColor: colors.background,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99,
    },
    cameraButton: {
        height: 50,
        width: 50,
        backgroundColor: colors.complementary,
        borderRadius: 100,
        borderWidth: 2,
        borderColor: colors.white,
    },
    avatar: {
        borderRadius: 20,
        width: "40%",
        flex: 1,
        justifyContent: "center",
        marginBottom: 10,
        alignItems: "center",
        height: 80,
    },
    stepBar: {
        flexDirection: "row",
        marginVertical: 10,
        alignItems: "center",
        paddingHorizontal: 40,
    },
    step: {
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: colors.shadow,
        justifyContent: "center",
        alignItems: "center",
    },
    stepActive: {
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: colors.complementary,
        justifyContent: "center",
        alignItems: "center",
    },
    stepOn: {
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: colors.shadow,
        borderWidth: 2,
        borderColor: colors.complementary,
        justifyContent: "center",
        alignItems: "center",
    },
    stepLine: {
        flex: 1,
        height: 2,
        backgroundColor: colors.shadow,
    },
    stepLineActive: {
        flex: 1,
        height: 2,
        backgroundColor: colors.complementary,
    },
    stepText: {
        color: colors.white,
        fontSize: 16,
    },
});
