import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import InlineButton from "@base/InlineButton";

import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";

import BotCard from "@base/BotCard";
import { OAuthService } from "@core/services/oauth/OAuthService";
import Initializer from "@screens/initializer/Initializer";

interface Props {
    navigation: any;
}

interface State {
    words: string[];
}

const { t } = i18n;

export default class ResetSeedScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPress = this.onPress.bind(this);
    }

    async onPress() {
        await OAuthService.getInstance().newAlias();
        Initializer.redirect(this.props.navigation);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("new_seed")} type={HeaderType.Light} {...this.props} />
                <Container style={styles.container}>
                    <BotCard title={t("warning_new_seed_question")} message={t("warning_new_seed")} />
                    <InlineButton
                        title={t("continue")}
                        onPress={this.onPress}
                        style={{ marginHorizontal: 0, marginBottom: 20 }}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    word: {
        paddingHorizontal: 20,
    },
});
