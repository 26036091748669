import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { FlatList, Platform, StyleSheet, View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { connect } from "react-redux";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import { NotificationModel } from "@custom-types/NotificationModel";
import { countUnreadNotification } from "@screens/notifications/notification.helper";
import NotificationCard from "@components/cards/NotificationCard";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    notifications: Array<NotificationModel>;
}

interface State {
    hideNotifications: boolean;
}

const { t } = i18n;

export class _NotificationsSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToSection = this.navigateToSection.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.filterNotifications = this.filterNotifications.bind(this);

        this.state = {
            hideNotifications: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ hideNotifications: true });
        }, 25000);
    }

    navigateToSection() {
        this.props.navigation.navigate(HomeNavigatorScreens.Notifications.routeName);
    }

    renderItem = ({ item }) => {
        return <NotificationCard notification={item} hideDate={true} navigation={this.props.navigation} />;
    };

    filterNotifications() {
        let notifications =
            this.props?.notifications.filter((n: NotificationModel) => {
                return !n.isRead;
            }) || [];
        if (notifications?.length > 1) {
            notifications = notifications.slice(0, 2);
        }
        return notifications;
    }

    render() {
        return (
            <View>
                {countUnreadNotification(this.props.notifications || []) > 0 && !this.state.hideNotifications && (
                    <View style={this.props.containerStyle}>
                        <SectionTitle
                            onPress={this.navigateToSection}
                            title={t("notifications").toUpperCase()}
                            icon={"🔔"}
                            counter={countUnreadNotification(this.props.notifications || [])}
                            {...this.props}
                        ></SectionTitle>

                        <FlatList
                            scrollEnabled={false}
                            data={this.filterNotifications()}
                            renderItem={this.renderItem}
                            keyExtractor={(item) => item._id}
                            contentContainerStyle={{ paddingTop: 10, zIndex: 99 }}
                            initialNumToRender={2}
                            maxToRenderPerBatch={2}
                        ></FlatList>
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
        //height: 180
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        notifications: state.notificationCenter?.notifications,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const NotificationsSection = connect(mapStateToProps, mapDispatchToProps)(_NotificationsSection);

export default NotificationsSection;
