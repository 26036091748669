import * as React from "react";
import Svg, { Path, G, Text, TSpan } from "react-native-svg";

function Logo(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={50}

            x={0}
            y={0}
            viewBox="0 0 578.8 584.6"
            style={{
                enableBackground: "new 0 0 578.8 584.6",
            }}
            xmlSpace="preserve"
            {...props}
        >
            <Path
                style={{
                    fill: "#a86fa6",
                }}
                d="m450.9 347.1-107.7-54.4v108z"
            />
            <Path
                style={{
                    fill: "#008fd5",
                }}
                d="m450.9 455.1-107.7-54.4v108z"
            />
            <Path style={{
                fill: "#1C5B87",
            }} d="m343.2 400.7 107.7-53.6v108z" />

            <Path
                style={{
                    fill: "#c8233e",
                }}
                d="M450.9 131.1 343.2 76.7v108z"
            />
            <Path
                style={{
                    fill: "#b68a5c",
                }}
                d="m450.9 239.1-107.7-54.4v108z"
            />
            <Path
                style={{
                    fill: "#8e0519",
                }}
                d="m343.2 184.7 107.7-53.6v108z"
            />
            <Path
                style={{
                    fill: "#f7aa0b",
                }}
                d="M343.2 76.7 235.6 22.3v108z"
            />
            <Path style={{
                fill: "#1C5B87",
            }}
                d="m343.2 508.7-107.6-54.4v108z" />

            <Path
                style={{
                    fill: "#6fc2e0",
                }}
                d="m343.2 400.7-107.6-54.4v108z"
            />
            <Path
                style={{
                    fill: "#814078",
                }}
                d="m343.2 292.7-107.6-54.4v108z"
            />
            <Path
                style={{
                    fill: "#f3e604",
                }}
                d="m343.2 184.7-107.6-54.4v108z"
            />
            <Path
                style={{
                    fill: "#df5b34",
                }}
                d="m235.6 130.3 107.6-53.6v108z"
            />
            <Path style={{
                fill: "#2B7CBB",
            }}
                d="m235.6 454.3 107.6-53.6v108z" />

            <Path
                style={{
                    fill: "#1b4956",
                }}
                d="m235.6 346.3 107.6-53.6v108z"
            />
            <Path
                style={{
                    fill: "#f5ab0c",
                }}
                d="m235.6 238.3 107.6-53.6v108z"
            />
            <Path
                style={{
                    fill: "#c6253a",
                }}
                d="m128 76.7 107.6-54.4v108z"
            />
            <Path
                style={{
                    fill: "#143f46",
                }}
                d="m128 508.7 107.6-54.4v108z"
            />
            <Path style={{
                fill: "#1C5B87",
            }} d="m128 400.7 107.6-54.4v108z" />
            <Path
                style={{
                    fill: "#1c4956",
                }}
                d="m128 292.7 107.6-54.4v108z"
            />
            <Path
                style={{
                    fill: "#6ebc4d",
                }}
                d="m128 184.7 107.6-54.4v108z"
            />
            <Path
                style={{
                    fill: "#c8d043",
                }}
                d="M235.6 130.3 128 76.7v108z"
            />
            <Path style={{
                fill: "#2B7CBB",
            }} d="M235.6 454.3 128 400.7v108z" />

            <Path
                style={{
                    fill: "#199c7a",
                }}
                d="M235.6 346.3 128 292.7v108z"
            />
            <Path
                style={{
                    fill: "#258649",
                }}
                d="M235.6 238.3 128 184.7v108z"
            />
        </Svg>
    );
}

export default Logo;
