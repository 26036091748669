import ReceiveScreen from "@screens/wallet/receive/ReceiveScreen";
import CurrencySettingsScreen from "@screens/wallet/settings/CurrencySettingsScreen";
import SelectCurrencyEnableScreen from "@screens/wallet/settings/SelectCurrencyEnableScreen";
import TransactionDetailScreen from "@screens/wallet/transaction/TransactionDetailScreen";
import WalletScreen from "@screens/wallet/WalletScreen";
import WalletsScreen from "@screens/wallet/WalletsScreen";
import { BuyNavigator } from "./BuyNavigator";
import { ExchangeNavigator } from "./ExchangeNavigator";
import { SendNavigator } from "./SendNavigator";
import NavigationInstantiator from "./helpers/NavigationInstantiator";
import { WalletConnectNavigator } from "./WalletConnectNavigator";
import SelectCurrencyWalletScreen from "@screens/wallet/select/SelectCurrencyWalletScreen";
import { SecurityKitNavigator } from "./SecurityKitNavigator";
import { ProfileNavigator } from "./ProfileNavigator";
import { NotificationsNavigator } from "./NotificationsNavigator";
import { WalletSettingsNavigator } from "./WalletSettingsNavigator";

const WalletNavigatorScreens = {
    WalletMain: {
        routeName: "WalletMain",
        title: "Wallet",
        component: WalletsScreen,
    },
    Wallet: {
        routeName: "Wallet",
        title: "Wallet",
        component: WalletScreen,
    },
    Receive: {
        routeName: "Receive",
        title: "Receive",
        component: ReceiveScreen,
    },
    Send: {
        routeName: "Send",
        title: "Send",
        component: SendNavigator,
    },
    TransactionDetail: {
        routeName: "TransactionDetail",
        title: "Transaction Detail",
        component: TransactionDetailScreen,
    },
    CurrencySettings: {
        routeName: "CurrencySettings",
        title: "Currency Settings",
        component: CurrencySettingsScreen,
    },
    WalletSettings: {
        routeName: "WalletSettings",
        title: "WalletSettings",
        component: WalletSettingsNavigator,
    },
    Exchange: {
        routeName: "Exchange",
        title: "Exchange",
        component: ExchangeNavigator,
    },
    Buy: {
        routeName: "Buy",
        title: "Buy",
        component: BuyNavigator,
    },
    SelectCurrencyEnable: {
        routeName: "SelectCurrencyEnable",
        title: "Select Currency",
        component: SelectCurrencyEnableScreen,
    },
    SelectCurrencyButtons: {
        routeName: "SelectCurrencyButtons",
        title: "Select Currency",
        component: SelectCurrencyWalletScreen,
    },
    WalletConnect: {
        routeName: "WalletConnect",
        title: "Wallet Connect",
        component: WalletConnectNavigator,
    },
    SecurityKit: {
        routeName: "SecurityKit",
        title: "SecurityKit",
        component: SecurityKitNavigator,
    },
    Notifications: {
        routeName: "Notifications",
        title: "Notifications",
        component: NotificationsNavigator,
    },
    Profile: {
        routeName: "Profile",
        title: "Profile",
        component: ProfileNavigator,
    },
};

const WalletNavigator = NavigationInstantiator(WalletNavigatorScreens);

export { WalletNavigator, WalletNavigatorScreens };
