import React, { Component } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { Header } from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import Currency from "@core/currencies/Currency";
import { WebView } from "react-native-webview";
import { IWalletConnectSession } from "@core/services/WalletConnectDataService";

interface Props {
    navigation: NavigationType;
    route: any;
}

interface State {
    source: string;
    walletSession: IWalletConnectSession;
    dappSession: IWalletConnectSession;
}

const { t } = i18n;

export class _WalletConnectWebViewScreen extends Component<Props, State> {
    protected currency: Currency;
    constructor(props: Props) {
        super(props);
        this.setStorage = this.setStorage.bind(this);
        this.state = {
            source: this.props.route.params?.session?.peerMeta?.url || "",
            walletSession: this.props.route.params?.session,
            dappSession: {
                connected: true,
                accounts: this.props.route.params?.session?.accounts,
                chainId: this.props.route.params?.session?.chainId,
                bridge: this.props.route.params?.session?.bridge,
                key: this.props.route.params?.session?.key,
                clientId: this.props.route.params?.session?.peerId,
                clientMeta: this.props.route.params?.session?.peerMeta,
                peerId: this.props.route.params?.session?.clientId,
                peerMeta: this.props.route.params?.session?.clientMeta,
                handshakeId: this.props.route.params?.session?.handshakeId,
                handshakeTopic: this.props.route.params?.session?.handshakeTopic,
            },
        };
    }

    onMessage = (payload) => {};

    setStorage() {
        const DAPPsession = this.state.dappSession;

        return `(function() {
            window.localStorage.setItem('walletconnect', '${JSON.stringify(DAPPsession).replace(/'/gi, " ")}');
            const walletconnectStorage = window.localStorage.getItem('walletconnect')
            window.ReactNativeWebView.postMessage(walletconnectStorage);
            window.localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');
           
          })();`;
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("wallet_connect")} type={HeaderType.Light} {...this.props} />

                {Platform.OS === "web" && (
                    <View style={{ flex: 1 }}>
                        <iframe src={this.state.source} style={{ border: "none" }} height={680} width={"100%"} />
                    </View>
                )}
                {Platform.OS !== "web" && (
                    <View style={{ flex: 1 }}>
                        <WebView
                            style={{ flex: 1 }}
                            source={{ uri: this.state.source }}
                            originWhitelist={["*"]}
                            javaScriptEnabled={true}
                            domStorageEnabled={true}
                            injectedJavaScript={this.setStorage()}
                            pullToRefreshEnabled={true}
                            onMessage={this.onMessage}
                        />
                    </View>
                )}
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});
const WalletConnectWebViewScreen = connect(mapStateToProps, mapDispatchToProps)(_WalletConnectWebViewScreen);
export default WalletConnectWebViewScreen;

const styles = StyleSheet.create({});
