import RegularText from "@base/RegularText";
import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import i18n from "@i18n/i18n";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import BoldText from "@base/BoldText";
import { getColorOpacity } from "@utils/helpers/global/global";
import ImageBase from "@components/image/ImageBase";
import { colors, settings } from "@styles/globalStyles";
import POAP from "@custom-types/POAP";
import { NavigationType } from "@custom-types/NavigationType";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { POAPsNavigatorScreens } from "@navigation/POAPsNavigator";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    poap: POAP;
}

interface State {}

const { t } = i18n;

export default class POAPCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPressPOAP = this.onPressPOAP.bind(this);

        this.state = {};
    }

    async onPressPOAP() {
        this.props.navigation.navigate(ProfileNavigatorScreens.POAPs.routeName, {
            screen: POAPsNavigatorScreens.POAP.routeName,
            params: { poapID: this.props.poap?.tokenId },
        });
    }

    render() {
        return (
            <>
                {this.props.poap && (
                    <View style={{ flex: 1 }} key={this.props.poap?.tokenId}>
                        <PressableBase onPress={this.onPressPOAP} style={styles.wrapper}>
                            <ImageBase
                                uri={this.props.poap?.event?.imageUrl || ""}
                                style={{
                                    height: 240,
                                    resizeMode: "cover",
                                }}
                            />

                            <View style={styles.flag}>
                                <RegularText fontSize={12}>POAP</RegularText>
                            </View>

                            <View style={styles.year}>
                                <RegularText fontSize={14}>{this.props.poap?.event?.year}</RegularText>
                            </View>

                            <View style={styles.title}>
                                <BoldText fontSize={12}>{trimHelper(this.props.poap?.event?.name, 18)}</BoldText>
                            </View>
                        </PressableBase>
                    </View>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: colors.shadow,
        //flex: 1,
        marginHorizontal: 5,
        marginVertical: 5,
        height: 240,
        borderRadius: settings.cardRadius,
        overflow: "hidden",
    },

    flag: {
        position: "absolute",
        top: 10,
        right: 0,
        opacity: 0.8,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 10,
        paddingRight: 8,
        paddingVertical: 4,
        borderTopLeftRadius: settings.cardRadius,
        borderBottomLeftRadius: settings.cardRadius,
    },

    title: {
        position: "absolute",
        bottom: 10,
        left: 10,
        paddingHorizontal: 6,
        backgroundColor: getColorOpacity(colors.grey, 0.6),
        borderRadius: 5,
    },

    year: {
        position: "absolute",
        top: 11,
        left: 10,
        paddingHorizontal: 8,
        paddingVertical: 2,
        backgroundColor: getColorOpacity(colors.complementary || colors.secondary, 0.6),
        borderRadius: 5,
    },
});
