import axios from "axios";
import Wallet from "@core/wallet/Wallet";
import { getEnv } from "@utils/helpers/global/global";
import { ApiService } from "./ApiService";
import Currency from "@core/currencies/Currency";

export default class DataService {
    async syncData(wallet: Wallet) {
   
        // try {
        //     const url = `${getEnv("API_URL")}digital-currencies/balances`;
        //     const result = await axios.get(url, { headers: await ApiService.getAuthHeaders() });
        //     const currenciesBalances = result?.data || [];

        //     currenciesBalances.map((c) => {
        //         let currency: Currency = Wallet.getInstance().findCurrencyById(c.digitalCurrencyId);
        //         if (currency) {
        //             currency.setBalance(c.balance);
        //         }
        //     });
        // } catch (e) {}
    }
}
