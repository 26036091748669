import { LoginNavigatorScreens } from "@navigation/LoginNavigator";
import ScannerSeedScreen from "@screens/scanner/ScannerSeedScreen";

export default class SeedScannerExtendedScreen extends ScannerSeedScreen {
    constructor(props) {
        super(props);
    }

    navigateToImport(data) {
        this.props.navigation.navigate(LoginNavigatorScreens.ImportSeed.routeName, { words: data });
    }
}
