import { Message } from "@custom-types/Message";
import { MessageAddType } from "@utils/helpers/chat/chat.helper";
import { runSagasAddMessage } from "@store/sagas/message.sagas";

export const ADD_MESSAGES = "ADD_MESSAGES";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

export const addMessage = (message: Message, addType: MessageAddType) => {
    const action = { type: ADD_MESSAGE, message, addType };
    runSagasAddMessage(action);
    return action;
};

export const addMessages = (messages: [{ [id: string]: Message }]) => {
    return { type: ADD_MESSAGES, messages };
    
};

export const updateMessage = (message: Message, id: string) => {
    return { type: UPDATE_MESSAGE, message, id };
};
