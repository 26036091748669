import NavigationInstantiator from "./helpers/NavigationInstantiator";

import POAPScreen from "@screens/poaps/POAPScreen";
import WebViewPOAPScreen from "@screens/poaps/WebViewPOAPScreen";

const POAPsNavigatorScreens = {
    POAP: {
        routeName: "POAP",
        title: "POAP",
        component: POAPScreen,
    },

    WebViewPOAPS: {
        routeName: "WebViewPOAPS",
        title: "WebViewPOAPS",
        component: WebViewPOAPScreen,
    },
};
const POAPsNavigator = NavigationInstantiator(POAPsNavigatorScreens);

export { POAPsNavigator, POAPsNavigatorScreens };
