import Container from "@base/Container";
import FlatListBase from "@base/FlatListBase";
import DappCard from "@components/cards/DappCard";
import { Header } from "@components/header/Header";
import SearchBase from "@components/search/SearchBase";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import DappsService from "@core/services/DappsService";
import { WalletConnectDappModel, DappModel } from "@custom-types/DappModel";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { DappsNavigatorScreens } from "@navigation/DappsNavigator";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import React, { Component } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    allDapps: Array<DappModel>;
}

interface State {
    search: string;
    isSearching: boolean;
    dapps: Array<DappModel>;
}

const { t } = i18n;

export class _DappsExplorerScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.loadMore = this.loadMore.bind(this);

        this.state = {
            search: "",
            isSearching: false,
            dapps: this.props.allDapps,
        };
    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps?.allDapps !== this.props.allDapps){
            this.setState({dapps: this.props.allDapps})
        }
    }

    renderDapp = ({ item }) => {
        return <DappCard  dapp={item} navigation={this.props.navigation}/>;
    };

   

    loadMore() {
        if (this.state.isSearching) return;
        DappsService.getInstance().getAllDapps();
    }

    onSearch(value) {
        this.setState({ search: value, isSearching: value?.length > 0 || false }, () => this.filterDapps());
    }

    filterDapps() {
        if (!this.state.isSearching) {
            this.setState({ dapps: this.props.allDapps });
            return;
        }
        const filteredDapps = this.props.allDapps.filter((dapp: DappModel) => {
            return dapp?.name?.toUpperCase().startsWith(this.state.search.toUpperCase());
        });

        this.setState({ dapps: filteredDapps });
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={"DAPPs"} type={HeaderType.Light} {...this.props} />

                <Container style={{ flex: 1 }}>
                    <SearchBase
                        containerStyle={{ marginVertical: 10 }}
                        value={this.state.search}
                        searching={false}
                        onSearch={(value) => this.onSearch(value)}
                    ></SearchBase>
                    <FlatListBase
                        data={this.state.dapps}
                        renderItem={this.renderDapp}
                        keyExtractor={(item) => item?._id || ""}
                        contentContainerStyle={{ paddingTop: 10, zIndex: 99 }}
                        initialNumToRender={6}
                        numColumns={2}
                        maxToRenderPerBatch={4}
                        showsHorizontalScrollIndicator={false}
                        onEndReached={this.loadMore}
                        onEndReachedThreshold={0.1}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        allDapps: state.dapps.allDapps,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const DappsExplorerScreen = connect(mapStateToProps, mapDispatchToProps)(_DappsExplorerScreen);

export default DappsExplorerScreen;
