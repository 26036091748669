import React, { Component } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import CurrencyCard from "@components/cards/CurrencyCard";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import i18n from "@i18n/i18n";
import SwitchCard from "@components/cards/SwitchCard";
import BoldText from "@base/BoldText";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import Row from "@base/Row";
import RegularText from "@base/RegularText";
import { WalletService } from "@core/services/WalletService";
import { trimHelper } from "@utils/helpers/chat/chat.helper";

interface Props {
    currencies: Array<Currency>;
    enabledCurrencies?: Array<Currency>;
    fiatCurrency: FiatCurrency;
    onPress: (item) => void;
}

interface State {
    udpate: boolean;
}

const { t } = i18n;

export default class SelectCurrencyComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            udpate: false,
        };
    }

    async switchSecurity(currency) {
        WalletService.getInstance().updateCurrencyEnabled({
            currency: currency,
            enabled: currency.enabled == null ? true : !currency.enabled,
        });

        setTimeout(() => {
            this.setState({ udpate: !this.state.udpate });
        }, 1000);
    }

    renderItem = ({ item }) =>
        item.isEnabled() ? (
            <CurrencyCard
                onPress={() => this.props.onPress(item)}
                currency={item}
                fiatCurrency={this.props.fiatCurrency}
            />
        ) : (
            <SwitchCard
                title={item.getPName()}
                left={
                    <Row style={{ alignItems: "center", alignContent: "center" }}>
                        <CurrencyIcon styles={{ marginRight: 15 }} currency={item} />

                        <View>
                            <Row style={{ alignItems: "center", alignContent: "center" }}>
                                <BoldText fontSize={20}>{item.getSymbol()}</BoldText>
                                <NetworkCurrencySymbol currency={item}></NetworkCurrencySymbol>
                            </Row>
                            <RegularText fontSize={14}>{trimHelper(item.getPName(), 20)}</RegularText>
                        </View>
                    </Row>
                }
                onToggle={() => this.switchSecurity(item)}
                isOn={item.isEnabled()}
            />
        );

    render() {
        return (
            <FlatList
                data={this.props.currencies}
                renderItem={this.renderItem}
                keyExtractor={(item) => item.getId()}
                contentContainerStyle={{ paddingBottom: 20 }}
                initialNumToRender={this.props.currencies.length}
                maxToRenderPerBatch={10}
                extraData={this.state.udpate}
            />
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,

        overflow: "hidden",
    },
});
