import React, { Component } from "react";
import { View, StyleSheet, Platform, AppState, Vibration } from "react-native";
import { colors } from "@styles/globalStyles";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { getLocalAuth, LocalAuthResponse } from "@utils/helpers/Auth.helper";
import i18n from "@i18n/i18n";
import Icon from "@components/icons";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import { FloatButton } from "@base/FloatButton";
import { Client } from "@custom-types/Client";
import AppStorage from "@utils/storage";
import store from "@store/index";
import { setAuthentication, setRedirection } from "@store/actions/global";
import Logo from "@assets/images/Logo";
import { VibrationType, vibrate } from "@utils/vibration/vibration.helper";

const { t } = i18n;

interface Props {
    navigation?: NavigationType;
    client: Client;
    redirection: string;
    activeAuthentication: boolean;
    priorityLoader: boolean;
    readyToRender: boolean;
}

interface State {
    showScreen: boolean;
    showAuth: boolean;
    showAlert: boolean;
    showErrorAuth: boolean;
    currentAppState: string;
    blockingTime: any;
}

export class _Authentication extends Component<Props, State> {
    appState: any;

    constructor(props: Props) {
        super(props);
        this.getAuth = this.getAuth.bind(this);
        this.setAuthentication = this.setAuthentication.bind(this);
        this.handleAppStateChange = this.handleAppStateChange.bind(this);

        this.appState = AppState.addEventListener("change", this.handleAppStateChange);

        this.state = {
            showScreen: false,
            showAuth: true,
            showAlert: false,
            showErrorAuth: false,
            currentAppState: AppState.currentState,
            blockingTime: null,
        };
    }

    async componentDidUpdate() {
        this.activeAuth();
    }

    async activeAuth() {
        if (this.props.activeAuthentication && !this.state.showScreen) {
            this.setState({ showScreen: true });
            await this.getAuth();
        }
    }

    async handleAppStateChange(nextAppState) {
        if (this.state.currentAppState == "background" && nextAppState == "active") {
            this.setState({ currentAppState: nextAppState });
            const authentication = await AppStorage.getItem("authentication");

            if (authentication) {
                const now = new Date().getTime();
                if (now - this.state.blockingTime > 30000) {
                    store.dispatch(setAuthentication(true));
                }
            }
            return;
        }

        if (this.state.currentAppState == "active" && nextAppState == "background") {
            this.setState({ blockingTime: new Date() });
        }

        if (this.state.currentAppState !== nextAppState) {
            this.setState({ currentAppState: nextAppState });
        }
    }

    async getAuth() {
        
        const localAuth = await getLocalAuth();
    
        switch (localAuth) {
            case LocalAuthResponse.AUTHENTICATED:
                this.setState({
                    showAuth: true,
                    showAlert: false,
                    showErrorAuth: false,
                });
                this.setAuthentication();
                break;
            case LocalAuthResponse.NO_AUTH:
                this.setState({
                    showAuth: false,
                    showAlert: true,
                    showErrorAuth: false,
                });
                break;
            case LocalAuthResponse.NOT_AUTHENTICATED:
                this.setState({
                    showAuth: false,
                    showAlert: false,
                    showErrorAuth: true,
                });
                break;
        }
    }
    
    setAuthentication() {
        vibrate(VibrationType.SUCCESS)
        store.dispatch(setAuthentication(false));
        this.setState({ showScreen: false });
    }

    render() {
        if (!this.state.showScreen) {
            return <></>;
        }
        return (
            <View style={styles.wrapper}>
                <ScreenWrapper>
                    <View
                        style={{
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: 90,
                            paddingVertical: 50,
                        }}
                    >
                        <View>
                            <Logo fill={colors.text} />
                        </View>
                    </View>
                    {this.state.showAuth && (
                        <View style={{ flex: 1 }}>
                            <View
                                style={{
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    marginTop: 20,
                                }}
                            >
                                <Icon name={"fingerprint"} size={40} color={colors.white} style={styles.icon} />
                            </View>

                            <BoldText align="center" color={colors.text} fontSize={40} style={{ marginTop: 40 }}>
                                {t("hi")}
                            </BoldText>
                            <RegularText
                                align="center"
                                fontSize={18}
                                style={{ marginTop: 10, paddingHorizontal: 20 }}
                                color={colors.text}
                            >
                                {t("local_auth")}
                            </RegularText>
                        </View>
                    )}

                    {this.state.showAlert && (
                        <View style={{ flex: 1 }}>
                            <View
                                style={{
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    marginTop: 20,
                                }}
                            >
                                <Icon name={"key"} size={40} color={colors.white} style={styles.icon} />
                            </View>

                            <BoldText align="center" color={colors.text} fontSize={30} style={{ marginTop: 40 }}>
                                {t("warning")}
                            </BoldText>
                            <RegularText
                                align="justify"
                                fontSize={18}
                                style={{ marginTop: 10, paddingHorizontal: 10 }}
                                color={colors.text}
                            >
                                {Platform.OS == "web" ? t("auth_warning_web") : t("auth_warning")}
                            </RegularText>
                            <FloatButton onPress={this.setAuthentication} iconName={"right"} />
                        </View>
                    )}

                    {this.state.showErrorAuth && (
                        <View style={{ flex: 1 }}>
                            <View
                                style={{
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    marginTop: 20,
                                }}
                            >
                                <Icon name={"password"} size={40} color={colors.white} style={styles.icon} />
                            </View>

                            <BoldText align="center" color={colors.text} fontSize={30} style={{ marginTop: 40 }}>
                                {t("warning")}
                            </BoldText>
                            <RegularText
                                align="center"
                                fontSize={18}
                                style={{ marginTop: 10, paddingHorizontal: 20 }}
                                color={colors.text}
                            >
                                {t("auth_error")}
                            </RegularText>
                            <FloatButton onPress={this.getAuth} iconName={"fingerprint"} />
                        </View>
                    )}
                </ScreenWrapper>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        redirection: state.global.redirection,
        activeAuthentication: state.global.activeAuthentication,
        priorityLoader: state.global.priorityLoader,
        readyToRender: state.global.readyToRender,
    };
};

const mapDispatchToProps = (dispatch) => ({
    
});

const Authentication = connect(mapStateToProps, mapDispatchToProps)(_Authentication);

export default Authentication;

const styles = StyleSheet.create({
    wrapper: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 99,
        backgroundColor: colors.gradientFrom,
    },
    icon: {
        padding: 20,
        backgroundColor: colors.secondary,
        borderRadius: 40,
        overflow: "hidden",
    },
});
