import SelectCurrencyScreen from "../../shared/SelectCurrencyScreen";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import { NavigationType } from "@custom-types/NavigationType";
import { ButtonActionsType } from "@components/wallet/WalletsButtons";
import store from "@store/index";
import { cleanOperation, selectCurrency, selectExchangeFrom } from "@store/actions/wallet";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { ExchangeNavigatorScreens } from "@navigation/ExchangeNavigator";
import { Route } from "@react-navigation/native";

interface Props {
    navigation: NavigationType;
    route: Route<string, { address?: string }>;
    selectedCurrency: string;
}

class _SelectCurrencyWalletScreen extends SelectCurrencyScreen {
    protected currency: Currency;

    constructor(props) {
        super(props);
    }

 

    onPress = (item) => {
        store.dispatch(selectCurrency(item.getId()));
        switch (this.props.buttonActionsType) {
            case ButtonActionsType.SEND:
                this.onPressSend();
                break;
            case ButtonActionsType.RECEIVE:
                this.props.navigation.navigate(WalletNavigatorScreens.Receive.routeName);
                break;
            case ButtonActionsType.EXCHANGE:
                this.onPressExchange(item);
                break;
            case ButtonActionsType.BUY:
                this.onPressBuy();
                break;
            case ButtonActionsType.SELL:
                this.onPressBuy();
                break;
            case ButtonActionsType.WALLETCONNECT:
                this.props.navigation.navigate(WalletNavigatorScreens.WalletConnect.routeName);
                break;
        }
    };

    onPressSend() {
        store.dispatch(cleanOperation());
        this.props.navigation.navigate(WalletNavigatorScreens.Send.routeName, {
            screen: "SendMain",
            params: { address: this.props.route?.params?.address || "", showFindClient: true },
        });
    }

    onPressBuy() {
        store.dispatch(cleanOperation());
        this.props.navigation.navigate(WalletNavigatorScreens.Buy.routeName);
    }

    onPressExchange = (item) => {
        store.dispatch(selectExchangeFrom({ currency: Wallet.getInstance().findCurrencyById(item.getId()) }));
        this.props.navigation.navigate(ExchangeNavigatorScreens.Exchange.routeName);
    };
}

const mapStateToProps = (state) => {
    let currencies = Wallet.getInstance()
        .getCurrencies()
        .filter((c: Currency) => {
            if (state.wallet.buttonActionsType == ButtonActionsType.WALLETCONNECT) {
                return c.hasWalletConnect();
            }
            if (state.wallet.buttonActionsType == ButtonActionsType.BUY) {
                return c.isBuyable();
            }
            if (state.wallet.buttonActionsType == ButtonActionsType.SELL) {
                return c.isSellable();
            }
            if (state.wallet.buttonActionsType == ButtonActionsType.EXCHANGE) {
                return c.isExchangeAvailable();
            } else {
                return true;
            }
        });

    return {
        buttonActionsType: state.wallet.buttonActionsType,
        currencies: currencies,
        fiatCurrency: state.wallet.fiatCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SelectCurrencyWalletScreen = connect(mapStateToProps, mapDispatchToProps)(_SelectCurrencyWalletScreen);

export default SelectCurrencyWalletScreen;
