import * as Crypto from 'expo-crypto';


export class Shamir {
    private static instance: Shamir;
    private defaults;
    private config;
    private preGenPadding;
    private runCSPRNGTest;
    private CSPRNGTypes;


    constructor(){
        this.init();
    }

    static getInstance() {
        if (!Shamir.instance){
            Shamir.instance = new Shamir()
        }
        return Shamir.instance;
    }

    reset() {
        this.defaults = {
            bits: 8, // default number of bits
            radix: 16, // work with HEX by default
            minBits: 3,
            maxBits: 20, // this permits 1,048,575 shares, though going this high is NOT recommended in JS!
            bytesPerChar: 2,
            maxBytesPerChar: 6, // Math.pow(256,7) > Math.pow(2,53)

            // Primitive polynomials (in decimal form) for Galois Fields GF(2^n), for 2 <= n <= 30
            // The index of each term in the array corresponds to the n for that polynomial
            // i.e. to get the polynomial for n=16, use primitivePolynomials[16]
            primitivePolynomials: [
                null,
                null,
                1,
                3,
                3,
                5,
                3,
                3,
                29,
                17,
                9,
                5,
                83,
                27,
                43,
                3,
                45,
                9,
                39,
                39,
                9,
                5,
                3,
                33,
                27,
                9,
                71,
                39,
                9,
                5,
                83
            ]
        }
        this.config = {}
        this.preGenPadding = new Array(1024).join("0") // Pre-generate a string of 1024 0's for use by this.padLeft().
        this.runCSPRNGTest = true

        this.CSPRNGTypes = [
            "nodeCryptoRandomBytes",
            "browserCryptoGetRandomValues",
            "testRandom"
        ]
    }

    isSetRNG() {
        if (this.config && this.config.rng && typeof this.config.rng === "function") {
            return true
        }

        return false
    }

    // Pads a string `str` with zeros on the left so that its length is a multiple of `bits`
    padLeft(str, multipleOfBits?) {
        var missing

        if (multipleOfBits === 0 || multipleOfBits === 1) {
            return str
        }

        if (multipleOfBits && multipleOfBits > 1024) {
            throw new Error(
                "Padding must be multiples of no larger than 1024 bits."
            )
        }

        multipleOfBits = multipleOfBits || this.config.bits

        if (str) {
            missing = str.length % multipleOfBits
        }

        if (missing) {
            return (this.preGenPadding + str).slice(
                -(multipleOfBits - missing + str.length)
            )
        }

        return str
    }

    hex2bin(str) {
        var bin = "",
            num,
            i

        for (i = str.length - 1; i >= 0; i--) {
            num = parseInt(str[i], 16)

            if (isNaN(num)) {
                throw new Error("Invalid hex character.")
            }

            bin = this.padLeft(num.toString(2), 4) + bin
        }
        return bin
    }

    bin2hex(str) {
        var hex = "",
            num,
            i

        str = this.padLeft(str, 4)

        for (i = str.length; i >= 4; i -= 4) {
            num = parseInt(str.slice(i - 4, i), 2)
            if (isNaN(num)) {
                throw new Error("Invalid binary character.")
            }
            hex = num.toString(16) + hex
        }

        return hex
    }

    getRNG(type ='nodeCryptoRandomBytes') {
        const that = this;
        function construct(bits, arr, radix, size) {
            var i = 0,
                len,
                str = "",
                parsedInt

            if (arr) {
                len = arr.length - 1
            }

            while (i < len || str.length < bits) {
                // convert any negative nums to positive with Math.abs()
                parsedInt = Math.abs(parseInt(arr[i], radix))
                str = str + that.padLeft(parsedInt.toString(2), size)
                i++
            }

            str = str.substr(-bits)

            // return null so this result can be re-processed if the result is all 0's.
            if ((str.match(/0/g) || []).length === str.length) {
                return null
            }

            return str
        }

        function nodeCryptoRandomBytes(bits) {
            var buf,
                bytes,
                radix,
                size,
                str = null

            radix = 16
            size = 4
            bytes = Math.ceil(bits / 8)

            while (str === null) {
                buf = Crypto.getRandomBytes(bytes)
                str = construct(bits, buf.toString("hex"), radix, size)
            }

            return str
        }

        this.config.typeCSPRNG = type
        return nodeCryptoRandomBytes
    }

    splitNumStringToIntArray(str, padLength?) {
        var parts = [],
            i

        if (padLength) {
            str = this.padLeft(str, padLength)
        }

        for (i = str.length; i > this.config.bits; i -= this.config.bits) {
            parts.push(parseInt(str.slice(i - this.config.bits, i), 2))
        }

        parts.push(parseInt(str.slice(0, i), 2))

        return parts
    }


    horner(x, coeffs) {
        var logx = this.config.logs[x],
            fx = 0,
            i

        for (i = coeffs.length - 1; i >= 0; i--) {
            if (fx !== 0) {
                fx =
                    this.config.exps[(logx + this.config.logs[fx]) % this.config.maxShares] ^
                    coeffs[i]
            } else {
                fx = coeffs[i]
            }
        }

        return fx
    }

    lagrange(at, x, y) {
        var sum = 0,
            len,
            product,
            i,
            j

        for (i = 0, len = x.length; i < len; i++) {
            if (y[i]) {
                product = this.config.logs[y[i]]

                for (j = 0; j < len; j++) {
                    if (i !== j) {
                        if (at === x[j]) {
                            // happens when computing a share that is in the list of shares used to compute it
                            product = -1 // fix for a zero product term, after which the sum should be sum^0 = sum, not sum^1
                            break
                        }
                        product =
                            (product +
                                this.config.logs[at ^ x[j]] -
                                this.config.logs[x[i] ^ x[j]] +
                                this.config.maxShares) %
                            this.config.maxShares // to make sure it's not negative
                    }
                }

                // though exps[-1] === undefined and undefined ^ anything = anything in
                // chrome, this behavior may not hold everywhere, so do the check
                sum = product === -1 ? sum : sum ^ this.config.exps[product]
            }
        }

        return sum
    }

    getShares(secret, numShares, threshold) {
        var shares = [],
            coeffs = [secret],
            i,
            len

        for (i = 1; i < threshold; i++) {
            coeffs[i] = parseInt(this.config.rng(this.config.bits), 2)
        }

        for (i = 1, len = numShares + 1; i < len; i++) {
            shares[i - 1] = {
                x: i,
                y: this.horner(i, coeffs)
            }
        }

        return shares
    }

    constructPublicShareString(bits, id, data) {
        var bitsBase36, idHex, idMax, idPaddingLen, newShareString

        id = parseInt(id, this.config.radix)
        bits = parseInt(bits, 10) || this.config.bits
        bitsBase36 = bits.toString(36).toUpperCase()
        idMax = Math.pow(2, bits) - 1
        idPaddingLen = idMax.toString(this.config.radix).length
        idHex = this.padLeft(id.toString(this.config.radix), idPaddingLen)

        if (typeof id !== "number" || id % 1 !== 0 || id < 1 || id > idMax) {
            throw new Error(
                "Share id must be an integer between 1 and " +
                idMax +
                ", inclusive."
            )
        }

        newShareString = bitsBase36 + idHex + data

        return newShareString
    }

    
    init (bits?, rngType?) {
        var logs = [],
            exps = [],
            x = 1,
            primitive,
            i

        // reset all config back to initial state
        this.reset()

        if (
            bits &&
            (typeof bits !== "number" ||
                bits % 1 !== 0 ||
                bits < this.defaults.minBits ||
                bits > this.defaults.maxBits)
        ) {
            throw new Error(
                "Number of bits must be an integer between " +
                this.defaults.minBits +
                " and " +
                this.defaults.maxBits +
                ", inclusive."
            )
        }

        if (rngType && this.CSPRNGTypes.indexOf(rngType) === -1) {
            throw new Error("Invalid RNG type argument : '" + rngType + "'")
        }

        this.config.radix = this.defaults.radix
        this.config.bits = bits || this.defaults.bits
        this.config.size = Math.pow(2, this.config.bits)
        this.config.maxShares = this.config.size - 1

        // Construct the exp and log tables for multiplication.
        primitive = this.defaults.primitivePolynomials[this.config.bits]

        for (i = 0; i < this.config.size; i++) {
            exps[i] = x
            logs[x] = i
            x = x << 1 // Left shift assignment
            if (x >= this.config.size) {
                x = x ^ primitive // Bitwise XOR assignment
                x = x & this.config.maxShares // Bitwise AND assignment
            }
        }

        this.config.logs = logs
        this.config.exps = exps

        if (rngType) {
            this.setRNG(rngType)
        }

        if (!this.isSetRNG()) {
            this.setRNG()
        }

        if (
            !this.isSetRNG() ||
            !this.config.bits ||
            !this.config.size ||
            !this.config.maxShares ||
            !this.config.logs ||
            !this.config.exps ||
            this.config.logs.length !== this.config.size ||
            this.config.exps.length !== this.config.size
        ) {
            throw new Error("Initialization failed.")
        }
    };

    combine(shares, at?){
        var i,
            j,
            len,
            len2,
            result = "",
            setBits,
            share,
            splitShare,
            x = [],
            y = []

        at = at || 0

        for (i = 0, len = shares.length; i < len; i++) {
            share = this.extractShareComponents(shares[i])

            // All shares must have the same bits settings.
            if (setBits === undefined) {
                setBits = share.bits
            } else if (share.bits !== setBits) {
                throw new Error(
                    "Mismatched shares: Different bit settings."
                )
            }

            // Reset everything to the bit settings of the shares.
            if (this.config.bits !== setBits) {
                this.init(setBits)
            }

            if (x.indexOf(share.id) === -1) {
                x.push(share.id)
                splitShare = this.splitNumStringToIntArray(this.hex2bin(share.data))
                for (j = 0, len2 = splitShare.length; j < len2; j++) {
                    y[j] = y[j] || []
                    y[j][x.length - 1] = splitShare[j]
                }
            }
        }

        // Extract the secret from the 'rotated' share data and return a
        // string of Binary digits which represent the secret directly. or in the
        // case of a newShare() return the binary string representing just that
        // new share.
        for (i = 0, len = y.length; i < len; i++) {
            result = this.padLeft(this.lagrange(at, x, y[i]).toString(2)) + result
        }

        // If 'at' is non-zero combine() was called from newShare(). In this
        // case return the result (the new share data) directly.
        //
        // Otherwise find the first '1' which was added in the share() function as a padding marker
        // and return only the data after the padding and the marker. Convert this Binary string
        // to hex, which represents the final secret result (which can be converted from hex back
        // to the original string in user space using `hex2str()`).
        return this.bin2hex(
            at >= 1 ? result : result.slice(result.indexOf("1") + 1)
        )
    }

    getConfig() {
        var obj: any = {}
        obj.radix = this.config.radix
        obj.bits = this.config.bits
        obj.maxShares = this.config.maxShares
        obj.hasCSPRNG = this.isSetRNG()
        obj.typeCSPRNG = this.config.typeCSPRNG
        return obj
    }

    extractShareComponents(share){
        var bits,
            id,
            idLen,
            max,
            obj: any = {},
            regexStr,
            shareComponents

        // Extract the first char which represents the bits in Base 36
        bits = parseInt(share.substr(0, 1), 36)

        if (
            bits &&
            (typeof bits !== "number" ||
                bits % 1 !== 0 ||
                bits < this.defaults.minBits ||
                bits > this.defaults.maxBits)
        ) {
            throw new Error(
                "Invalid share : Number of bits must be an integer between " +
                this.defaults.minBits +
                " and " +
                this.defaults.maxBits +
                ", inclusive."
            )
        }

        // calc the max shares allowed for given bits
        max = Math.pow(2, bits) - 1

        // Determine the ID length which is variable and based on the bit count.
        idLen = (Math.pow(2, bits) - 1).toString(this.config.radix).length

        // Extract all the parts now that the segment sizes are known.
        regexStr =
            "^([a-kA-K3-9]{1})([a-fA-F0-9]{" + idLen + "})([a-fA-F0-9]+)$"
        shareComponents = new RegExp(regexStr).exec(share)

        // The ID is a Hex number and needs to be converted to an Integer
        if (shareComponents) {
            id = parseInt(shareComponents[2], this.config.radix)
        }

        if (typeof id !== "number" || id % 1 !== 0 || id < 1 || id > max) {
            throw new Error(
                "Invalid share : Share id must be an integer between 1 and " +
                this.config.maxShares +
                ", inclusive."
            )
        }

        if (shareComponents && shareComponents[3]) {
            obj.bits = bits
            obj.id = id
            obj.data = shareComponents[3]
            return obj
        }

        throw new Error("The share data provided is invalid : " + share)
    }


    setRNG(rng?) {
        var errPrefix = "Random number generator is invalid ",
            errSuffix =
                " Supply an CSPRNG of the form function(bits){} that returns a string containing 'bits' number of random 1's and 0's."

        if (
            rng &&
            typeof rng === "string" &&
            this.CSPRNGTypes.indexOf(rng) === -1
        ) {
            throw new Error("Invalid RNG type argument : '" + rng + "'")
        }

        // If RNG was not specified at all,
        // try to pick one appropriate for this env.
        if (!rng) {
            rng = this.getRNG()
        }

        // If `rng` is a string, try to forcibly
        // set the RNG to the type specified.
        if (rng && typeof rng === "string") {
            rng = this.getRNG(rng)
        }

        if (this.runCSPRNGTest) {
            if (rng && typeof rng !== "function") {
                throw new Error(errPrefix + "(Not a function)." + errSuffix)
            }

            if (rng && typeof rng(this.config.bits) !== "string") {
                throw new Error(
                    errPrefix + "(Output is not a string)." + errSuffix
                )
            }

            if (rng && !parseInt(rng(this.config.bits), 2)) {
                throw new Error(
                    errPrefix +
                    "(Binary string output not parseable to an Integer)." +
                    errSuffix
                )
            }

            if (rng && rng(this.config.bits).length > this.config.bits) {
                throw new Error(
                    errPrefix +
                    "(Output length is greater than this.config.bits)." +
                    errSuffix
                )
            }

            if (rng && rng(this.config.bits).length < this.config.bits) {
                throw new Error(
                    errPrefix +
                    "(Output length is less than this.config.bits)." +
                    errSuffix
                )
            }
        }

        this.config.rng = rng
        return true
    }

    // Converts a given UTF16 character string to the HEX representation.
    // Each character of the input string is represented by
    // `bytesPerChar` bytes in the output string which defaults to 2.
    str2hex (str, bytesPerChar?) {
        var hexChars,
            max,
            out = "",
            neededBytes,
            num,
            i,
            len

        if (typeof str !== "string") {
            throw new Error("Input must be a character string.")
        }

        if (!bytesPerChar) {
            bytesPerChar = this.defaults.bytesPerChar
        }

        if (
            typeof bytesPerChar !== "number" ||
            bytesPerChar < 1 ||
            bytesPerChar > this.defaults.maxBytesPerChar ||
            bytesPerChar % 1 !== 0
        ) {
            throw new Error(
                "Bytes per character must be an integer between 1 and " +
                this.defaults.maxBytesPerChar +
                ", inclusive."
            )
        }

        hexChars = 2 * bytesPerChar
        max = Math.pow(16, hexChars) - 1

        for (i = 0, len = str.length; i < len; i++) {
            num = str[i].charCodeAt(0)

            if (isNaN(num)) {
                throw new Error("Invalid character: " + str[i])
            }

            if (num > max) {
                neededBytes = Math.ceil(Math.log(num + 1) / Math.log(256))
                throw new Error(
                    "Invalid character code (" +
                    num +
                    "). Maximum allowable is 256^bytes-1 (" +
                    max +
                    "). To convert this character, use at least " +
                    neededBytes +
                    " bytes."
                )
            }

            out = this.padLeft(num.toString(16), hexChars) + out
        }
        return out
    }

    // Converts a given HEX number string to a UTF16 character string.
    hex2str (str, bytesPerChar?) {
        var hexChars,
            out = "",
            i,
            len

        if (typeof str !== "string") {
            throw new Error("Input must be a hexadecimal string.")
        }
        bytesPerChar = bytesPerChar || this.defaults.bytesPerChar

        if (
            typeof bytesPerChar !== "number" ||
            bytesPerChar % 1 !== 0 ||
            bytesPerChar < 1 ||
            bytesPerChar > this.defaults.maxBytesPerChar
        ) {
            throw new Error(
                "Bytes per character must be an integer between 1 and " +
                this.defaults.maxBytesPerChar +
                ", inclusive."
            )
        }

        hexChars = 2 * bytesPerChar

        str = this.padLeft(str, hexChars)

        for (i = 0, len = str.length; i < len; i += hexChars) {
            out =
                String.fromCharCode(
                    parseInt(str.slice(i, i + hexChars), 16)
                ) + out
        }

        return out
    }

    // Generates a random bits-length number string using the PRNG
    random (bits) {
        if (
            typeof bits !== "number" ||
            bits % 1 !== 0 ||
            bits < 2 ||
            bits > 65536
        ) {
            throw new Error(
                "Number of bits must be an Integer between 1 and 65536."
            )
        }

        return this.bin2hex(this.config.rng(bits))
    }

    // Divides a `secret` number String str expressed in radix `inputRadix` (optional, default 16)
    // into `numShares` shares, each expressed in radix `outputRadix` (optional, default to `inputRadix`),
    // requiring `threshold` number of shares to reconstruct the secret.
    // Optionally, zero-pads the secret to a length that is a multiple of padLength before sharing.
    share (secret, numShares, threshold, padLength?) {
        var neededBits,
            subShares,
            x = new Array(numShares),
            y = new Array(numShares),
            i,
            j,
            len

        // Security:
        // For additional security, pad in multiples of 128 bits by default.
        // A small trade-off in larger share size to help prevent leakage of information
        // about small-ish secrets and increase the difficulty of attacking them.
        padLength = padLength || 128

        if (typeof secret !== "string") {
            throw new Error("Secret must be a string.")
        }

        if (
            typeof numShares !== "number" ||
            numShares % 1 !== 0 ||
            numShares < 2
        ) {
            throw new Error(
                "Number of shares must be an integer between 2 and 2^bits-1 (" +
                this.config.maxShares +
                "), inclusive."
            )
        }

        if (numShares > this.config.maxShares) {
            neededBits = Math.ceil(Math.log(numShares + 1) / Math.LN2)
            throw new Error(
                "Number of shares must be an integer between 2 and 2^bits-1 (" +
                this.config.maxShares +
                "), inclusive. To create " +
                numShares +
                " shares, use at least " +
                neededBits +
                " bits."
            )
        }

        if (
            typeof threshold !== "number" ||
            threshold % 1 !== 0 ||
            threshold < 2
        ) {
            throw new Error(
                "Threshold number of shares must be an integer between 2 and 2^bits-1 (" +
                this.config.maxShares +
                "), inclusive."
            )
        }

        if (threshold > this.config.maxShares) {
            neededBits = Math.ceil(Math.log(threshold + 1) / Math.LN2)
            throw new Error(
                "Threshold number of shares must be an integer between 2 and 2^bits-1 (" +
                this.config.maxShares +
                "), inclusive.  To use a threshold of " +
                threshold +
                ", use at least " +
                neededBits +
                " bits."
            )
        }

        if (threshold > numShares) {
            throw new Error(
                "Threshold number of shares was " +
                threshold +
                " but must be less than or equal to the " +
                numShares +
                " shares specified as the total to generate."
            )
        }

        if (
            typeof padLength !== "number" ||
            padLength % 1 !== 0 ||
            padLength < 0 ||
            padLength > 1024
        ) {
            throw new Error(
                "Zero-pad length must be an integer between 0 and 1024 inclusive."
            )
        }

        secret = "1" + this.hex2bin(secret) // prepend a 1 as a marker so that we can preserve the correct number of leading zeros in our secret
        secret = this.splitNumStringToIntArray(secret, padLength)

        for (i = 0, len = secret.length; i < len; i++) {
            subShares = this.getShares(secret[i], numShares, threshold)
            for (j = 0; j < numShares; j++) {
                x[j] = x[j] || subShares[j].x.toString(this.config.radix)
                y[j] = this.padLeft(subShares[j].y.toString(2)) + (y[j] || "")
            }
        }

        for (i = 0; i < numShares; i++) {
            x[i] = this.constructPublicShareString(
                this.config.bits,
                x[i],
                this.bin2hex(y[i])
            )
        }

        return x
    }

    // Generate a new share with id `id` (a number between 1 and 2^bits-1)
    // `id` can be a Number or a String in the default radix (16)
    newShare (id, shares) {
        var share, radid

        if (id && typeof id === "string") {
            id = parseInt(id, this.config.radix)
        }

        radid = id.toString(this.config.radix)

        if (id && radid && shares && shares[0]) {
            share = this.extractShareComponents(shares[0])
            return this.constructPublicShareString(
                share.bits,
                radid,
                this.combine(shares, id)
            )
        }

        throw new Error(
            "Invalid 'id' or 'shares' Array argument to newShare()."
        )
    }
    
}


