import AppStorage from ".";

export class ObjectStorage {
    static instance: ObjectStorage;
    public oldKey: string;
    public key: string;
    public isSecure: boolean;

    static getInstance(constructor: { new(): ObjectStorage }) {
        if (!this.instance) {
            this.instance = new constructor();
        }
      
        return this.instance;
    }

    get() {
    
        return AppStorage.getItem(this.key, { isSecure: this.isSecure, oldKey: this.oldKey });
    }

    set(value: any) {
        return AppStorage.setItem(this.key, value, { isSecure: this.isSecure });
    }

    remove () {
        return AppStorage.removeItem(this.key, { isSecure: this.isSecure });
    }
}