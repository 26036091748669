import React, { Component } from "react";
import { FlatList, StyleSheet,  View } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import i18n from "@i18n/i18n";
import RegularText from "@base/RegularText";
import Card from "@base/Card";
import Icon from "@components/icons";
import Row from "@base/Row";
import BoldText from "@base/BoldText";
import { colors } from "@styles/globalStyles";
import moment from "moment";
import store from "@store/index";
import { showModalBottom } from "@store/actions/global";
import { TransactionsCoincaex, equateCurrenciesFromCoincaex } from "../helper/coincaex.helper";
import * as Clipboard from "expo-clipboard";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    selectedCurrency: string;
    sliceVersion?: boolean;
    transactions: Array<TransactionsCoincaex>;
}

interface State {}

const { t } = i18n;

export class _TransactionHistoryComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            transactions: [],
        };
    }

    copyToClipboard = async (value) => {
        await Clipboard.setStringAsync(value);
    };

    modalContent = (item, currency) => {
        return (
            <View style={{ flex: 1 }}>
                <View style={{ marginBottom: 10, paddingBottom: 10 }}>
                    <Row style={{ alignItems: "center" }}>
                        <CurrencyIcon styles={{ marginRight: 15 }} currency={currency} />

                        <View>
                            <Row style={{ alignItems: "center" }}>
                                <BoldText fontSize={20}>
                                    {`${item.transactionCryptoAmount} ${currency.getPName()}`}
                                </BoldText>
                                <NetworkCurrencySymbol currency={currency}></NetworkCurrencySymbol>
                            </Row>
                            <RegularText fontSize={14} color={colors.grey}>
                                {item.transactionType == "Compra"
                                    ? `${t("buys")} ${currency.getPName()}`
                                    : `${t("sale")} ${currency.getPName()}`}
                            </RegularText>
                        </View>
                    </Row>
                </View>
                <View style={styles.container}>
                    <RegularText style={{ marginBottom: 5 }}>{t("transaction_detail")}</RegularText>
                    <RegularText fontSize={14} color={colors.grey}>
                        {t("amount")} {item.transactionAmount} {"USD (aprox)"}
                    </RegularText>
                    <RegularText fontSize={14} color={colors.grey}>
                        {moment(item.transactionTimeStamp).format("LL")}
                    </RegularText>
                </View>
                <View style={styles.container}>
                    <RegularText style={{ marginBottom: 5 }}>{t("bank")}</RegularText>
                    <RegularText fontSize={14} color={colors.grey}>
                        {item.bankAccountName}
                    </RegularText>
                    <RegularText fontSize={14} color={colors.grey}>
                        {item.bankName}
                    </RegularText>
                    <RegularText fontSize={14} color={colors.grey}>
                        {item.bankAccountNumber}
                    </RegularText>
                    <RegularText fontSize={14} color={colors.grey}>
                        {`${item.bankAccountCurrency} ${item.bankAccountType}`}
                    </RegularText>
                </View>
                <View style={styles.container}>
                    <RegularText style={{ marginBottom: 5 }}>{t("status")}</RegularText>
                    <RegularText fontSize={14} color={colors.grey}>
                        {item.transactionStatus}
                    </RegularText>
                </View>
                <View style={styles.container}>
                    <RegularText style={{ marginBottom: 5 }}>{t("transaction_id")}</RegularText>
                    <PressableBase onPress={() => this.copyToClipboard(item.id)} style={{ paddingVertical: 2 }}>
                        <Row style={{ alignItems: "baseline" }}>
                            <RegularText fontSize={14} color={colors.grey}>
                                {item.id}
                            </RegularText>
                            <Icon size={12} name="content-copy" color={colors.white} style={{ marginLeft: 10 }} />
                        </Row>
                    </PressableBase>
                </View>
            </View>
        );
    };

    renderItem = ({ item }) => {
        const currency: Currency = equateCurrenciesFromCoincaex(
            item.transactionCryptoCurrency,
            item.transactionType == "Compra"
                ? item.transactionCryptoNetwork
                : item.transactionCryptoNetwork?.split(" ")?.[0],
        );

        return (
            currency && (
                <PressableBase
                    onPress={() =>
                        store.dispatch(
                            showModalBottom({
                                modalContent: this.modalContent(item, currency),
                            }),
                        )
                    }
                >
                    <Card
                        style={{ alignItems: "center" }}
                        icon={<CurrencyIcon styles={{ marginRight: 15 }} currency={currency} />}
                        left={
                            <View>
                                <Row style={{ alignItems: "center" }}>
                                    <BoldText fontSize={14}>
                                        {item.transactionType == "Compra"
                                            ? `${t("buys")} ${currency.getPName()}`
                                            : `${t("sale")} ${currency.getPName()}`}
                                    </BoldText>
                                    <NetworkCurrencySymbol currency={currency}></NetworkCurrencySymbol>
                                </Row>
                                <RegularText fontSize={12} color={colors.grey}>
                                    {moment(item.transactionTimeStamp).format("LL")}
                                </RegularText>
                            </View>
                        }
                        right={
                            <BoldText fontSize={14}>
                                {trimHelper(item.transactionCryptoAmount.toString(), 10)} {currency.getPName()}
                            </BoldText>
                        }
                    ></Card>
                </PressableBase>
            )
        );
    };

    render() {
        return (
            <FlatList
                data={this.props.sliceVersion ? this.props.transactions.slice(0, 2) : this.props.transactions}
                scrollEnabled={this.props.sliceVersion ? false : true}
                renderItem={this.renderItem}
                keyExtractor={(item: any) => item.id}
                initialNumToRender={10}
                maxToRenderPerBatch={10}
                showsHorizontalScrollIndicator={false}
                style={{ marginTop: 5 }}
                ListEmptyComponent={
                    <Card style={{ alignItems: "flex-start", marginTop: 10 }}>
                        <RegularText fontSize={14} style={{ textAlign: "left" }}>
                            {t("empty_transactions")}
                        </RegularText>
                    </Card>
                }
            />
        );
    }
}

const styles = StyleSheet.create({
    container: {
        borderTopColor: colors.shadow,
        borderTopWidth: 0.5,
        marginBottom: 10,
        paddingVertical: 10,
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const TransactionHistoryComponent = connect(mapStateToProps, mapDispatchToProps)(_TransactionHistoryComponent);

export default TransactionHistoryComponent;
