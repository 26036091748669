import React, { Component } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import store from "@store/index";
import i18n from "@i18n/i18n";
import SwitchCard from "@components/cards/SwitchCard";
import BoldText from "@base/BoldText";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import Card from "@base/Card";
import FiatCurrencyCard from "@components/cards/FiatCurrencyCard";
import PressableBase from "@base/PressableBase";
import { setExperienceCurrency } from "@store/actions/experiences.actions";
import { hideModalBottom } from "@store/actions/global";
import RegularText from "@base/RegularText";
import Wallet from "@core/wallet/Wallet";
import { colors } from "@styles/globalStyles";
import Row from "@base/Row";
import { ExperienceCurrency } from "@custom-types/ExpercienceModel";
import SemiBoldText from "@base/SemiBold";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import { WalletService } from "@core/services/WalletService";

interface Props {
    currencies?: Array<ExperienceCurrency>;
    fiatCurrency?: Array<ExperienceCurrency>;
    onPress?: any;
}

interface State { }

const { t } = i18n;

export default class SelectCurrencyModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    async switchSecurity(currency) {
        WalletService.getInstance().updateCurrencyEnabled({
            currency: currency,
            enabled: currency.enabled == null ? true : !currency.enabled,
        });
    }

    renderCurrency = ({ item }) => {
        const currency = Wallet.getInstance().findCurrencyById(item.currencyId);

        return (
            currency &&
            (currency.isEnabled() ? (
                <Card
                    onPress={() => this.handleOnPress(currency, item.gatewayId)}
                    style={{ alignItems: "center" }}
                    icon={<CurrencyIcon styles={{ marginRight: 15 }} currency={currency} />}
                    left={
                        currency && (
                            <View>
                                <Row style={{ alignItems: "center" }}>
                                    <SemiBoldText>{currency?.getSymbol()}</SemiBoldText>
                                    <NetworkCurrencySymbol currency={currency}></NetworkCurrencySymbol>
                                </Row>
                                <RegularText
                                    style={{ textTransform: "capitalize", marginTop: 2 }}
                                    fontSize={14}
                                    color={colors.grey}
                                >
                                    {currency?.getName()}
                                </RegularText>
                            </View>
                        )
                    }
                />
            ) : (
                <SwitchCard
                    title={currency.getPName()}
                    left={
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <CurrencyIcon styles={{ marginRight: 15 }} currency={currency} />
                            <BoldText fontSize={20}>{currency.getPName()}</BoldText>
                            <NetworkCurrencySymbol currency={currency}></NetworkCurrencySymbol>
                        </View>
                    }
                    onToggle={() => this.switchSecurity(currency)}
                    isOn={currency.isEnabled()}
                />
            ))
        );
    };

    renderFiat = ({ item }) => {
        const currency = Wallet.getInstance().findFiatCurrencyById(item.currencyId);

        return (
            item && (
                <PressableBase onPress={() => this.handleOnPress(currency, item.gatewayId)}>
                    <FiatCurrencyCard fiatCurrencyId={item.currencyId} />
                </PressableBase>
            )
        );
    };

    handleOnPress = (currency: Currency | FiatCurrency, gatewayId: string) => {
        store.dispatch(setExperienceCurrency({ currency, gatewayId }));
        store.dispatch(hideModalBottom());
    };

    render() {
        return (
            <>
                {this.props.currencies && this.props.currencies.length > 0 && (
                    <FlatList
                        data={this.props.currencies}
                        renderItem={this.renderCurrency}
                        keyExtractor={(item) => item.currencyId}
                    />
                )}
                {this.props.fiatCurrency && this.props.fiatCurrency.length > 0 && (
                    <FlatList
                        data={this.props.fiatCurrency}
                        renderItem={this.renderFiat}
                        keyExtractor={(item) => item.currencyId}
                    />
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        overflow: "hidden",
    },
});
