import { StyleSheet, Appearance } from "react-native";

const lightColors = {
    primary: "rgba(0, 0, 0, 0.05)",
    secondary: "#1B1B1B",
    complementary: "#303030",
    tertiary: "#f7f7f7",
    background: "#fff",
    gradientFrom: "#fff",
    gradientTo: "#f7f7f7",
    grey: "#aaabad",
    white: "#fff",
    black: "#333",
    blue: "#2696fb",
    green: "#1DBC60",
    yellow: "#FECA54",
    red: "#EE4540",
    text: "#1B1B1B",
    shadow: "rgba(236, 236, 236, 0.3)",
    secondaryShadow: "rgba(190, 190, 190, 0.6)",
    tertiaryShadow: "rgba(0, 0, 0, 0.05)",
    highlightedText: "#363853",
    networkSymbol: "#fff",
    labelText: "white",
    latamxo1: "#333",
    latamxo2: "#333",
    avatarBase: "#a8a8a8",
    floatButtonText: "#ffffff",
    floatButtonBackground: "#1B1B1B",
    incognitoButton: "#363638",
    popupBackground: "#cfcfcf",
};

const darkColors = lightColors;

const settings = {
    cardRadius: 10,
    shadow: {
        shadowOpacity: 0.08,
        shadowRadius: 10,

    },
};

export { lightColors, darkColors, settings };
