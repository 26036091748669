import React, { Component } from "react";
import { View, StyleSheet, FlatList, RefreshControl } from "react-native";
import { connect } from "react-redux";
import { colors } from "@styles/globalStyles";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import SocialNetworkService from "./service/SocialNetworkService";
import Container from "@base/Container";
import Header from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import UserCard from "./components/UserCard";
import { Route } from "@react-navigation/native";
import { UsersViewType } from "@custom-types/UsersViewType";
import { FollowerRequestType } from "@custom-types/FollowerRequestType";
import i18n from "@i18n/i18n";
import BotCard from "@base/BotCard";

interface Props {
    client: Client;
    navigation: NavigationType;
    route: Route<string, { UsersViewType?: string; clientID?: string; postID?: string }>;
    followerRequests: Array<FollowerRequestType>;
}

interface State {
    title: string;
    page: number;
    totalPages: number;
    clients: Array<Client>;
    syncing: boolean;
    loadMore: boolean;
}

const { t } = i18n;

export class _UsersScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItemUser = this.renderItemUser.bind(this);
        this.state = {
            title: " ",
            page: 1,
            totalPages: 1,
            clients: [],
            syncing: false,
            loadMore: false,
        };
    }

    componentDidMount = () => {
        this.loadData();
    };

    async componentDidUpdate(props) {
        if (props != this.props) {
            this.loadData();
        }
    }

    loadData = async () => {
        this.setState({ syncing: true });
        if (this.props.route?.params?.UsersViewType == UsersViewType.FOLLOWED) {
            const clients = await SocialNetworkService.getInstance().getFollowed(
                this.props.route.params.clientID,
                this.state.page
            );
            if (clients) {
                this.setState({
                    clients: this.state.loadMore ? this.state.clients.concat(clients.docs) : clients.docs,
                    title: "Followed",
                    totalPages: clients.totalPages ? clients.totalPages : 1,
                    syncing: false,
                    loadMore: false,
                });
            }
        }
        if (this.props.route?.params?.UsersViewType == UsersViewType.FOLLOWERS) {
            const clients = await SocialNetworkService.getInstance().getFollowers(
                this.props.route.params.clientID,
                this.state.page
            );
            if (clients) {
                this.setState({
                    clients: this.state.loadMore ? this.state.clients.concat(clients.docs) : clients.docs,
                    title: "Followers",
                    totalPages: clients.totalPages ? clients.totalPages : 1,
                    syncing: false,
                    loadMore: false,
                });
            }
        }
        if (this.props.route?.params?.UsersViewType == UsersViewType.LIKES) {
            const clients = await SocialNetworkService.getInstance().getLikes(
                this.props.route.params.postID,
                this.state.page
            );
            if (clients) {
                this.setState({
                    clients: this.state.loadMore ? this.state.clients.concat(clients.docs) : clients.docs,
                    title: "Likes",
                    totalPages: clients.totalPages ? clients.totalPages : 1,
                    syncing: false,
                    loadMore: false,
                });
            }
        }
        if (this.props.route?.params?.UsersViewType == UsersViewType.REQUESTS) {
            const clients = this.props.followerRequests.map((c) => {
                return c.sender;
            });
            this.setState({
                title: "Follower requests",
                syncing: false,
                loadMore: false,
            });
        }
    };

    renderItemUser = ({ item }) => {
        return (
            <UserCard
                isRequest={this.props.route?.params?.UsersViewType == UsersViewType.REQUESTS}
                isOwner={item?._id == this.props.client?._id}
                client={item}
                navigation={this.props.navigation}
            />
        );
    };

    renderFollowerRequest = ({ item }) => {
        return (
            <UserCard
                isRequest={this.props.route?.params?.UsersViewType == UsersViewType.REQUESTS}
                isOwner={item?._id == this.props.client?._id}
                client={item.sender}
                navigation={this.props.navigation}
                delete={() => this.rejectFollowRequest(item._id)}
                confirm={() => this.acceptFollowRequest(item._id)}
            />
        );
    };

    async rejectFollowRequest(id: string) {
        this.setState({ syncing: true });
        await SocialNetworkService.getInstance().rejectFollowRequest(id);
        SocialNetworkService.getInstance().getFollowerRequests();
        this.setState({ syncing: false });
    }

    async acceptFollowRequest(id: string) {
        this.setState({ syncing: true });
        await SocialNetworkService.getInstance().acceptFollowRequest(id);
        SocialNetworkService.getInstance().getFollowerRequests();
        this.setState({ syncing: false });
    }

    loadMore = async () => {
        if (this.state.page < this.state.totalPages) {
            this.setState({ page: this.state.page + 1, loadMore: true }, this.loadData);
        }
    };

    onRefresh = async () => {
        this.setState({ page: 1, totalPages: 1, loadMore: false });
        this.loadData();
    };

    render() {
        return (
            <ScreenWrapper>
                <Header {...this.props} title={this.state.title} type={HeaderType.Light} />

                <Container style={{ flex: 1, paddingHorizontal: 0 }}>
                    <View style={{ paddingHorizontal: 20 }}>
                        {this.props.route?.params?.UsersViewType == UsersViewType.REQUESTS ? (
                            <FlatList
                                ListEmptyComponent={<BotCard message={t("empty_follower")} />}
                                data={this.props.followerRequests}
                                renderItem={this.renderFollowerRequest}
                                keyExtractor={(item) => item._id}
                                contentContainerStyle={{ paddingBottom: 20 }}
                                initialNumToRender={10}
                                maxToRenderPerBatch={10}
                                refreshControl={
                                    <RefreshControl
                                        tintColor={colors.text}
                                        onRefresh={this.onRefresh}
                                        refreshing={this.state.syncing}
                                    />
                                }
                                onEndReachedThreshold={1}
                            />
                        ) : (
                            <FlatList
                                data={this.state.clients}
                                renderItem={this.renderItemUser}
                                keyExtractor={(item) => item._id}
                                contentContainerStyle={{ paddingBottom: 20 }}
                                initialNumToRender={10}
                                maxToRenderPerBatch={10}
                                refreshControl={
                                    <RefreshControl
                                        tintColor={colors.text}
                                        onRefresh={this.onRefresh}
                                        refreshing={this.state.syncing}
                                    />
                                }
                                onEndReachedThreshold={1}
                                onEndReached={this.loadMore}
                            />
                        )}
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        followerRequests: state.social.followerRequests,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const UsersScreen = connect(mapStateToProps, mapDispatchToProps)(_UsersScreen);

export default UsersScreen;

const styles = StyleSheet.create({});
