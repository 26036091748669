import axios from "axios";
import { getEnv } from "@utils/helpers/global/global";
import { ApiService } from "./ApiService";
import AppStorage from "@utils/storage";
import store from "@store/index";
import { hidePopupMessage, loading, ready, showPopup, showPopupMessage, showSnackbar } from "@store/actions/global";
import { Client } from "@custom-types/Client";
import {
    CoincaexBankAccount,
    CoincaexBuy,
    CoincaexFiatCurrency,
    CoincaexSell,
    CoincaexSignin,
    CoincaexSignup,
    equateCurrenciesToCoincaex,
} from "@screens/wallet/buy/coincaex/helper/coincaex.helper";
import i18n from "@i18n/i18n";
import { Platform } from "react-native";
import Currency from "@core/currencies/Currency";


const { t } = i18n;

export class CoincaexService {
    private static instance: CoincaexService;
    private fiatCurrencies: Array<CoincaexFiatCurrency> = [];

    public static getInstance() {
        if (!CoincaexService.instance) {
            CoincaexService.instance = new CoincaexService();
        }

        return CoincaexService.instance;
    }

    async signup(register: CoincaexSignup) {
        const { firstName, lastName, email, phone, password, confirmPassword } = register;

        const body = {
            firstName,
            lastName,
            email,
            phone,
            password,
            confirmPassword,
        };

        try {
            store.dispatch(loading());
            const url = `${getEnv("API_URL")}coincaex/register`;
            const response = await axios.post(url, body, { headers: await ApiService.getAuthHeaders() });
            store.dispatch(ready());
            store.dispatch(showPopup({ type: "SUCCESS" }));
            return response.data;
        } catch (e: any) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("an_error_has_occurred"),
                }),
            );

            store.dispatch(ready());
        }
    }

    async signin(login: CoincaexSignin) {
        const { email, password } = login;

        const body = {
            email,
            password,
        };

        try {
            store.dispatch(loading());

            const url = `${getEnv("API_URL")}coincaex/login`;
            const response = await axios.post(url, body, { headers: await ApiService.getAuthHeaders() });
            store.dispatch(ready());
            this.setStorageClient(response.data?.id, response.data?.token);
            return response.data;
        } catch (e: any) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("an_error_has_occurred"),
                }),
            );

            store.dispatch(ready());
        }
    }

    async logOut() {
        return await this.removeStorageClient();
    }

    async setStorageClient(id: string, token: string) {
        const client: Client = store.getState().auth.client;
        let coincaex = (await AppStorage.getItem("coincaex")) || [];
        let newItem = {
            [client._id]: {
                id: id,
                token: token,
            },
        };

        const existingItemIndex = coincaex.findIndex((item) => Object.keys(item)[0] === client._id);

        if (existingItemIndex !== -1) {
            coincaex[existingItemIndex] = newItem;
        } else {
            coincaex.push(newItem);
        }

        await AppStorage.setItem("coincaex", coincaex);
    }

    async removeStorageClient() {
        const client: Client = store.getState().auth.client;
        let coincaex = (await AppStorage.getItem("coincaex")) || [];

        const filtered = coincaex.filter((item) => Object.keys(item)[0] !== client._id);
        await AppStorage.setItem("coincaex", filtered);
        return filtered;
    }

    async getClientLoged() {
        const client: Client = store.getState().auth.client;
        let coincaex = (await AppStorage.getItem("coincaex")) || [];

        const itemIndex = coincaex.findIndex((item) => Object.keys(item)[0] === client._id);

        if (itemIndex !== -1) {
            const storedData = Object.values(coincaex[itemIndex])[0];
            return storedData;
        } else {
            return null;
        }
    }

    async getCoincaexClientToken() {
        const c: { id?: string; token?: string } = await this.getClientLoged();
        return c?.token;
    }

    async getCoincaexClientID() {
        const c: { id?: string; token?: string } = await this.getClientLoged();
        return c?.id;
    }

    async getClientInfo() {
        try {
            const token = await this.getCoincaexClientToken();
            const url = `${getEnv("API_URL")}coincaex/user-information/${token}`;
            const response = await axios.get(url, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e: any) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("an_error_has_occurred"),
                }),
            );
        }
    }

    async getClientKYC() {
        try {
            store.dispatch(loading());
            const token = await this.getCoincaexClientToken();
            const url = `${getEnv("API_URL")}coincaex/kyc/${token}`;
            const response = await axios.get(url, { headers: await ApiService.getAuthHeaders() });
            store.dispatch(ready());
            return response.data;
        } catch (e: any) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("an_error_has_occurred"),
                }),
            );
        }
    }

    async getQuotation(amount: number, crypto: string, network: string, bankAccountCurrency: string, type: string) {

        try {
            const url = `${getEnv("API_URL")}coincaex/quotation`;
            const body = {
                amount: amount || 0,
                crypto: crypto,
                network: network,
                bankAccountCurrency: bankAccountCurrency,
                discountCode: "",
                exchangeRateCrypto: null,
                exchangeRateFiat: null,
                type,
            };
            const response = await axios.post(url, body, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e: any) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("an_error_has_occurred"),
                }),
            );
        }
    }

    async uploadPaymentBase64(image: string) {
        try {
            store.dispatch(loading());

            const form = new FormData();
            if (Platform.OS == "web") {
                const base64 = await fetch(image);
                const blob = await base64.blob();
                form.append("image", blob);
            } else {
                form.append("image", {
                    uri: image,
                    type: "image/jpeg",
                    name: "file" + Math.random(),
                } as any);
            }

            const url = `${getEnv("API_URL")}coincaex/voucher-base64`;

            const response = await axios.post(url, form, {
                headers: { ...(await ApiService.getAuthHeaders()), "Content-Type": "multipart/form-data" },
            });

            store.dispatch(ready());

            return response.data;
        } catch (e: any) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("an_error_has_occurred"),
                }),
            );
        }

        store.dispatch(ready());
    }

    async confirmBuy(data: CoincaexBuy) {
        try {
            store.dispatch(showPopupMessage({ type: "LOADING", message: t("processing_payment") }))
            const url = `${getEnv("API_URL")}coincaex/buy`;

            const body = {
                id: await this.getCoincaexClientID(),
                token: await this.getCoincaexClientToken(),
                amount: data.transactionDepositTotal,
                crypto: data.crypto,
                wallet: data.wallet,
                network: data.network,
                bankAccountCurrency: data.bankAccountCurrency,
                bankAccountNumber: data.bankAccountNumber,
                voucher: data.voucher,
                reference: data.reference,
                discountCode: "",
            };
            const response = await axios.post(url, body, { headers: await ApiService.getAuthHeaders() });
            store.dispatch(hidePopupMessage());

            return response.data;
        } catch (e: any) {
            store.dispatch(hidePopupMessage());

            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("error"),
                }),
            );

            return false;
        }
    }

    async confirmSell(data: CoincaexSell) {
        try {
            store.dispatch(showPopupMessage({ type: "LOADING", message: t("processing_transaction") }))
            const url = `${getEnv("API_URL")}coincaex/sell`;
            const body = {
                id: await this.getCoincaexClientID(),
                token: await this.getCoincaexClientToken(),
                nit: data.nit,
                details: data.details,
                amount: data.amount,
                crypto: data.crypto,
                wallet: data.wallet,
                network: data.networkId,
                bankAccountCurrency: data.bankAccountCurrency,
                bankAccountName: data.bankAccountName,
                bankAccountNumber: data.bankAccountNumber,
                bankAccountType: data.bankAccountType,
                bankName: data.bankName,
                reference: data.reference,
                discountCode: data.discountCode,
            };
            const response = await axios.post(url, body, { headers: await ApiService.getAuthHeaders() });
            store.dispatch(hidePopupMessage());

            return response.data;
        } catch (e: any) {
            store.dispatch(hidePopupMessage());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("error"),
                }),
            );

            return false;
        }
    }

    async getBankListUser() {
        try {
            store.dispatch(loading());
            const token = await this.getCoincaexClientToken();
            const url = `${getEnv("API_URL")}coincaex/banks/${token}`;
            const response = await axios.get(url, { headers: await ApiService.getAuthHeaders() });
            store.dispatch(ready());

            return response.data;
        } catch (e) {
            store.dispatch(ready());
        }
    }

    async getBankListCoincaex() {
        try {
            store.dispatch(loading());
            const url = `${getEnv("API_URL")}coincaex/banks-coincaex`;
            const response = await axios.get(url, { headers: await ApiService.getAuthHeaders() });
            store.dispatch(ready());

            return response.data;
        } catch (e: any) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("error"),
                }),
            );
            store.dispatch(ready());
        }
    }

    async addBankAccount(register: CoincaexBankAccount) {
        const { bankName, bankAccountName, bankAccountNumber, bankAccountCurrency, bankAccountType } = register;

        const body = {
            id: await this.getCoincaexClientID(),
            token: await this.getCoincaexClientToken(),
            bankAccountCurrency,
            bankAccountName,
            bankAccountNumber,
            bankAccountType,
            bankName,
            bankAlias: "",
        };

        try {
            store.dispatch(loading());
            const url = `${getEnv("API_URL")}coincaex/bank`;

            const response = await axios.post(url, body, { headers: await ApiService.getAuthHeaders() });
            store.dispatch(ready());

            return response.data;
        } catch (e: any) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("error"),
                }),
            );
        }
    }

    async getAvailableCountries() {
        try {
            store.dispatch(loading());
            const url = `${getEnv("API_URL")}coincaex/banks-available`;
            const response = await axios.get(url, { headers: await ApiService.getAuthHeaders() });
            const banks = response.data?.bankList;

            let countries = [];

            for (const key in banks) {
                countries.push(key);
            }

            store.dispatch(ready());

            return countries;
        } catch (e: any) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("error"),
                }),
            );

            store.dispatch(ready());
        }
    }

    async getAvailableBanksByCountry(country: string) {
        try {
            store.dispatch(loading());
            const url = `${getEnv("API_URL")}coincaex/banks-available`;
            const response = await axios.get(url, { headers: await ApiService.getAuthHeaders() });
            const bankList = response.data?.bankList[country];

            const banks = bankList.banks.map((key) => {
                return { id: key, value: key };
            });
            const currencies = Object.keys(bankList.currencies).map((key) => {
                return { id: key, value: bankList.currencies[key] };
            });
            const types = Object.keys(bankList.types).map((key) => {
                return { id: key, value: key };
            });

            bankList.banks = [...banks];
            bankList.currencies = [...currencies];
            bankList.types = [...types];

            store.dispatch(ready());

            return bankList;
        } catch (e: any) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("error"),
                }),
            );

            store.dispatch(ready());
        }
    }

    async getWalletsCoincaex(currency: Currency) {
        try {
            store.dispatch(loading());

            const currencyCoincaex = await equateCurrenciesToCoincaex(currency.getAdapter("xo"));

            const url = `${getEnv("API_URL")}coincaex/wallets-coincaex?crypto=${currencyCoincaex.currency}&network=${currencyCoincaex.networkId
                }`;
            const response = await axios.get(url, { headers: await ApiService.getAuthHeaders() });
            store.dispatch(ready());

            return response.data.walletList[response.data.walletList?.length - 1];
        } catch (e: any) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("an_error_has_occurred"),
                }),
            );
            store.dispatch(ready());
        }
    }

    async getFiatCurrencies() {
        try {
            if (this.fiatCurrencies?.length) {
                return this.fiatCurrencies;
            }

            store.dispatch(loading());
            const url = `${getEnv("API_URL")}coincaex/banks-available/`;
            const response = await axios.get(url, { headers: await ApiService.getAuthHeaders() });

            if (!response?.data) {
                store.dispatch(ready());
                return this.fiatCurrencies;
            }

            const allCurrencies = Object.values(response.data.bankList).map((country: any) => country.currencies);

            const filteredCurrencies: Record<string, string> = allCurrencies.reduce((acc, curr) => {
                return { ...acc, ...curr };
            }, {});

            const formattedCurrencies: Array<CoincaexFiatCurrency> = Object.entries(filteredCurrencies).map(
                ([symbol, name]) => ({
                    symbol,
                    name,
                }),
            );

            this.fiatCurrencies = formattedCurrencies;
            store.dispatch(ready());
            return formattedCurrencies;
        } catch (e: any) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response?.data?.message || t("an_error_has_occurred"),
                }),
            );
            store.dispatch(ready());
        }
    }
}
