const lightColors = {
    primary: "#1caff5",
    secondary: "#ed06a5",
    complementary: "#03b9f2",
    tertiary: "#0d0d0d",
    background: "#0d0d0d",
    gradientFrom: "#262626",
    gradientTo: "#0d0d0d",
    text: "white",
    grey: "rgba(237, 6, 165, 0.6)",
    shadow: "rgba(0,0,0,0.1)",
    secondaryShadow: "rgba(0, 0, 0, 0.01)",
    tertiaryShadow: "rgba(255, 255, 255, 0.12)",
    highlightedText: "white",
    networkSymbol: "#fff",
    labelText: "white",
    latamxo1: "white",
    latamxo2: "white",
    mainButtonsColor: "white",
    avatarBase: "#FFFFFF",
};

const darkColors = lightColors;

const settings = {

    // gradientLocations: [0, 0.7],
};

export { lightColors, darkColors, settings };
