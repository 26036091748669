import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import RegularText from "@base/RegularText";
import { HeaderType } from "@custom-types/HeaderType";
import { colors, settings } from "@styles/globalStyles";
import Tags from "react-native-tags";
import InlineButton from "@base/InlineButton";
import store from "@store/index";
import { loading, ready, showPopup } from "@store/actions/global";
import { wordlists } from "bip39";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { FloatButton } from "@base/FloatButton";
import RegularTextInput from "@base/RegularTextInput";
import { getColorOpacity } from "@utils/helpers/global/global";
import { SeedNavigatorScreens } from "@navigation/SeedNavigator";
import * as Clipboard from "expo-clipboard";
import Icon from "@components/icons";
import { Route } from "@react-navigation/native";
import { OAuthService } from "@core/services/oauth/OAuthService";
import Initializer from "../../../initializer/Initializer";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: any;
    route: Route<
        string,
        {
            words?: any;
        }
    >;
}

interface State {
    words: string[];
    showWordsError: boolean;
    scanned: boolean;
    decrypted: boolean;
    password: string;
}

const { t } = i18n;
const CryptoJS = require("crypto-js");

export default class ImportScreen extends Component<Props, State> {
    focusListener: any;
    constructor(props: Props) {
        super(props);

        this.state = {
            words: [],
            showWordsError: false,
            scanned: false,
            decrypted: false,
            password: "",
        };

        this.onPressImport = this.onPressImport.bind(this);
        this.navigateToScanner = this.navigateToScanner.bind(this);
    }

    componentDidMount = () => {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            if (this.props.route?.params?.words) {
                this.setState({ scanned: true, words: [] });
            }
        });
    };

    componentWillUnmount() {
        try {
            this.focusListener.remove();
        } catch (e) {}
    }

    changeInputValue(value) {
        this.setState(
            {
                ...this.state,
                password: value,
            },
            this.getDecryptedCode,
        );
    }

    getDecryptedCode = () => {
        if (this.state.password?.length == 4) {
            const decryptedSeed = CryptoJS.AES.decrypt(
                this.props.route?.params?.words[0],
                this.state.password,
            ).toString(CryptoJS.enc.Utf8);
            const arrayWordsOld: Array<string> = decryptedSeed.split(",");
            const arrayWords = decryptedSeed?.match(/[^,]+/g);

            if (arrayWords?.length == 12) {
                store.dispatch(showPopup({ type: "SUCCESS" }));

                this.setState({ words: arrayWords, decrypted: true });
            } else {
                store.dispatch(showPopup({ type: "ERROR" }));
            }
        }
    };

    async onPressImport() {
        store.dispatch(loading());

        if (this.state.words?.length == 12) {
            await OAuthService.getInstance().newAlias(this.state.words.join(" "));
            Initializer.redirect(this.props.navigation, { redirectToProfile: true });
            store.dispatch(ready());
        } else {
            store.dispatch(ready());
            this.setState({ showWordsError: true });
            setTimeout(() => {
                this.setState({ showWordsError: true });
            }, 2000);
        }
    }

    onPasteWords = async () => {
        const pasteText = await Clipboard.getStringAsync();
        const replacedText = pasteText?.replace(/\,/g, "").replace("  ", " ");

        const arrayWords = replacedText?.match(/\S+/g) || [];

        if (arrayWords?.length == 12) {
            this.setState({ scanned: true });
            store.dispatch(showPopup({ type: "SUCCESS" }));

            this.setState({ words: arrayWords, decrypted: true });
        } else {
            store.dispatch(showPopup({ type: "ERROR" }));
        }
    };

    navigateToScanner() {
        this.props.navigation.navigate(SeedNavigatorScreens.ScannerSeed.routeName);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("import")} type={HeaderType.Light} {...this.props} />
                <Container style={styles.container}>
                    {/* <BotCard title={`${t("hi")}!`} message={t("warning_import")} /> */}

                    <View style={{ marginTop: 20, width: "100%" }}>
                        {this.state.scanned ? (
                            <View>
                                {!this.state.decrypted && (
                                    <View>
                                        <RegularTextInput
                                            secureTextEntry={true}
                                            autoFocus={true}
                                            maxLength={4}
                                            multiline={false}
                                            align={"center"}
                                            onChangeText={(text) => this.changeInputValue(text)}
                                            value={this.state.password}
                                            placeholder={t("QR_password")}
                                            style={{
                                                backgroundColor: colors.shadow,
                                                padding: 10,
                                                overflow: "hidden",
                                                borderRadius: settings.cardRadius,
                                                marginTop: 10,
                                            }}
                                        />
                                    </View>
                                )}
                                <View
                                    style={{
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        maxWidth: "100%",
                                    }}
                                >
                                    {this.state.words.map((word) => {
                                        return (
                                            <View
                                                key={word}
                                                style={[
                                                    styles.word,
                                                    {
                                                        backgroundColor: wordlists.english.includes(
                                                            word.toLowerCase().trim(),
                                                        )
                                                            ? colors.shadow
                                                            : getColorOpacity(colors.red, 0.6),
                                                    },
                                                ]}
                                            >
                                                <RegularText>{word.toLowerCase()}</RegularText>
                                            </View>
                                        );
                                    })}
                                </View>
                            </View>
                        ) : (
                            <View>
                                <Tags
                                    initialText=""
                                    textInputProps={{
                                        placeholder: t("type_words"),
                                        placeholderTextColor: colors.text,
                                    }}
                                    initialTags={this.state.words}
                                    onChangeTags={(words) => {
                                        if (words?.length && words?.length > this.state.words?.length) {
                                            const lastWord = words[words?.length - 1].toLowerCase().trim();
                                            let processedWords = this.state.words;
                                            if (wordlists.english.includes(lastWord)) {
                                                processedWords.push(lastWord);
                                            }
                                            this.setState({ words: processedWords });
                                        }
                                    }}
                                    color={colors.text}
                                    maxNumberOfTags={12}
                                    containerStyle={{ justifyContent: "center", flexWrap: "wrap", maxWidth: "100%" }}
                                    inputContainerStyle={[styles.word, { padding: 0, height: 40 }]}
                                    inputStyle={{ padding: 10, height: 40, color: colors.text }}
                                    renderTag={({ tag, index, onPress, deleteTagOnPress, readonly }) => (
                                        <PressableBase
                                            key={`${tag}-${index}`}
                                            onPress={onPress}
                                            style={[
                                                styles.word,
                                                {
                                                    backgroundColor: wordlists.english.includes(
                                                        tag.toLowerCase().trim(),
                                                    )
                                                        ? colors.shadow
                                                        : getColorOpacity(colors.red, 0.6),
                                                },
                                            ]}
                                        >
                                            <RegularText>{tag.toLowerCase()}</RegularText>
                                        </PressableBase>
                                    )}
                                />
                                {this.state.words?.length < 1 && (
                                    <View
                                        style={{
                                            width: "100%",
                                            justifyContent: "flex-end",
                                            alignItems: "flex-end",
                                            marginTop: 5,
                                        }}
                                    >
                                        <PressableBase onPress={this.onPasteWords} style={styles.button}>
                                            <Icon size={20} name={"clipboard"} color={colors.text} />
                                        </PressableBase>
                                    </View>
                                )}
                            </View>
                        )}
                    </View>
                </Container>
                <Container style={{ flex: 1, flexDirection: "column-reverse" }}>
                    {!this.state.scanned && this.state.words?.length !== 12 && (
                        <FloatButton onPress={this.navigateToScanner} iconName={"qr"}></FloatButton>
                    )}
                    {this.state.words?.length == 12 && (
                        <InlineButton
                            vibrate={true}
                            title={t("import")}
                            onPressPriority={"high"}
                            onPress={this.onPressImport}
                            style={{ marginHorizontal: 0, marginBottom: 20 }}
                        />
                    )}

                    {this.state.showWordsError && (
                        <View style={{ paddingBottom: 20 }}>
                            <RegularText color={colors.secondary} fontSize={14} align={"center"}>
                                Please, enter 12 valid words
                            </RegularText>
                        </View>
                    )}
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "flex-start",
        justifyContent: "center",
    },
    word: {
        padding: 8,
        borderRadius: settings.cardRadius,
        backgroundColor: colors.shadow,
        margin: 5,
        lineHeight: 40,
        height: 40,
    },
    button: {
        width: 40,
        marginHorizontal: 5,
        backgroundColor: colors.shadow,
        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
    },
});
