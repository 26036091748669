import axios from "axios"
import {AuthService} from "./AuthService"
import {expo} from "../../../app.json"
import {format} from "date-fns"
import { currency } from "expo-localization"
import { getEnv } from "@utils/helpers/global/global";

export class KriptonService {
    private static instance: KriptonService

    public static getInstance() {
        if (!KriptonService.instance) {
            KriptonService.instance = new KriptonService()
        }

        return KriptonService.instance
    }

 
    getMin(fiatCurrency?: string) {
        return 50
    }

    getMax(fiatCurrency?: string) {
        return 1500
    }


    async getFiat(currency:string) {
        
    }

    async getCurrenciesFromCountry(country:string) {
        const url = `${getEnv('API_URL')}kriptonmarket/banks?country=${country}`;
        const result = (await axios.get(url, 
            {   
                headers: await ApiService.getAuthHeaders()
            }
        ))['data']['data'].map(item => item.currency_id.toUpperCase()).filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
        });
        
        return result;
    }

    async getBanksFromCountry(country:string, currency: string) {
        const url = `${getEnv('API_URL')}kriptonmarket/banks?country=${country}`;
        const result = (await axios.get(url, 
            {   
                headers: await ApiService.getAuthHeaders()
            }
         ))['data']['data'].filter((item) => item.currency_id.toUpperCase() == currency)
        
        return result;
    }
    
    async getDefaultCurrency(country: string) {

        const currencies = await this.getCurrenciesFromCountry(country);
        let res = currencies[0];
        currencies.map(c => {
            if(c!='USD') {
                res = c;
            }
        })
        return res;
    }

    async getCountries() {
        const url = `${getEnv('API_URL')}kriptonmarket/countries`;
        const result = (await axios.get(url, 
            {   
                headers: await ApiService.getAuthHeaders()
            }
        ))['data'];

        return result;
    }

    async getClientStatus() {
        try {
            const endpoint = `${getEnv('API_URL')}kriptonmarket/client`
            const response = await axios.get(endpoint, {headers: await ApiService.getAuthHeaders()})
            return response.data
        } catch (e) {
            console.warn(e)
        }
    }

    async getOperations(c?: string) {
        const currency = c;
        try {
            const endpoint = `${getEnv('API_URL')}kriptonmarket/client/operations?currency=${currency}`
            const response = await axios.get(endpoint, {headers: await ApiService.getAuthHeaders()})
            return response.data.data
        } catch (e) {
            console.warn(e)
        }
    }

    async getOperation(operationID?: string) {
        try {
            const endpoint = `${getEnv('API_URL')}kriptonmarket/client/operation/${operationID}`
            const response = await axios.get(endpoint, {headers: await ApiService.getAuthHeaders()})
            return response.data.data
        } catch (e) {
            console.warn(e)
        }
    }

    async getQuote(digital_currency, fiat_currency, requested_currency, amount, address?) {
        const amount_in = amount.toString();
        const params = {
            currency_in: requested_currency,
            amount_in: amount_in, 
            currency_out: requested_currency==digital_currency?fiat_currency:digital_currency,
            type: "cash-in",
            address: address
        }
        const url = `${getEnv('API_URL')}kriptonmarket/calculate-amount-out`;
        const amount_out = (await axios.post(url, 
            {...params},
            {   
                headers: await ApiService.getAuthHeaders()
            }
        ))['data']['data']['amount_out'];
        return {
            fiatMoney: {
                totalAmount: requested_currency==fiat_currency?amount_in:amount_out,
                currency: fiat_currency,
            },
            digitalMoney: {
                amount: requested_currency==digital_currency?amount_in:amount_out,
                currency: digital_currency
            },
            data: {
                ...params,
                amount_out
            }
        }
    }

    processData(data) {
        return data;
    }

    async getPayment(params) {
        const url = `${getEnv('API_URL')}kriptonmarket/client/operation`;
        const result = (await axios.post(url, 
            {...params},
            {   
                headers: await ApiService.getAuthHeaders()
            }
        ))['data'];

        return result;
    }


    async newClietnEmail(email) {
        var body = {email: email}
        const endpoint = `${getEnv('API_URL')}kriptonmarket/client`
        const response = await axios.post(endpoint, body, {headers: await ApiService.getAuthHeaders()})
        return response.data
    }

    async setBank(name_bank, cbu, identification) {
        var body = {name: name_bank, cbu: cbu, identification: identification}
        try {
            const endpoint = `${getEnv('API_URL')}kriptonmarket/client/bank`
            const response = await axios.post(endpoint, body, {headers: await ApiService.getAuthHeaders()})
            return response.data
        } catch (e) {
            console.warn(e)
        }
    }

    async setDomicile(params: {
        postal_code: string,
        street_address: string,
        street_number: string,
        telephone_number: string,
        city: string,
        country: string,
    }) {
        
        var body = {
            postal_code: params.postal_code,
            street_address: params.street_address,
            street_number: params.street_number,
            telephone_number: params.telephone_number,
            city: params.city,
            country: params.country
        }
      
        try {
            const endpoint = `${getEnv('API_URL')}kriptonmarket/client/domicile`
            const response = await axios.post(endpoint, body, {headers: await ApiService.getAuthHeaders()})
            return response.data
        } catch (e) {
            console.warn(e)
        }
    }

    async setPersonalInformation( birthday, first_name, last_name, gender, marital_status, nationality, politically_exposed, document_type, document_number
        ) {
        
        var body = {
            birthday: birthday,
            first_name: first_name,
            last_name: last_name,
            gender: gender,
            marital_status: marital_status,
            nationality: nationality,
            politically_exposed: politically_exposed,
            document_type: document_type,
            document_number: document_number           

        }
        try {
            const endpoint = `${getEnv('API_URL')}kriptonmarket/client/personal-information`
            const response = await axios.post(endpoint, body, {headers: await ApiService.getAuthHeaders()})
            return response.data
        } catch (e) {
            console.warn(e)
        }
    }

    async newDigitalDocument(document_type, uri) {
        try {

            const form = new FormData()

                form.append("file", {
                    uri: uri,
                    type: "image/jpeg",
                    name: "file" + Math.random(),
                } as any)
            

            const endpoint = `${getEnv('API_URL')}kriptonmarket/client/documents/${document_type}`
            const resp = await axios.post(endpoint, form, {headers: await ApiService.getAuthHeaders()})
            
            return resp.data
        } catch (e) {
            console.warn(e)
        }
    }

    async newTiket(operationID, uri) {
        try {

            const form = new FormData()

                form.append("file", {
                    uri: uri,
                    type: "image/jpeg",
                    name: "file" + Math.random(),
                } as any)
            

            const endpoint = `${getEnv('API_URL')}kriptonmarket/client/proof-of-payment/${operationID}/cash-in`
            const resp = await axios.post(endpoint, form, {headers: await ApiService.getAuthHeaders()})
            
            return resp.data
        } catch (e) {
            console.warn(e)
        }
    }

}
