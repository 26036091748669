import RegularText from "@base/RegularText";
import Row from "@base/Row";
import { colors, settings } from "@styles/globalStyles";
import React, { Component } from "react";
import { View, StyleSheet, ViewStyle } from "react-native";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import { NavigationType } from "@custom-types/NavigationType";
import store from "@store/index";
import { Client } from "@custom-types/Client";
import AvatarBase from "@components/avatar/AvatarBase";
import { reloadPosts } from "@store/actions/social.actions";
import i18n from "@i18n/i18n";
import { nameSocialLengthHelper } from "@utils/helpers/social/social.helper";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    client: Client;
    isOwner?: boolean;
    styles?: ViewStyle;
    searchVersion?: boolean;
    isRequest?: boolean;
    onPressUser?: (clientID) => void;
    delete?: () => void;
    confirm?: () => void;
}

interface State {
    followed: boolean;
}

const { t } = i18n;

export default class UserCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.openProfile = this.openProfile.bind(this);
        this.state = {
            followed: false,
        };
    }

    onFollow = async () => {
        this.setState({ followed: true });
        const follow = await SocialNetworkService.getInstance().followUser(this.props.client?._id);
        if (follow) {
            store.dispatch(reloadPosts(true));
        } else {
            this.setState({ followed: false });
        }
    };

    openProfile = () => {
        this.props.navigation.navigate("Profile", {
            screen: "ProfileMain",
            params: { clientID: this.props.client?._id },
        });
    };

    render() {
        return (
            <View style={{ flex: 1 }}>
                {this.props.searchVersion ? (
                    <View style={[this.props.styles, styles.wrapperCard]}>
                        <PressableBase onPress={() => this.props.onPressUser(this.props.client?._id)}>
                            <AvatarBase
                                uri={
                                    this.props.client?.profileImagePath
                                        ? this.props.client?.profileImagePath.thumbnail
                                        : ""
                                }
                                alias={this.props.client?.alias}
                                size={75}
                            />

                            <RegularText style={{ marginTop: 10, textAlignVertical: "center" }}>
                                @{nameSocialLengthHelper(this.props.client?.alias)}
                            </RegularText>
                        </PressableBase>
                    </View>
                ) : (
                    <View style={[this.props.styles, styles.wrapper]}>
                        <Row style={{ justifyContent: "space-between" }}>
                            <PressableBase onPress={this.openProfile}>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <AvatarBase
                                        uri={
                                            this.props.client?.profileImagePath
                                                ? this.props.client?.profileImagePath?.thumbnail
                                                : ""
                                        }
                                        alias={this.props.client?.alias}
                                        size={35}
                                    />

                                    <RegularText fontSize={14} style={{ marginLeft: 10, textAlignVertical: "center" }}>
                                        @{nameSocialLengthHelper(this.props.client?.alias)}
                                    </RegularText>
                                </View>
                            </PressableBase>

                            <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
                                {!this.props.client?.follow &&
                                    !this.state.followed &&
                                    !this.props.isOwner &&
                                    !this.props.isRequest && (
                                        <View style={styles.follow}>
                                            {!this.props.client?.isPrivateProfile ? (
                                                <PressableBase onPress={this.onFollow}>
                                                    <RegularText color={colors.white} align={"center"} fontSize={12}>
                                                        {t("follow")}
                                                    </RegularText>
                                                </PressableBase>
                                            ) : (
                                                <View>
                                                    <RegularText color={colors.white} align={"center"} fontSize={12}>
                                                        {t("private_account")}
                                                    </RegularText>
                                                </View>
                                            )}
                                        </View>
                                    )}
                                {this.props.isRequest && (
                                    <Row>
                                        <View style={[styles.follow, { paddingHorizontal: 10, paddingVertical: 4 }]}>
                                            <PressableBase onPress={this.props.confirm}>
                                                <RegularText color={colors.white} align={"center"} fontSize={11}>
                                                    {t("confirm")}
                                                </RegularText>
                                            </PressableBase>
                                        </View>
                                        <View style={[styles.delete, { paddingHorizontal: 10, paddingVertical: 4 }]}>
                                            <PressableBase onPress={this.props.delete}>
                                                <RegularText align={"center"} fontSize={11}>
                                                    {t("delete")}
                                                </RegularText>
                                            </PressableBase>
                                        </View>
                                    </Row>
                                )}
                            </View>
                        </Row>
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        marginVertical: 5,
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        overflow: "hidden",
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    wrapperCard: {
        backgroundColor: colors.shadow,
        marginHorizontal: 8,
        marginVertical: 10,
        height: 150,
        borderRadius: settings.cardRadius,
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    follow: {
        zIndex: 99,
        textAlignVertical: "center",
        alignSelf: "center",
        textAlign: "center",
        backgroundColor: colors.secondary,
        marginLeft: 10,
        paddingHorizontal: 15,
        borderRadius: 15,
        paddingVertical: 5,
        overflow: "hidden",
    },

    delete: {
        zIndex: 99,
        textAlignVertical: "center",
        alignSelf: "center",
        textAlign: "center",
        borderColor: colors.complementary || colors.secondary,
        borderWidth: 1,
        marginLeft: 10,
        paddingHorizontal: 15,
        borderRadius: 15,
        paddingVertical: 5,
        overflow: "hidden",
    },
});
