import * as React from "react"
import Svg, {Path, G} from "react-native-svg"

function Logo(props) {
    return (
        <Svg xmlns='http://www.w3.org/2000/svg' style={{marginTop: 12}} height={30} width={150} viewBox='0 0 877.7 232.19' {...props}>
            <Path
                fill='#dcaff0'
                d='M597 133.31l53.32-66.09-52.94-65.34h27.79l40.37 50.32 40.55-50.32h27.41l-52.76 64.96 53.32 66.47h-27.6l-40.93-50.32-41.31 50.32H597z'
            />
            <Path
                fill='#dcaff0'
                d='M732 67.59c0-19.15 6.79-35.2 20.37-48.16C765.95 6.48 783.44 0 804.85 0c21.4 0 38.9 6.48 52.48 19.43 13.58 12.96 20.37 29.01 20.37 48.16 0 19.28-6.76 35.36-20.28 48.25-13.64 12.89-31.17 19.34-52.57 19.34s-38.9-6.48-52.48-19.43C738.79 102.8 732 86.74 732 67.59zm37.55 33.24c9.14 8.89 20.9 13.33 35.3 13.33 14.65 0 26.5-4.41 35.58-13.24 9.07-8.82 13.61-19.93 13.61-33.33 0-13.27-4.6-24.38-13.8-33.33-9.2-8.95-21-13.43-35.39-13.43-14.4 0-26.19 4.48-35.39 13.43s-13.8 20.06-13.8 33.33c-.01 13.4 4.62 24.48 13.89 33.24zm18.59-33.24c0-4.51 1.53-8.32 4.6-11.45 3.07-3.13 7.1-4.69 12.11-4.69s9.04 1.57 12.11 4.69c3.07 3.13 4.6 6.95 4.6 11.45 0 4.63-1.53 8.48-4.6 11.55-3.07 3.07-7.11 4.6-12.11 4.6-5.01 0-9.01-1.56-12.02-4.69-3.13-3.01-4.69-6.82-4.69-11.46z'
            />
            <Path
                fill='none'
                d='M638.89 161.43l-2.95 2.94c24.09 26.06 58.54 42.41 96.74 42.41 38.85 0 73.82-16.91 97.97-43.74l-1.6-1.61M933.17 266.14h-.05l.05.05z'
            />
            <Path
                fill='#dcaff0'
                d='M732.68 229.15c45.07 0 85.61-19.35 113.8-50.18l-15.84-15.93c-24.14 26.83-59.11 43.74-97.97 43.74-38.2 0-72.65-16.35-96.74-42.41l-15.88 15.83c28.14 30.11 68.17 48.95 112.63 48.95z'
            />
            <G>
                <Path
                    fill='#2B2700'
                    d='M-.1-.15h22.01v110.62h66.41v22.2H-.1V-.15zM194.47 102.41h-52.99l-15.32 30.26h-24.69L167.88-.15l66.41 132.83H209.6l-15.13-30.27zM152.39 80.4h30.99l-15.49-30.98-15.5 30.98zM212.6-.15h110.81v22.2H279.2v110.62H257V22.05h-44.4V-.15zM392.73 102.41h-50.98l-15.32 30.26h-24.69L368.15-.15l66.41 132.83h-24.69l-17.14-30.27zM352.65 80.4h28.97l-13.48-30.98-15.49 30.98zM469.72 53.24v79.43h-22.01V-.15l66.41 66.41 66.6-66.41v132.83h-22.39V53.24l-44.21 44.4-44.4-44.4z'
                />
            </G>
        </Svg>
    )
}

export default Logo
