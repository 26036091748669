import React, { Component } from "react";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { FlatList, Platform, ScrollView, StyleSheet, View } from "react-native";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { SeedNavigatorScreens } from "@navigation/SeedNavigator";
import SeedHistoryList from "@components/history/SeedHistoryList";
import Card from "@base/Card";
import CircleButton from "@base/CircleButton";
import Row from "@base/Row";
import AvatarBase from "@components/avatar/AvatarBase";
import BoldText from "@base/BoldText";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import { OAuthService } from "@core/services/oauth/OAuthService";
import { AuthNavigatorScreens } from "@navigation/AuthNavigator";
import { AccountsNavigatorScreens } from "@navigation/AccountsNavigator";
import Initializer from "@screens/initializer/Initializer";

interface Props {
    navigation: any;
    client: Client;
}

interface State { }

const { t } = i18n;

export class _AccountsScreen extends Component<Props, State> {
    focusListener: any;
    constructor(props: Props) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.navigateToSeed = this.navigateToSeed.bind(this);
    }

    async logOut() {
        await OAuthService.getInstance().signout();
        Initializer.redirect(this.props.navigation);
    }

    navigateToSeed() {
        this.props.navigation.navigate(AccountsNavigatorScreens.SeedPhrase.routeName);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("accounts")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    <View
                        style={{
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",

                            paddingTop: 25,
                            paddingBottom: 35,
                        }}
                    >
                        <AvatarBase
                            size={80}
                            uri={
                                this.props.client?.profileImagePath?.square ||
                                this.props.client?.profileImagePath?.thumbnail
                            }
                            alias={this.props.client?.alias || " "}
                        ></AvatarBase>
                        <View style={{ flex: 1, paddingTop: 15 }}>
                            <BoldText align="center" fontSize={18}>
                                {"@"}
                                {trimHelper(this.props.client?.alias, 30)}{" "}
                            </BoldText>
                        </View>
                    </View>

                    <Card style={styles.wrapper}>
                        <CircleButton
                            icon={"key"}
                            title={t("seed_phrase")}
                            onPress={this.navigateToSeed}
                        ></CircleButton>
                        <CircleButton
                            icon={"person-plus"}
                            title={t("new_account")}
                            onPress={() => null}
                        ></CircleButton>
                        <CircleButton icon={"log-out"} title={t("signout")} onPress={this.logOut}></CircleButton>
                    </Card>

                    <SeedHistoryList
                        listTitle={t("history")}
                        contentContainerStyle={{ paddingTop: 30, flex: 1 }}
                        navigation={this.props.navigation}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        paddingVertical: 25,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const AccountsScreen = connect(mapStateToProps, mapDispatchToProps)(_AccountsScreen);

export default AccountsScreen;
