import React, { Component } from "react";
import { View, StyleSheet, FlatList, RefreshControl } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import NFTService from "@core/services/NFTService";
import NFT, { NFTsHistoryTransaction } from "@custom-types/NFTModel";
import NFTWideCard from "./components/NFTWideCard";
import { colors } from "@styles/globalStyles";
import BotCard from "@base/BotCard";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { ProfileSections } from "@custom-types/ProfileType";

interface Props {
    navigation: NavigationType;
}

interface State {
    transactions: Array<NFTsHistoryTransaction>;
    totalPages: number;
    page: number;
    syncing: boolean;
}

const { t } = i18n;

export class _HistoryNFTScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.setData = this.setData.bind(this);

        this.loadMore = this.loadMore.bind(this);
        this.redirectToProfile = this.redirectToProfile.bind(this);
        this.state = {
            transactions: [],
            totalPages: 1,
            page: 1,
            syncing: false,
        };
    }

    async componentDidMount() {
        this.setData();
    }

    async setData() {
        const transactions = await NFTService.getInstance().getUserHistory();
        if (transactions && transactions.docs) {
            this.setState({
                transactions: transactions.docs,
                totalPages: transactions.totalPages,
            });
        }
    }

    renderItem(item: NFTsHistoryTransaction) {
        return <NFTWideCard onPressAvatar={this.redirectToProfile} nftTransaction={item} />;
    }

    redirectToProfile(id) {
        this.props.navigation.navigate("Profile", {
            screen: ProfileNavigatorScreens.ProfileMain.routeName,
            params: {
                clientID: id,
                profileSection: ProfileSections.nftProfile,
            },
        });
    }

    async loadMore() {
        if (this.state.page < this.state.totalPages) {
            const transactions = await NFTService.getInstance().getUserHistory();
            if (transactions && transactions.docs) {
                this.setState({
                    page: transactions.page,
                    transactions: transactions.docs,
                    totalPages: transactions.totalPages,
                });
            }
        }
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("history")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    <FlatList
                        data={this.state.transactions}
                        keyExtractor={(item) => item._id}
                        renderItem={({ item }) => this.renderItem(item)}
                        extraData={this.state}
                        refreshControl={
                            <RefreshControl
                                tintColor={colors.text}
                                onRefresh={this.setData}
                                refreshing={this.state.syncing}
                            />
                        }
                        onEndReachedThreshold={1}
                        onEndReached={this.loadMore}
                        ListEmptyComponent={<BotCard message={t("empty_nft_history")} />}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({});

const HistoryNFTScreen = connect(mapStateToProps, mapDispatchToProps)(_HistoryNFTScreen);

export default HistoryNFTScreen;

const styles = StyleSheet.create({
    card: {},
    swipContainer: {
        flex: 1,
        height: 58,
    },
});
