import React, { Component } from "react";
import { Platform, ScrollView, StyleSheet, View } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { Route } from "@react-navigation/native";
import { MembershipModel } from "@custom-types/ExpercienceModel";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import ImageBase from "@components/image/ImageBase";
import { colors } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import Card from "@base/Card";
import Icon from "@components/icons";
import Row from "@base/Row";
import CircleButton from "@base/CircleButton";
import AvatarBase from "@components/avatar/AvatarBase";
import { hideModalBottom, loading, ready, showModalBottom } from "@store/actions/global";
import { getColorOpacity } from "@utils/helpers/global/global";
import ExperienceService from "@core/services/ExperienceService";
import { updateMembership } from "@store/actions/experiences.actions";
import store from "@store/index";
import QRBase from "@base/QRBase";
import PressableBase from "@base/PressableBase";
import InlineButton from "@base/InlineButton";

interface Props {
    navigation: NavigationType;
    client: Client;
    route: Route<string, { membership: MembershipModel }>;
}

interface State {
    membership: MembershipModel;
    showDescription: boolean;
    isOwner: boolean;
}

const { t } = i18n;

export class _MembershipScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.setFavorite = this.setFavorite.bind(this);
        this.showArchiveModal = this.showArchiveModal.bind(this);
        this.archiveMembership = this.archiveMembership.bind(this);
        this.setUnarchive = this.setUnarchive.bind(this);

        this.state = {
            membership: this.props.route?.params?.membership || null,
            showDescription: false,
            isOwner: this.props.route?.params?.membership?.owner?._id == this.props.client?._id,
        };
    }

    async componentDidMount() {
        const membership = await ExperienceService.getInstance().getMembership(
            this.props.route.params?.membership?._id
        );
        if (membership) {
            this.setState({ membership: membership, isOwner: membership?.owner?._id == this.props.client?._id });
        }
    }

    async setFavorite() {
        this.setState(
            (prevState) => ({
                membership: {
                    ...prevState.membership,
                    favorite: !this.state.membership.favorite,
                },
            }),
            () => store.dispatch(updateMembership(this.state.membership))
        );
        const resp = await ExperienceService.getInstance().setMembershipFavorite(this.state.membership);
    }

    showArchiveModal() {
        store.dispatch(
            showModalBottom({
                modalContent: (
                    <View style={{ paddingHorizontal: 30 }}>
                        <RegularText fontSize={18} align={"center"} style={{ paddingBottom: 30 }}>
                            {t("gate_message", { type: "MEMBERSHIP" })}
                        </RegularText>
                        <BoldText fontSize={18} align={"center"}>
                            {t("wallet_connect_request_question")}
                        </BoldText>
                        <InlineButton
                            style={{ marginTop: 25 }}
                            title={t("archive")}
                            onPress={this.archiveMembership}
                        ></InlineButton>
                    </View>
                ),
            })
        );
    }

    async archiveMembership() {
        store.dispatch(hideModalBottom());
        store.dispatch(loading());
        const resp = await ExperienceService.getInstance().archiveMembership(this.state.membership._id);
        if (resp) {
            ExperienceService.getInstance().getMemberships();
            this.props.navigation.navigate("Home", {
                screen: "HomeMain",
            });
        }
        store.dispatch(ready());
    }

    async setUnarchive() {
        store.dispatch(loading());
        const resp = await ExperienceService.getInstance().unarchiveMembeship(this.state.membership._id);
        if (resp) {
            ExperienceService.getInstance().getMemberships();
            this.props.navigation.navigate("Home", {
                screen: "HomeMain",
            });
        }
        store.dispatch(ready());
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={trimHelper(this.state.membership.membershipOption.name, 15)}
                    type={HeaderType.Light}
                    {...this.props}
                />

                <Container style={{ flex: 1 }}>
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <Card style={styles.wrapper}>
                            <ImageBase
                                autoSize={true}
                                uri={
                                    this.state.membership.membershipOption?.image?.carousel ||
                                    this.state.membership.membershipOption?.image?.cover ||
                                    ""
                                }
                            ></ImageBase>

                            {this.state.isOwner && (
                                <PressableBase onPress={this.setFavorite} style={styles.favorite}>
                                    {this.state.membership.favorite ? (
                                        <Icon color={colors.yellow} size={20} name="star-fill" />
                                    ) : (
                                        <Icon color={colors.grey} size={20} name="star" />
                                    )}
                                </PressableBase>
                            )}

                            <View style={styles.circleWrapper}>
                                <View style={styles.circle}>
                                    <RegularText style={{ marginTop: Platform.OS == "web" ? -2 : 2 }}>
                                        {"💎"}
                                    </RegularText>
                                </View>
                            </View>
                            <View
                                style={{
                                    position: "relative",
                                    height: 200,
                                    marginTop: -75,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                }}
                            >
                                <View style={styles.qrWrapper}>
                                    <QRBase value={`?membership=${this.state.membership._id}`} size={150} />
                                </View>
                            </View>
                            <View style={styles.details}>
                                {this.state.membership.archivedAt && (
                                    <View
                                        style={{
                                            alignSelf: "flex-end",
                                            borderRadius: 15,
                                            paddingHorizontal: 12,
                                            paddingVertical: 4,
                                            marginBottom: 10,
                                            backgroundColor: getColorOpacity(colors.grey, 0.8),
                                        }}
                                    >
                                        <Row style={{ alignItems: "center" }}>
                                            <Icon
                                                size={14}
                                                style={{ paddingRight: 5 }}
                                                color={colors.labelText}
                                                name={"archive"}
                                            ></Icon>

                                            <RegularText color={colors.labelText} fontSize={12}>
                                                {t("archived").toUpperCase()}
                                            </RegularText>
                                        </Row>
                                    </View>
                                )}
                                <BoldText style={{ position: "relative" }} fontSize={20}>
                                    {this.state.membership.membershipOption.name}
                                </BoldText>

                                {!this.state.showDescription &&
                                this.state.membership.membershipOption.description?.length > 139 ? (
                                    <PressableBase style={{}} onPress={() => this.setState({ showDescription: true })}>
                                        <RegularText align={"justify"} style={{ paddingTop: 10 }}>
                                            {trimHelper(this.state.membership.membershipOption.description, 140)}

                                            <RegularText align="left" style={{ color: "#4796fb" }}>
                                                {"  "}
                                                {t("see_more")}
                                            </RegularText>
                                        </RegularText>
                                    </PressableBase>
                                ) : (
                                    <View>
                                        <RegularText align={"justify"} style={{ paddingTop: 10 }}>
                                            {this.state.membership.membershipOption.description}
                                        </RegularText>
                                    </View>
                                )}
                                {this.state.membership?.merchant && (
                                    <Row style={{ alignItems: "center", paddingTop: 15 }}>
                                        <AvatarBase
                                            size={30}
                                            uri={
                                                this.state.membership?.merchant?.image?.thumbnail ||
                                                this.state.membership?.merchant?.image?.cover ||
                                                ""
                                            }
                                            alias={this.state.membership?.merchant?.name}
                                        ></AvatarBase>

                                        <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                                            By
                                            <BoldText fontSize={14}>
                                                {" "}
                                                {trimHelper(this.state.membership?.merchant.name, 25)}
                                            </BoldText>
                                        </RegularText>
                                    </Row>
                                )}
                                {this.state.membership?.owner && (
                                    <Row style={{ alignItems: "center", paddingTop: 10 }}>
                                        <AvatarBase
                                            size={30}
                                            uri={
                                                this.state.membership?.owner?.profileImagePath?.thumbnail
                                                    ? this.state.membership?.owner?.profileImagePath.thumbnail
                                                    : ""
                                            }
                                            alias={this.state.membership?.owner?.alias}
                                        ></AvatarBase>

                                        <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                                            {t("owned_by")}
                                            <BoldText fontSize={14}>
                                                {" @"}
                                                {trimHelper(this.state.membership?.owner?.alias, 20)}
                                            </BoldText>
                                        </RegularText>
                                    </Row>
                                )}
                            </View>
                        </Card>

                        {this.state.isOwner && (
                            <Card
                                style={{
                                    flex: 1,
                                    width: "100%",
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                    marginBottom: 20,
                                }}
                            >
                                {!this.state.membership.archivedAt && (
                                    <CircleButton
                                        icon={"archive"}
                                        title={t("archive")}
                                        iconSize={26}
                                        onPress={this.showArchiveModal}
                                    ></CircleButton>
                                )}

                                {this.state.membership.archivedAt && (
                                    <CircleButton
                                        icon={"archive"}
                                        title={t("unarchive")}
                                        iconSize={26}
                                        onPress={this.setUnarchive}
                                    ></CircleButton>
                                )}
                            </Card>
                        )}
                    </ScrollView>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    details: {
        width: "100%",
        paddingTop: 5,
        paddingBottom: 25,
        paddingHorizontal: 15,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "flex-start",
    },
    qrWrapper: {
        position: "absolute",
        zIndex: 100,
        padding: 5,
        backgroundColor: colors.gradientFrom,
        borderRadius: 5,
    },
    circleWrapper: {
        position: "absolute",
        zIndex: 100,
        top: 15,
        left: 15,
    },
    circle: {
        height: 32,
        width: 32,
        borderRadius: 16,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    favorite: {
        position: "absolute",
        zIndex: 100,
        top: 15,
        right: 15,
    },
    icon: {
        backgroundColor: colors.tertiaryShadow,
        height: 30,
        width: 30,
        borderRadius: 15,
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    bubble: {
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        borderRadius: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const MembershipScreen = connect(mapStateToProps, mapDispatchToProps)(_MembershipScreen);

export default MembershipScreen;
