import newNFTScreen from "@screens/nfts/newNFTScreen";
import NFTScreen from "@screens/nfts/NFTScreen";
import NFTsMainScreen from "@screens/nfts/NFTsMainScreen";
import NavigationInstantiator from "./helpers/NavigationInstantiator";
import ConfirmMintScreen from "@screens/nfts/ConfirmMintScreen";
import ApproveNFTScreen from "@screens/nfts/ApproveNFTScreen";
import OfferForSaleScreen from "@screens/nfts/OfferForSaleScreen";
import ConfirmSaleScreen from "@screens/nfts/ConfirmSaleScreen";
import ConfirmBuyScreen from "@screens/nfts/ConfirmBuyScreen";
import HistoryNFTScreen from "@screens/nfts/HistoryNFTScreen";



const NTFNavigatorScreens = {
 
    NFTMain: {
        routeName: "NFTMain",
        title: "NFT",
        component: NFTScreen,
    },
    newNFT: {
        routeName: "newNFT",
        title: "NFTs Creator",
        component: newNFTScreen,
    },
    
    ConfirmMintNFT: {
        routeName: "ConfirmMintNFT",
        title: "ConfirmMintNFT",
        component: ConfirmMintScreen
    },
    ApproveNFT: {
        routeName: "ApproveNFT",
        title: "ApproveNFT",
        component: ApproveNFTScreen
    },
    OfferForSale: {
        routeName: "OfferForSale",
        title: "OfferForSale",
        component: OfferForSaleScreen
    },
    ConfirmSale: {
        routeName: "ConfirmSale",
        title: "ConfirmSale",
        component: ConfirmSaleScreen
    },
    ConfirmBuy: {
        routeName: "ConfirmBuy",
        title: "ConfirmBuy",
        component: ConfirmBuyScreen
    },
    
};

const NTFNavigator = NavigationInstantiator(NTFNavigatorScreens);

export { NTFNavigator, NTFNavigatorScreens };
