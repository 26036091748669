import React from 'react';
import { Footer } from '@components/footer';
import { INagvigator, INav } from '@navigation/INavigator';
import NavigatorOptions from '@navigation/NavigatorOptions';
import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack'

const Stack = createStackNavigator();


function NavigationInstantiator(navigator: INagvigator) {
  return (
    () => 
      <Stack.Navigator screenOptions={{animationEnabled:false}}>
        {Object.keys(navigator).map((key: string) => {
          const nav:INav = navigator[key];
          const options: StackNavigationOptions = {
            ...NavigatorOptions, 
            title: nav.title
          }
          return <Stack.Screen  key={key} name={key} component={nav.component} options={options} />
        })}
      </Stack.Navigator>
  )
}

export default NavigationInstantiator;