import { StyleSheet, Appearance } from "react-native";

const darkColors = {
    primary: "#000",
    secondary: "#9e76ff",
    complementary: "#9e76ff",
    labelText: "white",
    tertiary: "#000",
    background: "#000",
    gradientFrom: "#161616",
    gradientTo: "#000",
    grey: "#9091a4",
    white: "white",
    black: "#333",
    blue: "#2696fb",
    green: "#1DBC60",
    yellow: "#FECA54",
    red: "#EE4540",
    text: "white",
    shadow: "rgba(255,255,255,0.05)",
    secondaryShadow: "rgba(0, 0, 0, 0.1)",
    tertiaryShadow: "rgba(255,255,255,0.08)",
    avatarBase: "#9e76ff",
    incognitoButton: "#363638",
    mainButtonsColor: "white",
};

const lightColors = {
    primary: "#1d1b2c",
    secondary: "#9B5AF2",
    complementary: "#9B5AF2",
    tertiary: "#fff",
    background: "#d8d9db",
    gradientFrom: "#f3f5f9",
    gradientTo: "#d8d9db",
    grey: "#9091a4",
    white: "white",
    black: "#333",
    blue: "#2696fb",
    green: "#1DBC60",
    yellow: "#FECA54",
    red: "#EE4540",
    text: "#454656",
    shadow: "rgba(255,255,255,0.8)",
    secondaryShadow: "rgba(0, 0, 0, 0.1)",
};

const settings = {};

export { lightColors, darkColors, settings };
