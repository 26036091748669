import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, G, Path } from "react-native-svg"

function KriptonMarket(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={50}
      height={50}
      id="prefix__svg34"
      {...props}
    >
      <Defs>
        <LinearGradient
          id="prefix__a"
          y1={0.5}
          x2={1}
          y2={0.5}
          gradientUnits="objectBoundingBox"
        >
          <Stop offset={0} stopColor="#f89737"  />
          <Stop offset={1} stopColor="#ef6f11"  />
        </LinearGradient>
        <LinearGradient
          id="prefix__h"
          x1={0}
          y1={0.5}
          x2={1}
          y2={0.5}
       
        />
      </Defs>
      <G transform="translate(-535.013 -16.149)" id="prefix__g32">
        {/* <Path
          d="M580.606 38.945a22.8 22.8 0 11-22.8-22.8 22.8 22.8 0 0122.8 22.8z"
          id="prefix__path12"
          fill="#fff"
        /> */}
        <G transform="translate(547.469 27.623)" id="prefix__g30">
          <Path
       
            d="M646.635 117.67l.664 1.454V116.4z"
            transform="translate(-636.708 -107.775)"
            id="prefix__path14"
            fill="#fff"
            fillOpacity={1}
          />
          <Path
        
            d="M607.056 83.266L607.72 82v-3.867h2.031l2.5-4.762h-5.713l-3.982 8.69h-.056v-8.69h-5.368v22.644h5.368v-5.994l1.125-1.9 3.145 7.9h.953V84.72z"
            id="prefix__path16"
            transform="translate(-597.129 -73.371)"
            fill="#fff"
            fillOpacity={1}
          />
          <Path
        
            d="M657.75 116.122h.69v-9.355c0-2.252 1.069-3.045 2.915-3.045a6.223 6.223 0 011.3.127v-5.327h-.837c-1.53 0-2.915 1.11-3.492 3.171h-.058v-2.728h-4.032l-.989 1.885v5.421z"
            transform="translate(-641.993 -93.479)"
            id="prefix__path18"
            fill="#fff"
            fillOpacity={1}
          />
          <Path
 
            d="M653.246 100.731v1.885l.988-1.885z"
            transform="translate(-641.993 -95.245)"
            id="prefix__path20"
            fill="#fff"
            fillOpacity={1}
          />
          <Path
       
            d="M653.246 147.019h4.5l-4.5-9.851z"
            transform="translate(-641.993 -124.376)"
            id="prefix__path22"
            fill="#fff"
            fillOpacity={1}
          />
          <Path
     
            d="M676.5 73.653l-.894 3.163h-.183l-.894-3.163h-.009v3.163h-.182v-3.423h.3l.885 3.078h.009l.864-3.078h.3v3.423h-.196z"
            transform="translate(-658.851 -73.389)"
            id="prefix__path24"
            fill="#fff"
            fillOpacity={1}
          />
          <Path
            
            d="M688.35 73.4h.165v2.248h.009l.986-1.332h.21l-.707.93.833 1.576h-.211L688.9 75.4l-.38.493v.92h-.165z"
            transform="translate(-670.058 -73.391)"
            id="prefix__path26"
            fill="#fff"
            fillOpacity={1}
          />
          <Path
            d="M695.874 75h.309v-.744h.166V75h.38v.163h-.38v1.932c0 .239.105.278.2.278a.633.633 0 00.207-.029v.156a1.133 1.133 0 01-.237.029c-.235 0-.336-.149-.336-.421v-1.947h-.309z"
            transform="translate(-676.073 -74.078)"
            id="prefix__path28"
            fill="#fff"
            fillOpacity={1}
          />
        </G>
      </G>
    </Svg>
  )
}

export default KriptonMarket
