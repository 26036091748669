import React, { Component } from 'react';
import { colors } from "@styles/globalStyles"
import { View, StyleSheet, TextStyle} from "react-native";
import BoldText from './BoldText';


interface Props {
    text: string,
    style?: TextStyle
    align?: "auto" | "left" | "right" | "center" | "justify",
    fontSize?: number,
    color?: string,
    fixedWidth?: number,
    selectable?:boolean,
    numberOfLines?: number,
}

interface State {
    inlineStyles: TextStyle,
    selectable: boolean
}



export default class Label extends Component<Props, State> {
    constructor(props:Props){
        super(props)
    }

    render() {
        return(
            <View style={[
                styles.label,
                this.props.style]}
            >
                <BoldText color={this.props.color || colors.labelText} fontSize={this.props.fontSize} align={this.props.align} fixedWidth={this.props.fixedWidth}>
                    {this.props.text}
                </BoldText>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    label: {
        fontSize:12,
        backgroundColor: colors.labelBackground,
        borderRadius: 5,
        paddingHorizontal: 5,
        paddingVertical: 3
    }
})

