import React, { Component } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import InlineButton from "@base/InlineButton";
import store from "@store/index";
import { hideModalBottom, showModalBottom, showSnackbar } from "@store/actions/global";
import RegularText from "@base/RegularText";
import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator";
import Row from "@base/Row";
import Icon from "@components/icons";
import Card from "@base/Card";
import BoldText from "@base/BoldText";
import RegularNumberInput from "@base/RegularNumberInput";
import { colors } from "@styles/globalStyles";
import { CoincaexService } from "@core/services/CoincaexService";
import { debounce } from "lodash";
import Wallet from "@core/wallet/Wallet";
import {
    CoincaexBankAccount,
    CoincaexFiatCurrency,
    CoincaexQuotation,
    CoincaexSell,
    equateCurrenciesToCoincaex,
} from "../../helper/coincaex.helper";
import { ActivityIndicator } from "react-native";
import { Avatar } from "react-native-elements";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import { Route } from "@react-navigation/native";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    selectedCurrency: string;
}

interface State {
    fiatCurrency: CoincaexFiatCurrency;
    fiatCurrencies: Array<CoincaexFiatCurrency>;
    fiatAmount: number;
    currency: Currency;
    loading: boolean;
    quotation: CoincaexQuotation;
}

const { t } = i18n;

export class _CoincaexSellScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.getQuote = this.getQuote.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            fiatCurrency: null,
            fiatCurrencies: [],
            fiatAmount: 0,
            currency: Wallet.getInstance().findCurrencyById(this.props.selectedCurrency),
            loading: false,
            quotation: {
                transactionAmount: 0,
                transactionCryptoAmount: 0,
                transactionDepositTotal: 0,
                transactionExchangeRateCrypto: 0,
                transactionExchangeRateFiat: 0,
                commission: 0,
            },
        };
        this.getQuote = debounce(this.getQuote, 500);
    }

    componentDidMount() {
        this.setFiatCurrencies();
    }

    async setFiatCurrencies() {
        const fiatCurrencies = await CoincaexService.getInstance().getFiatCurrencies();
        if (fiatCurrencies) {
            this.setState({
                fiatCurrencies: fiatCurrencies,
                fiatCurrency:
                    fiatCurrencies.find((f) => {
                        return f.symbol == "USD";
                    }) || fiatCurrencies[0],
            });
        }
    }

    handleInputChange = async (value: number) => {
        this.setState({ ...this.state, fiatAmount: value }, async () => {
            if (this.state.fiatAmount > 0) {
                this.getQuote();
                this.setState({ loading: true });
            } else {
                this.setState({
                    quotation: {
                        transactionAmount: 0,
                        transactionCryptoAmount: 0,
                        transactionDepositTotal: 0,
                        transactionExchangeRateCrypto: 0,
                        transactionExchangeRateFiat: 0,
                        commission: 0,
                    },
                });
            }
        });
    };

    async getQuote() {
        const c = await equateCurrenciesToCoincaex(this.state.currency.getAdapter("xo"));

        const resp = await CoincaexService.getInstance().getQuotation(
            Number(this.state.fiatAmount),
            c.currency,
            c.network,
            this.state.fiatCurrency?.symbol,
            "sell"
        );

        if (resp) {
            this.setState({ quotation: resp, loading: false });
        }
    }

    onPressCurrency = (item) => {
        this.setState({ fiatCurrency: item, loading: true }, () => this.getQuote());
        store.dispatch(hideModalBottom());
    };

    modalCurrency = () => {
        return (
            <FlatList
                data={this.state.fiatCurrencies}
                renderItem={({ item }) => {
                    return (
                        <PressableBase onPress={() => this.onPressCurrency(item)}>
                            <Card>
                                <Row style={{ justifyContent: "flex-start", width: "100%", alignItems: "center" }}>
                                    <Row style={{}}>
                                        <RegularText>{item.name?.toUpperCase()}</RegularText>

                                        <BoldText>
                                            {" - "}
                                            {item.symbol?.toUpperCase()}
                                        </BoldText>
                                    </Row>
                                </Row>
                            </Card>
                        </PressableBase>
                    );
                }}
                scrollEnabled={false}
            />
        );
    };

    handleSubmit = async () => {
        const { fiatAmount } = this.state;

        if (!fiatAmount) {
            store.dispatch(showSnackbar({ type: "ERROR", message: t("complete_field_warning") }));
        } else {
            const address = await CoincaexService.getInstance().getWalletsCoincaex(this.state.currency);

            const data: CoincaexSell = {
                amount: this.state.quotation.transactionCryptoAmount,
                crypto: (await equateCurrenciesToCoincaex(this.state.currency.getAdapter("xo"))).currency,
                network: (await equateCurrenciesToCoincaex(this.state.currency.getAdapter("xo"))).network,
                networkId: (await equateCurrenciesToCoincaex(this.state.currency.getAdapter("xo"))).networkId,
                wallet: address,
                transactionDepositTotal: this.state.quotation.transactionDepositTotal,
            };

            this.props.navigation.navigate(CoincaexNavigatorScreens.SellBanks.routeName, {
                data: data,
                fiatCurrency: this.state.fiatCurrency?.symbol,
            });
        }
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("transaction_detail")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1, justifyContent: "space-between" }}>
                    <View>
                        <View style={{ marginBottom: 20 }}>
                            <RegularText style={{ marginBottom: 10 }}>{t("amount_to_sell")}</RegularText>
                            <Card style={{ opacity: this.state.loading ? 0.5 : 1 }}>
                                <Row style={{ justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                    <Row style={{ alignItems: "center" }}>
                                        <CurrencyIcon styles={{ marginRight: 15 }} currency={this.state.currency} />

                                        <BoldText>{this.state.currency.getPName()}</BoldText>
                                        <NetworkCurrencySymbol currency={this.state.currency}></NetworkCurrencySymbol>
                                    </Row>
                                    <RegularNumberInput
                                        fontSize={20}
                                        key={"name"}
                                        maxLength={100}
                                        multiline={false}
                                        align={"right"}
                                        fontWeight="bold"
                                        onChangeText={(text) => this.handleInputChange(text)}
                                        value={this.state.fiatAmount}
                                        placeholder={"0"}
                                        style={{ flex: 1 }}
                                    />
                                </Row>
                            </Card>
                        </View>
                        <View style={{ marginBottom: 20 }}>
                            <Row style={{ alignItems: "center", marginBottom: 10 }}>
                                <RegularText>{t("you_will_receive")}</RegularText>
                                {this.state.loading && (
                                    <Row style={styles.loading}>
                                        <ActivityIndicator style={{ padding: 0 }} size={18} color={colors.text} />
                                    </Row>
                                )}
                            </Row>
                            <Card>
                                <Row style={{ justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                    <PressableBase
                                        onPress={() =>
                                            store.dispatch(
                                                showModalBottom({
                                                    modalContent: this.modalCurrency(),
                                                })
                                            )
                                        }
                                    >
                                        <Row style={{ alignItems: "center" }}>
                                            <View style={{ marginLeft: 15 }}>
                                                <Row style={{ marginBottom: 5, alignItems: "center" }}>
                                                    <BoldText>{this.state.fiatCurrency?.symbol}</BoldText>
                                                    <Icon
                                                        style={{ marginLeft: 10 }}
                                                        name="arrow-down"
                                                        size={20}
                                                        color={colors.text}
                                                    ></Icon>
                                                </Row>
                                                <RegularText fontSize={10}>
                                                    {this.state.fiatCurrency?.name?.toUpperCase()}
                                                </RegularText>
                                            </View>
                                        </Row>
                                    </PressableBase>
                                    <BoldText fontSize={20} color={colors.grey}>
                                        {this.state.quotation.transactionDepositTotal}
                                    </BoldText>
                                </Row>
                            </Card>
                        </View>
                        {this.state.quotation.transactionDepositTotal > 0 && (
                            <Card
                                style={{
                                    alignItems: "flex-start",
                                    marginTop: 20,
                                    opacity: this.state.loading ? 0.5 : 1,
                                }}
                            >
                                <RegularText style={{ marginBottom: 5 }} align="center" fontSize={14}>
                                    {t("total_deposit")}:{" "}
                                    <BoldText fontSize={14}>
                                        {this.state.quotation.transactionDepositTotal} {this.state.fiatCurrency?.name}
                                    </BoldText>
                                </RegularText>
                            </Card>
                        )}
                    </View>
                    {this.state.quotation.transactionDepositTotal > 0 && (
                        <InlineButton
                            style={{ marginBottom: 20, opacity: this.state.loading ? 0.5 : 1 }}
                            title={t("continue")}
                            onPress={() => this.handleSubmit()}
                        />
                    )}
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    loading: {
        position: "absolute",
        right: 0,
        backgroundColor: colors.grey,
        borderRadius: 15,
        height: 30,
        width: 30,
        justifyContent: "center",
        alignItems: "center",
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const CoincaexSellScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexSellScreen);

export default CoincaexSellScreen;
