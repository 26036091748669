
import SeedConfirmScreen from "@screens/settings/securityKit/SeedConfirmScreen";
import SecurityKitSeedScreen from "@screens/settings/securityKit/SecurityKitSeedScreen";
import NavigationInstantiator from "./helpers/NavigationInstantiator";



const SecurityKitNavigatorScreens = {
    StepOne: {
        routeName: "StepOne",
        title: "StepOne",
        component: SecurityKitSeedScreen,
    },
    StepTwo: {
        routeName: "StepTwo",
        title: "StepTwo",
        component: SeedConfirmScreen,
    }
};

const SecurityKitNavigator = NavigationInstantiator(SecurityKitNavigatorScreens)

export {SecurityKitNavigator, SecurityKitNavigatorScreens}
