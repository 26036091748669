import KriptonMarketScreen from "@screens/wallet/buy/kripton/KriptonMarketScreen"
import BuyMethodScreen from "@screens/wallet/buy/kripton/BuyMethodScreen"
import ConfirmBuyScreen from "@screens/wallet/buy/kripton/ConfirmBuyScreen"
import BuyHistoryScreen from "@screens/wallet/buy/kripton/BuyHistoryScreen"
import PurchaseOrderScreen from "@screens/wallet/buy/kripton/PurchaseOrderScreen"
import ClientStatusScreen from "@screens/wallet/buy/kripton/ClientStatusScreen"
import PersonalInformationScreen from "@screens/wallet/buy/kripton/PersonalInformationScreen"
import AddressStreetScreen from "@screens/wallet/buy/kripton/AddressStreetScreen"
import BankScreen from "@screens/wallet/buy/kripton/BankScreen"
import SelectNationalityScreen from "@screens/wallet/buy/kripton/SelectNationalityScreen"
import SelectCountryScreen from "@screens/wallet/buy/kripton/SelectCountryScreen"
import SelectFiatCurrencyScreen from "@screens/wallet/buy/kripton/SelectFiatCurrencyScreen"
import OperationDetailScreen from "@screens/wallet/buy/kripton/OperationDetailScreen"
import NavigationInstantiator from "./helpers/NavigationInstantiator"

const KriptonMarketNavigatorScreens = {
    KriptonMarket: {
        routeName: "KriptonMarket",
        title: "KriptonMarket",
        component: KriptonMarketScreen,
    },
    BuyMethodKripton: {
        routeName: "BuyMethodKripton",
        title: "KriptonMarket",
        component: BuyMethodScreen,
    },
    ConfirmBuyKripton: {
        routeName: "ConfirmBuyKripton",
        title: "KriptonMarket",
        component: ConfirmBuyScreen,
    },
    BuyHistoryKripton: {
        routeName: "BuyHistoryKripton",
        title: "KriptonMarket",
        component: BuyHistoryScreen,
    },
    PurchaseOrderKripton: {
        routeName: "PurchaseOrderKripton",
        title: "KriptonMarket",
        component: PurchaseOrderScreen,
    },
    ClientStatusKripton: {
        routeName: "ClientStatusKripton",
        title: "KriptonMarket",
        component: ClientStatusScreen,
    },
    PersonalInformationKripton: {
        routeName: "PersonalInformationKripton",
        title: "KriptonMarket",
        component: PersonalInformationScreen,
    },
    AddressStreetKripton: {
        routeName: "AddressStreetKripton",
        title: "KriptonMarket",
        component: AddressStreetScreen,
    },
    BankKripton: {
        routeName: "BankKripton",
        title: "KriptonMarket",
        component: BankScreen,
    },
    SelectNacionalityKripton: {
        routeName: "SelectNacionalityKripton",
        title: "KriptonMarket",
        component: SelectNationalityScreen,
    },
    SelectCountryKripton: {
        routeName: "SelectCountryKripton",
        title: "KriptonMarket",
        component: SelectCountryScreen,
    },
    SelectFiatCurrencyKripton: {
        routeName: "SelectFiatCurrencyKripton",
        title: "KriptonMarket",
        component: SelectFiatCurrencyScreen,
    },
    OperationDetailKripton: {
        routeName: "OperationDetailKripton",
        title: "KriptonMarket",
        component: OperationDetailScreen,
    },
}

const KriptonMarketNavigator = NavigationInstantiator(KriptonMarketNavigatorScreens)

export {KriptonMarketNavigator, KriptonMarketNavigatorScreens}
