import React, { Component } from "react";
import { View, StyleSheet, TextStyle, ScrollView, StyleProp, ViewStyle, Dimensions, Platform } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { LinearGradient } from "expo-linear-gradient";

interface Props {
    style?: StyleProp<ViewStyle>;
    children?: any;
    complementaryColor?: boolean;
    disable?: boolean;
}

interface State {}

export default class GradientWrapper extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.getColorsForGradient = this.getColorsForGradient.bind(this);
        this.state = {};
    }

    getColorsForGradient() {
        const secondaryColor = colors.secondary;
        const complementaryColor = colors.complementary;
        const gradientFromColor = colors.buttonGradientFrom;
        const gradientToColor = colors.buttonGradientTo;
        
        if (
            secondaryColor == gradientFromColor &&
            secondaryColor == gradientToColor &&
            !this.props.complementaryColor
        ) {
            return [secondaryColor, secondaryColor];
        }
        if (this.props.complementaryColor) {
            return [gradientFromColor, complementaryColor];
        }
        return [gradientFromColor, gradientToColor];
    }

    render() {
        return (
            <>
                {/* <View style={[this.props.style, {backgroundColor: colors.buttonGradientFrom}]}>{this.props.children}</View> */}
                {this.props.disable ? (
                    <View style={this.props.style}>{this.props.children}</View>
                ) : (
                    <LinearGradient
                        start={{ x: -0.8, y: 0 }}
                        end={{ x: 0.6, y: 1 }}
                        locations={Platform.OS !== "web" ? [0.45, 1] : [0, 1]}
                        colors={this.getColorsForGradient()}
                        style={this.props.style}
                    >
                        {this.props.children}
                    </LinearGradient>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({});
