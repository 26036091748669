import { Message } from "@custom-types/Message";
import store from "@store/index";
import { ApiService } from "@core/services/ApiService";
import { IMessage } from "react-native-gifted-chat";

export class SerializedMessage implements IMessage {
    id: string;
    _id: string;
    text: string;
    createdAt: any;
    user: {
        _id: string;
        id: string;
        name: string;
        avatar: string;
        address: string;
    };
    sent: boolean;
    received: boolean;
    pending: boolean;
    read: boolean;
    internalId: string;
    data: any;
    custom: boolean;
    author: string;


    constructor(msg: Message) {
       
        this.id = (msg._id) ? msg._id : null;
        this._id = (msg._id) ? msg._id : null;
        this.text = msg.text.trim();
        this.createdAt = msg.createdAt;
        this.user = {
            _id: msg.author._id,
            id: msg.author._id,
            name: msg.author.alias,
            avatar: msg.author.profileImagePath ? msg.author.profileImagePath.thumbnail : '',
            address: msg.author.address
        };
        this.sent = (msg.getStateNumber() === Message.SENT_STATE);
        this.received = (msg.getStateNumber() === Message.RECEIVED_STATE);
        this.pending = (msg.getStateNumber() === Message.PENDING_STATE);
        this.read = (msg.getStateNumber() === Message.READ_STATE);
        this.internalId = String(msg.internalId);
        this.data = msg.data;
        this.custom = msg.custom;
        this.author = msg.author._id.toString()
    }
}