import DiscoverScreen from "@screens/social/DiscoverScreen";
import NewPostScreen from "@screens/social/NewPostScreen";

import SocialNetworkSearchScreen from "@screens/social/SocialNetworkSearchScreen";
import UsersScreen from "@screens/social/UsersScreen";
import NavigationInstantiator from "./helpers/NavigationInstantiator";
import DiscoverExplorerScreen from "@screens/social/DiscoverExplorerScreen";

import { ProfileNavigator } from "./ProfileNavigator";
import SocialNetworkMainScreen from "@screens/social/SocialNetworkMainScreen";
import PostDetailScreen from "@screens/social/PostDetailScreen";

const SocialNetworkNavigatorScreens = {
    Discover: {
        routeName: "Discover",
        title: "Discover",
        component: DiscoverScreen,
    },
    SocialNetworkMain: {
        routeName: "SocialNetworkMain",
        title: "Social Network",
        component: SocialNetworkMainScreen,
    },

    DiscoverExplorer: {
        routeName: "DiscoverExplorer",
        title: "DiscoverExplorer",
        component: DiscoverExplorerScreen,
    },
    NewPost: {
        routeName: "NewPost",
        title: "New Post",
        component: NewPostScreen,
    },

    SocialNetworkSearch: {
        routeName: "SocialNetworkSearch",
        title: "SocialNetworkSearch",
        component: SocialNetworkSearchScreen,
    },
    PostDetail: {
        routeName: "PostDetail",
        title: "PostDetail",
        component: PostDetailScreen,
    },
    Users: {
        routeName: "Users",
        title: "Users",
        component: UsersScreen,
    },
    Profile: {
        routeName: "Profile",
        title: "Profile",
        component: ProfileNavigator,
    },
};

const SocialNetworkNavigator = NavigationInstantiator(SocialNetworkNavigatorScreens);

export { SocialNetworkNavigator, SocialNetworkNavigatorScreens };
