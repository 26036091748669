import React, {Component} from "react"
import {StyleSheet} from "react-native"
import {colors} from "@styles/globalStyles"
import {Header} from "@components/header/Header"
import {HeaderType} from "@custom-types/HeaderType"
import Container from "@base/Container"
import {NavigationType} from "../../../types/NavigationType"
import WarningCard from "@components/base/WarningCard"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import i18n from "@i18n/i18n"
import InlineButton from "@base/InlineButton"
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator"

interface Props {
    navigation: NavigationType
}

interface State {}

const {t} = i18n

export default class WarningSendCryptoScreen extends Component<Props, State> {
    constructor(props) {
        super(props)
    }
    onPress = () => {
        this.props.navigation.navigate(MessagesNavigatorScreens.Chat.routeName)
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("send_crypto")} backTo={MessagesNavigatorScreens.Chat.routeName} type={HeaderType.Light} {...this.props} />
                <Container style={styles.container}>
                    <WarningCard
                        message={t('warning_send_crypto')}
                    />
                </Container>
                <Container style={{paddingBottom: 5}}>
                    <InlineButton title={"Cerrar"} onPress={this.onPress}></InlineButton>
                </Container>
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    button: {
        backgroundColor: colors.secondary,
        width: 55,
        height: 55,
        position: "absolute",
        right: 20,
        bottom: 20,
        borderRadius: 30,
        justifyContent: "center",
        marginBottom: 8,
        alignItems: "center",
    },
})
