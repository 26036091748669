import * as React from "react";
import { View, Image } from "react-native";
import Svg, { Defs, ClipPath, Use, Path, LinearGradient, Stop, G, Circle } from "react-native-svg";
function Logo(props) {
    return (
        <Image
            source={require('./logo.png')}
            style={{ width: 55, height: 55 }}
        />
    );
}

export default Logo;
