import store from "@store/index";
import { Platform } from "react-native";
import i18n from "@i18n/i18n";
import AppStorage from "@utils/storage";
import { AuthNavigatorScreens } from "@navigation/AuthNavigator";
import { reset, setPriorityLoading } from "@store/actions/global";

const { t } = i18n;

export enum AppStatus {
    "IS_LOGGED" = "IS_LOGGED",
    "FIRST_START" = "FIRST_START",
    "STARTED" = "STARTED",
    "NEW_ACCOUNT" = "NEW_ACCOUNT",
}

export default class Initializer {
    private static instance: Initializer;

    constructor() {}

    static getInstance(): Initializer {
        if (!Initializer.instance) {
            Initializer.instance = new Initializer();
        }
        return Initializer.instance;
    }

    static redirect(navigation: any, params?: any) {
        store.dispatch(setPriorityLoading(true));
        navigation.navigate({
            name: AuthNavigatorScreens.Initializer.routeName,
            params: params,
        });
        if (params) {
            AppStorage.setItem("initializer", params);
        }
    }

    async getAppStatus() {
        let status = await AppStorage.getItem("app_state");
        if (!status) {
            await AppStorage.setItem("app_state", AppStatus.FIRST_START);
            status = await AppStorage.getItem("app_state");
        }
        return status;
    }

    async setAppStatus(status: AppStatus) {
        const s = await AppStorage.setItem("app_state", status);
        return s;
    }

    async areTermsAccepted() {
        const terms = await AppStorage.getItem("terms");
        return terms && terms.accepted;
    }
}
