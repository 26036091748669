import RegularText from "@base/RegularText";
import { colors, settings } from "@styles/globalStyles";
import React, { Component } from "react";
import { View, StyleSheet, Platform } from "react-native";
import i18n from "@i18n/i18n";
import NFT from "@custom-types/NFTModel";
import AvatarBase from "@components/avatar/AvatarBase";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import Icon from "@components/icons";
import Row from "@base/Row";
import BoldText from "@base/BoldText";
import { NavigationType } from "@custom-types/NavigationType";
import { getColorOpacity } from "@utils/helpers/global/global";
import ImageBase from "@components/image/ImageBase";
import VideoBase from "@components/image/VideoBase";
import { ResizeMode } from "expo-av";
import { ProfileSections } from "@custom-types/ProfileType";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    nft: NFT;
    onPress: () => void;
}

interface State {
    currency: Currency;
}

const { t } = i18n;

export default class NFTCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPressAvatar = this.onPressAvatar.bind(this);
        this.state = {
            currency: Wallet.getInstance().findCurrencyByBlockchain(this.props.nft?.blockchain),
        };
    }

    onPressAvatar() {
        this.props.navigation.navigate("Profile", {
            screen: "ProfileMain",
            params: {
                clientID: this.props.nft.owner._id,
                profileSection: ProfileSections.nftProfile,
            },
        });
        // this.props.navigation.navigate(NTFsNavigatorScreens.NFTProfile.routeName, {
        //     clientID: this.props.nft.owner._id,
        // });
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <PressableBase onPress={this.props.onPress} style={styles.wrapper}>
                    <View>
                        {this.props.nft.video?.thumbnail ? (
                            <View style={{ height: 200, marginTop: -10, overflow: "hidden"}}>
                                <VideoBase
                                    style={[Platform.OS == "web" ? {flexGrow: 1, flexDirection: "row"} : {height: 200}]}
                                    posterStyle={{height: 200, resizeMode: "cover"}}
                                    uri={this.props.nft.video?.thumbnail}
                                    posterUri={this.props.nft.image?.thumbnail}
                                    useNativeControls={false}
                                    resizeMode={ResizeMode.COVER}
                                ></VideoBase>
                            </View>
                        ) : (
                            <ImageBase
                                uri={this.props.nft.image?.thumbnail}
                                blurRadius={this.props.nft.isReported && 10}
                                style={{ height: 200, resizeMode: "cover", marginTop: -10 }}
                            />
                        )}

                        {this.props.nft.isReported && (
                            <Icon
                                name={"eye-slash"}
                                size={65}
                                color={colors.white}
                                style={{
                                    alignSelf: "center",
                                    position: "absolute",
                                    opacity: 0.6,
                                    top: 70,
                                }}
                            />
                        )}
                    </View>

                    <View style={styles.avatarContainer}>
                        <AvatarBase
                            onPress={this.onPressAvatar}
                            uri={
                                this.props.nft.owner.profileImagePath?.thumbnail
                                    ? this.props.nft.owner.profileImagePath?.thumbnail
                                    : ""
                            }
                            alias={this.props.nft.owner.alias}
                            size={40}
                        ></AvatarBase>
                    </View>
                    <PressableBase style={{ paddingHorizontal: 10, paddingTop: 10 }} onPress={this.onPressAvatar}>
                        <BoldText fontSize={12}>{trimHelper(this.props.nft.name, 20)}</BoldText>
                        <RegularText fontSize={10}>By {trimHelper(this.props.nft.owner.alias, 20)}</RegularText>
                    </PressableBase>
                    <Row style={{ position: "absolute", bottom: 10, left: 10, alignItems: "center" }}>
                        <Icon
                            size={12}
                            name={this.state.currency.getIcon()}
                            color={this.state.currency.getColor()}
                            style={{ marginRight: 5 }}
                        ></Icon>
                        {this.props.nft.price && this.props.nft.price !== "0" && (
                            <RegularText fontSize={12}>
                                {this.state.currency.fromDecimalsToString(this.props.nft.price || 0)}
                            </RegularText>
                        )}
                    </Row>
                    <View style={{ position: "absolute", bottom: 10, right: 10, alignItems: "center" }}>
                        <RegularText style={{ fontStyle: "italic" }} fontSize={12}>
                            #{this.props.nft.tokenId}
                        </RegularText>
                    </View>
                    {this.props.nft.isReported && (
                        <View
                            style={{
                                position: "absolute",
                                backgroundColor: getColorOpacity(colors.red, 0.4),
                                top: 10,
                                left: 10,
                                borderRadius: 5,
                                paddingHorizontal: 8,
                                paddingVertical: 2,
                            }}
                        >
                            <RegularText fontSize={10}>{t("reported")}</RegularText>
                        </View>
                    )}
                </PressableBase>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: colors.shadow,
        marginHorizontal: 8,
        marginVertical: 10,
        height: 300,
        borderRadius: settings.cardRadius,
        overflow: "hidden",
    },

    avatarContainer: {
        height: 44,
        width: 44,
        backgroundColor: colors.text,
        borderRadius: 22,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        alignSelf: "center",
        marginTop: -22,
    },
});
