import { StyleSheet, Appearance } from "react-native"

const lightColors = {
    primary: '#f0901d',
    secondary: '#f0901d',
    complementary: '#a67df8',
    tertiary: 'white',
    background: '#d8d9db',
    gradientFrom: '#f9f7f3',
    gradientTo: '#dbdad8',
    text: '#a67df8',
    shadow: 'rgba(255,255,255,0.7)',
    secondaryShadow: 'rgba(0, 0, 0, 0.1)',
    tertiaryShadow: 'rgba(239, 183, 66, 0.1)',
    highlightedText: '#f0901d',
    networkSymbol: '#fff',
    labelText: 'white',
    latamxo1: '#ceafd6',
    latamxo2: '#333'

}

const darkColors = lightColors;

const settings = {}

export { lightColors, darkColors, settings }
