import { MessageState } from "@utils/helpers/chat/message-state/message.state";
import store from "@store/index";
import { Sender } from "@utils/helpers/chat/message-state/sender";
import { Receiver } from "@utils/helpers/chat/message-state/receiver";


export function createMessageState(state = 0, message): MessageState {
    const userId = store.getState().auth.client?._id.toString();
    const isAuthor = (userId === message.author._id.toString());
    const messageState = (isAuthor) ? new Sender() : new Receiver();
    messageState.setMessage(message);
    messageState.state = state;
    return messageState;
}
