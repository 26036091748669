import { normalize, NormalizedSchema } from "normalizr";
import { Chat, chatScheme } from "@custom-types/Chat";
import store from "@store/index";
import { addChats, addChat, updateChat, setUnreadMessages } from "@store/actions/chat.actions";
import { Message, messageSchema } from "@custom-types/Message";
import { addMessages } from "@store/actions/message.actions";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import { Receiver } from "@utils/helpers/chat/message-state/receiver";
import { Sender } from "@utils/helpers/chat/message-state/sender";
import { chatSelectedSelector, selectMessagesFromSelectedChat } from "@store/reducers/chat.reducer";

export const reSendPending = () => {
    const messages: Array<Message> = Object.values(store.getState().message.items);
    for (let message of messages) {
        if (message.getStateNumber() === Message.PENDING_STATE) message.saveServer();
    }
};

export const saveChatsInStorage = (chats: Array<any>) => {
    const normalizedData: NormalizedSchema<any, any> = normalize(chats, [chatScheme]);
    const chatsNormalized = normalizedData.entities.chats;
    const messagesNormalized = normalizedData.entities.messages;
    let res: any = {
        items: {},
        order: [],
    };

    if (messagesNormalized) {
        Object.keys(messagesNormalized).forEach(
            (key) => (messagesNormalized[key] = Message.create(messagesNormalized[key]).decrypt()),
        );
        store.dispatch(addMessages(messagesNormalized));
    }

    if (chatsNormalized) {
        Object.keys(chatsNormalized).forEach((key) => {
            const c: Chat = Chat.create(chatsNormalized[key]);
            res.items = { ...res.items, ...{ [c.id]: c } };
            res.order = [...new Set([...res.order, c.id])];
            //store.dispatch(addChat(c));
        });
        store.dispatch(addChats(res.items, res.order));
    }
};

export const saveEarlierMessagesInStorage = (entities, chat: Chat) => {
    const normalizedData: NormalizedSchema<any, any> = normalize({ ...entities }, [messageSchema]);
    const messagesNormalized = normalizedData.entities.messages;

    if (messagesNormalized) {
        Object.keys(messagesNormalized).forEach(
            (key) => (messagesNormalized[key] = Message.create(messagesNormalized[key]).decrypt()),
        );

        store.dispatch(addMessages(messagesNormalized));
    }

    const messages = entities.filter((m) => !chat.messages.includes(m._id));
    if (messages.length > 0) {
        const messagesIds = messages.map((m) => m._id);
        chat.messages = [...chat.messages, ...messagesIds];
        store.dispatch(updateChat(chat));
    }
};

export const existsChatIsStorage = (id) => {
    const chats = store.getState().chat.items;
    return !!chats[id];
};

export const existsMessageIsStorage = (id) => {
    const messages = store.getState().message.items;
    return !!messages[id];
};

export const getLastMessageDateFromSelectedChat = () => {
    const messages: Array<Message> = selectMessagesFromSelectedChat(store.getState());
    if (messages && messages.length >= 0) {
        const idx = messages.length - 1;
        return messages[idx].createdAt;
    }

    return null;
};

export const setUnreadMessagesBase = () => {

    const reducerMessages = store.getState().message.items;
    const client = store.getState().auth.client;
    const messages = Object.values(reducerMessages).map((value) => value);
    const unread = messages.filter((m: Message) => {
        if (m.author._id !== client._id && m.state.getStateNumber() !== 3) {
            return m;
        }
    });
 
    if (store.getState().chat.unread !== unread?.length) {
        store.dispatch(setUnreadMessages(unread?.length));
    }
};
