import React, { Component } from "react";
import {  StyleSheet, FlatList, RefreshControl } from "react-native";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import { Header } from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import Post from "@custom-types/Post";
import { NavigationType } from "@custom-types/NavigationType";
import SocialNetworkService from "./service/SocialNetworkService";
import Container from "@base/Container";
import store from "@store/index";
import { addDiscoverPosts, setDiscoverPosts } from "@store/actions/social.actions";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import PostCard from "./components/PostCard";
import { colors } from "@styles/globalStyles";

interface Props {
    client: Client;
    navigation: NavigationType;
    route: any;
    discoverPosts: Array<Post>;
}

interface State {
    index: number;
    syncing: boolean;
}

const { t } = i18n;

export class _DiscoverExplorerScreen extends Component<Props, State> {
    socialNetworkService: SocialNetworkService;

    constructor(props: Props) {
        super(props);
        this.socialNetworkService = SocialNetworkService.getInstance();

        const index = this.getIndex();
        this.state = {
            index: index ? index : 0,
            syncing: false,
        };
        this.onFollow = this.onFollow.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
    }

    getIndex() {
        try {
            return this.props.discoverPosts.map((post: Post) => post.id).indexOf(this.props.route.params?.selectedPost);
        } catch (e) {}
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.discoverPosts.length !== this.props.discoverPosts.length;
    }

    renderItem = ({ item }) => (
        <PostCard isLikeable={true} onFollow={this.onFollow} post={item} navigation={this.props.navigation} />
    );

    onRefresh = async () => {
        this.setState({ syncing: true });
        var posts = await this.socialNetworkService.getPostsDiscover();

        if (posts) {
            store.dispatch(setDiscoverPosts(posts.docs));
            setTimeout(() => this.setState({ syncing: false }), 500);
        }
    };

    loadMore = async () => {
        await this.socialNetworkService.getPostsDiscover();
    };

    onFollow = async (clientID) => {
        const DiscoveryPosts = this.props.discoverPosts;

        DiscoveryPosts.map((item) => {
            if (item.author.clientID == clientID) {
                return (item.author.follow = !item.author.follow);
            }
        });
        store.dispatch(setDiscoverPosts(DiscoveryPosts));
        this.forceUpdate();
        const follow = await this.socialNetworkService.followUser(clientID);
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("discover")} type={HeaderType.Light} {...this.props} />

                <Container style={{ paddingHorizontal: 0, flex: 1 }}>
                    <FlatList
                        data={this.props.discoverPosts}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item.id}
                        contentContainerStyle={{
                            paddingBottom: 20,
                            //flex:1,
                        }}
                        initialNumToRender={4}
                        maxToRenderPerBatch={10}
                        initialScrollIndex={this.state.index}
                        onScrollToIndexFailed={(info) => {
                            const wait = new Promise((resolve) => setTimeout(resolve, 500));
                            wait.then(() => {
                                return;
                            });
                        }}
                        refreshControl={
                            <RefreshControl
                                tintColor={colors.text}
                                onRefresh={this.onRefresh}
                                refreshing={this.state.syncing}
                            />
                        }
                        onEndReachedThreshold={1}
                        extraData={this.props.discoverPosts}
                        onEndReached={this.loadMore}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        discoverPosts: state.social.discoverPosts,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const DiscoverExplorerScreen = connect(mapStateToProps, mapDispatchToProps)(_DiscoverExplorerScreen);

export default DiscoverExplorerScreen;

const styles = StyleSheet.create({});
