import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { REDEEMABLE, ExperienceStatus, RedeemableType, ExperiencesRedux, ASSET } from "@custom-types/ExpercienceModel";
import { Route } from "@react-navigation/native";
import { FlatList, View } from "react-native";
import RedeemableWideCard from "../../experiences/components/RedeemableWideCard";
import ExperienceService from "@core/services/ExperienceService";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import { Chat } from "@custom-types/Chat";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";
import BotCard from "@base/BotCard";
import AssetCard from "@screens/experiences/components/AssetCard";
import { ExperienceModuleType, ModuleControlService } from "@core/services/ModuleControlService";
import MerchantSection from "@screens/experiences/components/MerchantSection";

interface Props {
    navigation: NavigationType;
    route: Route<string, {}>;
    client: Client;
    experiences: ExperiencesRedux;
    customMessage: CustomMessageModel;
    selectedChat: Chat;
}

interface State {}

const { t } = i18n;

export class _SelectRedeemableActionsScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.onSelectAsset = this.onSelectAsset.bind(this);
        this.state = {};
    }

    async onSelectAsset(asset: ASSET) {
        const resp = await ExperienceService.getInstance().getTransferDataAsset(
            asset?._id,
            this.props.selectedChat.to._id
        );

        if (resp) {
            this.props.navigation.navigate(MessagesNavigatorScreens.ConfirmTransferAsset.routeName, {
                asset: asset,
                client_to: this.props.selectedChat.to,
                data: resp,
            });
        }
    }

    renderItem = ({ item }) => {
        return (
            <AssetCard
                asset={item}
                onlyView={true}
                onPress={(asset) => this.onSelectAsset(asset)}
                navigation={this.props.navigation}
            />
        );
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={"ASSETs"} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    {ModuleControlService.getInstance().getActiveExperienceModuleType(
                        ExperienceModuleType.exclusive
                    ) && (
                        <MerchantSection
                            navigation={this.props.navigation}
                            containerStyle={{ paddingTop: 10, paddingBottom: 25 }}
                        ></MerchantSection>
                    )}
                    <FlatList
                        data={this.props.experiences.asset?.docs}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item._id}
                        contentContainerStyle={{ paddingTop: 10, zIndex: 99 }}
                        initialNumToRender={5}
                        maxToRenderPerBatch={6}
                        numColumns={2}
                        columnWrapperStyle={{ justifyContent: "space-between", flex: 1 }}
                        showsHorizontalScrollIndicator={false}
                        ListHeaderComponent={
                            this.props.experiences.asset?.docs?.length > 0 && (
                                <BotCard message={t("bot_message_select_redeemable", { type: "ASSET" })} />
                            )
                        }
                        ListEmptyComponent={<BotCard message={t("bot_message_empty_redeemable", { type: "ASSET" })} />}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        experiences: state.experiences,
        customMessage: state.chat.customMessage,
        selectedChat: state.chat.selected,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SelectRedeemableActionsScreen = connect(mapStateToProps, mapDispatchToProps)(_SelectRedeemableActionsScreen);

export default SelectRedeemableActionsScreen;
