import Card from "@base/Card";
import RegularText from "@base/RegularText";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import LottieBase from "@components/animations/Lottie";
import AvatarBase from "@components/avatar/AvatarBase";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import Wallet from "@core/wallet/Wallet";
import { ActivityModel, ActivityTypes, DigitalCurrencyTransactionActivityData } from "@custom-types/ActivityModel";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import TransactionDetailComponent from "@screens/wallet/transaction/TransactionDetailComponent";
import { loading, ready, showModalBottom } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import moment from "moment";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    fiatCurrency?: FiatCurrency;
    activity: ActivityModel;
    client: Client;
}

interface State {
    currency: Currency;
    isOwner: boolean;
    activityData: DigitalCurrencyTransactionActivityData;
}

const { t } = i18n;

export class _ActivityCardDigitalCurrency extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currency: Wallet.getInstance().findCurrencyById(this.props?.activity?.data?.digitalCurrency?._id || ""),
            isOwner: this.props?.activity?.data?.clientFrom?._id == this.props.client?._id,
            activityData: this.props?.activity?.data,
        };
    }

    async openTransaction(data) {
        store.dispatch(loading());
        const hash = data?.txDetails?.txId || "";

        let tx = await this.state.currency.getTransactionByHash(hash);

        store.dispatch(ready());
        const transaction = this.state.currency.parseTransaction(tx);

        if (!this.state.currency || !transaction) return;
        store.dispatch(
            showModalBottom({
                modalContent: (
                    <TransactionDetailComponent
                        currency={this.state.currency}
                        transaction={transaction}
                        {...this.props}
                    />
                ),
            })
        );
    }

    render() {
        return (
            <>
                {this.state.activityData && this.state.currency && (
                    <>
                        {this.props.activity?.type == ActivityTypes.DigitalCurrencyTransaction && (
                            <Card
                                onPress={() => this.openTransaction(this.state.activityData)}
                                icon={
                                    <View>
                                        <AvatarBase
                                            size={45}
                                            uri={this.state.activityData.clientFrom?.profileImagePath?.thumbnail || ""}
                                            alias={this.state.activityData.clientFrom?.alias || ""}
                                        ></AvatarBase>
                                        <View style={styles.secondaryAvatar}>
                                            <AvatarBase
                                                size={20}
                                                uri={
                                                    this.state.activityData.clientTo?.profileImagePath?.thumbnail || ""
                                                }
                                                alias={
                                                    this.state.activityData.clientTo?.alias ||
                                                    this.state.activityData?.addressTo ||
                                                    ""
                                                }
                                            ></AvatarBase>
                                        </View>
                                    </View>
                                }
                                left={
                                    <View style={{ marginLeft: 10, justifyContent: "center" }}>
                                        {this.state.isOwner ? (
                                            <RegularText numberOfLines={1} fontSize={14} style={{ marginBottom: 2 }}>
                                                {t("you_send")}
                                                <SemiBoldText fontSize={14}>
                                                    {this.state.activityData?.clientTo
                                                        ? trimHelper(
                                                              " @" + this.state.activityData?.clientTo?.alias,
                                                              12
                                                          )
                                                        : " " + trimHelper(this.state.activityData?.addressTo, 20, true)}
                                                </SemiBoldText>{" "}
                                            </RegularText>
                                        ) : (
                                            <RegularText numberOfLines={1} fontSize={14} style={{ marginBottom: 2 }}>
                                                {t("you_receive")}
                                                <SemiBoldText fontSize={14}>
                                                    {trimHelper(" @" + this.state.activityData?.clientFrom?.alias, 12)}
                                                </SemiBoldText>{" "}
                                            </RegularText>
                                        )}

                                        <RegularText numberOfLines={1}>
                                            <View style={{ width: 24, height: 15 }}>
                                                <View style={{ position: "absolute", top: 0 }}>
                                                    <LottieBase
                                                        height={20}
                                                        width={20}
                                                        loop={
                                                            moment().diff(
                                                                moment(this.props.activity?.createdAt),
                                                                "minutes"
                                                            ) < 8
                                                        }
                                                        source={require("../../../assets/animations/crypto.json")}
                                                    />
                                                </View>
                                            </View>
                                            <RegularText fontSize={11}>{"CRYPTO"}</RegularText>
                                            <RegularText fontSize={11} color={colors.grey}>
                                                {"  ("}
                                                {moment(this.props.activity?.createdAt).fromNow()}
                                                {")"}
                                            </RegularText>
                                        </RegularText>
                                    </View>
                                }
                                right={
                                    <View style={{ alignItems: "flex-end", paddingTop: 3 }}>
                                        <CurrencyBalanceIcon
                                            style={{ paddingBottom: 5 }}
                                            fontSize={10}
                                            iconSize={11}
                                            fontWeight={"regular"}
                                            amount={this.state.currency?.toFiat(
                                                this.state.currency.fromDecimals(
                                                    Number(this.state.activityData?.txDetails?.amount)
                                                )
                                            )}
                                            fiatCurrency={this.props.fiatCurrency}
                                        />
                                        <CurrencyBalanceIcon
                                            fontSize={14}
                                            iconSize={15}
                                            trimLength={5}
                                            amount={this.state.currency.fromDecimals(
                                                Number(this.state.activityData?.txDetails?.amount)
                                            )}
                                            currency={this.state.currency}
                                        />
                                    </View>
                                }
                            ></Card>
                        )}
                    </>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    secondaryAvatar: {
        position: "absolute",
        bottom: -2,
        right: -4,
        padding: 2,
        borderRadius: 20,
        backgroundColor: colors.gradientFrom,
        zIndex: 100,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        fiatCurrency: state.wallet.fiatCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ActivityCardDigitalCurrency = connect(mapStateToProps, mapDispatchToProps)(_ActivityCardDigitalCurrency);

export default ActivityCardDigitalCurrency;
