import store from "@store/index";
import { hideScanner, loading, ready } from "@store/actions/global";
import Wallet from "@core/wallet/Wallet";
import { prePrepareTransaction, selectCurrency, setButtonActionsType } from "@store/actions/wallet";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import { SendNavigatorScreens } from "@navigation/SendNavigator";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { ButtonActionsType } from "@components/wallet/WalletsButtons";

export const isCryptoAddres = (navigation, qrCode) => {
    isCurrency(navigation, qrCode);

    const SegwitBTC = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
    const LegacyBTC = /^1[1-9A-HJ-NP-Za-km-z]{25,34}$/;
    const ETH = /^0x[a-fA-F0-9]{40}$/;
    const TRX = /^[TS][1-9A-HJ-NP-Za-km-z]{33,34}$/;
    const actionData = qrCode?.split(":") || "";

    switch (true) {
        case actionData[0] !== undefined && actionData[0] == "smartqr":
            return executeSmartQR(navigation, actionData[1], actionData[2]);
        case SegwitBTC.test(qrCode):
            return isAddress(navigation, qrCode);
        case LegacyBTC.test(qrCode):
            return isAddress(navigation, qrCode);
        case ETH.test(qrCode):
            return isAddress(navigation, qrCode);
        case TRX.test(qrCode):
            return isAddress(navigation, qrCode);

        default:
            return;
    }
};

const isAddress = (navigation, address) => {

    store.dispatch(loading());
    store.dispatch(setButtonActionsType(ButtonActionsType.SEND));
    navigation.navigate(TabsNavigatorScreens.Wallets.routeName);
    setTimeout(() => {
        navigation.navigate("Wallets", {
            screen: "SelectCurrencyButtons",
            params: {
                address: address,
            },
        });
    }, 500);
    store.dispatch(ready());
    store.dispatch(hideScanner());
};

const isCurrency = async (navigation, qrCode) => {
    const code = qrCode?.split(":") || "";
    if (code[0] !== undefined) {
        const c = Wallet.getInstance().findCurrencyByName(code[0]);
        if (c !== undefined) {
            const address = code[1].split("?")?.[0] || "";
            const amount =
                code[1].split("?")?.[1]?.split("=")?.[0] == "amount" ? code[1].split("?")?.[1]?.split("=")?.[1] : 0;
            executeSmartQR(navigation, c.getId(), address, amount);
        }
    }
};

export const executeSmartQR = async (navigation, currency, address, amount?) => {
    const c = Wallet.getInstance().findCurrencyById(currency);
    if (c) {
        store.dispatch(loading());
        store.dispatch(selectCurrency(c.getId()));
        store.dispatch(
            prePrepareTransaction({
                address: address,
                amount: amount || 0,
            }),
        );
        navigation.navigate(TabsNavigatorScreens.Wallets.routeName);
        setTimeout(() => {
            navigation.navigate(WalletNavigatorScreens.Wallet.routeName);
            navigation.navigate(WalletNavigatorScreens.Send.routeName, {
                screen: SendNavigatorScreens.SendMain.routeName,
            });
        }, 500);
    }
    store.dispatch(hideScanner());
    store.dispatch(ready());
};
