import { StyleSheet, Appearance } from "react-native";

const lightColors = {
    primary: "#d8d9db",
    secondary: "#dcaff0",
    complementary: "#b5aeff",
    tertiary: "#fff",
    background: "#d8d9db",
    gradientFrom: "#f3f5f9",
    gradientTo: "#d8d9db",
    grey: "#9091a4",
    white: "white",
    black: "#333",
    blue: "#2696fb",
    green: "#1DBC60",
    yellow: "#FECA54",
    red: "#EE4540",
    text: "#473e6b",
    shadow: "rgba(255,255,255,0.7)",
    secondaryShadow: "rgba(0, 0, 0, 0.1)",
    tertiaryShadow: "rgba(158,104,175,0.085)",
    labelText: "white",
    latamxo1: "#ceafd6",
    latamxo2: "#333",
    floatButtonText: "white",
    avatarBase: "#dcaff0",
    popupBackground: "#b5aeff",
};

const darkColors = lightColors;

const settings = {};

export { lightColors, darkColors, settings };
