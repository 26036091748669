import { MessageState } from "@utils/helpers/chat/message-state/message.state";
import { Message } from "@custom-types/Message";
import { MessageAddType, Participant } from "@utils/helpers/chat/chat.helper";

export abstract class BaseMessageState implements MessageState {
    abstract state: number;
    abstract participant: Participant;

    message: Message;

    constructor() {}
    abstract saveEarlier();
    abstract processNewMessage();

    setMessage(message: Message) {
        this.message = message;
    }

    getStateNumber() {
        return Number(this.state);
    }

    saveServer() {
        this.message.saveServer();
    }

    saveStorage(type?, decrypt?) {
        this.message.saveStorage(type, decrypt);
    }

    updateServer() {
        this.message.updateServer();
    }

    updateStorage() {
        this.message.updateStorage();
    }

}