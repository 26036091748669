import { all, fork } from 'redux-saga/effects';
import wallet from './wallet';
import globals from './globals';

const rootSaga = function* root() {
  yield all([
    fork(globals),
    fork(wallet),
  ]);
};

export default rootSaga;