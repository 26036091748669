import { hideScanner } from "@store/actions/global";
import store from "@store/index";

export const isProfile = (navigation, qrCode) => {
    const extractQuery = qrCode?.split("?");
    const params = extractQuery[1]?.split("&");

    if(!params || params == undefined) {
        return;
    }

     const type = params[0]?.split("=")?.[1] || "";
    const id = params[1]?.split("=")?.[1] || "";

    
    switch (type) {
        case "profile":
            return getProfile(navigation, id);
        default:
            return;
    }
};

export const getProfile = async (navigation, id) => {
    navigation.navigate("Profile", {
        screen: "ProfileMain",
        params: { clientID: id },
    });

    store.dispatch(hideScanner());
};
