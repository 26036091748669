import BoldText from "@base/BoldText";
import Card from "@base/Card";
import RegularText from "@base/RegularText";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import AvatarBase from "@components/avatar/AvatarBase";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import ActivityService from "@core/services/ActivityService";
import Wallet from "@core/wallet/Wallet";
import { ActivityModel, ActivityTypes, DigitalCurrencyExchangeActivityData } from "@custom-types/ActivityModel";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import ExchangeDetailComponent from "@screens/wallet/exchange/ExchangeDetailComponent";
import { showModalBottom } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import moment from "moment";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    fiatCurrency?: FiatCurrency;
    activity: ActivityModel;
    client: Client;
}

interface State {
    fromDigitalCurrency: Currency;
    toDigitalCurrency: Currency;
    activityData: DigitalCurrencyExchangeActivityData;
}

const { t } = i18n;

export class _ActivityCardDigitalCurrencyExchange extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.openSwap = this.openSwap.bind(this);
        this.state = {
            fromDigitalCurrency: Wallet.getInstance().findCurrencyById(
                this.props?.activity?.data?.fromDigitalCurrency || ""
            ),
            toDigitalCurrency: Wallet.getInstance().findCurrencyById(
                this.props?.activity?.data?.toDigitalCurrency || ""
            ),
            // isOwner: this.props?.activity?.data?.clientFrom?._id == this.props.client?._id,
            activityData: this.props?.activity?.data,
        };
    }

    async openSwap() {
        const resp = await ActivityService.getInstance().getActivityById(this.props?.activity?._id);
        const activity = resp || this.props?.activity;
        store.dispatch(
            showModalBottom({
                modalContent: <ExchangeDetailComponent rawData={activity} />,
            })
        );
    }

    render() {
        return (
            <>
                {this.state.activityData && (
                    <>
                        {this.props.activity?.type == ActivityTypes.DigitalCurrencyExchange && (
                            <Card
                                onPress={this.openSwap}
                                icon={
                                    <View>
                                        <CurrencyIcon
                                            size={45}
                                            currency={this.state.fromDigitalCurrency}
                                        ></CurrencyIcon>
                                        <View style={styles.secondaryAvatar}>
                                            <CurrencyIcon
                                                size={20}
                                                currency={this.state.toDigitalCurrency}
                                            ></CurrencyIcon>
                                        </View>
                                    </View>
                                }
                                left={
                                    <View style={{ marginLeft: 10, justifyContent: "center", maxWidth: 250 }}>
                                        <RegularText numberOfLines={2} fontSize={14} style={{}}>
                                            {t("changed")}
                                            <SemiBoldText fontSize={14}>
                                                <BoldText fontSize={14}>
                                                    {" "}
                                                    {trimHelper(
                                                        this.state.fromDigitalCurrency
                                                            .fromDecimals(Number(this.state.activityData?.amount))
                                                            ?.toString(),

                                                        8,
                                                        true
                                                    )}{" "}
                                                </BoldText>
                                                {this.state.fromDigitalCurrency?.getSymbol()}
                                            </SemiBoldText>{" "}
                                            {t("in")}
                                            <SemiBoldText numberOfLines={1} fontSize={14} style={{ marginBottom: 2 }}>
                                                {" "}
                                                {this.state.activityData?.exchange?.name}{" "}
                                            </SemiBoldText>
                                            <View style={{ width: 22, height: 15 }}>
                                                <View style={{ position: "absolute", top: 2 }}>
                                                    <AvatarBase
                                                        size={14}
                                                        uri={this.state.activityData?.exchange?.image || ""}
                                                    ></AvatarBase>
                                                </View>
                                            </View>
                                            <RegularText fontSize={11} color={colors.grey}>
                                                {" ("}
                                                {moment(this.props.activity?.createdAt).fromNow()}
                                                {")"}
                                            </RegularText>
                                        </RegularText>
                                    </View>
                                }
                                right={
                                    <View style={{ alignItems: "flex-end", paddingTop: 3 }}>
                                        <CurrencyBalanceIcon
                                            style={{ paddingBottom: 5 }}
                                            fontSize={10}
                                            iconSize={11}
                                            fontWeight={"regular"}
                                            amount={this.state.toDigitalCurrency.toFiat(
                                                this.state.toDigitalCurrency.fromDecimals(
                                                    Number(
                                                        this.state.activityData?.amountReceived ||
                                                            this.state.activityData?.estimatedAmount
                                                    )
                                                )
                                            )}
                                            fiatCurrency={this.props.fiatCurrency}
                                        />
                                        <CurrencyBalanceIcon
                                            equalSign={!this.state.activityData?.amountReceived}
                                            fontSize={14}
                                            iconSize={15}
                                            trimLength={5}
                                            amount={this.state.toDigitalCurrency.fromDecimals(
                                                Number(
                                                    this.state.activityData?.amountReceived ||
                                                        this.state.activityData?.estimatedAmount
                                                )
                                            )}
                                            currency={this.state.toDigitalCurrency}
                                        />
                                    </View>
                                }
                            ></Card>
                        )}
                    </>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    secondaryAvatar: {
        position: "absolute",
        bottom: -2,
        right: -4,
        padding: 2,
        borderRadius: 20,
        backgroundColor: colors.gradientFrom,
        zIndex: 100,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        fiatCurrency: state.wallet.fiatCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ActivityCardDigitalCurrencyExchange = connect(
    mapStateToProps,
    mapDispatchToProps
)(_ActivityCardDigitalCurrencyExchange);

export default ActivityCardDigitalCurrencyExchange;
