import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import Container from "@base/Container";
import RegularText from "@base/RegularText";
import { Linking, StyleSheet,  View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import Logo from "@assets/images/Logo";
import InlineButton from "@base/InlineButton";
import store from "@store/index";
import { acceptTerms, loading, ready } from "@store/actions/global";
import AppStorage from "@utils/storage";
import Constants from "expo-constants";
import Initializer from "@screens/initializer/Initializer";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
}

interface State {}

const { t } = i18n;

export default class TermsScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPressAccept = this.onPressAccept.bind(this);
        this.onPressTerms = this.onPressTerms.bind(this);
        this.state = {};
    }

    componentDidMount(): void {
        store.dispatch(ready());
    }

    onPressTerms = async () => {
        const url = `https://legal.${Constants.expoConfig?.extra?.url}` || "";
        Linking.canOpenURL(url).then((supported) => (supported ? Linking.openURL(url) : null));
    };

    onPressAccept() {
        store.dispatch(
            acceptTerms({
                onSuccess: () => {
                    AppStorage.setItem("terms", { accepted: true });
                    Initializer.redirect(this.props.navigation);
                },
            }),
        );
    }

    render() {
        return (
            <ScreenWrapper>
                <Container style={styles.container}>
                    <View style={{ alignItems: "center", alignContent: "center" }}>
                        <View style={styles.logo}>
                            <Logo style={{ width: 180, height: 180, marginTop: 50, marginBottom: 25 }} />
                        </View>
                        <View>
                            <BoldText align="center" fontSize={24}>
                                {t("terms")}
                            </BoldText>
                            <PressableBase disableOpacity={true} onPress={this.onPressTerms}>
                                <RegularText color={colors.text} style={styles.text} align={"center"} fontSize={16}>
                                    {t("before_continuing")}
                                    <BoldText color={colors.text} fontSize={16}>
                                        {" "}
                                        {t("terms_and_conditions")}{" "}
                                    </BoldText>
                                    {t("if_you_do_not")}
                                </RegularText>
                            </PressableBase>
                        </View>
                    </View>
                    <View style={{ paddingVertical: 25, width: "100%" }}>
                        <InlineButton title={t("i_accept")} onPress={this.onPressAccept}></InlineButton>
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
        alignItems: "center",
        alignContent: "center",
    },
    logo: {
        marginTop: 50,
    },
    text: {},
});
