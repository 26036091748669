import NavigationInstantiator from "./helpers/NavigationInstantiator";
import ActivityScreen from "@screens/activity/ActivityScreen";

const ActivityNavigatorScreens = {
    ActivityMain: {
        routeName: "ActivityMain",
        title: "ActivityMain",
        component: ActivityScreen,
    },
};

const ActivityNavigator = NavigationInstantiator(ActivityNavigatorScreens);

export { ActivityNavigator, ActivityNavigatorScreens };
