import React, { Component } from "react";
import { View, StyleSheet, FlatList, RefreshControl, ViewStyle } from "react-native";
import i18n from "@i18n/i18n";
import CircleButton from "@base/CircleButton";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import Card from "@base/Card";
import Row from "@base/Row";
import BoldText from "@base/BoldText";
import { IOAuthHistoryStorage, OAuthHistoryStorage } from "@utils/storage/storages/OAuthHistoryStorage";
import * as Clipboard from "expo-clipboard";
import { colors } from "@styles/globalStyles";
import SeedHistorySecureStorage from "@utils/storage/storages/SeedHistorySecureStorage";
import BotCard from "@base/BotCard";
import store from "@store/index";
import { showSnackbar } from "@store/actions/global";

interface Props {
    navigation: any;
    contentContainerStyle?: ViewStyle;
    listTitle?: string;
}

interface State {
    seedHistory: Array<any>;
    selected: string;
}

const { t } = i18n;

export default class OldSeedHistoryList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.loadState = this.loadState.bind(this);

        this.state = {
            seedHistory: [],
            selected: "",
        };
    }

    async componentDidMount() {
        await this.loadState();
    }

    async loadState() {
        let history: IOAuthHistoryStorage[] = (await OAuthHistoryStorage.get()) || [];

        let seedHistory = await SeedHistorySecureStorage.get();

        if (seedHistory) {
            seedHistory = seedHistory.filter((s) => {
                return !history.find((h) => {
                    return h.mnemonic == s.mnemonic;
                });
            });
            this.setState({ seedHistory: seedHistory });
        }
    }

    async removeItem(mnemonic) {
        let seedHistory = await SeedHistorySecureStorage.get();
        seedHistory = seedHistory.filter((s) => {
            return s.mnemonic !== mnemonic;
        });
        await SeedHistorySecureStorage.set(seedHistory);
        this.loadState();
    }

    copyToClipboard(mnemonic) {
        Clipboard.setStringAsync(mnemonic);
        store.dispatch(showSnackbar({ type: "SUCCESS", message: t("copied") }));
    }

    render() {
        return (
            <View style={this.props.contentContainerStyle}>
                {this.state.seedHistory?.length > 0 && (
                    <View style={{ flex: 1, paddingTop: 15, paddingBottom: 25 }}>
                        <BotCard title={t("important")} message={t("seed_history_warning")}></BotCard>
                        {this.state.seedHistory.map((h) => {
                            return (
                                <Card
                                    key={h.mnemonic?.split(" ")[0]}
                                    left={
                                        <View>
                                            <BoldText fontSize={12}>{trimHelper(h.mnemonic, 25)}</BoldText>
                                        </View>
                                    }
                                    right={
                                        <Row style={{ zIndex: 99, alignItems: "center" }}>
                                            <CircleButton
                                                style={{ width: 30, height: 30, borderRadius: 15 }}
                                                iconSize={15}
                                                icon="content-copy"
                                                onPress={() => this.copyToClipboard(h.mnemonic)}
                                            ></CircleButton>
                                            <CircleButton
                                                style={{ width: 30, height: 30, borderRadius: 15 }}
                                                iconSize={15}
                                                icon="trash"
                                                onPress={() => this.removeItem(h.mnemonic)}
                                            ></CircleButton>
                                        </Row>
                                    }
                                />
                            );
                        })}
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    active: {
        backgroundColor: colors.secondary,
        borderRadius: 5,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        paddingHorizontal: 5,
    },
});
