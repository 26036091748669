import ExchangeScreen from "@screens/exchange/ExchangeScreen"
import SelectCurrencyFromScreen from "@screens/exchange/SelectCurrencyFromScreen"
import ConfirmExchangeScreen from "@screens/exchange/ConfirmExchangeScreen"
import ApproveExchangeScreen from "@screens/exchange/ApproveExchangeScreen"
import ApprovingExchangeScreen from "@screens/exchange/ApprovingExchangeScreen"
import SelectCurrencyToScreen from "@screens/exchange/SelectCurrencyToScreen"
import NavigationInstantiator from "./helpers/NavigationInstantiator"
import ExchangeHistoryScreen from "@screens/exchange/ExchangeHistoryScreen"

const ExchangeNavigatorScreens = {
    Exchange: {
        routeName: "Exchange",
        title: "Exchange",
        component: ExchangeScreen,
    },
    ExchangeHistory: {
        routeName: "ExchangeHistory",
        title: "ExchangeHistory",
        component: ExchangeHistoryScreen,
    },
    SelectCurrencyFrom: {
        routeName: "SelectCurrencyFrom",
        title: "Select Currency From",
        component: SelectCurrencyFromScreen,
    },
    SelectCurrencyTo: {
        routeName: "SelectCurrencyTo",
        title: "Select Currency To",
        component: SelectCurrencyToScreen,
    },
    ConfirmExchange: {
        routeName: "ConfirmExchange",
        title: "Confirm Exchange",
        component: ConfirmExchangeScreen,
    },
    ApproveExchange: {
        routeName: "ApproveExchange",
        title: "Approve Exchange",
        component: ApproveExchangeScreen,
    },
    ApprovingExchange: {
        routeName: "ApprovingExchange",
        title: "Approving Exchange",
        component: ApprovingExchangeScreen,
    }
}

const ExchangeNavigator = NavigationInstantiator(ExchangeNavigatorScreens)

export {ExchangeNavigator, ExchangeNavigatorScreens}
