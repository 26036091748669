import React, { Component } from "react";
import { View, StyleSheet } from "react-native";

import i18n from "@i18n/i18n";
import { NavigationType } from "@custom-types/NavigationType";




import UsersScreen, { _UsersScreen } from "@screens/social/UsersScreen";
import { connect } from "react-redux";

const { t } = i18n;

interface Props {
    navigation: NavigationType;
    
}

interface State {}

export class _UsersExtendedScreen extends _UsersScreen {
    constructor(props: any) {
        super(props);
      }
    }
    
    const mapStateToProps = (state) => {
        return {
            client: state.auth.client,
        }
      };
    
    const mapDispatchToProps = (dispatch) => ({});
    
    const UsersExtendedScreen = connect(mapStateToProps, mapDispatchToProps)(_UsersExtendedScreen);
    
    export default UsersExtendedScreen;
