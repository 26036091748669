import React, { Component } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { connect } from "react-redux";
import Container from "@base/Container";
import { colors, settings } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import AvatarBase from "@components/avatar/AvatarBase";
import BotCard from "@base/BotCard";
import i18n from "@i18n/i18n";
import PressableBase from "@base/PressableBase";
import SearchBase from "@components/search/SearchBase";

const { t } = i18n;

interface Country {
    flag: string;
    country: string;
}

interface Props {
    search: string;
    nationalitySelected: (text: string) => void;
}

interface State {
    countries: Array<Country>;
    filteredCountries: Array<Country>;
    setType: any;
}

export class _NationalitySection extends Component<Props, State> {
    focusListener: any;

    countries = [
        {
            flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png",
            country: "Afghanistan",
        },
        { flag: "https://flagcdn.com/w320/al.png", country: "Albania" },
        { flag: "https://flagcdn.com/w320/dz.png", country: "Algeria" },
        { flag: "https://flagcdn.com/w320/as.png", country: "American Samoa" },
        { flag: "https://flagcdn.com/w320/ad.png", country: "Andorra" },
        { flag: "https://flagcdn.com/w320/ao.png", country: "Angola" },
        { flag: "https://flagcdn.com/w320/ai.png", country: "Anguilla" },
        { flag: "https://flagcdn.com/w320/aq.png", country: "Antarctica" },
        { flag: "https://flagcdn.com/w320/ag.png", country: "Antigua and Barbuda" },
        { flag: "https://flagcdn.com/w320/ar.png", country: "Argentina" },
        { flag: "https://flagcdn.com/w320/am.png", country: "Armenia" },
        { flag: "https://flagcdn.com/w320/aw.png", country: "Aruba" },
        { flag: "https://flagcdn.com/w320/au.png", country: "Australia" },
        { flag: "https://flagcdn.com/w320/at.png", country: "Austria" },
        { flag: "https://flagcdn.com/w320/az.png", country: "Azerbaijan" },
        { flag: "https://flagcdn.com/w320/bs.png", country: "Bahamas" },
        { flag: "https://flagcdn.com/w320/bh.png", country: "Bahrain" },
        { flag: "https://flagcdn.com/w320/bd.png", country: "Bangladesh" },
        { flag: "https://flagcdn.com/w320/bb.png", country: "Barbados" },
        { flag: "https://flagcdn.com/w320/by.png", country: "Belarus" },
        { flag: "https://flagcdn.com/w320/be.png", country: "Belgium" },
        { flag: "https://flagcdn.com/w320/bz.png", country: "Belize" },
        { flag: "https://flagcdn.com/w320/bj.png", country: "Benin" },
        { flag: "https://flagcdn.com/w320/bm.png", country: "Bermuda" },
        { flag: "https://flagcdn.com/w320/bt.png", country: "Bhutan" },
        { flag: "https://flagcdn.com/w320/bo.png", country: "Bolivia" },
        { flag: "https://flagcdn.com/w320/ba.png", country: "Bosnia and Herzegovina" },
        { flag: "https://flagcdn.com/w320/bw.png", country: "Botswana" },
        { flag: "https://flagcdn.com/w320/bv.png", country: "Bouvet Island" },
        { flag: "https://flagcdn.com/w320/br.png", country: "Brazil" },
        { flag: "https://flagcdn.com/w320/io.png", country: "British Indian Ocean Territory" },
        { flag: "https://flagcdn.com/w320/vg.png", country: "British Virgin Islands" },
        { flag: "https://flagcdn.com/w320/bn.png", country: "Brunei" },
        { flag: "https://flagcdn.com/w320/bg.png", country: "Bulgaria" },
        { flag: "https://flagcdn.com/w320/bf.png", country: "Burkina Faso" },
        { flag: "https://flagcdn.com/w320/bi.png", country: "Burundi" },
        { flag: "https://flagcdn.com/w320/kh.png", country: "Cambodia" },
        { flag: "https://flagcdn.com/w320/cm.png", country: "Cameroon" },
        { flag: "https://flagcdn.com/w320/ca.png", country: "Canada" },
        { flag: "https://flagcdn.com/w320/cv.png", country: "Cape Verde" },
        { flag: "https://flagcdn.com/w320/bq.png", country: "Caribbean Netherlands" },
        { flag: "https://flagcdn.com/w320/ky.png", country: "Cayman Islands" },
        { flag: "https://flagcdn.com/w320/cf.png", country: "Central African Republic" },
        { flag: "https://flagcdn.com/w320/td.png", country: "Chad" },
        { flag: "https://flagcdn.com/w320/cl.png", country: "Chile" },
        { flag: "https://flagcdn.com/w320/cn.png", country: "China" },
        { flag: "https://flagcdn.com/w320/cx.png", country: "Christmas Island" },
        { flag: "https://flagcdn.com/w320/cc.png", country: "Cocos (Keeling) Islands" },
        { flag: "https://flagcdn.com/w320/co.png", country: "Colombia" },
        { flag: "https://flagcdn.com/w320/km.png", country: "Comoros" },
        { flag: "https://flagcdn.com/w320/ck.png", country: "Cook Islands" },
        { flag: "https://flagcdn.com/w320/cr.png", country: "Costa Rica" },
        { flag: "https://flagcdn.com/w320/hr.png", country: "Croatia" },
        { flag: "https://flagcdn.com/w320/cu.png", country: "Cuba" },
        { flag: "https://flagcdn.com/w320/cw.png", country: "Curaçao" },
        { flag: "https://flagcdn.com/w320/cy.png", country: "Cyprus" },
        { flag: "https://flagcdn.com/w320/cz.png", country: "Czechia" },
        { flag: "https://flagcdn.com/w320/cd.png", country: "DR Congo" },
        { flag: "https://flagcdn.com/w320/dk.png", country: "Denmark" },
        { flag: "https://flagcdn.com/w320/dj.png", country: "Djibouti" },
        { flag: "https://flagcdn.com/w320/dm.png", country: "Dominica" },
        { flag: "https://flagcdn.com/w320/do.png", country: "Dominican Republic" },
        { flag: "https://flagcdn.com/w320/ec.png", country: "Ecuador" },
        { flag: "https://flagcdn.com/w320/eg.png", country: "Egypt" },
        { flag: "https://flagcdn.com/w320/sv.png", country: "El Salvador" },
        { flag: "https://flagcdn.com/w320/gq.png", country: "Equatorial Guinea" },
        { flag: "https://flagcdn.com/w320/er.png", country: "Eritrea" },
        { flag: "https://flagcdn.com/w320/ee.png", country: "Estonia" },
        { flag: "https://flagcdn.com/w320/sz.png", country: "Eswatini" },
        { flag: "https://flagcdn.com/w320/et.png", country: "Ethiopia" },
        { flag: "https://flagcdn.com/w320/fk.png", country: "Falkland Islands" },
        { flag: "https://flagcdn.com/w320/fo.png", country: "Faroe Islands" },
        { flag: "https://flagcdn.com/w320/fj.png", country: "Fiji" },
        { flag: "https://flagcdn.com/w320/fi.png", country: "Finland" },
        { flag: "https://flagcdn.com/w320/fr.png", country: "France" },
        { flag: "https://flagcdn.com/w320/gf.png", country: "French Guiana" },
        { flag: "https://flagcdn.com/w320/pf.png", country: "French Polynesia" },
        { flag: "https://flagcdn.com/w320/tf.png", country: "French Southern and Antarctic Lands" },
        { flag: "https://flagcdn.com/w320/ga.png", country: "Gabon" },
        { flag: "https://flagcdn.com/w320/gm.png", country: "Gambia" },
        { flag: "https://flagcdn.com/w320/ge.png", country: "Georgia" },
        { flag: "https://flagcdn.com/w320/de.png", country: "Germany" },
        { flag: "https://flagcdn.com/w320/gh.png", country: "Ghana" },
        { flag: "https://flagcdn.com/w320/gi.png", country: "Gibraltar" },
        { flag: "https://flagcdn.com/w320/gr.png", country: "Greece" },
        { flag: "https://flagcdn.com/w320/gl.png", country: "Greenland" },
        { flag: "https://flagcdn.com/w320/gd.png", country: "Grenada" },
        { flag: "https://flagcdn.com/w320/gp.png", country: "Guadeloupe" },
        { flag: "https://flagcdn.com/w320/gu.png", country: "Guam" },
        { flag: "https://flagcdn.com/w320/gt.png", country: "Guatemala" },
        { flag: "https://flagcdn.com/w320/gg.png", country: "Guernsey" },
        { flag: "https://flagcdn.com/w320/gn.png", country: "Guinea" },
        { flag: "https://flagcdn.com/w320/gw.png", country: "Guinea-Bissau" },
        { flag: "https://flagcdn.com/w320/gy.png", country: "Guyana" },
        { flag: "https://flagcdn.com/w320/ht.png", country: "Haiti" },
        { flag: "https://flagcdn.com/w320/hm.png", country: "Heard Island and McDonald Islands" },
        { flag: "https://flagcdn.com/w320/hn.png", country: "Honduras" },
        { flag: "https://flagcdn.com/w320/hk.png", country: "Hong Kong" },
        { flag: "https://flagcdn.com/w320/hu.png", country: "Hungary" },
        { flag: "https://flagcdn.com/w320/is.png", country: "Iceland" },
        { flag: "https://flagcdn.com/w320/in.png", country: "India" },
        { flag: "https://flagcdn.com/w320/id.png", country: "Indonesia" },
        { flag: "https://flagcdn.com/w320/ir.png", country: "Iran" },
        { flag: "https://flagcdn.com/w320/iq.png", country: "Iraq" },
        { flag: "https://flagcdn.com/w320/ie.png", country: "Ireland" },
        { flag: "https://flagcdn.com/w320/im.png", country: "Isle of Man" },
        { flag: "https://flagcdn.com/w320/il.png", country: "Israel" },
        { flag: "https://flagcdn.com/w320/it.png", country: "Italy" },
        { flag: "https://flagcdn.com/w320/ci.png", country: "Ivory Coast" },
        { flag: "https://flagcdn.com/w320/jm.png", country: "Jamaica" },
        { flag: "https://flagcdn.com/w320/jp.png", country: "Japan" },
        { flag: "https://flagcdn.com/w320/je.png", country: "Jersey" },
        { flag: "https://flagcdn.com/w320/jo.png", country: "Jordan" },
        { flag: "https://flagcdn.com/w320/kz.png", country: "Kazakhstan" },
        { flag: "https://flagcdn.com/w320/ke.png", country: "Kenya" },
        { flag: "https://flagcdn.com/w320/ki.png", country: "Kiribati" },
        { flag: "https://flagcdn.com/w320/xk.png", country: "Kosovo" },
        { flag: "https://flagcdn.com/w320/kw.png", country: "Kuwait" },
        { flag: "https://flagcdn.com/w320/kg.png", country: "Kyrgyzstan" },
        { flag: "https://flagcdn.com/w320/la.png", country: "Laos" },
        { flag: "https://flagcdn.com/w320/lv.png", country: "Latvia" },
        { flag: "https://flagcdn.com/w320/lb.png", country: "Lebanon" },
        { flag: "https://flagcdn.com/w320/ls.png", country: "Lesotho" },
        { flag: "https://flagcdn.com/w320/lr.png", country: "Liberia" },
        { flag: "https://flagcdn.com/w320/ly.png", country: "Libya" },
        { flag: "https://flagcdn.com/w320/li.png", country: "Liechtenstein" },
        { flag: "https://flagcdn.com/w320/lt.png", country: "Lithuania" },
        { flag: "https://flagcdn.com/w320/lu.png", country: "Luxembourg" },
        { flag: "https://flagcdn.com/w320/mo.png", country: "Macau" },
        { flag: "https://flagcdn.com/w320/mg.png", country: "Madagascar" },
        { flag: "https://flagcdn.com/w320/mw.png", country: "Malawi" },
        { flag: "https://flagcdn.com/w320/my.png", country: "Malaysia" },
        { flag: "https://flagcdn.com/w320/mv.png", country: "Maldives" },
        { flag: "https://flagcdn.com/w320/ml.png", country: "Mali" },
        { flag: "https://flagcdn.com/w320/mt.png", country: "Malta" },
        { flag: "https://flagcdn.com/w320/mh.png", country: "Marshall Islands" },
        { flag: "https://flagcdn.com/w320/mq.png", country: "Martinique" },
        { flag: "https://flagcdn.com/w320/mr.png", country: "Mauritania" },
        { flag: "https://flagcdn.com/w320/mu.png", country: "Mauritius" },
        { flag: "https://flagcdn.com/w320/yt.png", country: "Mayotte" },
        { flag: "https://flagcdn.com/w320/mx.png", country: "Mexico" },
        { flag: "https://flagcdn.com/w320/fm.png", country: "Micronesia" },
        { flag: "https://flagcdn.com/w320/md.png", country: "Moldova" },
        { flag: "https://flagcdn.com/w320/mc.png", country: "Monaco" },
        { flag: "https://flagcdn.com/w320/mn.png", country: "Mongolia" },
        { flag: "https://flagcdn.com/w320/me.png", country: "Montenegro" },
        { flag: "https://flagcdn.com/w320/ms.png", country: "Montserrat" },
        { flag: "https://flagcdn.com/w320/ma.png", country: "Morocco" },
        { flag: "https://flagcdn.com/w320/mz.png", country: "Mozambique" },
        { flag: "https://flagcdn.com/w320/mm.png", country: "Myanmar" },
        { flag: "https://flagcdn.com/w320/na.png", country: "Namibia" },
        { flag: "https://flagcdn.com/w320/nr.png", country: "Nauru" },
        { flag: "https://flagcdn.com/w320/np.png", country: "Nepal" },
        { flag: "https://flagcdn.com/w320/nl.png", country: "Netherlands" },
        { flag: "https://flagcdn.com/w320/nc.png", country: "New Caledonia" },
        { flag: "https://flagcdn.com/w320/nz.png", country: "New Zealand" },
        { flag: "https://flagcdn.com/w320/ni.png", country: "Nicaragua" },
        { flag: "https://flagcdn.com/w320/ne.png", country: "Niger" },
        { flag: "https://flagcdn.com/w320/ng.png", country: "Nigeria" },
        { flag: "https://flagcdn.com/w320/nu.png", country: "Niue" },
        { flag: "https://flagcdn.com/w320/nf.png", country: "Norfolk Island" },
        { flag: "https://flagcdn.com/w320/kp.png", country: "North Korea" },
        { flag: "https://flagcdn.com/w320/mk.png", country: "North Macedonia" },
        { flag: "https://flagcdn.com/w320/mp.png", country: "Northern Mariana Islands" },
        { flag: "https://flagcdn.com/w320/no.png", country: "Norway" },
        { flag: "https://flagcdn.com/w320/om.png", country: "Oman" },
        { flag: "https://flagcdn.com/w320/pk.png", country: "Pakistan" },
        { flag: "https://flagcdn.com/w320/pw.png", country: "Palau" },
        { flag: "https://flagcdn.com/w320/ps.png", country: "Palestine" },
        { flag: "https://flagcdn.com/w320/pa.png", country: "Panama" },
        { flag: "https://flagcdn.com/w320/pg.png", country: "Papua New Guinea" },
        { flag: "https://flagcdn.com/w320/py.png", country: "Paraguay" },
        { flag: "https://flagcdn.com/w320/pe.png", country: "Peru" },
        { flag: "https://flagcdn.com/w320/ph.png", country: "Philippines" },
        { flag: "https://flagcdn.com/w320/pn.png", country: "Pitcairn Islands" },
        { flag: "https://flagcdn.com/w320/pl.png", country: "Poland" },
        { flag: "https://flagcdn.com/w320/pt.png", country: "Portugal" },
        { flag: "https://flagcdn.com/w320/pr.png", country: "Puerto Rico" },
        { flag: "https://flagcdn.com/w320/qa.png", country: "Qatar" },
        { flag: "https://flagcdn.com/w320/cg.png", country: "Republic of the Congo" },
        { flag: "https://flagcdn.com/w320/ro.png", country: "Romania" },
        { flag: "https://flagcdn.com/w320/ru.png", country: "Russia" },
        { flag: "https://flagcdn.com/w320/rw.png", country: "Rwanda" },
        { flag: "https://flagcdn.com/w320/re.png", country: "Réunion" },
        { flag: "https://flagcdn.com/w320/bl.png", country: "Saint Barthélemy" },
        { flag: "https://flagcdn.com/w320/sh.png", country: "Saint Helena, Ascension and Tristan da Cunha" },
        { flag: "https://flagcdn.com/w320/kn.png", country: "Saint Kitts and Nevis" },
        { flag: "https://flagcdn.com/w320/lc.png", country: "Saint Lucia" },
        { flag: "https://flagcdn.com/w320/mf.png", country: "Saint Martin" },
        { flag: "https://flagcdn.com/w320/pm.png", country: "Saint Pierre and Miquelon" },
        { flag: "https://flagcdn.com/w320/vc.png", country: "Saint Vincent and the Grenadines" },
        { flag: "https://flagcdn.com/w320/ws.png", country: "Samoa" },
        { flag: "https://flagcdn.com/w320/sm.png", country: "San Marino" },
        { flag: "https://flagcdn.com/w320/sa.png", country: "Saudi Arabia" },
        { flag: "https://flagcdn.com/w320/sn.png", country: "Senegal" },
        { flag: "https://flagcdn.com/w320/rs.png", country: "Serbia" },
        { flag: "https://flagcdn.com/w320/sc.png", country: "Seychelles" },
        { flag: "https://flagcdn.com/w320/sl.png", country: "Sierra Leone" },
        { flag: "https://flagcdn.com/w320/sg.png", country: "Singapore" },
        { flag: "https://flagcdn.com/w320/sx.png", country: "Sint Maarten" },
        { flag: "https://flagcdn.com/w320/sk.png", country: "Slovakia" },
        { flag: "https://flagcdn.com/w320/si.png", country: "Slovenia" },
        { flag: "https://flagcdn.com/w320/sb.png", country: "Solomon Islands" },
        { flag: "https://flagcdn.com/w320/so.png", country: "Somalia" },
        { flag: "https://flagcdn.com/w320/za.png", country: "South Africa" },
        { flag: "https://flagcdn.com/w320/gs.png", country: "South Georgia" },
        { flag: "https://flagcdn.com/w320/kr.png", country: "South Korea" },
        { flag: "https://flagcdn.com/w320/ss.png", country: "South Sudan" },
        { flag: "https://flagcdn.com/w320/es.png", country: "Spain" },
        { flag: "https://flagcdn.com/w320/lk.png", country: "Sri Lanka" },
        { flag: "https://flagcdn.com/w320/sd.png", country: "Sudan" },
        { flag: "https://flagcdn.com/w320/sr.png", country: "Suriname" },
        { flag: "https://flagcdn.com/w320/sj.png", country: "Svalbard and Jan Mayen" },
        { flag: "https://flagcdn.com/w320/se.png", country: "Sweden" },
        { flag: "https://flagcdn.com/w320/ch.png", country: "Switzerland" },
        { flag: "https://flagcdn.com/w320/sy.png", country: "Syria" },
        { flag: "https://flagcdn.com/w320/st.png", country: "São Tomé and Príncipe" },
        { flag: "https://flagcdn.com/w320/tw.png", country: "Taiwan" },
        { flag: "https://flagcdn.com/w320/tj.png", country: "Tajikistan" },
        { flag: "https://flagcdn.com/w320/tz.png", country: "Tanzania" },
        { flag: "https://flagcdn.com/w320/th.png", country: "Thailand" },
        { flag: "https://flagcdn.com/w320/tl.png", country: "Timor-Leste" },
        { flag: "https://flagcdn.com/w320/tg.png", country: "Togo" },
        { flag: "https://flagcdn.com/w320/tk.png", country: "Tokelau" },
        { flag: "https://flagcdn.com/w320/to.png", country: "Tonga" },
        { flag: "https://flagcdn.com/w320/tt.png", country: "Trinidad and Tobago" },
        { flag: "https://flagcdn.com/w320/tn.png", country: "Tunisia" },
        { flag: "https://flagcdn.com/w320/tr.png", country: "Turkey" },
        { flag: "https://flagcdn.com/w320/tm.png", country: "Turkmenistan" },
        { flag: "https://flagcdn.com/w320/tc.png", country: "Turks and Caicos Islands" },
        { flag: "https://flagcdn.com/w320/tv.png", country: "Tuvalu" },
        { flag: "https://flagcdn.com/w320/ug.png", country: "Uganda" },
        { flag: "https://flagcdn.com/w320/ua.png", country: "Ukraine" },
        { flag: "https://flagcdn.com/w320/ae.png", country: "United Arab Emirates" },
        { flag: "https://flagcdn.com/w320/gb.png", country: "United Kingdom" },
        { flag: "https://flagcdn.com/w320/us.png", country: "United States" },
        { flag: "https://flagcdn.com/w320/um.png", country: "United States Minor Outlying Islands" },
        { flag: "https://flagcdn.com/w320/vi.png", country: "United States Virgin Islands" },
        { flag: "https://flagcdn.com/w320/uy.png", country: "Uruguay" },
        { flag: "https://flagcdn.com/w320/uz.png", country: "Uzbekistan" },
        { flag: "https://flagcdn.com/w320/vu.png", country: "Vanuatu" },
        { flag: "https://flagcdn.com/w320/va.png", country: "Vatican City" },
        { flag: "https://flagcdn.com/w320/ve.png", country: "Venezuela" },
        { flag: "https://flagcdn.com/w320/vn.png", country: "Vietnam" },
        { flag: "https://flagcdn.com/w320/wf.png", country: "Wallis and Futuna" },
        { flag: "https://flagcdn.com/w320/eh.png", country: "Western Sahara" },
        { flag: "https://flagcdn.com/w320/ye.png", country: "Yemen" },
        { flag: "https://flagcdn.com/w320/zm.png", country: "Zambia" },
        { flag: "https://flagcdn.com/w320/zw.png", country: "Zimbabwe" },
        { flag: "https://flagcdn.com/w320/ax.png", country: "Åland Islands" },
    ];

    constructor(props: Props) {
        super(props);
        this.state = {
            setType: "users",
            countries: this.countries,
            filteredCountries: this.countries,
        };
    }

    componentDidUpdate(props) {
        if (props != this.props) {
            this.onSearch();
        }
    }

    onSearch() {
        if (this.props.search.length > 0) {
            const filteredCountries = this.state.countries.filter((c) =>
                c.country.toUpperCase().includes(this.props.search.toUpperCase()),
            );
            this.setState({ filteredCountries });
        } else {
            this.setState({ filteredCountries: this.state.countries });
        }
    }

    renderItemCountries = ({ item }) => {
        return (
            <View style={styles.wrapperCard}>
                <PressableBase style={styles.flexCard} onPress={() => this.props.nationalitySelected(item.country)}>
                    <AvatarBase size={26} uri={item.flag}></AvatarBase>
                    <BoldText style={{ marginLeft: 10 }}>{item.country}</BoldText>
                </PressableBase>
            </View>
        );
    };

    render() {
        return (
            <View style={{ flex: 1, marginTop: 16 }}>
                {/* @todo:  SET SEARCH WITH SERACHBASE
                <SearchBase />
                */}
                <Container style={{ flex: 1, marginTop: 10 }}>
                    <FlatList
                        data={this.state.filteredCountries}
                        renderItem={this.renderItemCountries}
                        keyExtractor={(item) => item.country}
                        contentContainerStyle={{ paddingBottom: 20 }}
                        ListEmptyComponent={<BotCard message={t("empty_list")} />}
                    />
                </Container>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const NationalitySection = connect(mapStateToProps)(_NationalitySection);

export default NationalitySection;

const styles = StyleSheet.create({
    wrapperCard: {
        backgroundColor: colors.shadow,
        marginBottom: 10,
        padding: 15,
        borderRadius: settings.cardRadius,
    },
    flexCard: {
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
    },
});
