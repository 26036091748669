import FlatListBase from "@base/FlatListBase";
import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import LottieBase from "@components/animations/Lottie";
import AvatarBase from "@components/avatar/AvatarBase";
import Icon from "@components/icons";
import Wallet from "@core/wallet/Wallet";
import { ActivityModel, OrderModel } from "@custom-types/ActivityModel";
import i18n from "@i18n/i18n";
import { colors } from "@styles/globalStyles";
import * as Clipboard from "expo-clipboard";
import moment from "moment";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";

interface Props {
    activity: ActivityModel;
    order: OrderModel;
}

interface State {}

const { t } = i18n;

export default class ActivityOrderModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.props.order && (
                    <View>
                        <View>
                            <View style={{ marginBottom: 10, paddingBottom: 10 }}>
                                <Row style={{ alignItems: "center", gap: 15 }}>
                                    <AvatarBase
                                        size={50}
                                        uri={
                                            this.props.activity?.data?.items[0]?.data?.merchant?.image?.thumbnail || ""
                                        }
                                        alias={this.props.activity?.data?.items[0]?.data?.merchant?.name || ""}
                                    />
                                    <View>
                                        <RegularText fontSize={20}>
                                            {t("purchases")}{" "}
                                            <SemiBoldText fontSize={20}>
                                                {this.props.activity?.data?.items[0]?.data?.merchant?.name}
                                            </SemiBoldText>
                                        </RegularText>
                                        <RegularText color={colors.grey}>
                                            {`${moment(this.props.activity?.createdAt).format("LL")}, ${moment(
                                                this.props.activity?.createdAt
                                            ).format("hh:mm")}`}
                                        </RegularText>
                                    </View>
                                </Row>
                            </View>

                            <View style={styles.container}>
                                <RegularText style={{ marginBottom: 10 }}>{t("purchase_detail")}</RegularText>
                                {this.props.order?.items.length > 0 && (
                                    <View>
                                        <FlatListBase
                                            data={this.props.order?.items}
                                            renderItem={(item: any) => {
                                                return (
                                                    <Row style={{ marginTop: 10 }}>
                                                        <AvatarBase
                                                            uri={
                                                                item.item.item.image.thumbnail ||
                                                                item.item.item.image.cover ||
                                                                ""
                                                            }
                                                            size={40}
                                                        ></AvatarBase>
                                                        <View style={{ paddingLeft: 10, justifyContent: "center" }}>
                                                            <RegularText>{item.item.title}</RegularText>
                                                            {item?.item?.price > 0 ? (
                                                                <RegularText color={colors.grey}>
                                                                    $ {item.item.price} x {item.item.quantity}
                                                                </RegularText>
                                                            ) : (
                                                                <RegularText color={colors.grey}>
                                                                    {item.item.quantity}{" "}
                                                                    <SemiBoldText color={colors.grey}>
                                                                        {" ("}
                                                                        {t("free")}
                                                                        {")"}
                                                                    </SemiBoldText>
                                                                </RegularText>
                                                            )}
                                                        </View>
                                                    </Row>
                                                );
                                            }}
                                            keyExtractor={(item: any) => item._id}
                                        />
                                    </View>
                                )}
                            </View>

                            {this.props.activity?.data?.amount > 0 && (
                                <View style={styles.container}>
                                    <RegularText style={{ marginBottom: 10 }}>{t("payment_detail")}</RegularText>
                                    {this.props.order?.payment?.status && (
                                        <Row style={{ justifyContent: "space-between", marginBottom: 5 }}>
                                            <RegularText color={colors.grey}>{t("status")}</RegularText>
                                            {this.props.order?.payment?.status == "approved" && (
                                                <Row style={{ alignItems: "center" }}>
                                                    <View style={{ width: 35, marginTop: -5 }}>
                                                        <LottieBase
                                                            height={30}
                                                            width={30}
                                                            loop={true}
                                                            source={require("../../../assets/animations/success.json")}
                                                        />
                                                    </View>
                                                    <SemiBoldText fontSize={16}>{t("confirmed")}</SemiBoldText>
                                                </Row>
                                            )}
                                            {this.props.order?.payment?.status == "pending" && (
                                                <RegularText color={colors.grey}>{t("pending")}</RegularText>
                                            )}
                                            {this.props.order?.payment?.status == "rejected" && (
                                                <RegularText color={colors.grey}>{t("cancel")}</RegularText>
                                            )}
                                        </Row>
                                    )}

                                    {this.props.order?.payment?.gateway && (
                                        <Row style={{ justifyContent: "space-between", marginBottom: 5 }}>
                                            <RegularText color={colors.grey}>{t("payment_method")}</RegularText>
                                            <RegularText style={{ textTransform: "uppercase" }} color={colors.grey}>
                                                {this.props.order.payment.gateway}
                                            </RegularText>
                                        </Row>
                                    )}

                                    <Row style={{ justifyContent: "space-between", marginBottom: 5 }}>
                                        <RegularText color={colors.grey}>{t("amount")}</RegularText>
                                        {this.props.activity?.data?.currency?.fiatCurrencyId && (
                                            <CurrencyBalanceIcon
                                                fontSize={15}
                                                iconSize={16}
                                                trimLength={5}
                                                amount={this.props.activity?.data?.amount}
                                                currency={Wallet.getInstance().findFiatCurrencyById(
                                                    this.props.activity?.data?.currency?.fiatCurrencyId || ""
                                                )}
                                            />
                                        )}
                                    </Row>
                                </View>
                            )}

                            <View style={styles.container}>
                                <RegularText style={{ marginBottom: 5 }}>{t("transaction_id")}</RegularText>
                                {this.props.order?._id && (
                                    <PressableBase
                                        onPress={async () => await Clipboard.setStringAsync(this.props.activity._id)}
                                        style={{ paddingVertical: 2 }}
                                    >
                                        <Row style={{ alignItems: "baseline" }}>
                                            <RegularText color={colors.grey}>{this.props.order?._id}</RegularText>
                                            <Icon
                                                size={16}
                                                name="content-copy"
                                                color={colors.grey}
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Row>
                                    </PressableBase>
                                )}
                            </View>
                        </View>
                    </View>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        borderTopColor: colors.grey,
        borderTopWidth: 0.5,
        paddingVertical: 20,
    },
});
