import {Client} from "@custom-types/Client";
import {Token} from "@custom-types/Token";

export const SET_CLIENT = 'SET_CLIENT';
export const SET_TOKEN = 'SET_TOKEN';

export const setClient = (client: Client) => {
    return {type: SET_CLIENT, client};
}

export const setToken = (token: Token) => {
    return {type: SET_TOKEN, token};
}
