import {_SendScreen} from "@screens/wallet/send/SendScreen";
import { connect } from "react-redux";
import { _ConfirmSendScreen } from "@screens/wallet/send/ConfirmSendScreen";
import HistorySeedScreen from "@screens/settings/seed/HistorySeedScreen";


export class _ProfileHistorySeedScreen extends HistorySeedScreen {
  constructor(props: any) {
    super(props);
  }
}

const mapStateToProps = (state) => {
    return {
        
    };
  };

const mapDispatchToProps = (dispatch) => ({});

const ProfileHistorySeedScreen = connect(mapStateToProps, mapDispatchToProps)(_ProfileHistorySeedScreen);

export default ProfileHistorySeedScreen;