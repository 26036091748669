import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Logo(props) {
  return (
    <Svg
      width={40}
      height={50}
      viewBox="0 0 56 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M55.16 15.916C52.588 7.57 44.277 0 30.684 0S5.748 11.002 5.748 19.26c0 2.464.415 2.362.415 3.83 0 1.467-.51 2.654-1.756 4.224-1.233 1.583-3.512 3.906-4.129 4.8-.616.906-.2 2.067 1.14 2.667 1.34.587 1.447.587 2.265.88.818.294 1.233 1.085.925 1.966-.308.88-.617 2.068-.617 2.553 0 .485 1.14.88 1.14 1.468 0 .587-.617 1.48-.308 2.259.308.779 1.246.983 1.246 2.26 0 1.275-1.246 2.845-.308 4.62.925 1.774 2.48 2.348 5.054 2.552 2.574.204 5.563.306 7.01.498 1.435.191 2.467.191 3.821 2.846 1.046 2.071 3.788 6.83 4.944 8.822.285.492.93.644 1.411.335l21.286-13.694a.996.996 0 00.37-1.242c-1.096-2.498-2.945-7.852.462-12.588 3.794-5.31 7.614-14.052 5.04-22.4zM26.347 41.461c-.415.6-1.365.19-1.2-.518l1.998-8.587a.66.66 0 00-.65-.804h-4.143a.658.658 0 01-.577-.988l7.56-12.816c.376-.638 1.368-.274 1.231.451l-1.431 7.565a.33.33 0 00.328.389h6.206c.535 0 .852.591.55 1.028l-9.872 14.28z"
        fill="#F6F6F4"
      />
      <Path
        d="M55.16 15.916C52.588 7.57 44.277 0 30.684 0S5.748 11.002 5.748 19.26c0 2.464.415 2.362.415 3.83 0 1.467-.51 2.654-1.756 4.224-1.233 1.583-3.512 3.906-4.129 4.8-.616.906-.2 2.067 1.14 2.667 1.34.587 1.447.587 2.265.88.818.294 1.233 1.085.925 1.966-.308.88-.617 2.068-.617 2.553 0 .485 1.14.88 1.14 1.468 0 .587-.617 1.48-.308 2.259.308.779 1.246.983 1.246 2.26 0 1.275-1.246 2.845-.308 4.62.925 1.774 2.48 2.348 5.054 2.552 2.574.204 5.563.306 7.01.498 1.435.191 2.467.191 3.821 2.846 1.046 2.071 3.788 6.83 4.944 8.822.285.492.93.644 1.411.335l21.286-13.694a.996.996 0 00.37-1.242c-1.096-2.498-2.945-7.852.462-12.588 3.794-5.31 7.614-14.052 5.04-22.4zM26.347 41.461c-.415.6-1.365.19-1.2-.518l1.998-8.587a.66.66 0 00-.65-.804h-4.143a.658.658 0 01-.577-.988l7.56-12.816c.376-.638 1.368-.274 1.231.451l-1.431 7.565a.33.33 0 00.328.389h6.206c.535 0 .852.591.55 1.028l-9.872 14.28z"
        fill="#F6F6F4"
      />
    </Svg>
  )
}

export default Logo
