import Currency, { IConstructorCurrency } from "@core/currencies/Currency";
import TRXImplementation from "./trx/TRXImplementation";
import { Networks } from "@core/network/Networks";

export default class TronCurrency extends Currency {
    constructor(parameters: IConstructorCurrency) {
        super({
            ...parameters,
            decimals: Number(parameters.decimals),
            implementation: TRXImplementation,
            // networkID: parameters.testnet?195:195,
            network: parameters.testnet ? Networks.ethereum.testnet : Networks.ethereum.mainnet,
        });
    }
}
