import React, { Component } from "react";
import {
    View,
    StyleSheet,
      ScrollView,
    Platform,
    KeyboardAvoidingView,
    Dimensions,
} from "react-native";
import { Video, AVPlaybackStatus, ResizeMode } from "expo-av";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import { colors, settings } from "@styles/globalStyles";
import Icon from "@components/icons";
import RegularTextInput from "@base/RegularTextInput";
import InlineButton from "@base/InlineButton";
import * as ImagePicker from "expo-image-picker";
import Wallet from "@core/wallet/Wallet";
import Currency from "@core/currencies/Currency";
import Card from "@base/Card";
import Row from "@base/Row";
import RegularNumberInput from "@base/RegularNumberInput";
import { getColorOpacity } from "@utils/helpers/global/global";
import NFTService, { default_currency_NFTS } from "@core/services/NFTService";
import store from "@store/index";
import NFT from "@custom-types/NFTModel";
import { Client } from "@custom-types/Client";
import ImageBase from "@components/image/ImageBase";
import BotCard from "@base/BotCard";
import {
    calcFileSize,
    generateThumbnails,
    resizeImageHelper,
    sourceIsTooBig,
    videoSourceIsTooBig,
} from "@utils/helpers/nfts/nft.helper";
import { loading, ready, showPopup } from "@store/actions/global";
import * as ImageManipulator from "expo-image-manipulator";
import VideoBase from "@components/image/VideoBase";
import { ProfileSections } from "@custom-types/ProfileType";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    client: Client;
    route: any;
}

export enum MediaTypes {
    IMAGE = "image",
    VIDEO = "video",
}

interface State {
    id: string;
    name: string;
    media_type: MediaTypes;
    image: string;
    raw_image: { cancelled: boolean; height: number; width: number; uri: string };
    video: string;
    raw_video: { height: number };
    video_poster: string;
    description: string;
    currency: Currency;
    blockckain: string;
    attributes: Array<{ trait_type: string; value: string }>;
    trait_type: string;
    trait_type_value: string;
    return_percentage: number;
    error: "" | "return_percentage" | "image" | "video";
    error_video_message: "";
    botCard: boolean;
    isEdit: boolean;
}

const { t } = i18n;

export class _newNFTScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.changeInputValue = this.changeInputValue.bind(this);
        this.selectMedia = this.selectMedia.bind(this);
        this.addPropieties = this.addPropieties.bind(this);
        this.removePropieties = this.removePropieties.bind(this);
        this.setSelectedCurrency = this.setSelectedCurrency.bind(this);
        this.createNFT = this.createNFT.bind(this);
        this.reziseImage = this.reziseImage.bind(this);
        this.selectVideoPoster = this.selectVideoPoster.bind(this);
        this.saveEditedNFT = this.saveEditedNFT.bind(this);
        this.state = {
            id: "",
            name: "",
            image: "",
            video: "",
            raw_video: { height: 300 },
            video_poster: "",
            description: "",
            blockckain: Wallet.getInstance().findCurrencyById(default_currency_NFTS).getBlockchain(),
            currency: Wallet.getInstance().findCurrencyById(default_currency_NFTS),
            trait_type: "",
            trait_type_value: "",
            attributes: [],
            return_percentage: 1,
            error: "",
            error_video_message: "",
            botCard: false,
            raw_image: null,
            media_type: MediaTypes.IMAGE,
            isEdit: false,
        };
    }

    componentDidMount() {
        const nft: NFT = this.props.route.params?.nftEdit || null;
        if (nft && nft.isDraft) {
            this.setState({
                id: nft._id,
                name: nft.name,
                image: nft.image?.square || "",
                video: nft.video.video || "",
                raw_video: { height: 300 },
                video_poster: nft.image?.square || nft.image?.thumbnail || "",
                description: nft.description,
                blockckain: nft.blockchain,
                currency: Wallet.getInstance().findCurrencyByBlockchain(nft.blockchain),
                trait_type: "",
                trait_type_value: "",
                attributes: nft.attributes || [],
                return_percentage: nft.cashbackValues / 100,
                error: "",
                error_video_message: "",
                botCard: false,
                raw_image: null,
                media_type: nft.video.video ? MediaTypes.VIDEO : MediaTypes.IMAGE,
                isEdit: true,
            });
        }
    }

    changeInputValue(value, field) {
        this.setState({
            ...this.state,
            [field]: value,
        });
        this.checkValues(value, field);
    }

    checkValues(value, field) {
        switch (field) {
            case "return_percentage":
                if (value > 20) {
                    return this.setState({ error: field });
                }
                if (value.length == 0) {
                    return this.setState({ return_percentage: 0 });
                }
            default:
                return this.setState({ error: "" });
        }
    }

    selectMedia = async () => {
        let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

        if (permissionResult.granted === false) {
            alert("Permission to access Media Library is required!");
            return;
        }

        let pickerResult: any = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: false,
            base64: false,
            quality: 1,
        });

        if ((pickerResult?.type || pickerResult?.uri?.substring(5, 10)) == MediaTypes.IMAGE) {
            this.setState({ raw_image: pickerResult, media_type: MediaTypes.IMAGE });
            if (await sourceIsTooBig(pickerResult.uri, MediaTypes.IMAGE)) {
                this.setState({ image: "", video: "", error: "image", botCard: true });
                return;
            }
            this.setState({ image: pickerResult.uri, video: "", error: "", botCard: false });
        }

        if ((pickerResult?.type || pickerResult?.uri?.substring(5, 10)) == MediaTypes.VIDEO) {
            const response: any = await videoSourceIsTooBig(pickerResult);

            if (response?.error) {
                this.setState({
                    image: "",
                    video: "",
                    error: "video",
                    error_video_message: response.error_message,
                    botCard: false,
                });
                return;
            }

            if (Platform.OS !== "web") {
                const poster_uri = await generateThumbnails(pickerResult);
                if (poster_uri) {
                    this.setState({ video_poster: poster_uri });
                }
            }

            this.setState({
                image: "",
                video: pickerResult.uri,
                raw_video: {
                    height: pickerResult?.height
                        ? pickerResult.rotation == 90
                            ? pickerResult.width / (pickerResult.height / (Dimensions.get("window").width - 40))
                            : pickerResult.height / (pickerResult.width / (Dimensions.get("window").width - 40))
                        : this.state.raw_video.height,
                },
                media_type: MediaTypes.VIDEO,
                error: "",
                botCard: false,
            });
        }
    };

    selectVideoPoster = async () => {
        let pickerResult: any = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            base64: false,
            quality: 1,
        });
        let resize_image = await ImageManipulator.manipulateAsync(pickerResult?.uri, [{ resize: { width: 720 } }], {
            compress: 0.9,
            format: ImageManipulator.SaveFormat.JPEG,
        });
        this.setState({ video_poster: resize_image.uri });
    };

    addPropieties() {
        this.setState({
            attributes: this.state.attributes.concat({
                trait_type: this.state.trait_type,
                value: this.state.trait_type_value,
            }),
            trait_type: "",
            trait_type_value: "",
        });
    }
    removePropieties(p) {
        const filter = this.state.attributes.filter((a) => {
            return p.trait_type !== a.trait_type;
        });
        this.setState({ attributes: filter });
    }

    setSelectedCurrency(c: Currency) {
        this.setState({ blockckain: c.getBlockchain(), currency: c });
    }

    async createNFT() {
        if (this.state.media_type == MediaTypes.IMAGE) {
            const data = {
                uri: this.state.image,
                addressFrom: this.state.currency.getAddress(),
                name: this.state.name,
                description: this.state.description,
                fixedValues: 1,
                cashbackValues: (this.state.return_percentage * 100).toFixed(0),
                attributes: JSON.stringify(this.state.attributes),
                blockchain: this.state.blockckain,
                network: this.state.currency.getNetworkType(),
            };
            var response = await NFTService.getInstance().createImageNFT(data);
        }

        if (this.state.media_type == MediaTypes.VIDEO) {
            const data = {
                uri_video: this.state.video,
                uri_poster: this.state.video_poster,
                size: await calcFileSize(this.state.video),

                addressFrom: this.state.currency.getAddress(),
                name: this.state.name,
                description: this.state.description,
                fixedValues: 1,
                cashbackValues: (this.state.return_percentage * 100).toFixed(0),
                attributes: JSON.stringify(this.state.attributes),
                blockchain: this.state.blockckain,
                network: this.state.currency.getNetworkType(),
            };

            var response = await NFTService.getInstance().createVideoNFT(data);
        }

        if (response) {
            store.dispatch(showPopup({ type: "SUCCESS" }));

            this.props.navigation.navigate("Profile", {
                screen: "ProfileMain",
                params: {
                    profileSection: ProfileSections.nftProfile,
                },
            });
        }
    }

    async reziseImage() {
        try {
            store.dispatch(loading());
            const resizeImage = await resizeImageHelper(this.state.raw_image);
            if (resizeImage) {
                this.setState({ image: resizeImage.uri, error: "", botCard: false });
                store.dispatch(ready());
            }
        } catch {
            store.dispatch(ready());
            store.dispatch(showPopup({ type: "ERROR" }));
        }
    }

    async saveEditedNFT() {
        const data = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            attributes: this.state.attributes,
            fixedValues: 1,
            cashbackValues: this.state.return_percentage * 100,
            blockchain: this.state.blockckain,
            network: this.state.currency.getNetworkType(),
        };
        const response = await NFTService.getInstance().editDraft(data);

        if (response) {
            store.dispatch(showPopup({ type: "SUCCESS" }));
            this.props.navigation.navigate("Profile", {
                screen: "ProfileMain",
                params: {
                    profileSection: ProfileSections.nftProfile,
                },
            });
        }
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={"NFTs Creator"} type={HeaderType.Light} {...this.props} />
                <KeyboardAvoidingView
                    style={{
                        flex: 1,
                        overflow: Platform.OS == "ios" ? "hidden" : null,
                    }}
                    enabled
                    behavior={Platform.OS == "ios" ? "position" : null}
                >
                    <ScrollView>
                        <Container style={{ flex: 1, paddingBottom: 20 }}>
                            <BoldText fontSize={22}>
                                {this.state.isEdit ? t("edit_item") : t("create_new_item")}
                            </BoldText>

                            <View style={styles.viewContainer}>
                                <RegularText style={styles.label}>
                                    {t("image_video")}
                                    <RegularText color={colors.grey}>
                                        {" "}
                                        {this.state.isEdit ? t("not_edit") : t("required")}
                                    </RegularText>
                                </RegularText>
                                {!this.state.isEdit && (
                                    <RegularText align="justify" style={styles.description}>
                                        {t("file_type_supported")}
                                    </RegularText>
                                )}
                                {this.state.botCard && (
                                    <PressableBase style={{ marginTop: 20 }} onPress={this.reziseImage}>
                                        <BotCard
                                            title={t("bot_image_resize_title")}
                                            message={t("bot_image_resize_message")}
                                        ></BotCard>
                                    </PressableBase>
                                )}
                                {this.state.image.length == 0 && this.state.video.length == 0 && (
                                    <PressableBase
                                        onPress={this.state.isEdit ? null : this.selectMedia}
                                        style={styles.imagePlaceholderContainer}
                                    >
                                        <Row>
                                            <Icon
                                                style={{ marginRight: 15 }}
                                                name="images"
                                                size={70}
                                                color={colors.grey}
                                            ></Icon>

                                            <Icon
                                                style={{ marginLeft: 15 }}
                                                name="video"
                                                size={70}
                                                color={colors.grey}
                                            ></Icon>
                                        </Row>
                                    </PressableBase>
                                )}

                                {this.state.image.length > 0 && this.state.media_type == MediaTypes.IMAGE && (
                                    <PressableBase
                                        style={{
                                            flex: 1,
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                        }}
                                        onPress={this.state.isEdit ? null : this.selectMedia}
                                    >
                                        <ImageBase
                                            uri={this.state.image}
                                            style={styles.imageStyles}
                                            autoSize={true}
                                            maxAutoSizeHight={450}
                                        />
                                    </PressableBase>
                                )}

                                {this.state.video.length > 0 && (
                                    <View style={{ flex: 1 }}>
                                        <PressableBase
                                            style={{ flex: 1 }}
                                            onLongPress={this.state.isEdit ? null : this.selectMedia}
                                        >
                                            <VideoBase
                                                style={
                                                    Platform.OS == "web"
                                                        ? {
                                                              marginTop: 10,
                                                              marginBottom: 20,
                                                              borderRadius: settings.cardRadius,
                                                          }
                                                        : {
                                                              height: this.state.raw_video.height,
                                                              borderRadius: settings.cardRadius,
                                                              marginVertical: 10,
                                                          }
                                                }
                                                uri={this.state.video}
                                                resizeMode={ResizeMode.CONTAIN}
                                                useNativeControls={false}
                                            />
                                        </PressableBase>

                                        <View style={{}}>
                                            <RegularText style={styles.label}>
                                                {t("poster_image")}
                                                <RegularText color={colors.grey}>
                                                    {" "}
                                                    {this.state.isEdit ? t("not_edit") : t("required")}
                                                </RegularText>
                                            </RegularText>
                                            {!this.state.isEdit && (
                                                <RegularText style={styles.description}>
                                                    {t("select_poster_image")}
                                                </RegularText>
                                            )}

                                            <Row style={{ flex: 1, alignContent: "center", alignItems: "center" }}>
                                                {this.state.video_poster?.length > 0 && (
                                                    <View style={styles.imagePosterStyles}>
                                                        <ImageBase
                                                            uri={this.state.video_poster}
                                                            style={{ width: 80, height: 80, resizeMode: "cover" }}
                                                        />
                                                    </View>
                                                )}
                                                {!this.state.isEdit && (
                                                    <View>
                                                        <PressableBase
                                                            style={styles.videoPoster}
                                                            onPress={this.selectVideoPoster}
                                                        >
                                                            <Row>
                                                                <Icon
                                                                    name="images"
                                                                    size={40}
                                                                    color={colors.grey}
                                                                ></Icon>
                                                            </Row>
                                                        </PressableBase>
                                                    </View>
                                                )}
                                            </Row>
                                        </View>
                                    </View>
                                )}

                                {this.state.error == "image" && (
                                    <View>
                                        <RegularText color={getColorOpacity(colors.red, 0.8)} fontSize={14}>
                                            {t("image_size_error")}
                                        </RegularText>
                                    </View>
                                )}
                                {this.state.error == "video" && (
                                    <View>
                                        <RegularText color={getColorOpacity(colors.red, 0.8)} fontSize={14}>
                                            {this.state.error_video_message}
                                        </RegularText>
                                    </View>
                                )}
                            </View>
                            <View style={styles.viewContainer}>
                                <RegularText style={styles.label}>
                                    {t("name")}
                                    <RegularText color={colors.grey}> ({t("required")})</RegularText>
                                </RegularText>
                                <RegularTextInput
                                    key={"name"}
                                    maxLength={100}
                                    multiline={false}
                                    align={"left"}
                                    onChangeText={(text) => this.changeInputValue(text, "name")}
                                    value={this.state.name}
                                    placeholder={t("item_name")}
                                    style={styles.input}
                                />
                            </View>

                            <View style={styles.viewContainer}>
                                <RegularText style={styles.label}>{t("description")}</RegularText>
                                <RegularText style={styles.description}>{t("description_description")}</RegularText>
                                <RegularTextInput
                                    key={"description"}
                                    multiline={true}
                                    align={"left"}
                                    onChangeText={(text) => this.changeInputValue(text, "description")}
                                    value={this.state.description}
                                    placeholder={t("description_placeholder")}
                                    style={styles.input}
                                />
                            </View>

                            <View style={styles.viewContainer}>
                                <RegularText style={styles.label}>{t("propieties")}</RegularText>
                                <RegularText style={styles.description}>{t("propieties_description")}</RegularText>
                                {this.state.attributes.length > 0 && (
                                    <View style={styles.propietiesContainer}>
                                        {this.state.attributes.map((a) => {
                                            return (
                                                <View key={a.trait_type} style={styles.propietiesWrapper}>
                                                    <PressableBase
                                                        onLongPress={() => this.removePropieties(a)}
                                                        style={styles.propieties}
                                                    >
                                                        <BoldText align="center" style={{ flex: 0.5 }}>
                                                            {a.trait_type}
                                                        </BoldText>
                                                        <RegularText align="center" style={{ flex: 0.5 }}>
                                                            {a.value}
                                                        </RegularText>
                                                    </PressableBase>
                                                </View>
                                            );
                                        })}
                                    </View>
                                )}
                                <Row style={{ justifyContent: "space-between", marginTop: 10 }}>
                                    <RegularTextInput
                                        autoCapitalize={"characters"}
                                        key={"trait_type"}
                                        maxLength={100}
                                        multiline={false}
                                        align={"left"}
                                        onChangeText={(text) => this.changeInputValue(text.toUpperCase(), "trait_type")}
                                        value={this.state.trait_type}
                                        placeholder={t("type_props")}
                                        style={styles.inputRow}
                                    />
                                    <RegularTextInput
                                        key={"trait_type_value"}
                                        autoCapitalize={"sentences"}
                                        maxLength={100}
                                        multiline={false}
                                        align={"left"}
                                        onChangeText={(text) => this.changeInputValue(text, "trait_type_value")}
                                        value={this.state.trait_type_value}
                                        placeholder={t("name_props")}
                                        style={[styles.inputRow, { marginLeft: 10 }]}
                                    />
                                    {this.state.trait_type.length >= 1 && this.state.trait_type_value.length >= 1 && (
                                        <PressableBase onPress={this.addPropieties} style={[styles.buttonPlus]}>
                                            <Icon name="plus" color={colors.white} size={26}></Icon>
                                        </PressableBase>
                                    )}
                                </Row>
                            </View>

                            <View style={styles.viewContainer}>
                                <RegularText style={styles.label}>Blockchain</RegularText>
                                {!this.state.isEdit && (
                                    <RegularText style={styles.description}>{t("blockchain_description")}</RegularText>
                                )}
                                <View>
                                    {Wallet.getInstance()
                                        .getNftSupportedCurrencies()
                                        .map((c: Currency) => {
                                            return (
                                                <Card
                                                    key={c.getId()}
                                                    style={{
                                                        opacity: this.state.blockckain == c.getBlockchain() ? 1 : 0.6,
                                                    }}
                                                    onPress={() =>
                                                        this.state.isEdit ? null : this.setSelectedCurrency(c)
                                                    }
                                                    icon={<CurrencyIcon currency={c} styles={{ marginRight: 15 }} />}
                                                    left={
                                                        <View>
                                                            <RegularText>{c.getBlockchain().toUpperCase()}</RegularText>
                                                        </View>
                                                    }
                                                    right={<View style={{}}></View>}
                                                ></Card>
                                            );
                                        })}
                                </View>
                            </View>

                            <View style={styles.viewContainer}>
                                <RegularText style={styles.label}>{t("return_percentage")}</RegularText>
                                <RegularText style={styles.description}>
                                    {t("return_percentage_description")}
                                </RegularText>
                                <Row style={{ alignItems: "center" }}>
                                    <RegularNumberInput
                                        key={"return_percentage"}
                                        multiline={false}
                                        align={"center"}
                                        onChangeText={(number) => this.changeInputValue(number, "return_percentage")}
                                        value={this.state.return_percentage}
                                        style={styles.input}
                                    />

                                    <RegularText style={{ paddingTop: 3, paddingLeft: 3 }}>%</RegularText>
                                </Row>
                                {this.state.error == "return_percentage" && (
                                    <RegularText color={getColorOpacity(colors.red, 0.8)} fontSize={14}>
                                        {t("return_percentage_error")}
                                    </RegularText>
                                )}
                            </View>

                            {!this.state.isEdit && (
                                <View style={styles.viewContainer}>
                                    <RegularText style={styles.label}>{t("address")}</RegularText>
                                    <RegularText style={styles.description}>{t("address_description")}</RegularText>
                                    <RegularText style={[styles.input, { paddingVertical: 15 }]} fontSize={12}>
                                        {this.state.currency.getAddress()}
                                    </RegularText>
                                </View>
                            )}

                            {this.state.name.length > 2 &&
                                (this.state.image.length > 2 ||
                                    (this.state.video.length > 2 && this.state.video_poster.length > 2)) &&
                                this.state.return_percentage >= 0 &&
                                this.state.return_percentage <= 20 && (
                                    <View style={{ flex: 1 }}>
                                        {this.state.isEdit ? (
                                            <InlineButton
                                                style={{ marginHorizontal: 0, marginTop: 15, marginBottom: 20 }}
                                                title={t("save")}
                                                onPress={this.saveEditedNFT}
                                            ></InlineButton>
                                        ) : (
                                            <InlineButton
                                                style={{ marginHorizontal: 0, marginTop: 15, marginBottom: 20 }}
                                                title={t("create")}
                                                onPress={this.createNFT}
                                            ></InlineButton>
                                        )}
                                    </View>
                                )}
                        </Container>
                    </ScrollView>
                </KeyboardAvoidingView>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const newNFTScreen = connect(mapStateToProps, mapDispatchToProps)(_newNFTScreen);

export default newNFTScreen;

const styles = StyleSheet.create({
    viewContainer: {
        marginTop: 15,
    },
    imagePlaceholderContainer: {
        marginVertical: 15,
        width: "100%",
        height: 300,
        borderColor: colors.grey,
        backgroundColor: colors.shadow,
        borderWidth: 1,
        borderRadius: settings.cardRadius,
        borderStyle: "dashed",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    videoPoster: {
        marginVertical: 15,
        width: 80,
        height: 80,
        borderColor: colors.grey,
        backgroundColor: colors.shadow,
        borderWidth: 1,
        borderRadius: settings.cardRadius,
        borderStyle: "dashed",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    imagePosterStyles: {
        width: 80,
        height: 80,
        resizeMode: "cover",
        marginVertical: 15,
        marginRight: 15,
        overflow: "hidden",
        borderRadius: settings.cardRadius,
    },
    imageStyles: {
        resizeMode: "cover",
        marginVertical: 15,
        width: "100%",
        height: 300,
        borderRadius: settings.cardRadius,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    videoStyles: {
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        borderRadius: settings.cardRadius,
        height: 300,
        marginVertical: 10,
    },
    input: {
        backgroundColor: colors.shadow,
        padding: 10,
        overflow: "hidden",
        borderRadius: settings.cardRadius,
        marginTop: 5,
    },
    inputRow: {
        backgroundColor: colors.shadow,
        padding: 10,
        overflow: "hidden",
        borderRadius: settings.cardRadius,
        flex: 1,
        //minWidth: "48%",
    },
    buttonPlus: {
        backgroundColor: colors.shadow,
        height: 50,
        width: 45,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginLeft: 10,
        borderRadius: 10,
    },
    label: {
        fontSize: 16,
        paddingLeft: 2,
    },
    description: {
        fontSize: 12,
        paddingLeft: 2,
        color: colors.grey,
    },

    propietiesContainer: {
        marginVertical: 15,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    propietiesWrapper: {
        // justifyContent: "center",
        // alignContent: "center",
        // alignItems: "center",
        width: "48%",
        paddingVertical: 5,
        padding: 0,
    },
    propieties: {
        borderRadius: settings.cardRadius,
        paddingVertical: 15,
        paddingHorizontal: 5,
        backgroundColor: colors.shadow,
    },
});
