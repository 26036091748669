import React, { Component } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import Currency from "@core/currencies/Currency";
import store from "@store/index";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import Wallet from "@core/wallet/Wallet";
import BoldText from "@base/BoldText";
import SwitchCard from "@components/cards/SwitchCard";
import { loading, ready } from "@store/actions/global";
import { connect } from "react-redux";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import BotCard from "@base/BotCard";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import Row from "@base/Row";
import RegularText from "@base/RegularText";
import { WalletService } from "@core/services/WalletService";
import SearchBase from "@components/search/SearchBase";
import { trimHelper } from "@utils/helpers/chat/chat.helper";

interface Props {
    navigation: NavigationType;
}

interface State {
    currencies: Array<Currency>;
    filteredCurrencies: Array<Currency>;
    search: string;
    syncing: boolean;
}

const { t } = i18n;

export class _SelectCurrencyEnableScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onSwitch = this.onSwitch.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.state = {
            search: "",
            syncing: false,
            currencies: [],
            filteredCurrencies: [],
        };
    }

    async componentDidMount() {
        store.dispatch(loading());
        const currencies = Wallet.getInstance().getCurrencies();
        this.setState({ currencies: currencies, filteredCurrencies: currencies });
        store.dispatch(ready());
    }

    onSearch = async (search) => {
        this.setState({ search: search, syncing: true }, () => {
            if (this.state.search.length > 0) {
                const filteredCurrencies = this.state.currencies.filter(
                    (c) =>
                        c.getSymbol().startsWith(this.state.search.toUpperCase()) ||
                        c.getPName().startsWith(this.state.search.toUpperCase())
                );

                this.setState({ filteredCurrencies: filteredCurrencies, syncing: false });
            } else {
                this.setState({ filteredCurrencies: this.state.currencies, syncing: false });
            }
        });
    };

    async onSwitch(currency) {
        WalletService.getInstance().updateCurrencyEnabled({
            currency: currency,
            enabled: currency.enabled == null ? true : !currency.enabled,
        });
    }

    renderItem = ({ item }) => {
        return (
            <SwitchCard
                title={item.getPName()}
                left={
                    <Row style={{ alignItems: "center", alignContent: "center" }}>
                        <CurrencyIcon styles={{ marginRight: 15 }} currency={item} />

                        <View>
                            <Row style={{ alignItems: "center", alignContent: "center" }}>
                                <BoldText fontSize={20}>{item.getSymbol()}</BoldText>
                                <NetworkCurrencySymbol currency={item}></NetworkCurrencySymbol>
                            </Row>
                            <RegularText fontSize={14}>{trimHelper(item.getPName(), 20)}</RegularText>
                        </View>
                    </Row>
                }
                onToggle={() => this.onSwitch(item)}
                isOn={item.isEnabled()}
            />
        );
    };

    render() {
        return (
            <ScreenWrapper>
                <Header {...this.props} title={t("enable_currencies")} type={HeaderType.Light} />

                <Container style={styles.container}>
                    <SearchBase
                        containerStyle={{ marginBottom: 15 }}
                        onSearch={(value) => this.onSearch(value)}
                        value={this.state.search}
                    />
                    <FlatList
                        data={this.state.filteredCurrencies}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item.getId()}
                        contentContainerStyle={{ paddingBottom: 20 }}
                        initialNumToRender={this.state.currencies.length}
                        maxToRenderPerBatch={10}
                        extraData={this.state.currencies}
                        ListEmptyComponent={<BotCard message={t("empty_list")} />}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        overflow: "hidden",
        marginTop: 12,
    },
});

const mapStateToProps = (state) => {
    return {
        search: state.global.search,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SelectCurrencyEnableScreen = connect(mapStateToProps, mapDispatchToProps)(_SelectCurrencyEnableScreen);

export default SelectCurrencyEnableScreen;
