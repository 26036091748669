import { NotificationModel } from "@custom-types/NotificationModel";

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const UPDATE_NOTIFICATION_READ = 'UPDATE_NOTIFICATION_READ';


export const setNotifications = (notifications: Array<NotificationModel>) => {

    return { type: SET_NOTIFICATIONS, notifications };
};

export const updateNotification = (notificationId: string) => {

    return { type: UPDATE_NOTIFICATION_READ, notificationId };
};