import * as React from "react";
import Svg, { SvgProps, Defs, ClipPath, Path, LinearGradient, Stop, G } from "react-native-svg";

function Logo(props) {
    return (
      <Svg
      width={200}
      height={150}
      viewBox="0 0 79.375 39.688"
      xmlSpace="preserve"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <LinearGradient
          id="e"
          y1={0.547}
          x2={1}
          y2={0.547}
          gradientUnits="objectBoundingBox"
        >
          <Stop offset={0} stopColor="#fff" />
          <Stop offset={0.1} stopColor="#f4eeff" />
          <Stop offset={0.2} stopColor="#eadeff" />
          <Stop offset={0.3} stopColor="#dfcdff" />
          <Stop offset={0.4} stopColor="#d5bdff" />
          <Stop offset={0.5} stopColor="#cbacff" />
          <Stop offset={0.6} stopColor="#c19cff" />
          <Stop offset={0.7} stopColor="#b68bff" />
          <Stop offset={0.8} stopColor="#ac7bff" />
          <Stop offset={0.9} stopColor="#a26bff" />
          <Stop offset={1} stopColor="#975bff" />
        </LinearGradient>
        <LinearGradient
          xlinkHref="#e"
          id="h"
          gradientUnits="userSpaceOnUse"
          y1={96.18}
          x2={175.832}
          y2={96.18}
          gradientTransform="scale(1.97916 .50527)"
          x1={0}
        />
        <LinearGradient
          xlinkHref="#e"
          id="j"
          gradientUnits="userSpaceOnUse"
          y1={96.18}
          x2={175.832}
          y2={96.18}
          gradientTransform="scale(1.97916 .50527)"
          x1={0}
        />
        <LinearGradient
          xlinkHref="#e"
          id="l"
          gradientUnits="userSpaceOnUse"
          y1={96.18}
          x2={175.832}
          y2={96.18}
          gradientTransform="scale(1.97916 .50527)"
          x1={0}
        />
        <LinearGradient
          xlinkHref="#e"
          id="n"
          gradientUnits="userSpaceOnUse"
          y1={96.18}
          x2={175.832}
          y2={96.18}
          gradientTransform="scale(1.97916 .50527)"
          x1={0}
        />
        <LinearGradient
          xlinkHref="#e"
          id="p"
          gradientUnits="userSpaceOnUse"
          y1={96.18}
          x2={175.832}
          y2={96.18}
          gradientTransform="scale(1.97916 .50527)"
          x1={0}
        />
        <LinearGradient
          xlinkHref="#e"
          id="r"
          gradientUnits="userSpaceOnUse"
          y1={96.18}
          x2={175.832}
          y2={96.18}
          gradientTransform="scale(1.97916 .50527)"
          x1={0}
        />
        <LinearGradient
          xlinkHref="#e"
          id="t"
          gradientUnits="userSpaceOnUse"
          y1={96.18}
          x2={175.832}
          y2={96.18}
          gradientTransform="scale(1.97916 .50527)"
          x1={0}
        />
        <LinearGradient
          xlinkHref="#e"
          id="v"
          gradientUnits="userSpaceOnUse"
          y1={96.18}
          x2={175.832}
          y2={96.18}
          gradientTransform="scale(1.97916 .50527)"
          x1={0}
        />
        <LinearGradient
          xlinkHref="#e"
          id="x"
          gradientUnits="userSpaceOnUse"
          y1={96.18}
          x2={175.832}
          y2={96.18}
          gradientTransform="scale(1.97916 .50527)"
          x1={0}
        />
        <LinearGradient
          xlinkHref="#e"
          id="z"
          gradientUnits="userSpaceOnUse"
          y1={96.18}
          x2={175.832}
          y2={96.18}
          gradientTransform="scale(1.97916 .50527)"
          x1={0}
        />
        <ClipPath id="f">
          <Path
            data-name="Trazado 4"
            d="M0-147.866h348v-88.842H0Z"
            transform="translate(0 236.707)"
            fill="none"
          />
        </ClipPath>
        <ClipPath id="g">
          <Path
            data-name="Trazado 14"
            d="M0-142.055h9.282v-37.565H0Z"
            transform="translate(0 179.62)"
            fill="none"
          />
        </ClipPath>
        <ClipPath id="i">
          <Path
            data-name="Trazado 17"
            d="m68.927-179.62.273 22.986-18.619-22.986h-9.118v37.565h9.282l-.327-23.478 18.236 23.478h9.555v-37.565Z"
            transform="translate(-41.463 179.62)"
            fill="none"
          />
        </ClipPath>
        <ClipPath id="k">
          <Path
            data-name="Trazado 20"
            d="m183.719-179.62.273 22.986-18.619-22.986h-9.118v37.565h9.282l-.327-23.478 18.236 23.478H193v-37.565Z"
            transform="translate(-156.255 179.62)"
            fill="none"
          />
        </ClipPath>
        <ClipPath id="m">
          <Path
            data-name="Trazado 23"
            d="M265.228-161.111c0 13.05 8.954 19 20.693 19 11.685 0 20.694-5.951 20.694-19 0-12.995-9.009-18.947-20.694-18.947-11.739 0-20.693 5.952-20.693 18.947m9.337 0c0-5.57 2.839-10.1 11.357-10.1 8.463 0 11.357 4.531 11.357 10.1 0 5.624-2.894 10.155-11.357 10.155-8.518 0-11.357-4.531-11.357-10.155"
            transform="translate(-265.228 180.058)"
            fill="none"
          />
        </ClipPath>
        <ClipPath id="o">
          <Path
            data-name="Trazado 26"
            d="m402.792-179.074-8.736 26.263-8.956-26.809-9.883.546 13.322 37.019h11.029l13.486-37.565Z"
            transform="translate(-375.219 179.62)"
            fill="none"
          />
        </ClipPath>
        <ClipPath id="q">
          <Path
            data-name="Trazado 29"
            d="M481.164-179.018 467.9-142.055h10.046l1.856-5.514H494l1.856 5.514H505.9l-13.488-37.565Zm5.733 10.591 4.04 11.958h-8.081Z"
            transform="translate(-467.896 179.62)"
            fill="none"
          />
        </ClipPath>
        <ClipPath id="s">
          <Path
            data-name="Trazado 32"
            d="m559.738-179.62-.764 8.9H573.5v28.664h9.336v-28.664h13.759l.765-8.9Z"
            transform="translate(-558.974 179.62)"
            fill="none"
          />
        </ClipPath>
        <ClipPath id="u">
          <Path
            data-name="Trazado 34"
            d="M672.311-142.055h9.282v-37.565h-9.282Z"
            transform="translate(-672.311 179.62)"
            fill="none"
          />
        </ClipPath>
        <ClipPath id="w">
          <Path
            data-name="Trazado 37"
            d="M707.958-161.111c0 13.05 8.954 19 20.693 19 11.684 0 20.693-5.951 20.693-19 0-12.995-9.009-18.947-20.693-18.947-11.739 0-20.693 5.952-20.693 18.947m9.336 0c0-5.57 2.839-10.1 11.357-10.1 8.463 0 11.357 4.531 11.357 10.1 0 5.624-2.894 10.155-11.357 10.155-8.518 0-11.357-4.531-11.357-10.155"
            transform="translate(-707.958 180.058)"
            fill="none"
          />
        </ClipPath>
        <ClipPath id="y">
          <Path
            data-name="Trazado 40"
            d="m856.76-179.62.273 22.986-18.618-22.986H829.3v37.565h9.282l-.327-23.478 18.236 23.478h9.555v-37.565Z"
            transform="translate(-829.297 179.62)"
            fill="none"
          />
        </ClipPath>
        <Path
          id="a"
          d="m383.82 265.52-1.46 2.55-88 151.67v16.83c0 15.19 12.31 27.5 27.5 27.5h119.01c43.09 0 78.02-34.93 78.02-78.02 0-16.76-5.33-32.24-14.32-44.95l-39.73 65.84c-8.04 12.56-21.55 19.95-36.25 19.95-23.66 0-42.92-19.25-42.92-42.91 0-6.94 1.7-13.83 4.91-19.94l.14.07c2.5-4.26 10.95-18.42 40.52-67.67 2.12-3.88 3.32-8.58 3.32-13.32 0-15.59-12.69-28.28-28.28-28.28-10.22-.01-17.76 3.59-22.46 10.68"
        />
        <Path id="b" d="M294.36 254.83H518.9v209.24H294.36z" />
        <Path
          id="c"
          d="m347.66 207.71-53.3 93.84v89.03l75.18-129.61c7.07-13.4 20.12-20.77 36.75-20.77 23.66 0 42.91 19.25 42.91 42.91 0 7.2-1.82 14.33-5.27 20.61-16.02 26.7-40.07 66.8-41.23 68.86h-.01c-1.59 3.59-2.39 7.41-2.39 11.41 0 15.59 12.69 28.28 28.28 28.28 9.69 0 18.6-4.88 23.83-13.04l47.09-74.44c6.68-11.49 10.56-24.8 10.56-39.05 0-42.95-34.71-77.8-77.61-78.02h-84.79z"
        />
        <Path id="d" d="M294.36 207.71h215.71v204.55H294.36z" />
      </Defs>
      <G data-name="Grupo 56">
        <G
          data-name="Grupo 5"
          transform="matrix(.18593 0 0 .18593 8.213 10.99)"
          clipPath="url(#f)"
        >
          <Path data-name="Trazado 1" d="M0 58.991h9.282V21.426H0Z" />
          <Path
            data-name="Trazado 2"
            d="M52.308 58.991h-9.555L24.517 35.513l.328 23.478h-9.282V21.426h9.118l18.618 22.986-.273-22.986h9.282z"
          />
          <Path
            data-name="Trazado 3"
            d="M95.391 58.991h-9.555L67.601 35.513l.328 23.478h-9.282V21.426h9.118l18.619 22.986-.273-22.986h9.28Z"
          />
        </G>
        <G
          data-name="Grupo 6"
          transform="matrix(.18593 0 0 .18593 8.213 10.99)"
          clipPath="url(#f)"
        >
          <Path
            data-name="Trazado 5"
            d="M108.883 40.209c0 5.624 2.839 10.155 11.357 10.155 8.463 0 11.357-4.531 11.357-10.155 0-5.569-2.894-10.1-11.357-10.1-8.518 0-11.357 4.531-11.357 10.1m-9.337 0c0-12.995 8.954-18.947 20.693-18.947 11.684 0 20.693 5.952 20.693 18.947 0 13.05-9.009 19-20.693 19-11.739 0-20.693-5.951-20.693-19"
          />
          <Path
            data-name="Trazado 6"
            d="m154.151 58.991-13.322-37.018 9.882-.546 8.954 26.809 8.736-26.263 10.265-.546-13.486 37.565z"
          />
          <Path
            data-name="Trazado 7"
            d="M190.572 44.576h8.081l-4.04-11.958zm12.995 14.414-1.857-5.514h-14.194l-1.856 5.514h-10.044l13.268-36.963 11.247-.6 13.485 37.563z"
          />
          <Path
            data-name="Trazado 8"
            d="m248.179 21.426-.764 8.9h-13.759V58.99h-9.335V30.326h-14.525l.764-8.9z"
          />
          <Path data-name="Trazado 9" d="M252.334 58.991h9.282V21.426h-9.282z" />
          <Path
            data-name="Trazado 10"
            d="M275.049 40.209c0 5.624 2.839 10.155 11.357 10.155 8.463 0 11.357-4.531 11.357-10.155 0-5.569-2.894-10.1-11.357-10.1-8.518 0-11.357 4.531-11.357 10.1m-9.337 0c0-12.995 8.954-18.947 20.693-18.947 11.684 0 20.693 5.952 20.693 18.947 0 13.05-9.009 19-20.693 19-11.739 0-20.693-5.951-20.693-19"
          />
          <Path
            data-name="Trazado 11"
            d="M348 58.991h-9.555l-18.236-23.478.328 23.478h-9.28V21.426h9.118l18.618 22.986-.273-22.986h9.282z"
          />
        </G>
        <G
          data-name="Grupo 8"
          transform="matrix(.18593 0 0 .18593 8.213 14.973)"
          clipPath="url(#g)"
        >
          <G data-name="Grupo 7">
            <Path
              data-name="Trazado 13"
              d="M348 88.842H0V0h348z"
              fill="url(#e)"
              style={{
                fill: "url(#h)",
              }}
              transform="rotate(180 174 33.708)"
            />
          </G>
        </G>
        <G
          data-name="Grupo 11"
          transform="matrix(.18593 0 0 .18593 11.107 14.973)"
          clipPath="url(#i)"
        >
          <G
            data-name="Grupo 10"
            transform="translate(-15.562 -21.426)"
            clipPath="url(#f)"
          >
            <G data-name="Grupo 9">
              <Path
                data-name="Trazado 15"
                d="M348 88.842H0V0h348z"
                fill="url(#e)"
                style={{
                  fill: "url(#j)",
                }}
                transform="rotate(180 174 44.421)"
              />
            </G>
          </G>
        </G>
        <G
          data-name="Grupo 14"
          transform="matrix(.18593 0 0 .18593 19.117 14.973)"
          clipPath="url(#k)"
        >
          <G
            data-name="Grupo 13"
            transform="translate(-58.646 -21.426)"
            clipPath="url(#f)"
          >
            <G data-name="Grupo 12">
              <Path
                data-name="Trazado 18"
                d="M348 88.842H0V0h348z"
                fill="url(#e)"
                style={{
                  fill: "url(#l)",
                }}
                transform="rotate(180 174 44.421)"
              />
            </G>
          </G>
        </G>
        <G
          data-name="Grupo 17"
          transform="matrix(.18593 0 0 .18593 26.722 14.942)"
          clipPath="url(#m)"
        >
          <G
            data-name="Grupo 16"
            transform="translate(-99.546 -21.262)"
            clipPath="url(#f)"
          >
            <G data-name="Grupo 15">
              <Path
                data-name="Trazado 21"
                d="M348 88.842H0V0h348z"
                fill="url(#e)"
                style={{
                  fill: "url(#n)",
                }}
                transform="rotate(180 174 44.421)"
              />
            </G>
          </G>
        </G>
        <G
          data-name="Grupo 20"
          transform="matrix(.18593 0 0 .18593 34.397 14.973)"
          clipPath="url(#o)"
        >
          <G
            data-name="Grupo 19"
            transform="translate(-140.828 -21.426)"
            clipPath="url(#f)"
          >
            <G data-name="Grupo 18">
              <Path
                data-name="Trazado 24"
                d="M348 88.842H0V0h348z"
                fill="url(#e)"
                style={{
                  fill: "url(#p)",
                }}
                transform="rotate(180 174 44.421)"
              />
            </G>
          </G>
        </G>
        <G
          data-name="Grupo 23"
          transform="matrix(.18593 0 0 .18593 40.864 14.973)"
          clipPath="url(#q)"
        >
          <G
            data-name="Grupo 22"
            transform="translate(-175.612 -21.426)"
            clipPath="url(#f)"
          >
            <G data-name="Grupo 21">
              <Path
                data-name="Trazado 27"
                d="M348 88.842H0V0h348z"
                fill="url(#e)"
                style={{
                  fill: "url(#r)",
                }}
                transform="rotate(180 174 44.421)"
              />
            </G>
          </G>
        </G>
        <G
          data-name="Grupo 26"
          transform="matrix(.18593 0 0 .18593 47.22 14.973)"
          clipPath="url(#s)"
        >
          <G
            data-name="Grupo 25"
            transform="translate(-209.796 -21.426)"
            clipPath="url(#f)"
          >
            <G data-name="Grupo 24">
              <Path
                data-name="Trazado 30"
                d="M348 88.842H0V0h348z"
                fill="url(#e)"
                style={{
                  fill: "url(#t)",
                }}
                transform="rotate(180 174 44.421)"
              />
            </G>
          </G>
        </G>
        <G
          data-name="Grupo 28"
          transform="matrix(.18593 0 0 .18593 55.13 14.973)"
          clipPath="url(#u)"
        >
          <G data-name="Grupo 27">
            <Path
              data-name="Trazado 33"
              d="M348 88.842H0V0h348z"
              fill="url(#e)"
              style={{
                fill: "url(#v)",
              }}
              transform="rotate(180 47.833 33.708)"
            />
          </G>
        </G>
        <G
          data-name="Grupo 31"
          transform="matrix(.18593 0 0 .18593 57.617 14.942)"
          clipPath="url(#w)"
        >
          <G
            data-name="Grupo 30"
            transform="translate(-265.713 -21.262)"
            clipPath="url(#f)"
          >
            <G data-name="Grupo 29">
              <Path
                data-name="Trazado 35"
                d="M348 88.842H0V0h348z"
                fill="url(#e)"
                style={{
                  fill: "url(#x)",
                }}
                transform="rotate(180 174 44.421)"
              />
            </G>
          </G>
        </G>
        <G
          data-name="Grupo 34"
          transform="matrix(.18593 0 0 .18593 66.084 14.973)"
          clipPath="url(#y)"
        >
          <G
            data-name="Grupo 33"
            transform="translate(-311.254 -21.426)"
            clipPath="url(#f)"
          >
            <G data-name="Grupo 32">
              <Path
                data-name="Trazado 38"
                d="M348 88.842H0V0h348z"
                fill="url(#e)"
                style={{
                  fill: "url(#z)",
                }}
                transform="rotate(180 174 44.421)"
              />
            </G>
          </G>
        </G>
        <G
          data-name="Grupo 35"
          transform="matrix(.18593 0 0 .18593 8.213 10.99)"
          clipPath="url(#f)"
        >
          <Path
            data-name="Trazado 41"
            d="M218.827 88.841a28.958 28.958 0 0 1-9.03-1.275l1.14-4.959a30.342 30.342 0 0 0 7.673 1.062c2.934-.016 5.648.032 5.638-1.672-.007-1.262-.8-1.352-4.363-1.585-4.829-.288-10.011-1.711-10.038-6.538-.028-5.048 4.947-6.937 9.805-6.964a32.474 32.474 0 0 1 7.577.778l-.855 5.085a36.986 36.986 0 0 0-6.693-.688c-3.534.019-4.447.277-4.44 1.6.007 1.294 1.711 1.379 4.868 1.551 4.7.289 9.506 1.556 9.533 6.446.031 5.616-5.766 7.131-10.814 7.159"
            fill="#fff"
          />
          <Path
            data-name="Trazado 42"
            d="M254.38 77.222c.045 8.14-4.354 11.445-11.137 11.483-6.751.037-11.187-3.219-11.232-11.359l-.058-10.412 5.363-.03.058 10.412c.019 3.534.413 6.245 5.84 6.215 5.458-.03 5.822-2.746 5.8-6.279l-.058-10.412 5.364-.03z"
            fill="#fff"
          />
          <Path
            data-name="Trazado 43"
            d="m263.399 88.468-5.364.03-.12-21.711 6.941-.039 6.321 13.343 6.2-13.412 6.941-.039.12 21.707-5.364.03-.07-12.746-5.7 12.778-4.133.023-5.845-12.714z"
            fill="#fff"
          />
          <Path
            data-name="Trazado 44"
            d="m293.43 88.3-5.363.03-.121-21.706 6.941-.039 6.321 13.343 6.2-13.412 6.941-.039.121 21.707-5.363.03-.067-12.75-5.7 12.778-4.133.023-5.848-12.711z"
            fill="#fff"
          />
          <Path
            data-name="Trazado 45"
            d="m323.461 88.134-5.364.03-.121-21.706 5.364-.03z"
            fill="#fff"
          />
          <Path
            data-name="Trazado 46"
            d="m347.917 66.291-.413 5.145-7.951.044.092 16.564-5.395.03-.092-16.564-8.392.047.413-5.145z"
            fill="#fff"
          />
          <Path
            data-name="Trazado 47"
            d="m100.644 23.025-64.3 7.961-2.85-23.025L97.794 0z"
            fill="#fff"
          />
          <Path
            data-name="Trazado 48"
            d="m49.518 20.095.091 2.438-7.886.976-1.26-10.18 2.516-.311.962 7.768z"
            fill="black"
          />
          <Path
            data-name="Trazado 49"
            d="m53.759 18.053 2.19-.271-1.5-3.1zm4 3.47-.688-1.432-3.847.476-.318 1.557-2.722.337 2.356-10.462 3.028-.54 4.915 9.727z"
            fill="black"
          />
          <Path
            data-name="Trazado 50"
            d="m68.595 9.848.091 2.438-3.729.461.962 7.768-2.53.313-.962-7.768-3.936.487-.091-2.438z"
            fill="black"
          />
          <Path
            data-name="Trazado 51"
            d="m72.348 15.753 2.19-.271-1.5-3.105zm4 3.47-.688-1.432-3.847.476-.318 1.556-2.722.337 2.356-10.462 3.028-.54 4.915 9.727z"
            fill="black"
          />
          <Path
            data-name="Trazado 52"
            d="m82.362 18.479-2.515.311-1.26-10.18 3.255-.4 3.706 5.911 2.168-6.638 3.255-.4 1.26 10.18-2.515.311-.74-5.977-1.967 6.313-1.938.24-3.448-5.642z"
            fill="black"          />
        </G>
      </G>
    </Svg>
    );
}

export default Logo;
