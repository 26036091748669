import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import POAPService from "@core/services/POAPService";
import ProfileService from "@core/services/ProfileService";
import Wallet from "@core/wallet/Wallet";
import i18n from "@i18n/i18n";
import { POAPsNavigatorScreens } from "@navigation/POAPsNavigator";
import { ClaimPoapModal } from "@screens/poaps/components/ClaimPoapModal";
import { hideModalBottom, hideScanner, ready, showModalBottom, showPopup, showSnackbar } from "@store/actions/global";
import store from "@store/index";
import { Platform } from "react-native";

const { t } = i18n;

export const isPOAP = (navigation, qrCode) => {
    const kioskPOAP = /^https?:\/\/kiosk\.poap\.xyz\/claim\/.*$/i;
    const claimPOAP = /^https?:\/\/.*poap\.xyz\/claim\/.*$/i;
    const mintPOAP = /^https?:\/\/poap\.xyz\/mint\/.*$/i;

    switch (true) {
        case kioskPOAP.test(qrCode):
            return getKiosk(navigation, qrCode);

        case claimPOAP.test(qrCode):
            return getPOAP(navigation, qrCode);

        case mintPOAP.test(qrCode):
            return getPOAP(navigation, qrCode);
        default:
            return;
    }
};

const getKiosk = (navigation, url?: string) => {
    if (!ModuleControlService.getInstance().isModuleEnabled(Modules.poapsModule)) {
        store.dispatch(hideScanner());
        store.dispatch(
            showSnackbar({
                type: "ERROR",
                message: t("error_module_disabled"),
            })
        );
        return;
    }
    store.dispatch(hideScanner());
    if (Platform.OS == "web") {
        store.dispatch(
            showSnackbar({
                type: "ERROR",
                message: t("an_error_has_occurred"),
            })
        );
        return;
    }
    navigation.navigate("POAPs", {
        screen: POAPsNavigatorScreens.WebViewPOAPS.routeName,
        params: { url: url },
    });
};

export const getPOAP = async (navigation, data?: string) => {
    if (!ModuleControlService.getInstance().isModuleEnabled(Modules.poapsModule)) {
        store.dispatch(hideScanner());
        store.dispatch(
            showSnackbar({
                type: "ERROR",
                message: t("error_module_disabled"),
            })
        );
        return;
    }
    const decode = data?.split("/") || [];

    if (decode.length > 3 && decode[3] == "mint") {
        const claimID = decode[decode.length - 1];

        const poapData = await POAPService.getInstance().getClaimData(claimID);

        if (poapData?.claimed) {
            store.dispatch(hideScanner());
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("poap_claimed"),
                })
            );
            return;
        }

        if (!poapData?.is_active) {
            store.dispatch(hideScanner());
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("poap_inactive"),
                })
            );
            return;
        }

        store.dispatch(hideScanner());

        store.dispatch(
            showModalBottom({
                modalContent: <ClaimPoapModal claimData={poapData} onPress={() => mintPOAP(navigation, poapData)} />,
            })
        );
    }

    if (decode.length > 3 && decode[3] == "claim") {
        const claimID = decode[decode.length - 1];

        const poapData = await POAPService.getInstance().getClaimData(claimID);

        if (poapData?.claimed) {
            store.dispatch(hideScanner());
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("poap_claimed"),
                })
            );
            return;
        }

        if (!poapData?.is_active) {
            store.dispatch(hideScanner());
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("poap_inactive"),
                })
            );
            return;
        }

        store.dispatch(hideScanner());

        store.dispatch(
            showModalBottom({
                modalContent: <ClaimPoapModal claimData={poapData} onPress={() => mintPOAP(navigation, poapData)} />,
            })
        );
    }
};

const mintPOAP = async (navigation, poapData) => {
    const address = Wallet.getInstance().findCurrencyById("ethereum.mainnet.native.eth").getAddress();
    store.dispatch(hideModalBottom());
    const resp = await POAPService.getInstance().claimPOAP(address, poapData?.qr_hash, poapData?.secret);

    if (resp?.claimed) {
        const client = store.getState().auth.client;

        setTimeout(() => {
            store.dispatch(ready());
            store.dispatch(
                showPopup({
                    type: "SUCCESS",
                    message: `${t("congratulations")} ${t("info_receive")} POAP`,
                })
            );
            ProfileService.getInstance().setProfilePOAPs(client._id);
            navigateTo(navigation, client._id);
        }, 4000);
        setTimeout(() => {
            ProfileService.getInstance().setProfilePOAPs(client._id);
        }, 15000);
    }
};

const navigateTo = (navigation, clientID) => {
    // store.dispatch(showPopup({ type: "SUCCESS" }));
    navigation.navigate("Home", {
        screen: "HomeMain",
    });
};
