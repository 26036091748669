import {StyleSheet, Appearance} from "react-native"

const darkColors = {
    primary: '#303030',
    secondary: 'rgba(255,255,255,0.20)',
    complementary: '#746129',
    tertiary: '#1a1a1a', 
    background: '#4b4432',
    gradientFrom: '#2b210e',
    gradientTo: '#000',
    grey: '#746129',
    white: '#bea054',
    black: '#333',
    blue: '#2696fb',
    green: '#1DBC60',
    yellow: '#FECA54',
    red: '#EE4540',
    text: '#d5c89f',
    shadow: 'rgba(255,255,255,0.04)',
    secondaryShadow: 'rgba(255, 255, 255, 0.1)',
    tertiaryShadow: 'rgba(255, 255, 255, 0.08)',
    highlightedText: "#c2b280",
    incognitoButton: "#1c1b18"
  
}

const lightColors = {
    primary: '#d8d9db',
    secondary: '#cca532',
    complementary: '#cca532',
    tertiary: '#fff', 
    background: '#d8d9db',
    gradientFrom: '#f9f7f3',
    gradientTo: '#dbdad8',
    grey: '#b3953cc4',
    white: 'white',
    black: '#333',
    blue: '#2696fb',
    green: '#1DBC60',
    yellow: '#FECA54',
    red: '#EE4540',
    text: '#715719',
    shadow: 'rgba(255,255,255,0.7)',
    secondaryShadow: 'rgba(0, 0, 0, 0.1)',
    tertiaryShadow: 'rgba(239, 183, 66, 0.1)'
}

const settings = {
    cardRadius: 0
}

export { lightColors, darkColors, settings }
