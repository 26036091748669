import React, { Component } from "react";
import { View, StyleSheet, FlatList, RefreshControl, Platform } from "react-native";
import { connect } from "react-redux";
import { colors } from "@styles/globalStyles";
import { Client } from "@custom-types/Client";
import PostCard from "./components/PostCard";
import SocialHeader from "./components/SocialHeader";
import Post from "@custom-types/Post";
import { NavigationType } from "@custom-types/NavigationType";
import RegularText from "@base/RegularText";
import SocialNetworkService from "./service/SocialNetworkService";
import Container from "@base/Container";
import store from "@store/index";
import {
    addDiscoverPosts,
    addMainPosts,
    reloadPosts,
    setDiscoverPosts,
    setMainPosts,
} from "@store/actions/social.actions";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { FloatButton } from "@base/FloatButton";

import i18n from "@i18n/i18n";

import { loading, ready, showModal } from "@store/actions/global";
import { SocialNetworkNavigatorScreens } from "@navigation/SocialNetworkNavigator";
import { ModuleControlService } from "@core/services/ModuleControlService";

interface Props {
    client: Client;
    navigation: NavigationType;
    mainPosts: Array<Post>;
    discoverPosts: Array<Post>;
    reloadPosts: boolean;
}

interface State {
    syncing: boolean;
    page: number;
    totalPages: number;
    warning: boolean;
    adsCounter: number;
}

const { t } = i18n;

export class _SocialNetworkMainScreen extends Component<Props, State> {
    socialNetworkService: SocialNetworkService;
    focusListener: any;
    constructor(props: Props) {
        super(props);
        this.socialNetworkService = SocialNetworkService.getInstance();
        this.state = {
            syncing: false,
            page: 1,
            totalPages: 1,
            warning: false,
            adsCounter: 1,
        };
        this.onRefresh = this.onRefresh.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount = async () => {
        store.dispatch(loading());
        await this.getPosts();
        store.dispatch(ready());
    };

    componentDidUpdate = async (props) => {
        if (this.props.reloadPosts) {
            this.getPosts();
        }
    };
    componentWillUnmount() {
        try {
            this.focusListener.remove();
        } catch (e) {}
    }

    getPosts = async () => {
        store.dispatch(reloadPosts(false));

        const newPost: Array<Post> = this.props.mainPosts.length == 1 ? this.props.mainPosts : [];

        var posts = await this.socialNetworkService.getPosts(this.state.page);
        if (posts) {
            store.dispatch(setMainPosts(newPost.concat(posts.docs)));
            this.setState({
                totalPages: posts.totalPages ? posts.totalPages : 1,
                warning: false,
            });

            if (posts.totalDocs < 1) {
                var posts = await this.socialNetworkService.getPostsDiscover();
                store.dispatch(setMainPosts(newPost.concat(posts.docs)));
                this.setState({
                    warning: true,
                });
            }
        }
    };

    renderItem = ({ item }) => (
        <PostCard isLikeable={true} onFollow={this.onFollow} post={item} navigation={this.props.navigation} />
    );

    onRefresh = async () => {
        store.dispatch(loading());
        this.setState({ page: 1 });
        await this.getPosts();
        store.dispatch(ready());
    };

    loadMore = async () => {
        if (this.state.page < this.state.totalPages) {
            if (!this.state.warning) {
                var posts = await this.socialNetworkService.getPosts(this.state.page + 1);
                if (posts) {
                    store.dispatch(addMainPosts(posts.docs));
                    this.setState({
                        page: this.state.page + 1,
                    });
                }
            } else {
                var posts = await this.socialNetworkService.getPostsDiscover();
                if (posts) {
                    store.dispatch(addMainPosts(posts.docs));
                    this.setState({
                        page: this.state.page + 1,
                    });
                }
            }
        }
        if (this.state.page >= this.state.totalPages) {
            this.setState({ page: 1 });
            const postsRepeat = this.props.mainPosts.map((p) => p.id);
            var posts = await this.socialNetworkService.getPostsDiscover();
            if (posts) {
                store.dispatch(addMainPosts(posts.docs));
                this.setState({
                    page: this.state.page + 1,
                });
            }
        }
    };

    onFollow = async (clientID) => {
        const posts = this.props.mainPosts.map((item) => {
            if (item.author.clientID == clientID) {
                item.author.follow = !item.author.follow;
            }
            return item;
        });

        store.dispatch(setMainPosts(posts));

        this.setState({ warning: false });

        const discoveryPosts = this.props.discoverPosts.map((item) => {
            if (item.author.clientID == clientID) {
                item.author.follow = !item.author.follow;
            }
            return item;
        });
        store.dispatch(setDiscoverPosts(discoveryPosts));

        const follow = await this.socialNetworkService.followUser(clientID);
    };

    render() {
        return (
            <ScreenWrapper>
                <SocialHeader navigation={this.props.navigation} />
                <Container style={{ paddingHorizontal: 0, flex: 1 }}>
                    <FlatList
                        data={this.props.mainPosts}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item.id}
                        contentContainerStyle={{ paddingBottom: 20 }}
                        initialNumToRender={2}
                        maxToRenderPerBatch={5}
                        refreshControl={
                            <RefreshControl
                                tintColor={colors.text}
                                onRefresh={this.onRefresh}
                                refreshing={this.state.syncing}
                            />
                        }
                        onEndReachedThreshold={1}
                        onEndReached={this.loadMore}
                        extraData={this.state.warning}
                    />
                    {ModuleControlService.getInstance().isNecessaryUserVerified(this.props.client?.verified) && (
                        <FloatButton
                            onPress={() =>
                                this.props.navigation.navigate(SocialNetworkNavigatorScreens.NewPost.routeName)
                            }
                            iconName={"plus"}
                        />
                    )}
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        mainPosts: state.social.mainPosts,
        discoverPosts: state.social.discoverPosts,
        reloadPosts: state.social.reloadPosts,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SocialNetworkMainScreen = connect(mapStateToProps, mapDispatchToProps)(_SocialNetworkMainScreen);

export default SocialNetworkMainScreen;

const styles = StyleSheet.create({});
