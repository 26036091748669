import React, { Component } from "react"
import { View, StyleSheet } from "react-native"
import { Header } from "@components/header/Header"
import Container from "@base/Container"
import { HeaderType } from "@custom-types/HeaderType"
import { NavigationType } from "@custom-types/NavigationType"
import { colors, settings } from "@styles/globalStyles"
import { connect } from "react-redux"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import InlineButton from "@base/InlineButton"
import RegularTextInput from "@base/RegularTextInput"
import i18n from "@i18n/i18n"
import RegularText from "@base/RegularText"
import Icon from "@components/icons"
import { KriptonService } from "@core/services/KriptonService"
import WarningCard from "@base/WarningCard"
import store from "@store/index"
import { loading, ready } from "@store/actions/global"
import { KycStatus } from "@custom-types/KycStatus"
import StatusCard from "@components/cards/StatusCard"
import { FloatButton } from "@base/FloatButton"
import SimpleCard from "@components/cards/SimpleCard"
import { KriptonMarketNavigatorScreens } from "@navigation/KriptonMarketNavigator"
import { WalletNavigatorScreens } from "@navigation/WalletNavigator"

interface Props {
    navigation: NavigationType
    route: any
}

interface State {
    clientId: number
    email: string
    information: KycStatus
    domicile: KycStatus
    bank: KycStatus
    ready: boolean
    error: string
}

const { t } = i18n

export class _ClientStatusScreen extends Component<Props, State> {
    private kriptonService: KriptonService
    focusListener: any
    constructor(props: Props) {
        super(props)
        this.kriptonService = KriptonService.getInstance()
        this.state = {
            clientId: null,
            email: "",
            information: KycStatus.approving,
            domicile: KycStatus.pending,
            bank: KycStatus.checking,
            ready: false,
            error: "",
        }
        this.onPressNext = this.onPressNext.bind(this)
    }

    async componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", async () => {
            store.dispatch(loading())
            await this.getDataClient()
            this.forceUpdate()
            store.dispatch(ready())
        })
    }

    componentWillUnmount() {
        try {
            this.focusListener.remove()
        } catch (e) { }
    }

    changeInputValue(value) {
        this.setState({
            email: value,
            error: "",
        })
    }

    async getDataClient() {
        const status = await this.kriptonService.getClientStatus()
        if (status) {
            this.setState({
                information: status.kyc.information,
                domicile: status.kyc.domicile,
                bank: status.kyc.bank,
                email: status.email ? status.email : "",
            })

            this.setState({ clientId: status.id, ready: true })
        }
    }

    async onPressNext() {
        store.dispatch(loading())
        this.setState({ error: "" })

        try {
            const newClient = await this.kriptonService.newClietnEmail(this.state.email)
            if (newClient) {
                this.getDataClient()
            }
        } catch (e) {
            this.setState({
                error: t("an_error_has_occurred"),
            })
        }

        store.dispatch(ready())
    }

    validate() {
        return this.state.email.length > 2
    }

    render() {

        return (
            <ScreenWrapper>
                <Header
                    title={t("identity_verification")}
                    type={HeaderType.Light}
                    {...this.props}
                    backTo={this.props.route?.params
                        ? this.props.route.params.backTo
                        : WalletNavigatorScreens.Wallet.routeName
                    }
                />
                {this.state.ready && (
                    <View style={{ flex: 1, height: "100%" }}>
                        {!this.state.clientId && (
                            <Container>
                                <WarningCard message={t("kyc_warning")} />
                                <View style={styles.inputContainer}>
                                    <RegularTextInput
                                        style={styles.input}
                                        align={"center"}
                                        onChangeText={(text) => this.changeInputValue(text)}
                                        value={this.state.email}
                                        placeholder={t("enter_email")}></RegularTextInput>
                                </View>
                            </Container>
                        )}
                        {this.state.clientId && (
                            <Container style={styles.container}>
                                <View
                                    style={{
                                        paddingHorizontal: 20,
                                        paddingVertical: 14,
                                        alignItems: "center",
                                        flexDirection: "row",
                                        backgroundColor: colors.shadow,
                                        borderRadius: settings.cardRadius,
                                        marginVertical: 5,
                                        alignContent: "center",

                                    }}>
                                    <Icon name={"person-profile"} style={{ marginRight: 10 }} size={20} color={colors.text} />
                                    <RegularText>{this.state.email}</RegularText>
                                </View>

                                {(this.state.information !== "approving" ||
                                    this.state.bank !== "approving" ||
                                    this.state.domicile !== "approving") &&
                                    this.state.information !== "pending" &&
                                    this.state.bank !== "pending" &&
                                    this.state.domicile !== "pending" && (
                                        <WarningCard message={t("kyc_validation_pending")} />
                                    )}

                                {(this.state.information == "pending" ||
                                    this.state.bank == "pending" ||
                                    this.state.domicile == "pending") && (
                                        <WarningCard message={t("kyc_country_warning")} />
                                    )}
                                <View>
                                    <StatusCard
                                        title={t("personal_information_optional")}
                                        targetScreen={KriptonMarketNavigatorScreens.PersonalInformationKripton.routeName}
                                        status={this.state.information}
                                        navigation={this.props.navigation}
                                    />
                                    <StatusCard
                                        title={t("bank")}
                                        targetScreen={KriptonMarketNavigatorScreens.BankKripton.routeName}
                                        status={this.state.bank}
                                        navigation={this.props.navigation}
                                    />
                                    <StatusCard
                                        title={t("domicile")}
                                        targetScreen={KriptonMarketNavigatorScreens.AddressStreetKripton.routeName}
                                        status={this.state.domicile}
                                        navigation={this.props.navigation}
                                    />
                                </View>

                                {(this.state.information == "approving" &&
                                    this.state.bank == "approving" &&
                                    this.state.domicile == "approving") && (
                                        <FloatButton
                                            onPress={() => this.props.navigation.navigate(KriptonMarketNavigatorScreens.BuyMethodKripton.routeName)}
                                            iconName={"cart"}
                                        />
                                    )}
                            </Container>
                        )}
                        {!this.state.clientId && this.validate() && (
                            <Container style={{ flex: 1, flexDirection: "column-reverse" }}>
                                <View>
                                    <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                        {this.state.error}
                                    </RegularText>
                                    <InlineButton
                                        title={t("save")}
                                        onPress={this.onPressNext}
                                        style={{
                                            marginTop: 20,
                                            marginHorizontal: 0,
                                            marginBottom: 20,
                                        }}
                                    />
                                </View>
                            </Container>
                        )}
                    </View>
                )}
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    inputContainer: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 10,
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        paddingHorizontal: 40,
        textAlign: "center",
    },
})

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => ({})

const ClientStatusScreen = connect(mapStateToProps, mapDispatchToProps)(_ClientStatusScreen)

export default ClientStatusScreen
