import { IWalletConnectSession, IWalletConnectStore } from "@core/services/WalletConnectDataService";

export const ADD_CONNECTION = 'ADD_CONNECTION';
export const SET_CONNECTIONS = 'SET_CONNECTIONS';


export const addConnection = (connection: IWalletConnectStore) => {
    return {type: ADD_CONNECTION, connection: connection}
}

export const setConnections = (connections: Array<any>) => {
    return {type: SET_CONNECTIONS, connections: connections}
}
