import React, { Component } from "react";
import { StyleSheet, FlatList } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import store from "@store/index";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import OptionCard from "@components/cards/OptionCard";
import { KriptonService } from "@core/services/KriptonService";
import { connect } from "react-redux";
import { loading, ready } from "@store/actions/global";
import { setFiatCurrency } from "@store/actions/kriptonmarket.actions";
import { KriptonMarketNavigatorScreens } from "@navigation/KriptonMarketNavigator";

interface Props {
  navigation: NavigationType
  country: string
  currency: string
}

interface State {
  currencies: Array<any>
  selectedCurrency: string
}

const { t } = i18n

class _SelectFiatCurrencyScreen extends Component<Props, State> {
  private from = true;
  private kriptonService: KriptonService

  constructor(props: Props) {
    super(props);
    this.onPress = this.onPress.bind(this);
    this.state = {
      currencies: [],
      selectedCurrency: this.props.currency
    };
    this.kriptonService = KriptonService.getInstance();
    this.init();
  }

  async init() {
    store.dispatch(loading());
    const country = this.props.country;
    const currencies = await this.kriptonService.getCurrenciesFromCountry(country);
    this.setState({currencies});
    store.dispatch(ready());
  }

  onPress = (currency: string) => {
    store.dispatch(setFiatCurrency(currency));
    this.props.navigation.navigate(KriptonMarketNavigatorScreens.BuyMethodKripton.routeName);
  };

  renderItem = ({ item }) => (
    <OptionCard 
      title={item}
      onPress={() => this.onPress(item)}
      selected={this.state.selectedCurrency == item}
      />
  );

  render() {
    return (
      <ScreenWrapper>
        <Header
          {...this.props}
          title={t ('select_currency')}
          type={HeaderType.Light}
        />
        <Container style={styles.container}>
          {this.state.currencies &&
            <FlatList
              data={this.state.currencies}
              renderItem={this.renderItem}
              keyExtractor={(item) => item}
              contentContainerStyle={{ paddingBottom: 20 }}
              initialNumToRender={20}
              maxToRenderPerBatch={10}
            />
          }
        </Container>
      </ScreenWrapper>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: -15,
    overflow: "hidden",
    padding: 40,
  },
});


const mapStateToProps = (state) => {
  return state.kriptonMarket;
};

const mapDispatchToProps = (dispatch) => ({});

const SelectFiatCurrencyScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(_SelectFiatCurrencyScreen);

export default SelectFiatCurrencyScreen;