import { LogBox, Platform } from "react-native"

export interface Global {
    btoa: any
    atob: any
    self: any
    Buffer: any
    process: any
    location: any
    TextEncoder: any
}

declare var global: Global
if (typeof global.self === 'undefined') {
    global.self = global
}

if (typeof btoa === 'undefined') {
    global.btoa = function (str: string) {
        return new Buffer(str, 'binary').toString('base64')
    }
}

if (typeof atob === 'undefined') {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    global.atob = function  (input: string = '') {
        let str = input.replace(/=+$/, '');
        let output = '';

        if (str.length % 4 == 1) {
            throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
        }
        for (let bc = 0, bs = 0, buffer, i = 0;
            buffer = str.charAt(i++);

            ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
                bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
        ) {
            buffer = chars.indexOf(buffer);
        }

        return output;
    }
}

if (Platform.OS !== "web") {
    require("react-native-get-random-values");
    LogBox.ignoreLogs([
        "Warning: The provided value 'ms-stream' is not a valid 'responseType'.",
        "Warning: The provided value 'moz-chunked-arraybuffer' is not a valid 'responseType'.",
    ]);
}

global.Buffer = require('buffer').Buffer
global.process = require('process');

global.TextEncoder = require('text-encoding').TextEncoder;

