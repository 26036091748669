import NavigationInstantiator from "./helpers/NavigationInstantiator";
import BlogScreen from "@screens/blog/BlogScreen";

const BlogNavigatorScreens = {
    Blog: {
        routeName: "Blog",
        title: "Blog",
        component: BlogScreen,
    },
};

const BlogNavigator = NavigationInstantiator(BlogNavigatorScreens);

export { BlogNavigator, BlogNavigatorScreens };
