import * as React from "react"
import Svg, { Defs, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

const Logo = (props) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg' style={{ marginTop: 0 }} height={55} width={200}
    viewBox="0 0 664.48 305.92"
    {...props}
  >
 
    <Defs id="defs81"></Defs>
    <Path
      className="cls-1"
      id="polygon83"
      style={{
        fill: "#fff",
      }}
      transform="matrix(.62377 0 0 .62377 17.864 57.49)"
      d="M304.6 137.41V0H164.52v49.52h92.98v87.89z"
    />
    <Path
      className="cls-1"
      id="rect85"
      style={{
        fill: "#fff",
        strokeWidth: 0.869413,
      }}
      d="M228.969 57.49h27.771v191.568h-27.771z"
    />
    <Path
      className="cls-1"
      d="M113.281 57.496Z"
      id="path87"
      style={{
        fill: "#fff",
        strokeWidth: 0.623768,
      }}
    />
    <Path
      className="cls-1"
      d="M112.864 220.162c-36.99 0-66.974-30.11-66.974-67.26 0-36.522 28.986-66.245 65.108-67.237v-28.15c-51.604.998-93.134 43.314-93.134 95.386 0 52.696 42.534 95.437 95 95.437 52.465 0 95-42.716 95-95.437h-28.027c0 37.152-29.984 67.261-66.973 67.261z"
      id="path89"
      style={{
        fill: "#fff",
        strokeWidth: 0.623768,
      }}
    />
    <Path
      className="cls-1"
      d="M302.117 103.867a26.773 26.773 0 0 1-9.811-1.753 22.873 22.873 0 0 1-7.816-4.974 23.145 23.145 0 0 1-5.185-7.559 25.352 25.352 0 0 1 0-18.987 23.22 23.22 0 0 1 5.215-7.56 23.523 23.523 0 0 1 7.846-4.973 28.723 28.723 0 0 1 20.182.197 20.59 20.59 0 0 1 7.907 5.775l-5.533 5.2a17.657 17.657 0 0 0-5.685-4.051 16.63 16.63 0 0 0-6.651-1.346 17.914 17.914 0 0 0-6.622 1.18 15.435 15.435 0 0 0-5.215 3.37 15.722 15.722 0 0 0-3.462 5.14 17.627 17.627 0 0 0 0 13.062 15.888 15.888 0 0 0 3.462 5.14 15.117 15.117 0 0 0 5.215 3.356 17.612 17.612 0 0 0 6.622 1.194 16.312 16.312 0 0 0 6.651-1.36 16.992 16.992 0 0 0 5.685-4.112l5.533 5.2a20.817 20.817 0 0 1-7.907 5.805 26.667 26.667 0 0 1-10.431 2.056z"
      id="path91"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M325.867 103.202V68.054h7.846v9.69l-.923-2.827a10.824 10.824 0 0 1 4.944-5.442 17.189 17.189 0 0 1 8.375-1.875v7.846l-.922-.105h-.862a10.31 10.31 0 0 0-7.559 2.706c-1.844 1.799-2.781 4.535-2.781 8.239v17.007Z"
      id="path93"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M355.33 116.43a16.236 16.236 0 0 1-5.2-.862 11.157 11.157 0 0 1-4.218-2.373l3.22-6.047a10.582 10.582 0 0 0 2.812 1.723 8.224 8.224 0 0 0 3.266.65 5.518 5.518 0 0 0 3.613-1.119 10.083 10.083 0 0 0 2.585-3.824l1.965-4.536.801-1.058 13.243-30.93h7.922l-16.494 38.111a22.071 22.071 0 0 1-3.658 6.168 11.49 11.49 0 0 1-4.535 3.235 14.86 14.86 0 0 1-5.322.862zm7.196-12.003-15.949-36.373h8.496l12.986 30.401z"
      id="path95"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M388.438 116.006V68.054h7.846v8.314l-.333 9.358.665 9.373v20.968zm19.456-12.335a15.813 15.813 0 0 1-7.846-1.95 13.606 13.606 0 0 1-5.548-5.957 22.42 22.42 0 0 1-2.041-10.159 23.236 23.236 0 0 1 1.95-10.189 13.001 13.001 0 0 1 5.503-5.941 16.493 16.493 0 0 1 7.982-1.935 18.519 18.519 0 0 1 9.161 2.237 16.433 16.433 0 0 1 6.365 6.335 20.469 20.469 0 0 1 0 19.017 16.63 16.63 0 0 1-6.365 6.304 18.519 18.519 0 0 1-9.161 2.238zm-.998-6.985a10.416 10.416 0 0 0 5.382-1.39 10.114 10.114 0 0 0 3.719-3.886 12.88 12.88 0 0 0 0-11.64 9.841 9.841 0 0 0-3.72-3.825 10.582 10.582 0 0 0-5.38-1.36 10.431 10.431 0 0 0-5.277 1.36 9.811 9.811 0 0 0-3.749 3.825 12.88 12.88 0 0 0 0 11.64 10.083 10.083 0 0 0 3.75 3.886 10.31 10.31 0 0 0 5.275 1.39z"
      id="path97"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M426.806 74.857v-6.591h23.477v6.59zm17.944 28.814a12.457 12.457 0 0 1-8.964-3.024 11.52 11.52 0 0 1-3.175-8.798V60.223h8.178v31.384a5.427 5.427 0 0 0 1.316 3.885 4.883 4.883 0 0 0 3.703 1.39 6.833 6.833 0 0 0 4.536-1.51l2.373 5.865a10.446 10.446 0 0 1-3.628 1.784 15.798 15.798 0 0 1-4.339.65z"
      id="path99"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M472.052 103.67a19.562 19.562 0 0 1-9.584-2.343 17.325 17.325 0 0 1-6.697-6.424 17.9 17.9 0 0 1 6.652-25.02 19.41 19.41 0 0 1 9.63-2.343 19.743 19.743 0 0 1 9.735 2.343 17.34 17.34 0 0 1 6.652 6.35 19.38 19.38 0 0 1 0 18.7 17.158 17.158 0 0 1-6.697 6.394 19.895 19.895 0 0 1-9.69 2.344zm0-6.984a10.703 10.703 0 0 0 5.412-1.36 9.327 9.327 0 0 0 3.72-3.885 12.185 12.185 0 0 0 1.36-5.836 11.928 11.928 0 0 0-1.36-5.835 9.448 9.448 0 0 0-3.72-3.825 11.172 11.172 0 0 0-10.688 0 9.811 9.811 0 0 0-3.749 3.825 11.777 11.777 0 0 0-1.39 5.835 12.094 12.094 0 0 0 1.39 5.836 9.675 9.675 0 0 0 3.75 3.885 10.431 10.431 0 0 0 5.275 1.36z"
      id="path101"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M292.502 175.509v-38.913h-15.299v-7.256h39.14v7.256h-15.27v38.913z"
      id="path103"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M319.14 175.509v-35.224h7.785v9.706l-.922-2.843a10.93 10.93 0 0 1 4.958-5.442 17.128 17.128 0 0 1 8.36-1.874v7.846l-.922-.106h-.847a10.355 10.355 0 0 0-7.558 2.706q-2.767 2.69-2.767 8.239v17.022z"
      id="path105"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M352.443 175.962a16.735 16.735 0 0 1-6.924-1.345 10.718 10.718 0 0 1-4.535-3.764 9.57 9.57 0 0 1-1.618-5.443 10.144 10.144 0 0 1 1.421-5.336 9.509 9.509 0 0 1 4.642-3.764 22.177 22.177 0 0 1 8.571-1.376h10.22v5.472h-9.615c-2.782 0-4.656.454-5.639 1.346a4.4 4.4 0 0 0-1.512 3.401 4.142 4.142 0 0 0 1.769 3.492 8.209 8.209 0 0 0 4.943 1.316 10.703 10.703 0 0 0 5.443-1.376 8.27 8.27 0 0 0 3.537-4.097l1.315 4.944a9.237 9.237 0 0 1-4.217 4.822 15.828 15.828 0 0 1-7.801 1.708zm11.081-.453v-7.196l-.393-1.512v-12.457a7.271 7.271 0 0 0-2.207-5.638 9.66 9.66 0 0 0-6.697-2.011 18.337 18.337 0 0 0-5.745.922 15.117 15.117 0 0 0-4.868 2.57l-3.235-6.047a21.028 21.028 0 0 1 6.788-3.296 29.494 29.494 0 0 1 8.118-1.118q7.71 0 11.867 3.688 4.158 3.69 4.158 11.339v20.65Z"
      id="path107"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M394.711 175.962a18.534 18.534 0 0 1-9.07-2.237 16.251 16.251 0 0 1-6.364-6.304 20.696 20.696 0 0 1 0-19.018 16.221 16.221 0 0 1 6.304-6.274 18.534 18.534 0 0 1 9.07-2.237 15.919 15.919 0 0 1 7.906 1.95 13.606 13.606 0 0 1 5.549 5.926 22.344 22.344 0 0 1 2.04 10.19 23.084 23.084 0 0 1-1.98 10.158 13.258 13.258 0 0 1-5.442 5.957 16.16 16.16 0 0 1-8.013 1.89zm.983-6.984a10.34 10.34 0 0 0 5.306-1.36 9.811 9.811 0 0 0 3.765-3.886 11.7 11.7 0 0 0 1.42-5.835 11.474 11.474 0 0 0-1.42-5.835 10.053 10.053 0 0 0-3.765-3.886 11.142 11.142 0 0 0-10.582 0 10.129 10.129 0 0 0-3.749 3.825 11.474 11.474 0 0 0-1.421 5.835 11.7 11.7 0 0 0 1.421 5.836 9.872 9.872 0 0 0 3.75 3.885 10.34 10.34 0 0 0 5.275 1.421zm10.749 6.53v-8.314l.196-9.358-.65-9.372v-21.89h8.3v48.935z"
      id="path109"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M427.154 134.57a5.216 5.216 0 0 1-3.795-1.511 4.822 4.822 0 0 1-1.512-3.568 4.671 4.671 0 0 1 1.512-3.431 5.216 5.216 0 0 1 3.795-1.512 5.412 5.412 0 0 1 3.794 1.36 4.535 4.535 0 0 1 1.512 3.462 4.913 4.913 0 0 1-1.512 3.583 5.08 5.08 0 0 1-3.794 1.618zm-4.097 41.014v-35.223h8.178v35.223z"
      id="path111"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M459.958 139.832a16.448 16.448 0 0 1 7.56 1.647 12.396 12.396 0 0 1 5.215 5.08 17.718 17.718 0 0 1 1.935 8.768v20.182h-8.24v-19.124a9.887 9.887 0 0 0-2.176-7 8.133 8.133 0 0 0-6.198-2.297 10.87 10.87 0 0 0-5.14 1.18 8.148 8.148 0 0 0-3.538 3.537 12.623 12.623 0 0 0-1.224 5.895v17.809h-8.149v-35.224h7.861v9.57l-1.375-3.024a12.094 12.094 0 0 1 5.306-5.216 17.355 17.355 0 0 1 8.163-1.783z"
      id="path113"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M497.964 173.876a18.988 18.988 0 0 1-9.07-2.147 16.342 16.342 0 0 1-6.365-5.971 16.63 16.63 0 0 1-2.328-8.889 17.052 17.052 0 0 1 2.328-9.07 16.236 16.236 0 0 1 6.364-5.972 19.32 19.32 0 0 1 9.07-2.101 17.733 17.733 0 0 1 8.089 1.814 13.47 13.47 0 0 1 5.699 5.563 19.728 19.728 0 0 1 2.101 9.706 19.396 19.396 0 0 1-2.101 9.554 13.606 13.606 0 0 1-5.7 5.608 17.551 17.551 0 0 1-8.087 1.905zm1.058 14.83a34.891 34.891 0 0 1-9.524-1.285 20.257 20.257 0 0 1-7.559-3.794l3.689-6.123a18.519 18.519 0 0 0 5.805 3.1 22.525 22.525 0 0 0 7.256 1.179q5.73 0 8.436-2.661a10.93 10.93 0 0 0 2.706-8.088v-6.228l.665-7.907-.272-7.982v-8.647h7.846v29.872q0 9.57-4.868 14.09t-14.18 4.52zm.196-21.814a11.686 11.686 0 0 0 5.549-1.3 9.312 9.312 0 0 0 3.779-3.553 9.857 9.857 0 0 0 1.36-5.17 9.962 9.962 0 0 0-1.36-5.276 9.312 9.312 0 0 0-3.78-3.507 13.077 13.077 0 0 0-11.11 0 9.252 9.252 0 0 0-3.825 3.507 9.962 9.962 0 0 0-1.361 5.276 9.857 9.857 0 0 0 1.36 5.17 9.252 9.252 0 0 0 3.825 3.553 11.792 11.792 0 0 0 5.609 1.345z"
      id="path115"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M556.922 176.159a22.45 22.45 0 0 1-8.617-1.512 14.89 14.89 0 0 1-6.047-4.354 9.917 9.917 0 0 1-2.177-6.394 12.245 12.245 0 0 1 1.512-6.047 16.932 16.932 0 0 1 4.535-5.05 48.058 48.058 0 0 1 8.148-4.837 60.893 60.893 0 0 0 6.047-3.371 11.278 11.278 0 0 0 3.1-2.767 4.883 4.883 0 0 0 .891-2.766 4.097 4.097 0 0 0-1.39-3.205 5.85 5.85 0 0 0-3.961-1.21 6.44 6.44 0 0 0-4.324 1.346 4.4 4.4 0 0 0-1.512 3.462 6.455 6.455 0 0 0 .393 2.237 8.088 8.088 0 0 0 1.588 2.373c.786.892 1.86 2.011 3.235 3.372l24.415 23.417-4.536 5.351-26.365-25.322a33.122 33.122 0 0 1-3.628-4.066 13.606 13.606 0 0 1-1.98-3.583 10.9 10.9 0 0 1-.59-3.568 9.978 9.978 0 0 1 1.618-5.669 10.492 10.492 0 0 1 4.671-3.794 17.597 17.597 0 0 1 7.09-1.346 15.888 15.888 0 0 1 6.47 1.225 9.857 9.857 0 0 1 4.309 3.462 9.161 9.161 0 0 1 1.512 5.397 9.978 9.978 0 0 1-1.36 5.11 14.513 14.513 0 0 1-4.31 4.459 53.183 53.183 0 0 1-7.845 4.414 40.817 40.817 0 0 0-5.745 3.402 10.582 10.582 0 0 0-3.023 3.13 6.591 6.591 0 0 0-.923 3.416 5.352 5.352 0 0 0 1.15 3.401 7.559 7.559 0 0 0 3.265 2.313 13.606 13.606 0 0 0 4.943.816 15.98 15.98 0 0 0 7.68-1.844 15.495 15.495 0 0 0 5.835-5.639 27.015 27.015 0 0 0 3.432-9.327l6.591 2.116a29.524 29.524 0 0 1-4.656 11.61 22.948 22.948 0 0 1-8.3 7.347 23.69 23.69 0 0 1-11.141 2.495z"
      id="path117"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M292.669 247.8v-46.153h8.556V247.8z"
      id="path119"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M330.78 212.123a16.508 16.508 0 0 1 7.558 1.648 12.275 12.275 0 0 1 5.2 5.08 17.597 17.597 0 0 1 1.951 8.768V247.8h-8.239v-19.123a9.887 9.887 0 0 0-2.177-7 8.148 8.148 0 0 0-6.198-2.297 10.87 10.87 0 0 0-5.14 1.179 8.239 8.239 0 0 0-3.462 3.537 12.623 12.623 0 0 0-1.224 5.896V247.8h-8.179v-35.208h7.846v9.554l-1.376-3.023a12.2 12.2 0 0 1 5.307-5.216 17.34 17.34 0 0 1 8.133-1.784z"
      id="path121"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="m362.572 247.8-15.118-35.208h8.511l13.047 31.051h-4.218l13.455-31.051h7.906L371.038 247.8z"
      id="path123"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M404.765 248.254a21.33 21.33 0 0 1-10.25-2.343 17.007 17.007 0 0 1-6.788-6.395 17.9 17.9 0 0 1-2.403-9.327 18.368 18.368 0 0 1 2.328-9.328 17.068 17.068 0 0 1 6.47-6.395 18.73 18.73 0 0 1 9.388-2.343 18.247 18.247 0 0 1 9.176 2.283 16.493 16.493 0 0 1 6.334 6.35 19.653 19.653 0 0 1 2.298 9.705v1.149c0 .468 0 .861-.106 1.224H391.84v-5.472h25.004l-3.31 1.708a10.416 10.416 0 0 0-1.21-5.306 9.282 9.282 0 0 0-3.537-3.628 10.34 10.34 0 0 0-5.276-1.285 10.854 10.854 0 0 0-5.306 1.285 8.708 8.708 0 0 0-3.583 3.658 11.414 11.414 0 0 0-1.255 5.473v1.315a10.854 10.854 0 0 0 1.421 5.608 9.554 9.554 0 0 0 4.052 3.795 13.137 13.137 0 0 0 6.047 1.345 13.727 13.727 0 0 0 5.351-.982 12.85 12.85 0 0 0 4.218-2.903l4.535 5.14a15.828 15.828 0 0 1-6.047 4.218 21.724 21.724 0 0 1-8.178 1.451z"
      id="path125"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M438.068 248.254a30.704 30.704 0 0 1-8.51-1.149 23.115 23.115 0 0 1-6.456-2.797l3.16-6.273a21.739 21.739 0 0 0 5.669 2.479 24.007 24.007 0 0 0 6.666.952 11.308 11.308 0 0 0 5.564-1.028 3.114 3.114 0 0 0 1.753-2.796 2.449 2.449 0 0 0-1.179-2.208 9.358 9.358 0 0 0-3.023-1.149q-1.92-.408-4.263-.725a60.27 60.27 0 0 1-4.672-.892 16.342 16.342 0 0 1-4.263-1.648 9.07 9.07 0 0 1-3.023-2.933 10.235 10.235 0 0 1 .725-10.718 12.396 12.396 0 0 1 5.382-3.855 21.996 21.996 0 0 1 8.209-1.39 31.883 31.883 0 0 1 7.15.83 20.696 20.696 0 0 1 6.047 2.268l-3.22 6.259a16.871 16.871 0 0 0-5.019-2.01 24.43 24.43 0 0 0-5.08-.56 11.096 11.096 0 0 0-5.532 1.088 3.22 3.22 0 0 0-1.844 2.812 2.676 2.676 0 0 0 1.179 2.374 9.343 9.343 0 0 0 3.099 1.21c1.285.286 2.69.559 4.263.8 1.572.242 3.099.545 4.641.923a18.791 18.791 0 0 1 4.263 1.511 8.526 8.526 0 0 1 3.13 2.873 9.917 9.917 0 0 1-.756 10.582 12.774 12.774 0 0 1-5.488 3.764 23.855 23.855 0 0 1-8.572 1.406z"
      id="path127"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M454.29 219.44v-6.591h23.477v6.591zm17.929 28.814a12.547 12.547 0 0 1-8.965-3.023 11.595 11.595 0 0 1-3.16-8.799v-31.626h8.179v31.384a5.397 5.397 0 0 0 1.315 3.885 4.807 4.807 0 0 0 3.689 1.391 6.909 6.909 0 0 0 4.535-1.511l2.374 5.865a10.19 10.19 0 0 1-3.629 1.784 15.904 15.904 0 0 1-4.338.65z"
      id="path129"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M529.257 212.123a16.327 16.327 0 0 1 7.483 1.648 11.761 11.761 0 0 1 5.11 5.08 18.292 18.292 0 0 1 1.86 8.768V247.8h-8.164v-19.123a10.159 10.159 0 0 0-2.087-7 7.559 7.559 0 0 0-5.895-2.297 9.479 9.479 0 0 0-4.777 1.179 8.088 8.088 0 0 0-3.236 3.537 13.122 13.122 0 0 0-1.148 5.836V247.8h-8.179v-19.123a10.159 10.159 0 0 0-2.086-7 7.559 7.559 0 0 0-5.896-2.297 9.479 9.479 0 0 0-4.777 1.179 8.088 8.088 0 0 0-3.235 3.537 13.122 13.122 0 0 0-1.15 5.836V247.8h-8.178v-35.208h7.846v9.418l-1.512-2.827a12.457 12.457 0 0 1 5.17-5.215 16.176 16.176 0 0 1 7.816-1.845 15.117 15.117 0 0 1 8.572 2.434 11.943 11.943 0 0 1 4.822 7.559l-3.174-1.194a13.772 13.772 0 0 1 5.608-6.335 17.204 17.204 0 0 1 9.207-2.464z"
      id="path131"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M568.804 248.254a21.285 21.285 0 0 1-10.25-2.343 17.037 17.037 0 0 1-6.802-6.395 17.9 17.9 0 0 1-2.404-9.327 18.383 18.383 0 0 1 2.343-9.328 16.962 16.962 0 0 1 6.47-6.395 18.655 18.655 0 0 1 9.388-2.343 18.141 18.141 0 0 1 9.162 2.283 16.387 16.387 0 0 1 6.334 6.35 19.456 19.456 0 0 1 2.313 9.705v1.149c0 .468 0 .861-.106 1.224h-29.419v-5.472h25.065l-3.296 1.708a10.28 10.28 0 0 0-1.24-5.306 9.07 9.07 0 0 0-3.522-3.628 10.37 10.37 0 0 0-5.276-1.285 10.764 10.764 0 0 0-5.306 1.285 8.738 8.738 0 0 0-3.598 3.658 11.595 11.595 0 0 0-1.254 5.473v1.315a11.006 11.006 0 0 0 1.42 5.608 9.736 9.736 0 0 0 4.052 3.795 13.258 13.258 0 0 0 6.138 1.345 13.606 13.606 0 0 0 5.336-.982 12.547 12.547 0 0 0 4.218-2.903l4.535 5.14a15.888 15.888 0 0 1-6.047 4.218 21.83 21.83 0 0 1-8.254 1.451z"
      id="path133"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M611.133 212.123a16.508 16.508 0 0 1 7.559 1.648 12.366 12.366 0 0 1 5.2 5.08 17.597 17.597 0 0 1 1.95 8.768V247.8h-8.239v-19.123a9.887 9.887 0 0 0-2.177-7 8.148 8.148 0 0 0-6.198-2.297 10.93 10.93 0 0 0-5.155 1.179 8.315 8.315 0 0 0-3.462 3.537 12.82 12.82 0 0 0-1.21 5.896V247.8h-8.223v-35.208h7.861v9.554l-1.39-3.023a12.366 12.366 0 0 1 5.305-5.216 17.446 17.446 0 0 1 8.179-1.784z"
      id="path135"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
    <Path
      className="cls-1"
      d="M629.486 219.44v-6.591h23.477v6.591zm17.929 28.814a12.578 12.578 0 0 1-8.98-3.023 11.595 11.595 0 0 1-3.114-8.769v-31.656h8.178v31.384a5.397 5.397 0 0 0 1.316 3.885 4.807 4.807 0 0 0 3.688 1.391 6.878 6.878 0 0 0 4.536-1.511l2.373 5.865a10.19 10.19 0 0 1-3.628 1.784 15.798 15.798 0 0 1-4.37.65z"
      id="path137"
      style={{
        fill: "#fff",
        strokeWidth: 1.51175,
      }}
    />
  </Svg>
)

export default Logo