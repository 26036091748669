import RegularText from "@base/RegularText"
import Row from "@base/Row"
import React, {Component} from "react"
import {View, StyleSheet} from "react-native"
import Icon from "@components/icons"
import {colors} from "@styles/globalStyles"
import i18n from "@i18n/i18n"

interface Props {
    rewards?: number
}

interface State {}

const {t} = i18n

export default class RewardSign extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {}
        
    }

    render() {
        return (
            <View style={{position: "absolute", bottom: 10, right: 10, zIndex: 99}}>
                {this.props?.rewards > 0 && (
                    <Row
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: colors.secondaryShadow,
                            paddingHorizontal: 10,
                            borderRadius: 15,
                            paddingVertical: 5,
                        }}>
                        <Icon size={14} style={{marginRight: 5}} color={colors.yellow} name='star-fill' />
                        <RegularText>{t("rewarded")}</RegularText>
                    </Row>
                )}
            </View>
        )
    }
}

const styles = StyleSheet.create({})
