const lightColors = {
    primary: "#050324",
    secondary: "#623AF9",
    complementary: "#623AF9",
    tertiary: "#0E0939",
    background: "#060325",
    gradientFrom: "#060325",
    gradientTo: "#004179",
    text: "#FFFFFF",
    grey: "#bbbbbb",
    white: "#FFFFFF",
    shadow: "rgba(98,58,249,0.2)",
    secondaryShadow: "rgba(98,58,249,0.1)",
    tertiaryShadow: "rgba(173,150,255,0.2)",
    highlightedText: "#ffffff",
    mainButtonsColor: "#8BE904",
    modalBackground: "#050324",
    popupBackground: "#050324",
    activeFooter: "#ffffff",
    modalBackgroundFrom: "#050324",
    modalBackgroundTo: "#050324",
    floatButtonText: "#8BE904",
    floatButtonBackground: "#623AF9",
    inlineButtonText: "#ffffff",
    avatarBase: "#6339f980",
    labelText: "#8BE904",
    incognitoButton: "#363638"

};

const darkColors = lightColors;

const settings = {};

export { lightColors, darkColors, settings };
