import Card from "@base/Card";
import RegularText from "@base/RegularText";
import SemiBoldText from "@base/SemiBold";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import AvatarBase from "@components/avatar/AvatarBase";
import ActivityService from "@core/services/ActivityService";
import Wallet from "@core/wallet/Wallet";
import { ActivityModel, ActivityTypes, OrderActivityDara } from "@custom-types/ActivityModel";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { loading, ready, showModalBottom } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import moment from "moment";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import ActivityOrderModal from "./ActivityOrderModal";
import LottieBase from "@components/animations/Lottie";

interface Props {
    navigation: NavigationType;
    activity: ActivityModel;
    client: Client;
}

interface State {
    activityData: OrderActivityDara;
}

const { t } = i18n;

export class _ActivityCardOrderPurchase extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPressCard = this.onPressCard.bind(this);
        this.state = {
            activityData: this.props?.activity?.data,
        };
    }

    async onPressCard() {
        store.dispatch(loading());
        const order = await ActivityService.getInstance().getOrder(this.state.activityData?._id);
        store.dispatch(ready());
        if (!order) return;

        store.dispatch(
            showModalBottom({
                modalContent: <ActivityOrderModal activity={this.props.activity} order={order}></ActivityOrderModal>,
            })
        );
    }

    render() {
        return (
            <>
                {this.state.activityData && (
                    <>
                        {this.props.activity?.type == ActivityTypes.OrderPurchase && (
                            <>
                                <Card
                                    onPress={this.onPressCard}
                                    icon={
                                        <View>
                                            <AvatarBase
                                                size={45}
                                                uri={
                                                    this.state?.activityData?.items[0]?.data?.merchant?.image
                                                        ?.thumbnail || ""
                                                }
                                                alias={this.state?.activityData?.items[0]?.data?.merchant?.name || ""}
                                            ></AvatarBase>
                                            <View style={styles.secondaryAvatar}>
                                                <AvatarBase
                                                    size={20}
                                                    uri={this.props.client?.profileImagePath?.thumbnail}
                                                    alias={this.props.client?.alias || ""}
                                                ></AvatarBase>
                                            </View>
                                        </View>
                                    }
                                    left={
                                        <View style={{ marginLeft: 10, justifyContent: "center" }}>
                                            <RegularText numberOfLines={2} fontSize={14} style={{ marginBottom: 2 }}>
                                                {this.state.activityData?.amount > 0
                                                    ? t("you_bought")
                                                    : t("info_receive")}{" "}
                                                <SemiBoldText fontSize={14}>
                                                    {this.state.activityData?.items[0]?.title}{" "}
                                                    <RegularText fontSize={12} color={colors.grey}>
                                                        {" ("}
                                                        {trimHelper(
                                                            this.state.activityData?.items[0]?.data?.redeemableOption?.type?.toUpperCase(),
                                                            12
                                                        )}
                                                        {") "}
                                                    </RegularText>
                                                </SemiBoldText>
                                                {this.state.activityData?.items[1] ? (
                                                    <RegularText fontSize={14}> {t("and_others")}</RegularText>
                                                ) : (
                                                    <RegularText fontSize={14}>
                                                        {" "}
                                                        {this.state.activityData?.amount > 0
                                                            ? t("to").toLowerCase()
                                                            : t("from").toLowerCase()}{" "}
                                                        <SemiBoldText fontSize={14}>
                                                            {trimHelper(
                                                                this.state?.activityData?.items[0]?.data?.merchant
                                                                    ?.name,
                                                                10
                                                            )}{" "}
                                                        </SemiBoldText>
                                                    </RegularText>
                                                )}
                                                {!(this.state.activityData?.amount > 0) && (
                                                    <View style={styles.bubble}>
                                                        <SemiBoldText
                                                            align="center"
                                                            fontSize={10}
                                                            color={colors.labelText || colors.white}
                                                            style={{ textTransform: "uppercase" }}
                                                        >
                                                            {t("free")}
                                                        </SemiBoldText>
                                                    </View>
                                                )}
                                                <View style={{ width: 25, height: 15 }}>
                                                    <View style={{ position: "absolute", top: -3, left: 5 }}>
                                                        <LottieBase
                                                            height={20}
                                                            width={20}
                                                            loop={
                                                                moment().diff(
                                                                    moment(this.props.activity?.createdAt),
                                                                    "minutes"
                                                                ) < 10
                                                            }
                                                            source={require("../../../assets/animations/car-purchese.json")}
                                                        />
                                                    </View>
                                                </View>
                                                <RegularText fontSize={11} color={colors.grey}>
                                                    {"  ("}
                                                    {moment(this.props.activity?.createdAt).fromNow()}
                                                    {")"}
                                                </RegularText>
                                            </RegularText>
                                        </View>
                                    }
                                    right={
                                        <View style={{ alignItems: "flex-end", marginTop: -4 }}>
                                            {this.state.activityData?.amount > 0 ? (
                                                <View>
                                                    {this.state.activityData?.currency?.fiatCurrencyId && (
                                                        <CurrencyBalanceIcon
                                                            fontSize={15}
                                                            iconSize={16}
                                                            trimLength={5}
                                                            amount={this.state.activityData?.amount}
                                                            currency={Wallet.getInstance().findFiatCurrencyById(
                                                                this.state.activityData?.currency?.fiatCurrencyId || ""
                                                            )}
                                                        />
                                                    )}
                                                    {this.state.activityData?.currency?.digitalCurrencyId && (
                                                        <CurrencyBalanceIcon
                                                            fontSize={15}
                                                            iconSize={16}
                                                            trimLength={5}
                                                            amount={this.state.activityData?.amount}
                                                            currency={Wallet.getInstance().findCurrencyById(
                                                                this.state.activityData?.currency?.digitalCurrencyId ||
                                                                    ""
                                                            )}
                                                        />
                                                    )}
                                                    <SemiBoldText
                                                        style={{
                                                            marginTop: 6,
                                                        }}
                                                        fontSize={9}
                                                        color={colors.grey}
                                                    >
                                                        {trimHelper(this.state.activityData?.gateway?.name || "", 18)}
                                                    </SemiBoldText>
                                                </View>
                                            ) : (
                                                <View></View>
                                            )}
                                        </View>
                                    }
                                ></Card>
                            </>
                        )}
                    </>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    secondaryAvatar: {
        position: "absolute",
        bottom: -2,
        right: -4,
        padding: 2,
        borderRadius: 20,
        backgroundColor: colors.gradientFrom,
        zIndex: 99,
    },
    bubble: {
        backgroundColor: colors.complementary || colors.secondary,
        paddingHorizontal: 5,
        paddingVertical: 1,
        borderRadius: 4,
        // marginRight: 10,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ActivityCardOrderPurchase = connect(mapStateToProps, mapDispatchToProps)(_ActivityCardOrderPurchase);

export default ActivityCardOrderPurchase;
