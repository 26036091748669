import React, { Component } from "react";
import { View, Linking, Platform } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import RegularText from "@base/RegularText";
import { HeaderType } from "@custom-types/HeaderType";
import SimpleCard from "@components/cards/SimpleCard";
import { colors } from "@styles/globalStyles";
import config from "../../../app.json";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { consoleLog } from "@utils/helpers/global/global";
import Card from "@base/Card";
import * as WebBrowser from "expo-web-browser";
import Icon from "@components/icons";
import { SettingsNavigatorScreens } from "@navigation/SettingsNavigator";
import LatamXO from "@assets/images/LatamXO";
import Constants from "expo-constants";
import { CustomsSettingsSponsor } from "@custom/CustomsSettingsSponsor";

const { expo } = config;

interface Props {
    navigation: any;
}

interface State {}

const { t } = i18n;

export default class SettingsScren extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    openLatamXO() {
        WebBrowser.openBrowserAsync("https://latamxo.com/", { showInRecents: true });
    }

    render() {
        return (
            <ScreenWrapper type="scroll">
                <Header title={t("settings")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    {Platform.OS !== "web" && (
                        <SimpleCard
                            title={t("security")}
                            icon="shield-lock"
                            onPress={() => {
                                this.props.navigation.navigate(SettingsNavigatorScreens.Security.routeName);
                            }}
                        />
                    )}
                    <SimpleCard
                        title={t("accounts")}
                        icon="person-profile"
                        onPress={() => {
                            this.props.navigation.navigate(SettingsNavigatorScreens.Seed.routeName);
                        }}
                    />

                    <SimpleCard
                        title={t("language")}
                        icon="signpost-split"
                        onPress={() => this.props.navigation.navigate(SettingsNavigatorScreens.Language.routeName)}
                    />

                    <SimpleCard
                        title={t("support")}
                        icon="life-preserver"
                        onPress={() => {
                            Linking.openURL(Constants.expoConfig?.extra?.support || "mailto:support@beexo.com");
                        }}
                    />
                    <SimpleCard title={t("version")} version={expo.version} onPress={() => consoleLog(expo.version)} />

                    <Card style={{ justifyContent: "space-between", flexDirection: "row" }} onPress={this.openLatamXO}>
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                height: 30,
                            }}
                        >
                            <View>
                                <RegularText>By</RegularText>
                            </View>
                            <View
                                style={{
                                    transform: [{ scale: 0.4 }],
                                    alignItems: "flex-start",
                                    height: 70,
                                    marginTop: 10,
                                    marginLeft: -65,
                                }}
                            >
                                <LatamXO />
                            </View>
                        </View>
                        <View>
                            <Icon name="award" size={25} color={colors.text} align="right" />
                        </View>
                    </Card>
                    <CustomsSettingsSponsor />
                </Container>
            </ScreenWrapper>
        );
    }
}
