import { NavigationType } from "@custom-types/NavigationType";
import React, { Component } from "react";
import { FlatList, RefreshControl, View } from "react-native";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import i18n from "@i18n/i18n";
import NFTSmallCard from "@screens/nfts/components/NFTSmallCard";
import NFT from "@custom-types/NFTModel";
import Container from "@base/Container";
import { colors } from "@styles/globalStyles";
import NFTService from "@core/services/NFTService";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import { ChatService } from "@core/services/ChatService";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";
import store from "@store/index";
import { setCustomMessage } from "@store/actions/chat.actions";
import Wallet from "@core/wallet/Wallet";
import Currency from "@core/currencies/Currency";
import BotCard from "@base/BotCard";
import { loading, ready, showPopup } from "@store/actions/global";
import { CustomMessageType } from "@custom-types/CustomMessageType";
import { Chat } from "@custom-types/Chat";

interface Props {
    navigation: NavigationType;
    client: Client;
    customMessage: CustomMessageModel;
    selectedChat: Chat;
}

interface State {
    nfts: Array<NFT>;
    syncing: boolean;
}

const { t } = i18n;

export class _SelectNftActionsScreen extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.getNFTs = this.getNFTs.bind(this);
        this.onPressNFTCard = this.onPressNFTCard.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.getTransferData = this.getTransferData.bind(this);
        this.state = {
            nfts: [],
            syncing: false,
        };
    }

    componentDidMount() {
        this.getNFTs();
    }

    async getNFTs() {
        store.dispatch(loading());
        this.setState({ syncing: true });
        const nfts = await NFTService.getInstance().getUserNFTs(this.props.client?._id);

        if (nfts) {
            const sorted_nfts: Array<NFT> = nfts.sort((a: any, b: any) =>
                new Date(a.updatedAt) < new Date(b.updatedAt) ? 1 : -1,
            );
            if (this.props.customMessage.type == CustomMessageType.NFT) {
                const filtered_nfts = sorted_nfts.filter((n) => {
                    return !n.isDraft;
                });
                this.setState({ syncing: false, nfts: filtered_nfts });
            }

            if (this.props.customMessage.type == CustomMessageType.SendNFT) {
                const filtered_nfts = sorted_nfts.filter((n) => {
                    return !n.isListed && !n.isDraft;
                });
                this.setState({ syncing: false, nfts: filtered_nfts });
            }
        }
        store.dispatch(ready());
    }

    renderItem = ({ item }) => <NFTSmallCard isOwner={true} nft={item} onPress={() => this.onPressNFTCard(item)} />;

    async onPressNFTCard(item: NFT) {
        if (this.props.customMessage.type == CustomMessageType.NFT) {
            try {
                this.setNFTMessage(item);
                await this.sendMessage();
                this.props.navigation.navigate(MessagesNavigatorScreens.Chat.routeName);
            } catch (error) {
                console.warn(error);
            }
        }

        if (this.props.customMessage.type == CustomMessageType.SendNFT) {
            this.setNFTMessage(item);
            this.getTransferData(item);
        }
    }

    async getTransferData(nft: NFT) {
        store.dispatch(loading());
        const nftData: NFT = await NFTService.getInstance().getNFT(nft._id, nft.blockchain, true);
        if (!nftData.isListed && !nftData.isDraft) {
            const currency = Wallet.getInstance().findCurrencyByBlockchain(nftData.blockchain);
            const addressFrom = nft.owner.addresses[nft.network][currency.getUnderlyingCurrencyID().toLowerCase()];

            const addressTo =
                this.props.selectedChat.to.addresses[nft.network][currency.getUnderlyingCurrencyID().toLowerCase()];

            var skeleton = await NFTService.getInstance().transferNFT(nft, addressFrom, addressTo);

            if (skeleton) {
                skeleton.addressTo = addressTo;
                this.props.navigation.navigate(MessagesNavigatorScreens.ConfirmTransferNFTScreen.routeName, {
                    currency: currency.getId(),
                    nft: nft,
                    skeleton: skeleton,
                });
            }
            store.dispatch(ready());
        } else {
            store.dispatch(ready());
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("error_transfer_nft"),
                }),
            );
        }
    }

    async setNFTMessage(nft: NFT) {
        const currency: Currency = Wallet.getInstance().findCurrencyByBlockchain(nft?.blockchain);
        const customMessage: CustomMessageModel = this.props.customMessage;
        customMessage.network = Wallet.getInstance().getNetworkType();
        customMessage.currencyId = currency.getId();
        customMessage.currencyName = currency.getName();
        customMessage.address = currency.getAddress();
        customMessage.nft = {
            _id: nft._id,
            tokenId: nft.tokenId,
            contractAddress: nft.contractAddress,
            uri: nft.uri,
            image: nft.image,
            name: nft.name,
            description: nft.description || "",
        };
        store.dispatch(setCustomMessage(customMessage));
    }

    async sendMessage() {
        const service: ChatService = await ChatService.getInstance();
        await service.sendCustomMessage();
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("select_nft")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1, paddingHorizontal: 5 }}>
                    <FlatList
                        scrollEnabled={true}
                        showsHorizontalScrollIndicator={false}
                        data={this.state.nfts}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item.id || item._id}
                        contentContainerStyle={{ paddingBottom: 20 }}
                        numColumns={2}
                        columnWrapperStyle={{ justifyContent: "space-between", flex: 1 }}
                        initialNumToRender={2}
                        maxToRenderPerBatch={10}
                        onEndReachedThreshold={1}
                        ListEmptyComponent={
                            !this.state.syncing && (
                                <BotCard
                                    title="Ops!"
                                    message={
                                        (this.props.customMessage.type == CustomMessageType.NFT &&
                                            t("bot_select_nft_empty")) ||
                                        (this.props.customMessage.type == CustomMessageType.SendNFT &&
                                            t("bot_select_send_nft_empty")) ||
                                        ""
                                    }
                                ></BotCard>
                            )
                        }
                        ListHeaderComponent={
                            this.state.nfts?.length && (
                                <BotCard
                                    message={
                                        (this.props.customMessage.type == CustomMessageType.NFT &&
                                            t("bot_select_nft")) ||
                                        (this.props.customMessage.type == CustomMessageType.SendNFT &&
                                            t("bot_select_send_nft")) ||
                                        ""
                                    }
                                ></BotCard>
                            )
                        }
                        refreshControl={
                            <RefreshControl
                                tintColor={colors.text}
                                onRefresh={this.getNFTs}
                                refreshing={this.state.syncing}
                            />
                        }
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        customMessage: state.chat.customMessage,
        selectedChat: state.chat.selected,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SelectNftActionsScreen = connect(mapStateToProps, mapDispatchToProps)(_SelectNftActionsScreen);

export default SelectNftActionsScreen;
