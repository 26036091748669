import React, { Component } from "react";
import i18n from "@i18n/i18n";
import RegularText from "@base/RegularText";
import { StyleSheet, View } from "react-native";
import BoldText from "@base/BoldText";
import Logo from "@assets/images/Logo";
import Constants from "expo-constants";
import { importer } from "./helpers/importer";

interface Props { }

interface State { }

const { t } = i18n;

class DefaultCustomWelcomeContent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <Logo style={{ marginTop: 50, marginBottom: 25 }} width={180} height={180} />
                <BoldText fontSize={22} align="center">
                    {t("welcome_to")} {Constants.expoConfig?.name}
                    {"!"}
                </BoldText>
                <RegularText fontSize={16}>{t("a_portal")}</RegularText>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
    },
    wrapper: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
});

const CustomWelcomeContent = importer(DefaultCustomWelcomeContent, "CustomWelcomeContent");

export { CustomWelcomeContent };