

export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_FIAT_CURRENCY = 'SET_FIAT_CURRENCY';

export const setCountry = (country: string) => {
    return {type: SET_COUNTRY, country}
}

export const setFiatCurrency = (currency: string) => {
    return {type: SET_FIAT_CURRENCY, currency}
}