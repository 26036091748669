import newNFTScreen from "@screens/nfts/newNFTScreen";
import NFTScreen from "@screens/nfts/NFTScreen";
import NFTsMainScreen from "@screens/nfts/NFTsMainScreen";
import NavigationInstantiator from "./helpers/NavigationInstantiator";
import ConfirmMintScreen from "@screens/nfts/ConfirmMintScreen";
import ApproveNFTScreen from "@screens/nfts/ApproveNFTScreen";
import OfferForSaleScreen from "@screens/nfts/OfferForSaleScreen";
import ConfirmSaleScreen from "@screens/nfts/ConfirmSaleScreen";
import ConfirmBuyScreen from "@screens/nfts/ConfirmBuyScreen";
import HistoryNFTScreen from "@screens/nfts/HistoryNFTScreen";
import { ProfileNavigator } from "./ProfileNavigator";
import { NTFNavigator } from "./NFTNavigator";

const NTFsNavigatorScreens = {
    NFTsMarketplace: {
        routeName: "NFTsMarketplace",
        title: "NFT Marketplace",
        component: NFTsMainScreen,
    },
    Profile: {
        routeName: "Profile",
        title: "Profile",
        component: ProfileNavigator,
    },

    historyNFT: {
        routeName: "historyNFT",
        title: "NFTs History",
        component: HistoryNFTScreen,
    },
    NFT: {
        routeName: "NFT",
        title: "NFT",
        component: NTFNavigator,
    },
};

const NTFsNavigator = NavigationInstantiator(NTFsNavigatorScreens);

export { NTFsNavigator, NTFsNavigatorScreens };
