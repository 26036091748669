import {BaseMessageState} from "@utils/helpers/chat/message-state/base.message.state";
import {MessageAddType, Participant} from "@utils/helpers/chat/chat.helper";
import {Message} from "@custom-types/Message";

export class Sender extends BaseMessageState {
    participant = Participant.SENDER;
    state: number;


    processNewMessage() {
        this.saveStorage();
    }

    saveEarlier() {
        this.message.saveStorage(MessageAddType.end);
    }

    socketProcess() {
        this.message.decrypt();
        this.updateStorage();
    }

    saveAndSend() {
        if (this.getStateNumber() === Message.PENDING_STATE) {
            this.saveStorage(MessageAddType.start, false);
            this.saveServer();
        }
    }

}