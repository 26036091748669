import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { RedeemableModel, RedeemableType, ExperiencesRedux } from "@custom-types/ExpercienceModel";
import { Route } from "@react-navigation/native";
import { FlatList, View, StyleSheet, Platform, RefreshControl } from "react-native";
import RedeemableWideCard from "./components/RedeemableWideCard";
import ExperienceService from "@core/services/ExperienceService";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import BotCard from "@base/BotCard";
import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import store from "@store/index";
import { loading, ready } from "@store/actions/global";

interface Props {
    navigation: NavigationType;
    route: Route<string, { sectionType?: RedeemableType }>;
    client: Client;
    experiences: ExperiencesRedux;
}

interface State {
    type?: RedeemableType;
    redeemables: Array<RedeemableModel>;
}

const { t } = i18n;

export class _SectionRedeemableScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.navigateToRedeemable = this.navigateToRedeemable.bind(this);
        this.state = {
            type: this.props.route?.params?.sectionType || RedeemableType.TICKET,

            redeemables: [],
        };
    }

    navigateToRedeemable(redeemable: RedeemableModel) {
        this.props.navigation.navigate(ExperienceNavigatorScreens.Redeemable.routeName, {
            redeemable: redeemable,
        });
    }

    renderItem = ({ item }) => {
        return (
            <RedeemableWideCard
                onPress={this.navigateToRedeemable}
                redeemable={item}
                navigation={this.props.navigation}
            />
        );
    };

    async loadMore() {
        await ExperienceService.getInstance().getRedeemables(this.state.type, true);
    }

    setSearch = (type: RedeemableType) => {
        this.setState({ type: type });
    };

    async onRefresh() {
        store.dispatch(loading());
        await ExperienceService.getInstance().getRedeemables();
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={t(this.state.type)}
                    type={HeaderType.Light}
                    rightBtn={
                        Platform.OS == "web" && {
                            onPress: () => {
                                this.onRefresh();
                            },
                            icon: "refresh",
                            size: 22,
                        }
                    }
                    {...this.props}
                />

                <Container style={{ flex: 1 }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-around", marginVertical: 10 }}>
                        <PressableBase
                            onPress={() => this.setSearch(RedeemableType.TICKET)}
                            accessibilityLabel="users-botton"
                        >
                            <RegularText
                                fontSize={16}
                                style={this.state.type == RedeemableType.TICKET ? styles.select : null}
                            >
                                {t("ticket")}
                            </RegularText>
                        </PressableBase>
                        <PressableBase
                            onPress={() => this.setSearch(RedeemableType.VOUCHER)}
                            accessibilityLabel="posts-botton"
                        >
                            <RegularText style={this.state.type == RedeemableType.VOUCHER ? styles.select : null}>
                                {t("voucher")}
                            </RegularText>
                        </PressableBase>
                    </View>
                    <FlatList
                        data={this.props.experiences[this.state.type].docs}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item._id}
                        contentContainerStyle={{ paddingTop: 10, zIndex: 99 }}
                        initialNumToRender={6}
                        maxToRenderPerBatch={6}
                        showsHorizontalScrollIndicator={false}
                        onEndReachedThreshold={1}
                        onEndReached={this.loadMore}
                        refreshControl={
                            <RefreshControl onRefresh={this.onRefresh} tintColor={colors.text} refreshing={false} />
                        }
                        ListEmptyComponent={<BotCard message={t("empty_tickets")} />}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    select: {
        color: colors.secondary,
        borderBottomColor: colors.secondary,
        fontFamily: "NunitoBold",
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        experiences: state.experiences,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SectionRedeemableScreen = connect(mapStateToProps, mapDispatchToProps)(_SectionRedeemableScreen);

export default SectionRedeemableScreen;
