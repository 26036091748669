import { Fonts } from "@assets/fonts/Fonts";
import { FontAwesome } from "@expo/vector-icons";
import * as Font from "expo-font";

import { useEffect, useState } from "react";
const icomoon = require("@assets/fonts/icomoon.ttf");

export default function useCachedResources() {
    const [isLoadingComplete, setLoadingComplete] = useState(false);

    // Load any resources or data that we need prior to rendering the app
    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            try {
                // Load fonts
                await Font.loadAsync({
                    NunitoRegular: Fonts.regular,
                    NunitoBold: Fonts.bold,
                    NunitoLight: Fonts.light,
                    NunitoSemiBold: Fonts.semiBold,
                    ...(Fonts.extra || {}),
                    IcoMoon: icomoon,
                });
            } catch (e) {
                // We might want to provide this error information to an error reporting service
                console.warn(e);
            } finally {
                setLoadingComplete(true);
            }
        }

        loadResourcesAndDataAsync();
    }, []);

    return isLoadingComplete;
}
