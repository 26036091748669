import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { colors } from "@styles/globalStyles";
import Icon from "@components/icons";
import Badge from "@base/Badge";
import PressableBase from "@base/PressableBase";

interface Props {
    title?: string;
    icon: string;
    iconSize?: number;
    isCurrent?: boolean;
    screen?: string;
    showSidebar?: boolean;
    navigation: any;
    unreadMessages?: number;
    state?: any;
    route?: any;
    onPress?: () => void;
    vibrate?: boolean;
}

export class FooterButton extends Component<Props> {
    switch = async (screen: string) => {
        const current = this.isCurrent();

        if (current) {
            try {
                let state = this.props.state;
                let route;
                while (state && state.routes) {
                    const cRoute = state.routes[state.index];
                    if (cRoute.params?.backTo) {
                        route = cRoute.params.backTo;
                        state = null;
                    } else {
                        if (state.index > 0 && state.type != "tab") route = state.routes[state.index - 1].name;
                        state = state.routes[state.index].state;
                    }
                }
                if (route) {
                    this.props.navigation.navigate(route);
                }
            } catch (e) {
                console.warn(e);
            }
        }

        if (!current) {
            this.props.navigation.navigate(screen);
        }
    };

    isCurrent: () => boolean = () => {
        return (
            this.props.isCurrent ||
            (this.props.screen == this.props.state.routes[this.props.state.index].name && !this.props.showSidebar)
        );
    };

    render() {
        return (
            <PressableBase
                onPress={() => (this.props.onPress ? this.props.onPress() : this.switch(this.props.screen))}
                style={{ alignSelf: "center" }}
                vibrate={this.props.vibrate}
            >
                <View style={{ paddingHorizontal: 10, alignItems: "center" }}>
                    <Icon
                        name={this.props.icon}
                        size={this.props.iconSize || 28}
                        color={this.isCurrent() ? colors.text : colors.grey}
                        align="center"
                    />
                    <Badge
                        size={18}
                        count={this.props.unreadMessages}
                        color={colors.complementary || colors.secondary}
                        containerSyle={{ bottom: 12, right: -5 }}
                        smallBadgeContainer={{ right: 5 }}
                        badgeStyle={{ borderColor: colors.tertiary }}
                        smallBadgeStyle={{ borderColor: colors.tertiary }}
                    />
                </View>
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({
    button: {
        flex: 1,
        alignContent: "center",
        paddingVertical: 15,
        alignItems: "center",
    },
    current: {
        color: colors.activeFooter,
    },
});
