import React, { Component } from "react";
import { Linking, ScrollView, StyleSheet, View } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import InlineButton from "@base/InlineButton";
import RegularText from "@base/RegularText";
import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator";
import Row from "@base/Row";
import Card from "@base/Card";
import BoldText from "@base/BoldText";
import { Route } from "@react-navigation/native";
import { CoincaexBuy, equateCurrenciesFromCoincaex } from "../../helper/coincaex.helper";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";

import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { colors } from "@styles/globalStyles";
import BotCard from "@base/BotCard";
import Constants from "expo-constants";
import { CoincaexService } from "@core/services/CoincaexService";
import CurrencyIcon from "@components/accessories/CurrencyIcon";

interface Props {
    navigation: NavigationType;
    route: Route<string, { data: CoincaexBuy; confirmPurchase?: boolean }>;
}

interface State {
    data: CoincaexBuy;
    currency: Currency | null;
    fiatCurrency: string;
    successTransaction: boolean;
    count: number;
}

const { t } = i18n;

export class _CoincaexBuyDetailScreen extends Component<Props, State> {
    focusListener: any;
    interval: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            data: this.props.route?.params?.data,
            currency: equateCurrenciesFromCoincaex(
                this.props.route?.params?.data?.crypto,
                this.props.route?.params?.data?.network
            ),
            fiatCurrency: this.props.route?.params?.data?.bankAccountCurrency || "",
            successTransaction: true,
            count: 5,
        };
    }

    async componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", this.onFocus);
    }

    componentWillUnmount() {
        try {
            this.focusListener.remove();
            clearInterval(this.interval);
        } catch (e) {}
    }

    onFocus = async () => {
        if (this.props.route.params?.confirmPurchase) {
            const resp = await CoincaexService.getInstance().confirmBuy(this.state.data);

            if (resp) {
                this.interval = setInterval(() => {
                    this.setState(
                        (prevState) => ({ count: prevState.count - 1 }),
                        () => {
                            if (this.state.count === 0) {
                                clearInterval(this.interval);
                                this.props.navigation.navigate(CoincaexNavigatorScreens.CoincaexHome.routeName, {
                                    reload: true,
                                });
                            }
                        }
                    );
                }, 1000);
            } else {
                this.setState({ successTransaction: false });
            }
        }
    };

    openChat = async () => {
        Linking.openURL(Constants.expoConfig?.extra?.support || "mailto:support@beexo.com");
        this.props.navigation.navigate(CoincaexNavigatorScreens.CoincaexHome.routeName);
    };

    render() {
        return (
            <ScreenWrapper>
                <Header hideReturn={true} title={t("buy")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1, justifyContent: "space-between" }}>
                    <ScrollView>
                        <View style={{ flex: 1 }}>
                            {this.state.successTransaction ? (
                                <BotCard message={t("transaction_message")} />
                            ) : (
                                <BotCard message={t("error_coincaex")} onPress={this.openChat} />
                            )}
                            <Card style={{ alignItems: "flex-start", marginVertical: 20 }}>
                                <BoldText fontSize={16} style={{ marginBottom: 20 }}>
                                    {t("transaction_detail")}
                                </BoldText>
                                <RegularText fontSize={14} color={colors.grey} style={{ marginBottom: 10 }}>
                                    {t("buys")} {this.state.currency.getPName()}:
                                </RegularText>
                                <Row style={styles.rowContainer}>
                                    <Row style={{ alignItems: "center" }}>
                                        <CurrencyIcon styles={{ marginRight: 15 }} currency={this.state.currency} />

                                        <BoldText>{this.state.currency.getPName()}</BoldText>
                                        <NetworkCurrencySymbol currency={this.state.currency}></NetworkCurrencySymbol>
                                    </Row>
                                    <BoldText fontSize={18}>{this.state.data?.amount}</BoldText>
                                </Row>
                                <RegularText fontSize={14} color={colors.grey} style={{ marginBottom: 10 }}>
                                    {t("amount")}:
                                </RegularText>
                                <Row style={styles.rowContainer}>
                                    <Row style={{ alignItems: "center" }}>
                                        <View style={{}}>
                                            <BoldText>{this.state.fiatCurrency}</BoldText>
                                        </View>
                                    </Row>
                                    <BoldText fontSize={18}>
                                        {"$"}
                                        {this.state.data?.transactionDepositTotal}
                                    </BoldText>
                                </Row>
                                <View style={{ marginBottom: 20 }}>
                                    <RegularText fontSize={14} color={colors.grey} style={{ marginBottom: 10 }}>
                                        {t("address")}:
                                    </RegularText>
                                    <BoldText>{trimHelper(this.state.data.wallet, 30, true)}</BoldText>
                                </View>
                                <View>
                                    <RegularText fontSize={14} color={colors.grey} style={{ marginBottom: 10 }}>
                                        {t("bank")}:
                                    </RegularText>
                                    <RegularText style={{ marginBottom: 5 }}>
                                        {t("name_bank")}: {this.state.data.bankName}
                                    </RegularText>
                                    <RegularText>
                                        {t("account_number")}: {trimHelper(this.state.data.bankAccountNumber, 30, true)}
                                    </RegularText>
                                </View>
                            </Card>
                        </View>
                    </ScrollView>
                    {this.state.successTransaction ? (
                        <InlineButton
                            style={{ marginVertical: 10 }}
                            title={`${t("redirect_coincaex")}... ${this.state.count}`}
                            onPress={() => console.warn("redirect_coincaex")}
                        />
                    ) : (
                        <InlineButton
                            style={{ marginVertical: 10 }}
                            title={t("support")}
                            onPress={() => this.openChat()}
                        />
                    )}
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    rowContainer: {
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        marginBottom: 20,
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const CoincaexBuyDetailScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexBuyDetailScreen);

export default CoincaexBuyDetailScreen;
