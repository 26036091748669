import Container from "@base/Container";
import SimpleCard from "@components/cards/SimpleCard";
import { Header } from "@components/header/Header";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { OAuthService } from "@core/services/oauth/OAuthService";
import { HeaderType } from "@custom-types/HeaderType";
import i18n from "@i18n/i18n";
import { SeedNavigatorScreens } from "@navigation/SeedNavigator";
import Initializer from "@screens/initializer/Initializer";
import React, { Component } from "react";
import { Route } from "@react-navigation/native";
import { View } from "react-native";

interface Props {
    navigation: any;
    route: Route<string, { restrictedOptions: boolean }>;
}

interface State {}

const { t } = i18n;

export default class SeedScreen extends Component<Props, State> {
    focusListener: any;
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("accounts")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    {/* <SimpleCard
                        title={t("import")}
                        icon="paper-clip"
                        onPress={() => {
                            this.props.navigation.navigate(SeedNavigatorScreens.Import.routeName);
                        }}
                    /> */}
                    <SimpleCard
                        title={t("seed_phrase")}
                        icon="key"
                        onPress={() => {
                            this.props.navigation.navigate(SeedNavigatorScreens.Backup.routeName);
                        }}
                    />
                    {/* <SimpleCard
                        title={t("new_seed")}
                        icon="exchange"
                        onPress={() => {
                            this.props.navigation.navigate(SeedNavigatorScreens.ResetSeed.routeName);
                        }}
                    /> */}
                    <SimpleCard
                        title={t("history")}
                        icon="clock-history"
                        onPress={() => {
                            this.props.navigation.navigate(SeedNavigatorScreens.HistorySeed.routeName);
                        }}
                    />
                    {!this.props.route?.params?.restrictedOptions && (
                        <View>
                            <SimpleCard
                                title={t("remove_account")}
                                icon="person-x"
                                onPress={() => {
                                    this.props.navigation.navigate(SeedNavigatorScreens.RemoveSeed.routeName);
                                }}
                            />

                            <SimpleCard
                                title={t("restore_app")}
                                icon="trash"
                                onPress={() => {
                                    this.props.navigation.navigate(SeedNavigatorScreens.RestoreApp.routeName);
                                }}
                            />

                            <SimpleCard
                                title={t("signout")}
                                icon="log-out"
                                onPress={async () => {
                                    await OAuthService.getInstance().signout();
                                    Initializer.redirect(this.props.navigation);
                                }}
                            />
                        </View>
                    )}
                </Container>
            </ScreenWrapper>
        );
    }
}
