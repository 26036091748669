import NFT from "@custom-types/NFTModel";

export const SET_SELECTED_NFT = "SET_SELECTED_NFT";
export const SET_PRE_TEMPORAL_NFT = "SET_PRE_TEMPORAL_NFT";
export const SET_TEMPORAL_NFTS = "SET_TEMPORAL_NFTS";
export const SET_REQUEST_STATUS_NFTS = "SET_REQUEST_STATUS_NFTS";

export const setSelectedNFT = (nft: NFT) => {
    return { type: SET_SELECTED_NFT, selectedNFT: nft };
};

export const setPreTemporalNFT = (nft: NFT) => {
    return { type: SET_PRE_TEMPORAL_NFT, preTemporalNFT: nft };
};

export const setTemporalNFTs = (nfts: Array<NFT>) => {
    return { type: SET_TEMPORAL_NFTS, temporalNfts: nfts };
};

export const setRequestStatusNFTs = (statusMarketPlace: { status?: string; nft?: NFT }) => {
    return { type: SET_REQUEST_STATUS_NFTS, statusMarketPlace: statusMarketPlace };
};
