import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "@store/index";
import Loader from "./Loader";
import { AuthNavigator } from "../../navigation/AuthNavigator";
import Popup from "@components/popup/Popup";
import AnimatedPopUp from "@components/popup/Modal";
import WebSplash from "@components/splash/WebSplash";
import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import { colors } from "@styles/globalStyles";
import { SafeAreaInsetsContext, SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { LinearGradient } from "expo-linear-gradient";
import { Platform, View } from "react-native";
import Authentication from "@screens/auth/Authentication";
import Snackbar from "@components/popup/Snackbar";
import ModalBottom from "@components/popup/ModalBottom";
import PopupMessage from "@components/popup/PopupMessage";
import MaintenanceMode from "@components/maintenance/MaintenanceMode";

const CustomTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: colors.gradientTo,
    },
};

try {
    require("./global.css");
} catch {}

interface State {}

interface Props {}

export default class Init extends Component<Props, State> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Provider store={store}>
                <SafeAreaProvider>
                    <NavigationContainer theme={CustomTheme}>
                        <LinearGradient colors={[colors.gradientFrom, colors.gradientTo]} style={{ flex: 1 }}>
                            <SafeAreaView style={{ flex: 1 }}>
                                <AuthNavigator />
                            </SafeAreaView>
                        </LinearGradient>
                    </NavigationContainer>

                    {/* EXTRA COMPONENTS */}
                    <MaintenanceMode />
                    {/* <ForceUpdateMode /> */}
                    <Loader />
                    <ModalBottom />
                    <Popup />
                    <AnimatedPopUp />
                    <PopupMessage />
                    <Snackbar />
                    <Authentication />
                    <WebSplash />

                    {/* IOS CONFIG */}
                    {Platform.OS == "ios" && (
                        <SafeAreaInsetsContext.Consumer>
                            {(insets) => (
                                <View
                                    style={{
                                        backgroundColor: colors.tertiary,
                                        height: insets.bottom,
                                        position: "absolute",
                                        bottom: 0,
                                        width: "100%",
                                    }}
                                ></View>
                            )}
                        </SafeAreaInsetsContext.Consumer>
                    )}
                </SafeAreaProvider>
            </Provider>
        );
    }
}
