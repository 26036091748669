import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator";
import { _SendScreen } from "@screens/wallet/send/SendScreen";
import { connect } from "react-redux";

export class _CoincaexSendCryptoScreen extends _SendScreen {
    constructor(props: any) {
        super(props);
        this.setTitle("Send");
    }

    generateOnSucces = () => {
        this.props.navigation.navigate(CoincaexNavigatorScreens.ConfirmSend.routeName, {
            data: this.props.route?.params?.data || "",
        });
        return true;
    };
}

const mapStateToProps = (state) => {
    return {
        ...state.wallet,
        // customMessage: state.chat.customMessage,
        onSuccessNavigate: CoincaexNavigatorScreens.ConfirmSend.routeName,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const CoincaexSendCryptoScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexSendCryptoScreen);

export default CoincaexSendCryptoScreen;
