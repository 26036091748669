import * as AuthAux from "@core/utils/AuthAux";
import AuthStorage from "@utils/storage/storages/AuthStorage";
import { getEnv } from "@utils/helpers/global/global";
import { Platform } from "react-native";
import store from "@store/index";
import { v4 as uuidv4 } from 'uuid';
import Wallet from "@core/wallet/Wallet";

export class AuthService {
    private static instance: AuthService;

    private readonly chatEcKey: any;
    private sharedChatKeys = {};

    constructor() {
        this.chatEcKey = AuthAux.getEcKeyFromSeed(AuthAux.getChatSeed());
    }

    public static async getInstance(): Promise<AuthService> {
        if (!AuthService.instance) {
            AuthService.instance = new AuthService();
        }

        return AuthService.instance;
    }

    public static getSharedKeyFromExternalPublicKey(keyChat) {
        try {
            const instance: AuthService = AuthService.instance;
            if (!instance.sharedChatKeys[keyChat]) {
                instance.sharedChatKeys[keyChat] = AuthAux.generateSharedKeyFromEcKeyAndExternalPubKey(
                    instance.chatEcKey,
                    keyChat,
                );
            }

            return instance.sharedChatKeys[keyChat];
        } catch (e) {
            console.warn(e);
        }
    }

    public static async restartInstance(): Promise<AuthService> {
        await AuthStorage.remove();
        this.instance = null;
        return AuthService.getInstance();
    }
}
