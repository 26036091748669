import React, { Component, RefObject } from "react";
import { View, StyleSheet, ViewStyle, FlatList, ScrollView } from "react-native";
import * as Device from "expo-device";
import CircleButton from "./CircleButton";

interface Props {
    data: Array<any>;
    renderItem: (item) => JSX.Element;
    keyExtractor: (item) => JSX.Element;
    contentContainerStyle?: ViewStyle;
    columnWrapperStyle?: ViewStyle;
    initialNumToRender?: number;
    numColumns?: number;
    horizontal?: boolean;
    maxToRenderPerBatch?: number;
    showsHorizontalScrollIndicator?: boolean;
    ListEmptyComponent?: JSX.Element;
    ListHeaderComponent?: JSX.Element;
    refreshControl?: JSX.Element;
    onEndReachedThreshold?: number;
    onEndReached?: () => void;
    extraData?: any;
    onScrollToIndexFailed?: (info) => void;
    pagingEnabled?: boolean;
    onScroll?: (event: any) => void;
    scrollEventThrottle?: number;
    bannerIndexRight?: (index: number) => void;
    bannerIndexLeft?: (index: number) => void;
    autoScroll?: boolean;
}

interface State {
    device: string;
    index: number;
}

export default class CarouselBase extends Component<Props, State> {
    private flatListRef: RefObject<FlatList<any>>;
    private scrollInterval: NodeJS.Timeout | null;

    constructor(props: Props) {
        super(props);
        this.flatListRef = React.createRef();
        this.scrollInterval = null;
        this.state = {
            device: Device.DeviceType[1],
            index: 0,
        };
    }

    async componentDidMount() {
        try {
            const device = await Device.getDeviceTypeAsync();
            const devices = Device.DeviceType;
            this.setState({ device: devices[device] });
        } catch (e) { }

        if (this.props.autoScroll) {
            this.startAutoScroll();
        }
    }

    componentWillUnmount() {
        this.stopAutoScroll();
    }

    startAutoScroll() {
        this.scrollInterval = setInterval(() => {
            this.scrollRight();
        }, 4000);
    }

    stopAutoScroll() {
        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
            this.scrollInterval = null;
        }
    }

    scrollToIndex(data) {
        if (data.lenght > 1 && this.flatListRef) {
            this.flatListRef.current.scrollToIndex(data);
        }
    }

    scrollLeft = (fromButton: boolean) => {
        fromButton && this.stopAutoScroll()
        const newIndex = Math.max(0, this.state.index - 1);
        this.setState({ index: newIndex }, () => {
            this.flatListRef.current.scrollToIndex({ index: this.state.index, animated: true });
        });
        this.props.bannerIndexLeft(newIndex);
    };

    scrollRight = (fromButton?: boolean) => {
        fromButton && this.stopAutoScroll()
        const newIndex = (this.state.index + 1) % this.props.data.length;
        this.setState({ index: newIndex }, () => {
            this.flatListRef.current.scrollToIndex({ index: this.state.index, animated: true });
        });
        this.props.bannerIndexRight(newIndex);
    };

    handleUserInteraction = () => {
        this.stopAutoScroll();
    };

    render() {
        return (
            <>
                <View style={{ flex: 1, justifyContent: "center" }}>
                    {this.props.data?.length > 0 ? (
                        <FlatList
                            ref={this.flatListRef}
                            data={this.props.data || []}
                            renderItem={(item) => this.props.renderItem(item)}
                            keyExtractor={(item) => item._id}
                            numColumns={this.props.numColumns || 1}
                            contentContainerStyle={[this.props.contentContainerStyle, {}]}
                            initialNumToRender={this.props.initialNumToRender || 2}
                            horizontal={this.props.horizontal || false}
                            showsHorizontalScrollIndicator={this.props.showsHorizontalScrollIndicator || false}
                            onScrollToIndexFailed={this.props.onScrollToIndexFailed}
                            columnWrapperStyle={this.props.numColumns > 1 && this.props.columnWrapperStyle}
                            ListHeaderComponent={this.props.ListHeaderComponent}
                            onEndReachedThreshold={this.props.onEndReachedThreshold}
                            onEndReached={this.props.onEndReached}
                            extraData={this.props.extraData}
                            refreshControl={this.props.refreshControl}
                            pagingEnabled={this.props.pagingEnabled}
                            onScroll={this.props.onScroll}
                            scrollEventThrottle={this.props.scrollEventThrottle}
                            onTouchStart={this.handleUserInteraction}
                        />
                    ) : (
                        <ScrollView refreshControl={this.props.refreshControl} style={[this.props.contentContainerStyle]}>
                            {this.props.ListHeaderComponent}
                            {this.props.ListEmptyComponent}
                        </ScrollView>
                    )}

                    {this.state.index > 0 && this.state.device === Device.DeviceType[3] && this.props.horizontal && (
                        <View style={styles.arrowLeft}>
                            <CircleButton
                                style={{ width: 40, height: 40 }}
                                iconSize={20}
                                icon={"arrow-left"}
                                onPress={() => this.scrollLeft(true)}
                            />
                        </View>
                    )}
                    {this.props.data?.length > 0 &&
                        this.state.index < this.props.data?.length - 1 &&
                        this.state.device === Device.DeviceType[3] &&
                        this.props.horizontal && (
                            <View style={styles.arrowRight}>
                                <CircleButton
                                    style={{ width: 40, height: 40 }}
                                    iconSize={20}
                                    icon={"right"}
                                    onPress={() => this.scrollRight(true)}
                                />
                            </View>
                        )}
                </View>
            </>
        );
    }
}

const styles = StyleSheet.create({
    arrowLeft: {
        display: "flex",
        position: "absolute",
        zIndex: 99,
        left: -3,
        opacity: 0.8,
    },
    arrowRight: {
        display: "flex",
        position: "absolute",
        zIndex: 99,
        right: -3,
        opacity: 0.8,
    },
});



