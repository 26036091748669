import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { Header } from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import Container from "@base/Container";
import RegularText from "@base/RegularText";
;
import {ChatService} from "@core/services/ChatService";
import {NavigationType} from "@custom-types/NavigationType";
import {CustomMessageModel} from "@custom-types/CustomMessageModel";
import {connect} from "react-redux";
import Card from "@base/Card";
import BoldText from "@base/BoldText";
import { FloatButton } from "@base/FloatButton";
import debounce from "lodash.debounce";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n"
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";

interface Props {
    navigation: NavigationType;
    customMessage: CustomMessageModel;
    selectedChat: any;
}

interface State {}

const { t } = i18n

class _AddressScreen extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.send = debounce(this.send, 500);
  }

  async onPress() {
    try {
      await this.send();
      this.props.navigation.navigate(MessagesNavigatorScreens.Chat.routeName);
    } catch (error) {
      console.warn(error);
    }
  }

  async send() {
    const service: ChatService = await ChatService.getInstance();
    await service.sendCustomMessage();
  }

  render() {
    return (
      <ScreenWrapper>
        <Header
          title={`${t ('receive')} ${this.props.customMessage.currencyName}`}
          type={HeaderType.Light}
          {...this.props}
        />
        <Container style={styles.container}>

          <Card>
            <RegularText color={colors.text} align='center'>
              { t('your_address')}
            </RegularText>
            <BoldText color={colors.text} align='center' fixedWidth={250}>
              {this.props.customMessage.address}
            </BoldText>
          </Card>

          <FloatButton onPress={() => {this.onPress()}} iconName={"right"}></FloatButton>
         
        </Container>
      </ScreenWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customMessage: state.chat.customMessage,
    selectedChat: state.chat.selected
  };
};

const mapDispatchToProps = (dispatch) => ({});

const AddressScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(_AddressScreen);

export default AddressScreen;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: colors.background,
  },
  container: {
    flex: 1,
  },
  addressContainer: {
    backgroundColor: colors.shadow,
    padding: 10,
    borderRadius: settings.cardRadius,
  },
  button: {
    backgroundColor: colors.secondary,
    width: 55,
    height: 55,
    position: "absolute",
    right: 20,
    bottom: 20,
    borderRadius: 30,
    justifyContent: "center",
    marginBottom: 8,
    alignItems: "center",
  },
});
