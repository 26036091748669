import BigNumber from "bignumber.js";
import React, { Component } from "react";
import { StyleSheet, TextInput, TextStyle, KeyboardTypeOptions, Platform } from "react-native";
import RegularTextInput from "./RegularTextInput";

export default class RegularNumberInput extends RegularTextInput {
    onChangeText = (value: any) => {
        // if (Platform.OS == "web") {
        //     return this.props.onChangeText(value);
        // }
        value = value.toString();
        if (value === "") return this.props.onChangeText("");

        while (value.length > 1 && value[0] == "0" && value[1] != "." && value[1] != ",") value = value.substring(1);

        if (value[0] == ".") {
            value = "0" + value;
        }
        value = value.replace(",", ".");
        if (/^[+-]?\d+((\.)(|\d+))?$/.test(value)) {
            return this.props.onChangeText(value);
        }
    };

    onBlur = () => {
        BigNumber.config({ EXPONENTIAL_AT: 30 });
        const v = new BigNumber(this.props.value).toString();
        return this.props.onChangeText(v);
    };

    setType: () => KeyboardTypeOptions = () =>
        (Platform.OS == "ios" && "decimal-pad") || (Platform.OS == "web" && "decimal-pad") || "number-pad";
}
