import React, {Component} from "react"
import {View, StyleSheet, Image, Platform} from "react-native"
import {Header} from "@components/header/Header"
import Container from "@base/Container"
import {HeaderType} from "@custom-types/HeaderType"
import {NavigationType} from "@custom-types/NavigationType"
import { colors, settings } from "@styles/globalStyles"
import {connect} from "react-redux"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import InlineButton from "@base/InlineButton"
import RegularTextInput from "@base/RegularTextInput"
import i18n from "@i18n/i18n"
import RegularText from "@base/RegularText"
import Row from "@base/Row"
import {Camera} from "expo-camera"
import * as ImagePicker from "expo-image-picker"
import * as ImageManipulator from "expo-image-manipulator"
import CircleButton from "@base/CircleButton"

interface Props {
    title?: string
    subtitle?: string
    alert?: boolean
    onPressCallBack?: (img) => void
}

interface State {
    img: any
    alert?: boolean
    onPressCallBack: (img) => void
}

const {t} = i18n

export default class DigitalDocuments extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            img: null,
            alert: this.props.alert,
            onPressCallBack: this.props.onPressCallBack || (() => {}),
        }
    }

    selectImage = async (camera?: boolean) => {
        let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()
        let permissionCamera = await Camera.requestCameraPermissionsAsync()

        if ((permissionResult.granted === false || permissionCamera.granted === false) && Platform.OS !== "web") {
            alert("Permission to access camera roll is required!")
            return
        }

        let pickerResult: any = camera
            ? await ImagePicker.launchCameraAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.Images,
                  allowsEditing: false,
                  base64: false,

                  quality: 1,
              })
            : await ImagePicker.launchImageLibraryAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.Images,
                  allowsEditing: false,
                  base64: false,

                  quality: 1,
              })

        const manipResult = await ImageManipulator.manipulateAsync(pickerResult.uri, [{resize: {height: 1080}}], {
            compress: 1,
            format: ImageManipulator.SaveFormat.JPEG,
        })
        this.setState({img: manipResult.uri, alert: false})
        this.state.onPressCallBack(manipResult.uri)
    }

    componentDidUpdate(props) {
        if(this.props != props) {
          this.setState(
            { alert: this.props.alert }
          );
        }
      }

    render() {
        return (
            <View>
                {this.props.title && <RegularText color={colors.grey}>{this.props.title}</RegularText>}
               
                <View style={styles.inputContainer}>
                {this.state.alert && (
                    <RegularText style={{marginBottom: 10}} align={"center"} fontSize={12} color={colors.secondary}>
                        {t("img_error")}
                    </RegularText>
                )}
                    <View style={{height: 150, width: 150}}>
                        <Image
                            source={{
                                uri: this.state.img ? this.state.img : "",
                            }}
                            style={{
                                height: 150,
                                width: 150,
                                resizeMode: "cover",
                                backgroundColor: colors.grey,
                                borderRadius: settings.cardRadius,
                                borderWidth: 1,
                                borderColor: this.state.alert ? colors.secondary : colors.grey                            }}
                        />
                        <Row style={{position: "absolute", bottom: -30, left: 10}}>
                            <CircleButton icon={"images"} onPress={() => this.selectImage(false)}></CircleButton>
                            <CircleButton icon={"camera"} onPress={() => this.selectImage(true)}></CircleButton>
                        </Row>
                    </View>

                    <RegularText style={{marginTop: 30}} align={"justify"} color={colors.grey}>
                        {this.props.subtitle}
                    </RegularText>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        //justifyContent: "space-between",
        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    inputContainer: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        alignItems: "center",
        alignContent: "center",
        marginBottom: 20,
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        paddingHorizontal: 40,
        textAlign: "center",
    },
})

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => ({})
