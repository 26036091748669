import BoldText from "@base/BoldText";
import Card from "@base/Card";
import CircleButton from "@base/CircleButton";
import { OAuthHistoryService } from "@core/services/oauth/OAuthHistoryService";
import Wallet from "@core/wallet/Wallet";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { SeedNavigatorScreens } from "@navigation/SeedNavigator";
import { loading, ready } from "@store/actions/global";
import store from "@store/index";
import SeedHistorySecureStorage from "@utils/storage/storages/SeedHistorySecureStorage";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { Storage } from "../../../utils/storage/Storage";

interface Props {
    navigation: NavigationType;
    client: Client;
}

interface State {
    words: string[];
    seeds: string[];
    isVerified: boolean;
}

const { t } = i18n;

export class _StoredSeeds extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
    
        this.onPressCard = this.onPressCard.bind(this);
        this.state = {
            words: Wallet.getInstance().getMnemonic(),
            seeds: [],
            isVerified: false,
        };
    }

    componentDidMount() {
        this.getSeedStored();
    }

    async getSeedStored() {
        try {
            store.dispatch(loading());
            let seeds: string[] = [];
            // GET NEW SEEDS STORED
            const storageHistory = (await OAuthHistoryService.getSeedHistory(this.props.client)) || [];
            let newSeeds = storageHistory.map((s) => {
                return s?.mnemonic;
            });
            seeds = newSeeds?.length ? seeds.concat(newSeeds) : seeds;

            // SEED STORED
            const seedStored = Wallet.getInstance().getMnemonic().join(" ");
            seeds = seedStored ? seeds.concat(seedStored) : seeds;

            // GET OLD SEEDS STORED
            const seedHistory = (await SeedHistorySecureStorage.get()) || [];
            let oldSeeds = seedHistory.map((s) => {
                return s?.mnemonic;
            });
            seeds = oldSeeds?.length ? seeds.concat(oldSeeds) : seeds;

            // OLD SEED STORED
            const oldSeedStored = await Storage.getInstance().getItem("auth4.0", {
                isSecure: true,
                oldKey: "auth4.0",
            });
            seeds = oldSeedStored ? seeds.concat(oldSeedStored) : seeds;

            seeds = seeds.filter((seed, index) => seeds.indexOf(seed) === index);

            this.setState({ seeds: seeds });
            store.dispatch(ready());
        } catch (e) {
            store.dispatch(ready());
        }
    }

    async onPressCard(words: string) {
        this.props.navigation.navigate(SeedNavigatorScreens.Backup.routeName, { words: words.split(" ") });
    }

    

    render() {
        return (
            <View>
                {this.state.seeds.map((s) => {
                    return (
                        <Card
                            onPress={() => this.onPressCard(s)}
                            key={s}
                            style={{ marginTop: 15, alignContent: "center" }}
                            left={<BoldText numberOfLines={1}>{s}</BoldText>}
                            right={
                                <CircleButton
                                    onPress={() => this.onPressCard(s)}
                                    style={{ width: 35, height: 35 }}
                                    iconSize={18}
                                    icon={"right"}
                                ></CircleButton>
                            }
                        ></Card>
                    );
                })}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        paddingBottom: 25,
    },
    word: {
        paddingHorizontal: 20,
    },
    swipContainer: {
        flex: 1,
        height: 58,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const StoredSeeds = connect(mapStateToProps, mapDispatchToProps)(_StoredSeeds);

export default StoredSeeds;
