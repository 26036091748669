import { StyleSheet, Appearance } from 'react-native';

const lightColors = {
    primary: '#d8d9db',
    secondary: '#c6223d',
    complementary: '#c6223d',
    tertiary: '#fff', 
    background: '#e2e2e2',
    gradientFrom: '#f3f5f9',
    gradientTo: '#d8d9db',
    grey: '#9091a4',
    white: 'white',
    black: '#333',
    blue: '#2696fb',
    green: '#1DBC60',
    yellow: '#FECA54',
    red: '#EE4540',
    text: '#272727',
    shadow: 'rgba(255,255,255,0.7)',
    secondaryShadow: 'rgba(0, 0, 0, 0.1)',
    tertiaryShadow: 'rgba(255, 0, 43, 0.1)',
    highlightedText: '#c6223d'
};

const darkColors = lightColors;

const settings = {}

export { lightColors, darkColors, settings }