import { FollowerRequestType } from "@custom-types/FollowerRequestType";
import Post from "@custom-types/Post";

export const SET_MAIN_POSTS = "SET_MAIN_POSTS";
export const ADD_MAIN_POSTS = "ADD_MAIN_POSTS";
export const ADD_DISCOVER_POSTS = "ADD_DISCOVER_POSTS";
export const SET_DISCOVER_POSTS = "SET_DISCOVER_POSTS";
export const UPDATE_DISCOVER_POST = "UPDATE_DISCOVER_POST";
export const RELOAD_POSTS = "RELOAD_POSTS";
export const SET_PROFILE_POSTS_SOCIAL = "SET_PROFILE_POSTS_SOCIAL";
export const SET_PROFILE_POSTS_NFT = "SET_PROFILE_POSTS_NFT";
export const SET_FOLLOWER_REQUEST = "SET_FOLLOWER_REQUEST";
export const SET_SELECTED_POST = "SET_SELECTED_POST"

export const setMainPosts = (posts: Array<Post>) => {
    return { type: SET_MAIN_POSTS, mainPosts: posts };
};

export const addMainPosts = (posts: Array<Post>) => {
    return { type: ADD_MAIN_POSTS, mainPosts: posts };
};

export const setDiscoverPosts = (posts: Array<Post>) => {
    return { type: SET_DISCOVER_POSTS, discoverPosts: posts };
};
export const updateDiscoverPost = (post: Post) => {
    return { type: UPDATE_DISCOVER_POST, post: post };
};

export const addDiscoverPosts = (posts: Array<Post>) => {
    return { type: ADD_DISCOVER_POSTS, discoverPosts: posts };
};

export const reloadPosts = (reload: boolean) => {
    return { type: RELOAD_POSTS, reloadPosts: reload };
};

export const setProfilePostsSocial = (posts: Array<Post>) => {
    return { type: SET_PROFILE_POSTS_SOCIAL, profilePostsSocial: posts };
};

export const setProfilePostsNFT = (posts: Array<Post>) => {
    return { type: SET_PROFILE_POSTS_NFT, profilePostsNFT: posts };
};

export const setFollowerRequests = (followerRequests: Array<FollowerRequestType>) => {
    return { type: SET_FOLLOWER_REQUEST, followerRequests: followerRequests };
};

export const setSelectedPost = (post: Post) => {
    return { type: SET_SELECTED_POST, selectedPost: post };
};