import Card from "@base/Card";
import CircleButton from "@base/CircleButton";
import Container from "@base/Container";
import Label from "@base/Label";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import SemiBoldText from "@base/SemiBold";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import { Header } from "@components/header/Header";
import CurrencyBalance from "@components/wallet/CurrencyBalance";
import { ButtonActionsType } from "@components/wallet/WalletsButtons";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import { ModuleControlService, Services } from "@core/services/ModuleControlService";
import Wallet from "@core/wallet/Wallet";
import { ActivityModel } from "@custom-types/ActivityModel";
import DigitalCurrencyTransactionType from "@custom-types/DigitalCurrencyTransactionType";
import { NavigationType } from "@custom-types/NavigationType";
import { SimpleLineIcons } from "@expo/vector-icons";
import i18n from "@i18n/i18n";
import { ExchangeNavigatorScreens } from "@navigation/ExchangeNavigator";
import { SendNavigatorScreens } from "@navigation/SendNavigator";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { loading, ready, showModalBottom } from "@store/actions/global";
import {
    cleanOperation,
    selectCurrency,
    selectExchangeFrom,
    selectTransaction,
    setButtonActionsType,
} from "@store/actions/wallet";
import store from "@store/index";
import { colors, settings } from "@styles/globalStyles";
import React, { Component } from "react";
import { FlatList, RefreshControl, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import TransactionDetailComponent from "./transaction/TransactionDetailComponent";
import PressableBase from "@base/PressableBase";
import BoldText from "@base/BoldText";
import { getActivityCard } from "@utils/helpers/activity/activity.helper";

interface Props {
    navigation: NavigationType;
    selectedCurrency: string;
    fiatCurrency: FiatCurrency;
    syncing: boolean;
    route: any;

    client;
}

interface State {
    transactions: Array<any>;
    activity: Array<ActivityModel>;
    syncing: boolean;

    viewType: "ACTIVITY" | "TRANSACTIONS";
}

const { t } = i18n;

export class _WalletScreen extends Component<Props, State> {
    protected currency: Currency;

    constructor(props: Props) {
        super(props);
        this.currency = Wallet.getInstance().findCurrencyById(this.props.selectedCurrency);
        this.openTransaction = this.openTransaction.bind(this);
        this.onPressDetail = this.onPressDetail.bind(this);
        this.onPressSend = this.onPressSend.bind(this);
        this.onPressService = this.onPressService.bind(this);
        this.onPressExchange = this.onPressExchange.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onDisplay = this.onDisplay.bind(this);
        this.renderEmptyList = this.renderEmptyList.bind(this);
        this.renderListHeader = this.renderListHeader.bind(this);
        this.state = {
            transactions: this.currency.getTransactions(),
            activity: this.currency.getActivity(),
            syncing: false,
            viewType: "ACTIVITY",
        };
    }

    async onDisplay() {
        this.setState({ syncing: true });
        await this.currency.syncActivity();

        this.setState({
            activity: this.currency.getActivity(),
            viewType: this.currency.getActivity()?.length > 0 ? "ACTIVITY" : "TRANSACTIONS",
        });

        await this.currency.syncBalance();

        await this.currency.syncTransactions();

        this.setState(
            {
                transactions: this.currency.getTransactions(),
                syncing: false,
            },
            () => this.forceUpdate()
        );
    }

    async componentDidMount() {
        this.props.navigation.addListener("focus", this.onDisplay);
    }

    onPressDetail(index) {
        store.dispatch(selectTransaction(index));
        this.props.navigation.navigate(WalletNavigatorScreens.TransactionDetail.routeName);
    }

    async openTransaction(item: DigitalCurrencyTransactionType, index) {
        store.dispatch(loading());
        const txId = item?.txId || "";
        let tx: DigitalCurrencyTransactionType = await this.currency.getTransactionByHash(txId);
        const transaction = this.currency.parseTransaction(tx);
        store.dispatch(ready());
        if (this.currency && (transaction?.from || transaction?.txId || transaction?.to)) {
            store.dispatch(
                showModalBottom({
                    modalContent: (
                        <TransactionDetailComponent
                            currency={this.currency}
                            transaction={transaction}
                            {...this.props}
                        />
                    ),
                })
            );
            return;
        }

        // transaction = this.currency.getTransaction(index);
        // store.dispatch(
        //     showModalBottom({
        //         modalContent: (
        //             <TransactionDetailComponent currency={this.currency} transaction={transaction} {...this.props} />
        //         ),
        //     })
        // );
    }

    onPressSend() {
        store.dispatch(cleanOperation());
        store.dispatch(selectCurrency(this.currency.getId()));
        this.props.navigation.navigate(WalletNavigatorScreens.Send.routeName, {
            screen: SendNavigatorScreens.SendMain.routeName,
            params: { selectedCurrency: this.currency.getId(), showFindClient: true },
        });
    }

    onPressService(type: ButtonActionsType) {
        store.dispatch(cleanOperation());
        store.dispatch(selectCurrency(this.currency.getId()));
        store.dispatch(setButtonActionsType(type));
        this.props.navigation.navigate(WalletNavigatorScreens.Buy.routeName);
    }

    renderTransaction = ({ item, index }) => {
        const transaction: DigitalCurrencyTransactionType = this.currency.parseTransaction(item);
        return (
            <Card
                style={{
                    borderLeftColor: transaction?.confirmations >= 6 ? colors.green : colors.yellow,
                    borderLeftWidth: 10,
                }}
                key={transaction?.txId}
                onPress={() => this.openTransaction(item, index)}
                icon={
                    <View style={styles.icon}>
                        <SimpleLineIcons name="lock" size={16} align="center" color={colors.text} />

                        {transaction?.confirmations < 6 && transaction?.confirmations !== null && (
                            <SemiBoldText fontSize={10}> {transaction?.confirmations}/6 </SemiBoldText>
                        )}
                        {transaction?.confirmations >= 6 && <SemiBoldText fontSize={10}> +6 </SemiBoldText>}
                    </View>
                }
                left={
                    <View>
                        <SemiBoldText>
                            {transaction?.direction == "SENT" && t("sent")}
                            {transaction?.direction == "RECEIVED" && t("received")}
                        </SemiBoldText>
                        <RegularText fontSize={10}>
                            {" "}
                            {this.currency.getImplementation().parseDate(transaction?.date)}{" "}
                        </RegularText>
                    </View>
                }
                right={
                    <View>
                        {transaction?.value > 0 && (
                            <RegularText> {this.currency.format(transaction?.value)} </RegularText>
                        )}
                        {transaction?.value == 0 && <Label text="Contract excecution" fontSize={10} />}
                    </View>
                }
            />
        );
    };

    onRefresh = async () => {
        await this.onDisplay();
    };

    onPressExchange = () => {
        store.dispatch(selectExchangeFrom({ currency: this.currency }));
        this.props.navigation.navigate(ExchangeNavigatorScreens.Exchange.routeName);
    };

    renderEmptyList = () => {
        return (
            <View>
                {!this.props.syncing && !this.state.syncing && (
                    <Card>
                        <RegularText align="center">
                            {t("activity_transactions", { currency: this.currency.getPName() })}
                        </RegularText>
                    </Card>
                )}
            </View>
        );
    };

    renderListHeader = () => {
        return (
            <View>
                <CurrencyBalance
                    amount={this.currency.getFormatTotalBalance()}
                    balance={this.currency.getBalance()}
                    title={this.currency.getPName() + " " + t("balance")}
                    currency={this.currency}
                    fiatCurrency={this.props.fiatCurrency}
                />
                <Row style={styles.buttons}>
                    {this.currency.isExchangeAvailable() &&
                        ModuleControlService.getInstance().isServiceEnabled(Services.exchangeService) && (
                            <CircleButton title={t("exchange")} icon="exchange" onPress={this.onPressExchange} />
                        )}
                    <CircleButton title={t("send")} icon="send" onPress={this.onPressSend} />
                    <CircleButton
                        title={t("receive")}
                        icon="receive"
                        onPress={() => this.props.navigation.navigate(WalletNavigatorScreens.Receive.routeName)}
                    />
                    {!this.currency.isTestnet() &&
                        this.currency.isBuyable() &&
                        ModuleControlService.getInstance().isServiceEnabled(Services.buyService) && (
                            <CircleButton
                                title={t("buy")}
                                icon="cart"
                                onPress={() => this.onPressService(ButtonActionsType.BUY)}
                            />
                        )}
                    {!this.currency.isTestnet() &&
                        this.currency.isSellable() &&
                        ModuleControlService.getInstance().isServiceEnabled(Services.sellService) && (
                            <CircleButton
                                title={t("sell")}
                                icon="dollar"
                                onPress={() => this.onPressService(ButtonActionsType.SELL)}
                            />
                        )}
                </Row>

                <Row style={{ justifyContent: "space-between", marginVertical: 10, width: "100%" }}>
                    <PressableBase
                        onPress={() => this.setState({ viewType: "ACTIVITY" })}
                        style={{
                            flex: 0.45,
                            marginRight: 10,
                            paddingVertical: 4,
                            borderBottomColor:
                                this.state.viewType == "ACTIVITY" ? colors.white : colors.secondaryShadow,
                            borderBottomWidth: 1,
                        }}
                    >
                        <SemiBoldText
                            style={{ opacity: this.state.viewType == "ACTIVITY" ? 1 : 0.85 }}
                            numberOfLines={1}
                            align="center"
                        >
                            {t("activity").toUpperCase()}
                        </SemiBoldText>
                    </PressableBase>

                    <PressableBase
                        onPress={() => this.setState({ viewType: "TRANSACTIONS" })}
                        style={{
                            flex: 0.45,
                            marginRight: 10,
                            paddingVertical: 4,
                            borderBottomColor:
                                this.state.viewType == "TRANSACTIONS" ? colors.white : colors.secondaryShadow,
                            borderBottomWidth: 1,
                        }}
                    >
                        <SemiBoldText
                            style={{
                                opacity: this.state.viewType == "TRANSACTIONS" ? 1 : 0.85,
                            }}
                            numberOfLines={1}
                            align="center"
                        >
                            {t("transactions").toUpperCase()}
                        </SemiBoldText>
                    </PressableBase>
                </Row>
            </View>
        );
    };

    renderActivity = ({ item }) => {
        if (!item) return;
        return getActivityCard(item, this.props.navigation);
    };

    render() {
        return (
            <ScreenWrapper>
                <Header
                    containerStyles={{ zIndex: 99 }}
                    {...this.props}
                    title={" "}
                    rightBtn={[
                        {
                            onPress: () => {
                                this.onRefresh();
                            },
                            icon: "refresh",
                        },
                    ]}
                >
                    <View
                        style={{
                            zIndex: 999,
                            borderColor: colors.gradientFrom,
                            borderWidth: 4,
                            height: 60,
                            width: 60,
                            borderRadius: 30,
                            top: 8,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CurrencyIcon currency={this.currency} size={56} styles={{}} />
                    </View>
                </Header>

                <Container style={{ flex: 1, paddingHorizontal: 0, zIndex: 0 }}>
                    <FlatList
                        data={this.state.viewType == "ACTIVITY" ? this.state.activity : this.state.transactions}
                        renderItem={this.state.viewType == "ACTIVITY" ? this.renderActivity : this.renderTransaction}
                        keyExtractor={(item) => item?._id || item?.txId + (Math.random() * 100).toString()}
                        initialNumToRender={10}
                        ListEmptyComponent={this.renderEmptyList}
                        ListHeaderComponent={this.renderListHeader}
                        contentContainerStyle={{ paddingHorizontal: 20 }}
                        refreshControl={
                            <RefreshControl
                                progressViewOffset={-12}
                                tintColor={colors.text}
                                onRefresh={this.onRefresh}
                                refreshing={this.props.syncing || this.state.syncing}
                            />
                        }
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        activity: state.activity.activity,
        selectedCurrency: state.wallet.selectedCurrency,
        fiatCurrency: state.wallet.fiatCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const WalletScreen = connect(mapStateToProps, mapDispatchToProps)(_WalletScreen);

export default WalletScreen;

const styles = StyleSheet.create({
    buttons: {
        marginTop: 0,
        marginBottom: 15,
        marginHorizontal: -5,
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        backgroundColor: colors.secondary,
        width: 70,
        height: 70,
        borderRadius: 40,
        justifyContent: "center",
        marginBottom: 8,
    },
    buttonWrapper: {
        alignItems: "center",
    },
    icon: {
        width: 30,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: 5,
    },
    tag: {
        borderRadius: settings.cardRadius,
        backgroundColor: colors.secondary,
        padding: 5,
    },
    currencyIcon: {
        top: 8,
        width: 60,
        height: 60,
        borderRadius: 30,
        alignItems: "center",
        justifyContent: "center",
        borderColor: colors.gradientFrom,
        borderWidth: 4,
    },
});
