import React, { Component } from "react";
import { StyleSheet, View, TextStyle } from "react-native";
import RegularText from "./RegularText";
import { colors, settings } from "@styles/globalStyles";
import Icon from "@components/icons";
import GradientWrapper from "@components/wrapper/GradientWrapper";
import PressableBase from "./PressableBase";

interface Props {
    title?: string;
    icon: string;
    style?: TextStyle;
    containerStyles?: any;
    align?: "auto" | "left" | "right" | "center" | "justify";
    fontSize?: number;
    iconSize?: number;
    color?: string;
    iconColor?: string;
    onPress?: () => void;
    disableOnPress?: boolean;
    vibrate?: boolean;
}

interface State {
    inlineStyles: TextStyle;
}

export default class CircleButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            inlineStyles: {
                borderRadius: settings.mainButtonsRadius,
            },
        };
    }

    addStyles: () => TextStyle = () => ({});

    render() {
        return (
            <View
                style={[{ alignItems: "center", marginHorizontal: 5 }, this.props.containerStyles]}
                accessibilityLabel={this.props.icon}
            >
                {this.props.disableOnPress ? (
                    <GradientWrapper
                        disable={true}
                        style={[styles.defaultStyles, this.props.style, this.addStyles(), this.state.inlineStyles]}
                    >
                        <Icon
                            name={this.props.icon}
                            size={this.props.iconSize ? this.props.iconSize : 30}
                            color={this.props.iconColor ? this.props.iconColor : colors.mainButtonsColor}
                        />
                    </GradientWrapper>
                ) : (
                    <PressableBase onPress={this.props.onPress} vibrate={this.props.vibrate}>
                        <GradientWrapper
                            disable={true}
                            style={[styles.defaultStyles, this.props.style, this.addStyles(), this.state.inlineStyles]}
                        >
                            <Icon
                                name={this.props.icon}
                                size={this.props.iconSize ? this.props.iconSize : 30}
                                color={this.props.iconColor ? this.props.iconColor : colors.mainButtonsColor}
                            />
                        </GradientWrapper>
                    </PressableBase>
                )}
                {this.props.title && (
                    <RegularText
                        numberOfLines={1}
                        fontSize={this.props.fontSize ? this.props.fontSize : 12}
                        color={colors.text}
                        //style={{width: "100%", flex: 1, flexWrap: "nowrap"}}
                    >
                        {this.props.title}
                    </RegularText>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        backgroundColor: colors.mainButtonsBackground,
        width: 55,
        height: 55,
        borderRadius: 30,
        justifyContent: "center",
        marginBottom: 8,
        alignItems: "center",
    },
});
