import { loading, ready, showPopup } from "@store/actions/global";
import store from "@store/index";
import * as LocalAuthentication from "expo-local-authentication";
import i18n from "@i18n/i18n";

export enum LocalAuthResponse {
    NO_AUTH = "NO_AUTH",
    AUTHENTICATED = "AUTHENTICATED",
    NOT_AUTHENTICATED = "NOT_AUTHENTICATED",
}

const { t } = i18n;

export const getLocalAuth = async () => {
    const hasAuthentication = await LocalAuthentication.getEnrolledLevelAsync();
    if (hasAuthentication == 0) {
        return LocalAuthResponse.NO_AUTH;
    } else {
        const localAuth = await LocalAuthentication.authenticateAsync({ promptMessage: t("local_auth") });
        if (localAuth.success) {
            return LocalAuthResponse.AUTHENTICATED;
        } else {
            store.dispatch(showPopup({ type: "ERROR" }));
            return LocalAuthResponse.NOT_AUTHENTICATED;
        }
    }
};
