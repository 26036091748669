import React, { Component } from "react";
import { View, StyleSheet, FlatList, RefreshControl, Keyboard, EmitterSubscription } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import NFTCard from "./components/NFTCard";
import NFT from "@custom-types/NFTModel";
import { colors, settings } from "@styles/globalStyles";
import { NTFsNavigatorScreens } from "@navigation/NFTsNavigator";
import { Client } from "@custom-types/Client";
import store from "@store/index";
import { setRequestStatusNFTs, setSelectedNFT } from "@store/actions/nfts.action";
import { FloatButton } from "@base/FloatButton";
import NFTService from "@core/services/NFTService";
import Wallet from "@core/wallet/Wallet";
import BotCard from "@base/BotCard";
import { MarketPlaceStatus } from "@store/reducers/nfts.reducer";
import SearchBase from "@components/search/SearchBase";
import { Dimensions } from "react-native";
import RegularText from "@base/RegularText";
import BoldText from "@base/BoldText";
import UserCard from "@screens/social/components/UserCard";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import { ModuleControlService, NFTsModules } from "@core/services/ModuleControlService";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { ProfileSections } from "@custom-types/ProfileType";
import { NTFNavigatorScreens } from "@navigation/NFTNavigator";
import FlatListBase from "@base/FlatListBase";
import PressableBase from "@base/PressableBase";

const windowWidth = Dimensions.get("window").width;

interface Props {
    navigation: NavigationType;
    client: Client;

    statusMarketPlace: {
        status: string;
        nft: NFT;
    };
}

interface State {
    syncing: boolean;
    loading: boolean;
    nfts: Array<NFT>;
    search_nfts: Array<NFT>;
    search_users: Array<Client>;
    isSearching: boolean;
    keyboardOpen: boolean;
    totalPage: number;
    page: number;
    show_bot: boolean;
    bot_message: string;
    search: string;
    searchType: "NFT" | "USERS";
}

const { t } = i18n;

export class _NFTsMainScreen extends Component<Props, State> {
    private keyboardWillShowSub: EmitterSubscription;
    private keyboardWillHideSub: EmitterSubscription;
    private nftService: NFTService;
    flatListRef: any;
    intervalID: any;
    constructor(props: Props) {
        super(props);
        this.nftService = NFTService.getInstance();
        this.renderItem = this.renderItem.bind(this);
        this.onPressCard = this.onPressCard.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.onPressUser = this.onPressUser.bind(this);
        this.state = {
            syncing: false,
            loading: true,
            nfts: [],
            search_nfts: [],
            search_users: [],
            isSearching: false,
            totalPage: 1,
            page: 1,
            keyboardOpen: false,
            show_bot: false,
            bot_message: "",
            search: "",
            searchType: "NFT",
        };
    }

    async componentDidMount() {
        this.keyboardWillShowSub = Keyboard.addListener("keyboardDidShow", () => this.setState({ keyboardOpen: true }));
        this.keyboardWillHideSub = Keyboard.addListener("keyboardDidHide", () =>
            this.setState({ keyboardOpen: false }),
        );
        this.setInit();
        setTimeout(() => {
            if (this.state.nfts?.length > 0) {
                this.flatListRef.scrollToIndex({ index: 0, viewOffset: 200 });
            }
        }, 5000);
    }

    componentDidUpdate(props) {
        if (props != this.props) {
            this.setMarketPlaceActions();
        }
    }

    async setInit() {
        const nfts = await NFTService.getInstance().getAllNFTs(this.state.page, true);
        if (nfts && nfts.docs) {
            this.setMarketPlaceActions();
            this.setState({
                nfts: nfts.docs,
                totalPage: nfts.totalPages,
            });
        }
        this.setState({ loading: false });
    }

    setMarketPlaceActions() {
        if (this.props.statusMarketPlace.status == MarketPlaceStatus.Updated) {
            return;
        }
        if (this.props.statusMarketPlace.status == MarketPlaceStatus.WaitingListing) {
            this.watchListing(t("bot_marketplace_message"), "sell");
        }
        if (this.props.statusMarketPlace.status == MarketPlaceStatus.WaitingCancel) {
            this.watchListing(t("bot_marketplace_message_cancel"), "cancel");
        }
        if (this.props.statusMarketPlace.status == MarketPlaceStatus.Reload) {
            this.onRefresh();
        }
    }

    async watchListing(message: string, waitingFor: string) {
        this.setState({
            show_bot: true,
            bot_message: message,
        });
        if (this.state.nfts?.length > 0) {
            setTimeout(() => {
                if (this.state.nfts?.length > 0) {
                    this.flatListRef.scrollToIndex({ index: 0, viewOffset: 200 });
                }
            }, 1000);
        }

        this.intervalID = setInterval(async () => {
            const nft: NFT = await NFTService.getInstance().getNFT(
                this.props.statusMarketPlace?.nft._id,
                this.props.statusMarketPlace?.nft?.blockchain,
                false,
            );

            if ((waitingFor == "sell" && nft.isListed) || (waitingFor == "cancel" && !nft.isListed)) {
                await this.onRefresh();
                this.setState({
                    show_bot: false,
                    bot_message: "",
                });
                clearInterval(this.intervalID);
                store.dispatch(setRequestStatusNFTs({ status: MarketPlaceStatus.Updated, nft: null }));
            }
        }, 5000);

        setTimeout(async () => {
            this.setState({
                show_bot: false,
                bot_message: "",
            });
            store.dispatch(setRequestStatusNFTs({ status: MarketPlaceStatus.Updated, nft: null }));
            clearInterval(this.intervalID);
        }, 60000);
    }

    componentWillUnmount() {
        Keyboard.removeAllListeners("keyboardDidShow");
        Keyboard.removeAllListeners("keyboardDidHide");
        clearInterval(this.intervalID);
    }

    async onPressCard(nft: NFT) {
        const updatedNft = await NFTService.getInstance().getNFT(nft._id, nft?.blockchain, true);
        if (updatedNft) {
            store.dispatch(setSelectedNFT(updatedNft));

            this.props.navigation.navigate(NTFsNavigatorScreens.NFT.routeName);
        }
    }

    async onPressUser(clientID) {
        this.props.navigation.navigate(NTFsNavigatorScreens.Profile.routeName, {
            screen: ProfileNavigatorScreens.ProfileMain.routeName,
            params: { clientID: clientID, profileSection: ProfileSections.nftProfile },
        });
    }

    renderItem = ({ item }) => (
        <View style={{ flex: 1 }}>
            {this.state.isSearching ? (
                <View style={{ flex: 1 }}>
                    {this.state.searchType == "NFT" && (
                        <NFTCard
                            navigation={this.props.navigation}
                            nft={item}
                            onPress={() => this.onPressCard(item)}
                        ></NFTCard>
                    )}
                    {this.state.searchType == "USERS" && (
                        <UserCard
                            searchVersion={true}
                            client={item}
                            isOwner={true}
                            navigation={this.props.navigation}
                            styles={{ marginHorizontal: 8 }}
                            onPressUser={this.onPressUser}
                        />
                    )}
                </View>
            ) : (
                <NFTCard navigation={this.props.navigation} nft={item} onPress={() => this.onPressCard(item)}></NFTCard>
            )}
        </View>
    );

    onSearch = async (search) => {
        this.setState({ isSearching: search.length > 0, search: search });

        if (search.length > 0) {
            this.setState({ syncing: true });
            if (this.state.searchType == "NFT") {
                const resp = await NFTService.getInstance().searchNFTs(search);

                this.setState({ search_nfts: resp.docs, syncing: false });
            }
            if (this.state.searchType == "USERS") {
                const resp = await SocialNetworkService.getInstance().searchUser(search, 1);
                if (resp) {
                }
                this.setState({ search_users: resp.docs, syncing: false });
            }
        }
    };

    async onRefresh() {
        this.setState({ search: "", isSearching: false });
        const nfts = await NFTService.getInstance().getAllNFTs(1, true);

        if (nfts && nfts.docs) {
            this.setState({
                page: 1,
                nfts: nfts.docs,
                totalPage: nfts.totalPages,
            });
            store.dispatch(setRequestStatusNFTs({ status: MarketPlaceStatus.Updated, nft: null }));
        }
        if (!nfts) {
            this.setState({
                page: 1,
                nfts: [],
                totalPage: 1,
            });
            store.dispatch(setRequestStatusNFTs({ status: MarketPlaceStatus.Updated, nft: null }));
        }
    }

    async loadMore() {
        if (this.state.page < this.state.totalPage) {
            const nfts = await NFTService.getInstance().getAllNFTs(this.state.page + 1, false);
            if (nfts && nfts.docs) {
                this.setState({
                    page: nfts.page,
                    nfts: this.state.nfts.concat(nfts.docs),
                    totalPage: nfts.totalPages,
                });
            }
        }
    }

    getHeader() {
        return (
            <View style={{ flex: 1 }}>
                <View style={{ marginBottom: 15, marginTop: 5, marginHorizontal: 5 }}>
                    <SearchBase value={this.state.search} onSearch={(value) => this.onSearch(value)} />
                    {this.state.isSearching && (
                        <View style={{ flexDirection: "row", marginTop: 15, justifyContent: "space-around" }}>
                            {this.state.searchType == "NFT" ? (
                                <BoldText color={colors.complementary || colors.secondary}>NFTs</BoldText>
                            ) : (
                                <PressableBase
                                    onPress={() =>
                                        this.setState({ searchType: "NFT" }, () => this.onSearch(this.state.search))
                                    }
                                >
                                    <RegularText style={{ opacity: 0.8 }}>NFTs</RegularText>
                                </PressableBase>
                            )}
                            {this.state.searchType == "USERS" ? (
                                <BoldText color={colors.complementary || colors.secondary}>{t("users")}</BoldText>
                            ) : (
                                <PressableBase
                                    onPress={() =>
                                        this.setState({ searchType: "USERS" }, () => this.onSearch(this.state.search))
                                    }
                                >
                                    <RegularText style={{ opacity: 0.8 }}>{t("users")}</RegularText>
                                </PressableBase>
                            )}
                        </View>
                    )}
                </View>

                <View style={{ paddingHorizontal: 5 }}>
                    {this.state.show_bot && <BotCard message={this.state.bot_message}></BotCard>}
                </View>
            </View>
        );
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={"NFTs Marketplace"}
                    type={HeaderType.Light}
                    testnet={Wallet.getInstance().isTestnet()}
                    leftAvatar={{
                        onPress: () => {
                            this.props.navigation.navigate(NTFsNavigatorScreens.Profile.routeName, {
                                screen: ProfileNavigatorScreens.ProfileMain.routeName,
                                params: {
                                    clientID: this.props.client?._id,
                                    profileSection: ProfileSections.nftProfile,
                                },
                            });
                        },
                        uri:
                            this.props.client &&
                            this.props.client?.profileImagePath &&
                            this.props.client?.profileImagePath !== null
                                ? this.props.client?.profileImagePath.thumbnail
                                : "",
                        alias: this.props.client && this.props.client?.alias ? this.props.client?.alias : "",
                        size: 35,
                    }}
                    rightBtn={{
                        icon: "clock-history",
                        iconType: "custom",
                        onPress: () => {
                            this.props.navigation.navigate(NTFsNavigatorScreens.historyNFT.routeName);
                        },
                    }}
                    {...this.props}
                />

                <Container style={{ flex: 1, paddingHorizontal: 10 }}>
                    <FlatListBase
                        ref={(ref) => {
                            this.flatListRef = ref;
                        }}
                        data={
                            this.state.isSearching
                                ? (this.state.searchType == "NFT" && this.state.search_nfts) ||
                                  (this.state.searchType == "USERS" && this.state.search_users)
                                : this.state.nfts
                        }
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item._id}
                        contentContainerStyle={{ paddingBottom: 20 }}
                        ListHeaderComponent={this.getHeader()}
                        showsHorizontalScrollIndicator={false}
                        numColumns={windowWidth > settings.maxMobileWidth ? 3 : 2}
                        onScrollToIndexFailed={(info) => {
                            const wait = new Promise((resolve) => setTimeout(resolve, 500));
                            wait.then(() => {
                                return;
                            });
                        }}
                        refreshControl={
                            <RefreshControl
                                tintColor={colors.text}
                                onRefresh={this.onRefresh}
                                refreshing={this.state.syncing}
                            />
                        }
                        maxToRenderPerBatch={10}
                        initialNumToRender={6}
                        onEndReachedThreshold={1}
                        onEndReached={this.loadMore}
                    />

                    {!this.state.isSearching &&
                        !this.state.keyboardOpen &&
                        ModuleControlService.getInstance().isNFTsModuleEnabled(NFTsModules.mint) && (
                            <FloatButton
                                onPress={() =>
                                    this.props.navigation.navigate(NTFsNavigatorScreens.NFT.routeName, {
                                        screen: NTFNavigatorScreens.newNFT.routeName,
                                    })
                                }
                                iconName={"plus"}
                            ></FloatButton>
                        )}
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        statusMarketPlace: state.nfts.statusMarketPlace,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const NFTsMainScreen = connect(mapStateToProps, mapDispatchToProps)(_NFTsMainScreen);

export default NFTsMainScreen;

const styles = StyleSheet.create({});
