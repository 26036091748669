import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import React, { Component, PureComponent } from "react";
import { View, StyleSheet } from "react-native";
import i18n from "@i18n/i18n";
import NFT from "@custom-types/NFTModel";
import AvatarBase from "@components/avatar/AvatarBase";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import Icon from "@components/icons";
import Row from "@base/Row";
import BoldText from "@base/BoldText";
import { NavigationType } from "@custom-types/NavigationType";
import Card from "@base/Card";
import { SimpleLineIcons } from "@expo/vector-icons";
import { format } from "date-fns";
import { LinearGradient } from "expo-linear-gradient";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
const Pulse = require("react-native-pulse").default;

interface Props {
    navigation: NavigationType;
    fromCurrency: string;
    fromAmount: string;
    toCurrency: string;
    toAmount: string;
    status: string;
    createdAt: string;
}

interface State {
    fromCurrency: Currency;
    fromAmount: string;
    toCurrency: Currency;
    toAmount: string;
    status: string;
    createdAt: string;
}

const { t } = i18n;

export default class ExchangeHistoryCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const wallet = Wallet.getInstance();
        this.state = {
            status: this.props.status,
            fromCurrency: wallet.findCurrencyById(this.props.fromCurrency),
            fromAmount: this.props.fromAmount,
            toCurrency: wallet.findCurrencyById(this.props.toCurrency),
            toAmount: this.props.toAmount,
            createdAt: format(new Date(this.props.createdAt), "MMM dd, yyyy H:mma"),
        };
    }

    render() {
        return (
            <Card>
                <View
                    style={{
                        width: "100%",
                        flex: 1,
                        height: 3,
                        paddingHorizontal: 42,
                        position: "absolute",
                        marginTop: 41,
                        top: 0,
                    }}
                >
                    <LinearGradient
                        style={{
                            flex: 1,
                            height: 3,
                        }}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                        colors={[this.state.fromCurrency.getColor(), this.state.toCurrency.getColor()]}
                    ></LinearGradient>
                </View>
                <View
                    style={{
                        flexDirection: "row",
                        paddingTop: 10,
                        justifyContent: "space-between",
                        flex: 1,
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
                        <View style={{ alignItems: "flex-start" }}>
                            <CurrencyIcon
                                size={40}
                                iconSize={18}
                                currency={this.state.fromCurrency}
                                styles={{
                                    marginBottom: 5,
                                }}
                            />
                            <RegularText>{this.state.fromAmount}</RegularText>
                            <BoldText fontSize={10}>{this.state.fromCurrency.getPName()}</BoldText>
                        </View>
                    </View>
                    <View style={{ height: "100%", alignItems: "center" }}>
                        <View
                            style={{
                                position: "absolute",
                                width: 40,
                                height: 40,
                                backgroundColor: "white",
                                overflow: "hidden",
                                borderRadius: 20,
                                borderWidth: 3,
                                borderColor: colors.text,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {this.state.status == "finished" && (
                                <Icon color={colors.text} size={22} name="check"></Icon>
                            )}
                            {this.state.status != "finished" && (
                                <Pulse color={colors.text} numPulses={4} diameter={40} speed={50} duration={2000} />
                            )}
                        </View>
                        <View style={{ marginTop: 45, justifyContent: "center", flexDirection: "row" }}>
                            <BoldText>{this.state.status.toUpperCase()}</BoldText>
                        </View>
                        <RegularText fontSize={10}>{this.state.createdAt}</RegularText>
                    </View>
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-end" }}>
                        <View style={{ alignItems: "flex-end" }}>
                            <CurrencyIcon
                                size={40}
                                iconSize={18}
                                currency={this.state.toCurrency}
                                styles={{
                                    marginBottom: 5,
                                }}
                            />
                            <RegularText>{this.state.toAmount}</RegularText>
                            <BoldText fontSize={10}>{this.state.toCurrency.getPName()}</BoldText>
                        </View>
                    </View>
                </View>
            </Card>
        );
    }
}

const styles = StyleSheet.create({});
