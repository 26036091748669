import { ActivityModel, ActivityTypes } from "@custom-types/ActivityModel";
import ActivityCardAsset from "@screens/activity/components/ActivityCardAsset";
import ActivityCardDigitalCurrency from "@screens/activity/components/ActivityCardDigitalCurrency";
import ActivityCardDigitalCurrencyExchange from "@screens/activity/components/ActivityCardDigitalCurrencyExchange";
import ActivityCardMembership from "@screens/activity/components/ActivityCardMembership";
import ActivityCardOrderPurchase from "@screens/activity/components/ActivityCardOrderPurchase";
import ActivityCardRedeemable from "@screens/activity/components/ActivityCardRedeemable";
import React from "react";
import { View } from "react-native";

export function getActivityCard(activity: ActivityModel, navigation) {
    switch (activity.type) {
        case ActivityTypes.DigitalCurrencyTransaction:
            return <ActivityCardDigitalCurrency activity={activity} navigation={navigation} />;
        case ActivityTypes.DigitalCurrencyExchange:
            return <ActivityCardDigitalCurrencyExchange activity={activity} navigation={navigation} />;
        case ActivityTypes.MembershipClaim:
            return <ActivityCardMembership activity={activity} navigation={navigation} />;
        case ActivityTypes.AssetClaim:
            return <ActivityCardAsset activity={activity} navigation={navigation} />;
        case ActivityTypes.AssetTransfer:
            return <ActivityCardAsset activity={activity} navigation={navigation} />;
        case ActivityTypes.AssetDrop:
            return <ActivityCardAsset activity={activity} navigation={navigation} />;
        case ActivityTypes.RedeemableTransfer:
            return <ActivityCardRedeemable activity={activity} navigation={navigation} />;
        case ActivityTypes.RedeemableDrop:
            return <ActivityCardRedeemable activity={activity} navigation={navigation} />;
        case ActivityTypes.RedeemableUse:
            return <ActivityCardRedeemable activity={activity} navigation={navigation} />;
        case ActivityTypes.OrderPurchase:
            return <ActivityCardOrderPurchase activity={activity} navigation={navigation} />;
        default:
            return <View></View>;
    }
}
