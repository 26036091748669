import React, { Component } from "react"
import { Header } from "@components/header/Header"
import Container from "@base/Container"
import { HeaderType } from "@custom-types/HeaderType"
import SimpleCard from "@components/cards/SimpleCard"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import i18n from "@i18n/i18n"
import * as WebBrowser from "expo-web-browser"
import { WalletSettingsNavigatorScreens } from "@navigation/WalletSettingsNavigator"

interface Props {
    navigation: any
}

interface State {

}

const { t } = i18n

export default class WalletSettingsScreen extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
    }



    openLatamXO() {
        WebBrowser.openBrowserAsync("https://latamxo.com/", { showInRecents: true })
    }

    render() {
        return (
            <ScreenWrapper type='scroll'>
                <Header title={t("settings")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    <SimpleCard
                        title={t('network')}
                        icon='network'
                        onPress={() => {
                            this.props.navigation.navigate(WalletSettingsNavigatorScreens.Network.routeName)
                        }}
                    />
                </Container>
            </ScreenWrapper>
        )
    }
}
