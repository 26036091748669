import { colors } from "@styles/globalStyles"
import * as React from "react"
import Svg, { Defs, ClipPath, Path, G } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style, title */

const LatamXO = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    height={66.22}
    width={256.11}
    id="svg31"
    {...props}
  >
    <Defs id="defs7">
      <ClipPath id="clip-path">
        <Path
          style={{
            fill: "none",
          }}
          id="path4"
          d="m184.51 69.48-30.34 30.23h115.3l-30.05-30.23Z"
        />
      </ClipPath>
    </Defs>
    <G transform="translate(0 -22.82)" data-name="Capa 2" id="Capa_2">
      <G data-name="Capa 1" id="Capa_1-2">
        <Path
          style={{
            fill: colors.latamxo1,
            fillOpacity: 1,
          }}
          id="polygon11"
          className="cls-2"
          d="M172.52 23.41h8.03l11.65 14.53 11.71-14.53h7.92l-15.24 18.75 15.4 19.2h-7.97L192.2 46.83l-11.93 14.53h-7.86l15.4-19.09z"
        />
        <Path
          style={{
            fill: colors.latamxo1,
            fillOpacity: 1,
          }}
          id="path13"
          d="M 211.39 42.38 a 18.45 18.45 0 0 1 5.88 -13.91 q 5.88 -5.61 15.15 -5.61 t 15.15 5.61 a 18.45 18.45 0 0 1 5.88 13.91 a 18.47 18.47 0 0 1 -5.85 13.93 q -5.91 5.58 -15.18 5.58 t -15.15 -5.61 a 18.44 18.44 0 0 1 -5.88 -13.91 M 222.24 52 a 15.57 15.57 0 0 0 20.47 0 a 12.87 12.87 0 0 0 3.93 -9.62 a 12.88 12.88 0 0 0 -4 -9.62 a 15.41 15.41 0 0 0 -20.44 0 a 12.88 12.88 0 0 0 -4 9.62 a 12.64 12.64 0 0 0 4 9.6"
          className="cls-2"
        />
        <G
          style={{
            fill: colors.latamxo1,
            fillOpacity: 1,
            clipPath: "url(#clip-path)",
          }}
          id="g17"
          clipPath="url(#clip-path)"
        >
          {/* <Path
            style={{
              fill: colors.latamxo1,
              fillOpacity: 1,
            }}
            id="path15"
            d="M211.59 6.46a38.06 38.06 0 1 1-38.06 38.06 38.1 38.1 0 0 1 38.06-38.06m0-6.46a44.52 44.52 0 1 0 44.52 44.52A44.52 44.52 0 0 0 211.59 0"
          /> */}
        </G>
        <Path
          style={{
            fill: colors.latamxo2,
          }}
          id="polygon19"
          className="cls-5"
          d="M6.36 54.76h19.17v6.41H0V22.82h6.36z"
        />
        <Path
          style={{
            fill: colors.latamxo2,
          }}
          id="path21"
          d="M56.18 52.43h-15.3l-4.42 8.74h-7.13L48.5 22.82l19.18 38.35h-7.13ZM44 46.08h9l-4.5-8.95z"
          className="cls-5"
        />
        <Path
          style={{
            fill: colors.latamxo2,
          }}
          id="polygon23"
          className="cls-5"
          d="M93.42 29.23H80.65v31.94h-6.41V29.23H61.42v-6.41h32z"
        />
        <Path
          style={{
            fill: colors.latamxo2,
          }}
          id="path25"
          d="M113.43 52.43H98.71l-4.42 8.74h-7.14l19.18-38.35 19.18 38.35h-7.13zm-11.57-6.35h8.36l-3.89-8.95z"
          className="cls-5"
        />
        <Path
          style={{
            fill: colors.latamxo2,
          }}
          id="polygon27"
          className="cls-5"
          d="M129.31 61.17V22.82L148.48 42l19.23-19.18v38.35h-6.46V38.24l-12.77 12.82-12.82-12.82v22.93z"
        />
      </G>
    </G>
  </Svg>
)

export default LatamXO
