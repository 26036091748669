import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { NavigationType } from "@custom-types/NavigationType";
import RegularText from "@base/RegularText";
import Container from "@base/Container";
import Row from "@base/Row";
import BoldText from "@base/BoldText";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { connect } from "react-redux";
import store from "@store/index";
import { loading, ready, showModalBottom } from "@store/actions/global";
import AvatarBase from "@components/avatar/AvatarBase";

import i18n from "@i18n/i18n";
import { reloadPosts } from "@store/actions/social.actions";
import { ModuleControlService, Modules, NFTsModules } from "@core/services/ModuleControlService";
import RadiusButton from "@base/RadiusButton";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { ProfileSections } from "@custom-types/ProfileType";
import { UsersViewType } from "@custom-types/UsersViewType";
import ProfileService from "@core/services/ProfileService";
import { FollowerRequestType } from "@custom-types/FollowerRequestType";
import { Client } from "@custom-types/Client";
import Icon from "@components/icons";
import { getColorOpacity } from "@utils/helpers/global/global";
import ProfileQR from "./ProfileQR";
import CircleButton from "@base/CircleButton";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    client: Client;
    profiles: any;
    profile: ProfileData;
    postCounter: any;
    nftCounter: any;
    poapCounter: any;
    isOwner?: boolean;
    profileType?: ProfileSections;
    swithCallBack?: (profileType: ProfileSections) => void;
}

export interface ProfileData {
    _id: string;
    alias: string;
    avatar: {
        thumbnail: string;
        square: string;
    };
    followersCount: number;
    followedCount: number;
    followRequest?: FollowerRequestType;
    isFollower: boolean;
    verified: boolean;
    blocked: boolean;
    blockedBy: boolean;
    isPrivateProfile: boolean;
}

interface State {
    profileData: ProfileData;
}

const { t } = i18n;

export class _ProfileHeader extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.openUsers = this.openUsers.bind(this);
        this.onContact = this.onContact.bind(this);
        this.state = {
            profileData: this.props.profile,
        };
    }

    openUsers = (UsersViewType) => {
        this.props.navigation.navigate(ProfileNavigatorScreens.Users.routeName, {
            UsersViewType: UsersViewType,
            clientID: this.props.profile._id,
        });
    };

    unfollowUser = async () => {
        if (
            this.state.profileData.followRequest?.status == "PENDING" ||
            this.state.profileData.followRequest?.status == "REJECTED"
        ) {
            const resp = await SocialNetworkService.getInstance().cancelRequestFollow(
                this.state.profileData.followRequest?._id,
            );
            ProfileService.getInstance().setProfileData(this.state.profileData._id);
            return;
        }

        const unfollow = await SocialNetworkService.getInstance().unfollowUser(this.props.profile._id);
        if (unfollow == 204) {
            this.setState({
                profileData: {
                    ...this.state.profileData,
                    isFollower: !this.state.profileData.isFollower,
                },
            });
            ProfileService.getInstance().setFullProfile(this.state.profileData._id);
        }
        store.dispatch(reloadPosts(true));
    };

    onFollow = async () => {
        if (this.props.profile.isPrivateProfile) {
            const request_follow = await SocialNetworkService.getInstance().requestFollow(this.props.profile._id);
            if (request_follow) {
                let profile = this.state.profileData;
                profile.followRequest = request_follow;

                this.setState({ profileData: profile });
            }

            return;
        }
        this.setState({
            profileData: {
                ...this.state.profileData,
                isFollower: !this.state.profileData.isFollower,
            },
        });
        const follow = await SocialNetworkService.getInstance().followUser(this.props.profile._id);
        store.dispatch(reloadPosts(true));
    };

    onContact = async () => {
        store.dispatch(loading());
        var status = await SocialNetworkService.getInstance().contactUser(this.props.profile._id);
        if (status == "redirect") {
            this.props.navigation.navigate("Messages", {
                screen: "Chat",
            });
        }
        store.dispatch(ready());
    };

    openQR = () => {
        store.dispatch(
            showModalBottom({
                modalContent: <ProfileQR client={this.props.client} />,
            }),
        );
    };

    render() {
        return (
            <Container
                style={{
                    alignItems: "center",
                    marginBottom: 10,
                    paddingHorizontal: 5,
                    overflow: "hidden",
                }}
            >
                <View style={{ position: "relative" }}>
                    <AvatarBase
                        accesory={this.props.isOwner}
                        onPress={
                            this.props.isOwner
                                ? () => this.props.navigation.navigate(ProfileNavigatorScreens.ProfileEdit.routeName)
                                : () => ""
                        }
                        accesoryIconSize={22}
                        accesoryIiconName={"edit"}
                        uri={
                            this.props.isOwner
                                ? this.props.client?.profileImagePath?.square
                                : this.props.profile.avatar?.square || ""
                        }
                        alias={this.props.isOwner ? this.props.client?.alias : this.props.profile.alias}
                        size={110}
                    />

                    {this.props.isOwner && (
                        <View
                            style={{
                                position: "absolute",
                                justifyContent: "center",
                                zIndex: 100,
                                bottom: 0,
                                left: 0,
                            }}
                        >
                            <CircleButton
                                style={styles.icon}
                                iconSize={20}
                                iconColor={colors.floatButtonText}
                                icon="qr"
                                onPress={this.openQR}
                            ></CircleButton>
                        </View>
                    )}

                    {this.props.profile.isPrivateProfile && (
                        <View
                            style={{
                                position: "absolute",
                                top: 0,
                                right: -65,
                                backgroundColor: colors.complementary || colors.secondary,
                                paddingHorizontal: 8,
                                paddingVertical: 3,
                                borderRadius: 10,
                                borderBottomLeftRadius: 2,
                            }}
                        >
                            <RegularText
                                align="center"
                                color={colors.labelText}
                                style={{ textTransform: "uppercase" }}
                                fontSize={8}
                            >
                                {t("private_account")}
                            </RegularText>
                        </View>
                    )}
                </View>

                <View style={{ alignContent: "center", marginTop: 10 }}>
                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <RegularText fontSize={24} align={"center"}>
                            @
                            {trimHelper(
                                this.props.isOwner
                                    ? this.props.client?.alias
                                    : this.props.profiles[this.props.profile._id]?.profile?.alias,

                                20,
                            )}
                        </RegularText>
                        {this.props.profiles[this.props.profile._id]?.profile?.verified && (
                            <Icon
                                style={{ paddingLeft: 5, paddingBottom: 5 }}
                                size={18}
                                color={colors.complementary || colors.secondary}
                                name={"uniE924"}
                            />
                        )}
                    </View>

                    <View
                        style={{
                            flexDirection: "row",
                            marginBottom: 20,
                            height: 45,
                            marginTop: 15,
                            paddingHorizontal: 10,
                            justifyContent: "center",
                        }}
                    >
                        {ModuleControlService.getInstance().isNecessaryUserVerified(
                            this.state.profileData?.verified,
                        ) && (
                            <View style={styles.info}>
                                <BoldText fontSize={18} align={"center"}>
                                    {this.props.postCounter}
                                </BoldText>
                                <RegularText numberOfLines={1} fontSize={14} align={"center"} color={colors.grey}>
                                    POSTs
                                </RegularText>
                            </View>
                        )}
                        {ModuleControlService.getInstance().isNFTsModuleEnabled(NFTsModules.list) && (
                            <View style={{ width: 60 }}>
                                <BoldText fontSize={18} align={"center"}>
                                    {this.props.nftCounter}
                                </BoldText>
                                <RegularText numberOfLines={1} fontSize={14} align={"center"} color={colors.grey}>
                                    NFTs
                                </RegularText>
                            </View>
                        )}

                        {ModuleControlService.getInstance().isModuleEnabled(Modules.poapsModule) && (
                            <View style={{ width: 60 }}>
                                <BoldText fontSize={18} align={"center"}>
                                    {this.props.poapCounter}
                                </BoldText>
                                <RegularText numberOfLines={1} fontSize={14} align={"center"} color={colors.grey}>
                                    POAPs
                                </RegularText>
                            </View>
                        )}

                        {ModuleControlService.getInstance().isNecessaryUserVerified(
                            this.state.profileData?.verified,
                        ) && (
                            <PressableBase
                                style={{ width: 90 }}
                                onPress={() => this.openUsers(UsersViewType.FOLLOWERS)}
                            >
                                <BoldText fontSize={18} align={"center"}>
                                    {this.props.profile.followersCount}
                                </BoldText>
                                <RegularText numberOfLines={1} fontSize={14} align={"center"} color={colors.grey}>
                                    {t("followers")}
                                </RegularText>
                            </PressableBase>
                        )}

                        {ModuleControlService.getInstance().isNecessaryUserVerified(
                            this.state.profileData?.verified,
                        ) && (
                            <PressableBase
                                style={{ width: 90 }}
                                onPress={() => this.openUsers(UsersViewType.FOLLOWED)}
                            >
                                <BoldText fontSize={18} align={"center"}>
                                    {this.props.profile.followedCount}
                                </BoldText>
                                <RegularText numberOfLines={1} fontSize={14} align={"center"} color={colors.grey}>
                                    {t("following")}
                                </RegularText>
                            </PressableBase>
                        )}
                    </View>
                </View>

                {!this.props.isOwner && !this.props.profile.blocked && !this.props.profile.blockedBy && (
                    <Row
                        style={{
                            paddingVertical: 20,
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginHorizontal: 10,
                                justifyContent: "center",
                            }}
                        >
                            <RadiusButton
                                title={t("contact")}
                                boldText={true}
                                onPress={() => this.onContact()}
                                style={{ width: 150 }}
                                disableGradient={true}
                                border={true}
                            />
                        </View>

                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginHorizontal: 10,
                                justifyContent: "center",
                            }}
                        >
                            {!this.state.profileData?.followRequest ? (
                                <RadiusButton
                                    title={this.state.profileData.isFollower ? t("unfollow") : t("follow")}
                                    boldText={true}
                                    onPress={() =>
                                        this.state.profileData.isFollower ? this.unfollowUser() : this.onFollow()
                                    }
                                    style={{ width: 150 }}
                                    disableGradient={this.state.profileData.isFollower}
                                    border={this.state.profileData.isFollower}
                                />
                            ) : (
                                <RadiusButton
                                    title={
                                        this.state.profileData.followRequest.status == "PENDING"
                                            ? t("pending")
                                            : t("unfollow")
                                    }
                                    boldText={true}
                                    onPress={() => this.unfollowUser()}
                                    style={{ width: 150 }}
                                    disableGradient={this.state.profileData.isFollower}
                                    border={this.state.profileData.isFollower}
                                />
                            )}
                        </View>
                    </Row>
                )}

                {!this.state.profileData.blocked &&
                    !this.state.profileData.blockedBy &&
                    (this.state.profileData.isPrivateProfile
                        ? this.props.isOwner || this.state.profileData.isFollower
                        : true) && (
                        <Row style={{ justifyContent: "space-between", marginTop: 10, width: "100%" }}>
                            {ModuleControlService.getInstance().isNecessaryUserVerified(
                                this.state.profileData?.verified,
                            ) && (
                                <PressableBase
                                    onPress={() => this.props.swithCallBack(ProfileSections.socialNetwork)}
                                    style={{
                                        flex: 0.45,
                                        marginLeft: 10,
                                        paddingVertical: 4,
                                        borderBottomColor:
                                            this.props.profileType == ProfileSections.socialNetwork
                                                ? colors.white
                                                : colors.secondaryShadow,
                                        borderBottomWidth: 1,
                                        //opacity: this.props.profileType == ProfileType.socialNetwork ? 1 : 0.6,
                                    }}
                                >
                                    <RegularText numberOfLines={1} align="center">
                                        POSTs
                                    </RegularText>
                                </PressableBase>
                            )}

                            {ModuleControlService.getInstance().isNFTsModuleEnabled(NFTsModules.list) && (
                                <PressableBase
                                    onPress={() => this.props.swithCallBack(ProfileSections.nftProfile)}
                                    style={{
                                        flex: 0.45,
                                        marginRight: 10,
                                        paddingVertical: 4,
                                        borderBottomColor:
                                            this.props.profileType == ProfileSections.nftProfile
                                                ? colors.white
                                                : colors.secondaryShadow,
                                        borderBottomWidth: 1,
                                    }}
                                >
                                    <RegularText numberOfLines={1} align="center">
                                        NFTs
                                    </RegularText>
                                </PressableBase>
                            )}
                            {ModuleControlService.getInstance().isModuleEnabled(Modules.poapsModule) && (
                                <PressableBase
                                    onPress={() => this.props.swithCallBack(ProfileSections.poapsProfile)}
                                    style={{
                                        flex: 0.45,
                                        marginRight: 10,
                                        paddingVertical: 4,
                                        borderBottomColor:
                                            this.props.profileType == ProfileSections.poapsProfile
                                                ? colors.white
                                                : colors.secondaryShadow,
                                        borderBottomWidth: 1,
                                    }}
                                >
                                    <RegularText numberOfLines={1} align="center">
                                        POAPs
                                    </RegularText>
                                </PressableBase>
                            )}
                        </Row>
                    )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profiles: state.profile.profiles,
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ProfileHeader = connect(mapStateToProps, mapDispatchToProps)(_ProfileHeader);

export default ProfileHeader;

const styles = StyleSheet.create({
    buttons: {
        width: 50,
        height: 50,
        borderRadius: 10,
        alignSelf: "center",
        alignItems: "center",
        marginHorizontal: 10,
        paddingVertical: 12,
        borderWidth: 1,
        borderColor: colors.secondary,
    },

    follow: {
        width: 150,
        textAlignVertical: "center",
        alignSelf: "center",
        textAlign: "center",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: colors.secondary,
        marginHorizontal: 8,
        paddingVertical: 8,
        borderRadius: 25,
        overflow: "hidden",
    },
    info: {
        width: 60,
    },

    icon: {
        height: 36,
        width: 36,
        overflow: "hidden",
        borderRadius: 18,
        backgroundColor: getColorOpacity(colors.floatButtonBackground, 0.8),
        marginRight: 10,
        marginBottom: 0,
        zIndex: 100
    },
});
