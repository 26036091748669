import React, { Component } from "react";
import { StyleSheet, FlatList } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import OptionCard from "@components/cards/OptionCard";
import { KriptonService } from "@core/services/KriptonService";
import { KriptonMarketNavigatorScreens } from "@navigation/KriptonMarketNavigator";

interface Props {
  navigation: NavigationType;
  route: any
}

interface State {
  countries: Array<any>
  selectedCountry: string
}

const { t } = i18n

export default class SelectCountryScreen extends Component<Props, State> {
  private from = true;
  private kriptonService: KriptonService

  constructor(props: Props) {
    super(props);
    this.onPress = this.onPress.bind(this);
    this.state = {
      countries: [
        {name: "Argentina", alpha3: "ARG"},
        {name: "Colombia", alpha3: "COL"},
        {name: "Costa Rica", alpha3: "CRI"},
        {name: "El Salvador", alpha3: "SLV"},
      ],
      selectedCountry: this.props.route?.params?.country || 'ARG'
    };
    this.kriptonService = KriptonService.getInstance();
    //this.initCountries();
  }

  async initCountries() {
    const countries = await this.kriptonService.getCountries();
    this.setState({countries});
  }

  onPress = (country: string) => {
    this.setState({selectedCountry: country});
    this.props.navigation.navigate(KriptonMarketNavigatorScreens.AddressStreetKripton.routeName, {country: country});
  };

  renderItem = ({ item }) => (
    <OptionCard 
      title={item.name}
      onPress={() => this.onPress(item.alpha3)}
      selected={this.state.selectedCountry == item.alpha3}
      />
  );

  render() {
    return (
      <ScreenWrapper>
        <Header
          {...this.props}
          title={t ('select_country')}
          type={HeaderType.Light}
        />
        <Container style={styles.container}>
          {this.state.countries &&
            <FlatList
              data={this.state.countries}
              renderItem={this.renderItem}
              keyExtractor={(item) => item.alpha3}
              contentContainerStyle={{ paddingBottom: 20 }}
              initialNumToRender={20}
              maxToRenderPerBatch={10}
            />
          }
        </Container>
      </ScreenWrapper>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: -15,
    overflow: "hidden",
    padding: 40,
  },
});
