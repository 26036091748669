import React, {Component} from "react"
import {View, StyleSheet, ScrollView} from "react-native"
import {Header} from "@components/header/Header"
import Container from "@base/Container"
import {HeaderType} from "@custom-types/HeaderType"
import {NavigationType} from "@custom-types/NavigationType"
import { colors, settings } from "@styles/globalStyles"
import {connect} from "react-redux"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import InlineButton from "@base/InlineButton"
import i18n from "@i18n/i18n"
import RegularText from "@base/RegularText"
import {KriptonService} from "@core/services/KriptonService"
import store from "@store/index"
import {loading, ready, showPopup} from "@store/actions/global"
import Card from "@base/Card"
import BoldText from "@base/BoldText"
import {format} from "date-fns"
import DigitalDocuments from "./DigitalDocuments"
import InfoCard from "@base/InfoCard"
import BankCarousell from "./BanksCarousell"
import { KriptonMarketNavigatorScreens } from "@navigation/KriptonMarketNavigator"

interface Props {
    navigation: NavigationType
    route: any
    country: string
    currency: string
}

interface State {
    operation: any
    billing_img: any
    showMessage: boolean
    banks: Array<any>
}

const {t} = i18n

export class _OperationDetailScreen extends Component<Props, State> {
    private kriptonService: KriptonService
    constructor(props: Props) {
        super(props)
        this.state = {
            operation: null,
            billing_img: "",
            showMessage: false,
            banks: [],
        }
        this.kriptonService = KriptonService.getInstance()
        this.onSave = this.onSave.bind(this)
    }

    async componentDidMount() {
        store.dispatch(loading())
        const operationID = this.props.route?.params?.operation_id

        const operation = await this.kriptonService.getOperation(operationID)
        if (operation) {
            this.setState({operation: operation})

            const banks = await this.kriptonService.getBanksFromCountry(this.props.country, operation.currency_in)
            if (banks) {
                this.setState({banks: banks})
            }
        }
        store.dispatch(ready())
    }

    async onSave() {
        store.dispatch(loading())
        const operationID = this.props.route?.params?.operation_id

        const billing_digital_document = await this.kriptonService.newTiket(operationID, this.state.billing_img)
        store.dispatch(ready())
        if (billing_digital_document) {
            store.dispatch(showPopup({type: "SUCCESS"}))
            this.props.navigation.navigate(KriptonMarketNavigatorScreens.KriptonMarket.routeName)
        } else {
            store.dispatch(showPopup({type: "ERROR"}))
        }
    }

  

    render() {
        return (
            <ScreenWrapper>
                <Header title={"Operation Detail"} type={HeaderType.Light} {...this.props} />
                <ScrollView>
                    {this.state.operation && (
                        <Container>
                            {this.state.operation.url_voucher_image == null && this.state.operation.status == 'wait' && (
                                <View>
                                    <InfoCard message={t("wire_transfer_info_card")} />
                                
                                    <BankCarousell banks={this.state.banks}/>

                                   
                                    <View>
                                        <DigitalDocuments
                                            onPressCallBack={(img) => {
                                                this.setState({billing_img: img})
                                            }}
                                            subtitle={t("billing_image_description")}
                                        />
                                    </View>
                                </View>
                            )}

                            <Card>
                                <RegularText color={colors.text} align='center'>
                                    {t("status")}
                                </RegularText>
                                <BoldText color={colors.text} align='center'>
                                    {t(`${this.state.operation.status}`)}
                                </BoldText>
                            </Card>
                            <Card>
                                <RegularText color={colors.text} align='center'>
                                    {t("date")}
                                </RegularText>
                                <BoldText color={colors.text} align='center'>
                                    {format(new Date(this.state.operation.created_at), "MMM dd, yyyy H:mma")}{" "}
                                </BoldText>
                            </Card>
                            <Card>
                                <RegularText color={colors.text} align='center'>
                                    {t("amount_transfer")}
                                </RegularText>
                                <BoldText fixedWidth={300} color={colors.text} align='center' selectable={true}>
                                    ${this.state.operation.amount_in} ({this.state.operation.currency_in.toUpperCase()})
                                </BoldText>
                            </Card>
                            <Card>
                                <RegularText color={colors.text} align='center'>
                                    {t("amount_receive")}
                                </RegularText>
                                <BoldText fixedWidth={300} color={colors.text} align='center' selectable={true}>
                                    {this.state.operation.amount_out} ({this.state.operation.currency_out.toUpperCase()}
                                    )
                                </BoldText>
                            </Card>
                            {this.state.operation.url_voucher_image == null && (
                                <InlineButton
                                    style={{marginTop: 20, marginBottom: 20}}
                                    title={t("save")}
                                    onPress={this.onSave}></InlineButton>
                            )}
                        </Container>
                    )}
                </ScrollView>
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        //justifyContent: "space-between",
        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    inputContainer: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 20,
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        paddingHorizontal: 40,
        textAlign: "center",
    },
    bankAccountContainer: {
        backgroundColor: colors.shadow,
        padding: 10,
        borderRadius: settings.cardRadius,
    },
    bankAccountWrapper: {
        marginTop: 5,
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
    },
    button: {
        width: 40,
        marginHorizontal: 5,
        backgroundColor: colors.shadow,
        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
    },
})

const mapStateToProps = (state) => {
    return state.kriptonMarket
}

const mapDispatchToProps = (dispatch) => ({})

const OperationDetailScreen = connect(mapStateToProps, mapDispatchToProps)(_OperationDetailScreen)

export default OperationDetailScreen
