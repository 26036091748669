import axios from 'axios';
import { AuthService } from './AuthService';
import config from "../../../app.json";
import { format } from 'date-fns';
import { getEnv } from '@utils/helpers/global/global';
import { ApiService } from './ApiService';

const { expo } = config
export class SimplexService {
    private static instance: SimplexService;

    public static getInstance() {
        if (!SimplexService.instance) {
            SimplexService.instance = new SimplexService();
        }

        return SimplexService.instance;
    }

    getMin(fiatCurrency?: string) {
        return 50;
    }

    getMax(fiatCurrency?: string) {
        return 1500;
    }

    async getQuote(digital_currency, fiat_currency, requested_currency, amount) {
        const params = {
            digital_currency: digital_currency,
            fiat_currency: fiat_currency,
            requested_currency: requested_currency,
            requested_amount: amount,
            payment_methods: ["credit_card"]
        }
        const url = `${getEnv('API_URL')}simplex/quote`;
        const result = (await axios.post(url,
            { ...params },
            {
                headers: await ApiService.getAuthHeaders()
            }
        ))['data'];
        return result
    }

    async getPayment(id, address, currency) {
        const url = `${getEnv('API_URL')}simplex/quote/${id}/payment`;
        const result = (await axios.post(url,
            {
                appVersion: expo.version,
                currency: currency,
                address: address
            },
            {
                headers: await ApiService.getAuthHeaders()
            }
        ))['data'];
        const paymentId = result.payment.transactionDetails.paymentDetails.paymentId;
        return {
            url: `https://simplex.beexo.com?id=${paymentId}&v=${expo.version}`
        }
    }

    async getPurchaseOrders(currency?: string) {
        let url = `${getEnv('API_URL')}simplex/quote/client`;
        if (currency) {
            url = `${url}/${currency}`;
        }
        const result = (await axios.get(url,
            {
                headers: await ApiService.getAuthHeaders()
            }
        ))['data'];

        return result.map(data => {
            let state = 'UNKNOWN';
            let id = null;
            if (data.payment?.events?.length) {
                id = data.payment.events[data.payment.events.length - 1].payment.id;
                if (data.payment.events[data.payment.events.length - 1].name == 'payment_simplexcc_approved') {
                    state = 'DONE';
                }
                if (data.payment.events[data.payment.events.length - 1].name == 'payment_request_submitted') {
                    state = 'PENDING';
                }
            }
            let date = format(new Date(data.createdAt), 'MMM dd, yyyy H:mma');
            return {
                ...data,
                state,
                date,
                id
            }
        });
    }

    processData(data) {
        return data;
    }
}