import { StyleSheet, Appearance } from "react-native";

const lightColors = {
    primary: "#d8d9db",
    secondary: "#d2e7fc",
    complementary: "#79c2fe",
    tertiary: "#f7f7f7",
    background: "#fff",
    gradientFrom: "#fff",
    gradientTo: "#f7f7f7",
    grey: "#aaabad",
    //white: "white",
    white: "#0099ff",
    black: "#333",
    blue: "#2696fb",
    green: "#1DBC60",
    yellow: "#FECA54",
    red: "#EE4540",
    text: "#454656",
    shadow: "#edf3f6",
    secondaryShadow: "#f2f8fe",
    tertiaryShadow: "rgba(219, 234, 254, 0.5)",
    highlightedText: "#363853",
    networkSymbol: "#fff",
    labelText: "white",
    latamxo1: "#ceafd6",
    latamxo2: "#333",
    floatButtonText: "white",
};

const darkColors = lightColors;

const settings = {
    cardRadius: 16,
    shadow: {
        shadowOpacity: 0.08,
        shadowRadius: 10,
    },
};

export { lightColors, darkColors, settings };
