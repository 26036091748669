import React, { Component } from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import Row from "@base/Row";

interface Props {
    amount?: string;
    title?: string;
    color?: string;
    symbol?: string;
    subtitle?: string;
    testnet?: boolean;
    style?: StyleSheet;
    fiatCurrency?: FiatCurrency;
    syncing?: boolean;
}

interface State {
    title: string;
    color: string;
}

export default class Balance extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            title: props.title,
            color: props.color ? props.color : colors.balanceColor,
        };
    }

    componentDidUpdate(props) {
        if (props != this.props) {
            this.setState({
                title: this.props.title,
            });
        }
    }

    render() {
        return (
            <View style={styles.container}>
                {this.props.testnet && (
                    <View
                        style={{
                            marginTop: -5,
                            alignSelf: "center",
                            paddingVertical: 2,
                            paddingHorizontal: 5,
                            backgroundColor: colors.secondary,
                            borderRadius: 5,
                        }}
                    >
                        <BoldText color={colors.white} align="center" fontSize={10}>
                            TESTNET
                        </BoldText>
                    </View>
                )}

                <View style={[styles.center, { opacity: this.props.syncing ? 0.5 : 1 }]}>
                    {this.props.fiatCurrency && (
                        <CurrencyIcon currency={this.props.fiatCurrency} size={35}></CurrencyIcon>
                    )}
                    <RegularText style={{ alignSelf: "flex-end", paddingBottom: 8, paddingRight: 2 }} fontSize={16}>
                        {"  "}
                        {this.props.fiatCurrency.getSign()}
                    </RegularText>
                    <BoldText align="center" fontSize={40} color={this.state.color}>
                        {this.props.amount}{" "}
                        {this.props.fiatCurrency && <RegularText>{this.props.fiatCurrency.getSymbol()}</RegularText>}
                    </BoldText>
                </View>
                <Row style={{ justifyContent: "center" }}>
                    <RegularText
                        style={{ opacity: this.props.syncing ? 0.5 : 1 }}
                        align="center"
                        fontSize={16}
                        color={colors.text}
                    >
                        {" "}
                        {this.state.title}{" "}
                    </RegularText>
                    {this.props.syncing && (
                        <ActivityIndicator style={{ paddingLeft: 5 }} size={20} color={colors.text} />
                    )}
                </Row>
                {this.props.subtitle && (
                    <RegularText align="center" fontSize={12} color={colors.text}>
                        {" "}
                        {this.props.subtitle}{" "}
                    </RegularText>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        marginTop: 10,
        marginBottom: 20,
        alignItems: "center",
    },
    center: {
        flexDirection: "row",

        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
    },
});
