const nunitoBold = require("./PPMonumentExtended-Black.otf")
const nunitoLight = require("./PPMonumentExtended-Light.otf")
const nunitoRegular = require("./PPMonumentExtended-Regular.otf")
const nunitoSemiBold = require("./PPMonumentExtended-Regular.otf")

export const Fonts = {
    bold: nunitoBold,
    light: nunitoLight,
    regular: nunitoRegular,
    semiBold: nunitoSemiBold
}
