import { MainNavigator } from "./MainNavigator";
import NavigationInstantiator from "./helpers/NavigationInstantiator";
import { LoginNavigator } from "./LoginNavigator";
import InitializerScreen from "../screens/initializer/InitializerScreen";
import ControlServiceScreen from "@screens/initializer/ControlServiceScreen";
import { ForceUpdateNavigator } from "./ForceUpdateNavigator";

const AuthNavigatorScreens = {
    Initializer: {
        routeName: "Initializer",
        title: "Initializer",
        component: InitializerScreen,
    },

    Login: {
        routeName: "Login",
        title: "Login",
        component: LoginNavigator,
    },

    ForceUpdate: {
        routeName: "ForceUpdate",
        title: "ForceUpdate",
        component: ForceUpdateNavigator,
    },

    Main: {
        routeName: "Main",
        title: "Main",
        component: MainNavigator,
    },

    ControlService: {
        routeName: "ControlService",
        title: "ControlService",
        component: ControlServiceScreen,
    },
};

const AuthNavigator = NavigationInstantiator(AuthNavigatorScreens);

export { AuthNavigator, AuthNavigatorScreens };
