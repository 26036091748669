import React, { Component } from "react";
import { connect } from "react-redux";
import { Contact } from "@custom-types/Contact";
import { Chat } from "@custom-types/Chat";
import { ClientService } from "@core/services/ClientService";
import { selectChatsAsArray, selectOrderedAndFilteredChats } from "@store/reducers/chat.reducer";
import SearchBase from "@components/search/SearchBase";
import debounce from "lodash.debounce";

interface Props {
    contacts: Array<Contact>;
    chats;
    setList;
    // setChats: any;
    // setContacts: any;
    // setAliasClient: any;
    clearSearch: boolean;
}

interface State {
    search: string;
    syncing: boolean;
}

export class _SearchBarChat extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.filter = this.filter.bind(this);
        this.filterChats = this.filterChats.bind(this);
        this.filter = debounce(this.filter, 400);

        this.state = {
            search: "",
            syncing: false,
        };
    }

    componentDidUpdate(prevProps): void {
        if (prevProps.clearSearch !== this.props.clearSearch && this.props.clearSearch) {
            this.onSearch("");
        }
    }

    onSearch = async (search) => {
        this.setState({ search: search, syncing: true }, () => {
            this.filter();
        });
    };

    async filter() {
        try {
            const contacts = this.filterContacts(this.state.search);
            const chats = await this.filterChats(this.state.search);
            const aliasClients = await this.filterAlias(this.state.search);
            this.props.setList({ chats: chats, contacts: contacts, aliasClients: aliasClients });
            this.setState({ syncing: false });
        } catch (e) {
            this.setState({ syncing: false });
        }
    }

    filterContacts(value) {
        const chats = this.props.chats.filter((c: Chat) => c.messages && c.messages.length > 0);
        let contactsFilter = [];

        if (value) {
            const chatsIdTo = chats.map((c) => c.to._id);
            contactsFilter = this.props.contacts.filter((c) => {
                return c.name.toLowerCase().includes(value.toLowerCase()) && !chatsIdTo.includes(c.client._id);
            });
        }

        // this.props.setContacts(contactsFilter);
        return contactsFilter;
    }

    async filterChats(value): Promise<unknown> {
        return new Promise((resolve) => {
            let chatFilter = this.props.chats;

            if (value) {
                const contactsIds = this.props.contacts.map((c) => c.client._id);
                const contactsIdFilter = this.props.contacts
                    .filter((c) => c.name.toLowerCase().includes(value.toLowerCase()))
                    .map((c) => c.client._id);

                const chatContactFilter = this.props.chats.filter((c) => contactsIdFilter.includes(c.to._id));
                const chatNotContactFilter = this.props.chats.filter(
                    (c) => c.to.alias.toLowerCase().includes(value.toLowerCase()) && !contactsIds.includes(c.to._id)
                );

                chatFilter = [...chatContactFilter, ...chatNotContactFilter];
            }

            chatFilter = chatFilter.filter((c) => c.messages && c.messages.length > 0);

            resolve(chatFilter);
        });
    }

    async filterAlias(value) {
        let aliasClients = [];
        if (value) {
            aliasClients = await ClientService.getInstance().all(value);
        }

        return aliasClients;
    }

    render() {
        return (
            <SearchBase
                containerStyle={{ marginBottom: 15 }}
                value={this.state.search}
                searching={this.state.syncing}
                onSearch={(value) => this.onSearch(value)}
            ></SearchBase>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        chats: selectOrderedAndFilteredChats(state),
        contacts: state.chat.contacts,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SearchBarChat = connect(mapStateToProps, mapDispatchToProps)(_SearchBarChat);

export default SearchBarChat;
