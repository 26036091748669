import ForceUpdateScreen from "@screens/updates/ForceUpdateScreen";
import NavigationInstantiator from "./helpers/NavigationInstantiator";
import { SeedNavigator } from "./SeedNavigator";

const ForceUpdateNavigatorScreens = {
    ForceUpdateScreen: {
        routeName: "ForceUpdateScreen",
        title: "ForceUpdateScreen",
        component: ForceUpdateScreen,
    },
    Accounts: {
        routeName: "Accounts",
        title: "Accounts",
        component: SeedNavigator,
    },
};
const ForceUpdateNavigator = NavigationInstantiator(ForceUpdateNavigatorScreens);

export { ForceUpdateNavigator, ForceUpdateNavigatorScreens };
