import SelectProviderScreen from "@screens/wallet/buy/SelectProviderScreen"
import { KriptonMarketNavigator } from "./KriptonMarketNavigator"
import { SimplexNavigator } from "./SimplexNavigator"
import NavigationInstantiator from "./helpers/NavigationInstantiator"
import OnramperScreen from "@screens/wallet/buy/onramper/OnramperScreen"
import { CoincaexNavigator } from "./CoincaexNavigator"

const BuyNavigatorScreens = {
    SelectProviderBuy: {
        routeName: "SelectProviderBuy",
        title: "Select Provider",
        component: SelectProviderScreen,
    },
    KriptonMarket: {
        routeName: "KriptonMarket",
        title: "Kripton Market",
        component: KriptonMarketNavigator,
    },
    Simplex: {
        routeName: "Simplex",
        title: "Simplex",
        component: SimplexNavigator,
    },
    Onramper: {
        routeName: "Onramper",
        title: "Onramper",
        component: OnramperScreen,
    },
    Coincaex: {
        routeName: "Coincaex",
        title: "Coincaex",
        component: CoincaexNavigator,
    }
}
const BuyNavigator = NavigationInstantiator(BuyNavigatorScreens)

export { BuyNavigator, BuyNavigatorScreens }
