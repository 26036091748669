import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { colors } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";

import Container from "@base/Container";

import WebViewBase from "@components/webview/WebViewBase";
import { Route } from "@react-navigation/native";
import { getPOAP, isPOAP } from "@screens/scanner/implementations/POAPImplementation";

interface Props {
    navigation: NavigationType;
    route: Route<string, { url: string }>;
}

interface State {
    kioskURL: string;
}

const { t } = i18n;

export default class WebViewPOAPScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onNavigationStateChange = this.onNavigationStateChange.bind(this);
        this.state = {
            kioskURL: this.props?.route?.params?.url || "",
        };
    }

    async onNavigationStateChange(url) {
        const decode = url?.split("/") || [];

        if (decode[2] !== "kiosk.poap.xyz") {
            await this.props.navigation.goBack();
          
            getPOAP(this.props.navigation, url);
        }
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <Container
                    style={{
                        flex: 1,
                        paddingVertical: 20,
                    }}
                >
                    <View style={{ flex: 1, borderRadius: 20, overflow: "hidden" }}>
                        <WebViewBase
                            source={this.state.kioskURL}
                            onMessage={() => null}
                            onNavigationStateChange={this.onNavigationStateChange}
                            style={{ flex: 1, zIndex: 99, paddingHorizontal: 20 }}
                        ></WebViewBase>
                    </View>
                    <View
                        style={{
                            position: "absolute",
                            bottom: 40,
                            width: "100%",
                            paddingHorizontal: 20,
                            marginHorizontal: 20,
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <InlineButton
                            title={t("close")}
                            onPress={() => this.props.navigation.goBack()}
                            textColor={colors.text}
                            style={{ backgroundColor: colors.secondary, width: 120 }}
                        />
                    </View>
                </Container>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        backgroundColor: colors.background,
        padding: 0,
        paddingHorizontal: 0,
        paddingLeft: 0,
        paddingStart: 0,
        marginHorizontal: 0,
        marginLeft: 0,
        marginStart: 0,
    },
});
