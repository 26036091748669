import React from "react";
import { Footer } from "@components/footer";
import { INagvigator, INav } from "@navigation/INavigator";
import NavigatorOptions from "@navigation/NavigatorOptions";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { ModuleControlService } from "@core/services/ModuleControlService";

const Tab = createBottomTabNavigator();

function TabInstantiator(navigator: INagvigator) {
    return () => (
        <Tab.Navigator
            tabBar={(props) => <Footer {...props} />}
            //initialRouteName={ModuleControlService.getInstance().getDefaultScreenCompatibility() || ""}
        >
            {Object.keys(navigator).map((key: string) => {
                const nav: INav = navigator[key];
                return (
                    <Tab.Screen
                        key={key}
                        name={key}
                        component={nav.component}
                        options={{ ...NavigatorOptions, title: nav.title }}
                    />
                );
            })}
        </Tab.Navigator>
    );
}

export default TabInstantiator;
