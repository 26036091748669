import NavigationInstantiator from "./helpers/NavigationInstantiator";
import WelcomeScreen from "@screens/login/WelcomeScreen";
import IncognitoScreen from "@screens/login/IncognitoScreen";
import ImportSeedExtendedScreen from "@screens/login/import/ImportSeedExtendedScreen";
import SeedScannerExtendedScreen from "@screens/login/scanner/SeedScannerExtendedScreen";
import TermsScreen from "@screens/login/TermsScreen";
import NewSeedScreen from "@screens/login/NewSeedScreen";
import UserOAuthScreen from "@screens/login/UserOAuthScreen";
import SetProfileScreen from "@screens/login/SetProfileScreen";

const LoginNavigatorScreens = {
    
    Welcome: {
        routeName: "Welcome",
        title: "Welcome",
        component: WelcomeScreen,
    },
    ImportSeed: {
        routeName: "ImportSeed",
        title: "ImportSeed",
        component: ImportSeedExtendedScreen,
    },

    UserOAuth: {
        routeName: "UserOAuth",
        title: "UserOAuth",
        component: UserOAuthScreen,
    },

    NewSeed: {
        routeName: "NewSeed",
        title: "NewSeed",
        component: NewSeedScreen,
    },
    Incognito: {
        routeName: "Incognito",
        title: "Incognito",
        component: IncognitoScreen,
    },

    SeedScanner: {
        routeName: "SeedScanner",
        title: "SeedScanner",
        component: SeedScannerExtendedScreen,
    },

    SetProfile: {
        routeName: "SetProfile",
        title: "SetProfile",
        component: SetProfileScreen,
    },
    
    Terms: {
        routeName: "Terms",
        title: "Terms",
        component: TermsScreen,
    },
};
const LoginNavigator = NavigationInstantiator(LoginNavigatorScreens);

export { LoginNavigator, LoginNavigatorScreens };
