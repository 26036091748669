import Row from "@base/Row";
import Icon from "@components/icons";
import { hideModalBottom } from "@store/actions/global";
import store from "@store/index";
import { colors, settings } from "@styles/globalStyles";
import React, { Component } from "react";
import { Platform, ScrollView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import Modal from "react-native-modal";
import { ModalBottomOptions } from "./ModalOptions";
import CircleButton from "@base/CircleButton";
import { VibrationType, vibrate } from "@utils/vibration/vibration.helper";
import { getColorOpacity } from "@utils/helpers/global/global";

interface Props {
    showModalBottom: boolean;
    popupMessage: PopupMessageOptions;
    modalOptions: ModalBottomOptions;
    onPressClose?: () => void;
}

interface State {
    scrollOffset: null | number;
}

export class _ModalBottom extends Component<Props, State> {
    public scrollViewRef: React.RefObject<ScrollView>;

    constructor(props: Props) {
        super(props);

        this.state = {
            scrollOffset: null,
        };
        this.scrollViewRef = React.createRef();
    }

    handleOnScroll = (event) => {
        this.setState({
            scrollOffset: event.nativeEvent.contentOffset.y,
        });
    };

    handleScrollTo = (p) => {
        if (this.scrollViewRef.current) {
            this.scrollViewRef.current.scrollTo(p);
        }
    };

    onPressClose = () => {
        if (this.props.modalOptions?.onPressClose) {
            this.props.modalOptions?.onPressClose();
        }
        store.dispatch(hideModalBottom());
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (
            prevProps.showModalBottom !== this.props.showModalBottom &&
            this.props.showModalBottom &&
            !this.props.popupMessage
        ) {
            vibrate(VibrationType.ONPRESS);
        }
    }

    render() {
        return (
            <Modal
                testID={"modal"}
                isVisible={this.props.showModalBottom && !this.props.popupMessage}
                onSwipeComplete={this.onPressClose}
                onBackdropPress={this.onPressClose}
                swipeDirection={["down"]}
                scrollTo={this.handleScrollTo}
                scrollOffset={this.state.scrollOffset}
                scrollOffsetMax={400 - 300}
                propagateSwipe={true}
                style={styles.modal}
            >
                <View style={styles.scrollableModal}>
                    <ScrollView
                        ref={this.scrollViewRef}
                        showsVerticalScrollIndicator={false}
                        onScroll={this.handleOnScroll}
                        scrollEventThrottle={16}
                    >
                        <View style={styles.modalView}>
                            <Row style={styles.headerContainer}>
                                {Platform.OS == "web" ? (
                                    <CircleButton
                                        style={{
                                            height: 24,
                                            width: 24,
                                            backgroundColor: getColorOpacity(colors.floatButtonBackground, 0.8),
                                            marginBottom: 5,
                                        }}
                                        iconColor={colors.labelText}
                                        icon={"x-lg"}
                                        iconSize={14}
                                        onPress={this.onPressClose}
                                    ></CircleButton>
                                ) : (
                                    <Icon style={{ position: "absolute" }} name="dash" size={80} color={colors.grey} />
                                )}
                            </Row>
                            {this.props.modalOptions?.modalContent}
                        </View>
                    </ScrollView>
                </View>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    modal: {
        justifyContent: "flex-end",
        margin: 0,
        width: "100%",
    },
    scrollableModal: {
        maxHeight: "90%",
    },
    modalView: {
        alignSelf: "center",
        backgroundColor: colors.tertiary,
        borderTopRightRadius: settings.cardRadius,
        borderTopLeftRadius: settings.cardRadius,
        paddingHorizontal: 20,
        paddingVertical: 20,
        shadowColor: "#000",
        width: "100%",
        maxWidth: settings.maxWidth,
        justifyContent: "center",
        alignContent: "center",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    headerContainer: {
        justifyContent: Platform.OS == "web" ? "flex-end" : "center",
        marginBottom: Platform.OS == "web" ? 10 : 40,
        alignItems: "center",
        width: "100%",
    },
    close: {
        height: 35,
        width: 35,
        alignItems: "center",
        justifyContent: "center",
    },
});

const mapStateToProps = (state) => {
    return {
        showModalBottom: state.global.showModalBottom,
        modalOptions: state.global.modalBottomOptions,
        popupMessage: state.global.popupMessage,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ModalBottom = connect(mapStateToProps, mapDispatchToProps)(_ModalBottom);

export default ModalBottom;
