import NFT from "@custom-types/NFTModel";
import POAP from "@custom-types/POAP";
import Post from "@custom-types/Post";
import { ProfileData } from "@screens/profile2.0/components/ProfileHeader";
import { RELOAD } from "@store/actions/global";
import { SET_NFTs, SET_POAPs, SET_POSTs, SET_PROFILE, UPDATE_POST } from "@store/actions/profile.actions";

interface profileState {
    profile: ProfileData;
    profiles: Array<{
        id: {
            profile: ProfileData;
            POSTs: {
                page: number;
                totalDocs: number;
                totalPages: number;
                posts: Array<Post>;
            };
            NFTs: Array<NFT>;
            POAPs: Array<POAP>;
        };
    }>;
    POSTs: {
        page: number;
        totalDocs: number;
        totalPages: number;
        posts: Array<Post>;
    };
    NFTs: Array<NFT>;
    POAPs: Array<POAP>;
}

const initialState: profileState = {
    profiles: [],
    profile: null,
    POSTs: {
        page: 1,
        totalDocs: 0,
        totalPages: 0,
        posts: [],
    },
    NFTs: [],
    POAPs: [],
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE:
            return {
                ...state,
                profile: action.profile,
                profiles: {
                    ...state.profiles,
                    [action.profile._id]: {
                        ...state.profiles[action.profile._id],
                        profile: action.profile,
                    },
                },
            };
        case SET_POSTs:
            return {
                ...state,

                profiles: {
                    ...state.profiles,
                    [action.data.id]: {
                        ...state.profiles[action.data.id],
                        POSTs: action.data.dataPOSTs,
                    },
                },
            };
        case SET_NFTs:
            return {
                ...state,

                profiles: {
                    ...state.profiles,
                    [action.data.id]: {
                        ...state.profiles[action.data.id],
                        NFTs: action.data.dataNFTs,
                    },
                },
            };

        case SET_POAPs:
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    [action.data.id]: {
                        ...state.profiles[action.data.id],
                        POAPs: action.data.dataPOAPs,
                    },
                },

            };

        case RELOAD:
            return {
                ...initialState,
            };

        case UPDATE_POST:
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    [action.data.idClient]: {
                        ...state.profiles[action.data.idClient],
                        POSTs: {
                            ...state.profiles[action.data.idClient]['POSTs'],
                            posts: [...action.data.posts]
                        },
                    },
                },
            };

        default:
            return state;
    }
};

export default profileReducer;
