import React, { Component } from "react";
import { View, Text, StyleSheet, Button, Dimensions } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { colors } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import BoldText from "@base/BoldText";
import Card from "@base/Card";
import store from "@store/index";
import { loading, ready } from "@store/actions/global";
import Wallet from "@core/wallet/Wallet";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import InlineButton from "@base/InlineButton";
import * as WebBrowser from 'expo-web-browser';
import { SimplexService } from "@core/services/SimplexService";
import { SimplexNavigatorScreens } from "@navigation/SimplexNavigator";

interface Props {
  navigation: NavigationType;
  currencies: Array<Currency>;
  selectedCurrency: string;
  fiatCurrency: FiatCurrency;
  currentOperation: any;
}

interface State {

}

export class _ConfirmBuyScreen extends Component<Props, State> {
  private currency: Currency;
  private fiatCurrency: FiatCurrency;
  private simplexService: SimplexService;

  constructor(props: Props) {
    super(props);
    this.currency = Wallet.getInstance().findCurrencyById(
      props.selectedCurrency
    );
    this.fiatCurrency = props.fiatCurrency;
    this.simplexService = SimplexService.getInstance();
    this.onPressNext = this.onPressNext.bind(this);
  }

  onPressNext = async () => {
    store.dispatch(loading());
    const {url} = await this.simplexService.getPayment(
      this.props.currentOperation.data._id, 
      this.currency.getAddress(),
      this.currency.getName()
    )
    await WebBrowser.openBrowserAsync(url, {showInRecents:true});
    store.dispatch(ready());
    this.props.navigation.navigate(SimplexNavigatorScreens.SimplexMain.routeName);
  }

  render() {
    return (
      <ScreenWrapper>
        <Header
          title={"Checkout"}
          type={HeaderType.Light}
          {...this.props}
        />
        <Container style={styles.container}>
          {this.props.currentOperation.type == 'BUY' && (
          <View style={{flex: 1, justifyContent: "space-between"}}>
            <View>
              <Card style={styles.card}>
                <RegularText color={colors.text} align="center">
                  You will pay
                </RegularText>
                <BoldText color={colors.text} align="center">
                {this.props.currentOperation.data.fiatMoney.totalAmount} {this.props.currentOperation.data.fiatMoney.currency}
                </BoldText>
              </Card>
              <Card style={styles.card}>
                <RegularText color={colors.text} align="center">
                  You will buy
                </RegularText>
                <BoldText color={colors.text} align="center">
                {this.props.currentOperation.data.digitalMoney.amount} {this.props.currentOperation.data.digitalMoney.currency}
                </BoldText>
              </Card>
              <Card style={styles.card}>
                <RegularText color={colors.text} align="center">
                  To address
                </RegularText>
                <BoldText color={colors.text} align="center" fontSize={12}>
                  {this.currency.getAddress()}
                </BoldText>
              </Card>
              <Card style={styles.card}>
                <RegularText color={colors.text} align="center">
                  Payment method
                </RegularText>
                <BoldText color={colors.text} align="center">
                  Credit card
                </BoldText>
              </Card>
            </View>
            <View style={{}}>
              <InlineButton
                  title='Next'
                  onPress={this.onPressNext}
                  style={{marginHorizontal: 0}}
              />
            </View>
          </View>
          )}
        </Container>
      </ScreenWrapper>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingVertical: 20,
  },
  buttons: {
    marginVertical: 15,
  },
  card: {},
});

const mapStateToProps = (state) => {
  return {
    currencies: state.wallet.currencies,
    selectedCurrency: state.wallet.selectedCurrency,
    fiatCurrency: state.wallet.fiatCurrency,
    currentOperation: state.wallet.currentOperation,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const ConfirmBuyScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ConfirmBuyScreen);

export default ConfirmBuyScreen;
