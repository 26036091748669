import AppStorage from "@utils/storage";
import * as Localization from "expo-localization";
// import * as Location from "expo-location";

export default class LocationService {
    private static instance;
    private lang = "";
    private timezone = "";

    private constructor() {
        this.setLocation();
    }

    public static getInstance(): LocationService {
        if (!LocationService.instance) {
            LocationService.instance = new LocationService();
        }

        return LocationService.instance;
    }

    async setLocation() {
        try {
            const userLanguage = await AppStorage.getItem("language");
            const l = {
                language: userLanguage || Localization.locale.substring(0, 2),
                timeZone: Localization.getCalendars()?.[0]?.timeZone,
            };
            this.lang = l?.language;
            this.timezone = l?.timeZone;
        } catch (e) {}
    }

    getLang() {
        return this.lang;
    }
    getTimeZone() {
        return this.timezone;
    }
}
