import Container from "@base/Container";
import FloatingTextInput from "@base/FloatingTextInput";
import InlineButton from "@base/InlineButton";
import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import AvatarBase from "@components/avatar/AvatarBase";
import { Header } from "@components/header/Header";
import Icon from "@components/icons";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import ProfileService from "@core/services/ProfileService";
import Wallet from "@core/wallet/Wallet";
import { Client } from "@custom-types/Client";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { loading, ready, showPopupMessage, showSnackbar } from "@store/actions/global";
import store from "@store/index";
import { colors, settings } from "@styles/globalStyles";
import { getColorOpacity } from "@utils/helpers/global/global";
import { Camera } from "expo-camera";
import * as ImageManipulator from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import React, { Component } from "react";
import { Platform, ScrollView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    route: any;
    client: Client;
}

interface State {
    alias: string;
    initial: string;
    avatar: string;
    options: boolean;
    email: string;
    name: string;
    lastname: string;
    phone: string;
}

const { t } = i18n;

export class _ProfileEditScreen extends Component<Props, State> {
    protected wallet: Wallet;

    constructor(props: Props) {
        super(props);
        this.wallet = Wallet.getInstance();
        this.onPress = this.onPress.bind(this);
        this.state = {
            alias: "",
            email: "",
            name: "",
            lastname: "",
            phone: "",
            initial: "NN",
            avatar: "",
            options: true,
        };
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            alias: this.props.client?.alias,
            initial: this.props.client?.alias[0],
            email: this.props.client?.email || this.props.client?.personalData?.email || "",
            name: this.props.client?.personalData?.name || "",
            lastname: this.props.client?.personalData?.lastname || "",
            phone: this.props.client?.personalData?.phone || "",
            avatar: this.props.client?.profileImagePath ? this.props.client?.profileImagePath.square : "",
        });
    }

    async requestPermission() {
        await Camera.requestCameraPermissionsAsync();
        const permission = await Camera.getCameraPermissionsAsync();
        await ImagePicker.requestMediaLibraryPermissionsAsync();

        if (permission?.status !== "granted" && permission.canAskAgain) {
            store.dispatch(showPopupMessage({ type: "ERROR", message: t("camera_permission") }));
            await Camera.requestCameraPermissionsAsync();
        }

        if (permission?.status !== "granted" && !permission.canAskAgain) {
            store.dispatch(showPopupMessage({ type: "ERROR", message: t("camera_permission_null") }));
        }
    }

    selectImage = async (camera?: boolean) => {
        this.requestPermission();

        let pickerResult: any = camera
            ? await ImagePicker.launchCameraAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.Images,
                  allowsEditing: true,
                  base64: false,
                  aspect: [4, 4],
                  quality: 1,
              })
            : await ImagePicker.launchImageLibraryAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.Images,
                  allowsEditing: true,
                  base64: false,
                  aspect: [4, 4],
                  quality: 1,
              });

        const resizeResult = await ImageManipulator.manipulateAsync(
            pickerResult?.assets[0]?.uri,
            [
                {
                    resize:
                        pickerResult?.assets[0]?.width < pickerResult?.assets[0]?.height
                            ? { width: 480 }
                            : { height: 480 },
                },
            ],
            {
                compress: 0.8,
                format: ImageManipulator.SaveFormat.JPEG,
            }
        );

        const cropResult = await ImageManipulator.manipulateAsync(resizeResult.uri, [
            {
                crop: {
                    height: 480,
                    originX: resizeResult.width !== 480 ? (resizeResult.width - 480) / 2 : 0,
                    originY: resizeResult.height !== 480 ? (resizeResult.width - 480) / 2 : 0,
                    width: 480,
                },
            },
        ]);

        this.setState({ avatar: cropResult.uri });

        await this.saveProfileImage(cropResult);
    };

    async saveProfileImage(image) {
        try {
            store.dispatch(loading());

            await ProfileService.getInstance().setProfileImage({
                image: image,
            });
            this.setState({ options: false });
            store.dispatch(showSnackbar({ type: "SUCCESS", message: t("saved_succesfuly") }));

            store.dispatch(ready());
        } catch (error: any) {
            store.dispatch(ready());
            store.dispatch(showSnackbar({ type: "ERROR", message: error.response?.data?.message || t("error") }));
        }
    }

    validate(): boolean {
        if (this.state.email.length > 0 && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email)) {
            this.setState({
                ...this.state,
                email: "",
            });
            store.dispatch(showSnackbar({ type: "ERROR", message: t("error_email") }));

            return false;
        }

        if (this.state.name.length > 0) {
            if (this.state.name.length > 29) {
                this.setState({
                    ...this.state,
                    name: "",
                });
                store.dispatch(showSnackbar({ type: "ERROR", message: t("error_name") }));

                return false;
            }
        }

        if (this.state.lastname.length > 0) {
            if (this.state.lastname.length > 29) {
                this.setState({
                    ...this.state,
                    lastname: "",
                });
                store.dispatch(showSnackbar({ type: "ERROR", message: t("error_lastname") }));

                return false;
            }
        }

        if (this.state.phone.length > 0 && (!/^-?\d+$/.test(this.state.phone) || this.state.phone.length > 15)) {
            this.setState({
                ...this.state,
                phone: "",
            });
            store.dispatch(showSnackbar({ type: "ERROR", message: t("error_phone") }));

            return false;
        }

        return true;
    }

    onPress = async () => {
        const alias = this.state.alias.toLowerCase();

        if (this.validate()) {
            try {
                const resp = await ProfileService.getInstance().setProfile({
                    alias: this.state.alias,
                    personalData: {
                        name: this.state.name,
                        lastname: this.state.lastname,
                        phone: this.state.phone,
                        email: this.state.email,
                    },
                });

                if (resp) {
                    this.setState({
                        ...this.state,
                        alias,
                        initial: this.state.alias[0],
                    });

                    store.dispatch(showSnackbar({ type: "SUCCESS", message: t("saved_succesfuly") }));
                }
            } catch (error: any) {
                store.dispatch(ready());

                if (
                    error.response &&
                    error.response?.data.message &&
                    (error.response?.data.message == "Alias alredy exists" ||
                        error.response?.data.message.includes("alias"))
                ) {
                    this.setState({
                        ...this.state,
                        alias: store.getState().auth.client.alias,
                    });

                    store.dispatch(showSnackbar({ type: "ERROR", message: t("alias_exist") }));
                } else {
                    this.setState({
                        ...this.state,
                        alias: store.getState().auth.client.alias,
                    });

                    store.dispatch(
                        showSnackbar({ type: "ERROR", message: error.response?.data?.message || t("error") })
                    );
                }
            }
        }
    };

    changeInputValue = (value: string, field: string) => {
        if (field === "alias") {
            value = value.replace(" ", "");
        }

        this.setState({
            ...this.state,
            [field]: value,
        });
    };

    changeState = () => {
        if (
            (this.state.alias.length && this.state.alias !== this.props.client?.alias) ||
            (this.state.email && this.state.email !== this.props.client?.personalData?.email) ||
            (this.state.name.length && this.state.name !== this.props.client?.personalData?.name) ||
            (this.state.lastname.length && this.state.lastname !== this.props.client?.personalData?.lastname) ||
            (this.state.phone.length && this.state.phone !== this.props.client?.personalData?.phone)
        ) {
            return true;
        }
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("set_profile")} type={HeaderType.Light} {...this.props} />

                <Container style={{ flex: 1 }}>
                    <ScrollView>
                        <View style={{ flex: 1 }}>
                            <View style={{ alignSelf: "center" }}>
                                <AvatarBase
                                    uri={this.state.avatar}
                                    alias={this.props.client?.alias}
                                    size={120}
                                    accesory={!this.state.options}
                                    accesoryIconSize={22}
                                    accesoryIiconName={"edit"}
                                    onPress={() => this.setState({ options: !this.state.options })}
                                />

                                {this.state.options && (
                                    <View
                                        style={{
                                            position: "absolute",
                                            justifyContent: "center",
                                            zIndex: 100,
                                            bottom: 0,
                                            left: 0,
                                        }}
                                    >
                                        <PressableBase
                                            onPress={() => this.selectImage(false)}
                                            style={{ marginRight: 10 }}
                                        >
                                            <Icon
                                                name="images"
                                                size={16}
                                                color={colors.floatButtonText}
                                                style={[
                                                    styles.icon,
                                                    {
                                                        backgroundColor: getColorOpacity(
                                                            colors.floatButtonBackground,
                                                            0.8
                                                        ),
                                                    },
                                                ]}
                                            />
                                        </PressableBase>
                                    </View>
                                )}
                                {this.state.options && Platform.OS !== "web" && (
                                    <View
                                        style={{
                                            position: "absolute",
                                            justifyContent: "center",
                                            zIndex: 100,
                                            bottom: 0,
                                            right: -5,
                                        }}
                                    >
                                        <PressableBase
                                            onPress={() => this.selectImage(true)}
                                            style={{ marginRight: 10 }}
                                        >
                                            <Icon
                                                name="camera"
                                                size={16}
                                                color={colors.white}
                                                style={[
                                                    styles.icon,
                                                    {
                                                        backgroundColor: this.props.client?.profileImagePath
                                                            ? colors.secondary
                                                            : getColorOpacity(colors.grey, 0.7),
                                                    },
                                                ]}
                                            />
                                        </PressableBase>
                                    </View>
                                )}
                            </View>

                            <View>
                                <RegularText style={{ marginTop: 25, marginBottom: 10 }} align={"center"}>
                                    {t("set_alias")}
                                </RegularText>
                                <FloatingTextInput
                                    onChangeText={(text) => this.changeInputValue(text, "alias")}
                                    value={this.state.alias}
                                    label={t("alias")}
                                    multiline={false}
                                />
                                <RegularText style={{ marginVertical: 10 }} align={"center"}>
                                    {t("personal_information_optional")}
                                </RegularText>
                                <FloatingTextInput
                                    onChangeText={(text) => this.changeInputValue(text, "email")}
                                    value={this.state.email}
                                    label={t("email")}
                                    multiline={false}
                                    disable={this.props.client?.email?.length > 0}
                                    editable={this.props.client?.email?.length > 0 ? false : true}
                                />
                                <FloatingTextInput
                                    onChangeText={(text) => this.changeInputValue(text, "name")}
                                    value={this.state.name}
                                    label={t("name")}
                                    multiline={false}
                                />
                                <FloatingTextInput
                                    onChangeText={(text) => this.changeInputValue(text, "lastname")}
                                    value={this.state.lastname}
                                    label={t("lastname")}
                                    multiline={false}
                                />
                                <FloatingTextInput
                                    onChangeText={(text) => this.changeInputValue(text, "phone")}
                                    value={this.state.phone}
                                    label={t("phone")}
                                    multiline={false}
                                />
                            </View>

                            {this.changeState() && (
                                <InlineButton
                                    title={t("save")}
                                    onPress={this.onPress}
                                    style={{ marginHorizontal: 0, marginVertical: 10 }}
                                />
                            )}
                        </View>
                    </ScrollView>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    buttons: {
        marginVertical: 15,
    },
    addressContainer: {
        backgroundColor: colors.shadow,
        padding: 10,
        borderRadius: settings.cardRadius,
    },
    addressWrapper: {
        marginTop: 5,
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
    },
    qr: {
        flexDirection: "column",
        alignItems: "center",
        marginVertical: 20,
    },
    button: {
        width: 40,
        marginHorizontal: 5,
        backgroundColor: colors.shadow,
        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
    },
    icon: {
        height: 36,
        width: 36,
        overflow: "hidden",
        borderRadius: 18,
        padding: 10,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ProfileEditScreen = connect(mapStateToProps, mapDispatchToProps)(_ProfileEditScreen);

export default ProfileEditScreen;
