import React, {Component, useRef} from "react"
import {View, StyleSheet,  Clipboard} from "react-native"
import { colors, settings } from "@styles/globalStyles"
import i18n from "@i18n/i18n"
import RegularText from "@base/RegularText"
import {KriptonService} from "@core/services/KriptonService"
import Carousel, {Pagination} from "react-native-snap-carousel"
import {MaterialCommunityIcons} from "@expo/vector-icons"
import BoldText from "@base/BoldText"
import Icon from "@components/icons"
import Row from "@base/Row"
import PressableBase from "@base/PressableBase"

interface Props {
    banks: Array<any>
}

interface State {
    showMessage: boolean
    activeSlide: number
}

const {t} = i18n

export default class BankCarousell extends Component<Props, State> {
    private kriptonService: KriptonService
    private carousel: any

    constructor(props: Props) {
        super(props)
        this.state = {
            showMessage: false,
            activeSlide: 0,
        }
        this.onCopy = this.onCopy.bind(this)
        this.renderItem = this.renderItem.bind(this)
        this.carousel = React.createRef()
    }

    onCopy = (item) => {
        Clipboard.setString(item)
        this.setState({showMessage: true})
        setTimeout(() => {
            this.setState({showMessage: false})
        }, 3000)
    }

    renderItem = ({item, index}) => {
        return (
            <View style={{marginBottom: 20}}>
                <Row style={{alignContent:"center", justifyContent:"center", alignItems:"center"}}>
                    <Icon style={{marginRight: 10}} name={"bank"} size={20} color={colors.white} />
                    <BoldText fontSize={24} align={"center"}>
                        {item.name}
                    </BoldText>
                </Row>
                <RegularText style={{marginTop: 10}} align={"center"}>
                    {t("cbu")}
                </RegularText>
                <View style={styles.bankAccountWrapper}>
                    <View style={styles.bankAccountContainer}>
                        <RegularText fixedWidth={250} align='center' selectable={true} fontSize={14}>
                            {item.metadata.extras[0].value}
                        </RegularText>
                    </View>
                    <PressableBase onPress={() => this.onCopy(item.metadata.extras[0].value)} style={styles.button}>
                        {/* @todo  Change to <Icon imported from base
                        */}
                        <MaterialCommunityIcons size={20} name={"content-copy"} color={colors.text} />
                    </PressableBase>
                </View>
                <RegularText align={"center"}>Alias</RegularText>
                <View style={styles.bankAccountWrapper}>
                    <View style={styles.bankAccountContainer}>
                        <RegularText fixedWidth={250} align='center' selectable={true} fontSize={14}>
                            {item.metadata.extras[1].value}
                        </RegularText>
                    </View>
                    <PressableBase onPress={() => this.onCopy(item.metadata.extras[1].value)} style={styles.button}>
                        <MaterialCommunityIcons size={20} name={"content-copy"} color={colors.text} />
                    </PressableBase>
                </View>
            </View>
        )
    }

    render() {
        return (
            <View style={{alignContent: "center", alignItems: "center", marginTop: 20}}>
                <Carousel
                    ref={this.carousel}
                    onSnapToItem={(index) => this.setState({activeSlide: index})}
                    layout={"default"}
                    data={this.props.banks}
                    renderItem={this.renderItem}
                    sliderWidth={400}
                    itemWidth={400}
                    loop={true}
                />
                <Pagination
                    containerStyle={{marginTop: -30}}
                    dotsLength={this.props.banks.length}
                    dotColor={colors.secondary}
                    inactiveDotColor={colors.text}
                    inactiveDotOpacity={0.6}
                    inactiveDotScale={0.6}
                    activeDotIndex={this.state.activeSlide}
                    tappableDots={true}
                    carouselRef={this.carousel}
                />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    bankAccountContainer: {
        backgroundColor: colors.shadow,
        padding: 10,
        borderRadius: settings.cardRadius,
        minWidth: 250
    },
    bankAccountWrapper: {
        marginTop: 5,
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
    },
    button: {
        width: 40,
        marginHorizontal: 5,
        backgroundColor: colors.shadow,
        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
    },
})
