import BoldText from "@base/BoldText";
import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import ImageBase from "@components/image/ImageBase";
import VideoBase from "@components/image/VideoBase";
import Currency from "@core/currencies/Currency";
import { ModuleControlService, NFTsModules } from "@core/services/ModuleControlService";
import Wallet from "@core/wallet/Wallet";
import NFT from "@custom-types/NFTModel";
import i18n from "@i18n/i18n";
import { colors, settings } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { getColorOpacity } from "@utils/helpers/global/global";
import { ResizeMode } from "expo-av";
import Constants from "expo-constants";
import React, { Component } from "react";
import { ActivityIndicator, Platform, StyleSheet, View } from "react-native";
import { PulseIndicator } from "react-native-indicators";

interface Props {
    nft: NFT;
    isOwner: boolean;
    onPress: () => void;
}

interface State {
    currency: Currency;
}

const { t } = i18n;

export default class NFTSmallCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currency: Wallet.getInstance().findCurrencyByBlockchain(this.props.nft?.blockchain),
        };
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <PressableBase onPress={this.props.onPress} style={styles.wrapper}>
                    {this.props.nft.video?.thumbnail ||
                    this.props.nft.video?.original ||
                    this.props.nft.video?.video ? (
                        <View style={{ height: 240, overflow: "hidden" }}>
                            <VideoBase
                                style={[
                                    Platform.OS == "web" ? { flexGrow: 1, flexDirection: "row" } : { height: 240 },
                                    {
                                        opacity: this.props.nft.type == "temporalBuy" ? 0.5 : 1,
                                    },
                                ]}
                                posterStyle={{ height: 240, resizeMode: "cover" }}
                                uri={
                                    this.props.nft.video?.original ||
                                    this.props.nft.video?.thumbnail ||
                                    this.props.nft.video?.video
                                }
                                posterUri={this.props.nft.image?.original || this.props.nft.image?.thumbnail}
                                useNativeControls={false}
                                resizeMode={ResizeMode.COVER}
                            ></VideoBase>
                        </View>
                    ) : (
                        <ImageBase
                            uri={
                                this.props.nft.image?.original ||
                                this.props.nft.image?.thumbnail ||
                                this.props.nft.image.square
                            }
                            blurRadius={this.props.nft.isReported && 10}
                            style={{
                                height: 240,
                                opacity: this.props.nft.type == "temporalBuy" ? 0.5 : 1,
                            }}
                            contentFit={"cover"}
                        />
                    )}
                    {this.props.nft.isReported && (
                        <View
                            style={{
                                position: "absolute",
                                backgroundColor: getColorOpacity(colors.red, 0.4),
                                bottom: 30,
                                left: 10,
                                borderRadius: 5,
                                paddingHorizontal: 8,
                                paddingVertical: 2,
                                zIndex: 100
                            }}
                        >
                            <RegularText fontSize={8}>{t("reported")}</RegularText>
                        </View>
                    )}
                    {this.props.nft.type == "temporalBuy" || (this.props.nft.draftState && this.props.isOwner) ? (
                        <View
                            style={{
                                position: "absolute",
                                top: 40,
                                right: 0,
                                alignItems: "center",
                                zIndex: 100
                            }}
                        >
                            <View
                                style={{
                                    paddingVertical: 4,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    borderTopLeftRadius: settings.cardRadius,
                                    borderBottomLeftRadius: settings.cardRadius,
                                    backgroundColor: getColorOpacity(
                                        (this.props.nft.draftState &&
                                            (this.props.nft.draftState == "error-mint" ||
                                                this.props.nft.draftState == "error") &&
                                            colors.red) ||
                                            (this.props.nft.draftState &&
                                                this.props.nft.draftState == "processed" &&
                                                colors.green) ||
                                            colors.yellow,
                                        0.7,
                                    ),
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    overflow: "hidden",
                                }}
                            >
                                <BoldText style={{ paddingLeft: 2 }} fontSize={10} color={"white"}>
                                    {this.props.nft.draftState
                                        ? t(
                                              this.props.nft.draftState == "error-mint" ||
                                                  this.props.nft.draftState == "error-multimedia"
                                                  ? "error"
                                                  : this.props.nft.draftState,
                                          ).toUpperCase()
                                        : t("processing")}
                                </BoldText>
                            </View>
                        </View>
                    ) : (
                        <View></View>
                    )}

                    <View style={{ position: "absolute", top: 10, right: 0, opacity: 0.8, zIndex: 100 }}>
                        {this.props.nft.isDraft ? (
                            <View
                                style={{
                                    backgroundColor: colors.grey,
                                    paddingLeft: 10,
                                    paddingRight: 8,
                                    paddingVertical: 4,
                                    borderTopLeftRadius: settings.cardRadius,
                                    borderBottomLeftRadius: settings.cardRadius,
                                }}
                            >
                                <RegularText fontSize={12}>{t("draft").toUpperCase()}</RegularText>
                            </View>
                        ) : (
                            <View
                                style={{
                                    backgroundColor: colors.grey,
                                    paddingLeft: 10,
                                    paddingRight: 8,
                                    paddingVertical: 4,
                                    borderTopLeftRadius: settings.cardRadius,
                                    borderBottomLeftRadius: settings.cardRadius,
                                }}
                            >
                                <BoldText fontSize={12}>NFT</BoldText>
                            </View>
                        )}
                    </View>

                    {this.props.nft.isListed &&
                        ModuleControlService.getInstance().isNFTsModuleEnabled(NFTsModules.marketplace) &&
                        (!this.props.isOwner
                            ? this.props.nft?.marketplace?.wallet == Constants.expoConfig?.extra?.id
                            : true) && (
                            <View
                                style={{
                                    position: "absolute",
                                    top: 40,
                                    right: 0,
                                    alignItems: "center",
                                    overflow: "hidden",
                                    borderTopLeftRadius: settings.cardRadius,
                                    borderBottomLeftRadius: settings.cardRadius,
                                    zIndex: 100
                                }}
                            >
                                <BoldText
                                    fontSize={12}
                                    color={"white"}
                                    style={{
                                        paddingVertical: 4,
                                        paddingLeft: 12,
                                        paddingRight: 8,
                                        textTransform: "uppercase",
                                        backgroundColor: colors.complementary || colors.secondary,
                                    }}
                                >
                                    {t("on_sale")}
                                </BoldText>
                            </View>
                        )}
                    <View style={{ position: "absolute", left: 10, top: 10, zIndex: 100 }}>
                        {this.props.nft.type == "temporalBuy" ||
                        (this.props.nft.isDraft &&
                            (this.props.nft.draftState == "processing" || this.props.nft.draftState == "minted") &&
                            this.props.isOwner) ? (
                            <View>
                                {Platform.OS == "web" ? (
                                    <ActivityIndicator size={24} color="white" />
                                ) : (
                                    <PulseIndicator size={25} color={"white"}></PulseIndicator>
                                )}
                            </View>
                        ) : (
                            <View>
                                <Row
                                    style={{
                                        justifyContent: "center",
                                        alignContent: "center",
                                        alignItems: "center",
                                        height: 25,
                                        minWidth: 25,
                                        borderRadius:
                                            this.props.nft.price &&
                                            this.props.nft.price !== "0" &&
                                            ModuleControlService.getInstance().isNFTsModuleEnabled(
                                                NFTsModules.marketplace,
                                            )
                                                ? settings.cardRadius
                                                : 13,
                                    }}
                                >
                                    <CurrencyIcon size={25} currency={this.state?.currency} />
                                    {this.props.nft.price &&
                                        this.props.nft.price !== "0" &&
                                        ModuleControlService.getInstance().isNFTsModuleEnabled(
                                            NFTsModules.marketplace,
                                        ) && (
                                            <BoldText color={"white"} fontSize={12}>
                                                {" "}
                                                {this.state.currency.fromDecimalsToString(this.props.nft.price)}
                                            </BoldText>
                                        )}
                                </Row>
                            </View>
                        )}
                    </View>

                    <View
                        style={{
                            position: "absolute",
                            bottom: 10,
                            left: 10,
                            paddingHorizontal: 6,
                            backgroundColor: getColorOpacity(colors.grey, 0.6),
                            borderRadius: 5,
                            zIndex: 100
                        }}
                    >
                        <BoldText fontSize={12}>
                            {trimHelper(this.props.nft.name, 16)}
                            {this.props.nft.tokenId && (
                                <RegularText fontSize={12}> #{this.props.nft.tokenId}</RegularText>
                            )}
                        </BoldText>
                    </View>
                </PressableBase>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: colors.shadow,
        //flex: 1,
        marginHorizontal: 5,
        marginVertical: 5,
        height: 240,
        borderRadius: settings.cardRadius,
        overflow: "hidden",
    },

    avatarContainer: {
        height: 44,
        width: 44,
        backgroundColor: colors.text,
        borderRadius: 22,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        alignSelf: "center",
        marginTop: -22,
    },
});
