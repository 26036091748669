import { StyleSheet, Appearance } from "react-native";

const lightColors = {
    primary: "#575757",
    secondary: "#575757",
    complementary: "#575757",
    tertiary: "#e6e0e5",
    background: "#e6e0e5",
    gradientFrom: "#cae1ed",
    gradientTo: "#e6e0e5",
    grey: "#9091a4",
    white: "white",
    black: "#333",
    blue: "#2696fb",
    green: "#1DBC60",
    yellow: "#FECA54",
    red: "#EE4540",
    text: "#6A6A6A",
    shadow: "rgba(255,255,255,0.7)",
    secondaryShadow: "rgba(0, 0, 0, 0.1)",
    tertiaryShadow: "rgba(200, 247, 237, 0.2)",
    labelText: "white",
    latamxo1: "#ceafd6",
    latamxo2: "#333",
    floatButtonText: "white",
    avatarBase: "#bababa",
    popupBackground: "rgba(255,255,255,0.75)",
};

const darkColors = lightColors;

const settings = {
    cardRadius: 20,
    fontSizeRatio: {
        light: 1.2,
        regular: 1.2,
        semiBold: 1.2,
        bold: 1.2,
    },
};

export { lightColors, darkColors, settings };
