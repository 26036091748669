import {BaseMessageState} from "@utils/helpers/chat/message-state/base.message.state";
import {MessageAddType, Participant} from "@utils/helpers/chat/chat.helper";
import {Message} from "@custom-types/Message";

export class Receiver extends BaseMessageState {
    participant = Participant.RECEIVER;
    state: number;

    protected constructor() {
        super();
    }

    processNewMessage() {
        this.state = Message.RECEIVED_STATE;

        this.saveStorage();
        this.toReceived();
        this.updateServer();
    }

    toRead() {
        if (this.state !== Message.READ_STATE) this.state = Message.READ_STATE;
    }

    toReceived() {
        if (this.state === Message.SENT_STATE) this.state = Message.RECEIVED_STATE;
    }


    saveEarlier() {
        this.toRead();
        this.saveStorage(MessageAddType.end);
        this.saveServer();
    }


}