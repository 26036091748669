import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

function Logo(props: SvgProps) {
  return (
    <Svg
      id="prefix__Capa_1"
      height={25}
      width={100}
      viewBox="0 0 403 92"
      {...props}
    >
      <G
        id="prefix__Grupo_7412_00000183941120196792546520000003849939720580220338_"
        transform="translate(23.276)"
      >
        <Path
          id="prefix__Trazado_10284_00000039832267710443050020000000831506704311591337_"
          
          d="M872.14 624.81c-8.15 0-10.66-3.54-15.55-11.55-.37-.61-.75-1.23-1.13-1.85-2.78-4.8-6.24-9.18-10.26-13.01a26.381 26.381 0 00-17.91-7.12c-3.1.06-5.56 2.61-5.51 5.71.05 3.02 2.49 5.45 5.51 5.51 7.86 0 13.13 5.92 18.64 14.82.37.6.74 1.2 1.1 1.79 2.28 4.25 5.24 8.1 8.75 11.41a24.178 24.178 0 0016.37 5.51 5.6 5.6 0 005.5-5.71c-.07-3.03-2.5-5.46-5.51-5.51z"
        />
        <Path
          id="prefix__Trazado_10285_00000018198115258185438760000015845416734212922505_"
          
          d="M809.33 620c-3.1 0-5.61-2.51-5.61-5.61a23.171 23.171 0 016.27-16.2 21.64 21.64 0 017.57-5.1c3.09-1.23 6.39-1.84 9.72-1.81 3.1-.06 5.65 2.41 5.71 5.51.06 3.1-2.41 5.65-5.51 5.71h-.2c-9.11 0-12.35 6.41-12.35 11.9.01 3.09-2.5 5.6-5.6 5.6z"
        />
        <Path
          id="prefix__Trazado_10286_00000145017385269850669460000012848458422363107998_"
          
          d="M826.1 636.02a23.233 23.233 0 01-15.21-5.83 20.918 20.918 0 01-7.16-15.8 5.6 5.6 0 015.71-5.5c3.02.05 5.45 2.49 5.5 5.5 0 6.23 5.77 10.41 11.16 10.41a5.6 5.6 0 015.5 5.71 5.604 5.604 0 01-5.5 5.51z"
        />
        <Path
          id="prefix__Trazado_10287_00000024696612634643309490000003339051701362339238_"
          
          d="M826.1 636.03a5.6 5.6 0 01-5.5-5.71c.06-3.02 2.49-5.45 5.5-5.5 9.73 0 13.88-5.41 19.11-13.67a5.605 5.605 0 017.74-1.74 5.605 5.605 0 011.74 7.74c-2.7 4.7-6.14 8.94-10.19 12.55a27.897 27.897 0 01-18.4 6.33z"
        />
        <Path
          id="prefix__Trazado_10288_00000106866478168553166330000008530636027983449790_"
          
          d="M890.11 620h-.06a5.608 5.608 0 01-5.55-5.67c.13-3.18-.99-6.27-3.13-8.62a12.458 12.458 0 00-9.22-3.21 5.615 5.615 0 01-5.51-5.71c.05-3.02 2.49-5.45 5.51-5.51a23.29 23.29 0 0117.2 6.54 23.02 23.02 0 016.38 16.63c-.05 3.08-2.55 5.55-5.62 5.55z"
        />
        <Path
          id="prefix__Trazado_10289_00000096779107196584930960000012281613670278894244_"
          
          d="M872.13 636.02a5.6 5.6 0 01-5.5-5.71c.06-3.02 2.49-5.45 5.5-5.5 8.53 0 12.36-6.09 12.36-10.41a5.6 5.6 0 015.71-5.5c3.02.05 5.45 2.49 5.5 5.5a21.21 21.21 0 01-6.32 14.81 24.103 24.103 0 01-17.25 6.81z"
        />
        <Path
          id="prefix__Trazado_10290_00000124123928279739496560000018167315581688261774_"
          
          d="M849.94 619.74a5.6 5.6 0 01-5.6-5.61c0-1.06.3-2.1.87-2.99l.78-1.23c2.54-4.48 5.69-8.59 9.35-12.21a23.976 23.976 0 0116.8-6.41c3.1.06 5.56 2.61 5.51 5.71-.05 3.02-2.49 5.45-5.51 5.51-7.86 0-11.28 4.83-16.66 13.38-.26.42-.53.84-.79 1.26a5.636 5.636 0 01-4.75 2.59z"
        />
      </G>
      <G id="prefix__Grupo_7530" transform="translate(-296.553 -446.354)">
        <G id="prefix__Grupo_7527" transform="translate(296.553 446.354)">
          <G id="prefix__Grupo_7526" transform="translate(32.712 .476)">
            <Path
              id="prefix__Trazado_10313"
              d="M173.05 64.58c-15.01 0-19.62-6.51-28.62-21.26-.68-1.12-1.38-2.26-2.09-3.4a106.745 106.745 0 00-18.89-23.94A48.543 48.543 0 0090.48 2.87c-5.7 0-10.32 4.62-10.32 10.32s4.62 10.32 10.32 10.32c14.46 0 24.17 10.91 34.31 27.28.69 1.11 1.36 2.21 2.02 3.3 4.77 7.81 9.27 15.18 16.11 21 8.02 6.83 17.88 10.14 30.12 10.14 5.7 0 10.32-4.62 10.32-10.32s-4.62-10.32-10.32-10.32l.01-.01z"
              fill="#0ba550"
            />
            <Path
              id="prefix__Trazado_10314"
              d="M57.43 55.73c-5.7 0-10.32-4.62-10.32-10.32a42.664 42.664 0 0111.53-29.82c3.93-4.08 8.68-7.29 13.94-9.4a47.223 47.223 0 0117.89-3.33c5.7 0 10.32 4.62 10.32 10.32S96.17 23.5 90.47 23.5c-16.78 0-22.73 11.81-22.73 21.91.01 5.7-4.61 10.32-10.31 10.32z"
              fill="#27aae2"
            />
            <Path
              id="prefix__Trazado_10315"
              d="M88.3 85.23A42.759 42.759 0 0160.28 74.5 38.518 38.518 0 0147.1 45.42c0-5.7 4.62-10.32 10.32-10.32s10.32 4.62 10.32 10.32c0 11.47 10.63 19.17 20.55 19.17 5.7 0 10.32 4.62 10.32 10.32.02 5.7-4.61 10.32-10.31 10.32z"
              fill="#9c77b2"
            />
            <Path
              id="prefix__Trazado_10316"
              d="M88.3 85.23c-5.7 0-10.32-4.62-10.32-10.32S82.6 64.59 88.3 64.59c17.91 0 25.56-9.97 35.18-25.17 3.05-4.82 9.42-6.25 14.24-3.2s6.25 9.42 3.2 14.24a93.705 93.705 0 01-18.76 23.11A51.168 51.168 0 0188.3 85.23z"
              fill="#a54786"
            />
            <Path
              id="prefix__Trazado_10317"
              d="M206.13 55.73h-.11c-5.7-.06-10.27-4.73-10.21-10.43.24-5.85-1.84-11.55-5.78-15.88-3.89-3.93-9.6-5.92-16.98-5.92-5.7 0-10.32-4.62-10.32-10.32s4.62-10.32 10.32-10.32c16.32 0 26.22 6.55 31.66 12.05a42.326 42.326 0 0111.74 30.6c-.06 5.66-4.66 10.22-10.32 10.22z"
              fill="#e42528"
            />
            <Path
              id="prefix__Trazado_10318"
              d="M173.05 85.23c-5.7 0-10.32-4.62-10.32-10.32s4.62-10.32 10.32-10.32c15.7 0 22.76-11.21 22.76-19.17 0-5.7 4.62-10.32 10.32-10.32s10.32 4.62 10.32 10.32a39.051 39.051 0 01-11.62 27.27 44.382 44.382 0 01-31.78 12.54z"
              fill="#ef8123"
            />
            <Path
              id="prefix__Trazado_10319"
              d="M132.19 55.25c-5.7-.01-10.32-4.63-10.31-10.33 0-1.95.56-3.86 1.6-5.51l1.43-2.27c4.96-7.89 10.09-16.04 17.21-22.49a44.068 44.068 0 0130.93-11.79c5.7 0 10.32 4.62 10.32 10.32s-4.62 10.32-10.32 10.32c-14.46 0-20.77 8.9-30.67 24.63l-1.45 2.31a10.273 10.273 0 01-8.74 4.81z"
              fill="#fdc90a"
            />
          </G>
          <Path
            id="prefix__Trazado_10320"
            
            d="M20.71 80.23A34.055 34.055 0 016.07 65.94c-6.76-14-6.76-30.32 0-44.32A34.525 34.525 0 0120.65 7.21c6.95-3.48 14.64-5.2 22.41-5.01 6.79-.07 13.52 1.19 19.83 3.69a29.827 29.827 0 0113.49 9.8 6.213 6.213 0 011.38 3.8 6.512 6.512 0 01-3.11 5.19 5.629 5.629 0 01-3.46 1.04 8.613 8.613 0 01-3.46-.75 7.618 7.618 0 01-2.89-2.13 23.126 23.126 0 00-9.11-6.51 35.114 35.114 0 00-12.68-2.02 25.076 25.076 0 00-19.6 7.78c-4.69 5.19-7.03 12.43-7.03 21.73 0 9.22 2.36 16.41 7.09 21.55 4.73 5.15 11.31 7.72 19.76 7.73 9.68 0 17.4-2.84 23.17-8.53 1.7-1.9 4.04-3.09 6.57-3.34 1.39 0 2.74.49 3.8 1.38a6.69 6.69 0 012.65 5.07c-.03 1.48-.56 2.9-1.5 4.04-3.72 4.52-8.6 7.95-14.12 9.91a56.264 56.264 0 01-20.58 3.57c-7.79.18-15.53-1.52-22.55-4.97z"
          />
          <Path
            id="prefix__Trazado_10321"
            
            d="M258.17 83.34a6.444 6.444 0 01-2.07-5.07V20.41c-.28-4.66 1.42-9.22 4.67-12.56 3.56-3.16 8.22-4.78 12.97-4.5h19.48c11.68 0 20.56 2.42 26.63 7.26s9.11 11.95 9.1 21.33c0 9.38-2.96 16.48-8.87 21.32-5.92 4.84-14.64 7.26-26.17 7.26h-22.48v17.75c.07 1.91-.71 3.75-2.13 5.02-3.29 2.51-7.83 2.54-11.13.05zm55.33-51.41c0-11.14-6.57-16.71-19.71-16.72h-17.4c-1.37-.07-2.71.48-3.63 1.5a6.106 6.106 0 00-1.33 4.15v27.78h22.37c13.13.01 19.7-5.56 19.7-16.71z"
          />
          <Path
            id="prefix__Trazado_10322"
            
            d="M342.66 79.43a16.96 16.96 0 01-4.84-12.91V20.41c-.28-4.66 1.42-9.22 4.67-12.56 3.56-3.16 8.22-4.78 12.97-4.5h35.85c1.9-.13 3.78.43 5.3 1.56a5.476 5.476 0 011.85 4.44c.08 1.66-.62 3.26-1.9 4.32a8.13 8.13 0 01-5.24 1.56h-33.19c-1.38-.07-2.71.48-3.64 1.5a6.048 6.048 0 00-1.32 4.15V36.9h29.4c1.9-.13 3.77.43 5.3 1.56a5.498 5.498 0 011.84 4.44c.08 1.66-.62 3.26-1.9 4.32a8.015 8.015 0 01-5.24 1.56h-29.4v17.75c-.09 1.5.38 2.98 1.32 4.15a4.602 4.602 0 003.64 1.5h35.73c1.9-.13 3.77.42 5.3 1.56a5.498 5.498 0 011.84 4.44c.08 1.66-.62 3.26-1.9 4.32a8.015 8.015 0 01-5.24 1.56h-37.59a19.04 19.04 0 01-13.61-4.63z"
          />
        </G>
      </G>
    </Svg>
  )
}

export default Logo
