import React, { Component } from "react";
import { StyleSheet, View, TextStyle, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";

interface Props {
    style?: TextStyle | Array <ViewStyle>;
    color?: string;
    children?: any;
}

interface State {
    inlineStyles: TextStyle;
}

export default class Container extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            inlineStyles: {
                backgroundColor: props.color || "transparent",
            },
        };
    }

    render() {
        return (
            <View style={[styles.defaultStyles, this.state.inlineStyles, this.props.style]}>{this.props.children}</View>
        );
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        //paddingVertical: 5,
        paddingHorizontal: 20,
        maxWidth: settings.maxWidth,
        width: "100%",
        marginHorizontal: "auto",
        alignSelf: "center",
    },
});
