import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import Constants from "expo-constants";

export const isCurrencyEnabled = (enabled: boolean, id: string, defaultEnabled: boolean) => {
    if (enabled == undefined) {
        if (
            Constants.expoConfig?.extra?.defaultCurrencies &&
            Constants.expoConfig?.extra?.defaultCurrencies[id] != undefined
        ) {
            return Constants.expoConfig?.extra?.defaultCurrencies[id]?.activeCurrency;
        } else {
            return defaultEnabled;
        }
    } else {
        return enabled;
    }
};

export const isNFTEnabled = (id: string, defaultEnabled: boolean) => {
    if (Constants.expoConfig?.extra?.defaultCurrencies && Constants.expoConfig?.extra?.defaultCurrencies[id] != undefined) {
        return Constants.expoConfig?.extra?.defaultCurrencies[id]?.nftSupport;
    } else {
        return defaultEnabled;
    }
};
