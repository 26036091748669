import BackupScreen from "@screens/settings/seed/BackupScreen";
import ResetSeedScreen from "@screens/settings/seed/ResetSeedScreen";
import HistorySeedScreen from "@screens/settings/seed/HistorySeedScreen";
import SeedScreen from "@screens/settings/seed/SeedScreen";
import ImportScreen from "@screens/settings/seed/import/ImportScreen";
import NavigationInstantiator from "./helpers/NavigationInstantiator";
import ScannerSeedScreen from "@screens/scanner/ScannerSeedScreen";
import RemoveSeedScreen from "@screens/settings/seed/RemoveSeedScreen";
import RestoreAppScreen from "@screens/settings/app/RestoreAppScreen";

const SeedNavigatorScreens = {
    Seed: {
        routeName: "Seed",
        title: "Seed",
        component: SeedScreen,
    },
    Backup: {
        routeName: "Backup",
        title: "Backup",
        component: BackupScreen,
    },
    ResetSeed: {
        routeName: "ResetSeed",
        title: "ResetSeed",
        component: ResetSeedScreen,
    },
    HistorySeed: {
        routeName: "HistorySeed",
        title: "HistorySeed",
        component: HistorySeedScreen,
    },
    Import: {
        routeName: "Import",
        title: "Import",
        component: ImportScreen,
    },
    RemoveSeed: {
        routeName: "RemoveSeed",
        title: "RemoveSeed",
        component: RemoveSeedScreen,
    },
    RestoreApp: {
        routeName: "RestoreApp",
        title: "RestoreApp",
        component: RestoreAppScreen,
    },
    ScannerSeed: {
        routeName: "ScannerSeed",
        title: "Scanner",
        component: ScannerSeedScreen,
    },
};

const SeedNavigator = NavigationInstantiator(SeedNavigatorScreens);

export { SeedNavigator, SeedNavigatorScreens };
