import { _SendScreen } from "@screens/wallet/send/SendScreen";
import { connect } from "react-redux";
import { _ConfirmSendScreen } from "@screens/wallet/send/ConfirmSendScreen";
import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator";
import { CoincaexSell } from "../../helper/coincaex.helper";


export class _CoincaexConfirmSendCryptoScreen extends _ConfirmSendScreen {
    constructor(props: any) {
        super(props);
    }

    handleOnSuccessNavigate = (data) => {
        const sellData: CoincaexSell = {
            ...this.props.route?.params?.data,
            reference: data?.hash,
        }

        this.props.navigation.navigate(CoincaexNavigatorScreens.SellDetail.routeName, {
            data: sellData,
            confirmPurchase: true,
        });

        return true;
    };
}

const mapStateToProps = (state) => {
    return {
        currencies: state.wallet.currencies,
        selectedCurrency: state.wallet.selectedCurrency,
        fiatCurrency: state.wallet.fiatCurrency,
        currentOperation: state.wallet.currentOperation,
        customMessage: state.chat.customMessage,
        selectedChat: state.chat.selected,
        onSuccess: true,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const CoincaexConfirmSendCryptoScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexConfirmSendCryptoScreen);

export default CoincaexConfirmSendCryptoScreen;
