import React, { Component } from "react";
import { View } from "react-native";
import i18n from "@i18n/i18n";
import { importer } from "./helpers/importer";

interface Props {}

interface State {}

const { t } = i18n;

class DefaultListHeaderButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    render() {
        return <View></View>;
    }
};

const ListHeaderButton = importer(DefaultListHeaderButton, "ListHeaderButton")

export { ListHeaderButton };
