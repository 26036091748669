import React, { Component } from 'react';
import { createIconSetFromIcoMoon } from '@expo/vector-icons'
import icoMoonConfig from '@assets/fonts/selection.json';
import { ViewStyle } from 'react-native';

const Icons = createIconSetFromIcoMoon(icoMoonConfig, 'IcoMoon', 'icomoon.ttf')

interface Props {
    name: string,
    color?: string,
    size?: number,
    align?: "left" | "right" | "center"
    style?: ViewStyle | Array<ViewStyle>,
    onPress?: ()=> void
    
}


export default class Icon extends Component<Props> {
    constructor(props:Props){
        super(props)
    }

    render() {
        return(
            
                   <Icons {...this.props}/>
            
        )
    }
}



