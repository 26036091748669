import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import Container from "@base/Container";
import RegularText from "@base/RegularText";
import { StyleSheet, View } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { HeaderType } from "@custom-types/HeaderType";
import { Header } from "@components/header/Header";
import { LoginNavigatorScreens } from "@navigation/LoginNavigator";
import { generateMnemonic } from "bip39";
import BotCard from "@base/BotCard";
import InlineButton from "@base/InlineButton";
import store from "@store/index";
import { loading, ready } from "@store/actions/global";
import { OAuthService } from "@core/services/oauth/OAuthService";

interface Props {
    navigation: NavigationType;
}

interface State {
    words: string[];
}

const { t } = i18n;

export default class NewSeedScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.createNewSeed = this.createNewSeed.bind(this);
        this.onPressNext = this.onPressNext.bind(this);
        this.state = {
            words: [],
        };
    }

    componentDidMount() {
        this.createNewSeed();
    }

    createNewSeed() {
        const words = generateMnemonic();
        this.setState({ words: words.split(" ") });
    }

    async onPressNext() {
   
        store.dispatch(loading());
        await OAuthService.getInstance().newAlias(this.state.words.join(" "));
        this.props.navigation.navigate(LoginNavigatorScreens.SetProfile.routeName);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header type={HeaderType.Light} {...this.props} />
                <Container style={styles.container}>
                    <View>
                        <BotCard title={`${t("warning")}!`} message={t("warning_seed")} />

                        <View style={styles.wrapper}>
                            {this.state.words.map((word, key) => {
                                return (
                                    <RegularText style={styles.word} align="center" key={key}>
                                        {word}
                                    </RegularText>
                                );
                            })}
                        </View>
                    </View>
                    <View>
                        <InlineButton title={t("next")} onPress={this.onPressNext}></InlineButton>
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
        paddingBottom: 30,
    },
    wrapper: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "flex-start",
        justifyContent: "center",
    },
    word: {
        padding: 10,
        borderRadius: 10,
        backgroundColor: colors.shadow,
        margin: 5,
        overflow: "hidden",
    },
});
