import React, { Component } from "react";
import { View, StyleSheet, Image, ScrollView } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import NFT from "@custom-types/NFTModel";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import { colors, settings } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import Icon from "@components/icons";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import { getColorOpacity } from "@utils/helpers/global/global";
import RegularNumberInput from "@base/RegularNumberInput";
import NFTService from "@core/services/NFTService";
import ImageBase from "@components/image/ImageBase";
import BotCard from "@base/BotCard";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { NTFNavigatorScreens } from "@navigation/NFTNavigator";
import CurrencyIcon from "@components/accessories/CurrencyIcon";

interface Props {
    navigation: NavigationType;
    fiatCurrency: FiatCurrency;
    selectedNFT: NFT;
}

interface State {
    currency: Currency;
    amount: number;
    seeMore: boolean;
    serviceFee: number;
    isApprove: boolean;
}

const { t } = i18n;

export class _OfferForSaleScreen extends Component<Props, State> {
    intervalID: any;
    constructor(props: Props) {
        super(props);
        this.onConfirm = this.onConfirm.bind(this);
        this.isApprove = this.isApprove.bind(this);
        this.state = {
            currency: Wallet.getInstance().findCurrencyByBlockchain(this.props.selectedNFT.blockchain),

            amount: 1,
            seeMore: false,
            serviceFee: 2,
            isApprove: this.props.selectedNFT.isApproved,
        };
    }

    async componentDidMount() {
        if (this.props.selectedNFT.description && this.props.selectedNFT.description.length < 90) {
            this.setState({ seeMore: true });
        }
        const serviceFee = await NFTService.getInstance().getFeeService(this.props.selectedNFT?.blockchain);
        if (serviceFee && serviceFee.fee) {
            this.setState({ serviceFee: serviceFee.fee });
        }
        this.isApprove();
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    onChangeAmount = (value: number) => {
        this.setState({
            amount: value,
        });
    };

    isApprove() {
        if (!this.state.isApprove) {
            setTimeout(() => {
                this.getNFTApprove();
            }, 5000);
            this.intervalID = setInterval(async () => {
                this.getNFTApprove();
            }, 10000);
        }
    }

    async getNFTApprove() {
        const nft: NFT = await NFTService.getInstance().getNFT(
            this.props.selectedNFT._id,
            this.props.selectedNFT.blockchain,
            false,
        );
        if (nft?.isApproved) {
            this.setState({ isApprove: true });
            clearInterval(this.intervalID);
        }
    }

    async onConfirm() {
        const resp = await NFTService.getInstance().requestSaleNFT(
            this.state.currency,
            this.props.selectedNFT.tokenId,
            this.props.selectedNFT.contractAddress,
            this.state.currency.toDecimals(this.state.amount),
        );

        if (resp) {
            this.props.navigation.navigate(NTFNavigatorScreens.ConfirmSale.routeName, {
                currency: this.state.currency.getId(),
                data: resp,
            });
        }
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("offer_for_sale")} type={HeaderType.Light} {...this.props} />
                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <Container style={{ flex: 1, justifyContent: "space-between" }}>
                        <View>
                            <Row>
                                <ImageBase
                                    uri={this.props.selectedNFT.image?.thumbnail || ""}
                                    style={{
                                        backgroundColor: getColorOpacity(colors.grey, 0.5),
                                        width: 80,
                                        minHeight: 80,
                                        borderRadius: 5,
                                        resizeMode: "cover",
                                    }}
                                />
                                <View style={{ marginLeft: 10 }}>
                                    <RegularText fontSize={14}>#{this.props.selectedNFT.tokenId}</RegularText>
                                    <BoldText fontSize={18}>{trimHelper(this.props.selectedNFT.name, 25)}</BoldText>

                                    <RegularText fontSize={15}>
                                        {trimHelper(this.props.selectedNFT.description, 35)}
                                    </RegularText>
                                </View>
                            </Row>

                            <View style={{ marginTop: 15 }}>
                                <BoldText>{t("price")}</BoldText>
                                <RegularText style={{ marginBottom: 10 }} color={colors.grey} fontSize={12}>
                                    {t("price_description")}
                                </RegularText>
                                <Row>
                                    <Row style={[styles.input, { marginRight: 10 }]}>
                                        <CurrencyIcon
                                            currency={this.state.currency}
                                            size={25}
                                            iconSize={18}
                                            styles={{ marginRight: 5 }}
                                        />

                                        <RegularText fontSize={18}>{this.state.currency.getPName()}</RegularText>
                                    </Row>
                                    <Row style={[styles.input, { flex: 1, overflow: "visible" }]}>
                                        <RegularNumberInput
                                            style={{ textAlign: "left", minWidth: 80 }}
                                            fontSize={20}
                                            key={"return_percentage"}
                                            multiline={false}
                                            align={"center"}
                                            onChangeText={(number) => this.onChangeAmount(number)}
                                            value={this.state.amount}
                                        />
                                        <View
                                            style={{
                                                justifyContent: "center",
                                                alignContent: "center",
                                                alignItems: "flex-end",
                                                position: "absolute",
                                                bottom: -25,
                                                zIndex: 99,
                                                backgroundColor: getColorOpacity(colors.grey, 0.5),
                                                borderRadius: 5,
                                                marginLeft: 8,
                                                overflow: "hidden",
                                            }}
                                        >
                                            <RegularText
                                                align="center"
                                                fontSize={12}
                                                style={{
                                                    paddingVertical: 1,
                                                    paddingHorizontal: 4,
                                                }}
                                            >
                                                {this.props.fiatCurrency.getSymbol()}{" "}
                                                {this.props.fiatCurrency.fiatFormat(
                                                    this.state.currency.getFiat() * this.state.amount,
                                                )}
                                            </RegularText>
                                        </View>
                                    </Row>
                                </Row>
                            </View>

                            <View style={{ marginTop: 20 }}>
                                <BoldText>{t("fee")}</BoldText>
                                <RegularText style={{ marginBottom: 10 }} color={colors.grey} fontSize={12}>
                                    {t("service_fee")}{" "}
                                    <BoldText style={{ marginLeft: 5 }} color={colors.grey} fontSize={12}>
                                        {this.state.serviceFee}
                                        {"%"}
                                    </BoldText>
                                </RegularText>
                            </View>
                        </View>

                        {this.state.isApprove ? (
                            <InlineButton
                                style={{ marginBottom: 15, marginTop: 25, marginHorizontal: 0 }}
                                title={t("confirm")}
                                onPress={this.onConfirm}
                            ></InlineButton>
                        ) : (
                            <BotCard message={t("approve_pending")}></BotCard>
                        )}
                    </Container>
                </ScrollView>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return { fiatCurrency: state.wallet.fiatCurrency, selectedNFT: state.nfts.selectedNFT };
};

const mapDispatchToProps = (dispatch) => ({});

const OfferForSaleScreen = connect(mapStateToProps, mapDispatchToProps)(_OfferForSaleScreen);

export default OfferForSaleScreen;

const styles = StyleSheet.create({
    input: {
        backgroundColor: colors.shadow,
        padding: 10,
        overflow: "hidden",
        borderRadius: settings.cardRadius,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
    },
});
