import React, { Component } from "react";
import { View, StyleSheet, RefreshControl } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import i18n from "@i18n/i18n";
import { colors } from "@styles/globalStyles";
import { connect } from "react-redux";
import POAPCard from "@screens/poaps/components/POAPCard";
import BotCard from "@base/BotCard";
import FlatListBase from "@base/FlatListBase";

const { t } = i18n;

interface Props {
    navigation: NavigationType;
    isOwner: boolean;
    loadingBackground?: boolean;
    getProfileHeader: () => any;
    POAPs: any[];
}

interface State {
    syncing: boolean;
}

export class _POAPsSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            syncing: false,
        };
    }

    componentDidUpdate(props): void {
        if (props.POAPs !== this.props.POAPs) {
        }
    }

    renderItem = ({ item }) => {
        return <POAPCard navigation={this.props.navigation} poap={item} />;
    };

    render() {
        return (
            <Container style={{ flex: 1, paddingHorizontal: 5 }}>
                <FlatListBase
                    showsHorizontalScrollIndicator={false}
                    data={this.props.POAPs}
                    renderItem={this.renderItem}
                    keyExtractor={(item) => item.tokenId}
                    contentContainerStyle={{ paddingBottom: 20 }}
                    ListHeaderComponent={this.props.getProfileHeader()}
                    numColumns={2}
                    columnWrapperStyle={{ justifyContent: "space-between", flex: 1 }}
                    initialNumToRender={2}
                    maxToRenderPerBatch={10}
                    onEndReachedThreshold={1}
                    refreshControl={<RefreshControl tintColor={colors.text} refreshing={this.state.syncing} />}
                    ListEmptyComponent={
                        <View style={{ paddingHorizontal: 15, paddingTop: 15 }}>
                            {!this.props.loadingBackground && (
                                <BotCard
                                    title={this.props.isOwner ? t("empty_poap_own") : null}
                                    message={this.props.isOwner ? t("empty_create_poaps") : t("empty_poap")}
                                />
                            )}
                        </View>
                    }
                />
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({});

const POAPsSection = connect(mapStateToProps, mapDispatchToProps)(_POAPsSection);

export default POAPsSection;

const styles = StyleSheet.create({});
