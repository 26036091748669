import React, {Component} from "react"
import {View, StyleSheet, Text} from "react-native"
import RegularText from "@base/RegularText"
import {BoldText} from "@base/BoldText"
import {colors} from "@styles/globalStyles"
import Card from "@base/Card"
import Row from "@base/Row"

interface Props {
    title: string
    subtitle?: string
    icon?: string
    selected?: boolean
    onPress?: () => void
}

export default class OptionCard extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }
    render() {
        return (
            <Card onPress={this.props.onPress}>
                <Row style={styles.row}>
                    <View style={styles.left}>
                        {this.props.selected ? (
                            <View style={styles.externalCircle}>
                                <View style={styles.innerCircle}></View>
                            </View>
                        ) : (
                            <View style={[styles.externalCircle, {borderColor: colors.text}]}>
                                <View style={[styles.innerCircle, {backgroundColor: "transparent"}]}></View>
                            </View>
                        )}
                        <RegularText fontSize={16} style={{paddingLeft: 20}}>
                            {this.props.title}
                           
                        </RegularText>
                    </View>
                    <View style={styles.right}>
                        <RegularText align={'right'} fontSize={10}>{this.props.subtitle}</RegularText>
                    </View>
                </Row>
            </Card>
        )
    }
}

const styles = StyleSheet.create({
    externalCircle: {
        width: 24,
        height: 24,
        borderRadius: 12,
        borderColor: colors.complementary || colors.secondary,
        borderWidth: 2,
        justifyContent: "center",
        alignItems: "center",
    },
    innerCircle: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: colors.complementary || colors.secondary,
    },
    left: {
        flexDirection: "row",
        justifyContent: "flex-start",
        flex: 1,
    },
    right: {
        
        justifyContent: "flex-end",
    
        
    },
    row: {
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
    },
})
