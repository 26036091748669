import { BaseResourceService } from "@core/services/BaseResourceService";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { Contact } from "@custom-types/Contact";
import store from "@store/index";
import { setContacts } from "@store/actions/chat.actions";

export class ContactService extends BaseResourceService {
  resource = "contact";
  private static instance;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!ContactService.instance) {
      ContactService.instance = new ContactService();
    }

    return ContactService.instance;
  }

  setEntitiesStore(entities) {
    store.dispatch(setContacts([...entities]));
  }

  createModel(values) {
    return Contact.create(values);
  }

  getAllEntitiesStore() {
    return store.getState().chat.contacts;
  }

  async getContactsList() {
    try {
      const resp = await axios.get(`${this.getResourceUrl()}`, {
        headers: await ApiService.getAuthHeaders(),
      });

      if (resp.data.contacts && Array.isArray(resp.data.contacts)) {
        const contacts = resp.data.contacts.map((c) => Contact.create(c));
        store.dispatch(setContacts(contacts));
      }
    } catch (error) {
      console.warn(error.response.data.message);
      return error.response.data.message;
    }
  }

  public static restartInstance() {
    ContactService.instance = null;
    return ContactService.getInstance();
  }

  saveEntity(entity) {
    const contacts = store.getState().chat.contacts;
    store.dispatch(setContacts([...contacts, entity]));
  }
}
