import store from "@store/index";
import { Message } from "@custom-types/Message";
import { chatByIdSelector, chatSelectedSelector } from "@store/reducers/chat.reducer";
import { Chat } from "@custom-types/Chat";
import { ChatService } from "@core/services/ChatService";
import { selectChat, updateChat, updateOrder } from "@store/actions/chat.actions";

export const runSagasAddMessage = async (action) => {
    const message: Message = action.message;
    const chatInMemory = chatByIdSelector(store.getState(), message.chatId);

    if (chatInMemory) {
        const chat = Object.assign(new Chat(), chatInMemory);
        await addMessageToChat(chat, message);
        return;
    }

    const instance = await ChatService.getInstance();
    // await instance.getChatById(message.author._id);
    let chat = Object.assign(new Chat(), chatByIdSelector(store.getState(), message.chatId));
    chat.messages = [];
    addMessageToChat(chat, message);
    return;
};

export async function addMessageToChat(chat, message) {
    const allMessages = store.getState().message.items;

    if (chat.messages.length === 0) {
        chat.messages.push(message._id);
        store.dispatch(updateChat(chat));
    } else {
        for (let i = 0; i < chat.messages.length; i++) {
            const msg: Message = allMessages[chat.messages[i]];
            if (new Date(message.createdAt) > new Date(msg.createdAt)) {
                chat.messages.splice(i, 0, message._id);
                store.dispatch(updateChat(chat));
                break;
            }

            if (i === chat.messages.length - 1) {
                chat.messages.push(message._id);
                store.dispatch(updateChat(chat));
                break;
            }
        }
    }

    let order: Array<string> = [...store.getState().chat.order];
    const index = order.findIndex((o) => o === chat._id.toString());
    if (index > -1) {
        order.splice(index, 1);
    }
    order.unshift(chat._id);
    store.dispatch(updateOrder(order));
}

export const runSagasUpdateChat = async (action) => {
    const chat: Chat = action.chat;
    const selected: Chat = chatSelectedSelector(store.getState());
    if (selected) {
        if (chat.id === selected.id) {
            store.dispatch(selectChat(Object.assign({}, chat)));
        }
    }
};
