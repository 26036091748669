import React, { Component } from "react";
import { View, StyleSheet, FlatList, RefreshControl } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import NFTService from "@core/services/NFTService";
import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import Row from "@base/Row";
import ExchangeHistoryCard from "./components/ExchangeHistoryCard";
import ExchangeHistoryService from "./services/ExchangeHistoryService";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import store from "@store/index";
import { loading, ready } from "@store/actions/global";


interface Props {
    navigation: NavigationType
    selectedCurrency: string;
}

interface State {
    items: Array<any>;
    totalPages: number;
    page: number;
    syncing: boolean;
}

const { t } = i18n;

export class _ExchangeHistoryScreen extends Component<Props, State> {
    protected currency: Currency;

    constructor(props: Props) {
        super(props);
        this.currency = Wallet.getInstance().findCurrencyById(this.props.selectedCurrency);
        this.setData = this.setData.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.state = {
            items: [],
            totalPages: 1,
            page: 1,
            syncing: false,
        };
    }

    async componentDidMount() {
        store.dispatch(loading());
        this.setData();
    }

    async setData() {
        const items = await ExchangeHistoryService.getInstance().getExchangeHistory(this.currency);
        if (items && items.docs) {
            this.setState({
                items: items.docs,
                page: items.page,
                totalPages: items.totalPages,
            });
        }
        store.dispatch(ready());
    }

    renderItem(item: any) {
        return <ExchangeHistoryCard
            navigation={this.props.navigation}
            fromCurrency={item.fromCurrency}
            fromAmount={item.fromAmount}
            toCurrency={item.toCurrency}
            toAmount={item.toAmount}
            createdAt={item.createdAt}
            status={item.status}
        />
    }

    async loadMore() {
        if (this.state.page < this.state.totalPages) {
            await this.setData()
        }
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("history")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>

                    <FlatList
                        data={this.state.items}
                        keyExtractor={(item) => item._id}
                        renderItem={({ item }) => this.renderItem(item)}
                        extraData={this.state}
                        refreshControl={
                            <RefreshControl
                                tintColor={colors.text}
                                onRefresh={this.setData}
                                refreshing={this.state.syncing}
                            />
                        }
                        onEndReachedThreshold={1}
                        onEndReached={this.loadMore}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const ExchangeHistoryScreen = connect(mapStateToProps, mapDispatchToProps)(_ExchangeHistoryScreen);

export default ExchangeHistoryScreen;

const styles = StyleSheet.create({

});

