const lightColors = {
    primary: "#000000",
    secondary: "#b7853a",
    complementary: "#b7853a",
    tertiary: "#181819",
    background: "#090913",
    gradientFrom: "#090913",
    gradientTo: "#202124",
    text: "#ffffff",
    grey: "#b0b0ae",
    white: "#ffffff",
    shadow: "rgba(200, 173, 134, 0.1)",
    secondaryShadow: "rgba(200, 173, 134, 0.2)",
    tertiaryShadow: "rgba(71, 66, 56, 0.8)",
    highlightedText: "#ffffff",
    mainButtonsColor: "#ffffff",
    modalBackground: "#ffffff",
    popupBackground: "#090913",
    activeFooter: "#ffffff",
    modalBackgroundFrom: "#090913",
    modalBackgroundTo: "#090913",
    floatButtonText: "#ffffff",
    inlineButtonText: "#ffffff",
    avatarBase: "#b39f81",
    labelText: "#ffffff",
    incognitoButton: "#363638"
};

const darkColors = lightColors;

const settings = {
    // gradientLocations: [0, 0.7],
};

export { lightColors, darkColors, settings };
