import store from "@store/index";
import { Chat } from "@custom-types/Chat";
import { format } from 'date-fns';
import { Message } from "@custom-types/Message";


export function getNameAndIfExistsContact(item: Chat) {
    const contacts = store.getState().chat.contacts;
    const contact = contacts.find((c) => c.client._id.toString().toLowerCase() === item.to._id.toString().toLowerCase())
    let name = (contact) ? contact.name : item.to.alias;
    name = trimHelper(name);

    return {
        exists: (contact),
        name: `@${name}`
    };
}

export function trimHelper(name: string, amount?: number, middle?: boolean) {
    if(middle) {
        try {
            if (!amount || amount >= name.length) {
                return name;
            }

            const start = Math.floor(amount / 2) - 3;
            const end = name.length - start ;

            return `${name.substring(0, start)}...${name.substring(end)}`;
        } catch (e) {
            return '';
        }
    }
    try {
        return (name.length > (amount ? amount : 13)) ? `${name.substring(0, amount ? amount - 1 : 12)}...` : name
    } catch (e) {
        return '';
    }
}

export function messageLengthHelper(message: string) {
    return (message?.length > 23) ? `${message.substring(0, 22)}...` : message
}

export enum MessageAddType {
    start = 'START',
    end = 'END'
}

export enum Participant {
    SENDER = 'SENDER',
    RECEIVER = 'RECEIVER'
}

export function getUnreadMessagesCount(messages: Array<Message>) {
    let cloneMessages = [...messages];

    const me = store.getState().auth.client?._id.toString();
    if (cloneMessages.length === 0) return null;

    const nr = cloneMessages.filter((m: Message) => {
        if ((m.state.getStateNumber() !== 3 && m.author._id !== me)) {
            return true;
        }

    });
    return (nr.length > 0) ? nr.length : null;
}


export function getInitial(chat) {
    try {
        return chat.to.alias[0].toUpperCase()
    } catch (e) {
        return '';
    }
}

export function setTimeVariables(messages: Array<Message>) {
    const time: string = messages[0] ? messages[0].createdAt : null

    if (time != null) {
        const now = new Date();
        const messageTime = new Date(time);
        if (
            messageTime.getDate() == now.getDate() &&
            messageTime.getMonth() == now.getMonth() &&
            messageTime.getFullYear() == now.getFullYear()
        ) {
            return (
                format(new Date(messageTime), 'H:mm a')
            );
        }
        if (
            messageTime.getDate() == now.getDate() - 1 &&
            messageTime.getMonth() == now.getMonth() &&
            messageTime.getFullYear() == now.getFullYear()
        ) {
            return "Yesterday";
        } else {
            return format(new Date(messageTime), 'MMM dd, yyyy')
        }
    } else {
        return "";
    }
}

