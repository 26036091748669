import React, { Component } from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { NavigationType } from "@custom-types/NavigationType";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import Wallet from "@core/wallet/Wallet";
import { colors } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import { SecurityKitNavigatorScreens } from "@navigation/SecurityKitNavigator";

interface Props {
    navigation: NavigationType;
}

interface State {
    words: string[];
}

const { t } = i18n;

export default class SecurityKitSeedScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            words: Wallet.getInstance().getMnemonic(),
        };
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={"Seed"} type={HeaderType.Light} {...this.props} />
                <ScrollView>
                    <Container style={{ flex: 1, paddingBottom: 20 }}>
                        <View>
                            <View>
                                <BoldText style={styles.text}>{t("security_kit_seed_a")}</BoldText>
                                <RegularText style={styles.text}>{t("security_kit_seed_b")}</RegularText>
                                <RegularText style={styles.text}>{t("security_kit_seed_c")}</RegularText>

                                <View style={{ marginTop: 20 }}>
                                    <BoldText style={styles.text}>{t("security_kit_seed_d")}</BoldText>
                                    <RegularText style={styles.text}>{t("security_kit_seed_e")}</RegularText>
                                    <BoldText style={styles.text}>{t("security_kit_seed_f")}</BoldText>
                                    <RegularText style={styles.text}>{t("security_kit_seed_g")}</RegularText>
                                </View>
                            </View>

                            <View style={styles.container}>
                                {this.state.words.map((w) => {
                                    return (
                                        <RegularText key={w} style={styles.word}>
                                            {w}
                                        </RegularText>
                                    );
                                })}
                            </View>
                        </View>

                        <RegularText style={styles.text}>{t("security_kit_seed_h")}</RegularText>

                        <View>
                            <InlineButton
                                style={{ marginTop: 20 }}
                                title={t("continue")}
                                onPress={() =>
                                    this.props.navigation.navigate(SecurityKitNavigatorScreens.StepTwo.routeName)
                                }
                            ></InlineButton>
                        </View>
                    </Container>
                </ScrollView>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    text: {
        paddingVertical: 8,
        textAlign: "justify",
    },
    container: {
        marginVertical: 25,
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "flex-start",
        justifyContent: "center",
    },
    word: {
        padding: 10,
        borderRadius: 10,
        backgroundColor: colors.shadow,
        margin: 5,
        overflow: "hidden",
    },
});
