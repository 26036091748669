import { NotificationModel } from "@custom-types/NotificationModel";
import { getEnv } from "@utils/helpers/global/global";
import axios from "axios";
import { AuthService } from "./AuthService";
import store from "@store/index";
import { loading, ready } from "@store/actions/global";
import { setNotifications, updateNotification } from "@store/actions/notification.actions";
import { ApiService } from "./ApiService";

export class NotificationService {
    private static instance;
    protected resource = "notifications";

    private constructor() {}

    public static getInstance(): NotificationService {
        if (!NotificationService.instance) {
            NotificationService.instance = new NotificationService();
        }

        return NotificationService.instance;
    }

    async getNotifications() {
        try {
            const url = `${getEnv("API_URL")}client/notifications`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            store.dispatch(setNotifications(response.data || []));

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    public async updateReadNotification(notificationId: string): Promise<void> {
        // store.dispatch(loading());
        try {
            const body = {};
            const url = `${getEnv("API_URL")}client/notifications/${notificationId}/read`;
            const response = await axios.post(url, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(updateNotification(notificationId));
            store.dispatch(ready());
            return response.data;
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }
}
