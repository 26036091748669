import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { colors } from "@styles/globalStyles";

import Icon from "@components/icons";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import BoldText from "@base/BoldText";

import store from "@store/index";
import { hideSidebar } from "@store/actions/global";
import Badge from "@base/Badge";
import PressableBase from "@base/PressableBase";

interface Props {
    title: string;
    icon: string;
    screen: string;
    navigation?: any;
    unreadMessages?: any;
    state?: any;
    route?: any;
    counter?: number;
    onPress?: () => Promise<void>
}
interface State {}

export default class SidebarButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.switch = this.switch.bind(this);
    }
    isCurrent: () => boolean = () => {
        return this.props.screen == this.props.state.routes[this.props.state.index].name;
    };

    switch = async () => {
        if(this.props.onPress){
            await this.props.onPress()
        }

        const current = this.isCurrent();

        if (current) {
            try {
                let state = this.props.state;
                let route;
                while (state && state.routes) {
                    const cRoute = state.routes[state.index];
                    if (cRoute.params?.backTo) {
                        route = cRoute.params.backTo;
                        state = null;
                    } else {
                        if (state.index > 0 && state.type != "tab") route = state.routes[state.index - 1].name;
                        state = state.routes[state.index].state;
                    }
                }
                if (route) {
                    this.props.navigation.navigate(route);
                }
            } catch (e) {
                console.warn(e);
            }
        }

        if (!current) {
            this.props.navigation.navigate(this.props.screen);
        }
        store.dispatch(hideSidebar());
    };

    render() {
        return (
            <PressableBase onPress={this.switch}>
                <Row style={styles.button}>
                    <View style={{ width: 40 }}>
                        <Icon
                            name={this.props.icon}
                            size={22}
                            color={this.isCurrent() ? colors.complementary || colors.secondary : colors.text}
                            align="center"
                        />
                    </View>
                    <View>
                        {this.isCurrent() ? (
                            <BoldText
                                numberOfLines={1}
                                color={colors.complementary || colors.secondary}
                                align={"center"}
                                style={{ paddingLeft: 12 }}
                            >
                                {this.props.title}
                            </BoldText>
                        ) : (
                            <RegularText
                                numberOfLines={1}
                                color={colors.text}
                                align={"center"}
                                style={{ paddingLeft: 12 }}
                            >
                                {this.props.title}
                            </RegularText>
                        )}
                        <Badge
                            size={10}
                            count={this.props.counter}
                            color={colors.complementary || colors.secondary}
                            containerSyle={{ right: -30, bottom: 10, minWidth: 25 }}
                            smallBadgeContainer={{ right: -28, bottom: 8 }}
                            badgeStyle={{
                                borderWidth: 0,
                                borderRadius: 12,
                                paddingHorizontal: 8,
                                paddingBottom: 3,
                                paddingTop: 2,
                                borderBottomLeftRadius: 1,
                            }}
                            smallBadgeStyle={{ borderWidth: 0, borderRadius: 6, overflow: "hidden", height: 12, width: 12 }}
                        ></Badge>
                    </View>
                </Row>
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({
    button: {
        //flex: 1,
        height: 55,
        alignContent: "center",
        alignItems: "center",
        paddingVertical: 10,

        //backgroundColor: colors.red
    },
    current: {
        color: colors.text,
    },
});
