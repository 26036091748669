import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { BuyNavigatorScreens } from "@navigation/BuyNavigator";
import RegularText from "@base/RegularText";
import FloatingTextInput from "@base/FloatingTextInput";
import InlineButton from "@base/InlineButton";
import Coincaex from "@assets/images/Coincaex";
import store from "@store/index";
import { showSnackbar } from "@store/actions/global";
import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator";
import { colors } from "@styles/globalStyles";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { CoincaexService } from "@core/services/CoincaexService";
import { Route } from "@react-navigation/native";
import { CoincaexSignin } from "../helper/coincaex.helper";

interface Props {
    navigation: NavigationType;
    route: Route<string, { login?: CoincaexSignin }>;
    currencies: Array<Currency>;
    selectedCurrency: string;
}

interface State {
    login: CoincaexSignin;
}

const { t } = i18n;

export class _CoincaexLoginScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            login: {
                email: this.props.route?.params?.login?.email || "",
                password: this.props.route?.params?.login?.password || "",
            },
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.route?.params !== prevProps.route.params) {
            this.setState({
                login: {
                    email: this.props.route?.params?.login?.email || this.state.login.email,
                    password: this.props.route?.params?.login?.password || this.state.login.password,
                },
            });
        }
    }

    handleInputChange = (value: string, field: string) => {
        this.setState({
            ...this.state,
            login: { ...this.state.login, [field]: value },
        });
    };

    handleLogin = async (register: string) => {
        if (register == "signin") {
            const { email, password } = this.state.login;

            if (!email || !password) {
                store.dispatch(showSnackbar({ type: "ERROR", message: t("complete_field_warning") }));
            } else {
                const resp = await CoincaexService.getInstance().signin(this.state.login);
                if (resp) {
                    this.props.navigation.navigate(CoincaexNavigatorScreens.CoincaexHome.routeName);
                }
            }
        }

        if (register == "signup") {
            this.props.navigation.navigate(CoincaexNavigatorScreens.SignUp.routeName);
        }
    };

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={`${t("sign_in")}`}
                    type={HeaderType.Light}
                    backTo={WalletNavigatorScreens.WalletMain.routeName}
                    {...this.props}
                />
                <Container>
                    <View style={{ alignItems: "center" }}>
                        <Coincaex />
                    </View>
                    <FloatingTextInput
                        onChangeText={(text) => this.handleInputChange(text, "email")}
                        value={this.state.login.email}
                        label={t("email")}
                        multiline={false}
                        autoCapitalize={'none'}
                    />
                    <FloatingTextInput
                        onChangeText={(text) => this.handleInputChange(text, "password")}
                        value={this.state.login.password}
                        label={t("password")}
                        multiline={false}
                        secureTextEntry={true}
                        autoCapitalize={'none'}
                    />
                    <InlineButton
                        style={{ marginTop: 5, marginBottom: 10 }}
                        title={t("sign_in")}
                        onPress={() => this.handleLogin("signin")}
                    />
                    <RegularText align="center">{t("or")}</RegularText>
                    <InlineButton
                        style={styles.secondaryButton}
                        title={t("sign_up")}
                        onPress={() => this.handleLogin("signup")}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    secondaryButton: {
        marginVertical: 10,
        backgroundColor: "transparent",
        borderWidth: 1,
        borderColor: colors.secondary,
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const CoincaexLoginScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexLoginScreen);

export default CoincaexLoginScreen;
