import { StyleSheet, View, Keyboard, ViewStyle, ActivityIndicator } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import RegularTextInput from "@base/RegularTextInput";
import React, { Component } from "react";
import Icon from "@components/icons";
import i18n from "@i18n/i18n";
import { VibrationType, vibrate } from "@utils/vibration/vibration.helper";
import PressableBase from "@base/PressableBase";

interface Props {
    onSearch: (value) => void;
    value: string;
    searching?: boolean;
    containerStyle?: ViewStyle;
}

interface State {
    value: string;
}

const { t } = i18n;

export default class SearchBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onSearch = this.onSearch.bind(this);
        this.cleanSearch = this.cleanSearch.bind(this);
        this.state = {
            value: "",
        };
    }

    onSearch(value: string) {
        this.setState({ value: value });
        this.props.onSearch(value);
    }

    cleanSearch() {
        vibrate(VibrationType.ONPRESS);
        this.onSearch("");
        Keyboard.dismiss();
    }

    render() {
        return (
            <View style={this.props?.containerStyle}>
                <View style={styles.searchBox}>
                    <View style={{ width: 50, justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                        <Icon
                            name={"search"}
                            size={20}
                            color={colors.text}
                            align="left"
                            style={[{ opacity: this.props.searching ? 0.5 : 1 }]}
                        />
                    </View>
                    <RegularTextInput
                        style={styles.input}
                        onChangeText={(value) => {
                            this.onSearch(value);
                        }}
                        value={this.props.value}
                        placeholder={t("search")}
                    />
                    {this.props.value?.length > 0 && !this.props.searching && (
                        <PressableBase
                            style={{
                                width: 35,
                                height: 35,
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                            }}
                            onPress={this.cleanSearch}
                        >
                            <View style={styles.iconRight}>
                                <Icon name={"x-lg"} size={14} color={colors.labelText || colors.text} align="left" />
                            </View>
                        </PressableBase>
                    )}
                    {this.props.value?.length > 0 && this.props.searching && (
                        <ActivityIndicator style={{ paddingRight: 15 }} size={24} color={colors.text} />
                    )}
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    searchBox: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        flexDirection: "row",
        alignItems: "center",
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        paddingRight: 20,
      
    },
 
    iconRight: {
        position: "absolute",
        right: 10,
        width: 24,
        height: 24,
        backgroundColor: colors.secondary,
        padding: 5,
        borderRadius: 12,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        overflow: "hidden",
    },
    button: {
        position: "absolute",
        width: 30,
        height: 30,
        right: 15,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        borderColor: "white",
        borderWidth: 1,
    },
});
