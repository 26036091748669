// React 
import React, { Component } from 'react';
import { BackHandler, Platform, StyleSheet, View } from 'react-native';

// Types
import { NavigationType } from '@custom-types/NavigationType'
import { HeaderType } from '@custom-types/HeaderType';
import { NotificationModel } from '@custom-types/NotificationModel';

// Components
import ScreenWrapper from '@components/wrapper/ScreenWrapper';
import Header from '@components/header/Header';
import WebViewBase from "@components/webview/WebViewBase";

// Service 
import { NotificationService } from '@core/services/NotificationService';

// Base
import BoldText from '@base/BoldText';
import RegularText from '@base/RegularText';
import Container from '@base/Container';
import Row from '@base/Row';

// Store
import { connect } from 'react-redux';
import store from '@store/index';
import { updateNotification } from '@store/actions/notification.actions';

import i18n from '@i18n/i18n';
import Card from '@base/Card';
import { format } from 'date-fns';
import { colors } from '@styles/globalStyles';

interface Props {
    navigation: NavigationType;
    route: any
}

interface State { notification: NotificationModel }

const { t } = i18n

export class _NotificationDetailScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            notification: this.props.route?.params?.notification,
        }
        if (Platform.OS != 'web')
            BackHandler.addEventListener('hardwareBackPress', () => this.props.navigation.isFocused());
    }

    componentDidMount(): void {
        NotificationService.getInstance().updateReadNotification(this.state.notification._id
        )
    }

    render() {
        return (
            <ScreenWrapper style={styles.container}>
                <Header title={t("notifications")} type={HeaderType.Light} {...this.props} />
                <ScreenWrapper type='scroll'>
                    <Container>
                        <Card>
                            <Row style={[styles.row, { marginBottom: 20 }]}>
                                <View style={styles.tagDate}>
                                    <RegularText color={colors.white} fontSize={10}>{format(new Date(this.state.notification.createdAt), "MMM dd, yyyy")}</RegularText>
                                </View>
                            </Row>
                            <Row style={[styles.row, { marginBottom: 10 }]}>
                                <BoldText fontSize={16}>{this.state.notification.title}</BoldText>
                            </Row>
                            <Row style={styles.row}>
                                <RegularText fontSize={14}>{this.state.notification.description}</RegularText>
                            </Row>
                        </Card>
                    </Container>
                </ScreenWrapper>
            </ScreenWrapper >
        );
    }
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({});

const NotificationDetailScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_NotificationDetailScreen);

export default NotificationDetailScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    row: {
        width: "100%",
    },
    tagDate: {
        paddingVertical: 2,
        paddingHorizontal: 4,
        backgroundColor: colors.secondary,
        borderRadius: 5,
    }
})
