import * as React from "react"
import Svg, {Path, G, Stop, Defs, LinearGradient} from "react-native-svg"

function Logo(props) {
    return (
        <Svg xmlns='http://www.w3.org/2000/svg'  height={50} width={200} viewBox='0 0 400 100' {...props}>
            <G
                style={{
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "49.2778px",
                    lineHeight: 0,
                    fontFamily: "sans-serif",
                    fillOpacity: 1,
                    stroke: "none",
                    strokeWidth: 1.23195,
                }}
                aria-label='LOCKER TOKEN'>
                    <Defs>
                <LinearGradient
                    gradientTransform='rotate(0)'
                    id={'grad1'}>
                    <Stop offset='0%' stopColor='#bea054' />
                    <Stop offset='50%' stopColor='#ffda95' />
                    <Stop offset='100%' stopColor='#bea054' />
                </LinearGradient>
                </Defs>
                <Path
                    fill={"url(#grad1)"}
                    style={{
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: 700,
                        fontStretch: "normal",
                        lineHeight: 0.45,
                        fontFamily: "'Franklin Gothic Heavy'",
                        InkscapeFontSpecification: "'Franklin Gothic Heavy, Bold'",
                        strokeWidth: 1.23195,
                    }}
                    d='M35.182 63.916v8.06H12.156V39.134h10.081v24.783ZM52.507 72.482q-6.93 0-11.31-4.596-4.354-4.596-4.354-12.295 0-7.363 4.162-12.151 4.187-4.813 11.598-4.813 6.857 0 11.164 4.524 4.331 4.524 4.331 12.079 0 7.82-4.355 12.536-4.355 4.716-11.236 4.716zm-.048-7.46q2.55 0 3.657-2.213 1.13-2.238 1.13-8.157 0-8.566-4.595-8.566-4.957 0-4.957 9.77 0 9.167 4.765 9.167zM90.476 59.44l9.215.554q-.65 6.04-4.475 9.264-3.802 3.224-9.36 3.224-6.69 0-10.804-4.427-4.114-4.428-4.114-12.224 0-7.723 3.898-12.463 3.898-4.74 10.875-4.74 6.521 0 10.01 3.609 3.513 3.609 3.97 9.576l-9.408.505q0-3.296-1.275-4.764-1.251-1.468-2.96-1.468-4.764 0-4.764 9.577 0 5.366 1.227 7.29 1.251 1.925 3.49 1.925 3.993 0 4.475-5.437zM133.016 71.977h-11.044l-5.678-12.416-3.393 4.836v7.58h-9.504V39.133h9.504v13.57l9.071-13.57h9.047l-8.301 12.006zM160.446 64.133v7.844h-24.71V39.133h24.71v7.58h-14.822v4.763h11.983v7.29h-11.983v5.367zM193.651 71.977h-11.189l-4.788-12.56h-3.176v12.56h-10.082V39.133h17.156q5.679 0 8.807 2.67 3.152 2.647 3.152 7.002 0 2.84-1.155 5.03-1.155 2.165-4.668 3.825zM174.498 52.39h4.307q1.901 0 3.056-.794t1.155-2.334q0-3.152-3.874-3.152h-4.644zM224.209 47v24.977h-9.552V47h-6.81v-7.868h23.171V47zM248.102 72.482q-6.93 0-11.309-4.596-4.355-4.596-4.355-12.295 0-7.363 4.163-12.151 4.186-4.813 11.597-4.813 6.858 0 11.165 4.524 4.33 4.524 4.33 12.079 0 7.82-4.354 12.536-4.355 4.716-11.237 4.716zm-.048-7.46q2.55 0 3.657-2.213 1.131-2.238 1.131-8.157 0-8.566-4.596-8.566-4.956 0-4.956 9.77 0 9.167 4.764 9.167zM297.596 71.977h-11.044l-5.678-12.416-3.393 4.836v7.58h-9.504V39.133h9.504v13.57l9.071-13.57h9.047l-8.3 12.006zM325.026 64.133v7.844h-24.71V39.133h24.71v7.58h-14.821v4.763h11.982v7.29h-11.982v5.367zM357.076 71.977h-8.205l-8.999-14.51q-1.732-2.79-3.489-6.785.794 3.176.794 5.39v15.905h-8.18V39.133h9.528l7.651 12.03q2.286 3.586 3.465 6.305-.77-4.211-.77-8.614v-9.721h8.205z'
                    transform='scale(1.09025 .91722)'
                />
            </G>
        </Svg>
    )
}

export default Logo
