import { ExperienceStatus, RedeemableType } from "@custom-types/ExpercienceModel";
import i18n from "@i18n/i18n";
import { colors } from "@styles/globalStyles";

const { t } = i18n;

export const getRedeemableIcon = (redeemableType: RedeemableType) => {
    switch (redeemableType) {
        // case RedeemableType.MEMBERSHIP:
        //     return "💎";

        case RedeemableType.TICKET:
            return "🎫";

        case RedeemableType.VOUCHER:
            return "🎁";
        default:
            return "🔥";
    }
};

export const getRedeemableColorStatus = (redeemableStatus: ExperienceStatus) => {
    switch (redeemableStatus) {
        case ExperienceStatus.ASSIGNED:
            return colors.green;
        case ExperienceStatus.CREATED:
            return colors.yellow;
        case ExperienceStatus.TRANSFERED:
            return colors.green;
        case ExperienceStatus.USED:
            return colors.complementary;
        case ExperienceStatus.EXPIRED:
            return colors.red;
        default:
            return colors.secondary;
    }
};

export const getHistoryStatusIcon = (redeemableStatus: ExperienceStatus ) => {
    switch (redeemableStatus) {
        case ExperienceStatus.ASSIGNED:
            return "person-check";
        case ExperienceStatus.CREATED:
            return "gem";
        case ExperienceStatus.TRANSFERED:
            return "signpost-split";
        case ExperienceStatus.USED:
            return "fire";
        case ExperienceStatus.EXPIRED:
            return "password";
        default:
            return "password";
    }
};
