import React, { Component } from "react";
import { View, StyleSheet, Text, InteractionManager, Platform } from "react-native";
import RegularText from "@base/RegularText";
import { BoldText } from "@base/BoldText";
import Currency from "@core/currencies/Currency";
import Card from "@base/Card";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import Icon from "@components/icons";
import { colors } from "@styles/globalStyles";
import { AreaChart } from "react-native-svg-charts";
import * as shape from "d3-shape";
import Row from "@base/Row";
import {
    getPercentagePriceVariationColor,
    getPercentagePriceVariationDaily,
} from "@utils/helpers/currency/currency.helper";
import { getColorOpacity } from "@utils/helpers/global/global";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import Wallet from "@core/wallet/Wallet";
import AbstractCurrency from "@core/currencies/AbstractCurrency";
import SemiBoldText from "@base/SemiBold";

interface Props {
    fiatCurrencyId: string;
}

interface State {
    fiatCurrency: AbstractCurrency
}

export default class FiatCurrencyCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            fiatCurrency: null
        };
    }

    componentDidMount(): void {
        const currency = Wallet.getInstance().findFiatCurrencyById(this.props.fiatCurrencyId)
        this.setState({ fiatCurrency: currency })
    }

    render() {
        return (
            <Card
                style={{ alignItems: "center" }}
                icon={
                    <CurrencyIcon styles={{ marginRight: 15 }} currency={this.state.fiatCurrency} />
                }
                left={
                    this.state.fiatCurrency &&
                    <View>
                        <SemiBoldText>{this.state.fiatCurrency?.getSymbol()}</SemiBoldText>
                        <RegularText style={{ textTransform: "capitalize", marginTop: 2 }} fontSize={14} color={colors.grey}>{this.state.fiatCurrency?.getName()}</RegularText>
                    </View>
                }
            />
        );
    }
}

const styles = StyleSheet.create({
    left: {
        flex: 0.5,
    },
    right: {
        flex: 0.5,
    },
    leftDesc: {
        lineHeight: 18,
    },
    rightDesc: {
        textAlign: "right",
    },
});

