import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import { ProfileSections } from "@custom-types/ProfileType";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import Container from "@base/Container";
import WebViewXOConnect from "@components/webview/WebViewXOConnect";
import WebViewBase from "@components/webview/WebViewBase";

interface Props {
    navigation: NavigationType;
    client: Client;
    route: any
}

interface State {
    url: string
    xoConnect: boolean
}

const { t } = i18n;

export enum METHODS {
    "available" = "available",
    "connect" = "connect",
    "personalSign" = "personalSign",
    "transactionSign" = "transactionSign",
}

export enum TYPES {
    "request" = "request",
    "response" = "response",
}

export class _XoConnectScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const params = this.props.route.params
        this.state = {
            url: params.url,
            xoConnect: params.xoConnect
        }
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    type={HeaderType.Light}
                    leftAvatar={{
                        onPress: () => {
                            this.props.navigation.navigate(HomeNavigatorScreens.Profile.routeName, {
                                screen: ProfileNavigatorScreens.ProfileMain.routeName,
                                params: {
                                    profileSection: ModuleControlService.getInstance().isModuleEnabled(
                                        Modules.poapsModule
                                    )
                                        ? ProfileSections.poapsProfile
                                        : ProfileSections.socialNetwork,
                                },
                            });
                        },
                        uri:
                            this.props.client &&
                            this.props.client?.profileImagePath &&
                            this.props.client?.profileImagePath !== null
                                ? this.props.client?.profileImagePath.thumbnail
                                : "",
                        alias: this.props.client && this.props.client?.alias ? this.props.client?.alias : "",
                        size: 35,
                    }}
                    {...this.props}
                />

                <Container style={{ flex: 1, paddingHorizontal: 0 }}>
                    {this.state.xoConnect &&
                        <WebViewXOConnect 
                            source={this.state.url}
                        />
                    }
                    {!this.state.xoConnect &&
                        <WebViewBase
                            source={this.state.url}
                        />
                    }
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const XoConnectScreen = connect(mapStateToProps, mapDispatchToProps)(_XoConnectScreen);

export default XoConnectScreen;
