import SecurityScreen from "@screens/settings/security/SecurityScreen"
import NavigationInstantiator from "./helpers/NavigationInstantiator"


const SecurityNavigatorScreens = {
    Security: {
        routeName: "Security",
        title: "Security",
        component: SecurityScreen,
    },
}

const SecurityNavigator = NavigationInstantiator(SecurityNavigatorScreens)

export {SecurityNavigator, SecurityNavigatorScreens}
