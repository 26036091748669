import Currency, { IConstructorCurrency } from "@core/currencies/Currency";
import { Networks } from "@core/network/Networks";
import XOImplementation from "./XOImplementation";

export default class CentralizedCurrency extends Currency {
    constructor(parameters: IConstructorCurrency) {
        super({
            ...parameters,
            decimals: Number(parameters.decimals),
            implementation: XOImplementation,
            network: parameters.testnet ? Networks.bitcoin.testnet : Networks.bitcoin.mainnet,
        });
    }
}
