import { NotificationModel } from "@custom-types/NotificationModel";
import { getEnv } from "@utils/helpers/global/global";
import axios from "axios";
import { AuthService } from "./AuthService";
import store from "@store/index";
import { loading, ready } from "@store/actions/global";
import { setNotifications, updateNotification } from "@store/actions/notification.actions";
import { ApiService } from "./ApiService";
import { setBlogs } from "@store/actions/blog.actions";
import { ModuleControlService, Modules } from "./ModuleControlService";

export class BlogService {
    private static instance;

    private constructor() {}

    public static getInstance(): BlogService {
        if (!BlogService.instance) {
            BlogService.instance = new BlogService();
        }

        return BlogService.instance;
    }

    async getBlogs(loadMore?: boolean) {
        if (!ModuleControlService.getInstance().isModuleEnabled(Modules.blogModule)) {
            return;
        }
        try {
            if (loadMore) {
                let data = store.getState().blogs.blogs;

                if (data.paginator?.page < data.paginator?.totalPages) {
                    const url = `${getEnv("API_URL")}blog?&__skip=${data.paginator?.page * 10}`;
                    const response = await axios.get(url, {
                        headers: await ApiService.getAuthHeaders(),
                    });
                    store.dispatch(
                        setBlogs(data.docs.concat(response?.data?.docs), {
                            page: response?.data?.page,
                            totalDocs: response?.data?.totalDocs,
                            totalPages: response?.data?.totalPages,
                        })
                    );
                }
                return;
            }

            const url = `${getEnv("API_URL")}blog`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            store.dispatch(
                setBlogs(response?.data?.docs, {
                    page: response?.data?.page,
                    totalDocs: response?.data?.totalDocs,
                    totalPages: response?.data?.totalPages,
                })
            );

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    getBlog = async (id: string) => {
        try {
            const url = `${getEnv("API_URL")}blog/${id}`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            return response?.data;
        } catch (e) {}
    };
}
