import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import TransactionType from "@custom-types/TransactionType";
import { getEnv } from "@utils/helpers/global/global";
import axios from "axios";
import Web3 from "web3";
import { ApiService } from "./ApiService";
import store from "@store/index";
import { ready, showPopup } from "@store/actions/global";
import i18n from "@i18n/i18n";
const web3 = new Web3();

const { t } = i18n;

export class DigitalCurrenciesService {
    private static instance;

    public static getInstance(): DigitalCurrenciesService {
        if (!DigitalCurrenciesService.instance) {
            DigitalCurrenciesService.instance = new DigitalCurrenciesService();
        }

        return DigitalCurrenciesService.instance;
    }

    // GET BALANCE ALL CURRENCIES
    async syncAllBalances(wallet: Wallet) {
        try {
            const params = await getMultiFormatParams();
            const url = `${getEnv("API_URL")}digital-currencies/v2/balances/all${params}`;
            const result = await axios.get(url, { headers: await ApiService.getAuthHeaders() });
            const currenciesBalances = result?.data || [];

            currenciesBalances.map((c) => {
                let currency: Currency = Wallet.getInstance().findCurrencyById(c.digitalCurrencyId);
                if (currency) {
                    currency.setBalance(c.balance);
                }
            });
        } catch (e) {}
    }

    // GET CURRRENCY BALANCE
    async syncBalance(currency: Currency) {
        try {
            const params = getCurrencyParams(currency, true);
            const url = `${getEnv("API_URL")}digital-currencies/v2/balances${params}`;
            const response = await axios.get(url, { headers: await ApiService.getAuthHeaders() });
            return response;
        } catch (e) {}
    }

    // GET CURRENCY TRANSACTIONS
    async getTransactions(currency: Currency) {
        try {
            const params = getCurrencyParams(currency, false);
            const url = `${getEnv("API_URL")}digital-currencies/transactions${params}`;
            const response = await axios.get(url, { headers: await ApiService.getAuthHeaders() });
            return response;
        } catch (e) {}
    }

    // GET CURRENCY TRANSACTION (HASH)
    async getTransaction(currency: Currency, txId: string) {
        try {
            const params = getCurrencyParams(currency, false);
            const url = `${getEnv("API_URL")}digital-currencies/transactions/${txId}/${params}`;
            const resp = await axios.get(url, { headers: await ApiService.getAuthHeaders() });
            return resp?.data;
        } catch (e) {
            store.dispatch(ready());
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
        }
    }

    // GET TRANSACTION SKELETON
    async getSkeleton(currency: Currency, transaction: TransactionType) {
        const body = getSkeletonBody(currency, transaction);
        const url = `${getEnv("API_URL")}digital-currencies/transfers/skeleton`;
        return await axios.post(url, body, { headers: await ApiService.getAuthHeaders() });
    }

    // POST TRANSACTION BROADCAST
    async sendBroadCast(signedTransaction, skeleton) {
        const body = {
            txs: signedTransaction,
            skeletonId: skeleton._id,
        };
        const url = `${getEnv("API_URL")}digital-currencies/transfers/broadcast`;
        return await axios.post(url, body, { headers: await ApiService.getAuthHeaders() });
    }

    // SET CURRENCY ENABLED
    static async setEnabledCurrency(currency: Currency, enabled: boolean) {
        try {
            const body = { digitalCurrencyId: currency.getId(), enabled: enabled };

            const resp = await axios.post(`${getEnv("API_URL")}v3/client/digital-currency`, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            if (resp && resp?.data) {
                return resp?.data?.enabled;
            }
        } catch (e) {
            console.warn(e);
        }
    }

    // GET EXCHANGE ≈
    async newSwap(fromCurrency: string, toCurrency: string, amount: string) {
        const url = `${getEnv("API_URL")}digital-currencies/exchange`;
        const { data } = await axios.post(
            url,
            { fromCurrency, toCurrency, amount },
            { headers: await ApiService.getAuthHeaders() }
        );
        return data;
    }
}

//PARAMS GENERATOR
const getMultiFormatParams = async () => {
    let params = "";
    try {
        let currencies: Array<Currency> = Wallet.getInstance().getCurrencies();
        currencies = currencies?.filter((c) => {
            return c.isMultiFormat();
        });

        if (currencies?.length) {
            params = "?";
            currencies?.map((c) => {
                params = `${params}blockchain=${c.getBlockchain()?.toLowerCase()}&symbol=${c.getSymbol()}&address=${c
                    ?.getFormats()
                    ?.map((f) => c.getAddress({ format: f }))
                    ?.join()}`;
            });
        }

        return params;
    } catch {
        return params;
    }
};

const getCurrencyParams = (currency: Currency, multiFormatEnabled: boolean) => {
    if (!currency) {
        return "";
    }
    let addresses = "";

    if (currency.isMultiFormat()) {
        try {
            if (!multiFormatEnabled) {
                addresses = currency.getAddress({ format: currency?.getFormats()[0] });
            } else {
                let a = currency?.getFormats()?.map((f) => currency.getAddress({ format: f }));
                addresses = a.join();
            }
        } catch {}
    } else {
        addresses = currency.getAddress();
    }

    return `?network=${Wallet.getInstance()
        .getNetworkType()
        .toLocaleLowerCase()}&blockchain=${currency.getBlockchain()}&symbol=${currency.getSymbol()}&address=${addresses}`;
};

const getSkeletonBody = (currency: Currency, transaction: TransactionType) => {
    let body = {};
    try {
        let value = currency.toDecimals(transaction.amount);

        body = {
            digitalCurrencyId: currency.getId(),
            toAddress: transaction.addressTo,
            value: value || 0,
            feeType: "LOW",
        };
        return body;
    } catch (e) {
        return body;
    }
};
