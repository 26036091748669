import { Message, messageSchema } from '@custom-types/Message';
import {Client} from "@custom-types/Client";
import store from "@store/index";
import { BaseModel } from "@custom-types/BaseModel";
import { schema } from "normalizr";

export class Chat implements BaseModel {
    id: string;
    _id: string;
    messages: Array<string>;
    to: Client;
    from: Client;
    unread: number;
    lastMessage: string;
    messagesCount: number;

    public static create(data) {
        const chat = new Chat();
        const clientId = store.getState().auth.client.id;

        chat._id = data._id;
        if (data.hasOwnProperty('_id')) {
            chat.id = data._id;
        }

        if (data.hasOwnProperty('members') && Array.isArray(data.members)) {
            chat.to = data.members.find(m => m._id !== clientId);
            chat.from = data.members.find(m => m._id === clientId);
        }

        chat.messages = (data.messages) ? data.messages : [];

        chat.lastMessage = (data.messages && data.messages[0]) ? data.messages[0].createdAt : null;
        chat.unread = data.messages.filter(m => m.state === 1).length;
        chat.messagesCount = data.messagesCount;

        return chat;
    }
}

export const chatScheme = new schema.Entity('chats', {messages: [messageSchema]}, {idAttribute: '_id'})

