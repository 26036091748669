import React, {Component} from 'react';
import {View, StyleSheet, Text, TextStyle, Platform} from 'react-native';
import RegularText from '@base/RegularText';
import {BoldText} from '@base/BoldText';
import { colors, settings } from '@styles/globalStyles';
import {SimpleLineIcons} from '@expo/vector-icons'
import Row from './Row';
import Card from './Card';
import i18n from "@i18n/i18n"


interface Props {
    left?: any,
    right?: any,
    icon?: any,
    onPress?: () => void,
    style?: TextStyle,
    message: string,
}

interface State {
    onPress: () => void
}

const { t } = i18n

export default class WarningCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            onPress: props.onPress ? props.onPress : null
        }
    }

    render() {
        return (
            <Card>
                <BoldText color={colors.text} align='center'>{t('warning')}</BoldText>
                <RegularText color={colors.text} align='justify' fontSize={14}>
                    {this.props.message}
                    {this.props.children}
                </RegularText>
            </Card>
        );
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        paddingHorizontal: 20,
        paddingVertical: 14,
        alignItems: 'center',
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        marginVertical: 5,
    },
    row: {
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    left: {
        flex: 0.5
    },
    right: {
        flex: 0.5,
        alignItems: 'flex-end'
    },
    leftTitle: {
        fontSize: 20,
        lineHeight: 22,
        marginTop: 7
    },
    leftDesc: {
        lineHeight: 20,
        paddingLeft: 2
    },
    rightDesc: {
        textAlign: 'right'
    }
})
