import { RELOAD } from "@store/actions/global";
import { ADD_MESSAGE, ADD_MESSAGES, UPDATE_MESSAGE } from "@store/actions/message.actions";

interface state {
    items: {};
}

const initialState: state = {
    items: {},
};

export const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_MESSAGE:
            return {
                ...state,
                items: {...state.items, ...{[action.message.id]: action.message}}
            };

        case ADD_MESSAGES:
            return {
                ...state,
                items: {...state.items, ...action.messages}
            }

        case UPDATE_MESSAGE:
            return {
                ...state,
                items: {...state.items, ...{[action.id]: action.message}}
            };

        case RELOAD:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};
