import { OAuthService } from "@core/services/oauth/OAuthService";
import { LoginNavigatorScreens } from "@navigation/LoginNavigator";
import ImportScreen from "@screens/settings/seed/import/ImportScreen";
import { loading } from "@store/actions/global";
import store from "@store/index";

export default class ImportSeedExtendedScreen extends ImportScreen {
    constructor(props) {
        super(props);
    }

    navigateToScanner() {
   
        this.props.navigation.navigate(LoginNavigatorScreens.SeedScanner.routeName);
    }

    async onPressImport() {
        store.dispatch(loading())
        if (this.state.words?.length == 12) {
            await OAuthService.getInstance().newAlias(this.state.words.join(" "));
            this.props.navigation.navigate(LoginNavigatorScreens.SetProfile.routeName);
        } else {
            this.setState({ showWordsError: true });
            setTimeout(() => {
                this.setState({ showWordsError: true });
            }, 2000);
        }
    }
}
