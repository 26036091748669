import store from "@store/index";
import { AppState } from "react-native";
import { hideModal, showModal } from "@store/actions/global";
import i18n from "@i18n/i18n";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import Wallet from "@core/wallet/Wallet";
import SeedHistorySecureStorage from "@utils/storage/storages/SeedHistorySecureStorage";

const { t } = i18n;

export const initSecurityKit = async (navigation) => {
   
    setTimeout(async () => {
        const mnemonic = Wallet.getInstance().getMnemonic().join(" ") || "";
        const seedHistory = (await Wallet.getInstance().getSeedHistorySecureStorage()) || [];

        const filteredSeedHistoryState = seedHistory.find((m) => {
            return m.mnemonic == mnemonic;
        });

        if (filteredSeedHistoryState && !filteredSeedHistoryState.isWritten) {
            const appStatus = AppState.currentState;
            if (appStatus == "active") {
                showSecurityModal(navigation);
            }
        }
    }, 20000);
};

const showSecurityModal = async (navigation) => {
    store.dispatch(
        showModal({
            avatarIcon: "robot",
            title: t("hi"),
            message: t("security_kit_a"),
            subButtonTitle: t("security_kit_b"),
            question: t("security_kit_c"),
            btnTitle: t("continue"),
            onPressSubButton: ()=> onPressCancel(),
            onPress: () => onPressModal(navigation),
        }),
    );
};
const onPressCancel = async () => {
    const mnemonic = Wallet.getInstance().getMnemonic() || "";
    setStoreSuccess(mnemonic)
    store.dispatch(hideModal());
}
const onPressModal = (navigation) => {
    navigation.navigate(WalletNavigatorScreens.SecurityKit.routeName);
    store.dispatch(hideModal());
};

export const setStoreSuccess = async (mnemonic) => {
    const seedHistory = (await Wallet.getInstance().getSeedHistorySecureStorage()) || [];

    const newState = seedHistory.map((s) => {
        if (s.mnemonic == mnemonic.join(" ")) {
            return {
                mnemonic: s.mnemonic,
                clientId: s.clientId,
                isWritten: true,
            };
        } else {
            return s;
        }
    });

    await SeedHistorySecureStorage.set(newState);
};
