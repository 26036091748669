import { StyleSheet, Appearance } from 'react-native';

const darkColors = {
    primary: '#51b5e0',
    secondary: '#51b5e0',
    tertiary: '#004795', 
    background: '#005bbf',
    gradientFrom: '#005bbf',
    gradientTo: '#005bbf',
    grey: '#9091a4',
    white: 'white',
    black: '#333',
    blue: '#2696fb',
    green: '#1DBC60',
    yellow: '#FECA54',
    red: '#EE4540',
    text: 'white',
    shadow: 'rgba(255,255,255,0.04)',
    secondaryShadow: 'rgba(0, 0, 0, 0.1)',
    tertiaryShadow: 'rgba(255,255,255,0.04)'
};

const lightColors = {
    primary: '#51b5e0',
    secondary: '#51b5e0',
    complementary: '#51b5e0',
    tertiary: '#004795', 
    background: '#005bbf',
    gradientFrom: '#3ba1d4',
    gradientTo: '#004795',
    grey: '#99b4c0',
    white: 'white',
    black: '#282d26',
    blue: '#2696fb',
    green: '#1DBC60',
    yellow: '#FECA54',
    red: '#EE4540',
    text: 'white',
    shadow: 'rgba(255, 255, 255, 0.09)',
    secondaryShadow: 'rgba(0, 0, 0, 0.2)',
    tertiaryShadow: 'rgba(0, 0, 0, 0.05)'
};

const settings = {}

export { lightColors, darkColors, settings }