import SelectCurrencyScreen from "../shared/SelectCurrencyScreen";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";


class _SelectCurrencyToScreen extends SelectCurrencyScreen {
    constructor(props){
        super(props);
        this.setFrom(false);
    }
}

const mapStateToProps = (state) => {
    const from = state.wallet.selectedExchangeFrom;
    let currencies = Wallet.getInstance().getCurrencies();
    if(from){
        let pairs = from.getPairs().map(pair => pair.name.toUpperCase());
        currencies = currencies.filter((x: Currency) => {
            return (x.getId() != from.getId()) && (pairs.includes(x.getId()));
        }); 
    }
    return {
        currencies: currencies,
        fiatCurrency: state.wallet.fiatCurrency,
        from: state.wallet.selectedExchangeFrom,
        to: state.wallet.selectedExchangeTo,
    };
};

const mapDispatchToProps = (dispatch) => ({
    
});

const SelectCurrencyToScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_SelectCurrencyToScreen);
  
export default SelectCurrencyToScreen;