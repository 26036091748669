import { NavigationType } from "@custom-types/NavigationType";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import i18n from "@i18n/i18n";
import BoldText from "@base/BoldText";
import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import AvatarBase from "@components/avatar/AvatarBase";
import Icon from "@components/icons";
import ImageBase from "@components/image/ImageBase";
import ExperienceService from "@core/services/ExperienceService";
import { ExperienceStatus, RedeemableModel } from "@custom-types/ExpercienceModel";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import { updateRedeemable } from "@store/actions/experiences.actions";
import store from "@store/index";
import { colors, settings } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";

interface Props {
    navigation?: NavigationType;
    redeemable: RedeemableModel;
}

interface State { }

const { t } = i18n;

export default class RedeemableCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToRedeemable = this.navigateToRedeemable.bind(this);
        this.setFavorite = this.setFavorite.bind(this);
    }

    navigateToRedeemable() {
        this.props.navigation.navigate(HomeNavigatorScreens.Experience.routeName, {
            screen: ExperienceNavigatorScreens.Redeemable.routeName,
            params: { redeemable: this.props.redeemable },
        });
    }

    async setFavorite() {
        ExperienceService.getInstance().setFavorite(this.props.redeemable);
        let redeemable: RedeemableModel = this.props.redeemable;
        redeemable.favorite = !this.props.redeemable.favorite;
        store.dispatch(updateRedeemable(redeemable));
    }

    render() {
        return (
            <>
                {this.props.redeemable && (
                    <PressableBase
                        style={[
                            styles.card,
                            {
                                opacity:
                                    this.props.redeemable?.status == ExperienceStatus.USED ||
                                        this.props.redeemable?.status == ExperienceStatus.EXPIRED
                                        ? 0.6
                                        : 1,
                            },
                        ]}
                        onPress={this.navigateToRedeemable}
                    >
                        <ImageBase
                            style={{ height: 150 }}
                            uri={
                                this.props.redeemable?.redeemableOption?.image?.carousel ||
                                this.props.redeemable?.redeemableOption?.image?.cover ||
                                this.props.redeemable.experience?.image?.cover || ""
                            }
                            autoSizeWidth={true}
                        ></ImageBase>

                        {this.props.redeemable?.status == ExperienceStatus.USED ||
                            this.props.redeemable?.status == ExperienceStatus.EXPIRED ? (
                            <View
                                style={[
                                    styles.iconWrapper,
                                    { backgroundColor: colors.grey, borderRadius: 4, paddingHorizontal: 5 },
                                ]}
                            >
                                <BoldText fontSize={12} color={colors.labelText || colors.white}>
                                    {this.props.redeemable?.status.toUpperCase()}
                                </BoldText>
                            </View>
                        ) : (
                            <PressableBase onPress={this.setFavorite} style={styles.iconWrapper}>
                                {this.props.redeemable.favorite ? (
                                    <Icon color={colors.yellow} size={20} name="star-fill" />
                                ) : (
                                    <Icon color={colors.grey} size={20} name="star" />
                                )}
                            </PressableBase>
                        )}
                        <View style={styles.titleWrapper}>
                            <RegularText color={colors.labelText} fontSize={12} numberOfLines={1}>
                                {trimHelper(this.props.redeemable.redeemableOption.name, 15)}
                            </RegularText>
                        </View>
                        <View style={{ position: "absolute",   zIndex: 100, top: 10, left: 10 }}>
                            <AvatarBase
                                alias={this.props.redeemable?.experience?.merchant?.name || ""}
                                uri={this.props.redeemable?.experience?.merchant?.image?.thumbnail || ""}
                                size={30}
                            ></AvatarBase>
                        </View>
                    </PressableBase>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.shadow,
        maxWidth: 300,
        minWidth: 150,
    },
    titleWrapper: {
        position: "absolute",
        zIndex: 100,
        bottom: 10,
        left: 10,
        backgroundColor: colors.complementary || colors.secondary,
        paddingHorizontal: 10,
        paddingVertical: 2,
        borderRadius: settings.cardRadius,
    },
    iconWrapper: {
        position: "absolute",
        zIndex: 100,
        top: 10,
        right: 10,
    },
});
