import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { colors } from "@styles/globalStyles";
import { Header } from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import Container from "@base/Container";
import store from "@store/index";
import {
  prePrepareTransaction,
  selectCurrency,
  switchNetwork,
} from "@store/actions/wallet";

import { NavigationType } from "../../../types/NavigationType";
import WarningCard from "@components/base/WarningCard";
import { FloatButton } from "@base/FloatButton";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n"
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";

interface Props {
  navigation: NavigationType;
  route: any
}

interface State {
  network: {
    from: string;
    to: string;
  };
}

const { t } = i18n

export class NetworkOptionScreen extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      network: {
        from:
          this.props.route?.params?.data?.network == "mainnet"
            ? "testnet"
            : "mainnet",
        to: this.props.route?.params?.data?.network,
      },
    };
  }

  onPress = async () => {
    store.dispatch(
      switchNetwork({
        testnet: !this.props.route?.params?.testnet,
        onSuccess: () => {
          store.dispatch(
            selectCurrency(this.props.route?.params?.data?.currencyId)
          );
          store.dispatch(
            prePrepareTransaction({
              address: this.props.route.params.data.address,
              amount: this.props.route.params.data.amount,
            })
          );
          this.props.navigation.navigate(MessagesNavigatorScreens.SendCrypto.routeName);
        },
      })
    );
  };

  render() {
    return (
      <ScreenWrapper>
        <Header title={"Network"} type={HeaderType.Light} {...this.props} />
        <Container style={styles.container}>
          <WarningCard
            message={`${t('your_network_is')} ${this.state.network.from} ${t('and_the_operation')} ${this.state.network.to} ${t('tries_to_follow')}`}
          />

          <FloatButton onPress={() => this.onPress()} iconName={"right"}  />
        </Container>
      </ScreenWrapper>
    );
  }
}

export default NetworkOptionScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  button: {
    backgroundColor: colors.secondary,
    width: 55,
    height: 55,
    position: "absolute",
    right: 20,
    bottom: 20,
    borderRadius: 30,
    justifyContent: "center",
    marginBottom: 8,
    alignItems: "center",
  },
});
