import SimplexMainScreen from "@screens/wallet/buy/simplex/SimplexMainScreen"
import BuyMethodScreen from "@screens/wallet/buy/simplex/BuyMethodScreen"
import ConfirmBuyScreen from "@screens/wallet/buy/simplex/ConfirmBuyScreen"
import PurchaseOrderScreen from "@screens/wallet/buy/simplex/PurchaseOrderScreen"
import NavigationInstantiator from "./helpers/NavigationInstantiator"

const SimplexNavigatorScreens = {
    SimplexMain: {
        routeName: "SimplexMain",
        title: "SimplexMain",
        component: SimplexMainScreen,
    },
    BuyMethodSimplex: {
        routeName: "BuyMethodSimplex",
        title: "BuyMethodSimplex",
        component: BuyMethodScreen,
    },
    ConfirmBuySimplex: {
        routeName: "ConfirmBuySimplex",
        title: "ConfirmBuySimplex",
        component: ConfirmBuyScreen,
    },
    PurchaseOrderSimplex: {
        routeName: "PurchaseOrderSimplex",
        title: "PurchaseOrderSimplex",
        component: PurchaseOrderScreen,
    },
}

const SimplexNavigator = NavigationInstantiator(SimplexNavigatorScreens)

export {SimplexNavigator, SimplexNavigatorScreens}
