import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { NavigationType } from "@custom-types/NavigationType";
import { wordlists } from "bip39";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import Wallet from "@core/wallet/Wallet";
import { colors, settings } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import store from "@store/index";
import { showPopup } from "@store/actions/global";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { setStoreSuccess } from "@utils/helpers/security/securityKit";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
}

interface State {
    mnemonic: string[];
    words: string[];
    fakes_worlds: string[];
    selected_words: string[];
    errorLenght: boolean;
}

const { t } = i18n;

export default class SeedConfirmScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.setWorlds = this.setWorlds.bind(this);
        this.onPressWord = this.onPressWord.bind(this);
        this.onPressEnd = this.onPressEnd.bind(this);
        this.state = {
            mnemonic: Wallet.getInstance().getMnemonic(),
            fakes_worlds: wordlists.english,
            words: [],
            selected_words: [],
            errorLenght: false,
        };
    }

    componentDidMount() {
        this.setWorlds();
    }

    setWorlds() {
        let newArrayWords: string[] = [];
        this.state.mnemonic.map((w) => {
            if (newArrayWords.length < 10) {
                return newArrayWords.push(this.state.fakes_worlds[Math.floor(Math.random() * (2000 - 1) + 1)]);
            }
            if (newArrayWords.length > 9 && newArrayWords.length < 12) {
                return newArrayWords.push(this.state.mnemonic[0], this.state.mnemonic[2]);
            }
        });

        this.setState({ words: newArrayWords.sort() });
    }

    onPressWord(word: string) {
        if (this.state.selected_words.includes(word)) {
            this.setState(
                {
                    selected_words: this.state.selected_words.filter((w) => {
                        return w !== word;
                    }),
                },
                this.setErrorLenght,
            );
        } else {
            this.setState({ selected_words: this.state.selected_words.concat(word) }, this.setErrorLenght);
        }
    }

    setErrorLenght() {
        this.setState({ errorLenght: this.state.selected_words.length > 2 });
    }

    setColor(word: string) {
        if (this.state.selected_words.includes(word)) {
            return colors.complementary || colors.secondary;
        } else {
            return colors.shadow;
        }
    }

    onPressEnd() {
        if (
            this.state.selected_words.length == 2 &&
            this.state.mnemonic.includes(this.state.selected_words[0]) &&
            this.state.mnemonic.includes(this.state.selected_words[1])
        ) {
            store.dispatch(showPopup({ type: "SUCCESS" }));
            setStoreSuccess(this.state.mnemonic);

            this.props.navigation.navigate(WalletNavigatorScreens.WalletMain.routeName);
        } else {
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("security_kit_confirm_error"),
                }),
            );
        }
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={"Seed"} type={HeaderType.Light} {...this.props} />

                <Container style={{ flex: 1, paddingBottom: 20, justifyContent: "space-between" }}>
                    <View>
                        <RegularText>{t("security_kit_confirm_a")}</RegularText>

                        <View style={styles.container}>
                            {this.state.words.map((w) => {
                                return (
                                    <PressableBase
                                        style={{
                                            padding: 10,
                                            borderRadius: settings.cardRadius,
                                            backgroundColor: this.setColor(w),
                                            margin: 5,
                                            overflow: "hidden",
                                        }}
                                        key={w}
                                        onPress={() => this.onPressWord(w)}
                                    >
                                        <RegularText>{w}</RegularText>
                                    </PressableBase>
                                );
                            })}
                        </View>
                        <View>
                            {this.state.errorLenght && (
                                <BoldText align={"center"} color={colors.complementary || colors.secondary}>
                                    {t("security_kit_confirm_b")}
                                </BoldText>
                            )}
                        </View>
                    </View>
                    <View>
                        {this.state.selected_words.length > 1 && (
                            <InlineButton title={t("finalize")} onPress={this.onPressEnd}></InlineButton>
                        )}
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    text: {
        paddingVertical: 8,
        textAlign: "justify",
    },
    container: {
        marginVertical: 25,

        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "flex-start",
        justifyContent: "center",
    },
});
