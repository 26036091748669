import NavigationInstantiator from "./helpers/NavigationInstantiator"
import WalletConnectScreen from "@screens/wallet/walletconnect/WalletConnectScreen"
import WalletConnectScanner from "@screens/wallet/walletconnect/WalletConnectScanner"
import WalletConnectDetailScreen from "@screens/wallet/walletconnect/WalletConnectDetailScreen"
import WalletConnectWebViewScreen from "@screens/wallet/walletconnect/WalletConnectWebViewScreen"

const WalletConnectNavigatorScreens = {
    WalletConnectMain: {
        routeName: "WalletConnect",
        title: "Wallet Connect",
        component: WalletConnectScreen,
    },
    WalletConnectDetail: {
        routeName: "WalletConnectDetail",
        title: "Wallet Connect",
        component: WalletConnectDetailScreen,
    },
    WalletConnectScanner: {
        routeName: "WalletConnectScanner",
        title: "Wallet Connect Scanner",
        component: WalletConnectScanner,
    },
    WalletConnectWebView: {
        routeName: "WalletConnectWebView",
        title: "Wallet Connect Explore",
        component: WalletConnectWebViewScreen
    }
}

const WalletConnectNavigator = NavigationInstantiator(WalletConnectNavigatorScreens)

export {WalletConnectNavigator, WalletConnectNavigatorScreens}
