import {_SendScreen} from "@screens/wallet/send/SendScreen";
import { connect } from "react-redux";
import store from "@store/index";
import { setCustomMessage } from "@store/actions/chat.actions";

import { ChatService } from "@core/services/ChatService";
import i18n from "@i18n/i18n"
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";


const { t } = i18n
export class _OrderScreen extends _SendScreen {
  constructor(props: any) {
    super(props);
    this.setTitle( t('pay_order'));
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.wallet,
    customMessage: state.chat.customMessage,
    next: async (transaction: any) => {
      let customMessage = state.chat.customMessage;
      customMessage.amount = transaction.amount;
      store.dispatch(setCustomMessage(customMessage));
      const service: ChatService = await ChatService.getInstance();
      await service.sendCustomMessage();
    },
    onSuccessNavigate: MessagesNavigatorScreens.Chat.routeName
    };
};

const mapDispatchToProps = (dispatch) => ({});

const OrderScreen = connect(mapStateToProps, mapDispatchToProps)(_OrderScreen);

export default OrderScreen;
