import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { loading, ready, showPopup } from "@store/actions/global";
import { Platform } from "react-native";
import i18n from "@i18n/i18n";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import { Client } from "@custom-types/Client";
import { currency } from "expo-localization";

const { t } = i18n;

export default class ExchangeHistoryService {
    private static instance: ExchangeHistoryService;

    constructor() { }

    static getInstance(): ExchangeHistoryService {
        if (!ExchangeHistoryService.instance) {
            ExchangeHistoryService.instance = new ExchangeHistoryService();
        }
        return ExchangeHistoryService.instance;
    }

    async getExchangeHistory(currency: Currency) {
        try {

            let url = `${getEnv(
                "API_URL",
            )}v2/${currency.getBlockchain()}/${currency.getNetworkType()}/${currency.getName().toLowerCase()}/exchange/history/${currency.getAddress()}`;

            const resp = await axios.get(url, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });

            return resp.data;
        } catch (e) {
            console.warn(e);
        }
    }
}
